import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { type TabsProps } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system';

const $fg = cssVar('tabs-color');
const $bg = cssVar('tabs-bg');
const $border = cssVar('tabs-border-color');

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleRoot = defineStyle(props => {
  const { orientation } = props;
  return {
    display: orientation === 'vertical' ? 'flex' : 'block'
  };
});

const baseStyleTab = defineStyle(props => {
  const { isFitted } = props as unknown as TabsProps;

  return {
    flex: Boolean(isFitted) ? 1 : undefined,
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _focusVisible: {
      zIndex: 1,
      boxShadow: 'none',
      bg: 'primary.50',
      color: 'primary'
    },
    _disabled: {
      cursor: 'not-allowed',
      opacity: 0.4
    }
  };
});

const baseStyleTablist = defineStyle(props => {
  const { align = 'start', orientation } = props as unknown as TabsProps;

  const alignments: Record<string, string> = {
    end: 'flex-end',
    center: 'center',
    start: 'flex-start'
  };

  return {
    justifyContent: alignments[align],
    flexDirection: orientation === 'vertical' ? 'column' : 'row'
  };
});

const baseStyleTabpanel = defineStyle({
  p: 4
});

const baseStyle = definePartsStyle(props => ({
  root: baseStyleRoot(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel
}));

const sizes = {
  md: definePartsStyle({
    tab: {
      fontSize: 'md',
      py: 2,
      px: 4
    }
  })
};

const variantLine = definePartsStyle(props => {
  const { colorScheme: c, orientation } = props;
  const isVertical = orientation === 'vertical';
  const borderProp = isVertical ? 'borderStart' : 'borderBottom';
  const marginProp = isVertical ? 'marginStart' : 'marginBottom';

  let colorGrade = '500';
  if (c === 'secondary') {
    colorGrade = '800';
  }

  return {
    tablist: {
      [borderProp]: '2px solid',
      borderColor: 'inherit'
    },
    tab: {
      [borderProp]: '2px solid',
      borderColor: 'transparent',
      [marginProp]: '-2px',
      _selected: {
        borderColor: `${c}.${colorGrade}`
      },
      _disabled: {
        _active: { bg: 'none' }
      },
      bg: $bg.reference
    }
  };
});

const variantNarrow = definePartsStyle(props => {
  const lineVariant = variantLine(props);

  return {
    tablist: {
      ...lineVariant.tablist,
      gap: 4
    },
    tab: {
      ...lineVariant.tab,
      px: 0,
      pt: 0,
      pb: 2
    }
  };
});

const variantEnclosed = definePartsStyle(props => {
  const { colorScheme: c } = props;

  let colorGrade = '500';
  if (c === 'secondary') {
    colorGrade = '800';
  }

  return {
    tab: {
      borderTopRadius: 'md',
      border: '1px solid',
      borderColor: 'transparent',
      mb: '-1px',
      [$border.variable]: 'transparent',
      _hover: {
        borderColor: 'secondary.100',
        borderBottomColor: 'inherit'
      },
      _selected: {
        [$fg.variable]: `colors.${c}.${colorGrade}`,
        [$border.variable]: `colors.white`,
        borderColor: 'inherit',
        borderBottomColor: $border.reference
      },
      color: $fg.reference,
      px: 3
    },
    tablist: {
      mb: '-1px',
      borderBottom: '1px solid',
      borderColor: 'inherit'
    }
  };
});

const variantEnclosedColored = definePartsStyle(props => {
  const { colorScheme: c } = props;
  const enclosedVariant = variantEnclosed(props);

  let colorGrade = '50';
  if (c === 'secondary') {
    colorGrade = '100';
  }

  return {
    tab: {
      ...enclosedVariant.tab,
      _selected: {
        ...enclosedVariant.tab._selected,
        bg: `${c}.${colorGrade}`,
        borderColor: `${c}.${colorGrade}`,
        borderBottomColor: `${c}.50`
      }
    },
    tablist: {
      ...enclosedVariant.tablist
    }
  };
});

const variantUnstyled = definePartsStyle({});

const variants = {
  line: variantLine,
  narrow: variantNarrow,
  enclosed: variantEnclosed,
  'enclosed-colored': variantEnclosedColored,
  unstyled: variantUnstyled
};

export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'line',
    colorScheme: 'primary'
  }
});
