import { forwardRef } from 'react';
import { pickStyleableProps, useScopedStyles, type StyleableProps } from '../../../css/useStyles';
import { classNames } from '../../../utils/classNames';
import styles from './RangeSlider.module.css';

interface RangeSliderProps extends StyleableProps {
  className?: string | undefined;
  id?: string | undefined;
  name?: string | undefined;

  /**
   * The lowest value in the range of permitted values.
   * @default 0
   */
  min?: number;

  /**
   * The highest value in the range of permitted values.
   * @default 100
   */
  max?: number;

  /**
   * The stepping interval that the value must adhere to.
   * @default 1
   */
  step?: number;

  /** The current value of the slider. */
  value: number;

  /** @default false */
  disabled?: boolean;

  onChange?: ((newValue: number) => void) | undefined;
}

export const LegacyRangeSlider = forwardRef<HTMLInputElement, RangeSliderProps>((props, forwardedRef) => {
  const [styleProps, { className, max, min, step, value, onChange, ...restProps }] = pickStyleableProps(props);
  const scopedClassName = useScopedStyles('Slider', styleProps);

  const ratio = ((value ?? 0) - (min ?? 0)) / ((max ?? 100) - (min ?? 0));

  const cssVars = {
    '--percent': Number.isFinite(ratio) ? `${100 * ratio}%` : '0%'
  } as React.CSSProperties;

  return (
    <input
      {...restProps}
      type="range"
      ref={forwardedRef}
      className={classNames(styles.slider, scopedClassName, className)}
      value={value}
      min={min}
      max={max}
      step={step}
      style={cssVars}
      onChange={event => onChange?.(event.currentTarget.valueAsNumber)}
    />
  );
});
