const specialRegexCharacters = new Set('.[](){}+*?^$|\\');

/**
 * Create a fuzzy-search Regexp from a search string.
 *
 * The resulting Regexp object will match anywhere in the string.
 * @example
 * ```
 * const rx = searchToRegExp("abc");
 * rx.test('apple bees carrots')   // true
 * rx.test('123a456b789c0')        // true
 * rx.test('foobar')               // false
 * ```
 */
export function searchStringToRegex(searchTerm: string, caseSensitive = false) {
  if (!searchTerm) {
    return /(?:)/;
  }
  const chars = searchTerm.split('').map(char => (specialRegexCharacters.has(char) ? `\\${char}` : char === ' ' ? '' : char));
  return new RegExp(chars.join('.*?'), caseSensitive ? '' : 'i');
}
