import type { AccessorKeyColumnDef } from '@tanstack/react-table';
import { once } from 'lodash';
import { createContext, useContext, useState, type PropsWithChildren } from 'react';
import type { BaseDataTableRow } from './DataTableStore';

interface DataTableContextValue<Data> {
  data: Array<Data>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<AccessorKeyColumnDef<Data, any>>;

  isRowDisabled?: ((row: Data) => boolean) | undefined;
}

const createDataTableContext = once(<Data extends BaseDataTableRow>() => createContext({} as DataTableContextValue<Data>));
export const useDataTableContext = <Data extends BaseDataTableRow>() => useContext(createDataTableContext<Data>());

export function DataTableContextProvider<Data extends BaseDataTableRow>({
  children,
  value
}: PropsWithChildren<{ value: DataTableContextValue<Data> }>) {
  const [Context] = useState(() => createDataTableContext<Data>());
  return <Context.Provider value={value}>{children}</Context.Provider>;
}
