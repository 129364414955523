import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { transparentize } from '@chakra-ui/theme-tools';

const baseStyle = defineStyle(props => {
  const { colorScheme: c, theme } = props;

  return {
    lineHeight: '1.2',
    borderRadius: 'base',
    fontWeight: 'semibold',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _focusVisible: {
      boxShadow: `0 0 0.25rem 0.125rem ${transparentize(`${c}.400`, 0.5)(theme)}`
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
      boxShadow: 'none'
    },
    _hover: {
      _disabled: {
        bg: 'initial'
      }
    }
  };
});

const variantOutline = defineStyle(props => {
  const { colorScheme: c } = props;

  const colorMap: Record<string, string> = {
    secondary: 'secondary.800',
    white: 'white'
  };

  const hoverBgMap: Record<string, string> = {
    secondary: 'secondary.100',
    white: 'whiteAlpha.200'
  };

  const activeBgMap: Record<string, string> = {
    secondary: 'secondary.200',
    white: 'whiteAlpha.400'
  };

  return {
    color: colorMap[c] ?? `${c}.600`,
    bg: 'transparent',

    border: '1px solid',

    borderColor: 'currentColor',
    '.chakra-button__group[data-attached][data-orientation=horizontal] > &:not(:last-of-type)': { marginEnd: '-1px' },
    '.chakra-button__group[data-attached][data-orientation=vertical] > &:not(:last-of-type)': { marginBottom: '-1px' },

    _hover: {
      bg: hoverBgMap[c] ?? `${c}.50`
    },
    _active: {
      bg: activeBgMap[c] ?? `${c}.100`
    }
  };
});

const variantSolid = defineStyle(props => {
  const { colorScheme: c } = props;

  if (c === 'secondary') {
    const bg = `secondary.800`;

    return {
      bg,
      color: `white`,
      _hover: {
        bg: `secondary.900`,
        _disabled: {
          bg
        }
      },
      _active: { bg: `contrast.800` }
    };
  }

  const background = `${c}.500`;

  return {
    bg: background,
    color: 'white',
    _hover: {
      bg: `${c}.600`,
      _disabled: {
        bg: background
      }
    },
    _active: { bg: `${c}.700` }
  };
});

const variantLink = defineStyle(props => {
  const { colorScheme: c } = props;
  return {
    padding: 0,
    display: 'inline-flex',
    height: 'auto',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'initial',
    letterSpacing: 'inherit',
    verticalAlign: 'baseline',
    color: `${c}.${c === 'secondary' ? 800 : 500}`,
    _hover: {
      textDecoration: 'underline',
      _disabled: {
        textDecoration: 'none'
      }
    },
    _active: {
      color: `${c === 'secondary' ? 'black' : `${c}.700`}`
    },
    _focusVisible: {
      boxShadow: 'none',
      textDecoration: 'underline'
    }
  };
});

const variantUnstyled = defineStyle({
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: '0',
  p: '0'
});

const variants = {
  outline: variantOutline,
  solid: variantSolid,
  link: variantLink,
  unstyled: variantUnstyled
};

const sizes = {
  lg: defineStyle({
    h: '16',
    minW: '12',
    fontSize: '2xl',
    px: '4'
  }),
  md: defineStyle({
    h: '8',
    minW: '10',
    fontSize: 'sm',
    px: '3'
  }),
  sm: defineStyle({
    h: '6',
    minW: '8',
    fontSize: 'xs',
    px: '2'
  })
};

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'primary'
  }
});
