/** Error thrown when the user session is no longer valid. */
export class SessionExpiredError extends Error {
  static get [Symbol.species]() {
    return SessionExpiredError;
  }

  get [Symbol.toStringTag]() {
    return 'SessionExpiredError';
  }

  constructor() {
    super('Your user session has expired. Log back in to continue.');

    // Fix the "Error" prototype chain in case we compile to older-than-ES2015
    if (!(this instanceof SessionExpiredError)) {
      Object.setPrototypeOf(this, new.target.prototype);
    }

    // Set the correct stack trace
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, SessionExpiredError);
    }

    this.name = 'SessionExpiredError';
  }
}
