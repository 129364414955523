/**
 * Generates a 64-bit cyrb53 hash from a string
 * https://stackoverflow.com/a/52171480/5460631
 */
export function hashString(str: string, seed = 0): number {
  const multiply = Math.imul ?? ((a, b) => (a | 0) * (b | 0));

  let hash1 = 0xdeadbeef ^ seed;
  let hash2 = 0x41c6ce57 ^ seed;

  for (let index = 0, char: number; index < str.length; index++) {
    char = str.charCodeAt(index);
    hash1 = multiply(hash1 ^ char, 2654435761);
    hash2 = multiply(hash2 ^ char, 1597334677);
  }

  hash1 = multiply(hash1 ^ (hash1 >>> 16), 2246822507) ^ multiply(hash2 ^ (hash2 >>> 13), 3266489909);
  hash2 = multiply(hash2 ^ (hash2 >>> 16), 2246822507) ^ multiply(hash1 ^ (hash1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & hash2) + (hash1 >>> 0);
}
