import { createContext, useContext, useMemo, type ReactNode } from 'react';
import { useLocales } from '../../i18n/Locales';
import type { WeekInfo } from './IntlWeekInfoAPI';

interface CalendarSettings {
  dateLocale: string;
  namesLocale: string;
  weekInfo: WeekInfo;
}

/**
 * Default week settings in ISO
 * - Week starts Monday
 * - First calendar week has 4 days
 * - Weekend is Saturday, Sunday
 */
const defaultISOWeekSettings: WeekInfo = {
  firstDay: 1,
  minimalDays: 4,
  weekend: [6, 7]
};

const CalendarSettingsContext = createContext<CalendarSettings | null>(null);

export function ProvideCalendarSettings({
  children,
  dateLocale,
  namesLocale,
  weekInfo: weekInfoProp
}: Partial<CalendarSettings> & { children: ReactNode }) {
  const parentSettings = useContext(CalendarSettingsContext);
  const defaultDateLocale = useLocales().dateTime;

  const settings = useMemo((): CalendarSettings => {
    let weekInfo: WeekInfo;
    if (weekInfoProp) {
      weekInfo = weekInfoProp;
    } else {
      const locale = new Intl.Locale(dateLocale ?? defaultDateLocale);
      weekInfo = locale.getWeekInfo?.() ?? locale.weekInfo ?? parentSettings?.weekInfo ?? defaultISOWeekSettings;
    }

    return {
      dateLocale: dateLocale ?? defaultDateLocale,
      namesLocale: namesLocale ?? defaultDateLocale,
      weekInfo
    };
  }, [dateLocale, defaultDateLocale, namesLocale, parentSettings, weekInfoProp]);

  return <CalendarSettingsContext.Provider value={settings}>{children}</CalendarSettingsContext.Provider>;
}

function getSettingsFromLocale(dateLocale: string, namesLocale: string, weekInfo?: WeekInfo | undefined): CalendarSettings {
  let weekInfoToUse: WeekInfo;
  if (weekInfo) {
    weekInfoToUse = weekInfo;
  } else {
    const locale = new Intl.Locale(dateLocale);
    weekInfoToUse = locale.getWeekInfo?.() ?? locale.weekInfo ?? defaultISOWeekSettings;
  }

  return {
    dateLocale,
    namesLocale,
    weekInfo: weekInfoToUse
  };
}

export function useCalendarSettings(): CalendarSettings {
  const settingsFromContext = useContext(CalendarSettingsContext);
  const dateLocale = useLocales().dateTime;

  const settings = useMemo(() => {
    return settingsFromContext ?? getSettingsFromLocale(dateLocale, dateLocale);
  }, [dateLocale, settingsFromContext]);

  return settings;
}
