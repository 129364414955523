import { useCallback, useEffect, useRef, useState } from 'react';

interface UseClipboardOptions {
  /**
   * Timeout after copying to clipboard until the state (e.g. success) resets.
   * @default 1500ms
   * */
  timeout?: number;

  onSuccess?: () => void;
  onError?: () => void;
}

export function useClipboard({ timeout = 1500, onSuccess, onError }: UseClipboardOptions = {}) {
  const [state, setState] = useState<'idle' | 'busy' | 'success' | 'error'>('idle');

  const timerRef = useRef(Number.NaN);

  const copy = useCallback(
    (text: string) => {
      clearTimeout(timerRef.current);
      setState('busy');

      navigator.clipboard
        .writeText(text)
        .then(() => {
          setState('success');
          onSuccess?.();
        })
        .catch(() => {
          setState('error');
          onError?.();
        })
        .finally(() => {
          timerRef.current = window.setTimeout(() => setState('idle'), timeout);
        });
    },
    [onError, onSuccess, timeout]
  );

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return {
    copy,
    state,
    isIdle: state === 'idle',
    isBusy: state === 'busy',
    isSuccess: state === 'success',
    isError: state === 'error'
  };
}
