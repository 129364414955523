/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Avatar,
  extendTheme,
  Text,
  type ChakraTheme,
  type ComponentStyleConfig,
  type ThemeOverride,
  type ThemeTypings
} from '@chakra-ui/react';
import { cleanupChakraTheme } from './cleanup';
import { accordionTheme } from './components/Accordion';
import { alertTheme } from './components/Alert';
import { alertAccordionTheme } from './components/AlertAccordion';
import { buttonTheme } from './components/Button';
import { cardTheme } from './components/Card';
import { checkboxTheme } from './components/Checkbox';
import { filterTheme } from './components/Filter';
import { formControlTheme } from './components/FormControl';
import { formErrorTheme } from './components/FormError';
import { formLabelTheme } from './components/FormLabel';
import { headerSectionTheme } from './components/HeaderSection';
import { inputTheme } from './components/Input';
import { modalTheme } from './components/Modal';
import { multiSelectTheme } from './components/MultiSelect';
import { numberInputTheme } from './components/NumberInput';
import { selectTheme } from './components/Select';
import { switchTheme } from './components/Switch';
import { tableTheme } from './components/Table';
import { tabsTheme } from './components/Tabs';
import { tagTheme } from './components/Tag';
import { textareaTheme } from './components/Textarea';
import { colorDefinitions, colors } from './tokens/colors';

Text.defaultProps = {
  textStyle: 'body'
};

Avatar.defaultProps = {
  color: 'white',
  fontWeight: 'bold'
};

type ChakraSpaceObject = ChakraTheme['space'];
const space = Array.from(Array(51)).reduce<ChakraSpaceObject>((prev, _, index) => {
  const currentValue = index * 0.25;

  prev[String(index)] = currentValue + 'rem';
  if (index < 20) {
    prev[String(index + 0.5)] = currentValue + 0.125 + 'rem';
  }
  return prev;
}, {});

const theme = extendTheme({
  fonts: {
    body: "'Core Sans CR', 'Segoe UI', sans-serif",
    heading: "'Core Sans CR', 'Segoe UI', sans-serif",
    mono: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace"
  },
  textStyles: {
    title: { fontFamily: 'body', color: 'bodyText', fontSize: '4xl', lineHeight: 'tall' },
    headline: { fontFamily: 'body', color: 'bodyText', fontSize: '2xl', lineHeight: 'tall' },
    headline2: { fontFamily: 'body', color: 'bodyText', fontSize: 'xl', lineHeight: 'base' },
    caption: { fontFamily: 'body', color: 'bodyText', fontSize: 'lg', lineHeight: 'short' },
    bodyLg: { fontFamily: 'body', color: 'bodyText', fontSize: 'md', lineHeight: 'base' },
    body: { fontFamily: 'body', color: 'bodyText', fontSize: 'sm', lineHeight: 'short' },
    bodySm: { fontFamily: 'body', color: 'bodyText', fontSize: 'xs', lineHeight: 'short' },
    code: { fontFamily: 'monospace', color: 'bodyText', fontSize: 'sm', lineHeight: 'shorter' }
  },
  colors: {
    ...colors,
    ...colorDefinitions,
    page: '#fff'
  },
  semanticTokens: {
    colors: {
      bodyText: 'secondary.800'
    }
  },
  shadows: {
    lg: '0px 2px 14px 0px rgba(74, 88, 96, 0.20)',
    md: '0px 2px 10px 0px rgba(74, 88, 96, 0.20)',
    sm: '0px 2px 6px 0px rgba(74, 88, 96, 0.20)',
    focus: '0 0 2px 2px rgb(11 183 160 / 10%)'
  },
  space,
  sizes: {
    ...space,
    icon: {
      sm: '0.5rem',
      md: '1rem',
      lg: '1.25rem',
      xl: '1.5rem',
      '2xl': '2rem'
    }
  },
  breakpoints: {
    base: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1440px',
    '2xl': '2560px'
  },
  styles: {
    global: {
      ':root': {
        WebkitTextSizeAdjust: '100%'
      },
      ':any-link': {
        color: 'unset',
        '&:not(:hover)': {
          textDecoration: 'none'
        }
      },
      body: {
        color: 'bodyText',
        lineHeight: 'shorter'
      },
      '.recharts-tooltip-wrapper': {
        zIndex: 'tooltip'
      }
    }
  }
} satisfies ThemeOverride) as ChakraTheme;

theme.components.Accordion = accordionTheme as ComponentStyleConfig;
theme.components.Button = buttonTheme as ComponentStyleConfig;
theme.components.Checkbox = checkboxTheme as ComponentStyleConfig;
theme.components.Form = formControlTheme as ComponentStyleConfig;
theme.components.FormError = formErrorTheme as ComponentStyleConfig;
theme.components.FormLabel = formLabelTheme as ComponentStyleConfig;
theme.components.Input = inputTheme as ComponentStyleConfig;
theme.components.NumberInput = numberInputTheme as ComponentStyleConfig;
theme.components.Modal = modalTheme as ComponentStyleConfig;
theme.components.Select = selectTheme as ComponentStyleConfig;
theme.components.Switch = switchTheme as ComponentStyleConfig;
theme.components.Tabs = tabsTheme as ComponentStyleConfig;
theme.components.Tag = tagTheme as ComponentStyleConfig;
theme.components.Card = cardTheme as ComponentStyleConfig;
theme.components.Table = tableTheme as ComponentStyleConfig;
theme.components.Textarea = textareaTheme as ComponentStyleConfig;
theme.components.HeaderSection = headerSectionTheme as ComponentStyleConfig;
theme.components.MultiSelect = multiSelectTheme as ComponentStyleConfig;
theme.components.Alert = alertTheme as ComponentStyleConfig;
theme.components.AlertAccordion = alertAccordionTheme as ComponentStyleConfig;
theme.components.Filter = filterTheme as ComponentStyleConfig;

// here we're generate semanticTokens for each of our colors so that e.g. "primary" points to "primary.500"
// only exception is secondary, where we point it to "800"
Object.keys(theme.colors).forEach(colorKey => {
  const color = theme.colors[colorKey as keyof typeof theme.colors];

  if (typeof color === 'object' && '500' in color && theme.semanticTokens?.colors) {
    theme.semanticTokens.colors[colorKey] = `${colorKey}.500`;
  }
});
if (theme.semanticTokens?.colors) {
  theme.semanticTokens.colors['secondary'] = 'secondary.800';
}

const typedTheme = theme as unknown as ThemeTypings;
cleanupChakraTheme(typedTheme);

export default typedTheme;
