import { forwardRef, type ReactNode } from 'react';
import { useScopedStyles, type StyleableProps } from '../../css/useStyles';
import { classNames } from '../../utils/classNames';
import styles from './Menu.module.css';
import { useMenuContext, useMenuList, useMenuListPositioner } from './useMenu';

interface MenuListProps {
  children?: ReactNode;
  className?: string;
}

type StyleableMenuListProps = Pick<
  StyleableProps,
  | 'height'
  | 'maxHeight'
  | 'maxWidth'
  | 'minHeight'
  | 'minWidth'
  | 'overflow'
  | 'overflowX'
  | 'overflowY'
  | 'padding'
  | 'paddingBottom'
  | 'paddingLeft'
  | 'paddingRight'
  | 'paddingTop'
  | 'width'
  | 'zIndex'
>;

export const MenuList = forwardRef<HTMLElement, MenuListProps & StyleableMenuListProps>((props, ref) => {
  const { children, className, zIndex, ...scopedStyles } = props;
  const scopedPositionerClass = useScopedStyles('MenuList', { zIndex: zIndex ?? 1 });
  const scopedStylesClass = useScopedStyles('MenuList', { zIndex: 1, ...scopedStyles });

  const { isOpen } = useMenuContext();
  const listProps = useMenuList({ children, className }, ref);
  const positionerProps = useMenuListPositioner();

  return (
    <div {...positionerProps} className={scopedPositionerClass}>
      <div
        {...listProps}
        className={classNames(
          scopedStylesClass,
          styles.menuList,
          isOpen ? styles.menuListOpen : styles.menuListClosing,
          listProps.className
        )}
      />
    </div>
  );
});
