import { Box, Flex, Icon, Text, createStandaloneToast, type UseToastOptions } from '@chakra-ui/react';
import { CheckIcon, CloseCrossIcon, ExclamationMarkIcon, InfoIcon, WarningTriangleIcon } from '../../icons';
import chakraTheme from '../../theme/chakra/index';
import { Button } from '../Button/Button';

const { toast: chakraToast } = createStandaloneToast({ theme: chakraTheme });

function getColor(options: UseToastOptions) {
  const status = options.status;
  if (!status) return 'info';
  switch (status) {
    case 'loading':
      return 'info';
    default:
      return status;
  }
}

function getIcon(options: UseToastOptions) {
  switch (options.status) {
    case 'success':
      return CheckIcon;
    case 'warning':
      return WarningTriangleIcon;
    case 'error':
      return ExclamationMarkIcon;
    default:
      return InfoIcon;
  }
}

function getTitle(options: UseToastOptions) {
  if (Boolean(options.title)) {
    return options.title;
  }

  switch (options.status) {
    case 'success':
      return 'Success';
    case 'warning':
      return 'Warning';
    case 'error':
      return 'Error';
    case 'loading':
      return 'Loading';
    default:
      return 'Information';
  }
}

const defaultToastOptions: UseToastOptions = {
  isClosable: true,
  position: 'bottom-right'
};

export function toast(props: Parameters<typeof chakraToast>[0]) {
  const options = Object.assign({}, defaultToastOptions, props);

  const color = getColor(options);
  const icon = getIcon(options);
  const title = getTitle(options);

  const toastId = chakraToast({
    render: () => (
      <Box bg="white" boxShadow="lg" borderRadius="md" overflow="hidden" maxW={72}>
        <Flex flexDir="column" p={3} gap={2.5}>
          <Flex justify="space-between">
            <Flex color={color} fontWeight="bold" align="center" gap={3}>
              <Icon as={icon} size="font" />
              {title}
            </Flex>
            {Boolean(options.isClosable) && (
              <Button
                size="sm"
                variant="unstyled"
                px={1}
                minW={0}
                onClick={() => {
                  chakraToast.close(toastId);
                }}
              >
                <CloseCrossIcon size="font" />
              </Button>
            )}
          </Flex>
          {Boolean(options.description) && <Text fontSize="sm">{options.description}</Text>}
        </Flex>
        <Box bg={color} h={1} />
      </Box>
    ),
    ...options
  });

  return toastId;
}
export const closeToast = chakraToast.close;
export const closeAllToasts = chakraToast.closeAll;
