import type { ReactNode } from 'react';
import { routes } from '../../../common/routing/routes';
import { useAppRoute } from '../../../common/routing/useAppRoute';
import { MaintenanceFilterProvider } from './MaintenanceFiltersStore';

interface MaintenanceProvidersProps {
  children?: ReactNode;
}

export function MaintenanceProviders({ children }: MaintenanceProvidersProps) {
  const route = useAppRoute(routes.maintenances);

  return <MaintenanceFilterProvider key={route.params.portfolioId}>{children}</MaintenanceFilterProvider>;
}
