import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps
} from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { CloseCrossIcon } from '../../icons';

export interface DialogProps extends ModalProps {
  title: ReactNode;
  hideOverlay?: boolean;
  hideCloseButton?: boolean;
  footer?: ReactNode;
}

export function Dialog(props: DialogProps) {
  const { title, children, hideOverlay = false, hideCloseButton = false, footer, ...dialogProps } = props;

  return (
    <Modal {...dialogProps}>
      {!hideOverlay && <ModalOverlay />}
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        {!hideCloseButton && (
          <ModalCloseButton>
            <CloseCrossIcon />
          </ModalCloseButton>
        )}
        <ModalBody>{children}</ModalBody>

        {Boolean(footer) && (
          <ModalFooter>
            <Flex gap={3}>{footer}</Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
