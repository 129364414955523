import { numberInputAnatomy as parts } from '@chakra-ui/anatomy';
import { baseTheme, type ThemeTypings } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { calc } from '@chakra-ui/theme-tools';
import { inputSizes, inputTheme } from './Input';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleRoot = defineStyle({
  lineHeight: 'base'
});

const baseStyleField = defineStyle({
  ...inputTheme.baseStyle?.field
});

const baseStyle = definePartsStyle(() => ({
  root: baseStyleRoot,
  field: baseStyleField
}));

type Size = keyof typeof inputSizes;

function getSize(size: Size) {
  const sizeStyle = inputTheme.sizes?.[size];

  const radius: Record<Size, ThemeTypings['radii']> = {
    xs: 'base',
    md: 'md'
  };

  const _fontSize = (sizeStyle?.field?.fontSize ?? 'md') as Size;
  const fontSize = baseTheme.fontSizes[_fontSize];

  return definePartsStyle({
    field: {
      ...sizeStyle?.field,
      verticalAlign: 'top'
    },
    stepper: {
      fontSize: calc(fontSize).multiply(0.75).toString(),
      _first: {
        borderTopEndRadius: radius[size]
      },
      _last: {
        borderBottomEndRadius: radius[size],
        mt: '-1px',
        borderTopWidth: 1
      }
    }
  });
}

const sizes = {
  xs: getSize('xs'),
  md: getSize('md')
};

export const numberInputTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: inputTheme.variants,
  defaultProps: inputTheme.defaultProps
});
