import { useEffect, useMemo, useState } from 'react';
import type { ThemeBreakpoint } from './Theme';
import { useTheme } from './ThemeProvider';

export function useBreakpoint(breakpoint: ThemeBreakpoint) {
  const { breakpoints } = useTheme();

  const mediaQuery = useMemo(() => {
    return window.matchMedia(`(max-width: ${breakpoints[breakpoint]}px)`);
  }, [breakpoint, breakpoints]);

  const [matches, setMatches] = useState(() => mediaQuery.matches);

  useEffect(() => {
    setMatches(mediaQuery.matches);
    const onMediaChange = (event: MediaQueryListEvent) => setMatches(event.matches);
    mediaQuery.addEventListener('change', onMediaChange);
    return () => mediaQuery.removeEventListener('change', onMediaChange);
  }, [mediaQuery]);

  return { below: matches, above: !matches };
}
