import { Box, Grid } from '@chakra-ui/react';
import { forwardRef, type ReactNode } from 'react';
import { ScrollPanel } from '../ScrollPanel/ScrollPanel';
import { ProvideSidebarContext } from './SidebarContext';

interface SidebarProps {
  children: ReactNode;
  direction?: 'horizontal' | 'vertical';
  footer?: ReactNode;
  isMenuCollapsed?: boolean;
}

export const Sidebar = forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
  const { children, direction = 'vertical', footer, isMenuCollapsed = false, ...otherProps } = props;

  return (
    <ProvideSidebarContext value={direction}>
      <Grid
        bgColor="secondary.900"
        gridTemplate={`'fill' 1fr / 1fr`}
        w={['100%', 12, isMenuCollapsed ? 12 : 50]}
        {...otherProps}
        h="100%"
        overflowX="hidden"
        overflowY="auto"
        position="relative"
        zIndex="0"
        gridArea="navbar"
        transition="width 0.3s"
        ref={ref}
      >
        <ScrollPanel axis={direction} shadowColor="#000" shadowZIndex={2}>
          <Grid
            w="100%"
            h="100%"
            gridTemplate={[
              `'nav rest' 1fr
            / 1fr auto`,
              `'nav' 1fr
            'rest' auto
            / 1fr`
            ]}
          >
            {children}
          </Grid>
        </ScrollPanel>
        {Boolean(footer) && <Box>{footer}</Box>}
      </Grid>
    </ProvideSidebarContext>
  );
});
