import { useMemo } from 'react';

export function useCombinedEventHandler<T>(...handlers: (((event: T) => void) | undefined)[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => combineEventHandlers(...handlers), handlers);
}

function combineEventHandlers<T>(...eventHandlers: (((event: T) => void) | undefined)[]) {
  return function mergedEventHandler(event: T) {
    for (const handler of eventHandlers) {
      handler?.(event);

      if (event !== undefined && (event as { defaultPrevented: boolean }).defaultPrevented) {
        return;
      }
    }
  };
}
