import { useEffect, useRef } from 'react';
import { keyCombination } from './keyCombination';

/**
 * Bind an action to a keyboard press.
 * Only the exact combination triggers the passed function (e.g. "Ctrl+A" will not trigger "A").
 *
 * Input is ignored when inside a writable input (e.g. input, textarea).
 *
 * @example
 * ```
 * useHotkey("Ctrl+C", () => copyAsImage(imageRef.current));
 * useHotkey("Shift+D", () => downloadImage(imageRef.current));
 * ```
 */
export function useHotkey(hotkey: string, callback: (event: KeyboardEvent) => void, { enable = true } = {}) {
  return useHotkeys({ [hotkey]: callback }, { enable });
}

export interface KeyCombinationMap {
  [keyCombination: string]: ((event: KeyboardEvent) => void) | undefined;
}

/**
 * Bind actions to keyboard combinations.
 * Only the exact combination triggers the passed callbacks (e.g. "Ctrl+A" will not trigger "A").
 *
 * Input is ignored when inside a writable input (e.g. input, textarea).
 *
 * @example
 * ```
 * useHotkeys({
 *   "Ctrl+C": () => copyAsImage(imageRef.current),
 *   "Shift+D": () => downloadImage(imageRef.current)
 * });
 * ```
 */
export function useHotkeys(hotkeys: KeyCombinationMap, { enable = true } = {}) {
  const hotkeysRef = useRef(hotkeys);
  hotkeysRef.current = hotkeys;

  useEffect(() => {
    if (!enable) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.defaultPrevented) return;
      if (isTypeableElement(event.target as HTMLElement)) return;

      const combination = keyCombination(event);
      const character = !event.ctrlKey && !event.altKey && !event.metaKey ? event.key : undefined;

      const hotkeys = hotkeysRef.current;
      const callback = hotkeys[combination] ?? (character ? hotkeys[character] : undefined);

      if (!callback) return;

      callback(event);
      event.preventDefault();
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [enable]);
}

function isTypeableElement(target: HTMLElement | null) {
  if (target == null) return false;
  if (['INPUT', 'TEXTAREA'].includes(target.tagName)) {
    const input = target as HTMLInputElement | HTMLTextAreaElement;
    return input.type !== 'hidden' && !input.disabled && !input.readOnly;
  }

  if (target.matches('[contenteditable], [contenteditable] *')) {
    return true;
  }

  return false;
}
