import { base64ToString } from '@hydrogrid/utilities/string';

export interface AccessToken {
  tokenId: string;
  validFrom: Date;
  validUntil: Date;
  userEmail: string;
  defaultPortfolio: string;
  isAdmin: boolean;
}

export function parseAccessToken(jwt: string): AccessToken {
  try {
    const payload: unknown = JSON.parse(base64ToString(jwt.split('.')[1]));
    if (isValidTokenPayload(payload)) {
      const token: AccessToken = {
        tokenId: payload.jti,
        validFrom: new Date(payload.nbf * 1000),
        validUntil: new Date(payload.exp * 1000),
        userEmail: payload.sub,
        defaultPortfolio: payload.default_portfolio,
        isAdmin: payload.is_admin ?? false
      };
      return token;
    }
  } catch {}
  throw new Error('Access token has an invalid format.');
}

export function tryParseAccessToken(jwt: string): AccessToken | null {
  try {
    return parseAccessToken(jwt);
  } catch {
    return null;
  }
}

interface ParsedTokenPayload {
  /** `true` if this is the first token acquired via login, `false` if it is the result of a token refresh. */
  fresh: boolean;
  /** Issue date of the token as unix timestamp (seconds). */
  iat: number;
  /** Unique identifier of this token. */
  jti: string;
  /** The token type. */
  type: 'access' | 'refresh';
  /** E-mail address of the user the token belongs to. */
  sub: string;
  /** Validity start (nbf = not before) of the token as unix timestamp (seconds). */
  nbf: number;
  /** Expiry of the token as unix timestamp (seconds). */
  exp: number;
  /** Default portfolio of the dashboard user. */
  default_portfolio: string;
  /** Whether or not the user has admin permissions. */
  is_admin?: boolean;
}

function isValidTokenPayload(value: unknown): value is ParsedTokenPayload {
  const jwt = value as ParsedTokenPayload;
  return (
    jwt != null &&
    typeof jwt === 'object' &&
    typeof jwt.fresh === 'boolean' &&
    typeof jwt.iat === 'number' &&
    typeof jwt.jti === 'string' &&
    ['access', 'refresh'].includes(jwt.type) &&
    typeof jwt.sub === 'string' &&
    typeof jwt.nbf === 'number' &&
    typeof jwt.exp === 'number' &&
    typeof jwt.default_portfolio === 'string' &&
    (typeof jwt.is_admin === 'boolean' || !('is_admin' in jwt))
  );
}
