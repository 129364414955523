export function downloadUrl(url: string, fileName: string) {
  if (fileName.includes('/')) throw Error('Unsupported "/" character in fileName');
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadFile(file: File) {
  const url = URL.createObjectURL(file);
  try {
    downloadUrl(url, file.name);
  } finally {
    URL.revokeObjectURL(url);
  }
}

export function downloadJsonAsFile(json: unknown, fileName: string) {
  const text = JSON.stringify(json, null, 2);
  const file = new File([text], fileName, { type: 'application/json' });
  downloadFile(file);
}

export function downloadCSVAsFile(csv: File, fileName: string) {
  const file = new File([csv], fileName, { type: 'text/csv' });
  downloadFile(file);
}
