interface ColorLike {
  r: number;
  g: number;
  b: number;
  a?: number;
}

export class Color implements ColorLike {
  r: number;
  g: number;
  b: number;
  a: number;

  constructor(r: number, g: number, b: number, a: number = 1.0) {
    this.r = clamp(rgbRound(r), 0, 255);
    this.g = clamp(rgbRound(g), 0, 255);
    this.b = clamp(rgbRound(b), 0, 255);
    this.a = Number(clamp(a, 0, 1).toFixed(3));
  }

  static from(color: ColorLike) {
    return new Color(color.r, color.g, color.b, color.a ?? 1);
  }

  static rgb(r: number, g: number, b: number) {
    return new Color(r, g, b);
  }

  static rgba(r: number, g: number, b: number, a: number) {
    return new Color(r, g, b, a);
  }

  toRGB() {
    return `rgb(${this.r} ${this.g} ${this.b}${this.a === 1 ? '' : ` / ${this.a}`})`;
  }

  toRGBA() {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
  }

  toHex() {
    const colors = [this.r, this.g, this.b];
    if (this.a !== 1) {
      colors.push(this.a * 255);
    }
    return '#' + colors.map(c => clamp(Math.round(c), 0, 255).toString(16).padStart(2, '0')).join('');
  }

  /** Formats the color as a hex or rgba() string. */
  toString(): string {
    return this.a === 1 ? this.toHex() : this.toRGBA();
  }

  /**
   * Interpolate between two colors by a delta factor.
   * Passing a `delta` of `0` uses the current color (`this`), a `delta` of `1` corresponds to color `b`.
   *
   * @param delta Determines how much to use of color b vs color a.
   */
  interpolate(b: Color, delta: number): Color {
    const a = this;
    return new Color(
      a.r * (1.0 - delta) + b.r * delta,
      a.g * (1.0 - delta) + b.g * delta,
      a.b * (1.0 - delta) + b.b * delta,
      a.a * (1.0 - delta) + b.a * delta
    );
  }

  /**
   * Mix two colors by overlaying color `over` on top of `this`.
   * @param opacity Opacity for color `over` which will be multiplied by the alpha channel.
   */
  overlay(over: Color, opacity = 1.0): Color {
    const under = this;

    // Round above color's alpha to the next 1/255th like the browser renderer
    const overA = Math.round(clamp(over.a * opacity, 0, 1) * 255) / 255;

    const alpha = under.a + overA - under.a * overA;

    return new Color(
      (over.r * overA + under.r * under.a * (1 - overA)) / alpha,
      (over.g * overA + under.g * under.a * (1 - overA)) / alpha,
      (over.b * overA + under.b * under.a * (1 - overA)) / alpha,
      alpha
    );
  }

  /**
   * Parses a CSS color to its r,g,b,a components.
   *
   * @example
   * ```
   * Color.parse('#0bb7a0');                  // { r: 11, g: 183, b: 160, a: 1 }
   * Color.parse('#f00');                     // { r: 255, g: 0, b: 0, a: 1 }
   * Color.parse('rgb(11, 183, 169)');          // { r: 11, g: 183, b: 160, a: 1 }
   * Color.parse('rgb(11 183 169)');            // { r: 11, g: 183, b: 160, a: 1 }
   * Color.parse('rgba(11, 183, 169, 0.5)');  // { r: 11, g: 183, b: 160, a: 0.5 }
   * Color.parse('rgb(11 183 169 / 50%)');    // { r: 11, g: 183, b: 160, a: 0.5 }
   * ```
   */
  static parse(color: string): Color {
    return parseColor(color);
  }

  /**
   * Try to parse a string as color, without throwing if the input is not a valid color.
   */
  static tryParse(colorStr: string): Color | null {
    try {
      return parseColor(colorStr);
    } catch {
      return null;
    }
  }

  /**
   * Try to find a color anywhere in a string, tries to parse it and returns it as Color object.
   * If no color from the known formats is found, returns null.
   */
  static findInString(colorStr: string): Color | null {
    for (const pattern of allSyntaxPatternsMatchingAnywhereInString) {
      const match = colorStr.match(pattern);
      const color = match && Color.tryParse(match[0]);
      if (color) {
        return color;
      }
    }

    return null;
  }
}

// https://www.w3.org/TR/css-color-4/#hex-notation
// #0bb7a0, #ff6790cc
const hexSyntax = /^#(?<r>[0-9a-f]{2})(?<g>[0-9a-f]{2})(?<b>[0-9a-f]{2})(?<a>[0-9a-f]{2})?$/i;
// #cba, #9fec
const hexShortSyntax = /^#(?<r>[0-9a-f])(?<g>[0-9a-f])(?<b>[0-9a-f])(?<a>[0-9a-f])?$/i;

// https://www.w3.org/TR/css-color-4/#rgb-functions
// rgb(20% 50% 50%), rgb(20% 50% 50% 50%), rgb(10% 80% 100% / 50%), rgb(10% 80% 100% / 0.5)
const rgbPercentageSyntax =
  /^rgba?\(\s*(?<r>\d+(?:\.\d+)?%)\s+(?<g>\d+(?:\.\d+)?%)\s+(?<b>\d+(?:\.+)?%)\s*(?:\/\s*(?<a>\d+(?:\.\d+)?%?)\s*)?\)$/i;

// rgb(255 65 30), rgb(96 50 44), rgb(22 96 184 / 50%), rgb(22 96 184 / 0.5)
const rgbNumberSyntax = /^rgba?\(\s*(?<r>\d+(?:\.\d+)?)\s+(?<g>\d+(?:\.\d+)?)\s+(?<b>\d+(?:\.+)?)\s*(?:\/\s*(?<a>\d+(?:\.\d+)?%?)\s*)?\)$/i;

// rgb(10%, 80%, 100%), rgb(10%, 80%, 100%, 50%), rgb(10%, 80%, 100%, 0.5)
const rgbLegacyPercentageSyntax =
  /^rgba?\(\s*(?<r>\d+(?:\.\d+)?%)\s*,\s*(?<g>\d+(?:\.\d+)?%)\s*,\s*(?<b>\d+(?:\.+)?%)\s*(?:,\s*(?<a>\d+(?:\.\d+)?%?)\s*)?\)$/i;

// rgb(255, 65, 30), rgb(96, 50, 44), rgb(22, 96, 184, 50%), rgb(22, 96, 184, 0.5)
const rgbLegacyNumberSyntax =
  /^rgba?\(\s*(?<r>\d+(?:\.\d+)?)\s*,\s*(?<g>\d+(?:\.\d+)?)\s*,\s*(?<b>\d+(?:\.+)?)\s*(?:,\s*(?<a>\d+(?:\.\d+)?%?)\s*)?\)$/i;

const allSyntaxPatternsMatchingAnywhereInString = [
  rgbLegacyNumberSyntax,
  rgbNumberSyntax,
  rgbPercentageSyntax,
  rgbLegacyPercentageSyntax,
  hexSyntax,
  hexShortSyntax
].map(
  // Match "[color]", "prefix [color]", "[color] suffix", but not "prefix[color]" or "[color]suffix"
  regex => new RegExp(regex.source.replace(/^\^/, '(?<=^|\\W)').replace(/\$$/, '(?=$|\\W)'), regex.flags)
);

/**
 * Parses a string to a color by the CSS color spec.
 * https://www.w3.org/TR/css-color-4/#hex-notation
 * https://www.w3.org/TR/css-color-4/#rgb-functions
 *
 * Examples for valid color syntax:
 * ```txt
 * #0bb7a0
 * #ff6790cc (alpha = 80%)
 * #cba
 * #9fec (alpha = 80%)
 * rgb(20% 50% 50%)
 * rgb(10% 60% 80% / 50%)
 * rgb(10% 60% 80% / 0.5)
 * rgb(25 140 220 / 50%)
 * rgb(25 140 220 / 0.5)
 * rgba(255, 196, 42, 128)
 * rgba(255, 196, 42, 50%)
 * rgba(100%, 70%, 60%, 50%)
 * ```
 */
function parseColor(color: string): Color {
  const fromHex = (str: string) => Number.parseInt(str.length === 1 ? `${str}${str}` : str, 16);

  // Match hex syntax (3/4/6/8 characters)
  const matchHex = color.match(hexSyntax) ?? color.match(hexShortSyntax);
  if (matchHex?.groups) {
    const { r, g, b, a } = matchHex.groups;
    return new Color(fromHex(r), fromHex(g), fromHex(b), a == null ? 1 : fromHex(a) / 255);
  }

  const parseNumberOrPercent = (str: string) => Number.parseFloat(str) * (str.endsWith('%') ? 255 / 100 : 1);
  const parseAlpha = (str: string) => (str == null ? 1 : Number.parseFloat(str) / (str.endsWith('%') ? 100 : 1));

  // Match rgb/rgba syntax
  const matchRgb =
    color.match(rgbLegacyNumberSyntax) ??
    color.match(rgbNumberSyntax) ??
    color.match(rgbPercentageSyntax) ??
    color.match(rgbLegacyPercentageSyntax);
  if (matchRgb?.groups) {
    const { r, g, b, a } = matchRgb.groups;
    return new Color(parseNumberOrPercent(r), parseNumberOrPercent(g), parseNumberOrPercent(b), parseAlpha(a));
  }

  throw new Error(`Invalid color value: "${color}"`);
}

function clamp(value: number, min: number, max: number) {
  return value < min ? min : value > max ? max : value || 0;
}

/** Rounds values exactly at n.5 to n, values above to (n+1). */
function rgbRound(value: number) {
  return Math.ceil(value - 0.5);
}
