import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { Card } from '@hydrogrid/design-system';
import { noopAbortSignal } from '@hydrogrid/utilities/timing/abortSignal';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { api } from '../../common/api/InsightApi';
import { parseAccessToken } from '../../common/api/parseAccessToken';
import { useAuthSessions } from '../../common/auth/useAuthSessions';
import { routes } from '../../common/routing/routes';
import { useAppNavigate } from '../../common/routing/useAppNavigate';
import { useRouteEnvironment } from '../../common/routing/useRouteEnvironment';
import { useAuthStore } from '../../common/stores/AuthStore';
import { AppVersions } from '../../components/AppVersions/AppVersions';
import { LoginForm } from '../../components/LoginForm/LoginForm';
import { LoginSucessful } from '../../components/LoginSuccessful/LoginSucessful';
import { MfaForm } from '../../components/MfaForm/MfaForm';
import { environments } from '../../config/environments';
import { Title } from '../../containers/Title/Title';

type StepState =
  | { step: 'login'; username?: string | undefined }
  | {
      step: 'mfa';
      username: string;
      secret: string;
      validUntil: number;
    }
  | {
      step: 'success';
      email: string;
      fullName: string | null;
    };

interface LoginOverlayProps {
  initialUsername?: string;
  onSuccess: () => void;
}

/**
 * An overlay that is displayed when the user session expires,
 * which allows the user to log back in or switch to a different user / log out.
 */
export function LoginOverlay({ initialUsername, onSuccess }: LoginOverlayProps) {
  const navigate = useAppNavigate();
  const { loginSuccess } = useAuthStore(store => store.actions);
  const authSessions = useAuthSessions();
  const environment = useRouteEnvironment();
  const queryClient = useQueryClient();

  const [loginState, setLoginState] = useState<StepState>({
    step: 'login',
    username: initialUsername
  });

  const goToMfaStep = useCallback(({ username, secret, validUntil }: { username: string; secret: string; validUntil: number }) => {
    setLoginState({ step: 'mfa', username, secret, validUntil });
  }, []);

  const goBackToLoginStep = useCallback(() => {
    setLoginState(state => ({
      step: 'login',
      username: state.step === 'mfa' ? state.username : undefined
    }));
  }, []);

  const updateMfaCode = useCallback(({ secret, validUntil }: { secret: string; validUntil: number }) => {
    setLoginState(state => {
      if (state.step !== 'mfa') return state;
      return { ...state, secret, validUntil };
    });
  }, []);

  const showSuccessScreen = useCallback(
    async ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => {
      const token = parseAccessToken(accessToken);
      authSessions.setTokens({ accessToken, refreshToken });

      const tokenInfo = await api.dashboard.user.tokenInfo({ signal: noopAbortSignal() });
      const email = token.userEmail;
      const fullName = tokenInfo.data.name ?? null;
      const isAdmin = token.isAdmin;

      authSessions.loginSuccessful({ environment, accessToken, refreshToken });
      loginSuccess({ email, fullName, isAdmin });

      setLoginState({ step: 'success', email, fullName });

      const reloadAllData = queryClient.invalidateQueries();
      const navigateAnywayAfterTwoSeconds = new Promise(done => setTimeout(done, 2000));

      reloadAllData.catch(_ignore => {});
      await navigateAnywayAfterTwoSeconds;

      onSuccess();
    },
    [authSessions, environment, queryClient, loginSuccess, onSuccess]
  );

  const forgotPassword = useCallback(
    ({ email }: { email: string }) => {
      navigate(routes.forgotPassword, {
        params: { environment: environment.slug },
        state: { step: 'enter-email', email }
      });
    },
    [navigate, environment]
  );

  const changeEnvironmentOnLoginPage = useCallback(() => {
    // When the user changes the environment, navigate back to the login page.
    // TODO: Do we want this?
    navigate(routes.login, { params: { environment: environment.slug } }, { replace: false });
  }, [navigate, environment]);

  return (
    <Modal isCentered isOpen onClose={() => {}}>
      <ModalOverlay />
      <ModalContent boxShadow="none" bg="none" border="none" w="auto" maxW="calc(100vh - 2rem)">
        <Title>Login</Title>
        <Card mt={14} p={8} minH="min(18.125rem, 100vh - 7rem)" maxW="calc(100vw - 4rem)">
          {loginState.step === 'login' && (
            <LoginForm
              canSelectEnvironment={false}
              onChangeEnvironmentClick={changeEnvironmentOnLoginPage}
              environment={environment ?? environments[0]}
              environments={environments}
              usernamePrefilled={loginState.username}
              onSuccessNeedsMfa={goToMfaStep}
              onSuccessWithoutMfa={showSuccessScreen}
              onForgotPassword={forgotPassword}
            />
          )}
          {loginState.step === 'mfa' && (
            <MfaForm
              username={loginState.username}
              secret={loginState.secret}
              validUntil={loginState.validUntil}
              onSuccess={showSuccessScreen}
              onNewCodeSent={updateMfaCode}
              onBackToLogin={goBackToLoginStep}
            />
          )}
          {loginState.step === 'success' && <LoginSucessful email={loginState.email} fullName={loginState.fullName} isReturning={true} />}
        </Card>
        <AppVersions color="secondary.200" />
      </ModalContent>
    </Modal>
  );
}
