import type { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { DiagnosticErrorBoundary } from '../../containers/AppErrorBoundary/AppErrorBoundary';
import { ProvideMatchedRoute } from './MatchedRouteContext';
import type { AnyTypedRoute } from './TypedRoutes';
import { createPathVariants } from './createPathVariants';

/**
 * Helper to render a `react-router` `<Route>` for typed app routes.
 *
 * Why: React-Router v6 has removed support for optional parameters ("/users/edit?"),
 * but we can support them by creating multiple routes ("/users", "/users/edit").
 *
 * Renders route alternatives and provides the matched route so it can be accessed anywhere in the render tree.
 *
 * Unfortunately needs to be the slightly awkward function call instead of a more react-y
 * `<AppRoute route={...} element={...} />`, since react-router checks the `type` of its children
 * and only allows `<Route>` components.
 *
 * @usage
 * ```
 * return <Routes>
 *   {renderAppRoute(routes.cockpit, <LazyLoadedCockpit />)}
 * </Routes>;
 * ```
 */
export function renderAppRoute(route: AnyTypedRoute, element: ReactNode) {
  return createPathVariants(route.path).map(path => (
    <Route
      key={path}
      path={path}
      element={
        <ProvideMatchedRoute route={route}>
          <DiagnosticErrorBoundary>{element}</DiagnosticErrorBoundary>
        </ProvideMatchedRoute>
      }
    />
  ));
}
