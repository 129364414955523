/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  forwardRef,
  type ComponentPropsWithRef,
  type ComponentPropsWithoutRef,
  type ComponentRef,
  type ElementType,
  type ForwardRefRenderFunction,
  type ValidationMap,
  type WeakValidationMap
} from 'react';

/**
 * Types for the "as=" pattern.
 *
 * @example
 * <Component as={OtherComponent} ...propsForOtherComponent... />
 * <Component as="input" type="checkbox" checked={true} />
 */

export type As<P = any> = ElementType<P>;

export type PropsOf<T extends As> = ComponentPropsWithoutRef<T> & { as?: As };

// Source:
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/system/src/system.types.tsx

export type OmitCommonProps<Target, OmitAdditionalProps extends keyof any = never> = Omit<Target, 'as' | OmitAdditionalProps>;

export type RightJoinProps<SourceProps extends object = {}, OverrideProps extends object = {}> = OmitCommonProps<
  SourceProps,
  keyof OverrideProps
> &
  OverrideProps;

export type MergeWithAs<
  ComponentProps extends object,
  AsProps extends object,
  AdditionalProps extends object = {},
  AsComponent extends As = As
> = RightJoinProps<ComponentProps, AdditionalProps> &
  RightJoinProps<AsProps, AdditionalProps> & {
    as?: AsComponent;
  };

/**
 * Type to use for components which allow to provide a base element/component via the `as` prop.
 *
 * @example
 * ```tsx
 * type ExampleComponentProps = { firstName: string };
 * const ExampleComponent: ComponentWithAs<'div', ExampleComponentProps> = (props) => {
 *   const { as: Element = 'div', firstName, ...otherProps } = props;
 *   return <Element {...otherProps}>First name: {firstName}</Element>;
 * }
 *
 * // Render as <h1>
 * const example1 = <ExampleComponent as="h1" firstName="Joe" />;
 *
 * // Render as an <input>
 * const example2 = <ExampleComponent as="input" type="checkbox" checked={true} firstName="Barack" />;
 *
 * // or as a different component
 * const example3 = <ExampleComponent as={OtherComponent} otherComponentProp={42} firstName="Hillary" />;
 * ```
 **/
export type ComponentWithAs<Component extends As, Props extends object = {}> = {
  <AsComponent extends As = Component>(
    props: MergeWithAs<ComponentPropsWithoutRef<Component>, ComponentPropsWithoutRef<AsComponent>, Props, AsComponent>
  ): JSX.Element | null;

  displayName?: string | undefined;
  propTypes?: WeakValidationMap<any> | undefined;
  contextTypes?: ValidationMap<any> | undefined;
  defaultProps?: Partial<any> | undefined;
};

/**
 * Type to use for components which allow to provide a base element/component via the `as` prop,
 * and forward the nested HTML element via `React.forwardRef()`.
 *
 * @example
 * ```tsx
 * const PrettyComponent = forwardRefWithAs(...);
 *
 * const formRef = useRef<HTMLFormElement>(null);
 * const example1 = <ExampleComponent as="form" ref={formRef} />;
 *
 * const canvasRef = useRef<HTMLFormElement>(null);
 * const example2 = <ExampleComponent as="canvas" ref={canvasRef} />;
 *
 * const PrettyButton = forwardRef<HTMLButtonElement, PrettyButtonProps>(...);
 * const buttonRef = useRef<HTMLButtonElement>(null);
 * const example2 = <ExampleComponent as={PrettyButton} ref={buttonRef} />;
 * ```
 **/
export type ForwardRefComponentWithAs<Component extends As, Props extends object = {}> = {
  <AsComponent extends As = Component>(
    props: MergeWithAs<ComponentPropsWithoutRef<Component>, ComponentPropsWithRef<AsComponent>, Props, AsComponent>
  ): JSX.Element | null;

  displayName?: string | undefined;
  propTypes?: WeakValidationMap<any> | undefined;
  contextTypes?: ValidationMap<any> | undefined;
  defaultProps?: Partial<any> | undefined;
};

/**
 * Creates a component that uses `forwardRef` to allow access to its DOM element,
 * while also accepting a different component to render as.
 *
 * @example
 * ```tsx
 * type ExampleComponentProps = { firstName: string };
 * const ExampleComponent = forwardRefWithAs<'div', ExampleComponentProps>((props, ref) => {
 *   const { as: Element = 'div', firstName, ...otherProps } = props;
 *   return <Element ref={ref} {...otherProps}>First name: {firstName}</Element>;
 * }
 *
 * // Render as <form>
 * const formRef = useRef<HTMLFormElement>(null);
 * const example1 = <ExampleComponent as="form" ref={formRef} firstName="Joe" />;
 *
 * // Render as a different component uses forwardRef
 * const checkboxRef = useRef<HTMLInputElement>(null);
 * const FancyCheckbox = forwardRef<HTMLInputElement, FancyCheckboxProps>(...);
 * const example2 = <ExampleComponent as={FancyCheckbox} checked={true} firstName="Barack" />;
 * ```
 */
export function forwardRefWithAs<Component extends As, Props extends object = {}>(
  render: ForwardRefRenderFunction<ComponentRef<Component>, RightJoinProps<ComponentPropsWithRef<Component>, Props> & { as?: As }>
): ForwardRefComponentWithAs<Component, Props> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const forwarded = forwardRef<ComponentRef<Component>, Props>(render as any);
  return forwarded as unknown as ForwardRefComponentWithAs<Component, Props>;
}
