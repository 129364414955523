import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const $height = cssVar('input-height');
const $fontSize = cssVar('input-font-size');
const $lineHeight = cssVar('input-line-height');
const $padding = cssVar('input-padding');
const $borderRadius = cssVar('input-border-radius');

const baseStyle = definePartsStyle({
  addon: {
    height: $height.reference,
    fontSize: $fontSize.reference,
    lineHeight: $lineHeight.reference,
    px: $padding.reference,
    borderRadius: $borderRadius.reference,
    transitionProperty: 'common',
    transitionDuration: 'normal'
  },
  field: {
    width: '100%',
    height: $height.reference,
    fontSize: $fontSize.reference,
    lineHeight: $lineHeight.reference,
    px: $padding.reference,
    borderRadius: $borderRadius.reference,
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _disabled: {
      opacity: 0.6,
      cursor: 'not-allowed'
    }
  }
});

const size = {
  xs: defineStyle({
    [$fontSize.variable]: 'fontSizes.base',
    [$lineHeight.variable]: 'lineHeights.base',
    [$padding.variable]: 'space.3',
    [$borderRadius.variable]: 'radii.base',
    [$height.variable]: 'sizes.8'
  }),
  md: defineStyle({
    [$fontSize.variable]: 'fontSizes.md',
    [$lineHeight.variable]: 'lineHeights.base',
    [$padding.variable]: 'space.3',
    [$borderRadius.variable]: 'radii.base',
    [$height.variable]: 'sizes.10.5'
  })
};

export const inputSizes = {
  xs: definePartsStyle({
    field: size.xs,
    group: size.xs
  }),
  md: definePartsStyle({
    field: size.md,
    group: size.md
  })
};

const variantOutline = definePartsStyle(() => {
  return {
    field: {
      border: '1px solid',
      borderColor: 'secondary.200',
      bg: 'white',
      _readOnly: {
        userSelect: 'all'
      },
      _invalid: {
        borderColor: 'error'
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: 'primary',
        boxShadow: 'focus'
      }
    },
    addon: {
      border: '1px solid',
      borderColor: 'secondary.200',
      bg: 'secondary.50'
    }
  };
});

const variantGhost = definePartsStyle(() => {
  return {
    field: {
      border: '1px solid',
      borderColor: 'transparent',
      bg: 'white',
      _readOnly: {
        userSelect: 'all'
      },
      _focusVisible: {
        zIndex: 1
      }
    },
    addon: {
      border: '1px solid',
      borderColor: 'transparent',
      bg: 'white'
    }
  };
});

const variantSubtle = definePartsStyle(() => {
  return {
    field: {
      border: '1px solid',
      borderColor: 'transparent',
      bg: 'white',
      _groupHover: {
        borderColor: 'secondary.200'
      },
      _readOnly: {
        userSelect: 'all'
      },
      _invalid: {
        borderColor: 'error!important'
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: 'primary!important',
        boxShadow: 'focus'
      }
    },
    addon: {
      border: '1px solid',
      borderColor: 'transparent',
      bg: 'white',
      _groupHover: {
        borderColor: 'secondary.200',
        bg: 'secondary.50'
      },
      _groupFocusWithin: {
        borderColor: 'secondary.200',
        bg: 'secondary.50'
      }
    }
  };
});

const variants = {
  outline: variantOutline,
  ghost: variantGhost,
  subtle: variantSubtle
};

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: inputSizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'outline'
  }
});
