import type { ReactNode } from 'react';
import { createContext, useMemo } from 'react';

interface AppTitleContextType {
  prefix?: string | undefined;
  suffix?: string | undefined;
  separator?: string | undefined;
}

export const AppTitleContext = createContext<AppTitleContextType>({ separator: ' - ' });

interface AppTitleProps extends AppTitleContextType {
  children: ReactNode;
}

/**
 * Provider for the app title.
 *
 * Needs to be provided at the app level for the application-wide title pre-/suffix,
 * but can also be used at a subsection of the app.
 */
export function AppTitle({ prefix, suffix, separator, children }: AppTitleProps) {
  const providerValue: AppTitleContextType = useMemo(() => {
    return { prefix, suffix, separator };
  }, [prefix, suffix, separator]);

  return <AppTitleContext.Provider value={providerValue}>{children}</AppTitleContext.Provider>;
}
