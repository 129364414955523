import { createLocalStore } from '@hydrogrid/utilities/stores';
import { combine } from 'zustand/middleware';

export interface BaseDataTableRow {
  highlighted?: boolean;
  editedBy?: string | null;
}

export interface DataTableCellId {
  rowIndex: number;
  colIndex: number;
}
export function serializeDataTableCellId(cellId: DataTableCellId): string {
  return `${cellId.rowIndex}-${cellId.colIndex}`;
}
export function parseDataTableCellId(cellId: string): DataTableCellId {
  const [rowIndex, colIndex] = cellId.split('-');

  return {
    rowIndex: Number(rowIndex),
    colIndex: Number(colIndex)
  };
}

export type DataTableCellInfo = {
  id: DataTableCellId;
  isEditable: boolean;
  originalValue: string;
  isNumeric: boolean;
  toClipboard: ((val: string) => string | Date | number) | undefined;
  fromClipboard: ((val: string) => string) | undefined;
};

type HistoryEntry = {
  firstSelectedCell: DataTableCellId | undefined;
  lastSelectedCell: DataTableCellId | undefined;
  selectedCellIds: Array<string>;
  changedValues: Map<string, { cellId: DataTableCellId; value: string }>;
};
const EMPTY_HISTORY_ENTRY: HistoryEntry = {
  firstSelectedCell: undefined,
  lastSelectedCell: undefined,
  selectedCellIds: [],
  changedValues: new Map()
};

type State = {
  dragState: 'none' | 'select' | 'cell-value';
  firstSelectedCell: DataTableCellId | undefined;
  lastSelectedCell: DataTableCellId | undefined;
  selectedCellIds: Array<string>;
  cellInfos: Map<string, DataTableCellInfo>;
  cellRefs: Map<string, HTMLDivElement>;
  historyIndex: number;
  editHistory: Array<HistoryEntry>;
  cellEditState:
    | {
        cellId: DataTableCellId;
        value: string;
      }
    | undefined;
};

const defaultState: State = {
  dragState: 'none',
  firstSelectedCell: undefined,
  lastSelectedCell: undefined,
  selectedCellIds: [],
  historyIndex: 0,
  editHistory: [],
  cellInfos: new Map(),
  cellRefs: new Map(),
  cellEditState: undefined
};

interface InitProps {
  columnNameIndexMap: Array<string>;
  onCellUpdate?: ((props: { changedColumn: string; row: Record<string, string> }) => Partial<Record<string, string>> | void) | undefined;
}

interface UpdateCellProps {
  editHistory: Array<HistoryEntry>;
  historyIndex: number;
  replaceHistory?: boolean;
  cellId: DataTableCellId;
  value: string;
}

export const [useDataTableStore, DataTableStoreProvider, useDataTableStoreInstance] = createLocalStore(
  'DataTableStore',
  (initProps: InitProps | undefined) =>
    combine(defaultState, (set, get) => {
      const updateCellValue = (props: UpdateCellProps): { editHistory: HistoryEntry[]; historyIndex: number; changed: boolean } => {
        const { editHistory, historyIndex, replaceHistory = false, cellId, value } = props;
        const { cellInfos, firstSelectedCell, lastSelectedCell, selectedCellIds } = get();

        const serializedCellId = serializeDataTableCellId(cellId);
        const prevValue = cellInfos.get(serializedCellId);
        if (!prevValue || !prevValue.isEditable) {
          return { editHistory, historyIndex, changed: false };
        }
        const columnNameIndexMap = initProps?.columnNameIndexMap ?? [];

        const rowValues = Array.from(cellInfos.values()).filter(cellInfo => cellInfo.id.rowIndex === cellId.rowIndex);
        const newHistoryEntry: HistoryEntry = {
          firstSelectedCell,
          lastSelectedCell,
          selectedCellIds,
          changedValues: new Map()
        };
        const currentHistoryEntry = editHistory[historyIndex];
        if (typeof currentHistoryEntry !== 'undefined') {
          newHistoryEntry.changedValues = new Map(currentHistoryEntry.changedValues);
        }

        if (initProps?.onCellUpdate) {
          const changedColumnName = columnNameIndexMap[cellId.colIndex];
          const row: Record<string, string> = rowValues.reduce((prev, cellInfo) => {
            const changedValue = newHistoryEntry.changedValues.get(serializeDataTableCellId(cellInfo.id));

            return {
              ...prev,
              [columnNameIndexMap[cellInfo.id.colIndex]]: changedValue?.value ?? cellInfo.originalValue
            };
          }, {});
          row[changedColumnName] = value;

          const changes = initProps.onCellUpdate({ changedColumn: changedColumnName, row });
          if (changes) {
            // it's possible that a cell change can also infer the update of other cells, that's being handled here (not recursive though as this is not needed yet)
            Object.keys(changes).forEach(columnName => {
              const changedValue = changes[columnName];
              if (changedValue === undefined) {
                return;
              }

              const affectedCellId = {
                rowIndex: cellId.rowIndex,
                colIndex: columnNameIndexMap.findIndex(c => c === columnName)
              };
              const serializedCellId = serializeDataTableCellId(affectedCellId);

              const prevValue = cellInfos.get(serializedCellId);
              if (prevValue && prevValue.isEditable) {
                newHistoryEntry.changedValues.set(serializedCellId, { cellId: affectedCellId, value: changedValue });
              }
            });
          }
        }

        newHistoryEntry.changedValues.set(serializedCellId, { cellId, value });

        const slicedHistory =
          editHistory.length === 0 ? [EMPTY_HISTORY_ENTRY] : editHistory.slice(0, replaceHistory ? historyIndex : historyIndex + 1);
        const newHistory = [...slicedHistory, newHistoryEntry];

        return {
          editHistory: newHistory,
          historyIndex: newHistory.length - 1,
          changed: true
        };
      };

      const goToHistoryEntry = (newHistoryIndex: number) => {
        const { editHistory, cellRefs } = get();
        const newHistoryEntry = editHistory[newHistoryIndex];

        if (newHistoryEntry.lastSelectedCell) {
          const serializedCellId = serializeDataTableCellId(newHistoryEntry.lastSelectedCell);
          const cellRef = cellRefs.get(serializedCellId);
          cellRef?.focus();
        }

        return {
          historyIndex: newHistoryIndex,
          firstSelectedCell: newHistoryEntry.firstSelectedCell,
          lastSelectedCell: newHistoryEntry.lastSelectedCell,
          selectedCellIds: newHistoryEntry.selectedCellIds
        };
      };

      return {
        actions: {
          setDragState: (dragState: State['dragState']) => {
            set(() => ({
              dragState
            }));
          },
          selectSingleCell: (cell: DataTableCellId) => {
            set(prev => {
              const cellRef = prev.cellRefs.get(serializeDataTableCellId(cell));
              cellRef?.focus();

              return {
                firstSelectedCell: cell,
                lastSelectedCell: cell,
                selectedCellIds: [serializeDataTableCellId(cell)]
              };
            });
          },
          selectMultipleCells: (targetCell: DataTableCellId, singleColumnSelect = false) => {
            set(({ firstSelectedCell, cellRefs }) => {
              if (firstSelectedCell === undefined) {
                return {};
              }

              const rowStart = Math.min(firstSelectedCell.rowIndex, targetCell.rowIndex);
              const rowEnd = Math.max(firstSelectedCell.rowIndex, targetCell.rowIndex);
              const colStart = Math.min(firstSelectedCell.colIndex, targetCell.colIndex);
              const colEnd = Math.max(firstSelectedCell.colIndex, targetCell.colIndex);

              const selectedCellIds: Array<string> = [];
              for (let rowIndex = rowStart; rowIndex <= rowEnd; rowIndex++) {
                for (let colIndex = colStart; colIndex <= colEnd; colIndex++) {
                  if (singleColumnSelect && colIndex !== firstSelectedCell.colIndex) {
                    continue;
                  }

                  selectedCellIds.push(serializeDataTableCellId({ rowIndex, colIndex }));
                }
              }

              const focusedCell: DataTableCellId = singleColumnSelect
                ? { rowIndex: targetCell.rowIndex, colIndex: firstSelectedCell.colIndex }
                : targetCell;
              const cellRef = cellRefs.get(serializeDataTableCellId(focusedCell));
              cellRef?.focus();

              return {
                lastSelectedCell: targetCell,
                selectedCellIds
              };
            });
          },
          updateCellValue: (cellId: DataTableCellId, value: string, replaceHistory = false) => {
            set(prevState => {
              const { editHistory, historyIndex } = updateCellValue({
                editHistory: prevState.editHistory,
                historyIndex: prevState.historyIndex,
                cellId,
                value,
                replaceHistory
              });

              return { editHistory, historyIndex };
            });
          },
          updateSelectedCellValues: (value: string) => {
            set(prevState => {
              let historyIndex = prevState.historyIndex;
              let editHistory = prevState.editHistory;
              let replaceHistory = false;

              prevState.selectedCellIds.forEach(cellId => {
                const newValue = updateCellValue({
                  editHistory,
                  historyIndex,
                  cellId: parseDataTableCellId(cellId),
                  value,
                  replaceHistory
                });
                if (newValue.changed) {
                  replaceHistory = true;
                }
                editHistory = newValue.editHistory;
                historyIndex = newValue.historyIndex;
              });

              return { editHistory, historyIndex };
            });
          },
          setCellInfos: (cellInfos: Map<string, DataTableCellInfo>) => {
            set({
              cellInfos
            });
          },
          updateCellEditState: (cell: DataTableCellId, value: string) => {
            set({ cellEditState: { cellId: cell, value } });
          },
          stopEditCell: (cancel: boolean) => {
            set(prevState => {
              const { cellEditState, cellRefs, selectedCellIds, lastSelectedCell, cellInfos } = prevState;
              if (!cellEditState) {
                return {};
              }

              const serializedCellId = serializeDataTableCellId(cellEditState.cellId);
              const cellRef = cellRefs.get(serializedCellId);
              cellRef?.focus();

              if (cancel) {
                return { cellEditState: undefined };
              }

              // we sort the cell ids to have the cells of the currently focused column be updated last (to have the onCellUpdate function effects being called in correct order)
              const sortedCellIds = selectedCellIds
                .slice()
                .sort(cellId => (parseDataTableCellId(cellId).colIndex === lastSelectedCell?.colIndex ? 1 : -1));

              let historyIndex = prevState.historyIndex;
              let editHistory = prevState.editHistory;
              let replaceHistory = false;

              sortedCellIds.forEach(cellId => {
                const newValue = updateCellValue({
                  editHistory,
                  historyIndex,
                  cellId: parseDataTableCellId(cellId),
                  value: cellEditState.value,
                  replaceHistory
                });
                if (newValue.changed) {
                  replaceHistory = true;
                }
                editHistory = newValue.editHistory;
                historyIndex = newValue.historyIndex;
              });

              let newCellSelection = {};
              const maxRowIndex = Math.max(...Array.from(cellInfos.values()).map(cell => cell.id.rowIndex));
              if (lastSelectedCell && lastSelectedCell.rowIndex !== maxRowIndex) {
                const newSelectedCell = { rowIndex: lastSelectedCell.rowIndex + 1, colIndex: lastSelectedCell.colIndex };
                const newSerializedId = serializeDataTableCellId(newSelectedCell);
                newCellSelection = {
                  firstSelectedCell: newSelectedCell,
                  lastSelectedCell: newSelectedCell,
                  selectedCellIds: [newSerializedId]
                };
                cellRefs.get(newSerializedId)?.focus();
              }

              return { cellEditState: undefined, editHistory, historyIndex, ...newCellSelection };
            });
          },
          discardChanges: () => {
            set(({ editHistory, historyIndex }) => {
              const newHistoryEntry: HistoryEntry = {
                firstSelectedCell: undefined,
                lastSelectedCell: undefined,
                selectedCellIds: [],
                changedValues: new Map()
              };

              const newHistoryIndex = historyIndex + 1;
              const slicedHistory = editHistory.length === 0 ? [EMPTY_HISTORY_ENTRY] : editHistory.slice(0, historyIndex + 1);
              const newHistory = [...slicedHistory, newHistoryEntry];

              return {
                firstSelectedCell: undefined,
                lastSelectedCell: undefined,
                selectedCellIds: [],
                editHistory: newHistory,
                historyIndex: newHistoryIndex
              };
            });
          },
          setCellRef: (serializedCellId: string, ref: HTMLDivElement | null) => {
            set(({ cellRefs }) => {
              const newMap = new Map(cellRefs);
              if (ref === null) {
                newMap.delete(serializedCellId);
              } else {
                newMap.set(serializedCellId, ref);
              }

              return {
                cellRefs: newMap
              };
            });
          },
          clearHistory: () => {
            set({ editHistory: [], historyIndex: 0 });
          },
          undoHistory: () => {
            set(({ historyIndex }) => {
              if (historyIndex <= 0) {
                return {};
              }

              const newHistoryIndex = historyIndex - 1;
              return goToHistoryEntry(newHistoryIndex);
            });
          },
          redoHistory: () => {
            set(({ historyIndex, editHistory }) => {
              if (historyIndex >= editHistory.length - 1) {
                return {};
              }

              const newHistoryIndex = historyIndex + 1;
              return goToHistoryEntry(newHistoryIndex);
            });
          },
          calculateChangedRows: () => {
            const { editHistory, historyIndex, cellInfos } = get();
            if (historyIndex === 0) {
              return [];
            }

            const historyEntry = editHistory[historyIndex];
            const changedValues = Array.from(historyEntry.changedValues.values());
            const changedRowIndices = Array.from(new Set(changedValues.map(value => value.cellId.rowIndex)));

            const columnNameIndexMap = initProps?.columnNameIndexMap ?? [];
            const changedRows: Array<Record<string, string>> = [];

            changedRowIndices.forEach(rowIndex => {
              const rowValues = Array.from(cellInfos.values()).filter(cellInfo => cellInfo.id.rowIndex === rowIndex);

              const row = rowValues.reduce((prev, cellInfo) => {
                const changedValue = historyEntry.changedValues.get(serializeDataTableCellId(cellInfo.id));

                return {
                  ...prev,
                  [columnNameIndexMap[cellInfo.id.colIndex]]: changedValue?.value ?? cellInfo.originalValue
                };
              }, {});

              changedRows.push(row);
            });

            return changedRows;
          }
        }
      };
    })
);

export function useDataTableCurrentHistoryEntry() {
  return useDataTableStore(state => (state.editHistory.length === 0 ? null : state.editHistory[state.historyIndex]));
}

export function useDataTableChangedRowCount() {
  return useDataTableStore(state => {
    if (state.editHistory.length === 0) {
      return 0;
    }

    const currentEditState = state.editHistory[state.historyIndex];
    const rowIndices = new Set(Array.from(currentEditState.changedValues.values()).map(({ cellId }) => cellId.rowIndex));
    return rowIndices.size;
  });
}
