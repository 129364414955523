import { useCallback, useRef, useState } from 'react';

export function useSessionStorageState<T>(defaultValue: T, name: string, validate?: (obj: unknown) => obj is T) {
  const [state, setState] = useState(() => ({
    value: getFromSessionStorage(name, defaultValue, validate)
  }));

  const nameRef = useRef(name);
  if (name !== nameRef.current) {
    nameRef.current = name;

    // Update state without rerendering the component
    state.value = getFromSessionStorage(name, defaultValue, validate);
  }

  const value = state.value;
  const valueRef = useRef(value);
  valueRef.current = value;

  const setValue = useCallback(
    (newValue: T | ((current: T) => T)) => {
      let result: T;
      if (typeof newValue === 'function') {
        result = (newValue as (current: T) => T)(valueRef.current);
      } else {
        result = newValue;
      }

      valueRef.current = result;
      window.sessionStorage.setItem(name, JSON.stringify(result));

      // Update the state & rerender the component
      setState({ value: result });
    },
    [name]
  );

  return [value, setValue] as const;
}

function getFromSessionStorage<T>(name: string, defaultValue: T, validate?: (obj: unknown) => obj is T) {
  const storedValue = window.sessionStorage.getItem(name);
  if (storedValue === null) {
    return defaultValue;
  }

  try {
    const parsedValue = JSON.parse(storedValue) as unknown;

    let isValid = true;
    if (validate) {
      isValid = validate(parsedValue);
    } else if (defaultValue !== undefined) {
      isValid = typeof parsedValue === typeof defaultValue;
    }

    return isValid ? (parsedValue as T) : defaultValue;
  } catch {
    return defaultValue;
  }
}
