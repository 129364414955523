import { useEffect, useLayoutEffect, useRef } from 'react';

export function useOnWindowResize(callback: () => void, runOnMount = false) {
  const callbackRef = useRef(callback);
  useEffect(() => (callbackRef.current = callback), [callback]);

  useEffect(() => {
    let animationFrame = Number.NaN;
    const handleResize = () => {
      cancelAnimationFrame(animationFrame);
      animationFrame = requestAnimationFrame(() => callbackRef.current());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  const runOnMountRef = useRef(runOnMount);
  runOnMountRef.current = runOnMount;

  const called = useRef(false);

  useLayoutEffect(() => {
    if (runOnMountRef.current && !called.current) {
      callbackRef.current();
    }
    called.current = true;

    return () => {
      called.current = false;
    };
  }, []);
}
