type Base64Encoding = 'utf-8' | 'latin1';

export function stringToBase64(input: string, encoding: Base64Encoding = 'utf-8') {
  if (encoding === 'latin1') {
    return btoa(input);
  }

  // In node, and when available, use Buffer.toString.
  if (typeof Buffer === 'function' && Buffer.from != null && isEncodingSupportedByNodeJSBuffer(encoding)) {
    return Buffer.from(input, encoding).toString('base64');
  }

  // Encode characters to utf8 bytes and convert them to base64.
  const inputAsUTF8 = new TextEncoder().encode(input);
  // const inputAsUTF8 = stringToUtf8Array(input);
  return uint8ArrayToBase64(inputAsUTF8);
}

export function base64ToString(inputInBase64: string, encoding: Base64Encoding = 'utf-8') {
  if (encoding === 'latin1') {
    return atob(inputInBase64);
  }

  // In node, and when available, use Buffer.toString.
  if (typeof Buffer === 'function' && Buffer.from != null && isEncodingSupportedByNodeJSBuffer(encoding)) {
    return Buffer.from(inputInBase64, 'base64').toString(encoding);
  }

  // Decode base64 to bytes, then convert bytes to utf8 characters.
  // return utf8ArrayToString(bytesInInput);
  const bytesInInput = base64StringToUnit8Array(inputInBase64);
  return new TextDecoder(encoding, { fatal: true, ignoreBOM: true }).decode(bytesInInput);
}

//
// Code below based on:
// https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_2_%E2%80%93_rewriting_atob_and_btoa_using_typedarrays_and_utf-8
//

function _unused_stringToUtf8Array(input: string) {
  const inputLength = input.length;
  let outputLength = 0;

  for (let inputIndex = 0; inputIndex < inputLength; inputIndex++) {
    const codePoint = input.codePointAt(inputIndex);

    if (codePoint === undefined) {
      continue;
    }

    if (codePoint > 65536) {
      inputIndex++;
    }

    outputLength +=
      codePoint < 0x80 ? 1 : codePoint < 0x800 ? 2 : codePoint < 0x10000 ? 3 : codePoint < 0x200000 ? 4 : codePoint < 0x4000000 ? 5 : 6;
  }

  const output = new Uint8Array(outputLength);

  for (let inputIndex = 0, outputIndex = 0; outputIndex < outputLength; inputIndex++) {
    const codePoint = input.codePointAt(inputIndex);
    if (codePoint === undefined) {
      continue;
    } else if (codePoint < 128) {
      /* Code point can be represented as one byte */
      output[outputIndex++] = codePoint;
    } else if (codePoint < 0x800) {
      /* Code point can be represented as two bytes */
      output[outputIndex++] = 192 + (codePoint >>> 6);
      output[outputIndex++] = 128 + (codePoint & 63);
    } else if (codePoint < 0x10000) {
      /* Code point can be represented as three bytes */
      output[outputIndex++] = 224 + (codePoint >>> 12);
      output[outputIndex++] = 128 + ((codePoint >>> 6) & 63);
      output[outputIndex++] = 128 + (codePoint & 63);
    } else if (codePoint < 0x200000) {
      /* Code point can be represented as four bytes */
      output[outputIndex++] = 240 + (codePoint >>> 18);
      output[outputIndex++] = 128 + ((codePoint >>> 12) & 63);
      output[outputIndex++] = 128 + ((codePoint >>> 6) & 63);
      output[outputIndex++] = 128 + (codePoint & 63);
      inputIndex++;
    } else if (codePoint < 0x4000000) {
      /* Code point can be represented as five bytes */
      output[outputIndex++] = 248 + (codePoint >>> 24);
      output[outputIndex++] = 128 + ((codePoint >>> 18) & 63);
      output[outputIndex++] = 128 + ((codePoint >>> 12) & 63);
      output[outputIndex++] = 128 + ((codePoint >>> 6) & 63);
      output[outputIndex++] = 128 + (codePoint & 63);
      inputIndex++;
    } else {
      /* Code point can be represented as six bytes */
      output[outputIndex++] = 252 + (codePoint >>> 30);
      output[outputIndex++] = 128 + ((codePoint >>> 24) & 63);
      output[outputIndex++] = 128 + ((codePoint >>> 18) & 63);
      output[outputIndex++] = 128 + ((codePoint >>> 12) & 63);
      output[outputIndex++] = 128 + ((codePoint >>> 6) & 63);
      output[outputIndex++] = 128 + (codePoint & 63);
      inputIndex++;
    }
  }

  return output;
}

function _unused_utf8ArrayToString(bytes: Uint8Array) {
  let output = '';
  let currentByte;
  const inputLength = bytes.length;
  for (let inputIndex = 0; inputIndex < inputLength; inputIndex++) {
    currentByte = bytes[inputIndex];
    output += String.fromCodePoint(
      currentByte > 251 && currentByte < 254 && inputIndex + 5 < inputLength
        ? /* Code point is represented as six bytes */
          (currentByte - 252) * 1073741824 +
            ((bytes[++inputIndex] - 128) << 24) +
            ((bytes[++inputIndex] - 128) << 18) +
            ((bytes[++inputIndex] - 128) << 12) +
            ((bytes[++inputIndex] - 128) << 6) +
            bytes[++inputIndex] -
            128
        : currentByte > 247 && currentByte < 252 && inputIndex + 4 < inputLength
          ? /* Code point is represented as five bytes */
            ((currentByte - 248) << 24) +
            ((bytes[++inputIndex] - 128) << 18) +
            ((bytes[++inputIndex] - 128) << 12) +
            ((bytes[++inputIndex] - 128) << 6) +
            bytes[++inputIndex] -
            128
          : currentByte > 239 && currentByte < 248 && inputIndex + 3 < inputLength
            ? /* Code point is represented as four bytes */
              ((currentByte - 240) << 18) +
              ((bytes[++inputIndex] - 128) << 12) +
              ((bytes[++inputIndex] - 128) << 6) +
              bytes[++inputIndex] -
              128
            : currentByte > 223 && currentByte < 240 && inputIndex + 2 < inputLength
              ? /* Code point is represented as three bytes */
                ((currentByte - 224) << 12) + ((bytes[++inputIndex] - 128) << 6) + bytes[++inputIndex] - 128
              : currentByte > 191 && currentByte < 224 && inputIndex + 1 < inputLength
                ? /* Code point is represented as two bytes */
                  ((currentByte - 192) << 6) + bytes[++inputIndex] - 128
                : /* Code point is represented as one byte */
                  currentByte
    );
  }
  return output;
}

function uint6ToBase64(uint6: number) {
  return uint6 < 26 ? uint6 + 65 : uint6 < 52 ? uint6 + 71 : uint6 < 62 ? uint6 - 4 : uint6 === 62 ? 43 : uint6 === 63 ? 47 : 65;
}

function base64ToUint6(char: number) {
  return char > 64 && char < 91
    ? char - 65
    : char > 96 && char < 123
      ? char - 71
      : char > 47 && char < 58
        ? char + 4
        : char === 43
          ? 62
          : char === 47
            ? 63
            : 0;
}

function uint8ArrayToBase64(input: Uint8Array) {
  let indexMod3 = 2;
  let outputBase64 = '';
  const inputLength = input.length;
  let uint24 = 0;

  for (let index = 0; index < inputLength; index++) {
    indexMod3 = index % 3;
    if (index > 0 && ((index * 4) / 3) % 76 === 0) {
      outputBase64 += '\r\n';
    }

    uint24 |= input[index] << ((16 >>> indexMod3) & 24);
    if (indexMod3 === 2 || input.length - index === 1) {
      outputBase64 += String.fromCodePoint(
        uint6ToBase64((uint24 >>> 18) & 63),
        uint6ToBase64((uint24 >>> 12) & 63),
        uint6ToBase64((uint24 >>> 6) & 63),
        uint6ToBase64(uint24 & 63)
      );
      uint24 = 0;
    }
  }

  return outputBase64.substring(0, outputBase64.length - 2 + indexMod3) + (indexMod3 === 2 ? '' : indexMod3 === 1 ? '=' : '==');
}

function base64StringToUnit8Array(inputInBase64: string, blockSize?: number) {
  const sB64Enc = inputInBase64.replace(/[^A-Za-z0-9+/]/g, '');
  const inputLength = sB64Enc.length;
  const outputLength = blockSize != null ? Math.ceil(((inputLength * 3 + 1) >> 2) / blockSize) * blockSize : (inputLength * 3 + 1) >> 2;
  const output = new Uint8Array(outputLength);

  let inputIndexMod3;
  let inputIndexMod4;
  let currentUint24Value = 0;
  let outputIndex = 0;

  for (let inputIndex = 0; inputIndex < inputLength; inputIndex++) {
    inputIndexMod4 = inputIndex & 3;
    currentUint24Value |= base64ToUint6(sB64Enc.charCodeAt(inputIndex)) << (6 * (3 - inputIndexMod4));
    if (inputIndexMod4 === 3 || inputLength - inputIndex === 1) {
      inputIndexMod3 = 0;
      while (inputIndexMod3 < 3 && outputIndex < outputLength) {
        output[outputIndex] = (currentUint24Value >>> ((16 >>> inputIndexMod3) & 24)) & 255;
        inputIndexMod3++;
        outputIndex++;
      }
      currentUint24Value = 0;
    }
  }

  return output;
}

const nodeBufferEncodings: BufferEncoding[] = [
  'ascii',
  'utf8',
  'utf-8',
  'utf16le',
  'ucs2',
  'ucs-2',
  'base64',
  'base64url',
  'latin1',
  'binary',
  'hex'
];

function isEncodingSupportedByNodeJSBuffer(encoding: string): encoding is BufferEncoding {
  return nodeBufferEncodings.includes(encoding as BufferEncoding);
}
