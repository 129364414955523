import { defineIconComponent } from '../defineIconComponent';
import DownstreamSvg from './Downstream.svg?react';
import GateSvg from './Gate.svg?react';
import PlantSvg from './Plant.svg?react';
import ReservoirSvg from './Reservoir.svg?react';
import TurbineSvg from './Turbine.svg?react';

export const TurbineIcon = defineIconComponent('TurbineIcon', TurbineSvg);
export const GateIcon = defineIconComponent('GateIcon', GateSvg);
export const ReservoirIcon = defineIconComponent('ReservoirIcon', ReservoirSvg);
export const PlantIcon = defineIconComponent('PlantIcon', PlantSvg);
export const DownstreamIcon = defineIconComponent('DownstreamIcon', DownstreamSvg);
