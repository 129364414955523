import { getISOWeek, getISOWeeksInYear, setISOWeek, startOfISOWeek } from 'date-fns';

export function dateToISOCalendarWeek({ year, month, day }: { year: number; month: number; day: number }) {
  const date = new Date(year, month - 1, day);
  const weekOfYear = getISOWeek(date);

  return {
    yearOfWeek: year + (weekOfYear > 50 && month === 1 ? -1 : weekOfYear === 1 && month === 12 ? 1 : 0),
    weekOfYear,
    weekday: date.getDay() || 7
  };
}

/**
 * @example
 * // Get Monday of the 20th week in 2023
 * dateFromISOCalendarWeek(2023, 20, 1)
 * // { day: 21, month: 5, year: 2023 }
 *
 * @param weekday A number from 1 (= Monday) to 7 (= Sunday)
 */
export function dateFromISOCalendarWeek({ yearOfWeek, weekOfYear, weekday }: { yearOfWeek: number; weekOfYear: number; weekday: number }) {
  const yearStart = startOfISOWeek(new Date(yearOfWeek, 0, 1));
  if (yearStart.getFullYear() < yearOfWeek) {
    yearStart.setDate(yearStart.getDate() + 7);
  }

  const date = setISOWeek(yearStart, weekOfYear);
  date.setDate(date.getDate() + weekday - 1);

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  };
}

/**
 * @example
 * calendarWeekToStringISO8601({ yearOfWeek: 2023, weekOfYear: 4 })
 * // -> "2023W04"
 */
export function calendarWeekToISOString({ weekOfYear, yearOfWeek }: { weekOfYear: number; yearOfWeek: number }) {
  return `${yearOfWeek}W${String(weekOfYear).padStart(2, '0')}`;
}

/**
 * @example
 * calendarWeekDateToStringISO8601({ yearOfWeek: 2023, weekOfYear: 4, weekday: 7 })
 * // -> "2023-W04-7"
 */
export function calendarWeekDateToISOString({
  yearOfWeek,
  weekOfYear,
  weekday
}: {
  yearOfWeek: number;
  weekOfYear: number;
  weekday: number;
}) {
  return `${yearOfWeek}-W${String(weekOfYear).padStart(2, '0')}-${weekday}`;
}

/**
 * @example
 * calendarWeekFromStringISO8601("2023W04")
 * // -> { yearOfWeek: 2023, weekOfYear: 4 }
 */
export function calendarWeekFromISOString(input: string) {
  const parts = input.match(/^(\d{4})-?W(0[1-9]|[0-4]\d|5[0-3])$/);
  if (!parts) {
    throw new TypeError(`Input has an invalid format: ${input}`);
  }

  const [, yearOfWeek, weekOfYear] = parts.map(part => Number.parseInt(part, 10));

  if (weekOfYear === 53 && getISOWeeksInYear(new Date(yearOfWeek, 0, 7)) < 53) {
    throw new TypeError(`Invalid has an invalid calendar week: ${input}`);
  }

  return { yearOfWeek, weekOfYear };
}

/**
 * @example
 * calendarWeekDateFromISOString('2023-W04-6')
 * // -> { yearOfWeek: 2023, weekOfYear: 4, weekday: 6 }
 */
export function calendarWeekDateFromISOString(input: string) {
  const parts = input.match(/^(\d{4})-W(0[1-9]|[0-4]\d|5[0-3])-([1-7])$/);
  if (!parts) {
    throw new TypeError(`Input has an invalid format: ${input}`);
  }

  const [, yearOfWeek, weekOfYear, weekday] = parts.map(part => Number.parseInt(part, 10));

  if (weekOfYear === 53 && getISOWeeksInYear(new Date(yearOfWeek, 0, 7)) < 53) {
    throw new TypeError(`Invalid has an invalid calendar week: ${input}`);
  }

  return { yearOfWeek, weekOfYear, weekday };
}
