import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useMemo, type ReactNode } from 'react';
import { ApiError } from './ApiError';
import { SessionExpiredError } from './SessionExpiredError';

const SECOND = 1000;
const MINUTE = 60 * SECOND;

export function ProvideDefaultQueryClient({ children }: { children?: ReactNode }) {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 30 * SECOND,
            gcTime: 15 * MINUTE,
            refetchInterval: 15 * MINUTE,
            refetchIntervalInBackground: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: true,
            retry: (failureCount: number, error: unknown) => {
              // Don't retry if we receive a 401 / 403 / 404 / 500.
              if (error instanceof ApiError && [401, 403, 404, 500].includes(error.status ?? 0)) {
                return false;
              }

              if (error instanceof SessionExpiredError) {
                return false;
              }

              // Resource is blocked (via devtools) or a critical non-recoverable error
              if (error instanceof TypeError && error.message === 'Failed to fetch') {
                return false;
              }

              // Retry 3 times by default
              return failureCount < 3;
            },
            retryOnMount: true
          },
          mutations: {
            retry: 0
          }
        }
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </QueryClientProvider>
  );
}
