export const popperCssVars = {
  arrowShadowColor: cssVar('--popper-arrow-shadow-color'),
  arrowSize: cssVar('--popper-arrow-size', '8px'),
  arrowSizeHalf: cssVar('--popper-arrow-size-half'),
  arrowBackgroundColor: cssVar('--popper-arrow-background-color'),
  transformOrigin: cssVar('--popper-transform-origin'),
  arrowOffset: cssVar('--popper-arrow-offset')
} as const;

function cssVar(variableName: string, fallbackValue?: string) {
  return {
    var: variableName,
    varRef: fallbackValue ? `var(${variableName}, ${fallbackValue})` : `var(${variableName})`
  };
}
