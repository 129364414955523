import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgGate = (props, ref) => /* @__PURE__ */ jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: 40, height: 40, fill: "none", viewBox: "0 0 40 40", "aria-label": "Gate", ref, ...props, children: /* @__PURE__ */ jsxs("g", { fill: "currentColor", children: [
  /* @__PURE__ */ jsx("path", { fill: "#fff", d: "M20 39.44A19.44 19.44 0 1 0 20 .56a19.44 19.44 0 0 0 0 38.88Z" }),
  /* @__PURE__ */ jsx("path", { d: "M20 1.11a18.89 18.89 0 1 1 0 37.78A18.89 18.89 0 0 1 20 1.1ZM20 0a20 20 0 1 0 0 40 20 20 0 0 0 0-40Z" }),
  /* @__PURE__ */ jsx("path", { d: "M5.74 9.42 18.92 20 5.74 30.58V9.42Zm28.4 0v21.15L21.08 20 34.14 9.43Zm1.1-2.32L20 19.44 4.63 7.1v25.8L20 20.56 35.25 32.9V7.1Z" })
] }) });
const ForwardRef = forwardRef(SvgGate);
export default ForwardRef;
