import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgReserves = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 16, height: 16, viewBox: "0 0 16 16", fill: "none", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { children: [
    /* @__PURE__ */ jsx("path", { d: "M10.1875 2.09375L8 0.46875L5.8125 2.09375V9.59375L3.9375 15.5312H12.0625L10.1875 9.59375V2.09375Z", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M5.8125 9.59375L11.125 12.5625L3.9375 15.5312", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M10.1875 9.59375L4.875 12.5625L12.0625 15.5312", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" })
  ] }),
  /* @__PURE__ */ jsx("path", { d: "M10.1875 2.09375L5.8125 5.84375", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M5.8125 5.84375L10.1875 9.59375", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M5.8125 2.09375L10.1875 5.84375", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx("path", { d: "M10.1875 5.84375L5.8125 9.59375", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsxs("g", { mask: "url(#mask1_5739_66252)", children: [
    /* @__PURE__ */ jsx("path", { d: "M14.5625 3.96875H10.1875V2.09375L14.5625 3.96875Z", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M14.5625 4.90625V3.96875", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M1.4375 3.96875H5.8125V2.09375L1.4375 3.96875Z", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M1.4375 4.90625V3.96875", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M14.5625 7.71875H10.1875V5.84375L14.5625 7.71875Z", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M14.5625 8.65625V7.71875", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M1.4375 7.71875H5.8125V5.84375L1.4375 7.71875Z", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M1.4375 8.65625V7.71875", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { d: "M13.3125 15.5312H2.6875", stroke: "currentcolor", strokeWidth: 0.7, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" })
  ] })
] });
const ForwardRef = forwardRef(SvgReserves);
export default ForwardRef;
