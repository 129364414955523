import { memo, useCallback, useEffect, useState } from 'react';
import styles from './SkipToMainContent.module.css';

/** Accessibility aid that allows keyboard navigation to jump to the main contents, ignoring top & sidebar. */
export const SkipToMainContent = memo(function SkipToMainContent() {
  const [button, buttonRef] = useState<HTMLButtonElement | null>(null);
  const [contentIsFocusable, setContentIsFocusable] = useState(true);

  useEffect(() => {
    const showButtonIfAnyContentIsFocusable = (event: KeyboardEvent) => {
      if (event.code !== 'Tab') return;

      const main = button?.parentElement?.querySelector('main');
      const focusable = findFirstFocusableElement(main);
      setContentIsFocusable(focusable != null);
    };

    document.addEventListener('keydown', showButtonIfAnyContentIsFocusable);
    return () => document.addEventListener('keydown', showButtonIfAnyContentIsFocusable);
  }, [button]);

  const moveFocusToPageContent = useCallback(() => {
    const main = button?.parentElement?.querySelector('main');
    findFirstFocusableElement(main)?.focus();
  }, [button]);

  return (
    <>
      <button
        className={styles.skipButton}
        ref={buttonRef}
        onClick={moveFocusToPageContent}
        tabIndex={contentIsFocusable ? 0 : -1}
        hidden={!contentIsFocusable}
      >
        Skip to main content
      </button>
      <div className={styles.backdrop} role="presentation" />
    </>
  );
});

const focusableSelector = `
  :is(
    a[href],
    button,
    input:not([type="hidden"]),
    textarea,
    select,
    details,
    audio[controls],
    video[controls],
    [contenteditable],
    [tabindex]
  ):not(
    :disabled,
    [tabindex^="-"],
    [inert],
    [inert] *
  )
`;

function findFirstFocusableElement(root: HTMLElement | null | undefined) {
  if (!root) return undefined;

  const potentiallyFocusableElements = Array.from(root?.querySelectorAll<HTMLElement>(focusableSelector) ?? []);
  const firstFocusableElement = potentiallyFocusableElements.find(element => {
    // We can't focus elements with display:none
    if (!element.offsetParent) return false;

    const styles = window.getComputedStyle(element);
    return styles.visibility !== 'hidden';
  });

  return firstFocusableElement;
}
