import { Button, HorizontalStack, Text, TextInput, VerticalStack } from '@hydrogrid/design-system';
import { useCallback, useLayoutEffect, useReducer, useRef, useState, type FormEvent } from 'react';
import EmailRecevedAnimation from '../../assets/animations/email-received.svg?react';
import { ApiError } from '../../common/api/ApiError';
import { useMultiFactorAuth, useResendMultiFactorToken } from '../../common/api/hooks/AuthDataHooks';
import { waitTimeAfterRequestingNewMfaCode } from '../../common/business-logic/timings';
import { useAuthStore } from '../../common/stores/AuthStore';
import { useCountdown, type Countdown } from '../../common/time/useCountdown';
import styles from './MfaForm.module.css';

interface MfaFormProps {
  username: string;
  secret: string;
  validUntil: number;
  onSuccess: ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => void;
  onNewCodeSent?: ({ secret, validUntil }: { secret: string; validUntil: number }) => void;
  onBackToLogin?: () => void;
}

/**
 * A form with validation for entering a multi-factor auth code received via email.
 * Requests and validation needs to be done in the parent component.
 */
export function MfaForm({
  username,
  secret: initialSecret,
  validUntil: initialValidUntil,
  onNewCodeSent,
  onSuccess,
  onBackToLogin
}: MfaFormProps) {
  const [code, setCode] = useState('');
  const [secret, setSecret] = useState(initialSecret);
  const [canRequestNewCode, setCanRequestNewCode] = useState(true);
  const [svgAnimationCounter, restartSvgAnimation] = useReducer((counter: number) => counter + 1, 0);
  const { checkMfaStart, checkMfaSuccess, checkMfaFailed } = useAuthStore(state => state.actions);

  const mfaEndpoint = useMultiFactorAuth();

  const resendMfaEndpoint = useResendMultiFactorToken();

  const countdown = useCountdown(resendMfaEndpoint.data?.valid_until ?? initialValidUntil, { below: 60, hideExpired: false });

  const sendMfaCode = useCallback(
    (code: string, username: string, mfaSecret: string) => {
      checkMfaStart();

      mfaEndpoint.mutate(
        { code, username, mfaSecret },
        {
          onSuccess: data => {
            checkMfaSuccess();

            onSuccess({
              accessToken: data.access_token,
              refreshToken: data.refresh_token
            });
          },
          onError: () => {
            checkMfaFailed();
          }
        }
      );
    },
    [mfaEndpoint, checkMfaStart, checkMfaSuccess, checkMfaFailed, onSuccess]
  );

  const changeCode = useCallback(
    (code: string) => {
      code = code.trim();
      setCode(code);
      if (code.length === 6) {
        sendMfaCode(code, username, secret);
      }
    },
    [secret, sendMfaCode, username]
  );

  const submitForm = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      sendMfaCode(code, username, secret);
    },
    [code, secret, sendMfaCode, username]
  );

  const requestNewCode = useCallback(() => {
    setCanRequestNewCode(false);
    resendMfaEndpoint.mutate(
      { username, mfaSecret: secret },
      {
        onSuccess: data => {
          onNewCodeSent?.({
            secret: data.mfa_secret,
            validUntil: data?.valid_until
          });
          setSecret(data.mfa_secret);
          restartSvgAnimation();
        }
      }
    );
    setTimeout(() => setCanRequestNewCode(true), waitTimeAfterRequestingNewMfaCode);
  }, [resendMfaEndpoint, secret, username, onNewCodeSent]);

  const formRef = useRef<HTMLFormElement>(null);
  useLayoutEffect(() => {
    const svg = formRef.current?.querySelector('svg');
    if (svg && svgAnimationCounter > 0) {
      const animations = svg.querySelectorAll<SVGAnimateElement | SVGAnimateTransformElement>('animate, animateTransform');
      animations.forEach(animation => {
        if (animation.id !== 'appear') {
          animation.beginElement();
        }
      });
    }
  }, [svgAnimationCounter]);

  // When the MFA code is wrong, the error detail is set to:
  // "The code you have entered is either invalid, expired or has been replaced by a new code."
  const wasIncorrectCode =
    mfaEndpoint.isError &&
    mfaEndpoint.error instanceof ApiError &&
    mfaEndpoint.error.status === 401 &&
    /code .+? (invalid|expired)/i.test(String((mfaEndpoint.error.body as { detail?: string })?.detail));
  const isIncorrectCode = wasIncorrectCode && mfaEndpoint.variables?.code === code;
  const unexpectedError = mfaEndpoint.isError && !wasIncorrectCode ? mfaEndpoint.error : null;

  return (
    <form noValidate className={styles.form} onSubmit={submitForm} ref={formRef}>
      <VerticalStack spacing="2rem" align="stretch">
        <h1 className={styles.heading}>Two-factor authentication</h1>
        <HorizontalStack spacing="2rem" justify="stretch">
          <EmailRecevedAnimation width={200} />
          <VerticalStack spacing="2rem" flex="1 0 auto" align="stretch" justify="center">
            <VerticalStack spacing="0.5rem" flex="0 0 auto" align="stretch">
              <label htmlFor="code">Enter the code you received in your email inbox:</label>
              <TextInput
                name="code"
                id="code"
                placeholder="1a2b3c"
                autoFocus
                disabled={mfaEndpoint.isPending || mfaEndpoint.isSuccess || countdown.expired}
                value={code}
                onChange={changeCode}
                error={
                  isIncorrectCode &&
                  !countdown.expired &&
                  'That code seems to be incorrect.\nCheck your email inbox again, or request a new code.'
                }
                showError="always"
                iconRight={countdown.text ? <MfaCodeExpiryCountdown countdown={countdown} /> : null}
              />
            </VerticalStack>
            {!countdown.expired && (
              <VerticalStack spacing="0.5rem" flex="0 0 auto">
                <Button
                  colorScheme="primary"
                  type="submit"
                  isDisabled={code.trim().length < 6 || mfaEndpoint.isPending || mfaEndpoint.isSuccess}
                >
                  Continue
                </Button>
                <Button
                  colorScheme="primary"
                  variant="link"
                  isDisabled={!canRequestNewCode || mfaEndpoint.isPending || mfaEndpoint.isSuccess}
                  onClick={requestNewCode}
                >
                  Send a new code
                </Button>
              </VerticalStack>
            )}
            {countdown.expired && (
              <HorizontalStack spacing="0.25em" flex="0 0 auto">
                <Text>Your two-factor code expired.</Text>
                <Button colorScheme="primary" variant="link" type="submit" onClick={onBackToLogin}>
                  Back to login
                </Button>
              </HorizontalStack>
            )}
          </VerticalStack>
        </HorizontalStack>
        {unexpectedError != null && (
          <Text color="destructive">
            {'Unexpected error: '}
            {unexpectedError instanceof ApiError && unexpectedError.status != null
              ? `HTTP ${unexpectedError.status}`
              : unexpectedError instanceof Error
                ? unexpectedError.message
                : String(unexpectedError)}
          </Text>
        )}
      </VerticalStack>
    </form>
  );
}

function MfaCodeExpiryCountdown({ countdown }: { countdown: Countdown }) {
  return (
    <div role="timer" className={countdown.remaining != null && countdown.remaining < 10 ? styles.codeExpired : styles.codeCountdown}>
      {countdown.text}
    </div>
  );
}
