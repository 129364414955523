import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { inputTheme } from './Input';

const baseStyle = defineStyle({
  ...inputTheme.baseStyle?.field,
  paddingY: '2',
  minHeight: '20',
  lineHeight: 'short',
  verticalAlign: 'top'
});

const variants = {
  outline: defineStyle(inputTheme.variants?.outline().field ?? {}),
  ghost: defineStyle(inputTheme.variants?.ghost().field ?? {}),
  subtle: defineStyle(inputTheme.variants?.subtle().field ?? {})
};

const sizes = {
  md: inputTheme.sizes?.md.field ?? {}
};

export const textareaTheme = defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'outline'
  }
});
