let scrollbarSize: number | null = null;

/**
 * Determines if the browser supports overlay scrollbars,
 * or if unsupported the width/height they take up.
 */
export function getOverlayScrollbarSize() {
  if (scrollbarSize == null) {
    const tester = document.createElement('div');
    Object.assign(tester.style, {
      border: 'none',
      margin: 'none',
      overflow: 'overlay',
      padding: 'none',
      width: '100px'
    });

    document.documentElement.appendChild(tester);
    scrollbarSize = tester.offsetWidth - tester.clientWidth;
    document.documentElement.removeChild(tester);
  }

  return scrollbarSize;
}
