import { type Modifier, type Placement, type State } from '@popperjs/core';
import { popperCssVars } from './popper-css-vars';

type NoOptions = Record<string, never>;

/**
 * Modifier that sets the popper width to match the reference.
 * Useful for selects, autocomplete, etc.
 */
export const matchWidth: Modifier<'matchWidth', NoOptions> = {
  name: 'matchWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect:
    ({ state }) =>
    () => {
      const reference = state.elements.reference as HTMLElement;
      state.elements.popper.style.width = `${reference.offsetWidth}px`;
    }
};

const transformOriginForPlacement: Record<string, string> = {
  top: 'bottom center',
  'top-start': 'bottom left',
  'top-end': 'bottom right',

  bottom: 'top center',
  'bottom-start': 'top left',
  'bottom-end': 'top right',

  left: 'right center',
  'left-start': 'right top',
  'left-end': 'right bottom',

  right: 'left center',
  'right-start': 'left top',
  'right-end': 'left bottom'
};

/*
 * Modifier that sets the css `transformOrigin` value of the popper
 * based on the dynamic placement state of the popper.
 * Useful when we need to animate/transition the popper.
 */
export const transformOrigin: Modifier<'transformOrigin', NoOptions> = {
  name: 'transformOrigin',
  enabled: true,
  phase: 'write',
  fn: ({ state }) => setTransformOrigin(state),
  effect:
    ({ state }) =>
    () =>
      setTransformOrigin(state)
};

function setTransformOrigin(state: State) {
  state.elements.popper.style.setProperty(popperCssVars.transformOrigin.var, transformOriginForPlacement[state.placement]);
}

/**
 * Modifier that adds width, height and overrides the `top/left/right/bottom`
 * styles generated by popper.js to properly position the arrow.
 */
export const positionArrow: Modifier<'positionArrow', NoOptions> = {
  name: 'positionArrow',
  enabled: true,
  phase: 'afterWrite',
  fn: ({ state }) => {
    if (!state.placement) return;

    const overrides = getArrowStyle(state.placement);

    if (state.elements?.arrow && overrides) {
      Object.assign(state.elements.arrow.style, {
        [overrides.property]: overrides.value,
        width: popperCssVars.arrowSize.varRef,
        height: popperCssVars.arrowSize.varRef,
        zIndex: -1
      });

      const vars = {
        [popperCssVars.arrowSizeHalf.var]: `calc(${popperCssVars.arrowSize.varRef} / 2 - 1px)`,
        [popperCssVars.arrowOffset.var]: `calc(${popperCssVars.arrowSizeHalf.varRef} * -1)`
      };

      for (const property in vars) {
        state.elements.arrow.style.setProperty(property, vars[property]);
      }
    }
  }
};

function getArrowStyle(placement: Placement) {
  if (placement.startsWith('top')) {
    return { property: 'bottom', value: popperCssVars.arrowOffset.varRef };
  }
  if (placement.startsWith('bottom')) {
    return { property: 'top', value: popperCssVars.arrowOffset.varRef };
  }
  if (placement.startsWith('left')) {
    return { property: 'right', value: popperCssVars.arrowOffset.varRef };
  }
  if (placement.startsWith('right')) {
    return { property: 'left', value: popperCssVars.arrowOffset.varRef };
  }
}

/** Modifier that sets the placement styles for the inner arrow that forms the popper arrow tip. */
export const innerArrow: Modifier<'innerArrow', NoOptions> = {
  name: 'innerArrow',
  enabled: true,
  phase: 'main',
  requires: ['arrow'],
  fn: ({ state }) => setInnerArrowStyles(state),
  effect:
    ({ state }) =>
    () =>
      setInnerArrowStyles(state)
};

function getBoxShadowForPlacement(placement: Placement) {
  if (placement.includes('top')) {
    return `1px 1px 0px 0 var(--popper-arrow-shadow-color)`;
  }

  if (placement.includes('bottom')) {
    return `-1px -1px 0px 0 var(--popper-arrow-shadow-color)`;
  }

  if (placement.includes('right')) {
    return `-1px 1px 0px 0 var(--popper-arrow-shadow-color)`;
  }

  if (placement.includes('left')) {
    return `1px -1px 0px 0 var(--popper-arrow-shadow-color)`;
  }
}

function setInnerArrowStyles(state: State) {
  if (!state.elements.arrow) return;

  const arrowInner = state.elements.arrow.querySelector<HTMLElement>('[data-popper-arrow-inner]');
  if (!arrowInner) return;

  const boxShadow = getBoxShadowForPlacement(state.placement);
  if (boxShadow) {
    arrowInner.style.setProperty('--popper-arrow-default-shadow', boxShadow);
  }

  Object.assign(arrowInner.style, {
    transform: 'rotate(45deg)',
    background: popperCssVars.arrowBackgroundColor.varRef,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 'inherit',
    boxShadow: `var(--popper-arrow-shadow, var(--popper-arrow-default-shadow))`
  });
}
