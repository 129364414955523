import { PageTitle } from '@hydrogrid/design-system';
import { Fragment, lazy, type ReactElement, type ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DataRangeQueryParam } from '../../common/routing/QueryParamTypes';
import { defineRoute } from '../../common/routing/TypedRoutes';
import { renderAppRoute } from '../../common/routing/renderAppRoute';
import { routes } from '../../common/routing/routes';
import { environments } from '../../config/environments';
import { CenteredLayout } from '../../layouts/CenteredLayout/CenteredLayout';
import { LayoutWithSidebar } from '../../layouts/LayoutWithSidebar/LayoutWithSidebar';
import { ConstraintProviders } from '../../pages/Constraints/stores/ConstraintProviders';
import { MaintenanceProviders } from '../../pages/Maintenances/stores/MaintenanceProviders';
import { ActionsProvodiers } from '../../pages/Operations/Actions/stores/ActionsProviders';
import { DiagnosticErrorBoundary } from '../AppErrorBoundary/AppErrorBoundary';
import { NeedsAuth } from './NeedsAuth';

const RedirectToLogin = lazy(() => import('./RedirectToLogin'));
const RedirectToCockpit = lazy(() => import('./RedirectToCockpit'));
const RedirectToLoginOrCockpit = lazy(() => import('./RedirectToLoginOrCockpit'));

const Login = lazy(() => import('../../pages/Login/LoginPage'));
const ForgotPassword = lazy(() => import('../../pages/ForgotPassword/ForgotPasswordPage'));
const ResetPassword = lazy(() => import('../../pages/ResetPassword/ResetPasswordPage'));
const Cockpit = lazy(() => import('../../pages/Cockpit/CockpitPage'));
const Portfolio = lazy(() => import('../../pages/Portfolio/PortfolioPage'));
const LongTerm = lazy(() => import('../../pages/LongTerm/LongTermPage'));
const WaterValue = lazy(() => import('../../pages/WaterValue/WaterValuePage'));
const PlantReporting = lazy(() => import('../../pages/Reporting/PlantReporting'));
const PortfolioReporting = lazy(() => import('../../pages/Reporting/PortfolioReporting'));
const Constraints = lazy(() => import('../../pages/Constraints/ConstraintsPage'));
const Reserves = lazy(() => import('../../pages/Reserves/ReservesPage'));
const Maintenances = lazy(() => import('../../pages/Maintenances/MaintenancesPage'));
const AdminPlantOverviewPage = lazy(() => import('../../pages/Admin/PlantOverview/PlantOverviewPage'));
const PlantSettings = lazy(() => import('../../pages/Admin/PlantSettings/PlantSettingsPage'));
const CustomersPage = lazy(() => import('../../pages/Admin/Customers/CustomersPage'));
const NotFound = lazy(() => import('../../pages/NotFound/NotFound'));
const Users = lazy(() => import('../../pages/Admin/User/UsersPage'));
const Portfolios = lazy(() => import('../../pages/Admin/Portfolios/PortfoliosPage'));
const ApiUser = lazy(() => import('../../pages/Admin/User/ApiUserPage'));
const InsightUser = lazy(() => import('../../pages/Admin/User/InsightUserPage'));
const LiveData = lazy(() => import('../../pages/Operations/LiveData/LiveData'));
const Actions = lazy(() => import('../../pages/Operations/Actions/ActionsPage'));

// Defines which component should be rendered for each app route
const appRouteComponents: Record<keyof typeof routes, ReactNode> = {
  index: <RedirectToLoginOrCockpit />,
  login: (
    <CenteredLayout>
      <PageTitle>Login</PageTitle>
      <Login />
    </CenteredLayout>
  ),
  forgotPassword: (
    <CenteredLayout>
      <PageTitle>Forgot Password</PageTitle>
      <ForgotPassword />
    </CenteredLayout>
  ),
  resetPassword: (
    <CenteredLayout>
      <PageTitle>Reset Password</PageTitle>
      <ResetPassword />
    </CenteredLayout>
  ),
  cockpitMap: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Cockpit</PageTitle>
        <Cockpit />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  cockpitCharts: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Cockpit</PageTitle>
        <Cockpit />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  portfolioOverview: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Portfolio</PageTitle>
        <Portfolio />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  longTermView: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Long-Term</PageTitle>
        <LongTerm />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  waterValue: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Water Value</PageTitle>
        <WaterValue />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  plantReporting: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Reporting</PageTitle>
        <PlantReporting />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  portfolioReporting: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Reporting</PageTitle>
        <PortfolioReporting />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  constraints: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Constraints</PageTitle>
        <ConstraintProviders>
          <Constraints />
        </ConstraintProviders>
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  maintenances: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Maintenance</PageTitle>
        <MaintenanceProviders>
          <Maintenances />
        </MaintenanceProviders>
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  plants: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Plants</PageTitle>
        <AdminPlantOverviewPage />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  reserves: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Reserves</PageTitle>
        <Reserves />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  plantSettings: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Plants</PageTitle>
        <PlantSettings />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  customers: (
    <NeedsAuth isAdminOnly>
      <LayoutWithSidebar>
        <PageTitle>Customers</PageTitle>
        <CustomersPage />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  portfolios: (
    <NeedsAuth isAdminOnly>
      <LayoutWithSidebar>
        <PageTitle>Portfolios</PageTitle>
        <Portfolios />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  users: (
    <NeedsAuth isAdminOnly>
      <LayoutWithSidebar>
        <PageTitle>Users</PageTitle>
        <Users />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  insightUser: (
    <NeedsAuth isAdminOnly>
      <LayoutWithSidebar>
        <PageTitle>Users</PageTitle>
        <InsightUser />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  apiUser: (
    <NeedsAuth isAdminOnly>
      <LayoutWithSidebar>
        <PageTitle>Users</PageTitle>
        <ApiUser />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  liveData: (
    <NeedsAuth>
      <LayoutWithSidebar>
        <PageTitle>Operations</PageTitle>
        <LiveData />
      </LayoutWithSidebar>
    </NeedsAuth>
  ),
  actions: (
    <NeedsAuth isAdminOnly>
      <LayoutWithSidebar>
        <PageTitle>Actions</PageTitle>
        <ActionsProvodiers>
          <Actions />
        </ActionsProvodiers>
      </LayoutWithSidebar>
    </NeedsAuth>
  )
};

// Routes for development, will not be included in production build
let devOnlyRoutes: ReactElement | null = null;
if (import.meta.env.DEV) {
  const debugRoute = defineRoute('/:environment/debug', { query: { range: DataRangeQueryParam } });
  const DebugPage = lazy(() => import('../../development/DebugPage'));
  devOnlyRoutes = (
    <>
      {renderAppRoute(
        debugRoute,
        <DiagnosticErrorBoundary>
          <DebugPage />
        </DiagnosticErrorBoundary>
      )}
    </>
  );
}

export function AppRouter() {
  return (
    <Routes>
      {/**
       * All app routes are rendered via renderAppRoute to support alternative routes.
       * For example, "/cockpit/:plantId?" would render two routes, "/cockpit" & "/cockpit/:plantId".
       **/}
      {Object.entries(appRouteComponents).map(([routeName, element]) => {
        const route = routes[routeName as keyof typeof routes];
        return <Fragment key={routeName}>{renderAppRoute(route, element)}</Fragment>;
      })}

      {/* Development-only routes */}
      {devOnlyRoutes}

      {/* Redirect /cockpit to /eu/cockpit */}
      <Route path="/cockpit" element={<RedirectToCockpit />} />

      {/* Redirect /staging or /eu to either login or cockpit (ignores "/somewhereelse") */}
      {environments.map(env => (
        <Route key={env.slug} path={`/${env.slug}`} element={<RedirectToLoginOrCockpit />} />
      ))}

      {/* Extra routes which should only be used to handle navigation, not as a navigation destination */}
      <Route path="/login" element={<RedirectToLogin />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
