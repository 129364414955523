import {
  Box,
  Input as ChakraInput,
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  type BoxProps,
  type InputProps as ChakraInputProps
} from '@chakra-ui/react';
import { forwardRef, useRef, type ReactNode } from 'react';
import { CloseCrossIcon } from '../../icons';

export interface InputProps extends Omit<ChakraInputProps, 'onChange'> {
  onChange: (value: string) => void;
  addonLeft?: ReactNode;
  addonRight?: ReactNode;
  isClearable?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(inputProps, ref) {
  const { onChange, addonLeft, addonRight, variant = 'outline', isClearable = true, ...props } = inputProps;

  const inputRef = useRef<HTMLInputElement>();
  const showClearableButton = Boolean(props.value) && isClearable && !Boolean(props.isReadOnly) && !Boolean(props.isDisabled);

  return (
    <InputGroup
      {...{ variant }}
      lineHeight="base"
      _focusWithin={{ '[data-clear-button]:not(:focus-visible):not(:active)': { opacity: '0!important', pointerEvents: 'none' } }}
    >
      {Boolean(addonLeft) && <InputLeftAddon>{addonLeft}</InputLeftAddon>}
      <ChakraInput
        ref={node => {
          inputRef.current = node ?? undefined;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        {...props}
        data-peer
        onChange={e => {
          onChange?.(e.target.value);
        }}
      />
      {showClearableButton && (
        <InputClearButton
          onClick={() => {
            onChange?.('');
            inputRef.current?.focus();
          }}
        />
      )}
      {Boolean(addonRight) && <InputRightAddon>{addonRight}</InputRightAddon>}
    </InputGroup>
  );
});

export function InputClearButton({ onClick }: Pick<BoxProps, 'onClick'>) {
  return (
    <Box pos="relative" pointerEvents="none" _peerHover={{ '& [data-clear-button]': { opacity: 1 } }}>
      <Flex
        zIndex={1}
        pos="absolute"
        right={1.5}
        h="100%"
        align="center"
        fontSize="xs"
        rounded="full"
        onClick={e => {
          if (document.activeElement !== e.target) return;
          onClick?.(e);
        }}
      >
        <Box
          data-clear-button
          as="button"
          type="button"
          cursor="pointer"
          pointerEvents="auto"
          p={1.5}
          bg="white"
          border="1px"
          borderColor="transparent"
          rounded="full"
          transition="opacity .2s"
          opacity={0}
          _hover={{ opacity: 1 }}
          _focusVisible={{ opacity: 1, outline: 'none', boxShadow: 'focus', borderColor: 'primary' }}
          aria-label="Clear the input field"
          sx={{ '& > *': { pointerEvents: 'none' } }}
        >
          <CloseCrossIcon size="font" />
        </Box>
      </Flex>
    </Box>
  );
}
