/**
 * Unicode-aware function that capitalizes the first character of every word.
 *
 * @example
 * ```ts
 * capitalize("á şimplé əxampl€")  // "Á Şimplé Əxampl€"
 * ```
 */
export function capitalize(input: string) {
  return input.replace(/(?<!\p{L})\p{Ll}/gu, char => char.toUpperCase());
}
