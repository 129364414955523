import { useCallback, useEffect, useRef } from 'react';

// https://stackoverflow.com/questions/55045566/react-hooks-usecallback-causes-child-to-re-render/55047178

/**
 * Hook to have constistent reference-stable callbacks for function components.
 * This is similar to useCallback, but returns the same function every time.
 *
 * Useful when you want to pass a function that depends on a piece of state,
 * as a prop, without triggering a re-render everytime.
 *
 * @example
 * ```tsx
 * function SomeComponent() {
 *   const bar = useStableCallback((value: T) => {
 *     // do stuff, update state
 *   });
 *
 *   // OtherComponent will not rerender when "someState" changes.
 *   return <OtherComponent onChange={bar} />
 * }
 * ```
 */
export function useStableCallback<T extends (...args: never[]) => unknown>(callback: T): T;
export function useStableCallback<T extends (...args: never[]) => unknown>(callback: T | undefined): T | undefined;
export function useStableCallback<T extends (...args: never[]) => unknown>(callback: T): T | undefined {
  // Instance to hold the actual callback.
  const callbackRef = useRef(callback);

  // The memoized callback that won't change and calls the changed callbackRef.
  const memoizedCallback = useCallback((...args: Parameters<T>) => {
    return callbackRef.current?.(...args);
  }, []);

  // The effect updates the callbackRef
  useEffect(() => {
    callbackRef.current = callback;
  });

  // Return the memoized callback.
  return callback === undefined ? callback : (memoizedCallback as T);
}
