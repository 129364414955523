import { type DataRange } from '@hydrogrid/design-system';
import { ApiError } from '../ApiError';
import { api } from '../InsightApi';
import type { TimeZone as BackendTimeZone, Currency, HydroBody, LatLonLocation, TurbineWaterValues } from '../generated-client/Schemas';
import { dataRangeToBackendParameters } from '../query-utils/dataRangeToBackendParameters';
import { defineQuery } from '../query-utils/defineQuery';

type NonEmptyLocation = {
  [K in keyof LatLonLocation]-?: LatLonLocation[K] & {};
};

interface HydroBodyWithPlantId extends Omit<HydroBody, 'location'> {
  plantId: string;
  location: NonEmptyLocation | null;
}

export const hydrobodyDetails = defineQuery(
  ({ portfolioId, plantId, hydrobodyId }: { portfolioId: string; plantId: string; hydrobodyId: string }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'hydrobody', hydrobodyId],
    queryFn: ({ signal }) =>
      api.dashboard.hydroBody
        .requestHydroBodyDetails({ portfolio_id: portfolioId, plant_id: plantId, hydro_body_id: hydrobodyId, signal })
        .then((body): HydroBodyWithPlantId => {
          const { latitude, longitude } = body.location ?? {};
          return {
            ...body,
            location: latitude != null && longitude != null ? { latitude, longitude } : null,
            plantId
          };
        })
  })
);

export const powerAndPrice = defineQuery(
  ({ portfolioId, plantId, range, timeZone }: { portfolioId: string; plantId: string; range: DataRange; timeZone: BackendTimeZone }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'power-and-price', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.power.requestPlantPowerAndPrice({
        portfolio_id: portfolioId,
        plant_id: plantId,
        queryParams: dataRangeToBackendParameters(range, timeZone),
        signal
      })
  })
);

export const portfolioGeneration = defineQuery((portfolioId: string) => ({
  queryKey: ['portfolio', portfolioId, 'plant-generation'],
  queryFn: ({ signal }) =>
    api.dashboard.portfolio.getPortfolioPlantGeneration({ portfolio_id: portfolioId, signal }).then(response => response.data)
}));

export const priceForecast = defineQuery(
  ({ priceForecastSourceId, range, timeZone }: { priceForecastSourceId: string; range: DataRange; timeZone: BackendTimeZone }) => ({
    queryKey: ['price-forecast', priceForecastSourceId, timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.priceForecast.getPriceForecast({
        price_forecast_source_uuid: priceForecastSourceId,
        queryParams: {
          ...dataRangeToBackendParameters(range, timeZone),
          request_time: Date.now(),
          timezone: timeZone
        },
        signal
      })
  })
);

export const hydrobodyLevel = defineQuery(
  ({
    portfolioId,
    plantId,
    hydrobodyId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    hydrobodyId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'hydrobody', hydrobodyId, 'level', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.reservoir.requestLevel({
        portfolio_id: portfolioId,
        plant_id: plantId,
        reservoir_id: hydrobodyId,
        queryParams: dataRangeToBackendParameters(range, timeZone),
        signal
      })
  })
);

export const hydrobodyInflow = defineQuery(
  ({
    portfolioId,
    plantId,
    hydrobodyId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    hydrobodyId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'hydrobody', hydrobodyId, 'inflow', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.reservoir.getInflow({
        portfolio_id: portfolioId,
        plant_id: plantId,
        hydro_body_id: hydrobodyId,
        queryParams: dataRangeToBackendParameters(range, timeZone),
        signal
      })
  })
);

export const turbineGeneration = defineQuery(
  ({
    portfolioId,
    plantId,
    turbineId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    turbineId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'turbine', turbineId, 'power', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.power.requestTurbinePower({
        portfolio_id: portfolioId,
        plant_id: plantId,
        turbine_id: turbineId,
        queryParams: {
          ...dataRangeToBackendParameters(range, timeZone),
          additional_type: 'price'
        },
        signal
      })
  })
);

export const gateThroughput = defineQuery(
  ({
    portfolioId,
    plantId,
    gateId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    gateId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'gate', gateId, 'throughput', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.gate.requestGateDischarge({
        portfolio_id: portfolioId,
        plant_id: plantId,
        gate_id: gateId,
        queryParams: dataRangeToBackendParameters(range, timeZone),
        signal
      })
  })
);

export const plantWaterValue = defineQuery(
  ({ portfolioId, plantId, range, timeZone }: { portfolioId: string; plantId: string; range: DataRange; timeZone: BackendTimeZone }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'water-value', timeZone, range],
    queryFn: async ({ signal }) => {
      try {
        const response = await api.dashboard.waterValue.getPlantWaterValue({
          portfolio_id: portfolioId,
          plant_id: plantId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        });

        return {
          hasNoWaterValues: false,
          ...response
        };
      } catch (error) {
        // Workaround (2023-03-30):
        // The backend returns HTTP 400 when no water values are available for a certain date range
        //
        // TODO(Leon): Check if this is still the case (2023-09-04)
        if (!(error instanceof ApiError) || error.status !== 400) {
          throw error;
        }

        const fallbackResponse: { data: TurbineWaterValues; hasNoWaterValues: boolean; metrics: Currency } = {
          data: [],
          hasNoWaterValues: true,
          metrics: {
            currency: {
              symbol: '€',
              shortName: 'EUR',
              description: 'Unknown (no values for range)'
            }
          }
        };
        return fallbackResponse;
      }
    }
  })
);

export const turbineGenerationActuals = defineQuery(
  ({
    portfolioId,
    plantId,
    turbineId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    turbineId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'turbine', turbineId, 'generation-actuals', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.power
        .requestPowerActivegrossActualWithOverrideInfo({
          portfolio_id: portfolioId,
          plant_id: plantId,
          turbine_id: turbineId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
        .then(body => body.data)
  })
);

export const turbineGenerationPlan = defineQuery(
  ({
    portfolioId,
    plantId,
    turbineId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    turbineId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'turbine', turbineId, 'generation-plan', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.power
        .requestPowerActivenetPlanWithOverrideInfo({
          portfolio_id: portfolioId,
          plant_id: plantId,
          turbine_id: turbineId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
        .then(body => body.data)
  })
);

export const plantTurbineLosses = defineQuery(
  ({ portfolioId, plantId, range, timeZone }: { portfolioId: string; plantId: string; range: DataRange; timeZone: BackendTimeZone }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'turbine-losses', timeZone, range],
    queryFn: async ({ signal }) => {
      const { granularity: _remove, ...startAndEnd } = dataRangeToBackendParameters(range, timeZone);

      const body = await api.dashboard.turbine.requestLosses({
        portfolio_id: portfolioId,
        plant_id: plantId,
        queryParams: startAndEnd,
        signal
      });
      return body.data;
    }
  })
);

export const gateDischargeActuals = defineQuery(
  ({
    portfolioId,
    plantId,
    gateId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    gateId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'gate', gateId, 'throughput-actuals', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.gate
        .requestGateDischargeActualWithOverrideInfo({
          portfolio_id: portfolioId,
          plant_id: plantId,
          gate_id: gateId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
        .then(body => body.data)
  })
);

export const gateDischargePlan = defineQuery(
  ({
    portfolioId,
    plantId,
    gateId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    gateId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'gate', gateId, 'throughput-plan', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.gate
        .requestGateDischargePlanWithOverrideInfo({
          portfolio_id: portfolioId,
          plant_id: plantId,
          gate_id: gateId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
        .then(body => body.data)
  })
);

export const reservoirLevelActuals = defineQuery(
  ({
    portfolioId,
    plantId,
    reservoirId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    reservoirId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'reservoir', reservoirId, 'level-actuals', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.reservoir
        .requestReservoirLevelActualWithOverrideInfo({
          portfolio_id: portfolioId,
          plant_id: plantId,
          reservoir_id: reservoirId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
        .then(body => body.data)
  })
);

export const reservoirLevelPlan = defineQuery(
  ({
    portfolioId,
    plantId,
    reservoirId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    reservoirId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'reservoir', reservoirId, 'level-plan', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.reservoir
        .requestReservoirLevelPlanWithOverrideInfo({
          portfolio_id: portfolioId,
          plant_id: plantId,
          reservoir_id: reservoirId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
        .then(body => body.data)
  })
);

export const hydroBodyInflowActuals = defineQuery(
  ({
    portfolioId,
    plantId,
    hydroBodyId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    hydroBodyId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'hydro-body', hydroBodyId, 'inflow-actuals', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.inflow
        .requestHydroBodyInflowActualWithOverrideInfo({
          portfolio_id: portfolioId,
          plant_id: plantId,
          hydro_body_id: hydroBodyId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
        .then(body => body.data)
  })
);

export const hydroBodyInflowPlan = defineQuery(
  ({
    portfolioId,
    plantId,
    hydroBodyId,
    range,
    timeZone
  }: {
    portfolioId: string;
    plantId: string;
    hydroBodyId: string;
    range: DataRange;
    timeZone: BackendTimeZone;
  }) => ({
    queryKey: ['portfolio', portfolioId, 'plant', plantId, 'hydro-body', hydroBodyId, 'inflow-plan', timeZone, range],
    queryFn: ({ signal }) =>
      api.dashboard.inflow
        .requestHydroBodyInflowPlanWithOverrideInfo({
          portfolio_id: portfolioId,
          plant_id: plantId,
          hydro_body_id: hydroBodyId,
          queryParams: dataRangeToBackendParameters(range, timeZone),
          signal
        })
        .then(body => body.data)
  })
);

export const strategyAdjustment = defineQuery(({ portfolioId, plantId }: { portfolioId: string; plantId: string }) => ({
  queryKey: ['portfolio', portfolioId, 'plant', plantId, 'strategyAdjustment'],
  queryFn: ({ signal }) =>
    api.dashboard.plant.requestStrategyAdjustment({ portfolio_id: portfolioId, plant_id: plantId, signal }).then(response => response.data)
}));
