/* eslint-disable @typescript-eslint/no-empty-function */

/** A promise that is only evaluated when awaited via `await` or `.then()`. */
export class LazyPromise<T> extends Promise<T> {
  #executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: unknown) => void) => void;
  #promise: Promise<T> | undefined;

  constructor(executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: unknown) => void) => void) {
    super(() => {});
    this.#executor = executor;
  }

  /**
   * Attaches callbacks for the resolution and/or rejection of the Promise.
   * @param onfulfilled The callback to execute when the Promise is resolved.
   * @param onrejected The callback to execute when the Promise is rejected.
   * @returns A Promise for the completion of which ever callback is executed.
   */
  override then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: unknown) => TResult2 | PromiseLike<TResult2>) | undefined | null
  ): Promise<TResult1 | TResult2> {
    if (!this.#promise) {
      this.#promise = new Promise(this.#executor);
    }

    return this.#promise.then(onfulfilled, onrejected);
  }

  /**
   * Attaches a callback for only the rejection of the Promise.
   * @param onrejected The callback to execute when the Promise is rejected.
   * @returns A Promise for the completion of the callback.
   */
  override catch<TResult = never>(
    onrejected?: ((reason: unknown) => TResult | PromiseLike<TResult>) | undefined | null
  ): Promise<T | TResult> {
    return this.then(null, onrejected);
  }

  /**
   * Attaches a callback that is invoked when the Promise is settled (fulfilled or rejected). The
   * resolved value cannot be modified from the callback.
   * @param onfinally The callback to execute when the Promise is settled (fulfilled or rejected).
   * @returns A Promise for the completion of the callback.
   */
  override finally(onfinally?: (() => void) | undefined | null): Promise<T> {
    return this.then(
      value => {
        onfinally?.();
        return value;
      },
      reason => {
        onfinally?.();
        throw reason;
      }
    );
  }

  /**
   * Create a new lazy promise that depends on the result of this promise.
   * Like any `LazyPromise`, the whole chain is only executed when the promise is awaited.
   */
  pipe<TResult = T>(onfulfilled?: ((value: T) => TResult | PromiseLike<TResult>) | undefined | null) {
    return new LazyPromise<TResult>((resolve, reject) => {
      this.then(value => {
        if (!onfulfilled) {
          return resolve(value as unknown as TResult);
        }
        return resolve(onfulfilled(value));
      }, reject);
    });
  }
}
