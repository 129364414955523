import { ChakraProvider } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';
import { ProvideLocaleFormat } from '../i18n/LocaleFormat';
import { ProvideLocales } from '../i18n/Locales';
import chakraTheme from '../theme/chakra/index';

export function HydrogridChakraProvider({
  children,
  forceLocale
}: PropsWithChildren<unknown> & {
  forceLocale?: string;
}) {
  return (
    <ProvideLocales forceLocale={forceLocale}>
      <ProvideLocaleFormat>
        <ChakraProvider theme={chakraTheme}>{children}</ChakraProvider>
      </ProvideLocaleFormat>
    </ProvideLocales>
  );
}
