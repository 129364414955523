import type { Environment } from '../../../config/environments';
import type { Api } from '../generated-client/Api';
import { api } from '../InsightApi';
import { defineQuery } from '../query-utils/defineQuery';

export const apiStatus = defineQuery((api: Api, environment: Environment) => ({
  queryKey: ['health', environment.slug],
  queryFn: ({ signal }) => api.health.getHealthStatus({ signal }),
  staleTime: 5 * 60_000
}));

export const portfolioList = defineQuery(() => ({
  queryKey: ['portfolios'],
  queryFn: async ({ signal }) => {
    const { data: unsortedPortfolios } = await api.dashboard.account.requestPortfolios({ signal });
    return unsortedPortfolios.sort((a, b) => a.name.localeCompare(b.name));
  }
}));

export const plantViewList = defineQuery((portfolioId: string) => ({
  queryKey: ['portfolio', portfolioId, 'plant-views'],
  queryFn: async ({ signal }) => {
    const { data: unsortedPlants } = await api.dashboard.portfolio.listPortfolioPlantViews({ portfolio_id: portfolioId, signal });
    return unsortedPlants.sort((a, b) => a.name.localeCompare(b.name));
  }
}));

export const basicArrangement = defineQuery((plantId: string) => ({
  queryKey: ['plant', plantId, 'plant-arrangement'],
  queryFn: async ({ signal }) => await api.dashboard.plant.getBasicArrangement({ plant_id: plantId, signal })
}));

export const physicalPlantList = defineQuery((portfolioId: string) => ({
  queryKey: ['portfolio', portfolioId, 'physicalPlants'],
  queryFn: async ({ signal }) => {
    const { data: unsortedPlants } = await api.dashboard.portfolio.requestPhysicalPlants({ portfolio_id: portfolioId, signal });
    return unsortedPlants.plants?.sort((a, b) => a.name.localeCompare(b.name));
  }
}));

export const plantComponentList = defineQuery((portfolioId: string, plantId: string) => ({
  queryKey: ['portfolio', portfolioId, 'plant', plantId, 'components'],
  queryFn: ({ signal }) => api.dashboard.plant.requestComponents({ portfolio_id: portfolioId, plant_id: plantId, signal }),
  enabled: Boolean(plantId)
}));

export const topology = defineQuery((portfolioId: string, plantId: string) => ({
  queryKey: ['portfolio', portfolioId, 'plant', plantId, 'topology'],
  queryFn: ({ signal }) => api.dashboard.plant.requestTopology({ portfolio_id: portfolioId, plant_id: plantId, signal }),
  enabled: Boolean(plantId)
}));

export const portfolioTopology = defineQuery((portfolioId: string) => ({
  queryKey: ['portfolio', portfolioId, 'topology'],
  queryFn: ({ signal }) => api.dashboard.portfolio.requestTopology({ portfolio_id: portfolioId, signal })
}));
