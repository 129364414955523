import { useEffect, useRef, type DependencyList, type EffectCallback } from 'react';

/**
 * Like React's built-in `useEffect`, but the callback is only called
 * when any of the dependencies change, not on the first render.
 */
export function useUpdateEffect(callback: EffectCallback, deps: DependencyList) {
  const didRunRenderCycle = useRef(false);
  const didRunEffectCycle = useRef(false);

  useEffect(() => {
    const isUpdate = didRunRenderCycle.current && didRunEffectCycle.current;

    if (isUpdate) {
      return callback();
    }

    didRunEffectCycle.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    didRunRenderCycle.current = true;
    return () => {
      didRunRenderCycle.current = false;
    };
  }, []);
}
