import { useRef } from 'react';

/**
 * This function returns `a` if `b` is deeply equal.
 * If not, it will replace any deeply equal children of `b` with those of `a`.
 * Any functions on b will be replaced by functions which can be updated
 * when this function is called again, so the function reference stays the same.
 * This can be used for structural sharing, or to use consistent objects
 * as react options when using `useMemo` gets awkward.
 *
 * Inspired by [`replaceEqualDeep`](https://github.com/TanStack/query/blob/main/packages/query-core/src/utils.ts#L218)
 * used for structural sharing in @tanstack/query.
 */
export function deepReuseWithCallbacks<T>(a: T | undefined, b: T): T {
  if (a === b || Object.is(a, b)) {
    return a as T;
  }

  const array = isPlainArray(a) && isPlainArray(b);

  if (array || (isPlainObject(a) && isPlainObject(b))) {
    const aSize = array ? a.length : Object.keys(a).length;
    const bItems = array ? b : Object.keys(b);
    const bSize = bItems.length;
    const copy = (array ? [] : {}) as T;

    let equalItems = 0;

    for (let index = 0; index < bSize; index++) {
      const key = (array ? index : bItems[index]) as keyof typeof a;
      copy[key] = deepReuseWithCallbacks(a[key], b[key]);
      if (copy[key] === a[key] || Object.is(copy[key], a[key])) {
        equalItems++;
      }
    }

    return aSize === bSize && equalItems === aSize ? a : copy;
  }

  if (typeof a === 'function' && typeof b === 'function') {
    if (isUpdatableFunction(a)) {
      a[$$updateFunction](b as typeof a);
      return a;
    } else {
      const updateable = createUpdateableFunction(b as (...args: unknown[]) => unknown);
      return updateable as T;
    }
  }

  return b;
}

const $$updateFunction = Symbol('$updateFunction');

type UpdatableFunction<T extends (...args: unknown[]) => unknown> = T & { [$$updateFunction]: (newFn: T) => void };

function createUpdateableFunction<T extends (...args: unknown[]) => unknown>(fn: T) {
  let currentFn = fn;

  const updateable = function updateable(this: unknown, ...args: unknown[]) {
    return currentFn.apply(this, args);
  } as UpdatableFunction<T>;

  const update = (newFn: T) => {
    currentFn = newFn;

    Object.defineProperty(updateable, 'name', {
      configurable: true,
      value: newFn.name
    });

    const toRemove = new Set([...Object.keys(updateable), ...Object.getOwnPropertySymbols(updateable)]);
    const toCopy = [...Object.keys(newFn), ...Object.getOwnPropertySymbols(newFn)];

    toRemove.delete($$updateFunction);

    for (const key of toCopy) {
      if (key === $$updateFunction) continue;
      toRemove.delete(key);
      (updateable as T)[key as keyof T] = newFn[key as keyof T];
    }

    for (const key of toRemove) {
      delete (updateable as T)[key as keyof T];
    }
  };

  updateable[$$updateFunction] = update;
  update(fn);

  return updateable;
}

function isUpdatableFunction(fn: unknown): fn is UpdatableFunction<(...args: unknown[]) => unknown> {
  return typeof fn === 'function' && $$updateFunction in fn;
}

function isPlainArray(value: unknown): value is unknown[] {
  return Array.isArray(value) && value.length === Object.keys(value).length;
}

// Copied from: https://github.com/jonschlinkert/is-plain-object
function isPlainObject(obj: unknown): obj is object {
  if (!hasObjectPrototype(obj)) {
    return false;
  }

  const ctor = (obj as { constructor?: unknown }).constructor;
  if (typeof ctor === 'undefined') {
    return true;
  }

  const proto = (ctor as { prototype?: unknown }).prototype;
  if (!hasObjectPrototype(proto)) {
    return false;
  }

  if (!Object.prototype.hasOwnProperty.call(proto, 'isPrototypeOf')) {
    return false;
  }

  return true;
}

function hasObjectPrototype(obj: unknown): boolean {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

/**
 * Returns the input object, and on subsequent rerenders uses as many nested values
 * of the previous object as possible, as long as they are "deep equal" to the new object.
 * Callbacks are wrapped so they are equal by reference on every returned value,
 * but update to call whatever new function was passed in.
 *
 * This can be used for structural sharing, and when passing option objects to custom hooks
 * which should be reused when possible, and using `useMemo` gets awkward.
 *
 * Inspired by [`replaceEqualDeep`](https://github.com/TanStack/query/blob/main/packages/query-core/src/utils.ts#L218)
 * used for structural sharing in @tanstack/query.
 */
export function useDeepReusedWithCallbacks<T>(obj: T): T {
  const prev = useRef<T>();
  return (prev.current = deepReuseWithCallbacks(prev.current, obj));
}
