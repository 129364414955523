import { toast } from '@hydrogrid/design-system';
import { noopAbortSignal } from '@hydrogrid/utilities/timing/abortSignal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthSessions } from '../../auth/useAuthSessions';
import { useRouteEnvironment } from '../../routing/useRouteEnvironment';
import { lastViewedPortfolioKey } from '../../storage/storageKeys';
import { api } from '../InsightApi';

export function useLogin() {
  return useMutation({
    mutationKey: ['login'],
    mutationFn: ({ username, password }: { username: string; password: string }) =>
      api.auth.getAccessToken({ security: { security: 'basicAuth', username, password }, signal: noopAbortSignal() }),
    retry: 0
  });
}

export function useMultiFactorAuth() {
  return useMutation({
    mutationKey: ['mfa'],
    mutationFn: ({ code, username, mfaSecret }: { code: string; username: string; mfaSecret: string }) =>
      api.auth.getAccessTokenFromMultiFactorAuth({
        body: { mfa_token: code },
        security: {
          security: 'basicAuthMfaSecret',
          username,
          mfaSecret
        }
      }),
    retry: 0
  });
}

export function useResendMultiFactorToken() {
  return useMutation({
    mutationKey: ['mfa', 'resend'],
    mutationFn: ({ username, mfaSecret }: { username: string; mfaSecret: string }) =>
      api.auth.resendMultiFactorToken({
        security: { security: 'basicAuthMfaSecret', username, mfaSecret },
        signal: noopAbortSignal()
      })
  });
}

export function useForgotPassword() {
  return useMutation({
    mutationKey: ['forgotPassword'],
    mutationFn: ({ email }: { email: string }) => api.auth.forgotPassword({ body: { email } })
  });
}

/** Set a new password for a not-logged-in user via a `resetToken`. */
export function useResetPassword() {
  const dontUseAuthToken = null;

  return useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: ({ newPassword, email, resetToken }: { newPassword: string; email: string | undefined; resetToken: string | undefined }) =>
      api.auth.resetPassword({ body: { new_password: newPassword, email, reset_token: resetToken }, security: dontUseAuthToken })
  });
}

/** Change the password for a logged-in user. */
export function useChangePassword() {
  return useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: ({ currentPassword, newPassword }: { currentPassword: string; newPassword: string }) =>
      api.auth.resetPassword({ body: { password: currentPassword, new_password: newPassword } })
  });
}

export function useLogoutMutation({ logoutStart, logoutFailed }: { logoutStart: () => void; logoutFailed: () => void }) {
  const queryClient = useQueryClient();
  const authSessions = useAuthSessions();
  const environment = useRouteEnvironment();

  return useMutation({
    mutationKey: ['logout'],
    mutationFn: () => api.auth.logout().raw,
    onMutate: () => logoutStart(),
    onSuccess: response => {
      if (response.ok || response.status === 401) {
        authSessions.forgetSession(environment);
        sessionStorage.removeItem(lastViewedPortfolioKey);

        // Forget all cached responses
        queryClient.clear();
      } else {
        console.warn(`Not sure if logout worked (HTTP ${response.status}): `, response);
        logoutFailed();
      }
    },
    onError: error => {
      console.error('Logout failed: ', error);
      logoutFailed();

      toast({ status: 'error', description: 'The logout failed due to an error.' });
    }
  });
}
