import type { Environment } from '../../config/environments';
import { environments } from '../../config/environments';
import { parseAccessToken, type AccessToken } from '../api/parseAccessToken';
import { lastEnvironmentKey, lastSessionKey } from '../storage/storageKeys';

type TokenPair = {
  accessToken: string;
  refreshToken: string;
};

type TokenSet = {
  accessJWT: string;
  refreshJWT: string;
  access: AccessToken;
};

class AuthSessionManager {
  private tokens: TokenSet | null = null;

  get tokenSet(): Readonly<TokenSet> | null {
    return this.tokens;
  }

  get accessToken() {
    return this.tokens?.access ?? null;
  }

  get accessJWT() {
    return this.tokens?.accessJWT ?? null;
  }

  get refreshJWT() {
    return this.tokens?.refreshJWT ?? null;
  }

  get lastEnvironment(): Environment | null {
    const lastEnvironmentSlug = sessionStorage.getItem(lastEnvironmentKey) ?? localStorage.getItem(lastEnvironmentKey);
    const matchingEnvironment = environments.find(env => env.slug === lastEnvironmentSlug);
    return matchingEnvironment ?? null;
  }

  get lastSession(): TokenPair | null {
    const env = this.lastEnvironment;
    if (!env) return null;
    return this.getLastSession(env);
  }

  getLastSession(environment: Environment): TokenPair | null {
    const storageKey = lastSessionKey(environment.slug);
    const storedSession = sessionStorage.getItem(storageKey) ?? localStorage.getItem(storageKey);
    try {
      const tokenPair = storedSession && (JSON.parse(storedSession) as unknown);
      return isValidTokenPair(tokenPair) ? tokenPair : null;
    } catch {
      return null;
    }
  }

  setTokens(tokenPair: TokenPair) {
    this.tokens = {
      accessJWT: tokenPair.accessToken,
      refreshJWT: tokenPair.refreshToken,
      access: parseAccessToken(tokenPair.accessToken)
    };
  }

  loginSuccessful({ environment, accessToken, refreshToken }: { environment: Environment } & TokenPair) {
    const env = environment.slug;
    sessionStorage.setItem(lastEnvironmentKey, env);
    localStorage.setItem(lastEnvironmentKey, env);

    this.setTokens({ accessToken, refreshToken });

    const session = JSON.stringify({ accessToken, refreshToken });
    sessionStorage.setItem(lastSessionKey(env), session);
    localStorage.setItem(lastSessionKey(env), session);
  }

  tokenRefreshed({ environment, accessToken, refreshToken }: { environment: Environment } & TokenPair) {
    this.setTokens({ accessToken, refreshToken });

    const session = JSON.stringify({ accessToken, refreshToken });
    sessionStorage.setItem(lastSessionKey(environment.slug), session);
    localStorage.setItem(lastSessionKey(environment.slug), session);
  }

  forgetSession(environment: Environment) {
    sessionStorage.removeItem(lastSessionKey(environment.slug));
    localStorage.removeItem(lastSessionKey(environment.slug));
    this.tokens = null;
  }

  forgetAllSessions() {
    const prefix = lastSessionKey('');
    removeStorageKeysWithPrefix(prefix, sessionStorage);
    removeStorageKeysWithPrefix(prefix, localStorage);
    this.tokens = null;
  }
}

const authSessionManager = new AuthSessionManager();

type AuthSessions = Omit<AuthSessionManager, 'tokens'>;
export type { AuthSessions };

export function useAuthSessions(): AuthSessions {
  return authSessionManager;
}

function isValidTokenPair(value: unknown): value is TokenPair {
  return (
    typeof value === 'object' &&
    value != null &&
    typeof (value as TokenPair).accessToken === 'string' &&
    typeof (value as TokenPair).refreshToken === 'string'
  );
}

function removeStorageKeysWithPrefix(prefix: string, storage: Storage) {
  const keysToRemove = [];

  for (let index = 0; index < storage.length; index += 1) {
    const key = storage.key(index);
    if (key != null && key.startsWith(prefix)) {
      keysToRemove.push(key);
    }
  }

  for (const key of keysToRemove) {
    storage.removeItem(key);
  }
}
