import { Children as ReactChildrenUtils, cloneElement, isValidElement, type ReactElement, type ReactNode } from 'react';
import type { IconProps } from '../../icons/defineIconComponent';
import { classNames } from '../../utils/classNames';
import styles from './Menu.module.css';

interface MenuItemIconProps {
  children?: ReactNode;
  className?: string | undefined;
}

interface IconChildProps extends IconProps {
  'aria-hidden'?: boolean;
}

export function MenuItemIcon({ children, className }: MenuItemIconProps) {
  const child = ReactChildrenUtils.only(children) as ReactElement<IconChildProps>;

  const icon = isValidElement(child)
    ? cloneElement(child, {
        'aria-hidden': true,
        className: classNames(styles.icon, child.props.className),
        size: 'font'
      })
    : child;

  return <span className={classNames(styles.iconWrapper, className)}>{icon}</span>;
}
