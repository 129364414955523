import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgEmailReceived = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 60 512 452", ref, ...props, children: [
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsx("path", { id: "envelope", d: "M467,76H45C20.238,76,0,96.149,0,121v270c0,24.86,20.251,45,45,45h422c24.762,0,45-20.149,45-45V121     C512,96.143,491.752,76,467,76z M460.09,106c-14.549,14.597-185.445,186.05-192.466,193.094c-5.864,5.882-17.381,5.886-23.248,0     L51.91,106H460.09z M30,385.485v-258.97L159.065,256L30,385.485z M51.91,406l128.334-128.752l42.885,43.025     c17.574,17.631,48.175,17.624,65.743,0l42.885-43.024L460.09,406H51.91z M482,385.485L352.935,256L482,126.515V385.485z" }),
    /* @__PURE__ */ jsx("rect", { id: "desktop-screen", x: 36, y: 90, width: 440, height: 264 }),
    /* @__PURE__ */ jsx("rect", { id: "phone-screen", x: 370, y: 228, width: 124, height: 176 }),
    /* @__PURE__ */ jsxs("mask", { id: "mask-desktop", children: [
      /* @__PURE__ */ jsx("use", { href: "#desktop-screen", fill: "#fff" }),
      /* @__PURE__ */ jsx("use", { href: "#phone-screen", fill: "#000" })
    ] }),
    /* @__PURE__ */ jsx("mask", { id: "mask-phone", children: /* @__PURE__ */ jsx("use", { href: "#phone-screen", fill: "#fff" }) })
  ] }),
  /* @__PURE__ */ jsxs("g", { style: {
    color: "var(--color-secondary, #4a5860)"
  }, fill: "currentColor", children: [
    /* @__PURE__ */ jsx("animate", { id: "appear", "data-restart": "false", attributeName: "opacity", from: 0, to: 1, begin: "0ms", dur: "200ms", fill: "freeze" }),
    /* @__PURE__ */ jsxs("g", { "aria-label": "Desktop computer monitor", transform: "translate(-16 0) scale(1.58)", children: [
      /* @__PURE__ */ jsx("path", { fill: "var(--color-page,#fff)", d: "M33.2,61.2 h278 v80 h-72 v114.6 h-206Z" }),
      /* @__PURE__ */ jsx("path", { d: "m322.222 138.889s0-36.425 0-61.111c0-7.367-2.926-14.433-8.136-19.642-5.209-5.209-12.274-8.136-19.642-8.136-53.811 0-190.633 0-244.444 0-7.367 0-14.433 2.927-19.642 8.136s-8.136 12.275-8.136 19.642v161.111c0 7.367 2.927 14.432 8.136 19.642 5.209 5.209 12.275 8.136 19.642 8.136h188.889c3.066 0 5.555-2.49 5.555-5.556s-2.489-5.555-5.555-5.555c0 0-134.668 0-188.889 0-4.42 0-8.66-1.756-11.785-4.882-3.126-3.126-4.882-7.365-4.882-11.785 0-39.523 0-121.588 0-161.111 0-4.42 1.756-8.66 4.882-11.785 3.125-3.126 7.365-4.882 11.785-4.882h244.444c4.421 0 8.66 1.756 11.786 4.882 3.125 3.125 4.881 7.365 4.881 11.785v61.111c0 3.066 2.489 5.555 5.556 5.555 3.066 0 5.555-2.489 5.555-5.555z" }),
      /* @__PURE__ */ jsx("path", { d: "m27.778 233.333h211.111c3.066 0 5.555-2.489 5.555-5.555s-2.489-5.556-5.555-5.556h-211.111c-3.066 0-5.556 2.49-5.556 5.556s2.49 5.555 5.556 5.555z" }),
      /* @__PURE__ */ jsx("path", { d: "m194.091 259.16c-.813-2.168-2.886-3.604-5.202-3.604h-33.333c-2.316 0-4.389 1.436-5.202 3.604l-16.667 44.445c-.64 1.706-.402 3.617.636 5.115 1.037 1.498 2.744 2.391 4.566 2.391h66.667c1.822 0 3.528-.893 4.566-2.391s1.275-3.409.635-5.115zm-9.052 7.507 12.5 33.333h-50.633s12.5-33.333 12.5-33.333z" }),
      /* @__PURE__ */ jsx("path", { d: "m122.222 311.111h100c3.066 0 5.556-2.489 5.556-5.555 0-3.067-2.49-5.556-5.556-5.556h-100c-3.066 0-5.555 2.489-5.555 5.556 0 3.066 2.489 5.555 5.555 5.555z" })
    ] }),
    /* @__PURE__ */ jsx("g", { "aria-label": "Email envelope in computer monitor", mask: "url(#mask-desktop)", children: /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("set", { attributeName: "opacity", to: 0, begin: 0, dur: "100ms" }),
      /* @__PURE__ */ jsx("animateTransform", { attributeName: "transform", type: "translate", from: "0 200", to: "0 0", begin: "100ms", dur: "300ms", calcMode: "spline", keySplines: ".42,0,.58,1", fill: "freeze", repeatCount: "infinity" }),
      /* @__PURE__ */ jsx("animate", { attributeName: "opacity", from: 0, to: 1, begin: "100ms", dur: "300ms" }),
      /* @__PURE__ */ jsx("use", { href: "#envelope", transform: "translate(128 100) scale(0.5)", strokeWidth: 4, stroke: "currentColor" })
    ] }) }),
    /* @__PURE__ */ jsxs("g", { "aria-label": "Smartphone", transform: "translate(-16 0) scale(1.58)", children: [
      /* @__PURE__ */ jsx("path", { fill: "var(--color-page,#fff)", d: "M244,144 h79 v146 h-79 Z" }),
      /* @__PURE__ */ jsx("path", { d: "m333.333 155.555c0-12.272-9.949-22.222-22.222-22.222h-55.556c-12.272 0-22.222 9.95-22.222 22.222v122.223c0 12.273 9.95 22.222 22.222 22.222h55.556c12.273 0 22.222-9.949 22.222-22.222zm-11.111 0v122.223c0 6.136-4.974 11.111-11.111 11.111h-55.555c-6.137 0-11.111-4.975-11.112-11.111v-122.223c.001-6.136 4.975-11.11 11.111-11.111h55.556c6.137.001 11.111 4.975 11.111 11.111z" }),
      /* @__PURE__ */ jsx("path", { d: "m275 277.778h16.667c3.066 0 5.555-2.49 5.555-5.556s-2.489-5.555-5.555-5.555h-16.667c-3.066 0-5.556 2.489-5.556 5.555s2.49 5.556 5.556 5.556z" })
    ] }),
    /* @__PURE__ */ jsx("g", { "aria-label": "Email envelope on smartphone", mask: "url(#mask-phone)", children: /* @__PURE__ */ jsxs("g", { children: [
      /* @__PURE__ */ jsx("set", { attributeName: "opacity", to: 0, begin: "0ms", dur: "300ms" }),
      /* @__PURE__ */ jsx("animateTransform", { attributeName: "transform", type: "translate", from: "0 100", to: "0 0", begin: "300ms", dur: "300ms", calcMode: "spline", keySplines: ".42,0,.58,1", fill: "freeze" }),
      /* @__PURE__ */ jsx("animate", { attributeName: "opacity", from: 0, to: 1, begin: "300ms", dur: "300ms", fill: "freeze" }),
      /* @__PURE__ */ jsx("use", { href: "#envelope", transform: "translate(385 290) scale(0.18)", strokeWidth: 30, stroke: "currentColor" })
    ] }) }),
    /* @__PURE__ */ jsx("g", { "aria-label": "Notification indicator on smartphone", children: /* @__PURE__ */ jsxs("circle", { cx: 470, cy: 310, r: 16, fill: "var(--color-primary,#0bb7a0)", strokeWidth: 10, stroke: "currentColor", children: [
      /* @__PURE__ */ jsx("set", { attributeName: "opacity", to: 0, dur: "500ms" }),
      /* @__PURE__ */ jsx("animate", { attributeName: "opacity", from: 0, to: 1, begin: "500ms", dur: "300ms", fill: "freeze" }),
      /* @__PURE__ */ jsx("animate", { attributeName: "r", from: 0, to: 16, begin: "500ms", dur: "300ms", fill: "freeze" }),
      /* @__PURE__ */ jsx("animate", { attributeName: "stroke-width", from: 0, to: 10, begin: "500ms", dur: "300ms", fill: "freeze" })
    ] }) })
  ] })
] });
const ForwardRef = forwardRef(SvgEmailReceived);
export default ForwardRef;
