import type { ImgHTMLAttributes, ReactNode } from 'react';
import { Children as ReactChildren } from 'react';
import { isRenderedIconComponent } from '../icons/defineIconComponent';

/**
 * Generates a flat string value from react children.
 * Helpful for accessibility attributes (e.g. aria-label).
 **/
export function childrenToString(children: ReactNode): string {
  return (
    ReactChildren.map(children, (child): string => {
      if (child == null || typeof child === 'symbol') {
        return '';
      } else if (typeof child !== 'object') {
        return String(child);
      } else if (!('type' in child)) {
        return '';
      }

      const props = child.props as Record<PropertyKey, unknown>;

      if (typeof props['aria-label'] === 'string') {
        return props['aria-label'];
      }

      if (isRenderedIconComponent(child) && child.props.title) {
        return child.props.title;
      }

      if (child.type === 'img') {
        return (props as ImgHTMLAttributes<HTMLImageElement>).alt ?? '';
      } else if (typeof child.type === 'string') {
        return childrenToString((child.props as { children?: ReactNode }).children);
      }

      return '';
    })?.join('') ?? ''
  );
}
