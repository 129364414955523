export interface Environment {
  slug: string;
  name: string;
  api: { api: string; scheduler: string };
  mapsApiKey: string;
  hidden?: boolean;
}

export let environments: Environment[] = [];

const dynamic: Environment = {
  name: 'Dynamic',
  slug: 'en',
  api: { api: '/api/', scheduler: '/scheduler/' },
  mapsApiKey: 'AIzaSyATdWdDg2lOoe7fhuX34gwgZcfSC37wWwU'
};

const production: Environment = {
  slug: 'eu',
  name: 'Production',
  api: { api: 'https://api.hydrogrid.ai/', scheduler: 'https://mastermind-api.hydrogrid.eu/v1' },
  mapsApiKey: 'AIzaSyATdWdDg2lOoe7fhuX34gwgZcfSC37wWwU'
};

const stagingEnvironment: Environment = {
  slug: 'staging',
  name: 'Staging',
  api: { api: 'https://staging-api.hydrogrid.ai/', scheduler: 'https://staging-mastermind-api.hydrogrid.eu/v1' },
  mapsApiKey: 'AIzaSyBys2l21KUeCmqGFtXi-hzHXZlxLQVzzH8'
};

const marvinDevEnvironment: Environment = {
  slug: 'dev',
  name: 'Development',
  api: { api: 'https://marvin-dev-api.hydrogrid.ai/', scheduler: 'https://marvin-dev-mastermind-api.hydrogrid.eu/v1' },
  mapsApiKey: 'AIzaSyB0fIkmiVq1S_uhpkhLqA8qFSOY66_XYpc'
};

const optimus: Environment = {
  slug: 'optimus',
  name: 'Optimus',
  api: { api: 'https://[namespace]-api.hydrogrid.domain/', scheduler: 'https://[namespace]-mastermind-api.hydrogrid.eu/v1' },
  mapsApiKey: 'AIzaSyB0fIkmiVq1S_uhpkhLqA8qFSOY66_XYpc'
};

const local: Environment = {
  slug: 'local',
  name: 'Local development',
  api: { api: 'http://localhost:8080/', scheduler: 'http://localhost:8080/' },
  mapsApiKey: 'AIzaSyB0fIkmiVq1S_uhpkhLqA8qFSOY66_XYpc'
};

const localProxy: Environment = {
  slug: 'proxy',
  name: 'Local proxy',
  api: { api: 'http://localhost:3000/', scheduler: 'http://localhost:3000/' },
  mapsApiKey: 'AIzaSyB0fIkmiVq1S_uhpkhLqA8qFSOY66_XYpc'
};

// Choose the available environments depending on the subdomain.
// This can also be supplied at build time by setting a VITE_DEPLOY_TARGET variable.
const domain = import.meta.env.VITE_DEPLOY_TARGET || window.location.hostname.replace(/\.hydrogrid\.ai$/, '');
if (domain === 'dynamic') {
  environments = [dynamic];
} else if (domain === 'insight') {
  environments = [production];
} else if (domain === 'staging-insight') {
  environments = [stagingEnvironment];
} else if (domain === 'marvin-dev-insight') {
  environments = [marvinDevEnvironment, stagingEnvironment];
  frontendUsesGoogleMapsKeyFor(marvinDevEnvironment);
} else if (import.meta.env.DEV || domain === 'localhost' || domain === '127.0.0.1') {
  environments = [marvinDevEnvironment, stagingEnvironment, production, local];
  if (import.meta.env.VITE_PROXY_API) {
    environments.push(localProxy);
    localProxy.name = `Local proxy (${import.meta.env.VITE_PROXY_API === 'staging' ? 'staging' : 'dev'})`;
    localProxy.api = { api: location.origin, scheduler: location.origin };
  }
  frontendUsesGoogleMapsKeyFor(local);
} else if (domain.endsWith('-frontend.hydrogrid.domain')) {
  const namespace = domain.replace('-frontend.hydrogrid.domain', '');
  optimus.api = {
    api: optimus.api.api.replace('[namespace]', namespace),
    scheduler: optimus.api.scheduler.replace('[namespace]', namespace)
  };
  optimus.name = `Optimus (${namespace})`;
  environments = [optimus, marvinDevEnvironment, stagingEnvironment];
  frontendUsesGoogleMapsKeyFor(optimus);
} else {
  environments = [production];
}

// API key for google maps depends on the frontend URL, not the backend URL
function frontendUsesGoogleMapsKeyFor({ mapsApiKey }: Environment) {
  for (const environment of environments) {
    environment.mapsApiKey = mapsApiKey;
  }
}
