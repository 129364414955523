import type { AriaRole, ReactNode } from 'react';
import { forwardRef } from 'react';
import { useScopedStyles } from '../../css/useStyles';
import { pickThemeableProps, useThemeableStyles, type ThemeableProps } from '../../css/useThemeableStyles';
import { classNames } from '../../utils/classNames';
import styles from './Stack.module.css';
import type { StackAlign, StackJustify } from './StackCssProperties';

interface StackProps extends ThemeableProps {
  children?: ReactNode;
  className?: string;
  direction: 'horizontal' | 'vertical';
  flex?: string;
  spacing?: number | string;
  align?: StackAlign;
  justify?: StackJustify;
  role?: AriaRole;
  wrap?: boolean;
}

/** A horizontal or vertical stack of items. */
export const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({ align = 'stretch', children, className, direction, flex, justify, spacing, wrap = false, ...restProps }, ref) => {
    const [themeableStyleProps, otherProps] = pickThemeableProps(restProps);
    const themeableStylesClassName = useThemeableStyles('Stack', themeableStyleProps);

    const styleablePropsClassName = useScopedStyles('Stack', {
      alignItems: align,
      gap: typeof spacing === 'number' ? `${spacing}px` : spacing,
      flex,
      flexWrap: wrap ? 'wrap' : undefined,
      justifyContent: justify
    });

    const allClassNames = classNames(styles[`stack-${direction}`], className, styleablePropsClassName, themeableStylesClassName);

    return (
      <div ref={ref} className={allClassNames} {...otherProps}>
        {children}
      </div>
    );
  }
);
