import {
  CheckIcon,
  CloseIcon,
  EqualizerIcon,
  HotkeyIcon,
  LockIcon,
  Text,
  classNames,
  useClickOutside,
  useHotkey
} from '@hydrogrid/design-system';
import { useCallback, useEffect, useRef, useState, type AnimationEvent, type MouseEvent } from 'react';
import LogoutLockAnimation from '../../assets/animations/logout-successful.svg?react';
import { useLogoutMutation } from '../../common/api/hooks/AuthDataHooks';
import { waitTimeAfterSuccessfulLogout } from '../../common/business-logic/timings';
import { AppLink } from '../../common/routing/AppLink';
import { routes } from '../../common/routing/routes';
import { useAppNavigate } from '../../common/routing/useAppNavigate';
import { useRouteEnvironment } from '../../common/routing/useRouteEnvironment';
import { useAuthStore } from '../../common/stores/AuthStore';
import { useHotkeyModal } from '../../components/HotkeyModal/HotkeyModalStateContext';
import { SettingsModal } from '../SettingsModal/SettingsModal';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import styles from './UserMenu.module.css';

export function UserMenu() {
  const { email, fullName, actions } = useAuthStore(state => ({ email: state.email, fullName: state.fullName, actions: state.actions }));
  const { logoutStart, logoutSuccess, logoutFailed } = actions;
  const containerRef = useRef<HTMLDivElement>(null);
  const environment = useRouteEnvironment();
  const navigate = useAppNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [renderMenu, setRenderMenu] = useState(false);
  const toggleMenu = useCallback(() => setIsOpen(open => !open), []);

  const [settingsVisible, setSettingsVisible] = useState(false);
  const logout = useLogoutMutation({ logoutStart, logoutFailed });

  useEffect(() => {
    if (isOpen) {
      setRenderMenu(true);
    }
  }, [isOpen]);

  useClickOutside({
    enabled: isOpen && !logout.isPending,
    ref: containerRef,
    handler: () => setIsOpen(false)
  });

  const openSettings = useCallback(() => {
    setIsOpen(false);
    setSettingsVisible(true);
  }, []);

  const hideDropdownAfterVanishAnimation = useCallback((event: AnimationEvent<HTMLUListElement>) => {
    if (event.animationName === styles.ContentVanish) {
      setRenderMenu(false);
    }
  }, []);

  const startLogout = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      logout.mutate();
    },
    [logout]
  );

  const logoutAnimationDone = useCallback(() => {
    // Late update to prevent the login overlay from showing, navigate back to login
    navigate(routes.login, { params: { environment: environment.slug } });
    logoutSuccess();
  }, [logoutSuccess, environment.slug, navigate]);

  // Allow user to skip logout animation
  useHotkey('Enter', logoutAnimationDone, { enable: logout.isSuccess });

  const backToLoginLinkClicked = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      navigate(routes.login, { params: { environment: environment.slug } });
      logoutSuccess();
    },
    [logoutSuccess, environment.slug, navigate]
  );

  const { open: showHotkeyModal } = useHotkeyModal();

  return (
    <>
      <div className={styles.container} ref={containerRef}>
        <button type="button" role="menuitem" className={styles.button} onClick={toggleMenu} aria-haspopup aria-expanded={isOpen}>
          {email ? <UserAvatar border name={fullName} email={email} /> : <div className={styles.userAvatarFallback} />}
        </button>
        {/* TODO: Move this to use the Menu component in design-system */}
        {renderMenu && (
          <ul
            role="menu"
            className={classNames(styles.menu, isOpen ? styles.menuOpen : styles.menuClosed)}
            onAnimationEnd={hideDropdownAfterVanishAnimation}
          >
            <li role="none">
              <button type="button" role="menuitem" className={styles.menuItem} onClick={openSettings}>
                <EqualizerIcon />
                Settings
              </button>
            </li>
            <li role="none">
              <button
                type="button"
                role="menuitem"
                className={styles.menuItem}
                onClick={() => {
                  showHotkeyModal();
                  setIsOpen(false);
                }}
                aria-keyshortcuts="?"
              >
                <HotkeyIcon />
                Keyboard Shortcuts
                <kbd className={styles.keyboardShortcut}>?</kbd>
              </button>
            </li>
            <li role="none">
              <button
                type="button"
                role="menuitem"
                className={classNames(styles.menuItem, logout.isPending && styles.activeLogoutButton)}
                onClick={startLogout}
              >
                {logout.isIdle && <LockIcon />}
                {logout.isPending && <LockIcon aria-label="Logging out..." />}
                {logout.isSuccess && <CheckIcon color="positive" aria-label="Logout sucessful" />}
                {logout.isError && <CloseIcon color="destructive" aria-label="Logout failed" />}
                Log Out
              </button>
            </li>
          </ul>
        )}
      </div>
      <SettingsModal visible={settingsVisible} onChangeVisible={setSettingsVisible} />
      {logout.isSuccess && (
        <div className={styles.logoutMessageOverlay}>
          <div className={styles.logoutMessagePanel}>
            <LogoutLockAnimation fill="var(--color-secondary)" className={styles.lockAnimation} />
            <div className={styles.logoutMessage}>
              <Text as="h2" color="secondary">
                You are logged out.
              </Text>
              <p>Goodbye and see you soon!</p>
              <AppLink to={routes.login} params={{ environment: environment.slug }} onClick={backToLoginLinkClicked}>
                Back to login
              </AppLink>
            </div>
            <div
              className={styles.logoutMessageProgressBar}
              style={{ animationDuration: `${waitTimeAfterSuccessfulLogout}ms` }}
              onAnimationEnd={logoutAnimationDone}
            />
          </div>
        </div>
      )}
    </>
  );
}
