import { Box, Select as ChakraSelect, Flex, Icon, type BoxProps, type SelectProps as ChakraSelectProps } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { ArrowDownIcon, CloseCrossIcon } from '../../icons';

export interface SelectProps extends Omit<ChakraSelectProps, 'onChange'> {
  onChange?: (value: string) => void;
  leftIcon?: ReactNode;
  isClearable?: boolean;
}

export function Select({ onChange, leftIcon, sx, isClearable = false, ...props }: SelectProps) {
  const { flexGrow = undefined as never, flexShrink = undefined as never, ...innerProps } = props;
  const outerProps = { flexGrow, flexShrink };
  const showClearableButton = Boolean(props.value) && isClearable && !Boolean(props.isReadOnly) && !Boolean(props.isDisabled);

  return (
    <Box lineHeight="0" pos="relative" role="group" {...outerProps}>
      <ChakraSelect
        icon={
          <>
            {showClearableButton && <ClearButton onClick={() => onChange?.('')} />}
            <Icon as={ArrowDownIcon} size="font" fontSize="sm" />
          </>
        }
        {...innerProps}
        sx={{ ...sx, ps: Boolean(leftIcon) ? 8.5 : (undefined as never) }}
        onChange={e => {
          if (Boolean(props.isReadOnly)) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }

          onChange?.(e.target.value);
        }}
      />
      {Boolean(leftIcon) && (
        <Flex top="0" pos="absolute" h="100%" w={9} justify="center" align="center">
          {leftIcon}
        </Flex>
      )}
    </Box>
  );
}

function ClearButton({ onClick }: Pick<BoxProps, 'onClick'>) {
  return (
    <Box pos="relative" pointerEvents="none" _groupHover={{ '& [data-clear-button]': { opacity: 1 } }}>
      <Flex
        zIndex={1}
        pos="absolute"
        right={1.5}
        h="100%"
        align="center"
        fontSize="xs"
        rounded="full"
        onClick={e => {
          if (document.activeElement !== e.target) return;
          onClick?.(e);
        }}
      >
        <Box
          data-clear-button
          as="button"
          type="button"
          cursor="pointer"
          pointerEvents="auto"
          p={1.5}
          bg="white"
          border="1px"
          borderColor="transparent"
          rounded="full"
          transition="opacity .2s"
          opacity={0}
          _hover={{ opacity: 1 }}
          _focusVisible={{ opacity: 1, outline: 'none', boxShadow: 'focus', borderColor: 'primary' }}
          aria-label="Clear the input field"
          sx={{ '& > *': { pointerEvents: 'none' } }}
        >
          <CloseCrossIcon size="font" />
        </Box>
      </Flex>
    </Box>
  );
}
