import { useContext, useEffect } from 'react';
import { AppTitleContext } from './AppTitle';

/**
 * Hook to set the page title.
 *
 * @example
 * ```
 * const AwesomePage = () => {
 *   usePageTitle('Awesome Page');
 *   return <p>This is an awesome page!</p>;
 * };
 * const RootComponent = () => (
 *   <AppTitle prefix="Example" suffix="Appname" separator=" - ">
 *     <AwesomePage />
 *   </PageTitlePrefix>
 * );
 *
 * // Browser tab title: "Example - Awesome Page - Appname"
 * ```
 */
export function usePageTitle(title: string) {
  const appTitle = useContext(AppTitleContext);

  useEffect(() => {
    const { prefix, separator = ' - ', suffix } = appTitle;
    const fullTitle = [prefix, title, suffix].filter(Boolean).join(separator);
    document.title = fullTitle;

    return () => {
      const fallbackTitle = [prefix, suffix].filter(Boolean).join(separator);
      document.title = fallbackTitle;
    };
  }, [appTitle, title]);
}
