import { useCallback, useEffect, useState, type ReactNode } from 'react';
import { api } from '../../common/api/InsightApi';
import { parseAccessToken, type AccessToken } from '../../common/api/parseAccessToken';
import { useAuthSessions } from '../../common/auth/useAuthSessions';
import { useRouteEnvironment } from '../../common/routing/useRouteEnvironment';
import type { AuthState } from '../../common/stores/AuthState';
import { useAuthStore } from '../../common/stores/AuthStore';
import { LoginOverlay } from '../LoginOverlay/LoginOverlay';
import RedirectToCockpit from './RedirectToCockpit';
import { TokenVerifyErrorDialog } from './TokenVerifyErrorDialog';

/**
 * Wrapper to render routes that requires authentication.
 * If the page is refreshed or opened for the first time, checks if the current session
 * is still valid, and shows a login overlay if the user needs to log back in.
 */
export function NeedsAuth({
  children,
  isStagingOnly = false,
  isAdminOnly = false
}: {
  children: ReactNode;
  isStagingOnly?: boolean;
  isAdminOnly?: boolean;
}) {
  const environment = useRouteEnvironment();
  const authStore = useAuthStore(store => {
    const state = store as AuthState;

    return {
      isAdmin: state.isAdmin,
      status: state.status,
      verifyingSession: state.verifyingSession,
      refreshingToken: state.refreshingToken,
      returningUser: state.status === 'logged-out' ? state.returningUser : null,
      actions: store.actions
    };
  });
  const { returningWithoutSession, returningWithSession } = authStore.actions;
  const authSessions = useAuthSessions();
  const [loginOverlayVisible, setLoginOverlayVisible] = useState(false);

  const [initialized, setInitialized] = useState(authStore.status === 'logged-in');
  useEffect(() => setInitialized(authStore.status === 'logged-in'), [authStore.status]);

  useEffect(() => {
    if (initialized || authStore.status !== 'unknown' || authStore.verifyingSession || authStore.refreshingToken) {
      return;
    }

    const tokenPair = authSessions.getLastSession(environment);
    if (!tokenPair) {
      returningWithoutSession();
    } else {
      let token: AccessToken;
      try {
        token = parseAccessToken(tokenPair.accessToken);
      } catch {
        console.info(`Stored session has an invalid format.`);
        returningWithoutSession();
        setInitialized(true);
        return authSessions.forgetSession(environment);
      }

      authSessions.setTokens(tokenPair);

      returningWithSession({
        email: token.userEmail,
        isAdmin: token.isAdmin
      });

      void api.checkIfStillLoggedIn();
    }

    setInitialized(true);
  }, [authSessions, authStore, environment, returningWithSession, returningWithoutSession, initialized]);

  const shouldShowLoginOverlay = authStore.status === 'logged-out' && !authStore.verifyingSession;
  useEffect(() => {
    if (shouldShowLoginOverlay) {
      setLoginOverlayVisible(true);
    }
  }, [shouldShowLoginOverlay]);

  const closeOverlay = useCallback(() => {
    setLoginOverlayVisible(false);
  }, []);

  if (isStagingOnly && environment.slug !== 'staging') return <RedirectToCockpit />;
  if (initialized && isAdminOnly && !authStore.isAdmin) return <RedirectToCockpit />;

  const initialUsername = authStore.returningUser?.email || '';
  const showTokenVerifyErrorDialog = !loginOverlayVisible && authStore.status === 'error-verifying-token';

  return (
    <>
      {initialized ? children : null}
      {loginOverlayVisible && <LoginOverlay onSuccess={closeOverlay} initialUsername={initialUsername} />}
      {showTokenVerifyErrorDialog && <TokenVerifyErrorDialog />}
    </>
  );
}
