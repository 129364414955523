import { createGlobalStore } from '@hydrogrid/utilities/stores';
import { combine } from 'zustand/middleware';
import type { AuthState } from './AuthState';

const defaultState: AuthState = {
  status: 'unknown',
  email: null,
  fullName: null,
  isAdmin: false,
  loggingIn: false,
  loggingOut: false,
  checkingMfa: false,
  verifyingSession: false,
  refreshingToken: false
};

export const useAuthStore = createGlobalStore(
  'AuthStore',
  combine(
    defaultState as AuthState,

    set => ({
      actions: {
        // User opened a tab / reopened the browser / restarted the computer with a maybe still valid session
        returningWithSession: ({ email, isAdmin }: { email: string; isAdmin: boolean }) => {
          set({
            status: 'unknown',
            email,
            fullName: null,
            isAdmin,
            loggingIn: false,
            loggingOut: false,
            checkingMfa: false,
            refreshingToken: false,
            verifyingSession: true
          });
        },
        // User opened a tab / reopened the browser / restarted the computer but has no session
        returningWithoutSession: () => {
          set({
            status: 'logged-out',
            email: null,
            fullName: null,
            isAdmin: false,
            loggingIn: false,
            loggingOut: false,
            checkingMfa: false,
            refreshingToken: false,
            verifyingSession: false
          });
        },
        errorVerifyingToken: () => {
          set({
            status: 'error-verifying-token',
            email: null,
            fullName: null,
            isAdmin: false,
            loggingIn: false,
            loggingOut: false,
            checkingMfa: false,
            refreshingToken: false,
            verifyingSession: false
          });
        },
        verifySessionStart: () => {
          set({ verifyingSession: true });
        },
        sessionStillValid: ({ email, fullName }: { email: string; fullName: string | null }) => {
          set({
            status: 'logged-in',
            email,
            fullName,
            loggingIn: false,
            checkingMfa: false,
            refreshingToken: false,
            verifyingSession: false
          });
        },
        sessionNoLongerValid: () => {
          set({
            status: 'logged-out',
            email: null,
            fullName: null,
            isAdmin: false,
            loggingOut: false,
            checkingMfa: false,
            refreshingToken: false,
            verifyingSession: false
          });
        },
        loginStart: () => {
          set({ loggingIn: true });
        },
        loginSuccess: ({ email, fullName, isAdmin }: { email: string; fullName: string | null; isAdmin: boolean }) => {
          set({
            status: 'logged-in',
            loggingIn: false,
            checkingMfa: false,
            email,
            isAdmin,
            fullName,
            refreshingToken: false
          });
        },
        loginFailed: () => {
          set({ loggingIn: false });
        },
        checkMfaStart: () => {
          set({ checkingMfa: true });
        },
        checkMfaSuccess: () => {
          set({ checkingMfa: false });
        },
        checkMfaFailed: () => {
          set({ checkingMfa: false });
        },
        refreshTokenStart: () => {
          set({ refreshingToken: true });
        },
        refreshTokenSuccess: () => {
          set({ refreshingToken: false });
        },
        refreshTokenExpired: () => {
          set(state => ({
            status: 'logged-out',
            email: null,
            fullName: null,
            loggingIn: false,
            loggingOut: false,
            checkingMfa: false,
            refreshingToken: false,
            verifyingSession: false,
            returningUser: {
              email: state.email
            },
            isAdmin: false
          }));
        },
        refreshTokenFailed: () => {
          set({ refreshingToken: false });
        },
        logoutStart: () => {
          set({ loggingOut: true });
        },
        logoutSuccess: () => {
          set({
            status: 'logged-out',
            email: null,
            fullName: null,
            isAdmin: false,
            loggingOut: false,
            checkingMfa: false,
            refreshingToken: false,
            verifyingSession: false
          });
        },
        logoutFailed: () => {
          set({ loggingOut: false });
        },
        logoutAfterPasswordChange: () => {
          set(state => ({
            status: 'logged-out',
            email: null,
            fullName: null,
            isAdmin: false,
            loggingOut: false,
            checkingMfa: false,
            refreshingToken: false,
            verifyingSession: false,
            returningUser: {
              email: state.email
            }
          }));
        }
      }
    })
  )
);
