import { Text } from '@hydrogrid/design-system';
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';

interface PropsWithQueryResult {
  query: UseQueryResult | Omit<UseMutationResult, 'mutate' | 'mutateAsync'>;
  error?: never;
}

interface PropsWithError {
  query?: never;
  error: unknown;
}

type InlineErrorProps = PropsWithQueryResult | PropsWithError;

/**
 * Displays a fallback inline error message on server errors or (unexpected!) application errors.
 *
 * @usage
 * ```tsx
 * function SomeComponent() {
 *   const someQuery = useQuery(...);
 *   return <>
 *     <ShowData {...someQuery.data} />
 *     <InlineError query={someQuery} />
 *   </>
 * }
 * ```
 *
 * @usage
 * ```tsx
 * function SomeComponent() {
 *   const [error, setError] = useState<Error>();
 *   return <>
 *     <ShowData {...someQuery.data} onFail={setError} />
 *     <InlineError error={error} />
 *   </>
 * }
 * ```
 */
export function InlineError({ query, error }: InlineErrorProps) {
  const shouldShow = query != null ? query.isError : error != null && error !== false;
  const errorObj = query?.error ?? error;
  const message = errorObj instanceof Error ? errorObj.message : String(errorObj);

  if (!shouldShow) {
    return null;
  }

  const capitalizedMessage = message.charAt(0).toUpperCase() + message.substring(1);

  return (
    <Text as="div" color="destructive" data-testid="inline-error">
      {capitalizedMessage}
    </Text>
  );
}
