import { useQuery } from '@tanstack/react-query';
import { environments, type Environment } from '../../../config/environments';
import { useRouteEnvironment } from '../../routing/useRouteEnvironment';
import type { FrontendTopology, FrontendTopologyControlUnit, FrontendTopologyHydroBody } from '../FrontendSchemas';
import { useApiForEnvironment } from '../InsightApi';
import type { TimeZone as BackendTimeZone } from '../generated-client/Schemas';
import * as queries from '../queries';
import { useCombineQueries } from '../query-utils/combineQueries';

export function useApiStatus(environment?: Environment) {
  const environmentFromRoute = useRouteEnvironment({ optional: true });
  const env = environment ?? environmentFromRoute ?? environments[0];
  const api = useApiForEnvironment(env);

  return useQuery(queries.apiStatus(api, env));
}

export function usePortfolioList() {
  return useQuery(queries.portfolioList());
}

export function usePlantViewList(portfolioId: string | undefined, { enabled = true } = {}) {
  return useQuery(queries.plantViewList(portfolioId, { enabled }));
}

export function usePhysicalPlantList(portfolioId: string | undefined, { enabled = true } = {}) {
  return useQuery(queries.physicalPlantList(portfolioId, { enabled }));
}

export function usePlantView(portfolioId: string | undefined, plantId: string | undefined, { enabled = true } = {}) {
  const plantList = useQuery(queries.plantViewList(portfolioId, { enabled: enabled && plantId != null }));
  return useCombineQueries(plantList, plants => plants.find(plant => plant.id === plantId));
}
export function useBasicArrangement(portfolioId: string | undefined, plantId: string | undefined, { enabled = true } = {}) {
  return useQuery(queries.basicArrangement(portfolioId, { enabled: enabled && plantId != null }));
}

export function usePlantTimeZone(
  portfolioId: string | undefined,
  plantId: string | undefined,
  { enabled = true } = {}
): BackendTimeZone | undefined {
  const plantList = useQuery(queries.plantViewList(portfolioId, { enabled: enabled && plantId != null }));
  return plantList.data?.find(plant => plant.id === plantId)?.timezone;
}

export function usePlantComponentList(portfolioId: string | undefined, plantId: string | undefined, { enabled = true } = {}) {
  return useQuery(queries.plantComponentList(portfolioId, plantId, { enabled }));
}

export function useFrontEndTopology(portfolioId: string, plantId: string | undefined) {
  const topologyQuery = useQuery(queries.topology(portfolioId, plantId));
  return useCombineQueries(topologyQuery, topology => {
    const control_units = topology.control_units.map((controlUnit, index): FrontendTopologyControlUnit => {
      const type = controlUnit.type.toUpperCase();
      if (!controlUnit.id) {
        console.warn(`Control unit ${index} in Topology has no id.`);
      }

      return {
        ...controlUnit,
        id: controlUnit.id ?? '',
        type: type === 'GATE' ? type : 'TURBINE',
        isPartOfPlant: controlUnit.redirect_id != null
      };
    });

    const hydro_bodies = topology.hydro_bodies.map((hydroBody, index): FrontendTopologyHydroBody => {
      const type = hydroBody.type.toUpperCase();
      if (!hydroBody.id) {
        console.warn(`Hydro body ${index} in Topology has no id.`);
      }

      let isPartOfPlant = hydroBody.redirect_id != null;
      if (type === 'DOWNSTREAM') {
        isPartOfPlant &&= hydroBody.id != null && control_units.some(unit => unit.isPartOfPlant && unit.spills_to === hydroBody.id);
      }

      return {
        ...hydroBody,
        id: hydroBody.id ?? '',
        type: type === 'DOWNSTREAM' ? type : 'RESERVOIR',
        isPartOfPlant
      };
    });

    const frontendTopology: FrontendTopology = {
      hydro_bodies,
      control_units
    };

    return frontendTopology;
  });
}

export function usePortfolioTopologies(portfolioId: string | undefined, { enabled = true } = {}) {
  return useQuery(queries.portfolioTopology(portfolioId, { enabled }));
}
