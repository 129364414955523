import { createContext, useCallback, useContext, useEffect, useRef, type ReactNode, type RefCallback } from 'react';

type ScrollAxis = 'horizontal' | 'vertical' | 'both';

interface SynchronizeScrollingManager {
  add: (element: HTMLElement, axis: ScrollAxis) => void;
  update: (element: HTMLElement, axis: ScrollAxis) => void;
  remove: (element: HTMLElement) => void;
}

const SynchronizeScrollingContext = createContext<SynchronizeScrollingManager | null>(null);

/**
 * Synchronize multiple elements in how they scroll.
 *
 * @usage
 * ```
 * function ScrollableExampleComponent() {
 *   const scrollRef = useSynchronizedScrolling('vertical');
 *   return <div ref={scrollRef} ... />
 * }
 *
 * <SynchronizeScrolling>
 *   <ScrollableExampleComponent />
 *   <ScrollableExampleComponent />
 * </ScrollableExampleComponent>
 * ```
 */
export function SynchronizeScrolling({ children }: { children: ReactNode }) {
  const initialized = useRef(false);
  const elementsRef = useRef<{ element: HTMLElement; axis: ScrollAxis }[]>([]);
  const lastScrollPositions = useRef<{ horizontal?: number | undefined; vertical?: number | undefined }>({});
  const ignoreEventsCounterRef = useRef(0);
  const animationFrameRef = useRef(Number.NaN);

  const syncScrolling = useCallback((event: Event) => {
    if (ignoreEventsCounterRef.current > 0) {
      ignoreEventsCounterRef.current -= 1;
      return;
    }

    const element = event.currentTarget as HTMLElement;
    const otherElements = elementsRef.current.filter(el => el.element !== element);
    const lastScrollPos = lastScrollPositions.current;
    const axis = elementsRef.current.find(el => el.element === element)?.axis;
    const horizontal = axis !== 'vertical';
    const vertical = axis !== 'horizontal';

    // Lock scroll events until the synchronization is done
    ignoreEventsCounterRef.current = otherElements.length;

    let percentHorizontal = 0;
    let percentVertical = 0;

    if (horizontal) {
      percentHorizontal = element.scrollLeft / (element.scrollWidth - element.offsetWidth);
      lastScrollPos.horizontal = percentHorizontal;
    }

    if (vertical) {
      percentVertical = element.scrollTop / (element.scrollHeight - element.offsetHeight);
      lastScrollPos.vertical = percentVertical;
    }

    cancelAnimationFrame(animationFrameRef.current);
    if (!otherElements.length) return;

    animationFrameRef.current = requestAnimationFrame(() => {
      for (const { axis, element } of otherElements) {
        if (horizontal && axis !== 'vertical') {
          element.scrollLeft = Math.round(percentHorizontal * (element.scrollWidth - element.offsetWidth));
        }
        if (vertical && axis !== 'vertical') {
          element.scrollTop = Math.round(percentVertical * (element.scrollHeight - element.offsetHeight));
        }
      }

      animationFrameRef.current = requestAnimationFrame(() => {
        ignoreEventsCounterRef.current = 0;
      });
    });

    // TODO(leon): Should we sync an element when it is added?
  }, []);

  const managerRef = useRef<SynchronizeScrollingManager>({
    add: (element, axis) => {
      elementsRef.current.push({ element, axis });
      element.addEventListener('scroll', syncScrolling, { passive: true });
    },
    update: (element, axis) => {
      for (const trackedElement of elementsRef.current) {
        if (trackedElement.element === element) {
          trackedElement.axis = axis;
        }
      }
    },
    remove: element => {
      element.removeEventListener('scroll', syncScrolling);

      const index = elementsRef.current.findIndex(trackedElement => trackedElement.element === element);
      if (index >= 0) {
        elementsRef.current.splice(index, 1);
      }
    }
  });

  useEffect(() => {
    if (!initialized.current) return;
    initialized.current = true;

    const elements = elementsRef.current;
    return () => {
      for (const { element } of elements) {
        element.removeEventListener('scroll', syncScrolling);
      }
    };
  }, [syncScrolling]);

  return <SynchronizeScrollingContext.Provider value={managerRef.current}>{children}</SynchronizeScrollingContext.Provider>;
}

/**
 * Synchronize multiple elements in how they scroll.
 *
 * @usage
 * ```
 * function ScrollableExampleComponent() {
 *   const scrollRef = useSynchronizedScrolling('vertical');
 *   return <div ref={scrollRef} ... />
 * }
 *
 * <SynchronizeScrolling>
 *   <ScrollableExampleComponent />
 *   <ScrollableExampleComponent />
 * </ScrollableExampleComponent>
 * ```
 */
export function useSynchronizedScrolling(axis: ScrollAxis, { enable = true } = {}) {
  const scrollManager = useContext(SynchronizeScrollingContext);
  const elementRef = useRef<HTMLElement | null>(null);
  const initialized = useRef(false);

  const axisRef = useRef(axis);
  if (axisRef.current !== axis) {
    axisRef.current = axis;

    if (elementRef.current) {
      scrollManager?.update(elementRef.current, axis);
    }
  }

  const ref: RefCallback<HTMLElement> = useCallback(
    element => {
      const previousElement = elementRef.current;
      elementRef.current = element;

      if (!scrollManager && enable) {
        throw new Error('Using useSynchronizedScrolling without a SynchronizeScrolling parent.');
      }

      if (element && enable) {
        scrollManager?.add(element, axisRef.current);
      } else if (previousElement) {
        scrollManager?.remove(previousElement);
      }
    },
    [enable, scrollManager]
  );

  useEffect(() => {
    if (!initialized.current) return;
    initialized.current = true;

    // Cleanup just in case anything was missed.
    return () => {
      if (elementRef.current && scrollManager) {
        scrollManager.remove(elementRef.current);
      }
    };
  }, [scrollManager]);

  return ref;
}
