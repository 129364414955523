import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgDownstream = (props, ref) => /* @__PURE__ */ jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: 40, height: 40, fill: "none", viewBox: "0 0 40 40", "aria-label": "Downstream", ref, ...props, children: /* @__PURE__ */ jsxs("g", { fill: "currentColor", children: [
  /* @__PURE__ */ jsx("path", { fill: "#fff", d: "M20 39.44A19.44 19.44 0 1 0 20 .56a19.44 19.44 0 0 0 0 38.88Z" }),
  /* @__PURE__ */ jsx("path", { d: "M20 1.11a18.89 18.89 0 1 1 0 37.78A18.89 18.89 0 0 1 20 1.1ZM20 0a20 20 0 1 0 0 40 20 20 0 0 0 0-40Z" }),
  /* @__PURE__ */ jsx("path", { d: "M13.33 20.99c6.67 0 6.67-.74 13.34-.74 6.66 0 6.68.92 13.3.74v-.13c-.1-.12-.3-.31-.34-.55-.02-.09.17-.15.36-.21v-.22c-6.67-.19-6.66-.74-13.33-.74-6.66 0-6.66.74-13.33.74s-6.68-.93-13.31-.74v.16c.11.18.35.45.35.58 0 .09-.18.14-.37.18v.19h.01c6.66.18 6.66.74 13.32.74ZM26.67 23.58c6.5 0 6.67.88 12.86.75l.12-.61a.53.53 0 0 1-.02-.08c0-.03.02-.06.05-.09l.06-.35c-6.42-.2-6.5-.73-13.07-.73-6.67 0-6.67.74-13.34.74-6.61 0-6.68-.91-13.18-.74l.06.44c.08.09.13.19.16.3 0 .04-.04.07-.1.1l.05.28c6.36.2 6.45.73 13.01.73 6.67 0 6.67-.74 13.34-.74ZM26.67 26.91c6.1 0 6.63.78 11.8.77.17-.39.31-.78.45-1.17-5.61-.21-5.97-.7-12.25-.7-6.67 0-6.67.73-13.34.73-6.36 0-6.66-.84-12.48-.76.12.4.25.8.4 1.17 5.45.22 5.86.7 12.08.7 6.67 0 6.67-.74 13.34-.74ZM26.67 30.25c5.34 0 6.4.6 10.05.73.26-.4.5-.8.74-1.2-4.27-.24-5.1-.64-10.8-.64-6.66 0-6.66.74-13.33.74-5.83 0-6.57-.71-11.14-.77.22.43.46.85.7 1.26 3.97.24 4.91.62 10.44.62 6.67 0 6.67-.74 13.34-.74ZM26.67 33.58c3.87 0 5.5.31 7.5.54.37-.38.73-.76 1.07-1.17-2.58-.22-4.02-.48-8.57-.48-6.67 0-6.67.74-13.34.74-4.74 0-6.12-.47-8.91-.67.39.48.8.94 1.22 1.38 2.09.2 3.68.4 7.7.4 6.66 0 6.66-.74 13.33-.74ZM26.67 36.91c1.58 0 2.79.06 3.8.13.53-.33 1.05-.68 1.55-1.05-1.32-.11-2.87-.19-5.35-.19-6.67 0-6.67.74-13.34.74-1.63.02-3.27-.06-4.9-.23.67.48 1.36.91 2.09 1.3.8.03 1.71.04 2.81.04 6.67 0 6.67-.74 13.34-.74Z" })
] }) });
const ForwardRef = forwardRef(SvgDownstream);
export default ForwardRef;
