import { useMemo, useState } from 'react';
import { hashString } from './hashString';

/**
 * Returns a 64-bit cyrb53 hash from the provided object.
 * The returned hash is equal when the input is equal-by-value,
 * even if different-by-reference.
 *
 * @example
 *
 *   const a = { type: 'fruit', name: 'Banana' };
 *   const b = { type: 'fruit', name: 'Banana' };
 *   const hashA = useHash(a);
 *   const hashB = useHash(b);
 *
 *   console.log(a === b);   // true
 */
export function useHash(value: unknown): string {
  const [cache] = useState(() => new Map<string, string>());

  const cacheKey = useMemo(() => fastStringify(value), [value]);

  const hash = useMemo(() => {
    const fromCache = cache.get(cacheKey);
    if (fromCache) {
      return fromCache;
    }

    const numericHash = hashString(cacheKey);
    const hash = numericHash.toString(36);

    cache.set(cacheKey, hash);

    return hash;
  }, [cache, cacheKey]);

  return hash;
}

function fastStringify(input: unknown): string {
  if (typeof input !== 'object' || input == null) {
    return `${typeof input}:${String(input)}`;
  }

  if (Array.isArray(input)) {
    const items = input.map(fastStringify).join(',');
    return `array:${input.length}:${items}`;
  }

  const props = Object.entries(input)
    .map(([key, value]) => `${key}:${fastStringify(value)}`)
    .join(',');
  return `object:${props}`;
}
