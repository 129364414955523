import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['alert', 'accordionItem', 'accordionButton', 'label', 'accordionPanel', 'icon', 'listItem']);

const baseStyle = helpers.definePartsStyle({
  alert: {
    transition: 'background-color 0.2s',
    p: 0
  },
  accordionItem: {
    borderTop: 0,
    borderBottom: 0,
    borderRadius: 'base'
  },
  accordionButton: {
    textAlign: 'left',
    height: 'fit-content',
    fontSize: 'sm',
    px: '4',
    py: '3',
    gap: '3',
    justifyContent: 'space-between'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: '3'
  },
  accordionPanel: {
    padding: 0,
    paddingTop: '3',
    borderTopWidth: '1px',
    py: '3',
    mx: '4'
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '3',
    marginBottom: '2'
  }
});

const variants = {
  subtle: helpers.definePartsStyle(props => {
    const { colorScheme: c } = props;

    return {
      accordionButton: {
        _hover: {
          bgColor: `${c}.100`
        }
      },
      accordionPanel: {
        borderColor: `${c}`
      },
      icon: {
        color: `${c} !important`
      }
    };
  }),
  solid: helpers.definePartsStyle(props => {
    const { colorScheme: c } = props;

    return {
      accordionButton: {
        _hover: {
          bgColor: `${c}`
        }
      },
      accordionPanel: {
        borderColor: 'white'
      }
    };
  })
};

export const alertAccordionTheme = helpers.defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'subtle',
    colorScheme: 'primary'
  }
});
