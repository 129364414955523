import { forwardRef, useContext, useMemo, type MouseEvent, type ReactElement, type ReactNode, type RefAttributes } from 'react';
import { useMatchedRoute } from './MatchedRouteContext';
import type { TypedRouteArguments } from './TypedRouteArguments';
import type { AnyTypedRoute, TypedRoute } from './TypedRoutes';

// Unfortunately, react-router does not expose the route context otherwise...
import { Link as ReactRouterLink, UNSAFE_RouteContext as ReactRouterRouteContext } from 'react-router-dom';

type AppLinkProps<T extends AnyTypedRoute> = AppLinkBaseProps<T> & TypedRouteArguments<T>;

interface AppLinkBaseProps<T extends AnyTypedRoute> {
  to: T;
  children?: ReactNode;
  className?: string | (({ isActive }: { isActive: boolean }) => string);
  replace?: boolean;
  tabIndex?: number;
  onClick?: ((event: MouseEvent<HTMLAnchorElement>) => void) | undefined;
  onMouseEnter?: ((event: MouseEvent<HTMLAnchorElement>) => void) | undefined;
  onMouseLeave?: ((event: MouseEvent<HTMLAnchorElement>) => void) | undefined;
}

interface AnyAppLinkProps
  extends AppLinkBaseProps<TypedRoute<string, Record<string, unknown>, Record<string, unknown>, Record<string, unknown>>> {
  params?: Record<string, unknown>;
  query?: Record<string, unknown>;
  state?: Record<string, unknown>;
}

// Explicit type necessary to allow using forwardRef with template component types
interface AppLinkComponentType {
  <T extends AnyTypedRoute>(props: AppLinkProps<T> & RefAttributes<HTMLAnchorElement>): ReactElement;
  displayName?: string;
}

/**
 * Creates a link to another page, with typechecked route parameters, query parameters and route state.
 *
 * @usage
 * ```
 * const icecream = defineRoute('/icecream/:flavor(strawberry|vanilla)', {
 *   query: { temparature: Number },
 *   state: (s: { fromPage?: string }) => s
 * });
 *
 * function IcecreamVan() {
 *   return <div>
 *     <AppLink to={icecream} params={{ flavor: 'strawberry' }} query={{ temparature: -2 }}>
 *       Strawberry
 *     </AppLink>
 *     <AppLink to={icecream} params={{ flavor: 'vanilla' }} query={{ temparature: -4 }} state={{ fromPage: 'van' }}>
 *       Vanilla
 *     </AppLink>
 *   </div>;
 * }
 * ```
 */
const AppLinkComponent = forwardRef<HTMLAnchorElement, AnyAppLinkProps>((props, ref) => {
  const { children, className, to: targetRoute, params = {}, query = {}, state, replace = false, ...otherProps } = props;

  const matchedRouteFromContext = useMatchedRoute();
  const matchedRoutesFromReactRouter = useContext(ReactRouterRouteContext).matches;

  const isActive = useMemo(() => {
    if (matchedRouteFromContext != null) {
      return matchedRouteFromContext === targetRoute;
    }

    return matchedRoutesFromReactRouter.some(match => match.route.path === targetRoute.path);
  }, [matchedRouteFromContext, matchedRoutesFromReactRouter, targetRoute]);

  const url = useMemo(() => {
    return targetRoute.url({
      params,
      query
    });
  }, [targetRoute, params, query]);

  const linkClassName = useMemo(() => {
    return typeof className === 'function' ? className({ isActive }) : className;
  }, [className, isActive]);

  return (
    <ReactRouterLink {...otherProps} ref={ref} to={url} state={state} replace={replace} className={linkClassName}>
      {children}
    </ReactRouterLink>
  );
});

export const AppLink = AppLinkComponent as unknown as AppLinkComponentType;
AppLink.displayName = AppLinkComponent.displayName = 'AppLink';
