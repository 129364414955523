import type { ReactNode } from 'react';
import { ProvideConfirmDialog } from '../components/Dialog/useConfirmDialog';
import { ProvidePageNavigation } from '../components/Navigation/PageNavigationContext';
import { OverlayHost } from '../components/Portal/OverlayHost';
import { ProvideFontSize } from '../css/useRem';
import { defaultTheme, type Theme } from '../theme/Theme';
import { ProvideTheme } from '../theme/ThemeProvider';

interface DesignSystemProvidersProps {
  children?: ReactNode;
  theme?: Theme;
}

/**
 * Provide most context providers for `@hydrogrid/design-system`.
 * You need to manually provide `<AppTitle>`
 */
export function DesignSystemProviders({ children, theme = defaultTheme }: DesignSystemProvidersProps) {
  return (
    <ProvidePageNavigation>
      <ProvideFontSize>
        <ProvideTheme theme={theme}>
          <ProvideConfirmDialog>
            <OverlayHost id="root">{children}</OverlayHost>
          </ProvideConfirmDialog>
        </ProvideTheme>
      </ProvideFontSize>
    </ProvidePageNavigation>
  );
}
