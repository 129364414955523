import { Box, Flex, Icon, Text, useDisclosure } from '@chakra-ui/react';
import {
  ActionRedoIcon,
  ArrowRightIcon,
  BookOpenIcon,
  BulbIcon,
  ChartIcon,
  CheckIcon,
  ClockIcon,
  DropIcon,
  EnergyIcon,
  ExclamationIcon,
  MapIcon,
  Navigation,
  NavigationAccordion,
  NavigationItem,
  NotebookIcon,
  PeopleIcon,
  PlantIcon,
  ReservesIcon,
  RocketIcon,
  Sidebar,
  UserIcon,
  WrenchIcon,
  isValidDataRange,
  useBreakpoint,
  useElementSize,
  type DataRange
} from '@hydrogrid/design-system';
import { useQuery } from '@tanstack/react-query';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { allTimeZones, type TimeZone } from '../../common/api/generated-client/Schemas';
import * as queries from '../../common/api/queries';
import { useUserPermissions } from '../../common/auth/UserPermissions';
import { useAuthSessions } from '../../common/auth/useAuthSessions';
import { DataRangeQueryParam, TimeZoneQueryParam } from '../../common/routing/QueryParamTypes';
import { routes } from '../../common/routing/routes';
import { useAppRoute } from '../../common/routing/useAppRoute';
import { useRouteEnvironment } from '../../common/routing/useRouteEnvironment';
import { lastViewedPlantInPortfolioKey } from '../../common/storage/storageKeys';
import { useSessionStorageState } from '../../common/storage/useSessionStorage';
import { useAuthStore } from '../../common/stores/AuthStore';
import { AppVersions } from '../../components/AppVersions/AppVersions';
import { NavigationBarRibbon } from '../../components/NavigationBarRibbon/NavigationBarRibbon';

export const AppNavigationBar = memo(() => {
  const authSessions = useAuthSessions();
  const pathParams = useParams<'environment' | 'portfolioId' | 'plantId'>();
  const environment = useRouteEnvironment().slug;
  const authState = useAuthStore(state => ({ email: state.email, status: state.status }));
  const showBgForVersion =
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('marvin-dev') ||
    window.location.hostname.includes('staging');

  const smallBreakpoint = useBreakpoint('small');
  const mediumBreakpoint = useBreakpoint('medium');

  const defaultPortfolioId = useMemo(() => {
    void authState.email;
    return authSessions.accessToken?.defaultPortfolio;
  }, [authSessions, authState.email]);

  const portfolioId = pathParams.portfolioId ?? defaultPortfolioId;

  const [lastViewedPlantId, saveLastViewedPlantId] = useSessionStorageState(
    undefined,
    lastViewedPlantInPortfolioKey(portfolioId ?? ''),
    (parsed): parsed is string => typeof parsed === 'string'
  );

  const plantList = useQuery(queries.plantViewList(portfolioId, { enabled: authState.status === 'logged-in' }));
  const defaultPlantId = useMemo(() => plantList.data?.at(0)?.id, [plantList.data]);

  // Parse query parameters to get "range" parameter
  const appRoute = useAppRoute('*');

  const range: DataRange | undefined = useMemo(() => {
    const paramType = appRoute.matchedRoute.queryParamTypes['range'];
    if (paramType == null) {
      return undefined;
    }

    if (paramType !== DataRangeQueryParam && paramType.relatedType !== DataRangeQueryParam) {
      return undefined;
    }

    const paramValue = appRoute.query?.['range'];
    if (paramValue != null && isValidDataRange(paramValue as DataRange)) {
      return paramValue as DataRange;
    }
  }, [appRoute]);

  const timezone: TimeZone | undefined = useMemo(() => {
    const paramType = appRoute.matchedRoute.queryParamTypes['timezone'];
    if (paramType == null) {
      return undefined;
    }

    if (paramType !== TimeZoneQueryParam && paramType.relatedType !== TimeZoneQueryParam) {
      return undefined;
    }

    const paramValue = appRoute.query?.['timezone'];
    if (typeof paramValue === 'string' && allTimeZones.includes(paramValue as TimeZone)) {
      return paramValue as TimeZone;
    }
  }, [appRoute]);

  const direction = smallBreakpoint.above ? 'vertical' : 'horizontal';

  // this should be used on pages where we want to restore the last interacted plant id, but don't fall back to the default one (e.g. cockpit or watervalue)
  const lastInteractedPlantId = pathParams.plantId ?? lastViewedPlantId;
  // this should be used on pages where a plant is mandatory (e.g. constraints)
  const mandatoryPlantId = lastInteractedPlantId ?? defaultPlantId;

  const isOnAdminPage = /\/(admin)\//.test(appRoute.matchedRoute.path);
  const isOnOperationsPage = /\/(operations)\//.test(appRoute.matchedRoute.path);
  const plantsUrl = portfolioId ? routes.plants.url({ params: { environment, portfolioId } }) : undefined;

  const isOnCockpit = /\/(map|cockpit)\//.test(appRoute.matchedRoute.path);
  const cockpitUrl = portfolioId
    ? routes.cockpitMap.url({
        params: { environment, portfolioId, plantId: isOnCockpit || isOnAdminPage ? undefined : lastInteractedPlantId }
      })
    : undefined;

  const portfolioUrl = portfolioId
    ? routes.portfolioOverview.url({ params: { environment, portfolioId }, query: { range, timezone } })
    : undefined;

  const isOnWaterValue = appRoute.matchedRoute.path.includes('water-value');
  const waterValueUrl = portfolioId
    ? routes.waterValue.url({
        params: { environment, portfolioId, plantId: isOnWaterValue || isOnAdminPage ? undefined : lastInteractedPlantId }
      })
    : undefined;

  const isOnReportingPage = appRoute.matchedRoute.path.includes('reporting');
  const reportingUrl = (() => {
    if (!portfolioId) return undefined;
    if (lastInteractedPlantId && !isOnReportingPage && !isOnAdminPage) {
      return routes.plantReporting.url({
        params: { environment, portfolioId, plantId: lastInteractedPlantId }
      });
    }
    return routes.portfolioReporting.url({ params: { environment, portfolioId } });
  })();

  const constraintsUrl = portfolioId
    ? routes.constraints.url({ params: { environment, portfolioId, plantId: isOnAdminPage ? undefined : mandatoryPlantId } })
    : undefined;

  const reservesUrl = portfolioId ? routes.reserves.url({ params: { environment, portfolioId } }) : undefined;

  const maintenancesUrl = portfolioId
    ? routes.maintenances.url({
        params: { environment, portfolioId }
        // TODO: We want a different range here than on the cockpit 🤔
        // query: { range }
      })
    : undefined;

  const liveDataUrl = portfolioId
    ? routes.liveData.url({
        params: { environment, portfolioId }
      })
    : undefined;

  const actionsUrl = routes.actions.url({ params: { environment } });

  const canPlantBeSelectedOnCurrentPage = appRoute.matchedRoute.pathParamNames.includes('plantId');
  useEffect(() => {
    // The portfolio and plant view of the reporting page are in two different routes (portfolioReporting and plantReporting)
    if (!canPlantBeSelectedOnCurrentPage && !isOnReportingPage) {
      return;
    }

    saveLastViewedPlantId(pathParams.plantId);
  }, [canPlantBeSelectedOnCurrentPage, isOnAdminPage, isOnReportingPage, pathParams.plantId, saveLastViewedPlantId]);

  const [isMenuCollapsed, setIsMenuCollapsed] = useSessionStorageState(false, 'menuCollapsed');
  const collapse = () => {
    if (isMenuCollapsed) {
      setIsMenuCollapsed(false);
    } else {
      setIsMenuCollapsed(true);
    }
  };

  const adminAccordionDisclosure = useDisclosure({
    defaultIsOpen: isOnAdminPage
  });

  const operationsAccordionDisclosure = useDisclosure({
    defaultIsOpen: isOnOperationsPage
  });

  const [isScrollBarVisible, setIsScrollbarVisible] = useState(false);
  const navigationRef = useRef<HTMLUListElement>(null);
  const navigationHeight = useElementSize(navigationRef)?.height;
  const footerRef = useRef<HTMLDivElement>(null);
  const footerHeight = useElementSize(footerRef)?.height;
  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarHeight = useElementSize(sidebarRef)?.height;

  useEffect(() => {
    if (navigationHeight != null && footerHeight != null && sidebarHeight != null && navigationHeight + footerHeight >= sidebarHeight) {
      setIsScrollbarVisible(true);
    } else {
      setIsScrollbarVisible(false);
    }
  }, [navigationHeight, footerHeight, sidebarHeight]);

  const { plan, manageUsers, editCustomers, managePortfolios, viewActions, viewLiveData } = useUserPermissions();
  const viewOperations = viewActions && viewLiveData;

  const appVersionsBgColor = (environment: string) => {
    if (environment === 'staging') return 'info.600';
    if (environment === 'eu') return 'error';
    if (environment === 'dev') return 'auxilliary4.400';
    return {};
  };

  return (
    <Sidebar
      ref={sidebarRef}
      direction={direction}
      footer={
        mediumBreakpoint.above && (
          <Box ref={footerRef}>
            {showBgForVersion ? (
              <Flex bgColor={appVersionsBgColor(environment)} align="center" justify="center" color="white" minH={10} px={3}>
                <Icon as={environment === 'eu' ? ExclamationIcon : CheckIcon} />
                {!isMenuCollapsed && <AppVersions color="white" fontWeight="bold" />}
              </Flex>
            ) : (
              !isMenuCollapsed && <AppVersions color="secondary.700" />
            )}

            <Box as="button" w="100%" color="white" py={3} px={4} bg="contrast.800" onClick={collapse} minH={11}>
              <Flex justify={isMenuCollapsed ? 'center' : 'space-between'} align="center" onClick={collapse}>
                {!isMenuCollapsed && (
                  <Text fontWeight="bold" color="white">
                    {' '}
                    Collapse{' '}
                  </Text>
                )}
                <Icon
                  as={ArrowRightIcon}
                  transform={`rotate(${isMenuCollapsed ? 0 : 180}deg)`}
                  transition="transform 0.2s"
                  w={2.5}
                  size="small"
                />
              </Flex>
            </Box>
          </Box>
        )
      }
      isMenuCollapsed={isMenuCollapsed}
    >
      <Navigation direction={direction} ref={navigationRef}>
        <NavigationItem icon={<MapIcon />} to={cockpitUrl} isActive={isOnCockpit} isMenuCollapsed={isMenuCollapsed}>
          Cockpit
        </NavigationItem>
        <NavigationItem icon={<BookOpenIcon />} to={portfolioUrl} isMenuCollapsed={isMenuCollapsed}>
          Portfolio
        </NavigationItem>
        <NavigationItem icon={<DropIcon />} to={waterValueUrl} isMenuCollapsed={isMenuCollapsed}>
          Water Value{' '}
          {plan != 'plus' && (
            <NavigationBarRibbon
              textContent="new"
              isMenuCollapsed={isMenuCollapsed}
              isScrollBarVisible={isScrollBarVisible}
              ribbonColor="primary.700"
            />
          )}
        </NavigationItem>
        <NavigationItem icon={<ChartIcon />} to={reportingUrl} isMenuCollapsed={isMenuCollapsed}>
          Reporting
        </NavigationItem>
        <NavigationItem icon={<ClockIcon />} to={constraintsUrl} isMenuCollapsed={isMenuCollapsed}>
          Constraints
        </NavigationItem>
        <NavigationItem icon={<Icon as={ReservesIcon} fill="none !important" />} to={reservesUrl} isMenuCollapsed={isMenuCollapsed}>
          Reserves <NavigationBarRibbon textContent="demo" isMenuCollapsed={isMenuCollapsed} isScrollBarVisible={isScrollBarVisible} />
        </NavigationItem>

        <NavigationItem icon={<WrenchIcon />} to={maintenancesUrl} isMenuCollapsed={isMenuCollapsed}>
          Maintenance{' '}
          {plan != 'plus' && (
            <NavigationBarRibbon textContent="trial" isMenuCollapsed={isMenuCollapsed} isScrollBarVisible={isScrollBarVisible} />
          )}
        </NavigationItem>
        {viewOperations && (
          <NavigationAccordion isMenuCollapsed={isMenuCollapsed} label="Operations" icon={<BulbIcon />} {...operationsAccordionDisclosure}>
            {viewLiveData && (
              <NavigationItem icon={<EnergyIcon />} to={liveDataUrl} isMenuCollapsed={isMenuCollapsed}>
                Live Data
              </NavigationItem>
            )}
            {viewActions && (
              <NavigationItem icon={<ActionRedoIcon />} to={actionsUrl} isMenuCollapsed={isMenuCollapsed}>
                Actions
                <NavigationBarRibbon
                  textContent="new"
                  isMenuCollapsed={isMenuCollapsed}
                  isScrollBarVisible={isScrollBarVisible}
                  ribbonColor="primary.700"
                />
              </NavigationItem>
            )}
          </NavigationAccordion>
        )}
        <NavigationAccordion isMenuCollapsed={isMenuCollapsed} label="Administration" icon={<RocketIcon />} {...adminAccordionDisclosure}>
          <NavigationItem icon={<PlantIcon />} to={plantsUrl} isMenuCollapsed={isMenuCollapsed}>
            Plants
            <NavigationBarRibbon
              textContent="new"
              isMenuCollapsed={isMenuCollapsed}
              isScrollBarVisible={isScrollBarVisible}
              ribbonColor="primary.700"
            />
          </NavigationItem>
          {managePortfolios && (
            <NavigationItem
              icon={<NotebookIcon />}
              to={routes.portfolios.url({ params: { environment } })}
              isMenuCollapsed={isMenuCollapsed}
            >
              Portfolios
              <NavigationBarRibbon
                textContent="new"
                isMenuCollapsed={isMenuCollapsed}
                isScrollBarVisible={isScrollBarVisible}
                ribbonColor="primary.700"
              />
            </NavigationItem>
          )}
          {editCustomers && (
            <NavigationItem icon={<PeopleIcon />} to={routes.customers.url({ params: { environment } })} isMenuCollapsed={isMenuCollapsed}>
              Customers
              <NavigationBarRibbon
                textContent="new"
                isMenuCollapsed={isMenuCollapsed}
                isScrollBarVisible={isScrollBarVisible}
                ribbonColor="primary.700"
              />
            </NavigationItem>
          )}
          {manageUsers && (
            <NavigationItem icon={<UserIcon />} to={routes.users.url({ params: { environment } })} isMenuCollapsed={isMenuCollapsed}>
              Users
              <NavigationBarRibbon
                textContent="new"
                isMenuCollapsed={isMenuCollapsed}
                isScrollBarVisible={isScrollBarVisible}
                ribbonColor="primary.700"
              />
            </NavigationItem>
          )}
        </NavigationAccordion>
      </Navigation>
    </Sidebar>
  );
});
