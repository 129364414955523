import { createStrictContext } from '@hydrogrid/utilities/react';
import { useCallback, useState, type ReactNode } from 'react';
import { ConfirmDialog, type ConfirmDialogProps } from './ConfirmDialog';

interface ConfirmDialogState {
  setConfirmDialogProps: (props: ConfirmDialogProps | null) => void;
}

const [useContext, ProvideContext] = createStrictContext<ConfirmDialogState>('ConfirmDialogContext');

export function ProvideConfirmDialog({ children }: { children: ReactNode }) {
  const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

  return (
    <ProvideContext value={{ setConfirmDialogProps }}>
      {children}
      {confirmDialogProps !== null && <ConfirmDialog {...confirmDialogProps} />}
    </ProvideContext>
  );
}

/**
 * Enables to deal with Confirm Dialogs globally in a promise-based way.
 *
 * Usage:
 * const { confirmDialog } = useConfirmDialog();
 *
 * <Button
 *   onClick={() => {
 *     if (await confirmDialog({ title: 'Title', description: 'Description' })) {
 *       // here you can put the logic after the user successfuly confirmed the action
 *       // won't come here if the user cancels or closes the ConfirmDialog
 *     }
 *   }}
 * >
 *   Confirm
 * </Button>
 */
export function useConfirmDialog() {
  const contextValue = useContext();
  const { setConfirmDialogProps } = contextValue;

  const confirmDialog = useCallback(
    async (props: Omit<ConfirmDialogProps, 'onConfirm' | 'onClose'>) => {
      return new Promise<boolean>(resolve => {
        setConfirmDialogProps({
          ...props,
          onConfirm: () => {
            setConfirmDialogProps(null);
            resolve(true);
          },
          onClose: () => {
            setConfirmDialogProps(null);
            resolve(false);
          }
        });
      });
    },
    [setConfirmDialogProps]
  );

  return { confirmDialog };
}
