import type { RefetchOptions, RefetchQueryFilters, UseQueryResult } from '@tanstack/react-query';
import { useCallback, useEffect, useRef } from 'react';

export type CombinedQuery<TData = unknown, TError = Error, TAllData extends unknown[] = unknown[]> = UseQueryResult<TData, TError> & {
  readonly anyArePending: boolean;
  readonly allArePending: boolean;
  readonly anyAreError: boolean;
  readonly allAreError: boolean;
  readonly anyAreSuccess: boolean;
  readonly allAreSuccess: boolean;
  readonly successAmount: number;
  readonly numberOfQueries: number;

  readonly allData: TAllData;
  readonly allErrors: TError[];

  /** Only refetch failed queries */
  refetchFailed: (options?: RefetchOptions & RefetchQueryFilters) => Promise<CombinedQuery<TData, TError, TAllData>>;
};

type FlatOrNestedQueryArray = readonly (UseQueryResult | UseQueryResult[])[];

type CombinedQueryLastData<T extends FlatOrNestedQueryArray, TError = Error> = CombinedQuery<
  DataTypeOfLastQueryInQueriesArray<T>,
  TError,
  DataTypeOfQueriesArray<T> & unknown[]
>;

type Last<T extends readonly unknown[]> = T extends readonly [...unknown[], infer TLast] ? TLast : never;

type DataTypeOfLastQueryInQueriesArray<T extends FlatOrNestedQueryArray> =
  Last<T> extends UseQueryResult<infer TData, unknown>
    ? TData
    : Last<T> extends readonly UseQueryResult<infer TData, unknown>[]
      ? TData[]
      : never;

type DataTypeOfQueriesArray<T extends FlatOrNestedQueryArray> = T extends [...infer Tuple]
  ? {
      [K in keyof Tuple]: Tuple[K] extends UseQueryResult<infer TData, unknown>
        ? TData
        : Tuple[K] extends UseQueryResult<unknown>[]
          ? DataTypeOfQueriesArray<Tuple[K]>
          : never;
    }
  : never;

const pickLast = (...results: unknown[]) => results[results.length - 1];

/** Combine multiple queries, with the `data` of the returned result equal to the `data` of the last passed query. */
export function useCombineQueries<T extends FlatOrNestedQueryArray>(...queries: T): CombinedQueryLastData<T>;

/** Combine multiple queries with a mapper function that decides how to combine the `data` of the returned result from the `data` of the passed queries. */
export function useCombineQueries<T extends FlatOrNestedQueryArray, R>(
  ...args: [...queries: T, combineFunction: (...args: DataTypeOfQueriesArray<T> & unknown[]) => R]
): CombinedQuery<R, Error>;

export function useCombineQueries(...args: unknown[]): CombinedQuery {
  let queries: FlatOrNestedQueryArray = [];
  let combiner = pickLast;

  const lastArg = args[args.length - 1] as ((...args: unknown[]) => unknown) | undefined;
  if (typeof lastArg === 'function') {
    queries = args.slice(0, args.length - 1) as FlatOrNestedQueryArray;
    combiner = args[args.length - 1] as (...args: unknown[]) => unknown;
  } else {
    queries = args as FlatOrNestedQueryArray;
  }

  const prevQueries = useRef<FlatOrNestedQueryArray>();
  const combinedRef = useRef<CombinedQuery>();
  const updateCombinerRef = useRef<(combiner: (...data: unknown[]) => unknown) => void>(() => {});
  let combined: CombinedQuery;

  if (
    prevQueries.current?.length !== queries.length ||
    !combinedRef.current ||
    queries.some((query, index) => prevQueries.current?.[index] !== query)
  ) {
    prevQueries.current = queries;

    const [combinedQuery, _updateQueries, updateCombiner] = createCombinedQuery(queries, combiner);

    combined = combinedRef.current = combinedQuery;
    updateCombinerRef.current = updateCombiner;
  } else {
    prevQueries.current = queries;
    combined = combinedRef.current;
  }

  useEffect(() => {
    updateCombinerRef.current(combiner);
  }, [combiner]);

  const refetchRef = useRef(combined.refetch);
  refetchRef.current = combined.refetch;
  const refetch: (typeof combined)['refetch'] = useCallback(options => {
    return refetchRef.current(options);
  }, []);

  return {
    ...combined,
    refetch
  };
}

/**
 * Combines multiple queries from `@tanstack/react-query` into a single query.
 *
 * Properties are calculated on an "assumed-that-is-what-you-mean" basis, e.g.:
 * - `isPending` is true if at least one query has the status `"pending"`
 * - `isError` is true if any query has the status `"error"`
 * - `isSuccess` is true if all queries have the status `"success"`
 * - `failureCount` is the sum of the `failureCount` of all queries
 * - `dataIsUpdated` is the newest timestamp of `dataIsUpdated` of all queries
 * - `data` gives you a combination of `query.data` depending on the passed `mapFn`
 */
function createCombinedQuery(queriesArray: FlatOrNestedQueryArray, mapFn: (...data: unknown[]) => unknown) {
  let queries = queriesArray;
  let flatQueries = queriesArray.flat();
  let mappingFunction = mapFn;

  // Create a single combined query that combines all passed queries
  const combinedQuery = {
    get anyArePending() {
      return flatQueries.some(query => query.status === 'pending');
    },

    get allArePending() {
      return flatQueries.every(query => query.status === 'pending');
    },

    get anyAreError() {
      return flatQueries.some(query => query.status === 'error');
    },

    get allAreError() {
      return flatQueries.every(query => query.status === 'error');
    },

    get anyAreSuccess() {
      return flatQueries.some(query => query.status === 'success');
    },

    get allAreSuccess() {
      return flatQueries.every(query => query.status === 'success');
    },

    get successAmount() {
      return flatQueries.filter(query => query.isSuccess === true).length;
    },

    get numberOfQueries() {
      return flatQueries.length;
    },

    get allData() {
      // Map queries to their .data, query arrays to every .data of their elements, e.g.
      //   if queries = [Query<Continent>, Query<Country>[], Query<City>[]]
      //   mappedData = [Continent, Country[], City[]]
      return queries.map(query => {
        return Array.isArray(query) ? query.map(nestedQuery => nestedQuery.data) : query.data;
      });
    },

    get data() {
      if (flatQueries.some(query => !query.isSuccess)) {
        return undefined;
      }

      return mappingFunction(...this.allData);
    },

    get dataUpdatedAt() {
      return flatQueries.reduce((max, query) => Math.max(max, query.dataUpdatedAt), 0);
    },

    get error() {
      return flatQueries.find(query => query.error)?.error ?? null;
    },

    get allErrors() {
      return flatQueries.some(query => query.error !== null) ? flatQueries.map(query => query.error) : null;
    },

    get errorUpdateCount() {
      return flatQueries.reduce((sum, query) => sum + query.errorUpdateCount, 0);
    },

    get errorUpdatedAt() {
      return flatQueries.reduce((max, query) => Math.max(max, query.errorUpdatedAt), 0);
    },

    get failureCount() {
      return flatQueries.reduce((sum, query) => sum + query.failureCount, 0);
    },

    get failureReason() {
      return flatQueries.some(query => query.failureReason !== null) ? flatQueries.map(query => query.failureReason) : null;
    },

    get fetchStatus() {
      return flatQueries.some(query => query.fetchStatus === 'fetching')
        ? 'fetching'
        : flatQueries.some(query => query.fetchStatus === 'paused')
          ? 'paused'
          : 'idle';
    },

    get isError() {
      return this.status === 'error';
    },

    get isFetched() {
      return flatQueries.every(query => query.isFetched);
    },

    get isFetchedAfterMount() {
      return flatQueries.every(query => query.isFetchedAfterMount);
    },

    get isFetching() {
      return this.fetchStatus === 'fetching';
    },

    get isInitialLoading() {
      return flatQueries.some(query => query.isInitialLoading);
    },

    get isPending() {
      return this.status === 'pending';
    },

    get isLoading() {
      return this.isPending && this.isFetching;
    },

    get isLoadingError() {
      return flatQueries.some(query => query.isLoadingError);
    },

    get isPaused() {
      return this.fetchStatus === 'paused';
    },

    get isPlaceholderData() {
      return flatQueries.some(query => query.isPlaceholderData);
    },

    get isRefetchError() {
      return this.status === 'error' && flatQueries.some(query => query.isRefetchError);
    },

    get isRefetching() {
      return this.fetchStatus === 'fetching' && this.status !== 'pending';
    },

    get isStale() {
      return flatQueries.some(query => query.isStale);
    },

    get isSuccess() {
      return this.status === 'success';
    },

    get status(): UseQueryResult['status'] {
      return flatQueries.some(query => query.status === 'pending')
        ? 'pending'
        : flatQueries.some(query => query.status === 'error')
          ? 'error'
          : 'success';
    },

    refetch: async options => {
      await Promise.all(flatQueries.map(query => query.refetch(options)));
      return combinedQuery;
    },

    refetchFailed: async options => {
      const failedQueries = flatQueries.filter(query => query.status === 'error');
      await Promise.all(failedQueries.map(query => query.refetch(options)));
      return combinedQuery;
    }
  } as CombinedQuery;

  const updateQueries = (newQueries: UseQueryResult[]) => {
    queries = newQueries;
    flatQueries = newQueries.flat();
  };

  const updateMappingFunction = (mapFn: (...data: unknown[]) => unknown) => {
    mappingFunction = mapFn;
  };

  return [combinedQuery, updateQueries, updateMappingFunction] as const;
}
