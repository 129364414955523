import type { PickEventHandlers } from '@hydrogrid/utilities/typescript';
import { forwardRef, memo, useCallback, type ReactElement } from 'react';
import type { FormInputProps } from '../../../shared-types/FormInputProps';
import { classNames } from '../../../utils/classNames';
import { Popover } from '../../Popover/Popover';
import styles from './TextInput.module.css';

type TextInputProps = TextFieldTextInputProps | PasswordFieldTextInputProps;

type TextInputEventHandlers = PickEventHandlers<
  'input',
  'FocusEvent' | 'ClipboardEvent' | 'KeyboardEvent' | 'MouseEvent' | 'SelectionEvent'
>;

interface TextInputBaseProps extends FormInputProps, TextInputEventHandlers {
  className?: string | undefined;
  disabled?: boolean | undefined;
  iconLeft?: ReactElement | null;
  iconRight?: ReactElement | null;
  id?: string | undefined;
  label?: string;
  name?: string;
  maxLength?: number;
  placeholder?: string;
  readOnly?: boolean;

  /**
   * - `true` for a red outline
   * - `string` for a popover error message
   *
   * @see {@link showError}
   */
  error?: boolean | string | null;

  /**
   * - `"without-focus"` - show red outline, hide when input has focus _(default)_
   * - `"with-focus"` - only show red outline when input has focus
   * - `"always"` - show red outline regardless of focus
   *
   * @default "without-focus"
   */
  showError?: 'without-focus' | 'with-focus' | 'always';

  value?: string;
  variant?: 'default' | 'borderless' | 'subtle';
  onChange?: (newValue: string) => void;
}

interface TextFieldTextInputProps extends TextInputBaseProps {
  type?: 'text';
}

interface PasswordFieldTextInputProps extends TextInputBaseProps {
  passwordrules?: string;
  type: 'password';
}

export const TextInput = memo(
  forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
    const {
      className,
      disabled = false,
      iconLeft,
      iconRight,
      label,
      type = 'text',
      variant = 'default',
      error,
      showError = 'without-focus',
      onChange,
      ...otherProps
    } = props;

    const handleChange = useCallback(
      (event: React.FormEvent<HTMLInputElement>) => {
        onChange?.(event.currentTarget.value);
      },
      [onChange]
    );

    return (
      <div
        role="group"
        className={classNames(
          styles.formField,
          disabled ? styles.disabled : styles.enabled,
          styles[`formField-variant-${variant}`],
          styles[`formField-variant-${variant}-${disabled ? 'disabled' : 'enabled'}`],
          styles[`show-errors-${showError}`],
          Boolean(error) && styles[`formField-variant-${variant}-error`],
          className
        )}
      >
        {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}
        <input
          ref={ref}
          type={type}
          className={styles.formControl}
          disabled={disabled}
          aria-label={label}
          aria-invalid={Boolean(error)}
          {...otherProps}
          onChange={handleChange}
        />
        {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
        {typeof error === 'string' && !!error && (
          <Popover align="right" color="destructive" borderColor="destructive">
            {error}
          </Popover>
        )}
      </div>
    );
  })
);
