import { createContext, useContext, useEffect, useState, type ReactNode } from 'react';

function getBodyFontSize() {
  const size = window.getComputedStyle(document.body).getPropertyValue('font-size');
  return Number.parseFloat(size.replace('px', ''));
}

const FontSizeContext = createContext(16);

export function ProvideFontSize({ children }: { children?: ReactNode }) {
  const [fontSize, setFontSize] = useState(getBodyFontSize);

  useEffect(() => {
    const element = document.createElement('div');
    element.style.position = 'absolute';
    element.style.left = '-10000px';
    element.style.height = '0px';
    element.style.width = '10rem';
    element.style.visibility = 'hidden';
    element.ariaHidden = 'true';
    document.body.appendChild(element);

    const observer = new ResizeObserver(() => {
      setFontSize(getBodyFontSize());
    });
    observer.observe(element);

    return () => {
      observer.unobserve(element);
      observer.disconnect();
      element.remove();
    };
  }, []);

  return <FontSizeContext.Provider value={fontSize}>{children}</FontSizeContext.Provider>;
}

/** Returns the pixel size of 1rem. */
export function useRem() {
  return useContext(FontSizeContext);
}
