import { ApiError } from './ApiError';
import { LazyPromise } from './LazyPromise';

export interface TypedResponse<T> extends Response {
  data?: T;
  error?: unknown;
  json(): Promise<T>;
  clone(): TypedResponse<T>;
}

interface ReadonlyRequestArgs {
  readonly method: string;
  readonly path: string;
  readonly queryParams: { readonly [key: string]: string | number } | undefined;
}

type ResponseBodyType = 'arrayBuffer' | 'blob' | 'formData' | 'json' | 'text';

/**
 * A promise for raw responses or response bodies, with support for cancellation.
 *
 * @example
 * ```tsx
 * const response = new ResponsePromise(() => fetch('/some/resource'));
 *
 * // Awaits the response body. On non-successful HTTP status, the returned promise is rejected.
 * const body1 = await response;
 * // Same, but explicit.
 * const body2 = await response.body;
 *
 * // Awaits the response with headers, status, etc. Supports streaming and any other low-level API.
 * try {
 *   const rawResponse = await response.raw;
 *   if (rawResponse.ok) {
 *     // Response was successful
 *   } else {
 *     // Response was successful, but with a non-successful HTTP status
 *   }
 * } catch (error) {
 *   // Response failed due to a connection error (e.g. no internet, CORS)
 * }
 * ```
 */
export class ResponsePromise<T> extends LazyPromise<T> {
  public readonly request: ReadonlyRequestArgs;

  #rawPromise: Promise<TypedResponse<T>>;
  #bodyPromise: Promise<T> | undefined;
  #responseBodyType: ResponseBodyType;

  constructor(executor: () => Promise<TypedResponse<T>>, responseBodyType: ResponseBodyType, request: ReadonlyRequestArgs) {
    super((resolve, reject) => {
      this.body.then(resolve, reject);
    });

    this.request = Object.freeze({
      method: request.method ?? 'GET',
      path: request.path,
      queryParams: request.queryParams && Object.freeze({ ...request.queryParams })
    });

    this.#rawPromise = new LazyPromise((resolve, reject) => {
      executor().then(resolve, reject);
    });
    this.#responseBodyType = responseBodyType;
  }

  get body(): Promise<T> {
    if (!this.#bodyPromise) {
      this.#bodyPromise = this.#rawPromise.then(async response => {
        let body: T;
        if (response.status === 204 || !this.#responseBodyType || (response.body === null && !response.ok)) {
          body = null as unknown as T;
        } else {
          try {
            body = (await response[this.#responseBodyType]()) as T;
          } catch {
            throw new ApiError({ request: this.request, response, error: new Error('Invalid JSON in response body.') });
          }
        }

        if (response.ok) {
          return (response.data = body);
        } else {
          throw (response.error = new ApiError({ request: this.request, response, responseBody: body }));
        }
      });
    }

    return this.#bodyPromise;
  }

  get raw() {
    return this.#rawPromise;
  }

  /**
   * Create a new {@link ResponsePromise} that pipes the response body through a piping function.
   * Aborting the returned promise aborts `this`, and vice-versa.
   */
  override pipe<TResult = T>(onfulfilled?: ((value: T) => TResult | PromiseLike<TResult>) | null | undefined) {
    const pipedPromise = new ResponsePromise<TResult>(
      async () => {
        const raw = await this.#rawPromise;
        const piped = raw.clone();

        const bodyMethods = ['arrayBuffer', 'blob', 'formData', 'json', 'text'] as const;
        const mappedMethods = piped as unknown as Record<(typeof bodyMethods)[number], () => Promise<unknown>>;

        for (const method of bodyMethods) {
          const original = mappedMethods[method];
          mappedMethods[method] = async function (this) {
            const result = (await original.call(this)) as T;
            return onfulfilled ? onfulfilled(result) : result;
          };
        }

        return piped as TypedResponse<unknown> as TypedResponse<TResult>;
      },
      this.#responseBodyType,
      this.request
    );

    if (this.#bodyPromise) {
      pipedPromise.#bodyPromise = this.#bodyPromise.then(body => (onfulfilled ? onfulfilled(body) : body)) as Promise<TResult>;
    }

    return pipedPromise;
  }
}
