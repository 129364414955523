/**
 * Error thrown when the token couldn't be verified.
 * (e.g. because the backend throws an error or the user has no internet connection)
 */
export class TokenVerifyError extends Error {
  static get [Symbol.species]() {
    return TokenVerifyError;
  }

  get [Symbol.toStringTag]() {
    return 'TokenVerifyError';
  }

  constructor() {
    super('The auth token could not be verified.');

    // Fix the "Error" prototype chain in case we compile to older-than-ES2015
    if (!(this instanceof TokenVerifyError)) {
      Object.setPrototypeOf(this, new.target.prototype);
    }

    // Set the correct stack trace
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, TokenVerifyError);
    }

    this.name = 'TokenVerifyError';
  }
}
