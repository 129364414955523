import type { PickEventHandlers } from '@hydrogrid/utilities/typescript';
import { forwardRef, memo, useCallback, useEffect, useLayoutEffect, useRef, type AriaAttributes, type ReactElement } from 'react';
import { pickThemeableProps, useThemeableStyles, type ThemeableProps } from '../../../css/useThemeableStyles';
import { useElementSize } from '../../../dom/useElementSize';
import type { FormInputAriaRole } from '../../../shared-types/FormInputProps';
import { classNames } from '../../../utils/classNames';
import { useCombinedRefs } from '../../../utils/useCombinedRefs';
import { Popover } from '../../Popover/Popover';
import styles from './TextArea.module.css';

type TextAreaEventHandlers = PickEventHandlers<
  'textarea',
  'FocusEvent' | 'ClipboardEvent' | 'KeyboardEvent' | 'MouseEvent' | 'SelectionEvent'
>;

export type LegacyTextAreaProps = TextAreaPropsWithMinMaxLines | TextAreaPropsWithLines;

interface TextAreaPropsWithMinMaxLines extends TextAreaBaseProps {
  lines?: never;
  minLines?: number;
  maxLines?: number;
}

interface TextAreaPropsWithLines extends TextAreaBaseProps {
  lines: number;
  minLines?: never;
  maxLines?: never;
}

interface TextAreaBaseProps extends ThemeableProps, TextAreaEventHandlers, AriaAttributes {
  className?: string | undefined;
  disabled?: boolean;
  error?: boolean | string | null;
  showError?: 'without-focus' | 'always';
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  id?: string | undefined;
  label?: string;
  name?: string | undefined;
  placeholder?: string;
  readOnly?: boolean;
  role?: FormInputAriaRole;
  variant?: 'default' | 'borderless';
  value?: string;
  maxLength?: number;
  onChange?: (newValue: string) => void;
}

export const LegacyTextArea = memo(
  forwardRef<HTMLTextAreaElement, LegacyTextAreaProps>((props, ref) => {
    const [
      themeableProps,
      {
        className,
        iconLeft,
        iconRight,
        label,
        lines: linesProp,
        minLines: minLinesProp,
        maxLines: maxLinesProp,
        value,
        variant = 'default',
        onChange,
        error,
        showError = 'without-focus',
        ...otherProps
      }
    ] = pickThemeableProps(props);

    const themableStylesClassName = useThemeableStyles('TextArea', themeableProps);

    const minLines = minLinesProp ?? linesProp;
    const maxLines = maxLinesProp ?? linesProp;

    const groupRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>();

    const restoreStateAfterRender = useRef<() => void>();
    const restoreStateAnimationFrame = useRef(Number.NaN);

    const restoreStateAfterLayoutShift = useCallback(() => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      const { selectionDirection, selectionStart, selectionEnd } = textarea;

      const restoreState = () => {
        textarea.selectionDirection = selectionDirection;
        textarea.selectionStart = selectionStart;
        textarea.selectionEnd = selectionEnd;

        restoreStateAfterRender.current = undefined;
        cancelAnimationFrame(animationFrame);
      };

      restoreStateAfterRender.current = restoreState;
      const animationFrame = requestAnimationFrame(restoreState);
      restoreStateAnimationFrame.current = animationFrame;
    }, []);

    useLayoutEffect(() => {
      restoreStateAfterRender.current?.();
    });

    useEffect(() => {
      return () => {
        restoreStateAfterRender.current = undefined;
        cancelAnimationFrame(restoreStateAnimationFrame.current);
        restoreStateAnimationFrame.current = Number.NaN;
      };
    }, []);

    const adjustHeightToContent = useCallback(() => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      textarea.style.setProperty('--textarea--min-lines', String(minLines ?? 1));
      textarea.style.setProperty('--textarea--max-lines', String(maxLines ?? 1000));

      const shouldAutoResize = minLines !== maxLines || minLines === undefined;

      if (shouldAutoResize) {
        restoreStateAfterLayoutShift();

        textarea.style.height = '3px';

        const autoHeight = textarea.scrollHeight;
        textarea.style.setProperty('--textarea--auto-height', String(autoHeight));

        textarea.style.removeProperty('height');
        textarea.style.removeProperty('max-height');
        textarea.style.removeProperty('min-height');
      }
    }, [maxLines, minLines, restoreStateAfterLayoutShift]);

    const groupWidth = useElementSize(groupRef.current)?.width;

    const handleInput = useCallback(
      (event: React.FormEvent<HTMLTextAreaElement>) => {
        onChange?.(event.currentTarget.value);
        adjustHeightToContent();
      },
      [adjustHeightToContent, onChange]
    );

    useLayoutEffect(() => {
      // Resize when any prop changed that can affect layout
      void value;
      void minLines;
      void maxLines;
      void className;
      void iconLeft;
      void iconRight;
      void groupWidth;

      adjustHeightToContent();
    }, [adjustHeightToContent, value, groupWidth, className, iconLeft, iconRight, minLines, maxLines]);

    const previousMinLines = useRef(minLines);
    useLayoutEffect(() => {
      const minLinesReduced = minLines != null && previousMinLines.current != null && minLines <= previousMinLines.current;
      if (minLinesReduced) {
        previousMinLines.current = minLines;

        const animationFrame = requestAnimationFrame(adjustHeightToContent);
        return () => cancelAnimationFrame(animationFrame);
      }
    }, [adjustHeightToContent, minLines]);

    const combinedClassNames = classNames(
      styles.formField,
      styles[`formField-variant-${variant}`],
      themableStylesClassName,
      styles[`show-errors-${showError}`],
      Boolean(error) && styles[`formField-variant-${variant}-error`],
      className
    );

    return (
      <div role="group" ref={groupRef} className={combinedClassNames}>
        {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}
        <textarea
          ref={useCombinedRefs(ref, textareaRef)}
          className={styles.formControl}
          aria-label={label}
          value={value}
          {...otherProps}
          onInput={handleInput}
        />
        {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
        {typeof error === 'string' && !!error && (
          <Popover align="right" color="destructive" borderColor="destructive">
            {error}
          </Popover>
        )}
      </div>
    );
  })
);
