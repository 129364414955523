import type { ReactNode } from 'react';
import styles from './Breadcrumb.module.css';

interface BreadcrumbProps {
  as?: 'p' | 'span' | 'div' | 'em' | 'strong' | 'small' | 'big' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: ReactNode;
}

export function Breadcrumb({ as: TagName = 'div', children }: BreadcrumbProps) {
  return <TagName className={styles.breadcrumb}>{children}</TagName>;
}

Breadcrumb.isBreadcrumb = true;
