import { Box, TableContainer as ChakraTableContainer, type TableContainerProps as ChakraTableContainerProps } from '@chakra-ui/react';
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { combineRefs } from '../../utils/useCombinedRefs';
import { TableProviders, useTableContext } from './TableContext';
import { useTableStore } from './TableStore';

const SCROLL_SHADOW_TOLERANCE_IN_PX = 6;

export type TableContainerProps = ChakraTableContainerProps & {
  rowCount: number;
  columnTemplate: string;
  isHeaderSticky?: boolean;
  isFirstColumnSticky?: boolean;
  isFooterSticky?: boolean;
};

export const TableContainer = forwardRef<HTMLDivElement, TableContainerProps>(function TableContainer(
  { rowCount, columnTemplate, isHeaderSticky = false, isFirstColumnSticky = false, isFooterSticky = false, children, ...props },
  ref
) {
  return (
    <TableProviders contextValue={{ rowCount, columnTemplate, isHeaderSticky, isFirstColumnSticky, isFooterSticky }}>
      <TableContainerContent ref={ref} {...props}>
        {children}
      </TableContainerContent>
    </TableProviders>
  );
});

const TableContainerContent = forwardRef<HTMLDivElement, ChakraTableContainerProps>(function TableContainerContent(
  { children, onScroll, ...props },
  ref
) {
  const { rowCount, isFooterSticky } = useTableContext();
  const { setActiveShadows } = useTableStore(state => state.actions);
  const showShadowBottom = useTableStore(state => state.activeShadows.bottom) && !isFooterSticky;

  const tableContainerRef = useRef<HTMLDivElement>();
  const recalculateShadows = useCallback(() => {
    const containerElement = tableContainerRef.current;
    if (!containerElement) {
      return;
    }

    setActiveShadows({
      top: containerElement.scrollTop > 0,
      bottom:
        Math.ceil(containerElement.scrollTop) <
        containerElement.scrollHeight - containerElement.offsetHeight - SCROLL_SHADOW_TOLERANCE_IN_PX,
      left: containerElement.scrollLeft > 0,
      right:
        Math.ceil(containerElement.scrollLeft) < containerElement.scrollWidth - containerElement.offsetWidth - SCROLL_SHADOW_TOLERANCE_IN_PX
    });
  }, [setActiveShadows]);

  useEffect(() => {
    recalculateShadows();
  }, [rowCount, recalculateShadows]);

  return (
    <ChakraTableContainer
      overflowY="auto"
      pos="relative"
      h="100%"
      ref={combineRefs(ref, tableContainerRef)}
      onScroll={e => {
        recalculateShadows();
        onScroll?.(e);
      }}
      transition="scrollbar-width .2s"
      sx={{
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgb(0 0 0 / 30%) transparent'
      }}
      {...props}
    >
      {children}

      {showShadowBottom && (
        <Box
          aria-hidden="true"
          pointerEvents="none"
          display="block"
          pos="sticky"
          mt={-2}
          left={0}
          bottom={-2}
          height={2}
          zIndex={2}
          bg="secondary.100"
          width="100%"
          boxShadow="0px -1px 12px var(--chakra-colors-secondary-400)"
        />
      )}
    </ChakraTableContainer>
  );
});
