import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar('modal-bg');
const $shadow = cssVar('modal-shadow');

const baseStyleOverlay = defineStyle({
  bg: 'blackAlpha.700',
  zIndex: 'modal'
});

const baseStyleDialogContainer = defineStyle(props => {
  const { isCentered, scrollBehavior } = props;

  return {
    display: 'flex',
    zIndex: 'modal',
    justifyContent: 'center',
    alignItems: Boolean(isCentered) ? 'center' : 'flex-start',
    overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto',
    overscrollBehaviorY: 'none'
  };
});

const baseStyleDialog = defineStyle(props => {
  const { isCentered, scrollBehavior } = props;

  return {
    border: '1px',
    borderColor: 'secondary.100',
    borderRadius: 'md',
    color: 'inherit',
    my: Boolean(isCentered) ? 'auto' : '16',
    mx: Boolean(isCentered) ? 'auto' : (undefined as never),
    zIndex: 'modal',
    maxH: scrollBehavior === 'inside' ? 'calc(100% - 7.5rem)' : (undefined as never),
    [$bg.variable]: 'colors.white',
    [$shadow.variable]: 'shadows.lg',
    _dark: {
      [$bg.variable]: 'colors.gray.700',
      [$shadow.variable]: 'shadows.dark-lg'
    },
    bg: $bg.reference,
    boxShadow: $shadow.reference
  };
});

const baseStyleHeader = defineStyle({
  pl: '5',
  pr: '12',
  py: '5',
  pb: '6',
  textStyle: 'bodyLg',
  fontWeight: 'bold'
});

const baseStyleCloseButton = defineStyle({
  position: 'absolute',
  top: '4',
  insetEnd: '4'
});

const baseStyleBody = defineStyle(props => {
  const { scrollBehavior } = props;
  return {
    px: '5',
    pb: '5',
    flex: '1',
    overflow: scrollBehavior === 'inside' ? 'auto' : undefined,
    textStyle: 'bodyLg'
  };
});

const baseStyleFooter = defineStyle({
  px: '5',
  pb: '5',
  pt: '1'
});

const baseStyle = definePartsStyle(props => ({
  overlay: baseStyleOverlay,
  dialogContainer: baseStyleDialogContainer(props),
  dialog: baseStyleDialog(props),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody(props),
  footer: baseStyleFooter
}));

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string) {
  if (value === 'full') {
    return definePartsStyle({
      dialog: {
        maxW: '100vw',
        minH: '$100vh',
        my: '0',
        borderRadius: '0'
      }
    });
  }

  let maxW = '400px';
  switch (value) {
    case 'lg':
      maxW = '600px';
      break;
    case 'xl':
      maxW = '1000px';
      break;
    case '2xl':
      maxW = '1400px';
      break;
  }

  return definePartsStyle({
    dialog: { maxW }
  });
}

const sizes = {
  sm: getSize('sm'),
  lg: getSize('lg'),
  xl: getSize('xl'),
  '2xl': getSize('2xl'),
  full: getSize('full')
};

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: { size: 'sm' }
});
