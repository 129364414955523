import { useEffect, useRef, type RefObject } from 'react';

interface UseClickOutsideOptions {
  handler: ((evet: MouseEvent) => void) | undefined;

  ref: Element | RefObject<Element> | null | undefined;

  event?: 'click' | 'mousedown' | 'pointerdown';

  /** @default true */
  enabled?: boolean;
}

/**
 * Utility function to react when a user clicks outside an element.
 *
 * @usage
 * ```tsx
 * const contentDiv = useRef<HTMLDivElement>(null);
 * // Or, even better, and required in a React.memo() component:
 * // const [contentDiv, setContentDiv] = useState<HTMLDivElement | null>(null);
 *
 * useClickOutside(contentDiv, () => closeOverlay());
 *
 * return <div ref={contentDiv}>
 *   Hello there!
 * </div>;
 * ```
 */
export function useClickOutside({ enabled = true, ref, handler, event = 'click' }: UseClickOutsideOptions) {
  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  const refOfRefOrElement = useRef(ref);
  useEffect(() => {
    refOfRefOrElement.current = ref;
  }, [ref]);

  useEffect(() => {
    if (!enabled) return;

    let wasOutsideInBubblePhase: boolean | undefined;

    const getContainer = () => {
      const element = refOfRefOrElement.current;
      return element == null ? element : 'current' in element ? element.current : element;
    };

    const handleClickInBubblePhaseToDetectRemovedElements = (event: MouseEvent) => {
      const container = getContainer();
      const clickedElement = event.target;

      if (clickedElement instanceof Node && container && clickedElement.isConnected) {
        wasOutsideInBubblePhase = !container.contains(clickedElement);
      } else {
        wasOutsideInBubblePhase = undefined;
      }
    };

    const handleClickCapture = (event: MouseEvent) => {
      const container = getContainer();
      const clickedElement = event.target;

      if (!(clickedElement instanceof Node) || !handlerRef.current) return;

      let isOutside = container != null && !container.contains(clickedElement);
      if (!clickedElement.isConnected) {
        // If the target element was removed because of this click event, we check
        // if it was outside (in the "bubble" event phase) before being removed.
        isOutside = wasOutsideInBubblePhase ?? isOutside;
      }

      if (isOutside) {
        handlerRef.current(event);
      }
    };

    document.addEventListener(event, handleClickInBubblePhaseToDetectRemovedElements, true);
    document.addEventListener(event, handleClickCapture, false);

    return () => {
      document.removeEventListener(event, handleClickInBubblePhaseToDetectRemovedElements, true);
      document.removeEventListener(event, handleClickCapture, false);
    };
  }, [event, enabled]);
}
