import { useCallback, useContext, useMemo, useRef } from 'react';
import { useInRouterContext, useLocation, useNavigate, useParams, type NavigateOptions, type To } from 'react-router-dom';
import { useMatchedRoute } from './MatchedRouteContext';
import type { RelativeRouteArguments, TypedRouteArguments } from './TypedRouteArguments';
import type {
  AnyTypedRoute,
  TypedRoutePathParams,
  TypedRouteQueryParamsIn,
  TypedRouteQueryParamsOut,
  TypedRouteState,
  UnknownTypedRoute
} from './TypedRoutes';
import type { AppNavigateOptions } from './useAppNavigate';

// Unfortunately, react-router does not expose the route context otherwise...
import { UNSAFE_RouteContext as ReactRouterRouteContext } from 'react-router-dom';

/**
 * Allows to get or set the state of the current route.
 *
 * Returned by {@link useAppRoute}.
 */
export interface UseAppRouteResult<T extends AnyTypedRoute> {
  /**
   * A reference of the matched route.
   * Can be compared to the expected `TypedRoute` if multiple possible routes were passed to `useAppRoute`.
   */
  readonly matchedRoute: T;

  /** The path name of the current location. */
  readonly path: string;

  /** Path parameters of the current location for the matched route. */
  readonly params: Readonly<TypedRoutePathParams<T>>;

  /** Query parameters of the current location for the matched route. */
  readonly query: Readonly<TypedRouteQueryParamsOut<T>>;

  /** Route state of the current location. */
  readonly state: Readonly<TypedRouteState<T>> | undefined;

  /**
   * Set some or all path parameters of the current route.
   *
   * Replaces the current history entry by default, pass `{ replace: false }` to create a new history entry.
   */
  setParams: UseAppRouteSetParams<T>;

  /**
   * Set some or all query parameters of the current route.
   *
   * Replaces the current history entry by default, pass `{ replace: false }` to create a new history entry.
   */
  setQuery: UseAppRouteSetQuery<T>;

  /**
   * Set the current route state for the current route.
   *
   * Replaces the current history entry by default, pass `{ replace: false }` to create a new history entry.
   */
  setState: UseAppRouteSetState<T>;

  /**
   * Set all arguments of the current route.
   *
   * Replaces the current history entry by default, pass `{ replace: false }` to create a new history entry.
   */
  setAll: UseAppRouteSetAll<T>;

  /**
   * Navigate to a different route. Reuses current route arguments when possible.
   *
   * Creates a new history entry by default, pass `{ replace: true }` to replace the current entry.
   */
  navigate: UseAppRouteNavigate<T>;
}

/** See {@link UseAppRouteResult.setParams}. */
type UseAppRouteSetParams<T extends AnyTypedRoute> = (
  pathParams: ObjectOrReducer<Readonly<TypedRoutePathParams<T>>>,
  options?: AppNavigateOptions
) => void;

/** See {@link UseAppRouteResult.setQuery}. */
type UseAppRouteSetQuery<T extends AnyTypedRoute> = (
  queryParams: ObjectOrReducer<Readonly<TypedRouteQueryParamsOut<T>>, Readonly<TypedRouteQueryParamsIn<T>>>,
  options?: AppNavigateOptions
) => void;

/** See {@link UseAppRouteResult.setState}. */
type UseAppRouteSetState<T extends AnyTypedRoute> = (
  state: ObjectOrReducer<Readonly<TypedRouteState<T>>>,
  options?: AppNavigateOptions
) => void;

/**
 * See {@link UseAppRouteResult.setAll}.
 *
 * Can either be passed a full hash of route arguments (params, query, state - depending on the route),
 * or a reducer function that derives new route arguments from the current ones (similar to useState).
 */
type UseAppRouteSetAll<T extends AnyTypedRoute> = {
  (routeArgs: Readonly<TypedRouteArguments<T>>, options?: AppNavigateOptions): void;
  (
    updaterFn: (args: {
      readonly params: Readonly<TypedRoutePathParams<T>>;
      readonly query: Readonly<TypedRouteQueryParamsOut<T>>;
      readonly state: Readonly<TypedRouteState<T>>;
    }) => Readonly<TypedRouteArguments<T>>,
    options?: AppNavigateOptions
  ): void;
};

/** Most app route methods support the `useState`-like pattern of either passing the new value or a reducer that calculates it. */
type ObjectOrReducer<TCurrentState, TNewState = TCurrentState> = ((prev: TCurrentState | undefined) => TNewState) | TNewState;
type AnyObjectOrReducer = Record<string, unknown> | ((prev: Record<string, unknown>) => Record<string, unknown>);

/**
 * See {@link UseAppRouteResult.navigate}.
 */
type UseAppRouteNavigate<Source extends AnyTypedRoute> = <Target extends AnyTypedRoute>(
  to: Target,
  ...args: [...args: RelativeRouteArguments<Source, Target>, options?: AppNavigateOptions]
) => void;

/**
 * Match a specific app route.
 * If the current page does not match the passed route, the hook will throw.
 *
 * @usage
 * ```tsx
 * routes.cockpitPlantDetails = defineRoute(
 *   '/:environment/cockpit/:portfolioId/:plantId/plant',
 *   {
 *     query: {
 *       range: optional(DataRange),
 *       edit: optional(EditState),
 *     },
 *     state: (state?: { chartScrolledTo?: { from: number, to: number } }) => state
 *   }
 * ),
 *
 * const route = useAppRoute(routes.cockpitPlantDetails);
 * route.matchedRoute // === routes.cockpitPlantDetails
 * route.path         // "/dev/cockpit/portfolio1/plant1/plant"
 * route.params       // { environment: "dev", portfolioId: "portfolio1", plantId: "plant1" }
 * route.query        // { range?: DataRange, edit?: EditState }
 * route.state        // { chartScrolledTo?: { from: number, to: number } }
 *
 * route.setParams({ environment?, portfolioId?, plantId? })
 * route.setParams(params => ({ environment, portfolioId, plantId }))
 * route.setParams(..., { replace: false })
 *
 * route.setQuery({ range?, edit? });
 * route.setQuery(query => ({ range?, edit? }));
 * route.setQuery(..., { replace: false });
 *
 * route.setState({ chartScrolledTo? });
 * route.setState(state => ({ chartScrolledTo? }));
 * route.setState(..., { replace: false });
 *
 * route.setAll({
 *   params: { environment?, portfolioId?, plantId? },
 *   query: { range?, edit? },
 *   state: { chartScrolledTo? }
 * });
 * route.setAll(current => ({
 *   params: { environment, portfolioId, plantId },
 *   query: { range?, edit? },
 *   state: { chartScrolledTo? }
 * }));
 * route.setAll(..., { replace: false });
 *
 * route.navigate(otherRoute, { ... args for other route ... });
 * ```
 */
export function useAppRoute<T extends AnyTypedRoute>(route: T): UseAppRouteResult<T>;

/**
 * Match the current route against an array of accepted routes.
 * If the current page is not matched by any of the passed routes, the hook will throw.
 */
export function useAppRoute<T1 extends AnyTypedRoute, T2 extends AnyTypedRoute[]>(
  route: T1,
  ...otherRoutes: T2
): UseAppRouteResult<T1 | T2[number]>;

/**
 * Match the current route against any defined app routes.
 * This is only intended for utility hooks, as the calling code has to handle a lot of cases manually.
 */
export function useAppRoute(route: '*'): UseAppRouteResult<UnknownTypedRoute>;

export function useAppRoute(...routes: AnyTypedRoute[] | ['*']): UseAppRouteResult<AnyTypedRoute> {
  const isInsideRouter = useInRouterContext();
  if (!isInsideRouter) {
    throw new Error('useAppRoute called in a component that is not within a <Router>.');
  }

  // We keep refs of all "external" values so we can use them in useCallback-callbacks
  // without the function reference changing with the data (the refs are not in the dependency array).

  const currentRouteFromContext = useMatchedRoute();
  const currentRouteFromReactRouter = useContext(ReactRouterRouteContext);
  const currentRouteRef = useRef(currentRouteFromReactRouter);
  currentRouteRef.current = currentRouteFromReactRouter;

  const routerNavigate = useNavigate();
  const navigateRef = useRef(routerNavigate);
  navigateRef.current = routerNavigate;

  const location = useLocation();
  const locationRef = useRef(location);
  locationRef.current = location;

  const state: unknown = location.state;

  const params = useParams();
  const pathParamsRef = useRef(params);
  pathParamsRef.current = params;

  const matchedRoute = useMemo(() => {
    if (isStarRoute(routes)) {
      return currentRouteFromContext;
    }

    if (!currentRouteFromContext) {
      return routes.find(route => currentRouteFromReactRouter.matches.some(match => match.route.path === route.path));
    } else if (routes.includes(currentRouteFromContext)) {
      return currentRouteFromContext;
    }

    const currentPathParams = JSON.stringify(currentRouteFromContext.pathParams);
    const currentQueryParams = Object.keys(currentRouteFromContext.queryParamTypes).join('§§');

    return routes.find(
      route =>
        route.path === currentRouteFromContext.path &&
        Object.keys(route.queryParamTypes).join('§§') === currentQueryParams &&
        JSON.stringify(route.pathParams) === currentPathParams
    );
  }, [currentRouteFromContext, currentRouteFromReactRouter.matches, routes]);

  if (!matchedRoute) {
    throw new Error('useAppRoute used inside a component but no route matches.');
  }

  const matchedRouteRef = useRef(matchedRoute);
  matchedRouteRef.current = matchedRoute;

  const path = location.pathname;

  const prevSearchParamsRef = useRef<Record<string, string[]>>();
  const prevSearchParamsRouteRef = useRef<AnyTypedRoute>();
  const prevSearchRef = useRef<Record<string, unknown>>({});

  const query = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    const searchParams: Record<string, string[]> = {};
    const search: Record<string, unknown> = {};

    const prevSearch = matchedRoute === prevSearchParamsRouteRef.current ? prevSearchParamsRef.current : undefined;

    for (const [name, paramType] of Object.entries(matchedRoute.queryParamTypes)) {
      const prev = prevSearch?.[name];
      const next = urlSearchParams.getAll(name);
      searchParams[name] = next;

      if (!next.length) {
        // A query parameter has no value
        continue;
      }

      if (!prev || next.length !== prev.length || next.some((value, key) => !(key in prev) || prev[key] !== value)) {
        // A query parameter or query parameters have changed, run the serializer
        search[name] = paramType.deserialize(next[0], name, urlSearchParams);
      } else {
        // A query parameter or query parameters stayed the same, keep the previous value
        search[name] = prevSearchRef.current[name];
      }
    }

    prevSearchParamsRouteRef.current = matchedRoute;
    prevSearchParamsRef.current = searchParams;
    prevSearchRef.current = search;

    return search;
  }, [location.search, matchedRoute]);

  const queryRef = useRef(query);
  queryRef.current = query;

  const setParams = useCallback((newPathParams: AnyObjectOrReducer, { replace = true }: { replace?: boolean } = {}) => {
    const pathParams = typeof newPathParams === 'function' ? newPathParams(pathParamsRef.current) : newPathParams;

    // We reuse the current query parameters & state, so they do not need to be serialized/deserialized again
    const to: To = {
      ...locationRef.current,
      pathname: matchedRouteRef.current.formatPath(pathParams)
    };

    navigateRef.current(to, { replace });
  }, []);

  const setQuery = useCallback((newQueryParams: AnyObjectOrReducer, { replace = true }: { replace?: boolean } = {}) => {
    const queryParams = typeof newQueryParams === 'function' ? newQueryParams(queryRef.current) : newQueryParams;

    // We reuse the current path parameters & state
    const to: To = {
      ...locationRef.current,
      search: matchedRouteRef.current.formatQueryString(queryParams)
    };
    navigateRef.current(to, { replace });
  }, []);

  const setState = useCallback((newRouteState: AnyObjectOrReducer, { replace = true }: { replace?: boolean } = {}) => {
    const state = typeof newRouteState === 'function' ? newRouteState(locationRef.current.state as Record<string, unknown>) : newRouteState;
    // We reuse the current path parameters & state
    navigateRef.current(locationRef.current, { state, replace });
  }, []);

  const setAll = useCallback((routeArgs: AnyObjectOrReducer, { replace = true }: { replace?: boolean } = {}) => {
    const currentLocation = locationRef.current;
    const matchedRoute = matchedRouteRef.current;

    const prevArgs = { params: pathParamsRef.current ?? {}, query: queryRef.current, state: currentLocation.state as unknown };
    const nextArgs = typeof routeArgs === 'function' ? routeArgs(prevArgs) : routeArgs;

    const to: To = {
      pathname: prevArgs.params !== nextArgs.params ? matchedRoute.formatPath(nextArgs.params) : currentLocation.pathname,
      search: prevArgs.query !== nextArgs.query ? matchedRoute.formatQueryString(nextArgs.query) : locationRef.current.search
    };

    navigateRef.current(to, { state: nextArgs.state, replace });
  }, []) as unknown;

  // Callable as navigate(toRoute, args, options) or navigate(toRoute, options) if all arguments are optional
  const navigate = useCallback(
    (targetRoute: AnyTypedRoute, secondArg?: Record<string, unknown> | AppNavigateOptions, thirdArg?: AppNavigateOptions) => {
      const currentLocation = locationRef.current;

      let options: NavigateOptions = { replace: false };
      let argsToChange: Record<string, unknown>;

      if (!secondArg) {
        argsToChange = {};
      } else if ('params' in secondArg || 'query' in secondArg || 'state' in secondArg) {
        argsToChange = secondArg;
        options = thirdArg ?? options;
      } else if ('replace' in secondArg || Object.keys(secondArg).length === 0) {
        argsToChange = {};
        options = secondArg as NavigateOptions;
      } else {
        throw new Error(
          `useAppRoute.navigate(): Unknown parameter combination ${targetRoute.path}, ${JSON.stringify(secondArg)}, ${String(thirdArg)}`
        );
      }

      const to: To = {
        pathname: targetRoute.formatPath({ ...pathParamsRef.current, ...(argsToChange?.params ?? {}) }),
        search: targetRoute.formatQueryString({ ...queryRef.current, ...(argsToChange?.query ?? {}) })
      };

      let state: unknown = currentLocation.state;
      const transformerReturnValue = targetRoute.routeStateTransformer(currentLocation.state) as unknown;
      // true => value is accepted
      // false => value is rejected
      // object => value is transformed

      if (transformerReturnValue === false) {
        console.warn('useAppRoute.navigate(): The state type checker you provided returned `false` for state ', state);
        state = null;
      } else if (transformerReturnValue !== true) {
        state = transformerReturnValue;
      }

      navigateRef.current(to, { state, replace: options.replace ?? false });
    },
    []
  );

  const appRoute = useMemo(
    () => ({
      matchedRoute,
      path,
      params,
      query,
      state,
      setParams,
      setQuery,
      setState,
      setAll,
      navigate
    }),
    [matchedRoute, path, params, query, state, setParams, setQuery, setState, setAll, navigate]
  );

  return appRoute as UseAppRouteResult<AnyTypedRoute>;
}

function isStarRoute(args: unknown[]): args is ['*'] {
  return args.length === 1 && args[0] === '*';
}
