import { useStableCallback } from '@hydrogrid/utilities/memoization';
import { useCallback, useLayoutEffect, useRef, type ReactNode } from 'react';

interface UseEllipsisTitleProps<E> {
  /** @default "horizontal" */
  axis?: 'horizontal' | 'vertical' | 'both';

  children?: ReactNode;

  title?: string | ((element: E) => string);
}

type StyleableElement = Element & ElementCSSInlineStyle;

/**
 * Hook which automatically sets a title when the text of an element is clipped
 * with text ellipsis, so the user can hover the clipped text to see the full text,
 * and removes the title when the text is no longer clipped.
 */
export function useTitleOnTextEllipsis<E extends StyleableElement = StyleableElement>({
  axis = 'horizontal',
  children,
  title: titleProp
}: UseEllipsisTitleProps<E> = {}) {
  const elementRef = useRef<E | null>(null);
  const hadOverflow = useRef<boolean>();

  const update = useStableCallback((element: E | null) => {
    if (element == null) return;

    let hasOverflow = false;
    if (axis !== 'vertical') {
      hasOverflow = element.scrollWidth > element.clientWidth;
    }
    if (axis !== 'horizontal') {
      hasOverflow ||= element.scrollHeight > element.clientHeight;
    }

    if (hasOverflow === hadOverflow.current) return;

    hadOverflow.current = hasOverflow;

    if (hasOverflow) {
      element.style.overflow = 'hidden';
      element.style.textOverflow = 'ellipsis';

      const title = typeof titleProp === 'function' ? titleProp(element) : (titleProp ?? element.textContent ?? '');
      element.setAttribute('title', title);
    } else {
      element.style.removeProperty('overflow');
      element.style.textOverflow = 'clip';
      element.removeAttribute('title');
    }
  });

  const cleanup = useRef<() => void>();

  const ref = useCallback(
    (element: E | null) => {
      const prev = elementRef.current;
      if (prev != null) {
        cleanup.current?.();
      }

      elementRef.current = element;
      if (!element) {
        cleanup.current = undefined;
        return;
      }

      const resizeObserver = new ResizeObserver(entries => {
        if (entries.length < 1) return;
        update(element);
      });

      resizeObserver.observe(element);
      cleanup.current = () => resizeObserver.disconnect();
    },
    [update]
  );

  useLayoutEffect(() => {
    void children;
    update(elementRef.current);
  }, [children, update]);

  return ref;
}
