import { create, type StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';

/**
 * This should only be used for creating globally accessible stores, like for our authentication state.
 *
 * Take a look at "createLocalStore" for stores that hold states for specific pages only (preferred most of the time).
 */
export const createGlobalStore = <T>(name: string, creator: StateCreator<T>) => {
  return create(
    devtools(creator, {
      name,
      enabled: !import.meta.env.PROD && import.meta.env.MODE !== 'test'
    })
  );
};
