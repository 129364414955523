import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'popoverTrigger',
  'iconContainer',
  'labelContainer',
  'label',
  'selection',
  'popoverContent',
  'icon',
  'content'
]);

const baseStyle = helpers.definePartsStyle({
  popoverTrigger: {
    display: 'flex',
    width: 'fit-content',
    flexDirection: 'column',
    px: '2',
    py: '0.5',
    borderWidth: '1px',
    borderColor: 'transparent',
    borderRadius: 'base',
    w: '100%',
    transition: 'all 0.1s',
    _hover: {
      _disabled: {
        cursor: 'not-allowed'
      }
    }
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5'
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left',
    w: '100%',
    gap: '2'
  },
  label: {
    fontSize: 'sm',
    w: '100%'
  },
  selection: {
    fontSize: 'sm',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  popoverContent: {
    borderWidth: '1px',
    p: '2',
    _focus: { boxShadow: 'md' }
  }
});

const variants = {
  outline: helpers.definePartsStyle(() => {
    return {
      popoverTrigger: {
        borderColor: 'secondary.100'
      }
    };
  }),
  ghost: helpers.definePartsStyle(() => {
    return {};
  }),
  subtle: helpers.definePartsStyle(() => {
    return {
      popoverTrigger: {
        _hover: {
          _disabled: {
            borderColor: 'transparent'
          }
        }
      }
    };
  })
};

export const filterTheme = helpers.defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'subtle'
  }
});
