import { useCallback } from 'react';
import { useNavigate, type NavigateOptions } from 'react-router-dom';
import type { AbsoluteRouteArguments, AnyTypedRouteArguments } from './TypedRouteArguments';
import type { AnyTypedRoute } from './TypedRoutes';

type AppNavigate = <T extends AnyTypedRoute>(to: T, ...args: [...args: AbsoluteRouteArguments<T>, options?: AppNavigateOptions]) => void;

export interface AppNavigateOptions {
  replace?: boolean;
}

export function useAppNavigate(): AppNavigate {
  const reactRouterNavigate = useNavigate();

  const navigate = useCallback(
    function navigate(targetRoute: AnyTypedRoute, secondArg?: AnyTypedRouteArguments | AppNavigateOptions, thirdArg?: AppNavigateOptions) {
      let routeArgs: AnyTypedRouteArguments = {
        params: {} as Record<string, unknown>,
        query: {} as Record<string, unknown>,
        state: undefined as unknown
      };

      let options: NavigateOptions = { replace: false };

      if (thirdArg) {
        options = thirdArg;
        routeArgs = (secondArg as AnyTypedRouteArguments) ?? routeArgs;
      } else if (secondArg && !('params' in secondArg) && !('query' in secondArg) && !('state' in secondArg)) {
        options = secondArg as NavigateOptions;
      } else if (secondArg) {
        routeArgs = secondArg;
      }

      const url = (targetRoute.url as (args: AnyTypedRouteArguments) => string)(routeArgs);

      reactRouterNavigate(url, {
        ...options,
        state: routeArgs?.state
      });
    },
    [reactRouterNavigate]
  );

  return navigate as AppNavigate;
}
