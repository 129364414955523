import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgLogoutSuccessful = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 90, height: 200, viewBox: "0 0 90 200", xmlns: "http://www.w3.org/2000/svg", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { fill: "#4a5860", children: [
    /* @__PURE__ */ jsx("animateTransform", { attributeName: "transform", type: "translate", values: "0 0; 0 20; 0 25; 0 22", begin: "200ms", dur: "400ms", fill: "freeze" }),
    /* @__PURE__ */ jsx("path", { "data-id": "bar", d: "M82.24 38.8v17.9h-9.36l-.01-17.9c0-16.25-11.45-28.98-28.3-28.98-17.03 0-27.95 13-27.95 28.98v39.85H7.23l.01-39.85C7.24 17.65 22.37.44 44.57.44c22.1 0 37.67 16.85 37.67 38.36Z" })
  ] }),
  /* @__PURE__ */ jsx("path", { fill: "#4a5860", stroke: "#3e4a51", strokeWidth: 1, "data-id": "hexagon", d: "M44.74 158a10.85 10.85 0 0 1-5.12-1.32L5.07 136.76A10.45 10.45 0 0 1 0 127.87v-39.8c-.02-3.63 1.91-7 5.07-8.8l34.69-19.94c1.52-.84 3.23-1.3 4.98-1.33 1.78.01 3.54.46 5.11 1.32l34.56 19.93a10.45 10.45 0 0 1 5.06 8.88v39.8c.03 3.63-1.9 7-5.06 8.8l-34.7 19.94c-1.52.84-3.23 1.3-4.97 1.33Z" }),
  /* @__PURE__ */ jsx("path", { "data-id": "keyhole", fill: "#e4e5e6", stroke: "#3e4a51", strokeWidth: 1, d: "M33 96a12 12 0 0 1 24 0c0 4.43-2.43 8.26-6 10.33V126a6 6 0 0 1-12 0v-19.67c-3.57-2.07-6-5.9-6-10.33Z" })
] });
const ForwardRef = forwardRef(SvgLogoutSuccessful);
export default ForwardRef;
