/**
 * @file Defines app-wide tools only available during development.
 * These are removed in the build step on `npm run build`.
 */

import { addTemporalFormattersForBrowserConsole, addTemporalFormattersForNode } from '@hydrogrid/utilities/development';
import { useEffect, type FunctionComponent } from 'react';
import { useApiForEnvironment } from '../common/api/InsightApi';
import { useAuthSessions } from '../common/auth/useAuthSessions';
import { useRouteEnvironment } from '../common/routing/useRouteEnvironment';
import { environments } from '../config/environments';
import { ShowBugReportsOnDragAndDrop } from './ShowBugReportsOnDragAndDrop';
import { attachConsoleHelpers } from './consoleHelpers';

function DevelopmentTools() {
  const environment = useRouteEnvironment({ optional: true }) ?? getEnvironmentFromBrowserRoute();
  const api = useApiForEnvironment(environment);
  const sessions = useAuthSessions();

  useEffect(() => {
    attachConsoleHelpers({ api, sessions });
  }, [api, sessions]);

  useEffect(() => addTemporalFormattersForBrowserConsole(), []);
  useEffect(() => addTemporalFormattersForNode(), []);

  return (
    <>
      <ShowBugReportsOnDragAndDrop />
    </>
  );
}

/** Rendering DevelopmentTools outside <Route> makes in necessary to parse path parameters manually. */
function getEnvironmentFromBrowserRoute() {
  const slug = window.location.pathname.match(/^\/(\w+)\//)?.[1];
  return environments.find(env => env.slug === slug);
}

const developmentToolsToExport: FunctionComponent = import.meta.env.DEV ? DevelopmentTools : () => null;

export { developmentToolsToExport as DevelopmentTools };
