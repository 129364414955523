/**
 * Watch the DOM and run {@link onStylesChanged} when `<style>` elements were added to the `<head>`.
 *
 * Updates are batched, so multiple changes in the same tick only call {@link onStylesChanged} once.
 *
 * Does not actually check if any styles changed, so might call the callback for on false positives.
 *
 * In development, will also observe when styles are hot-reloaded from the bundler via:
 * ```ts
 * styleElement.innerHTML = "new css";
 * styleSheet.insertRule("new css");
 * styleSheet.insertRule(".selector", "new css");
 * styleSheet.replaceSync("new css");
 * await styleSheet.replace("new css");
 * ```
 */
export function observeDocumentStyles(onStylesChanged: () => void) {
  const observer = { notify: onStylesChanged };
  observers.push(observer);

  if (observers.length === 1) {
    setupStyleObserving();
  }

  return () => {
    observers = observers.filter(o => o !== observer);
    if (!observers.length) {
      stopObserving();
    }
  };
}

let observers: { notify: () => void }[] = [];
let stopObserving = () => {};

function setupStyleObserving() {
  let updateScheduled = false;
  let stoppedObserving = false;

  const patchStylesheetsOnNextTick = () => {
    if (updateScheduled || stoppedObserving) return;
    updateScheduled = true;
    void Promise.resolve().then(() => {
      updateScheduled = false;
      observers.forEach(({ notify }) => notify());
    });
  };

  const cleanups = [
    () => {
      stoppedObserving = true;
    }
  ];

  const MutationObserver = window.MutationObserver ?? window.WebKitMutationObserver ?? window.MozMutationObserver;
  if (MutationObserver != null) {
    // Use the more modern MutationObserver
    const observer = new MutationObserver(mutations => {
      const addedNodes = [...mutations].flatMap(mutation => [...mutation.addedNodes]);
      if (addedNodes.some(node => node instanceof HTMLStyleElement)) {
        patchStylesheetsOnNextTick();
      }
    });
    observer.observe(document.head, {
      childList: true,
      subtree: false
    });
    cleanups.push(() => observer.disconnect());
  } else {
    // Use the (now deprecated) DOMNodeInserted event when MutationObserver is not available.
    const listener = (event: Event) => {
      if (event.target instanceof HTMLStyleElement) {
        patchStylesheetsOnNextTick();
      }
    };
    document.head.addEventListener('DOMNodeInserted', listener);
    cleanups.push(() => document.head.removeEventListener('DOMNodeInserted', listener));
  }

  // Only in development: Watch for hotreloaded stylesheets
  if (import.meta.env.DEV) {
    const unwatchHotReloadedStyles = watchForHotReloadedStylesInDevelopment(patchStylesheetsOnNextTick);
    cleanups.push(...unwatchHotReloadedStyles);
  }

  cleanups.push(() => {
    stopObserving = () => {};
  });

  stopObserving = () => cleanups.forEach(cleanup => cleanup());
}

function watchForHotReloadedStylesInDevelopment(onStylesChanged: () => void) {
  const cleanups: (() => void)[] = [];

  // Detect changed css via styleElement.innerHTML = '...' / styleElement.textContent = '...'
  const settersToOverwrite = ['innerHTML', 'textContent'] as const;

  for (const setterName of settersToOverwrite) {
    // Find the correct prototype for `style.[setter] = "new css";`
    let originalDescriptor: PropertyDescriptor | undefined;
    let prototype: unknown = HTMLStyleElement.prototype;
    while (prototype != null && !(originalDescriptor = Object.getOwnPropertyDescriptor(prototype, setterName))) {
      prototype = Object.getPrototypeOf(prototype);
    }

    if (prototype != null && originalDescriptor) {
      Object.defineProperty(HTMLStyleElement.prototype, setterName, {
        ...originalDescriptor,
        configurable: true,
        set(this: HTMLStyleElement, newValue: string) {
          originalDescriptor?.set?.call(this, newValue);
          onStylesChanged();
        }
      });

      cleanups.push(() => {
        delete (HTMLStyleElement.prototype as { innerHTML?: string; textContent?: string })[setterName];
      });
    }
  }

  const methodsToSpyOn = [
    [CSSStyleSheet.prototype, 'addRule'],
    [CSSStyleSheet.prototype, 'insertRule'],
    [CSSStyleSheet.prototype, 'replace'],
    [CSSStyleSheet.prototype, 'replaceSync'],
    [CSSMediaRule.prototype, 'insertRule']
  ] as [unknown, string][];

  for (const [prototype, method] of methodsToSpyOn) {
    const prototypeWithMethod = prototype as Record<string, (...args: unknown[]) => unknown>;
    const originalMethod = prototypeWithMethod[method];

    if (typeof originalMethod !== 'function') {
      continue;
    }

    // Proxy the methods in the list above to call onStylesChanged() when they are called.
    prototypeWithMethod[method] = function (this: unknown, ...args: unknown[]) {
      const returnValue = originalMethod.apply(this, args);
      if (!(returnValue instanceof Promise)) {
        onStylesChanged();
        return returnValue;
      }

      // If a promise is returned (CSSStyleSheet.prototype.replace), await it first.
      return returnValue.then((value: unknown) => {
        onStylesChanged();
        return value;
      });
    };

    cleanups.push(() => {
      prototypeWithMethod[method] = originalMethod;
    });
  }

  return cleanups;
}
