import { observeDocumentStyles } from '../utils/observeDocumentStyles';

/**
 * Polyfill media queries to allow the use of variables, which current browsers don't support natively.
 *
 * @example
 * ```css
 * :root {
 *   --breakpoint-small: 576px;
 * }
 *
 * @media screen and (max-width: var(--breakpoint-small)) {
 *   .headline {
 *     font-size: 0.8rem;
 *   }
 * }
 * ```
 */
export function polyfillVariablesInMediaQueries(elementWithVariables = () => document.documentElement) {
  patchAllStylesheets(elementWithVariables);
  return observeDocumentStyles(() => patchAllStylesheets(elementWithVariables));
}

declare global {
  interface Window {
    WebKitMutationObserver: typeof MutationObserver;
    MozMutationObserver: typeof MutationObserver;
  }
}

const variablePattern = /\bvar\(|\b--\w/;

function patchAllStylesheets(elementWithVariables: () => HTMLElement) {
  const rulesToPatch = [...document.styleSheets]
    .flatMap(styleSheet => {
      try {
        // Stylesheets from external domains will throw - ignore & don't patch them
        return [...styleSheet.cssRules];
      } catch {
        return [];
      }
    })
    .filter(isCSSMediaRule)
    .filter(rule => variablePattern.test(rule.media.mediaText));

  if (!rulesToPatch.length) return;

  const cssVariableValues = window.getComputedStyle(elementWithVariables());

  // Replace "@media(max-width: var(--breakpoint-small))" -> "@media(max-width: 576px)"
  for (const rule of rulesToPatch) {
    for (const mediumWithVariables of [...rule.media]) {
      let variablesAreMissing = false;
      const mediumWithValues = mediumWithVariables.replace(/\bvar\((--\w[^)]+)\)/g, (_, variable: string) => {
        const value = cssVariableValues.getPropertyValue(variable);
        if (!value) {
          variablesAreMissing = true;
        }
        return value;
      });

      if (!variablesAreMissing && mediumWithValues !== mediumWithVariables && ![...rule.media].includes(mediumWithValues)) {
        rule.media.appendMedium(mediumWithValues);
      }
    }
  }
}

function isCSSMediaRule(rule: CSSRule): rule is CSSMediaRule {
  return rule instanceof CSSMediaRule || (rule as { type: number }).type === /* CSSRule.MEDIA_RULE */ 4;
}
