import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgReservoir = (props, ref) => /* @__PURE__ */ jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: 40, height: 40, fill: "none", viewBox: "0 0 40 40", "aria-label": "Reservoir", ref, ...props, children: /* @__PURE__ */ jsxs("g", { fill: "currentColor", children: [
  /* @__PURE__ */ jsx("path", { fill: "#fff", d: "M20 39.44A19.44 19.44 0 1 0 20 .56a19.44 19.44 0 0 0 0 38.88Z" }),
  /* @__PURE__ */ jsx("path", { d: "M20 1.11a18.89 18.89 0 1 1 0 37.78A18.89 18.89 0 0 1 20 1.1ZM20 0a20 20 0 1 0 0 40 20 20 0 0 0 0-40Z" }),
  /* @__PURE__ */ jsx("path", { d: "M36.63 20a.37.37 0 0 1 .37.37 17 17 0 0 1-34 0 .37.37 0 0 1 .37-.37h33.26Z" }),
  /* @__PURE__ */ jsx("path", { fill: "none", stroke: "#fff", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 0.62, d: "M10.8 23.35c1.84 0 1.84.74 3.68.74s1.84-.74 3.68-.74 1.84.74 3.68.74 1.84-.74 3.68-.74 1.84.74 3.68.74m-18.4 1.53c1.84 0 1.84.74 3.68.74s1.84-.74 3.68-.74 1.84.74 3.68.74 1.84-.74 3.68-.74 1.84.74 3.68.74M10.8 27.9c1.84 0 1.84.74 3.68.74s1.84-.74 3.68-.74 1.84.74 3.68.74 1.84-.74 3.68-.74 1.84.74 3.68.74" })
] }) });
const ForwardRef = forwardRef(SvgReservoir);
export default ForwardRef;
