import { Text, type ChakraProps } from '@chakra-ui/react';
import { useApiStatus } from '../../common/api/hooks/CommonDataHooks';
import { frontendVersion } from './viteEnvironment';

interface AppVersionsProps {
  color?: ChakraProps['color'];
  fontWeight?: string;
}
export function AppVersions({ color = 'secondary.500', fontWeight = '' }: AppVersionsProps) {
  const health = useApiStatus();
  const backendVersion = health.data?.version;

  return (
    <Text color={color} padding={2} textAlign="center" userSelect="text" data-testid="AppVersions" fontSize="xs" fontWeight={fontWeight}>
      {frontendVersion} {backendVersion ? `/ ${backendVersion}` : ' / ?'}
    </Text>
  );
}
