import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgSad = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "#4A5860", viewBox: "0 0 100 100", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M49.74 0c-1.79.01-3.55.46-5.12 1.32L10.07 21.24A10.45 10.45 0 0 0 5 30.13v39.8c-.02 3.63 1.91 7 5.07 8.8l34.69 19.94c1.52.84 3.23 1.3 4.98 1.33 1.78-.01 3.54-.46 5.11-1.32l34.56-19.93a10.45 10.45 0 0 0 5.06-8.88v-39.8c.03-3.63-1.9-7-5.06-8.8L54.7 1.32A10.74 10.74 0 0 0 49.75 0Z" }),
  /* @__PURE__ */ jsxs("g", { fill: "#fff", children: [
    /* @__PURE__ */ jsx("path", { d: "M26.24 33.49a5.26 5.26 0 1 1-10.52 0 5.26 5.26 0 0 1 10.52 0ZM26.24 66.48a5.26 5.26 0 1 1-10.52 0 5.26 5.26 0 0 1 10.52 0ZM40.3 48.12h5.95a5.26 5.26 0 1 1 0 3.73h-5.96a5.26 5.26 0 1 1 0-3.73Z" }),
    /* @__PURE__ */ jsxs("g", { "data-animation": "mouth", "transform-origin": "70 50%", transform: "scale(0.95) translate(2 0)", children: [
      /* @__PURE__ */ jsx("path", { d: "M80.18 72.85a5.26 5.26 0 1 1-10.52 0 5.26 5.26 0 0 1 10.52 0ZM80.18 27.15a5.26 5.26 0 1 1-10.52 0 5.26 5.26 0 0 1 10.52 0Z" }),
      /* @__PURE__ */ jsx("path", { d: "m75.8 71.2-.88 1.65-.88 1.64h-.02l-.04-.03a10.58 10.58 0 0 1-.61-.36 31.25 31.25 0 0 1-6.49-5.54A27.53 27.53 0 0 1 59.77 50c0-8.44 3.57-14.57 7.11-18.56a31.25 31.25 0 0 1 6.49-5.54 16.78 16.78 0 0 1 .6-.36l.05-.02.01-.01.89 1.64.88 1.64-.01.01a6.7 6.7 0 0 0-.46.27 27.52 27.52 0 0 0-5.66 4.84A23.8 23.8 0 0 0 63.5 50a23.8 23.8 0 0 0 6.17 16.09 27.52 27.52 0 0 0 6.03 5.06l.09.05h.01Z" })
    ] })
  ] })
] });
const ForwardRef = forwardRef(SvgSad);
export default ForwardRef;
