import { createContext, useContext, type ReactNode } from 'react';
import type { AnyTypedRoute } from './TypedRoutes';

/**
 * @file Workaround react context since react-router v6 no longer allows
 * accessing the matched route in a simple way, and no longer supports
 * alternative routes (e.g. `"/icecream/flavor(strawberry|vanilla)"`,
 * which makes route matching even harder.
 */

const MatchedRouteContext = createContext<AnyTypedRoute | null>(null);

interface ProvideMatchedRouteProps {
  children?: ReactNode;
  route: AnyTypedRoute;
}

export function ProvideMatchedRoute({ children, route }: ProvideMatchedRouteProps) {
  return <MatchedRouteContext.Provider value={route}>{children}</MatchedRouteContext.Provider>;
}

export function useMatchedRoute() {
  return useContext(MatchedRouteContext);
}
