import type { MinimalRequestArgs } from './generated-client/RequestTypes';

interface ApiErrorInit {
  request: MinimalRequestArgs;
  response: Response;
  responseBody?: unknown;
  error?: unknown;
}

export interface ProblemJson {
  title: string;
  detail: string;
  type: 'about:blank';
  status: number;
}

export function isProblemJson(body: unknown): body is ProblemJson {
  return typeof body === 'object' && body !== null && 'title' in body && 'detail' in body && 'type' in body && 'status' in body;
}

/** Error class for API errors. */
export class ApiError extends Error {
  static get [Symbol.species]() {
    return ApiError;
  }

  readonly request: MinimalRequestArgs;
  readonly response: Response;
  readonly status: number | null;
  readonly body: unknown | null;
  readonly relatedError: unknown | null;

  get [Symbol.toStringTag]() {
    return 'ApiError';
  }

  constructor({ request, response, responseBody = null, error }: ApiErrorInit) {
    super(
      `Request to ${request.method.toUpperCase()} ${request.path} failed with ${
        error != null ? `error: ${error instanceof Error ? error.message : String(error)}` : `HTTP ${response.status}.`
      }`
    );

    // Fix the "Error" prototype chain in case we compile to older-than-ES2015
    if (!(this instanceof ApiError)) {
      Object.setPrototypeOf(this, new.target.prototype);
    }

    // Set the correct stack trace
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = 'ApiError';
    this.request = request;
    this.response = response;
    this.status = response.status;
    this.body = responseBody;
    this.relatedError = error;
  }
}
