import { useStableCallback } from '@hydrogrid/utilities/memoization';
import {
  forwardRef,
  useCallback,
  useId,
  useLayoutEffect,
  useRef,
  useState,
  type FocusEvent,
  type KeyboardEvent,
  type ReactNode
} from 'react';
import { keyCombination } from '../../accessibility/keyCombination';
import { useClickOutside } from '../../accessibility/useClickOutside';
import { useScopedStyles } from '../../css/useStyles';
import { useAnimatablePresence, type AnimatablePresenceEvents } from '../../dom/useAnimatablePresence';
import { ArrowDownIcon } from '../../icons';
import { useControllableState } from '../../state/useControllableState';
import { classNames } from '../../utils/classNames';
import { useCombinedRefs } from '../../utils/useCombinedRefs';
import styles from './Dropdown.module.css';

const defaultWidth = '20rem';

interface DropdownProps extends AnimatablePresenceEvents {
  triggerAutoResize?: boolean;
  className?: string | undefined;
  label?: string;
  children: ReactNode;
  displayValue: ReactNode;
  displayValuePadding?: boolean;
  disabled?: boolean;
  isOpen?: boolean;
  showArrow?: boolean;
  showBorder?: boolean;
  align?: 'start' | 'end' | 'middle';
  verticalAlign?: 'top' | 'bottom';
  id?: string;
  tabIndex?: number | undefined;
  title?: string | undefined;

  /** @default "medium" */
  size?: 'medium' | 'small';

  /** @default "default" */
  variant?: 'default' | 'borderless';

  minWidth?: string | undefined;
  maxWidth?: string | undefined;
  width?: string | undefined;

  onToggle?: (isOpen: boolean) => void;
  onTriggerClick?: ((event: React.MouseEvent) => void) | undefined;
  onClickOutside?: ((event: MouseEvent) => void) | undefined;
  onFocus?: ((event: FocusEvent<HTMLElement>) => void) | undefined;
  onBlur?: ((event: FocusEvent<HTMLElement>) => void) | undefined;
  onMouseEnter?: (() => void) | undefined;
  onMouseLeave?: (() => void) | undefined;
  onAppearStart?: () => void;
  onAppearEnd?: () => void;
  onVanishStart?: () => void;
  onVanishEnd?: () => void;
}

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>((props, externalRef) => {
  const {
    className,
    label,
    children,
    displayValue,
    displayValuePadding = true,
    disabled = false,
    showArrow = true,
    showBorder = true,
    isOpen: isOpenProp,
    align = 'start',
    verticalAlign = 'bottom',
    id: idFromProps,
    tabIndex = 0,
    triggerAutoResize = false,
    size = 'medium',
    variant = 'default',
    minWidth,
    maxWidth,
    width,
    onToggle,
    onTriggerClick,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    onBlur,
    onClickOutside,
    onAppearStart,
    onAppearEnd,
    onVanishStart,
    onVanishEnd,
    ...extraProps
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const scopedContainerStyles = useScopedStyles('Dropdown', { width, minWidth, maxWidth: maxWidth ?? defaultWidth });
  const scopedTriggerStyles = useScopedStyles('Dropdown', {
    width: width ?? (triggerAutoResize ? 'fit-content' : defaultWidth),
    minWidth,
    maxWidth
  });

  const uniqueId = useId();
  const id = idFromProps ?? `Dropdown-${uniqueId}`;

  const [isOpen, setIsOpen, openStateIsControlled] = useControllableState({
    defaultValue: false,
    value: isOpenProp,
    onChange: onToggle
  });

  const { ref: animationRef, shouldRender } = useAnimatablePresence({
    isOpen,
    onAppearStart,
    onAppearEnd,
    onVanishStart,
    onVanishEnd
  });

  const combinedContentRef = useCombinedRefs<HTMLElement | null>(contentRef, animationRef);

  const [contentSizeComparedToTrigger, setContentSizeComparedToTrigger] = useState<'smaller' | 'wider' | 'same'>('same');
  const closing = shouldRender && !isOpen;

  useClickOutside({
    enabled: isOpen,
    ref,
    handler: event => {
      onClickOutside?.(event);
      if (!openStateIsControlled) {
        setIsOpen(false);
      }
    }
  });

  const toggleOnTriggerClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      onTriggerClick?.(event);
      if (event.defaultPrevented) return;
      setIsOpen(!isOpen);
    },
    [isOpen, onTriggerClick, setIsOpen]
  );

  useLayoutEffect(() => {
    if (!shouldRender) return;

    const compareContentAndTriggerWidth = () => {
      const contentWidth = contentRef.current?.offsetWidth;
      const triggerWidth = triggerRef.current?.offsetWidth;

      if (contentWidth == null || triggerWidth == null) return;

      setContentSizeComparedToTrigger(contentWidth === triggerWidth ? 'same' : contentWidth > triggerWidth ? 'wider' : 'smaller');
    };

    compareContentAndTriggerWidth();

    const frame = requestAnimationFrame(compareContentAndTriggerWidth);
    return () => cancelAnimationFrame(frame);
  }, [shouldRender]);

  const toggleOnEnter = useStableCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.defaultPrevented) return;

    const key = keyCombination(event);

    if (key === 'Enter' || key === 'Space') {
      setIsOpen(!isOpen);
      event.preventDefault();
    } else if (key === 'Escape') {
      setIsOpen(false);
      event.preventDefault();
    }
  });

  const triggerState = disabled ? 'disabled' : isOpen ? 'open' : 'closed';

  return (
    <div
      ref={useCombinedRefs(ref, externalRef)}
      className={classNames(
        styles.container,
        verticalAlign === 'top' ? styles.containerUp : styles.containerDown,
        scopedContainerStyles,
        isOpen && styles.containerOpen,
        className
      )}
      {...extraProps}
    >
      <div
        ref={triggerRef}
        className={classNames(
          styles.trigger,
          styles[`trigger-${size}`],
          styles[`trigger-${variant}-${triggerState}`],
          styles[`trigger-${triggerState}`],
          scopedTriggerStyles,
          !showBorder && styles.triggerNoBorder
        )}
        id={id}
        role="button"
        aria-label={label}
        aria-haspopup="listbox"
        aria-controls={`${id}-contents`}
        aria-expanded={isOpen}
        tabIndex={disabled ? -1 : tabIndex}
        aria-disabled={disabled}
        onClick={disabled ? undefined : toggleOnTriggerClick}
        onKeyDown={disabled ? undefined : toggleOnEnter}
        onFocus={disabled ? undefined : onFocus}
        onBlur={disabled ? undefined : onBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={classNames(
            styles.displayValue,
            styles[`displayValue-${size}`],
            displayValuePadding && styles[`displayValuePadding-${size}`]
          )}
        >
          {displayValue}
        </div>
        {showArrow && (
          <div className={classNames(styles.arrow, styles[`arrow-${size}`], isOpen && styles.arrowOpen, disabled && styles.arrowDisabled)}>
            <ArrowDownIcon />
          </div>
        )}
      </div>
      {shouldRender && (
        <div
          ref={combinedContentRef}
          id={`${id}-contents`}
          className={classNames(
            styles.content,
            align === 'start' ? styles.contentRight : align === 'end' ? styles.contentLeft : styles.contentMiddle,
            verticalAlign === 'top' ? styles.contentUp : styles.contentDown,
            closing && styles.contentClosing,
            styles[`content-width-${contentSizeComparedToTrigger}`]
          )}
          aria-labelledby={id}
        >
          {children}
        </div>
      )}
    </div>
  );
});
