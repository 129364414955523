import { createContext, useContext, useEffect, useId, useLayoutEffect, useRef, useState, type ReactNode, type RefCallback } from 'react';
import { combineRefs } from '../utils/useCombinedRefs';
import { DescendantsManager, type DescendantOptions } from './DescendantsManager';

export function createDescendantsContext<E extends HTMLElement = HTMLElement, D extends object = {}>(name: string) {
  const DescendantsContext = createContext<DescendantsManager<E, D> | null>(null);
  DescendantsContext.displayName = name;

  const useDescendantsContext = () => {
    const descendants = useContext(DescendantsContext);
    if (!descendants) {
      throw new Error(`You need to render this component within a ${name}Provider.`);
    }
    return descendants;
  };

  const useDescendant = (options?: DescendantOptions<D>) => {
    const descendants = useDescendantsContext();

    const generatedId = useId();
    const id = options?.id ?? generatedId;
    const [index, setIndex] = useState(() => descendants.getInitialIndex(id));

    const ref = useRef<E>(null);

    descendants.invalidate();

    useLayoutEffect(() => {
      const node = ref.current;
      if (!node) return;

      if (!options) {
        descendants.register(node);
      } else {
        const register = descendants.register(options) as RefCallback<E>;
        register(node);
      }
    }, [descendants, options]);

    useLayoutEffect(() => {
      const node = ref.current;
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (node && ref.current == null) {
          descendants.unregister(node);
        }
      };
    }, [descendants]);

    useEffect(() => {
      return () => descendants.removeInitial(id);
    }, [descendants, id]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
      if (!ref.current) return;

      descendants.ensureNodesAreSorted();
      const index = descendants.indexOf(ref.current);

      if (!Number.isNaN(index) && index >= 0) {
        setIndex(index);
      }

      return () => descendants.invalidate();
    });

    return {
      descendants,
      index,
      enabledIndex: descendants.enabledIndexOf(ref.current),
      register: combineRefs<E | null>(ref)
    };
  };

  function useDescendants() {
    const managerRef = useRef(new DescendantsManager<E, D>());

    useEffect(() => {
      const currentManager = managerRef.current;
      return () => {
        currentManager.destroy();
      };
    }, []);

    return managerRef.current;
  }

  function Provider({ value: descendants, children }: { value: DescendantsManager<E, D>; children: ReactNode }) {
    useLayoutEffect(() => descendants.parentFinishedRendering(), [descendants]);

    return <DescendantsContext.Provider value={descendants}>{children}</DescendantsContext.Provider>;
  }

  Provider.displayName = `${name}Provider`;

  return {
    Provider,
    useDescendantsContext,
    useDescendant,
    useDescendants
  };
}
