import { classNames, hashString, useScopedStyles } from '@hydrogrid/design-system';
import md5hex from 'md5-hex';
import { useEffect, useMemo, useState, type AriaRole } from 'react';
import { useRouteEnvironment } from '../../common/routing/useRouteEnvironment';
import type { Environment } from '../../config/environments';
import styles from './UserAvatar.module.css';
import { getInitialsFromEmail, getInitialsFromName } from './getInitialsFromName';

interface UserAvatarProps {
  border?: boolean;
  className?: string | undefined;
  name?: string | null | undefined;
  email: string;
  environment?: Environment;
  role?: AriaRole;
  size?: number | string;
  style?: React.CSSProperties;
  'aria-hidden'?: boolean;
}

export function UserAvatar(props: UserAvatarProps) {
  const { border = false, className, name, email, environment, size, style, ...extraProps } = props;

  const routeEnvironment = useRouteEnvironment({ optional: true });
  const env = (environment ?? routeEnvironment)?.slug ?? 'none';

  const sizeClass = useScopedStyles('UserAvatar', {
    width: typeof size === 'number' ? `${size}px` : size,
    height: typeof size === 'number' ? `${size}px` : size
  });

  // TODO: Use actual avatar images (backend 🙃) - for now, use gravatar.
  const imageUrl = useMemo(() => {
    const devicePixelRatio = 2;
    const sizeInPixels = typeof size === 'number' ? size : size != null && size.endsWith('px') ? Number(size.replace('px', '')) : null;
    return gravatarUrl(email, sizeInPixels == null ? null : sizeInPixels * devicePixelRatio);
  }, [email, size]);

  const [render, setRender] = useState<'nothing' | 'image' | 'initials'>(preloadedAvatars.has(imageUrl) ? 'image' : 'nothing');

  const color = useMemo(() => generateColor(env, email), [email, env]);

  useEffect(() => {
    if (preloadedAvatars.has(imageUrl)) {
      return setRender('image');
    }

    setRender('nothing');
    const image = new Image();
    image.onload = () => setRender('image');
    image.onerror = () => setRender('initials');
    image.src = imageUrl;
  }, [imageUrl]);

  return (
    <div
      aria-label={name ?? undefined}
      {...extraProps}
      className={classNames(styles.avatar, border && styles.withBorder, sizeClass, className)}
      style={{ color, ...style }}
    >
      {render === 'image' && <img src={imageUrl} alt={name ?? email} className={styles.image} />}
      {render === 'initials' && (
        <span className={styles.initials} role="presentation">
          {name ? getInitialsFromName(name) : getInitialsFromEmail(email)}
        </span>
      )}
    </div>
  );
}

const preloadedAvatars = new Map<string, HTMLImageElement>();

UserAvatar.preload = (email: string, sizeInPixels?: number | null) => {
  return new Promise<string>((resolve, reject) => {
    const url = gravatarUrl(email, sizeInPixels);
    const image = new Image();

    image.onload = () => {
      image.onerror = null;
      image.onload = null;
      preloadedAvatars.set(url, image);
      resolve(url);
    };

    image.onerror = cause => {
      image.onerror = null;
      image.onload = null;
      reject(new Error('Failed to preload avatar', { cause }));
    };

    image.crossOrigin = 'anonymous';
    image.referrerPolicy = 'no-referrer';

    image.src = url;
  });
};

UserAvatar.forgetPreloadedAvatars = () => preloadedAvatars.clear();

function gravatarUrl(email: string, sizeInPixels?: number | null) {
  return `https://gravatar.com/avatar/${md5hex(email)}?d=404${sizeInPixels != null ? `&s=${sizeInPixels}` : ``}`;
}

/**
 * Generates a unique color for a pair of environment + user email.
 * This allows to differentiate which environment the current user is logged into.
 */
function generateColor(environment: string, email: string) {
  // Limit the generated colors to hsl(0-359 50..90% 40..60%)
  // Hydrogrid primary color in HSL = hsl(172deg 89% 38%)
  const hue = hashString(`${environment}-${email}`) % 360;
  const saturation = 50 + (hashString(`${environment}-${email}-s`) % 40);
  const lightness = 40 + (hashString(`${environment}-${email}-l`) % 20);
  return `hsl(${hue}deg ${saturation}% ${lightness}%)`;
}
