import { isValidDataRange, parseDataRange, stringifyDataRange, type DataRange } from '@hydrogrid/design-system';
import type { CockpitEditMetric } from '../../containers/CockpitTables/CockpitEditMetric';
import { allTimeZones, type TimeZone } from '../api/generated-client/Schemas';
import type { ParamStaticType, QueryParamSerializer } from './TypedRoutes';

export function optional(serializer: StringConstructor): QueryParamSerializer<string | undefined, string | undefined>;
export function optional(serializer: NumberConstructor): QueryParamSerializer<number | undefined, string | number | undefined>;
export function optional<Out, In extends Out = Out>(
  serializer: ParamStaticType<Out> | QueryParamSerializer<Out, In>
): QueryParamSerializer<Out | undefined, In | undefined> & { relatedType: typeof serializer };
export function optional<Out, In extends Out = Out>(serializer: ParamStaticType<Out> | QueryParamSerializer<Out, In>) {
  const optionalSerializer: QueryParamSerializer<Out | undefined, In | undefined> & { relatedType: typeof serializer } = {
    name: `optional<${serializer.name ?? 'unknown'}>`,
    relatedType: serializer,
    serialize: (input, name) => {
      if (input === undefined) {
        return undefined;
      }

      if (typeof serializer === 'function') {
        return String(input);
      }

      return serializer.serialize(input, name);
    },
    deserialize: (input: string, name: string, params: URLSearchParams) => {
      if (!params?.has(name)) {
        return undefined;
      }

      if (typeof serializer === 'function') {
        return serializer(input);
      }

      return serializer.deserialize(input, name, params);
    }
  };

  return optionalSerializer;
}

export const DataRangeQueryParam: QueryParamSerializer<DataRange, DataRange | string> = {
  name: 'DataRange',
  serialize: (range, name) => {
    if (typeof range === 'string') {
      range = parseDataRange(range);
    }

    if (!isValidDataRange(range)) {
      throw new TypeError(`Input for query parameter ${name} is not a valid data range: ${JSON.stringify(range)}`);
    }

    return stringifyDataRange(range);
  },
  deserialize: (rangeStr, name) => {
    try {
      return parseDataRange(rangeStr);
    } catch {
      throw new TypeError(`Value "${rangeStr}" for query parameter ${name} is not a valid data range.`);
    }
  }
};

export const TimeZoneQueryParam: QueryParamSerializer<TimeZone> = {
  name: 'TimeZone',
  serialize: (timeZone, name) => {
    if (typeof timeZone !== 'string' || !allTimeZones.includes(timeZone)) {
      throw new TypeError(`Value "${timeZone}" for query parameter ${name} is not a valid timezone.`);
    }

    return timeZone;
  },
  deserialize: (timezoneStr, name) => {
    if (typeof timezoneStr !== 'string' || !allTimeZones.includes(timezoneStr as TimeZone)) {
      throw new TypeError(`Value "${timezoneStr}" for query parameter ${name} is not a valid timezone.`);
    }

    return timezoneStr as TimeZone;
  }
};

const validEditMetricNames: CockpitEditMetric[] = [
  'generation',
  'generation-plan',
  'throughput',
  'throughput-plan',
  'level',
  'level-plan',
  'inflow',
  'inflow-plan',
  'throughput',
  'throughput-plan'
];

export const CockpitEditModeQueryParam: QueryParamSerializer<CockpitEditMetric> = {
  name: 'CockpitEditMode',
  serialize: metric => metric,
  deserialize: (metric: string) => {
    if (validEditMetricNames.includes(metric as CockpitEditMetric)) {
      return metric as CockpitEditMetric;
    }

    throw new TypeError(`Invalid edit mode "${metric}" in query parameters.`);
  }
};
