export const colors = {
  teal: {
    '50': '#e7f8f6',
    '100': '#cef1ec',
    '200': '#9de2d9',
    '300': '#6dd4c6',
    '400': '#3cc5b3',
    '500': '#0bb7a0',
    '600': '#139f8e',
    '700': '#1a867b',
    '800': '#226e69',
    '900': '#295556'
  },
  magenta: {
    '50': '#f7e8f0',
    '100': '#efd0e0',
    '200': '#dea0c1',
    '300': '#ce71a3',
    '400': '#bd4184',
    '500': '#ad1265',
    '600': '#941b5e',
    '700': '#7b2358',
    '800': '#632c51',
    '900': '#4a344b'
  },
  green: {
    '50': '#edf6f2',
    '100': '#daede5',
    '200': '#b5dbcb',
    '300': '#8fc9b1',
    '400': '#6ab797',
    '500': '#45a57d',
    '600': '#419072',
    '700': '#3d7b66',
    '800': '#39675b',
    '900': '#35524f'
  },
  red: {
    '50': '#f9e6e6',
    '100': '#f3cccc',
    '200': '#e69999',
    '300': '#da6767',
    '400': '#cd3434',
    '500': '#c10101',
    '600': '#a40d0e',
    '700': '#87191c',
    '800': '#6b2529',
    '900': '#4e3137'
  },
  yellow: {
    '50': '#fff7f0',
    '100': '#ffefe0',
    '200': '#ffdec0',
    '300': '#ffcea1',
    '400': '#ffbd81',
    '500': '#ffad62',
    '600': '#d6975c',
    '700': '#ad8056',
    '800': '#836a50',
    '900': '#5a534a'
  },
  blue: {
    '50': '#e7f2f5',
    '100': '#cde5ea',
    '200': '#9ccbd5',
    '300': '#6ab1bf',
    '400': '#3997aa',
    '500': '#077d95',
    '600': '#0f7085',
    '700': '#186375',
    '800': '#205764',
    '900': '#294a54'
  },
  gray: {
    '50': '#f4f5f6',
    '100': '#e9ebec',
    '200': '#d3d7d9',
    '300': '#bec3c6',
    '400': '#a8afb3',
    '500': '#929ba0',
    '600': '#7f888e',
    '700': '#6b757b',
    '800': '#586369',
    '900': '#445056'
  }
};

export const colorDefinitions = {
  primary: colors.teal,
  secondary: colors.gray,
  accent: colors.magenta,

  success: colors.green,
  info: colors.blue,
  warning: colors.yellow,
  error: colors.red,

  contrast: {
    '900': '#1B2327',
    '800': '#313D44',
    '700': '#4A5860',
    '600': '#5C6873',
    '500': '#748390',
    '400': '#909CA7',
    '300': '#ADB5BD',
    '200': '#C9CFD4',
    '100': '#E5E8EB',
    '50': '#F4F5F6'
  },

  auxilliary1: {
    '50': '#fdf1ee',
    '100': '#fbe3db',
    '200': '#f7c7b8',
    '300': '#f2aa94',
    '400': '#ee8e71',
    '500': '#ea724d',
    '600': '#c5674b',
    '700': '#a05d49',
    '800': '#7b5248',
    '900': '#564846'
  },
  auxilliary2: {
    '50': '#e7f2f5',
    '100': '#cde5ea',
    '200': '#9ccbd5',
    '300': '#6ab1bf',
    '400': '#3997aa',
    '500': '#077d95',
    '600': '#0f7085',
    '700': '#186375',
    '800': '#205764',
    '900': '#294a54'
  },
  auxilliary3: {
    '50': '#f2e8ef',
    '100': '#e5d1de',
    '200': '#caa3bd',
    '300': '#b0749b',
    '400': '#95467a',
    '500': '#7b1859',
    '600': '#6c1f55',
    '700': '#5d2751',
    '800': '#4f2e4c',
    '900': '#403648'
  },
  auxilliary4: {
    '50': '#efe9f2',
    '100': '#dfd2e5',
    '200': '#bea4cb',
    '300': '#9e77b1',
    '400': '#7d4997',
    '500': '#5d1c7d',
    '600': '#542372',
    '700': '#4b2966',
    '800': '#43305b',
    '900': '#3a364f'
  }
};
