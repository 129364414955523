/**
 * Helper function to create variations of paths with optional params.
 * (A feature that was removed in react-router 6.0.0).
 *
 * @example
 * ```ts
 * createPathVariations('/users/:userid?/details?/:tab?');
 * // -> [
 * //   '/users',
 * //   '/users/:userid',
 * //   '/users/:userid/details',
 * //   '/users/:userid/details/:tab'
 * // ]
 * ```
 */
export function createPathVariants(path: string): string[] {
  const optionalParamsPattern = /(\/|^)((?::?)[^/:?]+)\?((?:\/[^/?]+)*)(?=$|\/)/g;
  let match = optionalParamsPattern.exec(path);

  if (!match) {
    return [path];
  }

  const variations: string[] = match.index ? [path.substring(0, match.index)] : path.startsWith('/') ? ['/'] : [];

  do {
    let variant = `${path.substring(0, match.index)}${match[1]}${match[2]}${match[3]}`.replace(/\?(?=$|\/)/g, '');
    if (path.startsWith('/') && !variant.startsWith('/')) {
      variant = `/${variant}`;
    }
    variations.push(variant);
    match = optionalParamsPattern.exec(path);
  } while (match);

  return variations;
}
