import { useEffect } from 'react';
import { tryParseAccessToken } from '../../common/api/parseAccessToken';
import { useAuthSessions } from '../../common/auth/useAuthSessions';
import { routes } from '../../common/routing/routes';
import { useAppNavigate } from '../../common/routing/useAppNavigate';
import { useRouteEnvironment } from '../../common/routing/useRouteEnvironment';
import { lastViewedPortfolioKey } from '../../common/storage/storageKeys';
import { useSessionStorageState } from '../../common/storage/useSessionStorage';
import { environments } from '../../config/environments';

/** Redirects to the cockpit page for the environment/region the user last logged in. */
export default function RedirectToCockpit() {
  const authSessions = useAuthSessions();
  const navigate = useAppNavigate();
  const [lastViewedPortfolioId] = useSessionStorageState<string | undefined>(undefined, lastViewedPortfolioKey);

  const backupEnvironment = useRouteEnvironment();

  useEffect(() => {
    const environment = authSessions.lastEnvironment ?? backupEnvironment ?? environments[0];
    const token = authSessions.accessToken ?? tryParseAccessToken(authSessions.lastSession?.accessToken ?? '');

    const portfolioId = lastViewedPortfolioId ?? token?.defaultPortfolio;

    if (!portfolioId) {
      navigate(routes.login, { params: { environment: environment.slug } }, { replace: true });
    } else {
      navigate(routes.cockpitMap, { params: { environment: environment.slug, portfolioId } }, { replace: true });
    }
  }, [authSessions, lastViewedPortfolioId, navigate, backupEnvironment]);

  return null;
}
