import type { LoadScript } from '@react-google-maps/api';
import { type ComponentPropsWithoutRef, lazy } from 'react';

type Props = Omit<ComponentPropsWithoutRef<typeof LoadScript>, keyof (typeof LoadScript)['defaultProps']>;

export const LazyLoadedGoogleMapsLoadScript = lazy(async () => {
  const LoadScript = (await import('@react-google-maps/api')).LoadScript;
  function WrappedLoadScript(props: Props) {
    return <LoadScript {...props} />;
  }
  return { default: WrappedLoadScript };
});
