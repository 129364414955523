import { createContext, useCallback, useContext, useRef, useState, type ReactNode } from 'react';

const HostRegistryContext = createContext<((hostElement: HTMLElement | null) => void) | null>(null);

const HostContext = createContext<HTMLElement | null>(null);

/** @internal */
export function useRegisterPageNavigationHost() {
  return useContext(HostRegistryContext);
}

/** @internal */
export function usePageNavigationHost() {
  return useContext(HostContext);
}

export function ProvidePageNavigation({ children }: { children?: ReactNode }) {
  const [hostElement, setHostElement] = useState<HTMLElement | null>(null);
  const hostRef = useRef(hostElement);

  const registerHost = useCallback((newHost: HTMLElement | null) => {
    if (import.meta.env.DEV) {
      if (hostRef.current && newHost && newHost !== hostRef.current) {
        console.error('Multiple elements as PageNavigationHost: ', hostRef.current, newHost);
        throw new Error(
          'Multiple elements are rendering PageNavigationHost.' +
            ' Most likely you have an invalid component hierarchy, like Layout within a Layout,' +
            ' or two routes rendering inconsistently in the AppRouter.'
        );
      }
      hostRef.current = newHost;
    }

    setHostElement(newHost);
  }, []);

  return (
    <HostRegistryContext.Provider value={registerHost}>
      <HostContext.Provider value={hostElement}>{children}</HostContext.Provider>
    </HostRegistryContext.Provider>
  );
}
