import { createContext, useContext, useEffect, useMemo, type ReactNode } from 'react';
import { Color } from '../css/Color';
import { formatCssPropsAsRule, useStyleRule, type StyleableProps } from '../css/useStyles';
import { colorOpacities, type Theme } from './Theme';
import { polyfillVariablesInMediaQueries } from './polyfillVariablesInMediaQueries';

const ThemeContext = createContext<Theme | null>(null);

/**
 * Use the current theme in a function component.
 *
 * See {@link ProvideTheme} for priving the app-wide theme, {@link ThemedSection} for theming a sub-section.
 */
export function useTheme(): Theme {
  const theme = useContext(ThemeContext);
  if (!theme) {
    throw new Error('For useTheme to work, render ProvideTheme in the the root component.');
  }
  return theme;
}

interface ProvideThemeProps {
  children: ReactNode;
  theme: Theme;
  selector?: string;
}

/**
 * Provide an app-wide theme. Should only be used once at the application root component.
 * Use {@link ThemedSection} to use a different theme in a subsection of the app.
 */
export function ProvideTheme({ children, theme }: ProvideThemeProps) {
  const parentTheme = useContext(ThemeContext);
  if (parentTheme) {
    throw new Error(
      `<ProvideTheme> should only be used on the root app level. Use <ThemedSection> to theme a part of the application differently.`
    );
  }

  // Allow using e.g. `@media(max-width: var(--breakpoint-medium))`
  useEffect(() => polyfillVariablesInMediaQueries(), []);

  const styles = useMemo(() => formatThemeAsCssVariables(theme), [theme]);
  const cssRule = useMemo(() => formatCssPropsAsRule(`:root`, styles), [styles]);
  const reducedMotion = useMemo(() => formatThemeAsPrefersReducedMotionQuery(':root', theme), [theme]);

  useStyleRule(cssRule);
  useStyleRule(reducedMotion);

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

/**
 * @example
 * ```ts
 * const orangeTheme = { colors: { primary: '#9f5f00', ... }, ... };
 * formatThemeAsCssVariables(orangeTheme);
 * // {
 * //   '--color-primary': '#9f5f00',
 * //   ...
 * // }
 * ```
 */
function formatThemeAsCssVariables(theme: Theme): StyleableProps {
  const styles: Record<string, string> = {};

  const pageColor = Color.parse(theme.colors.page);

  for (const [name, colorValue] of Object.entries(theme.colors)) {
    styles[`--color-${name}`] = colorValue;

    const color = Color.parse(colorValue);

    for (const opacity of colorOpacities) {
      const mixedColor = pageColor.overlay(color, opacity / 100);
      styles[`--color-${name}-${opacity}`] = mixedColor.toString();
    }

    const { r, g, b } = color;
    styles[`--color-${name}-rgb`] = `${r} ${g} ${b}`;
  }

  for (const [name, width] of Object.entries(theme.breakpoints)) {
    styles[`--breakpoint-${name}`] = `${width}px`;
  }

  for (const [name, fontFamily] of Object.entries(theme.fonts)) {
    styles[`--font-${name}`] = fontFamily;
  }

  for (const [name, fontSize] of Object.entries(theme.fontSizes)) {
    styles[`--fontSize-${name}`] = typeof fontSize === 'string' ? fontSize : `${fontSize / 16}rem`;
  }

  for (const [name, lineHeight] of Object.entries(theme.lineHeights)) {
    styles[`--lineHeight-${name}`] = typeof lineHeight === 'number' ? lineHeight.toString() : lineHeight;
  }

  for (const [name, iconSize] of Object.entries(theme.iconSizes)) {
    styles[`--iconSize-${name}`] = iconSize;
  }

  for (const [name, { font, size, color, decoration, style, weight }] of Object.entries(theme.textStyles)) {
    styles[`--textStyle-${name}-font`] = `var(--font-${font})`;
    styles[`--textStyle-${name}-size`] = `var(--fontSize-${size})`;
    styles[`--textStyle-${name}-color`] = `var(--color-${color})`;
    let shorthandStyle = `var(--font-${font}), var(--fontSize-${size}), var(--color-${color})`;

    if (decoration != null) {
      shorthandStyle = `${shorthandStyle} ${decoration}`;
      styles[`--textStyle-${name}-textDecoration`] = String(decoration);
    }

    if (style != null) {
      shorthandStyle = `${shorthandStyle} ${style}`;
      styles[`--textStyle-${name}-fontStyle`] = style;
    }

    if (weight != null) {
      shorthandStyle = `${shorthandStyle} ${weight}`;
      styles[`--textStyle-${name}-fontWeight`] = String(weight);
    }

    styles[`--textStyle-${name}`] = shorthandStyle;
  }

  for (const [name, shadow] of Object.entries(theme.shadows)) {
    styles[`--shadow-${name}`] = shadow;
    const shadowColor = Color.findInString(shadow);
    if (shadowColor) {
      const { r, g, b } = shadowColor;
      styles[`--shadow-${name}-color`] = shadowColor.toRGB();
      styles[`--shadow-${name}-color-rgb`] = `${r} ${g} ${b}`;
    }
  }

  for (const [name, duration] of Object.entries(theme.durations)) {
    styles[`--duration-${name}`] = typeof duration === 'number' ? `${duration}ms` : duration;
  }

  return styles as unknown as StyleableProps;
}

/**
 * If users prefer to have no motion, we disable all animations.
 */
function formatThemeAsPrefersReducedMotionQuery(selector: string, theme: Theme): string {
  return `
    @media (prefers-reduced-motion) {
      ${selector} {
        ${Object.keys(theme.durations)
          .map(name => `--duration-${name}: 0s;`)
          .join('\n    ')}
      }
    }
  `;
}
