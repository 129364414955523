import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgLogotypeInsightStackedHorizontal = (props, ref) => /* @__PURE__ */ jsxs("svg", { viewBox: "0 0 207 65", xmlns: "http://www.w3.org/2000/svg", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { "aria-label": "HYDROGRID Logo", children: [
    /* @__PURE__ */ jsx("path", { fill: "#00BFA5", d: "M29.08 65c-1.17 0-2.32-.3-3.34-.87L3.3 51.19A6.7 6.7 0 0 1 0 45.42V19.53a6.56 6.56 0 0 1 3.3-5.72L25.84.87c1-.55 2.1-.84 3.24-.87 1.17 0 2.33.3 3.35.87l22.44 12.94a6.7 6.7 0 0 1 3.3 5.77v25.89a6.56 6.56 0 0 1-3.3 5.72L32.33 64.13c-.99.57-2.11.87-3.25.87Z" }),
    /* @__PURE__ */ jsxs("g", { fill: "#fff", children: [
      /* @__PURE__ */ jsx("path", { d: "M30.3 17.35v-3.87a3.42 3.42 0 0 0 1.21-5.63 3.44 3.44 0 0 0-5.26 4.38c.4.57.97 1 1.62 1.25v3.87a3.42 3.42 0 0 0 1.21 6.64 3.44 3.44 0 0 0 2.83-5.39c-.4-.57-.97-1-1.62-1.25h.01Z" }),
      /* @__PURE__ */ jsx("path", { d: "M50.75 20.07a3.41 3.41 0 0 0-6.03.22c-.3.63-.39 1.33-.27 2.01l-3.35 1.94a3.4 3.4 0 1 0 .9 4.09c.3-.63.39-1.33.27-2.01l3.34-1.94a3.44 3.44 0 0 0 4.88-.53 3.4 3.4 0 0 0 .26-3.78Z" }),
      /* @__PURE__ */ jsx("path", { d: "M30.3 51.57V47.7a3.42 3.42 0 0 0 1.21-5.63 3.44 3.44 0 0 0-5.26 4.38c.4.57.97 1 1.62 1.25v3.87a3.42 3.42 0 0 0 1.21 6.64 3.44 3.44 0 0 0 2.83-5.4c-.4-.57-.96-1-1.62-1.24h.01Z" }),
      /* @__PURE__ */ jsx("path", { d: "M22.25 36.4a3.4 3.4 0 0 0-6.03.23c-.3.62-.4 1.33-.27 2.01l-3.35 1.93a3.35 3.35 0 0 0-3.87-.29 3.4 3.4 0 1 0 4.77 4.43c.3-.63.39-1.33.27-2.01l3.34-1.94a3.42 3.42 0 0 0 5.14-4.36Z" }),
      /* @__PURE__ */ jsx("path", { d: "M49.5 40.28a3.36 3.36 0 0 0-3.88.34l-3.34-1.94a3.35 3.35 0 0 0-2.91-3.93 3.41 3.41 0 1 0 1.74 6.01l3.35 1.94a3.35 3.35 0 0 0 1.65 3.5 3.41 3.41 0 1 0 3.39-5.92Z" }),
      /* @__PURE__ */ jsx("path", { d: "M21 23.94a3.44 3.44 0 0 0-3.88.34l-3.34-1.94a3.36 3.36 0 0 0-6.05-2.53 3.4 3.4 0 0 0 4.89 4.67l3.34 1.94a3.4 3.4 0 1 0 6.63-.38A3.48 3.48 0 0 0 21 23.95v-.01Z" })
    ] })
  ] }),
  /* @__PURE__ */ jsxs("g", { "aria-label": "HYDROGRID", children: [
    /* @__PURE__ */ jsxs("g", { fill: "#00BFA5", children: [
      /* @__PURE__ */ jsx("path", { d: "M81.63 9.16a1.5 1.5 0 0 1 1.5 1.5v13.58a1.5 1.5 0 0 1-2.8.75 1.5 1.5 0 0 1-.21-.75v-5.68h-8.33v5.67a1.51 1.51 0 0 1-2.8.75c-.14-.23-.2-.49-.21-.75V10.66a1.51 1.51 0 0 1 2.26-1.3c.46.27.75.76.75 1.3v5.19h8.43v-5.19c0-.4.16-.78.44-1.06.24-.3.59-.45.97-.44Z" }),
      /* @__PURE__ */ jsx("path", { d: "M99.52 9.45a1.44 1.44 0 0 1 .24 2.09l-5.38 7.27v5.43c.02.8-.61 1.47-1.41 1.5h-.05a1.5 1.5 0 0 1-1.5-1.5v-5.38l-5.47-7.28a1.47 1.47 0 0 1 .1-1.98 1.5 1.5 0 0 1 1.06-.44c.47.01.91.23 1.21.59l4.51 6.06 4.6-6.11c.14-.19.32-.34.53-.44.21-.05.42-.09.63-.1.34-.03.67.08.92.3" }),
      /* @__PURE__ */ jsx("path", { d: "M105.57 12.12v10.61h2.81a4.58 4.58 0 0 0 3.5-1.6 5.95 5.95 0 0 0 1.4-3.73 4.53 4.53 0 0 0-.39-1.99 5.21 5.21 0 0 0-2.57-2.81c-.6-.3-1.26-.44-1.94-.44l-2.81-.04Zm2.82-2.77a7.7 7.7 0 0 1 5.52 2.33c.72.73 1.27 1.6 1.6 2.57a8.5 8.5 0 0 1-1.6 8.77 7.48 7.48 0 0 1-5.52 2.42h-4.27c-.8 0-1.45-.65-1.45-1.45V10.81a1.5 1.5 0 0 1 1.45-1.45l4.27-.01Z" }),
      /* @__PURE__ */ jsx("path", { d: "M122.59 17.25h3.88c.32 0 .65-.05.96-.14.31-.1.6-.26.83-.48.22-.21.4-.46.53-.73.16-.3.23-.64.2-.97 0-.35-.06-.7-.2-1.02-.16-.3-.34-.59-.53-.87a2.44 2.44 0 0 0-.83-.58c-.3-.16-.63-.23-.96-.2h-3.88v4.99Zm8.92 6.06c.14.3.24.6.29.92 0 .4-.16.79-.44 1.07-.26.32-.65.5-1.06.49a1.52 1.52 0 0 1-1.17-.58l-3.73-5.14h-2.81v4.17a1.5 1.5 0 0 1-1.5 1.5c-.8.02-1.47-.6-1.5-1.41V10.86c-.03-.42.16-.82.49-1.07a1.5 1.5 0 0 1 1.06-.44h5.28c1.44 0 2.82.58 3.84 1.6.47.52.86 1.1 1.16 1.75.29.67.44 1.4.44 2.13a4.88 4.88 0 0 1-.88 2.9 5.92 5.92 0 0 1-2.23 1.9l2.76 3.68Z" }),
      /* @__PURE__ */ jsx("path", { d: "M137.66 17.45c0 .73.15 1.46.44 2.13a5.2 5.2 0 0 0 9.69 0c.58-1.36.58-2.9 0-4.27a5.2 5.2 0 0 0-9.69.01 5.4 5.4 0 0 0-.44 2.13Zm-3.01 0a8.14 8.14 0 0 1 8.24-8.24c1.1-.01 2.19.2 3.21.63a8.29 8.29 0 0 1-6.41 15.27 8.38 8.38 0 0 1-4.41-4.42 9.5 9.5 0 0 1-.63-3.24Z" })
    ] }),
    /* @__PURE__ */ jsxs("g", { fill: "#4A5860", children: [
      /* @__PURE__ */ jsx("path", { d: "M153 17.16a8.1 8.1 0 0 1 11.1-7.53c.46.15.9.36 1.3.62.42.23.78.54 1.07.9a1.46 1.46 0 0 1 .4 1c0 .33-.13.64-.37.85-.23.28-.58.42-.94.4-.24.02-.47-.04-.68-.15l-.6-.4c-.4-.32-.85-.58-1.31-.79a5.46 5.46 0 0 0-3.9.1 5 5 0 0 0-1.63 1.15 4.5 4.5 0 0 0-1.1 1.74 5.3 5.3 0 0 0-.4 2.1c0 .71.15 1.42.4 2.1.25.64.62 1.22 1.1 1.71a4.9 4.9 0 0 0 3.62 1.56c.66.01 1.32-.1 1.94-.3a3.68 3.68 0 0 0 1.63-1v-2.56h-3.57c-.38 0-.76-.13-1.06-.38a1.22 1.22 0 0 1-.4-1 1.3 1.3 0 0 1 .9-1.23c.18-.07.37-.1.56-.08h5c.39.01.76.15 1.07.4.28.26.43.62.4 1v4.22c0 .35-.13.69-.38.94a8.19 8.19 0 0 1-2.74 2.06 8.46 8.46 0 0 1-6.53.07 8.08 8.08 0 0 1-4.31-4.32 9.96 9.96 0 0 1-.57-3.18Z" }),
      /* @__PURE__ */ jsx("path", { d: "M174.27 16.94h3.79c.31 0 .63-.06.93-.16a2 2 0 0 0 .78-.47c.46-.44.73-1.04.75-1.68 0-.35-.07-.69-.22-1-.14-.3-.32-.6-.53-.84a2.92 2.92 0 0 0-.78-.6c-.29-.15-.6-.22-.93-.22h-3.79v4.97Zm8.84 5.93c.17.28.26.59.26.9-.02.4-.16.77-.41 1.07a1.36 1.36 0 0 1-1.06.47c-.22 0-.44-.06-.63-.16a3.59 3.59 0 0 1-.53-.4l-3.68-5.06h-2.75v4.15c-.01.4-.15.77-.41 1.06-.28.3-.66.44-1.06.4a1.43 1.43 0 0 1-1.47-1.4V10.7c0-.41.16-.8.47-1.06a1.46 1.46 0 0 1 1.06-.41h5.22c.72 0 1.42.14 2.09.4a5.37 5.37 0 0 1 3.25 5 4.9 4.9 0 0 1-.84 2.84 5.36 5.36 0 0 1-2.22 1.84l2.72 3.56Z" }),
      /* @__PURE__ */ jsx("path", { d: "M187.05 10.47c.01-.38.16-.76.4-1.06a1.35 1.35 0 0 1 1.07-.4c.39 0 .76.15 1.06.4.28.28.42.67.4 1.06v13.34c0 .39-.15.76-.4 1.07-.28.28-.67.43-1.06.4a1.44 1.44 0 0 1-1.47-1.4v-13.4Z" }),
      /* @__PURE__ */ jsx("path", { d: "M196.98 11.91v10.4h2.76c.65 0 1.3-.15 1.9-.4.58-.28 1.1-.68 1.53-1.16a5.75 5.75 0 0 0 1.37-3.68c0-.67-.12-1.33-.37-1.94a5.07 5.07 0 0 0-2.53-2.78 4.42 4.42 0 0 0-1.9-.4h-2.76v-.04Zm2.72-2.69c1.03-.01 2.06.2 3 .63a7.27 7.27 0 0 1 3.96 4.21 8.16 8.16 0 0 1 0 6.1c-.37.92-.9 1.78-1.56 2.52a7.39 7.39 0 0 1-5.4 2.38h-4.22a1.41 1.41 0 0 1-1.4-1.4V10.62a1.38 1.38 0 0 1 1.4-1.4h4.22Z" })
    ] })
  ] }),
  /* @__PURE__ */ jsxs("g", { "aria-label": "INSIGHT", fill: "#4A5860", children: [
    /* @__PURE__ */ jsx("path", { d: "M69 33.19v19.87a2.2 2.2 0 0 0 2.19 2.2 2.2 2.2 0 0 0 2.19-2.2V33.2a2.2 2.2 0 0 0-2.2-2.19A2.2 2.2 0 0 0 69 33.19Z" }),
    /* @__PURE__ */ jsx("path", { d: "M94.53 53.87a2.5 2.5 0 0 0 1.99 1.05h1.45a2.24 2.24 0 0 0 2.22-2.2V33.17c0-1.18-.98-2.16-2.22-2.16-1.25 0-2.23.98-2.23 2.16v12.63l.17 3.16-1.58-2.83-9.57-13.74a2.6 2.6 0 0 0-1.98-1.04h-1.45a2.26 2.26 0 0 0-2.22 2.19v19.53a2.2 2.2 0 0 0 2.22 2.2 2.2 2.2 0 0 0 2.22-2.2v-12.6l-.17-3.16 1.59 2.83 9.56 13.74Z" }),
    /* @__PURE__ */ jsx("path", { d: "M113.56 51.28a5.38 5.38 0 0 1-4.51-2.16c-.4-.5-1.11-1.18-2.16-1.18-1.2 0-1.98.84-1.98 2.06 0 1.58 3.1 5.25 8.59 5.25 4.95 0 8.82-2.93 8.82-7.3 0-8.16-12.4-6.07-12.4-10.45 0-1.35 1.55-2.63 3.88-2.63 1.89 0 3.13.85 3.94 1.89.44.57 1.08 1.15 1.95 1.15a2.01 2.01 0 0 0 2.1-2c0-1.6-2.74-4.91-8.1-4.91-4.94 0-8.08 3.07-8.08 6.77 0 8.25 12.23 5.86 12.23 10.4 0 1.76-1.89 3.1-4.28 3.1Z" }),
    /* @__PURE__ */ jsx("path", { d: "M127.37 33.19v19.87a2.2 2.2 0 0 0 2.2 2.2 2.2 2.2 0 0 0 2.18-2.2V33.2a2.2 2.2 0 0 0-2.19-2.19 2.2 2.2 0 0 0-2.19 2.19Z" }),
    /* @__PURE__ */ jsx("path", { d: "M136.8 43.13c0 6.63 5.43 12.12 12.06 12.12 3.5 0 6.84-1.41 9.13-4.07.34-.4.54-.91.54-1.42v-6.2a2.2 2.2 0 0 0-2.19-2.15h-7.48c-1.21 0-2.19.8-2.19 1.99 0 1.24.98 2.08 2.2 2.08h5.28v3.84a7.5 7.5 0 0 1-5.29 1.92c-4.24 0-7.68-3.63-7.68-8.11 0-4.45 3.44-8.12 7.68-8.12 2.2 0 3.7.8 4.79 1.65.57.47 1.04.84 1.88.84a1.98 1.98 0 0 0 1.92-1.89c0-.5-.2-1.14-.6-1.54-1.82-1.96-5.02-3.07-7.99-3.07a12.15 12.15 0 0 0-12.06 12.13Z" }),
    /* @__PURE__ */ jsx("path", { d: "M182.58 31c-1.18 0-2.19.94-2.19 2.19v7.58h-12.26v-7.58c0-1.25-.98-2.19-2.19-2.19s-2.19.94-2.19 2.19v19.9c0 1.22.98 2.16 2.19 2.16s2.19-.94 2.19-2.15v-8.32h12.26v8.32a2.18 2.18 0 0 0 2.19 2.15c1.21 0 2.19-.94 2.19-2.15V33.19c0-1.25-.98-2.19-2.19-2.19Z" }),
    /* @__PURE__ */ jsx("path", { d: "M204.81 31.34h-14.79c-1.2 0-2.22.77-2.22 2.02 0 1.21 1.01 2.02 2.22 2.02h5.16v17.65a2.22 2.22 0 1 0 4.45 0V35.38h5.18c1.21 0 2.19-.81 2.19-2.02 0-1.25-.98-2.02-2.19-2.02Z" })
  ] })
] });
const ForwardRef = forwardRef(SvgLogotypeInsightStackedHorizontal);
export default ForwardRef;
