import { useLocales } from '@hydrogrid/design-system';
import { useDeepReusedWithCallbacks } from '@hydrogrid/utilities/memoization';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, type ReactNode } from 'react';
import type { AppAction } from './useAppActions';

interface AppActionsRegistry {
  add: (action: AppAction) => void;
  remove: (prev: AppAction) => void;
}

const AppActionsContext = createContext<readonly AppAction[] | null>(null);

const AppActionsRegistryContext = createContext<AppActionsRegistry | null>(null);

function useAppActionsContext() {
  const context = useContext(AppActionsContext);
  if (!context) {
    throw new Error('Call useAppAction inside TrackAppActions.');
  }
  return context;
}

function useAppActionsRegistryContext() {
  const context = useContext(AppActionsRegistryContext);
  if (!context) {
    throw new Error('Call useAppActionsRegistry inside TrackAppActions.');
  }
  return context;
}

export function TrackAppActions({ children }: { children: ReactNode }) {
  const [actions, setActions] = useState<AppAction[]>([]);
  const sortLocale = useLocales().collator;

  const add = useCallback((action: AppAction) => {
    setActions(current => [...current, action]);
  }, []);

  const remove = useCallback((action: AppAction) => {
    setActions(current => current.filter(item => item !== action));
  }, []);

  const registry = useMemo((): AppActionsRegistry => {
    return { add, remove };
  }, [add, remove]);

  const sortedActions = useMemo(() => {
    return [...actions].sort(
      (a, b) => (a.group ?? '').localeCompare(b.group ?? '', sortLocale) || 0 // a.name.localeCompare(b.name, sortLocale)
    );
  }, [actions, sortLocale]);

  return (
    <AppActionsRegistryContext.Provider value={registry}>
      <AppActionsContext.Provider value={sortedActions}>{children}</AppActionsContext.Provider>
    </AppActionsRegistryContext.Provider>
  );
}

export function useTrackAppActions(actions: AppAction[]) {
  const { add, remove } = useAppActionsRegistryContext();
  const stableActions = useDeepReusedWithCallbacks(actions);
  const prevActions = useRef<AppAction[]>([]);

  useEffect(() => {
    for (const action of prevActions.current) {
      if (!stableActions.includes(action)) {
        remove(action);
      }
    }

    for (const action of stableActions) {
      if (!prevActions.current.includes(action)) {
        add(action);
      }
    }

    prevActions.current = stableActions;
  }, [stableActions, add, remove]);

  useEffect(() => {
    return () => {
      prevActions.current.forEach(remove);
      prevActions.current = [];
    };
  }, [remove]);
}

export function useAvailableAppActions() {
  const actions = useAppActionsContext();
  return actions;
}
