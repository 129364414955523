import { toast } from '@hydrogrid/design-system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { UserSettings } from '../../stores/UserSettingsStore';
import { ApiError, isProblemJson } from '../ApiError';
import { api } from '../InsightApi';
import type { AnySettingType } from '../generated-client/Schemas';

export function useUpdateSettingMutation({ isSettingsMatching }: { isSettingsMatching: (data: unknown) => data is UserSettings }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ setting, body }: { setting: string; body: AnySettingType }) =>
      api.dashboard.user.upsertSetting({ setting_name: setting, body }),

    onMutate: newSetting => {
      const previousSettings = queryClient.getQueryData(['user-settings']);

      queryClient.setQueryData(['user-settings'], (oldSettings: unknown) => {
        if (!isSettingsMatching(oldSettings)) return;
        return { ...oldSettings, ...newSetting };
      });
      return previousSettings;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['user-settings'] });
    },
    onError: error => {
      if (!(error instanceof ApiError)) return;

      if (!isProblemJson(error.body)) {
        toast({ status: 'error', description: `There was a problem saving the 'outdated after' user setting.` });
        return;
      }
      toast({ status: 'error', description: error.body.detail, title: 'User setting could not be saved' });
    }
  });
}
