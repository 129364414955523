/**
 * @file "Inspired" by the great work by Diego Haz
 * https://github.com/reakit/reakit/blob/master/packages/reakit-utils/src/tabbable.ts
 */

import { isHTMLElement } from './isElement';

function hasTabIndex(element: HTMLElement) {
  return element.hasAttribute('tabindex');
}

function hasNegativeTabIndex(element: HTMLElement) {
  return hasTabIndex(element) && element.tabIndex < 0;
}

function isDisabled(element: HTMLElement) {
  return Boolean(element.getAttribute('disabled') || element.getAttribute('aria-disabled'));
}

function isHidden(element: HTMLElement) {
  if (element.parentElement && isHidden(element.parentElement)) {
    return true;
  }
  return element.hidden;
}

function isContentEditable(element: HTMLElement) {
  const value = element.getAttribute('contenteditable');
  return value !== 'false' && value != null;
}

const focusableTags = ['input', 'select', 'textarea', 'button'];

export function isFocusable(element: HTMLElement) {
  if (!isHTMLElement(element) || isHidden(element) || isDisabled(element)) {
    return false;
  }

  const { localName } = element;

  if (focusableTags.includes(localName)) {
    return true;
  }

  if (localName === 'a') {
    return element.hasAttribute('href');
  }

  if (localName === 'audio' || localName === 'video') {
    return element.hasAttribute('controls');
  }

  if (isContentEditable(element)) {
    return true;
  }

  return hasTabIndex(element);
}

/** Returns `true` if the passed element can be focused by the user via "Tab". */
export function isTabbable(element?: HTMLElement | null) {
  return element != null && isHTMLElement(element) && isFocusable(element) && !hasNegativeTabIndex(element);
}
