import { Suspense, type ReactNode } from 'react';
import { AppVersions } from '../../components/AppVersions/AppVersions';
import styles from './CenteredLayout.module.css';

export function CenteredLayout({ children }: { children?: ReactNode }) {
  return (
    <div className={styles.page}>
      <Suspense fallback={<div className={styles.loadingSkeleton} />}>{children}</Suspense>
      <AppVersions />
    </div>
  );
}
