import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgLogoHexSquare = (props, ref) => /* @__PURE__ */ jsxs("svg", { viewBox: "0 0 65 65", xmlns: "http://www.w3.org/2000/svg", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fill: "#0bb7a0", d: "M32.495 65c-1.17 0-2.32-.3-3.34-.87l-22.44-12.94a6.7 6.7 0 0 1-3.3-5.77v-25.89a6.56 6.56 0 0 1 3.3-5.72l22.54-12.94a7.05 7.05 0 0 1 3.24-.87c1.17 0 2.33.3 3.35.87l22.44 12.94a6.7 6.7 0 0 1 3.3 5.77v25.89a6.56 6.56 0 0 1-3.3 5.72l-22.54 12.94c-.99.57-2.11.87-3.25.87" }),
  /* @__PURE__ */ jsxs("g", { fill: "#fff", children: [
    /* @__PURE__ */ jsx("path", { d: "M33.715 17.35v-3.87a3.42 3.42 0 0 0 2.22-3.2 3.44 3.44 0 1 0-6.88 0 3.42 3.42 0 0 0 2.23 3.2v3.87a3.42 3.42 0 0 0-2.23 3.2 3.44 3.44 0 0 0 6.88 0 3.42 3.42 0 0 0-2.23-3.2z" }),
    /* @__PURE__ */ jsx("path", { d: "M54.165 20.07a3.41 3.41 0 0 0-4.65-1.26 3.35 3.35 0 0 0-1.65 3.49l-3.35 1.94a3.4 3.4 0 1 0-.48 5.57 3.35 3.35 0 0 0 1.65-3.49l3.34-1.94a3.44 3.44 0 0 0 3.88.34 3.4 3.4 0 0 0 1.26-4.65z" }),
    /* @__PURE__ */ jsx("path", { d: "M33.715 51.57v-3.87a3.42 3.42 0 0 0 2.22-3.2 3.44 3.44 0 0 0-6.88 0 3.42 3.42 0 0 0 2.23 3.2v3.87a3.42 3.42 0 0 0-2.23 3.2 3.44 3.44 0 0 0 6.88 0 3.37 3.37 0 0 0-2.23-3.2z" }),
    /* @__PURE__ */ jsx("path", { d: "M25.665 36.4a3.41 3.41 0 0 0-4.65-1.26 3.35 3.35 0 0 0-1.65 3.5l-3.35 1.93a3.35 3.35 0 0 0-3.87-.29 3.4 3.4 0 0 0 3.39 5.91 3.35 3.35 0 0 0 1.65-3.49l3.34-1.94a3.42 3.42 0 0 0 5.14-4.36z" }),
    /* @__PURE__ */ jsx("path", { d: "M52.905 40.28a3.36 3.36 0 0 0-3.88.34l-3.34-1.94a3.35 3.35 0 0 0-1.65-3.5 3.4 3.4 0 1 0-3.4 5.92 3.44 3.44 0 0 0 3.88-.34l3.35 1.94a3.35 3.35 0 0 0 1.65 3.5 3.4 3.4 0 1 0 3.39-5.92z" }),
    /* @__PURE__ */ jsx("path", { d: "M24.405 23.94a3.44 3.44 0 0 0-3.88.34l-3.34-1.94a3.36 3.36 0 0 0-6.05-2.53 3.4 3.4 0 0 0 4.89 4.67l3.34 1.94a3.4 3.4 0 0 0 6.3 2.23 3.48 3.48 0 0 0-1.26-4.7Z" })
  ] })
] });
const ForwardRef = forwardRef(SvgLogoHexSquare);
export default ForwardRef;
