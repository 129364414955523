import { PageNavigationHost, classNames, useBreakpoint } from '@hydrogrid/design-system';
import Logo from '../../assets/icons/logo-hex-square.svg?react';
import Logotype from '../../assets/icons/logotype-insight-stacked-horizontal.svg?react';
import { UserMenu } from '../UserMenu/UserMenu';
import styles from './AppHeader.module.css';

export function AppHeader({ className }: { className?: string }) {
  const medium = useBreakpoint('medium');
  const LogoToShow = medium.above ? Logotype : Logo;

  return (
    <header className={classNames(styles.header, className)}>
      <div role="banner" className={styles.logoSection}>
        <LogoToShow className={styles.logo} aria-label="HYDROGRID Insight" />
      </div>
      <PageNavigationHost as="nav" className={styles.navigation} />
      <UserMenu />
    </header>
  );
}
