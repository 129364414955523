import { guessNameFromEmail } from '@hydrogrid/utilities/string';

export function getInitialsFromName(fullName: string, maxLetters = 3) {
  const matches = fullName.matchAll(/(?:^|\P{L})(\p{L})/gu);
  const initials = Array.from(matches ?? [], ([_, initial]) => initial)
    .join('')
    .toUpperCase();

  const length = Math.min(initials.length, maxLetters);
  const fromFront = Math.ceil(length / 2);
  const fromBack = length - fromFront;

  return initials.substring(0, fromFront) + initials.substring(initials.length - fromBack);
}

export function getInitialsFromEmail(email: string, maxLetters = 3) {
  return getInitialsFromName(guessNameFromEmail(email), maxLetters);
}
