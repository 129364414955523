import type { CSSProperties, ReactNode } from 'react';
import { forwardRef } from 'react';
import { pickThemeableProps, useThemeableStyles, type ThemeableProps } from '../../css/useThemeableStyles';
import { classNames } from '../../utils/classNames';
import styles from './Text.module.css';

interface TextProps extends Partial<ThemeableProps> {
  /** @default `"p"` */
  as?: 'p' | 'span' | 'div' | 'em' | 'strong' | 'small' | 'big' | 'code' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children?: ReactNode;
  className?: string;
  style?: CSSProperties | undefined;
}

export const Text = forwardRef<HTMLParagraphElement, TextProps>((props, ref) => {
  const [themeableProps, otherProps] = pickThemeableProps(props);
  const themeableStylesClassName = useThemeableStyles('Text', { margin: 0, ...themeableProps });

  const { children, className, as: TagName = 'p', style, ...extraProps } = otherProps;

  const combinedClassName = classNames(TagName === 'p' && styles.paragraph, themeableStylesClassName, className);

  return (
    <TagName className={combinedClassName} style={style} {...extraProps} ref={ref}>
      {children}
    </TagName>
  );
});
