import type { KeyboardEvent as ReactKeyboardEvent, MouseEvent as ReactMouseEvent } from 'react';

/** Returns a string representation of the pressed keys / mouse buttons. */
export function keyCombination(event: KeyboardEvent | ReactKeyboardEvent | MouseEvent | ReactMouseEvent) {
  let { key, ctrlKey, altKey, metaKey, shiftKey } = event as KeyboardEvent;
  if (key === ' ') {
    key = 'Space';
  }

  const keyUpper = key?.toUpperCase();
  const isBaseKey = key.length === 1 && keyUpper !== key?.toLowerCase() && keyUpper.length === 1;
  const isSymbol = key.length === 1 && !isBaseKey;

  const isMouseEvent = (event as MouseEvent).buttons != null;
  const mouseButtons = (event as MouseEvent).buttons ?? 0;

  const keys = [
    ctrlKey && 'Ctrl',
    altKey && 'Alt',
    metaKey && 'Meta',
    shiftKey && (isBaseKey || !isSymbol || isMouseEvent) && 'Shift',
    isBaseKey ? keyUpper : ['Control', 'Alt', 'Shift', 'Meta'].includes(key) ? '' : key,
    mouseButtons & 1 && 'LeftClick',
    mouseButtons & 2 && 'RightClick',
    mouseButtons & 4 && 'MiddleClick'
  ];

  return keys.filter(Boolean).join('+');
}
