import { createLocalStore } from '@hydrogrid/utilities/stores';
import type { UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { combine } from 'zustand/middleware';
import type { CompleteConstraint } from '../../../common/api/generated-client/Schemas';
import { useCombineQueries } from '../../../common/api/query-utils/combineQueries';
import { fallbackTimeZone } from '../../../common/business-logic/timeZones';

type State = {
  components: string[] | 'all';
  search: string;
  type: string[] | 'all';
  activeBetween: { start: Temporal.PlainDate; end: Temporal.PlainDate } | null;
};

const defaultState: State = {
  components: 'all',
  search: '',
  type: 'all',
  activeBetween: null
};

export const [useConstraintFiltersStore, ConstraintFilterProvider] = createLocalStore('ConstraintFiltersStore', () =>
  combine(
    defaultState,

    set => ({
      actions: {
        clearFilters: () => {
          set({
            ...defaultState
          });
        },
        setComponents: (components: string[] | 'all') => {
          set({ components });
        },
        setSearch: (search: string) => {
          set({ search });
        },
        setType: (type: string[] | 'all') => {
          set({ type });
        },
        setActiveBetween: (activeBetween: { start: Temporal.PlainDate; end: Temporal.PlainDate } | null) => {
          set({ activeBetween });
        }
      }
    })
  )
);

export function useIsFilterConstraintActive() {
  const filters = useConstraintFiltersStore(state => state);
  const { actions: _actions, ...restFilters } = filters;
  return !_.isEqual(restFilters, defaultState);
}

type ConstraintFiltered = UseQueryResult<CompleteConstraint[]>;

export function useFilterConstraints(constraints: UseQueryResult<CompleteConstraint[]>): ConstraintFiltered {
  const filters = useConstraintFiltersStore(state => state);
  const { actions: _actions, ...restFilters } = filters;

  const filteredConstraints = useCombineQueries(constraints, constraints => {
    if (_.isEqual(restFilters, defaultState)) {
      return constraints;
    }

    const filteredConstraints = constraints.filter(constraint => {
      if (filters.components != 'all') {
        if (!filters.components.includes(constraint.constrainable_id)) return false;
      }

      if (filters.search != '') {
        const searchRegex = new RegExp(filters.search, 'i');
        const isIncludedInSearch =
          searchRegex.test(constraint.constrainable_id ?? '') ||
          searchRegex.test(constraint.constrainable_name ?? '') ||
          searchRegex.test(constraint.email ?? '') ||
          searchRegex.test(constraint.type_display_name ?? '');
        if (!isIncludedInSearch) return false;
      }

      if (filters.type != 'all') {
        if (!filters.type.includes(constraint.type_name)) return false;
      }

      if (filters.activeBetween != null) {
        const filterStart = filters.activeBetween.start;
        const filterEnd = filters.activeBetween.end;

        if (constraint.deactivated_timestamp_millis != null) {
          const wasDisabled = Temporal.Instant.fromEpochMilliseconds(constraint.deactivated_timestamp_millis)
            .toZonedDateTimeISO(fallbackTimeZone)
            .toPlainDate();

          if (Temporal.PlainDate.compare(wasDisabled, filterStart) === -1) return false;
        }

        if (constraint.validity_start_timestamp_millis != null) {
          const constraintStart = Temporal.Instant.fromEpochMilliseconds(constraint.validity_start_timestamp_millis)
            .toZonedDateTimeISO(fallbackTimeZone)
            .toPlainDate();

          if (Temporal.PlainDate.compare(filterEnd, constraintStart) === -1) return false;
        }

        if (constraint.validity_end_timestamp_millis != null) {
          const constraintEnd = Temporal.Instant.fromEpochMilliseconds(constraint.validity_end_timestamp_millis)
            .toZonedDateTimeISO(fallbackTimeZone)
            .toPlainDate();

          if (Temporal.PlainDate.compare(constraintEnd, filterStart) === -1) return false;
        }
      }

      return true;
    });

    return filteredConstraints;
  });

  return filteredConstraints;
}
