import { Box, Textarea as ChakraTextarea, Flex, type TextareaProps as ChakraTextareaProps, type FlexProps } from '@chakra-ui/react';
import { forwardRef, useRef } from 'react';
import { CloseCrossIcon } from '../../icons';

export interface TextareaProps extends Omit<ChakraTextareaProps, 'onChange'> {
  onChange: (value: string) => void;
  isClearable?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(textareaProps, ref) {
  const {
    onChange,
    resize = 'none',
    isClearable = true,
    flexShrink = undefined as never,
    flexGrow = undefined as never,
    ...props
  } = textareaProps;

  const textareaRef = useRef<HTMLTextAreaElement>();
  const showClearableButton = Boolean(props.value) && isClearable && !Boolean(props.isReadOnly) && !Boolean(props.isDisabled);

  return (
    <Box
      pos="relative"
      _focusWithin={{ '[data-clear-button]:not(:focus-visible):not(:active)': { opacity: '0!important', pointerEvents: 'none' } }}
      {...{ flexShrink, flexGrow }}
    >
      <ChakraTextarea
        ref={node => {
          textareaRef.current = node ?? undefined;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        {...props}
        resize={resize}
        data-peer
        onChange={e => {
          onChange?.(e.target.value);
        }}
      />
      {showClearableButton && (
        <ClearButton
          onClick={() => {
            onChange?.('');
            textareaRef.current?.focus();
          }}
        />
      )}
    </Box>
  );
});

function ClearButton({ onClick }: Pick<FlexProps, 'onClick'>) {
  return (
    <Flex
      zIndex={1}
      pos="absolute"
      top={1}
      right={1}
      align="center"
      fontSize="xs"
      rounded="full"
      pointerEvents="none"
      _peerHover={{ '& [data-clear-button]': { opacity: 1 } }}
      onClick={e => {
        if (document.activeElement !== e.target) return;
        onClick?.(e);
      }}
    >
      <Box
        data-clear-button
        as="button"
        type="button"
        cursor="pointer"
        pointerEvents="auto"
        p={1.5}
        bg="white"
        border="1px"
        borderColor="transparent"
        rounded="full"
        transition="opacity .2s"
        opacity={0}
        _hover={{ opacity: 1 }}
        _focusVisible={{ opacity: 1, outline: 'none', boxShadow: 'focus', borderColor: 'primary' }}
        aria-label="Clear the input field"
        sx={{ '& > *': { pointerEvents: 'none' } }}
      >
        <CloseCrossIcon size="font" />
      </Box>
    </Flex>
  );
}
