import { Flex, type ChakraProps, type FlexProps } from '@chakra-ui/react';

interface RibbonProps extends FlexProps {
  textContent: string;
  ribbonColor?: ChakraProps['color'];
  isMenuCollapsed?: boolean;
  isScrollBarVisible?: boolean;
}

export function NavigationBarRibbon({
  textContent,
  ribbonColor,
  isMenuCollapsed = false,
  isScrollBarVisible = false,
  ...props
}: RibbonProps) {
  return (
    <Flex
      pos="absolute"
      top={1}
      right={[-8, isScrollBarVisible ? -5.5 : -7.5]}
      fontSize="xs"
      fontWeight="bold"
      py={[0.5, null, isMenuCollapsed ? 0.5 : 1]}
      w={23}
      justify="center"
      transform="rotate(45deg)"
      bgColor={ribbonColor ?? 'accent.400'}
      {...props}
    >
      {textContent}
    </Flex>
  );
}
