import { formAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const $fg = cssVar('form-control-color');

const baseStyleRequiredIndicator = defineStyle({
  marginStart: '1',
  [$fg.variable]: 'colors.red.500',
  _dark: {
    [$fg.variable]: 'colors.red.300'
  },
  color: $fg.reference
});

const baseStyleHelperText = defineStyle({
  mt: '0',
  [$fg.variable]: 'colors.gray.600',
  _dark: {
    [$fg.variable]: 'colors.whiteAlpha.600'
  },
  color: $fg.reference,
  textStyle: 'bodySm'
});

const baseStyle = definePartsStyle({
  container: {
    width: '100%',
    position: 'relative'
  },
  requiredIndicator: baseStyleRequiredIndicator,
  helperText: baseStyleHelperText
});

export const formControlTheme = defineMultiStyleConfig({
  baseStyle
});
