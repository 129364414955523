import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['container', 'text']);

const sizes = {
  sm: helpers.definePartsStyle({
    container: {
      py: '1.5',
      px: '3'
    },
    text: {
      fontSize: 'sm'
    }
  }),
  md: helpers.definePartsStyle({
    container: {
      py: '3',
      px: '3'
    },
    text: {
      fontSize: 'md'
    }
  }),
  lg: helpers.definePartsStyle({
    container: {
      py: '4',
      px: '4'
    },
    text: {
      fontSize: 'lg'
    }
  })
};

const variants = {
  outline: helpers.definePartsStyle(() => {
    return {
      container: {
        borderWidth: '1px'
      }
    };
  }),
  filled: helpers.definePartsStyle(() => {
    return {
      container: {
        backgroundColor: `secondary.50`
      }
    };
  }),
  unstyled: {}
};

export const headerSectionTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      backgroundColor: 'page.40',
      borderRadius: 'base',
      color: 'chakra-body-text',
      borderWidth: '1',
      borderColor: `secondary.100`
    },
    text: {
      fontSize: 'sm',
      fontWeight: 'bold'
    }
  },
  variants,
  sizes,
  defaultProps: {
    variant: 'filled',
    colorScheme: 'secondary',
    size: 'md'
  }
});
