import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const numericStyles = defineStyle({
  '&[data-is-numeric=true]': {
    textAlign: 'end'
  }
});

const baseStyle = definePartsStyle({
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderCollapse: 'collapse',
    width: 'full',
    lineHeight: '140%'
  },
  th: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    letterSpacing: 'wider',
    textAlign: 'start',
    ...numericStyles
  },
  td: {
    textAlign: 'start',
    ...numericStyles
  },
  caption: {
    mt: 4,
    fontFamily: 'heading',
    textAlign: 'center',
    fontWeight: 'medium'
  }
});

const variantSimple = definePartsStyle(props => {
  const { colorScheme: c } = props;

  return {
    thead: {
      pos: 'relative',
      boxShadow: `0px 1px 0px var(--chakra-colors-${c}-100)`
    },
    th: {
      color: `${c}.600`
    },
    tbody: {
      tr: {
        borderBottom: '1px',
        borderColor: `${c}.100`
      }
    },
    caption: {
      color: `${c}.600`
    },
    tfoot: {
      color: c,
      backgroundColor: `${c}.100`,
      pos: 'relative',
      boxShadow: `0px -0.5px 0px var(--chakra-colors-${c}-200)`,
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
});

const variantStripe = definePartsStyle(props => {
  const { colorScheme: c } = props;

  return {
    thead: {
      pos: 'relative',
      boxShadow: `0px 1px 0px var(--chakra-colors-${c}-100)`
    },
    th: {
      color: 'secondary.600'
    },
    td: {
      borderBottom: '1px',
      borderColor: 'transparent'
    },
    caption: {
      color: 'secondary.600'
    },
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          background: `${c}.50`,
          'th, td': {
            borderBottomWidth: '1px',
            borderColor: `${c}.50`
          },
          td: {
            background: `${c}.50`
          }
        }
      }
    },
    tfoot: {
      color: 'secondary',
      backgroundColor: 'secondary.100',
      pos: 'relative',
      boxShadow: `0px -0.5px 0px var(--chakra-colors-${c}-200)`,
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
});

const variants = {
  simple: variantSimple,
  striped: variantStripe,
  unstyled: defineStyle({})
};

const sizes = {
  sm: definePartsStyle({
    th: {
      px: '2',
      py: '1',
      fontSize: 'xs'
    },
    td: {
      px: '2',
      py: '1',
      fontSize: 'xs'
    },
    caption: {
      px: '2',
      py: '1',
      fontSize: 'xs'
    }
  }),
  md: definePartsStyle({
    th: {
      px: '3',
      py: '2',
      fontSize: 'sm'
    },
    td: {
      px: '3',
      py: '2',
      fontSize: 'sm'
    },
    caption: {
      px: '3',
      py: '2',
      fontSize: 'sm'
    }
  }),
  lg: definePartsStyle({
    th: {
      px: '7',
      py: '4',
      fontSize: 'sm'
    },
    td: {
      px: '7',
      py: '4',
      fontSize: 'sm'
    },
    caption: {
      px: '7',
      py: '4',
      fontSize: 'md'
    }
  })
};

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'simple',
    size: 'md',
    colorScheme: 'gray'
  }
});
