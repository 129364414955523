import type { ReactNode } from 'react';

export function usePasswordRequirements(password: string) {
  // Password requirements:
  // https://bitbucket.hydrogrid.ai/projects/HYD/repos/public_api_v1/browse/services/auth/authentication_module.py?at=16a7821#276-286
  const containsSpecialCharacter = /[!"#$%&'()*+, -./:;<=>?@[\\\]^_`{|}~#]/.test(password);
  const containsNumber = /\d/.test(password);
  const containsUppercase = /[A-Z]/.test(password);
  const containsLowercase = /[a-z]/.test(password);
  const isLongEnough = password.length >= 12;

  const isValid = password !== '' && containsSpecialCharacter && containsNumber && containsUppercase && containsLowercase && isLongEnough;

  return {
    isValid,
    containsSpecialCharacter,
    containsNumber,
    containsUppercase,
    containsLowercase,
    isLongEnough
  };
}

export function PasswordRequirement({ valid, children }: { valid: boolean; children: ReactNode }) {
  if (valid) {
    return <>{children}</>;
  } else {
    return <span style={{ color: '#F46D43' }}>{children}</span>;
  }
}

export function PasswordRequirements({
  touched,
  containsSpecialCharacter,
  containsNumber,
  containsUppercase,
  containsLowercase,
  isLongEnough
}: {
  touched: boolean;
  containsSpecialCharacter: boolean;
  containsNumber: boolean;
  containsUppercase: boolean;
  containsLowercase: boolean;
  isLongEnough: boolean;
}) {
  return (
    <div data-testid="passwordRequirements">
      {'Password must contain '}
      <PasswordRequirement valid={!touched || containsNumber}>numbers</PasswordRequirement>
      {', '}
      <PasswordRequirement valid={!touched || containsSpecialCharacter}>special characters</PasswordRequirement>
      {', '}
      <PasswordRequirement valid={!touched || containsUppercase}>uppercase</PasswordRequirement>
      {' and '}
      <PasswordRequirement valid={!touched || containsLowercase}>lowercase</PasswordRequirement>
      {' letters, and be '}
      <PasswordRequirement valid={!touched || isLongEnough}>at least 12 characters long</PasswordRequirement>.
    </div>
  );
}
