/* eslint-disable @typescript-eslint/no-explicit-any */

import { ResponsePromise } from '../ResponsePromise';
import { HttpClient } from './HttpClient';
import type {
  AccountList,
  AccountPermissionsListElem,
  AddComponent,
  AnalyticsParameters,
  AnySettingType,
  ApiServiceAccountList,
  AppContext,
  BasicPlantArrangement,
  CompleteConstraintList,
  CompleteControlUnit,
  CompleteHydroBody,
  CompleteTopologyWithSpillway,
  ComponentList,
  Constraint,
  ConstraintTypeList,
  ControlUnitUpdate,
  CreationTimestamp,
  CSVFile,
  Currency,
  Date,
  DayAheadPrice,
  DischargeScheduleActual,
  DischargeScheduleActualNullable,
  DischargeSchedulePlan,
  ElectricityTariff,
  ExtendedBasicPlantArrangement,
  FlowToPowerList,
  GateDischargeOverride,
  GateDischargeOverrideInfo,
  GateDischargeResponse,
  HandlingOption,
  HasEmail,
  HourAndCoarserGranularities,
  HydroBody,
  HydroBodyInflowOverrideInfo,
  HydroBodyInflowPlanOverride,
  HydroBodyInflowSeries,
  HydroBodyUpdate,
  IdentifiedPriceForecastSourceList,
  IndividualAndAggregatedEnergy,
  IndividualAndAggregatedInflow,
  IndividualAndAggregatedInflowWeightedDayAheadPrice,
  IndividualAndAggregatedPower,
  IndividualAndAggregatedRealizedDayAheadPrice,
  IndividualAndAggregatedRevenue,
  IndividualAndAggregatedWaterContent,
  InExTernalComponentList,
  InflowValue,
  JwtTokens,
  LatestTelemetryTimesByPlant,
  LevelToContentList,
  ListPlantsTopology,
  MaintenanceSubmission,
  MfaSecret,
  MfaToken,
  MwGenerationValue,
  NewAccount,
  NominationConfiguration,
  OpeningScheduleActual,
  OpeningScheduleActualNullable,
  OpeningSchedulePlan,
  OverrideDeleteTyp,
  PasswordResetPayload,
  PeriodicTaskList,
  PermissionsListElem,
  PhysicalPlantList,
  PlantGenerationList,
  PlantList,
  PlantPowerActualAndPlanAndNomination,
  PlantTurbineLosses,
  PlantUpdate,
  Portfolio,
  PortfolioList,
  PortfolioMaintenanceResponse,
  PortfolioPowerOverview,
  PowerSchedule,
  PowerScheduleNullable,
  PriceForecastSource,
  PriceForecastSubmission,
  PriceForecastTimeseries,
  PricePlanPermissionsListElem,
  PriceRegionList,
  ReservoirInflowPlans,
  ReservoirLevelActual,
  ReservoirLevelOverride,
  ReservoirLevelOverrideInfo,
  ReservoirLevelPlanAndBoundariesElement,
  ReservoirLevelSeries,
  SettingsObject,
  SimpleControlUnit,
  SimpleCustomer,
  SimpleCustomerList,
  SimpleHydroBody,
  SimpleHydroBodyList,
  SimplePlantList,
  SimplePlantWithInternalIDList,
  StrategyAdjustmentValue,
  SubHourGranularities,
  TaskArguments,
  ThroughputRatioList,
  TimeZoneList,
  TokenInfo,
  TurbineMaintenance,
  TurbineMaintenanceHistory,
  TurbineMaintenances,
  TurbineMaintenanceSubmission,
  TurbinePowerActualAndPlan,
  TurbinePowerOverride,
  TurbinePowerOverrideInfo,
  TurbineWaterValues,
  UpdateThroughputRatioList,
  UserEmail,
  WaterValueTimeseriesReturnType
} from './Schemas';

type AllKeysOf<T> = T extends any ? keyof T : never;
type ProhibitKeys<K extends keyof any> = { [P in K]?: never };
type ExactlyOneOf<T extends any[]> = T[number] extends object
  ? {
      [K in keyof T]: T[K] & ProhibitKeys<Exclude<AllKeysOf<T[number]>, keyof T[K]>>;
    }[number]
  : T[number];

export class Api extends HttpClient {
  auth = {
    /**
     * Password reset email
     *
     * Request a reset email for an account's password
     * - `204` - Email sent
     */
    forgotPassword: (args: {
      body: UserEmail;
      security?:
        | null
        | {
            security: 'bearerAuth';
            token: string;
          }
        | undefined;
    }): ResponsePromise<void> => {
      return this.request({
        path: `dashboard-v3/auth/password/forgot`,
        method: 'POST',
        body: args.body,
        security: args.security
      });
    },

    /**
     * Get JWT or mfa_secret
     *
     * Main Authentication endpoint. Supply Basic Authentication credentials to get
     * a JWT. In case MFA is required for this account the response is the mfa_secret
     * that needs to be supplied together with the mfa_token.
     * - `200` - Token and refresh token or mfa_secret
     * - `401` - Incorrect username or password
     * - `429` - Too many failed attempts
     */
    getAccessToken: (args: {
      security: {
        security: 'basicAuth';
        username: string;
        password: string;
      };
      signal: AbortSignal;
    }): ResponsePromise<ExactlyOneOf<[JwtTokens, MfaSecret]>> => {
      return this.request({
        path: `dashboard-v3/auth/token`,
        security: args.security,
        signal: args.signal
      });
    },

    /**
     * Request api token providing 2nd factor one-time-password
     * - `200` - Token and refresh token or mfa_secret
     * - `401` - Authentication error
     */
    getAccessTokenFromMultiFactorAuth: (args: {
      body: MfaToken;
      security: {
        security: 'basicAuthMfaSecret';
        username: string;
        mfaSecret: string;
      };
    }): ResponsePromise<JwtTokens> => {
      return this.request({
        path: `dashboard-v3/auth/multi_factor`,
        method: 'POST',
        body: args.body,
        security: args.security
      });
    },

    /**
     * Get JWT
     *
     * Main Authentication endpoint. Supply Basic Authentication credentials to get a JWT.
     * Alternatively the credentials can be passed in the request body
     * - `200` - Token and refresh token
     * - `401` - Incorrect username or password
     */
    getAccessTokenNoMultiFactor: (args: {
      security: {
        security: 'basicAuth';
        username: string;
        password: string;
      };
      signal: AbortSignal;
    }): ResponsePromise<{ access_token: string; refresh_token: string }> => {
      return this.request({
        path: `v1/auth`,
        security: args.security,
        signal: args.signal
      });
    },

    /**
     * Refresh JWT
     *
     * When your jwt has expired, use this to get a new one by supplying the expired  jwt and the refresh token. Provide the refresh token as Authentication/Bearer.
     * - `200` - Access token
     */
    getRefreshToken: (args: { body: { access_token: string } }): ResponsePromise<{ access_token: string; refresh_token: string }> => {
      return this.request({
        path: `v1/auth/refresh`,
        method: 'POST',
        body: args.body
      });
    },

    /**
     * Logout the user associated to the requesting access_token.
     *
     * When you want to close your session, call this endpoint to invalidate your token on the server side so that it cannot be used anymore.
     * - `204` - Logout successful
     * - `401` - Access token is missing or invalid
     */
    logout: (): ResponsePromise<void> => {
      return this.request({
        path: `v1/auth/logout`,
        method: 'POST'
      });
    },

    /**
     * Get JWT or mfa_secret
     *
     * Main Authentication endpoint. Supply Basic Authentication credentials to get
     * a JWT. In case mfa is required for this account the response is the mfa_secret
     * that needs to be supplied together with the mfa_token
     * - `200` - Token and refresh token or mfa_secret
     * - `401` - Incorrect username or password
     */
    resendMultiFactorToken: (args: {
      security: {
        security: 'basicAuthMfaSecret';
        username: string;
        mfaSecret: string;
      };
      signal: AbortSignal;
    }): ResponsePromise<MfaSecret> => {
      return this.request({
        path: `dashboard-v3/auth/multi_factor/resend_token`,
        security: args.security,
        signal: args.signal
      });
    },

    /**
     * Password reset request
     *
     * Set an account's password using either a reset token or the current password
     * - `201` - Successfully set the password
     * - `401` - Authentication error
     */
    resetPassword: (args: {
      body: PasswordResetPayload;
      security?:
        | null
        | {
            security: 'bearerAuth';
            token: string;
          }
        | undefined;
    }): ResponsePromise<unknown> => {
      return this.request({
        path: `dashboard-v3/auth/password/set`,
        method: 'POST',
        body: args.body,
        security: args.security
      });
    }
  };

  dashboard = {
    account: {
      /**
       * Get a list of available portfolios
       *
       * List all portfolios available to the user, if the user has more than one
       * the first one is the default one
       * - `200` - List of portfolios
       */
      requestPortfolios: (args: { signal: AbortSignal }): ResponsePromise<{ data: PortfolioList }> => {
        return this.request({
          path: `dashboard-v3/portfolio`,
          signal: args.signal
        });
      }
    },

    constraint: {
      /**
       * Add a new constraint
       *
       * Add a new constraint.
       * - `204` - Successfully added a new constraint
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      addConstraint: (args: { portfolio_id: string; plant_id: string; body: Constraint }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/constraint`,
          method: 'POST',
          body: args.body
        });
      },

      /**
       * Deactivate a constraint
       *
       * Deactivate a constraint.
       * - `204` - Successfully deactivated constraint
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      deactivateConstraint: (args: { portfolio_id: string; constraint_id: string }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/constraint/${args.constraint_id}/deactivate`,
          method: 'PUT'
        });
      },

      /**
       * Get a list of all constraint types
       *
       * Get all constraint types.
       * - `200` - Successfully fetched constraint types
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getAllConstraintTypes: (args: { portfolio_id: string; signal: AbortSignal }): ResponsePromise<ConstraintTypeList> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/constraint_types`,
          signal: args.signal
        });
      }
    },

    controlUnit: {
      /**
       * Add a new control-unit.
       *
       * Add a new control-unit with its very basic properties to an existing plant.
       * - `200` - Successfully added a new control-unit
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      addControlUnit: (args: { plant_id: string; body: SimpleControlUnit }): ResponsePromise<string> => {
        return this.request({
          path: `dashboard-v3/${args.plant_id}/control-unit`,
          method: 'POST',
          body: args.body
        });
      },

      /**
       * Delete a control unit.
       *
       * Delete a control unit using the internal control-unit id.
       * - `204` - Successfully deleted control unit
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       * - `409` - Operation is not possible because of the current state of the target
       */
      deleteControlUnit: (args: { control_unit_id: string }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/control-unit/${args.control_unit_id}`,
          method: 'DELETE'
        });
      },

      /**
       * Get all properties of an existing control-unit.
       *
       * Get all properties of an existing control-unit using the internal control-unit id.
       * - `200` - Successfully fetched control unit
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getControlUnit: (args: { control_unit_id: string; signal: AbortSignal }): ResponsePromise<CompleteControlUnit> => {
        return this.request({
          path: `dashboard-v3/control-unit/${args.control_unit_id}`,
          signal: args.signal
        });
      },

      /**
       * Update an existing control-unit.
       *
       * Update the properties of an existing control-unit.
       * - `204` - Successfully updated control unit
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      updateControlUnit: (args: { control_unit_id: string; body: ControlUnitUpdate }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/control-unit/${args.control_unit_id}`,
          method: 'PUT',
          body: args.body
        });
      }
    },

    gate: {
      /**
       * Delete overrides for a given timeframe
       * - `200` - Successfully removed all overrides in the given timeframe
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      deleteGateDischargeActualOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        gate_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
      }): ResponsePromise<{ data: OverrideDeleteTyp }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/gate/${args.gate_id}/discharge/actual/override`,
          method: 'DELETE',
          queryParams: args.queryParams
        });
      },

      /**
       * Delete overrides for a given timeframe
       * - `200` - Successfully removed all overrides in the given timeframe
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      deleteGateDischargePlanOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        gate_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
      }): ResponsePromise<{ data: OverrideDeleteTyp }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/gate/${args.gate_id}/discharge/plan/override`,
          method: 'DELETE',
          queryParams: args.queryParams
        });
      },

      /**
       * Get gate discharge plan and actual for a specific timeframe
       * - `200` - The gate discharge plan and actual timeseries
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      requestGateDischarge: (args: {
        portfolio_id: string;
        plant_id: string;
        gate_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: GateDischargeResponse }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/gate/${args.gate_id}/discharge`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * The gate discharge actual
       * - `200` - The gate discharge actual, including information on overrides
       * - `403` - Permission denied
       */
      requestGateDischargeActualWithOverrideInfo: (args: {
        portfolio_id: string;
        plant_id: string;
        gate_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: GateDischargeOverrideInfo }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/gate/${args.gate_id}/discharge/actual-with-override-info`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * The gate discharge plan
       * - `200` - The gate discharge plan, including information on overrides
       * - `403` - Permission denied
       */
      requestGateDischargePlanWithOverrideInfo: (args: {
        portfolio_id: string;
        plant_id: string;
        gate_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: GateDischargeOverrideInfo }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/gate/${args.gate_id}/discharge/plan-with-override-info`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Set overrides for discharge actuals
       * - `204` - Success
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      submitGateDischargeActualOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        gate_id: string;
        body: GateDischargeOverride;
        queryParams?:
          | {
              granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
            }
          | undefined;
      }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/gate/${args.gate_id}/discharge/actual/override`,
          method: 'POST',
          queryParams: args.queryParams,
          body: args.body
        });
      },

      /**
       * Set overrides for discharge plans
       * - `204` - Success
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      submitGateDischargePlanOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        gate_id: string;
        body: GateDischargeOverride;
        queryParams?:
          | {
              granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
            }
          | undefined;
      }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/gate/${args.gate_id}/discharge/plan/override`,
          method: 'POST',
          queryParams: args.queryParams,
          body: args.body
        });
      }
    },

    hydroBody: {
      /**
       * Add a new hydro-body.
       *
       * Add a new hydro-body with its very basic properties to an existing plant.
       * - `200` - Successfully added a new hydro-body
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      addHydroBody: (args: { plant_id: string; body: SimpleHydroBody }): ResponsePromise<string> => {
        return this.request({
          path: `dashboard-v3/${args.plant_id}/hydro-body`,
          method: 'POST',
          body: args.body
        });
      },

      /**
       * Delete a hydro-body.
       *
       * Delete a hydro-body using the internal hydro-body id.
       * - `204` - Successfully deleted hydro-body
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       * - `409` - Operation is not possible because of the current state of the target
       */
      deleteHydroBody: (args: { hydro_body_id: string }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/hydro-body/${args.hydro_body_id}`,
          method: 'DELETE'
        });
      },

      /**
       * Get all properties of an existing hydro-body.
       *
       * Get all properties of an existing hydro-body using the internal hydro-body id.
       * - `200` - Successfully fetched hydro_body
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getHydroBody: (args: { hydro_body_id: string; signal: AbortSignal }): ResponsePromise<CompleteHydroBody> => {
        return this.request({
          path: `dashboard-v3/hydro-body/${args.hydro_body_id}`,
          signal: args.signal
        });
      },

      /**
       * Request details of an existing hydro-body (name, location, etc)
       * - `200` - Complete properties of a hydro-body.
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestHydroBodyDetails: (args: {
        portfolio_id: string;
        plant_id: string;
        hydro_body_id: string;
        signal: AbortSignal;
      }): ResponsePromise<HydroBody> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/hydro-body/${args.hydro_body_id}`,
          signal: args.signal
        });
      },

      /**
       * Update an existing hydro-body.
       *
       * Update the properties of an existing hydro-body.
       * - `204` - Successfully updated hydro-body
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      updateHydroBody: (args: { hydro_body_id: string; body: HydroBodyUpdate }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/hydro-body/${args.hydro_body_id}`,
          method: 'PUT',
          body: args.body
        });
      }
    },

    inflow: {
      /**
       * Delete overrides for a given timeframe
       * - `200` - Successfully removed all overrides in the given timeframe
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      deleteHydroBodyInflowActualOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        hydro_body_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
      }): ResponsePromise<{ data: OverrideDeleteTyp }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/hydro_body/${args.hydro_body_id}/inflow/actual/override`,
          method: 'DELETE',
          queryParams: args.queryParams
        });
      },

      /**
       * Delete overrides for a given timeframe
       * - `200` - Successfully removed all overrides in the given timeframe
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      deleteHydroBodyInflowPlanOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        hydro_body_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
      }): ResponsePromise<{ data: OverrideDeleteTyp }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/hydro_body/${args.hydro_body_id}/inflow/plan/override`,
          method: 'DELETE',
          queryParams: args.queryParams
        });
      },

      /**
       * The reservoir inflow actual
       * - `200` - The reservoir inflow actual, including information on overrides
       * - `403` - Permission denied
       */
      requestHydroBodyInflowActualWithOverrideInfo: (args: {
        portfolio_id: string;
        plant_id: string;
        hydro_body_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: HydroBodyInflowOverrideInfo }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/hydro_body/${args.hydro_body_id}/inflow/actual-with-override-info`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * The reservoir inflow plan
       * - `200` - The reservoir inflow plan, including information on overrides
       * - `403` - Permission denied
       */
      requestHydroBodyInflowPlanWithOverrideInfo: (args: {
        portfolio_id: string;
        plant_id: string;
        hydro_body_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: HydroBodyInflowOverrideInfo }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/hydro_body/${args.hydro_body_id}/inflow/plan-with-override-info`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Set overrides for hydro body inflow actuals
       * - `204` - Success
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      submitHydroBodyInflowActualOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        hydro_body_id: string;
        body: HydroBodyInflowPlanOverride;
        queryParams?:
          | {
              granularity?: 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';
            }
          | undefined;
      }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/hydro_body/${args.hydro_body_id}/inflow/actual/override`,
          method: 'POST',
          queryParams: args.queryParams,
          body: args.body
        });
      },

      /**
       * Set overrides for hydro body inflow plans
       * - `204` - Success
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      submitHydroBodyInflowPlanOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        hydro_body_id: string;
        body: HydroBodyInflowPlanOverride;
        queryParams?:
          | {
              granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
            }
          | undefined;
      }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/hydro_body/${args.hydro_body_id}/inflow/plan/override`,
          method: 'POST',
          queryParams: args.queryParams,
          body: args.body
        });
      }
    },

    outage: {
      /**
       * Retrieve a specific turbine maintenance using its uuid
       * - `200` - A single maintenance for a turbine
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestOutage: (args: {
        portfolio_id: string;
        plant_id: string;
        maintenance_id: string;
        signal: AbortSignal;
      }): ResponsePromise<{ data: TurbineMaintenance }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/maintenance/${args.maintenance_id}`,
          signal: args.signal
        });
      },

      /**
       * Retrieve a specific turbine maintenance history
       * - `200` - Retrieve a specific turbine maintenance history
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestOutageHistory: (args: {
        maintenance_id: string;
        signal: AbortSignal;
      }): ResponsePromise<{ data: TurbineMaintenanceHistory }> => {
        return this.request({
          path: `dashboard-v3/maintenance/${args.maintenance_id}/history`,
          signal: args.signal
        });
      },

      /**
       * Get maintenances for all turbines in a plant as list
       * - `200` - All turbine maintenances in a plant
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestPlantOutages: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: TurbineMaintenances }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/maintenance`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get maintenances for single turbine in a plant as list
       * - `200` - All maintenances for a turbine
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestTurbineOutages: (args: {
        portfolio_id: string;
        plant_id: string;
        turbine_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: TurbineMaintenances }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine/${args.turbine_id}/maintenance`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Add a new maintenance for a turbine
       *
       * Add a new maintenance for a turbine.
       * - `201` - Successfully created a new maintenance
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      submitTurbineOutage: (args: {
        portfolio_id: string;
        plant_id: string;
        turbine_id: string;
        body: TurbineMaintenanceSubmission;
      }): ResponsePromise<TurbineMaintenance> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine/${args.turbine_id}/maintenance`,
          method: 'POST',
          body: args.body
        });
      },

      /**
       * Update a specific turbine maintenance
       * - `200` - Successfully updated a maintenance
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      updateOutage: (args: {
        portfolio_id: string;
        plant_id: string;
        maintenance_id: string;
        body?: MaintenanceSubmission | undefined;
      }): ResponsePromise<{ data: TurbineMaintenance }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/maintenance/${args.maintenance_id}`,
          method: 'PUT',
          body: args.body
        });
      }
    },

    plant: {
      /**
       * Get a basic plant arrangement
       *
       * Get a basic plant arrangement including basic data, simple hydrobodies
       * and simple control_units
       * - `200` - Successfully fetched plant
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      getBasicArrangement: (args: { plant_id: string; signal: AbortSignal }): ResponsePromise<ExtendedBasicPlantArrangement> => {
        return this.request({
          path: `dashboard-v3/plant/${args.plant_id}`,
          signal: args.signal
        });
      },

      /**
       * Get aggregated day ahead price for a specific timeframe
       * - `200` - The plant plan and actual day ahead price timeseries
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getDayAheadPrice: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: DayAheadPrice; metrics: ElectricityTariff }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/day-ahead-price`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * The inflow actual and best estimate using inflow plan (with override data if present) for all reservoirs in plant
       * - `200` - The inflow actual and best estimate using inflow plans (with override data if present)
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getInflowActualAndForecast: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: IndividualAndAggregatedInflow }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/inflow`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get nomination configuration
       *
       * Get day ahead and intraday nomination configuration
       * - `200` - Successfully fetched nomination configuration
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      getNominationConfiguration: (args: { plant_id: string; signal: AbortSignal }): ResponsePromise<NominationConfiguration> => {
        return this.request({
          path: `dashboard-v3/plant/${args.plant_id}/nomination-configuration`,
          signal: args.signal
        });
      },

      /**
       * Get the actual and best estimate realized day ahead price aggregated for the
       * whole plant and for each turbine in the given granularity.
       * - `200` - Individual and aggregated realized day ahead price
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getRealizedDayAheadPriceAndForecast: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: IndividualAndAggregatedRealizedDayAheadPrice; metrics?: ElectricityTariff | undefined }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/realized-day-ahead-price`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get all analytics-parameters for a plant
       *
       * Get a set of plant analytics parameters
       * - `200` - Request analytics-parameters of a plant
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      requestAnalyticsParameters: (args: { plant_id: string; signal: AbortSignal }): ResponsePromise<{ data: AnalyticsParameters }> => {
        return this.request({
          path: `dashboard-v3/plant/${args.plant_id}/analytics-parameter`,
          signal: args.signal
        });
      },

      /**
       * Get a list of all components of a plant
       *
       * Get all components of a plant.
       * - `200` - Successfully fetched components
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestComponents: (args: { portfolio_id: string; plant_id: string; signal: AbortSignal }): ResponsePromise<ComponentList> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/components`,
          signal: args.signal
        });
      },

      /**
       * Get a list of all constraints for a plant
       *
       * Get all constraints for a plant.
       * - `200` - Successfully fetched constraints
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestConstraints: (args: {
        portfolio_id: string;
        plant_id: string;
        signal: AbortSignal;
      }): ResponsePromise<CompleteConstraintList> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/constraint`,
          signal: args.signal
        });
      },

      /**
       * Get the generated energy aggregated for the whole plant and for each
       * turbine in the given granularity.
       * - `200` - Individual and aggregated energy
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestEnergy: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: IndividualAndAggregatedEnergy }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/energy`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get the inflow weighted day ahead price aggregated for the whole plant and
       * for each turbine in the given granularity.
       * - `200` - Individual and aggregated inflow weighted day ahead price
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      requestInflowWeightedDayAheadPrice: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: IndividualAndAggregatedInflowWeightedDayAheadPrice; metrics?: ElectricityTariff | undefined }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/inflow-weighted-price`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get aggregated power plan and actual for a specific timeframe
       * - `200` - The plant plan and actual power timeseries
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestPower: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: IndividualAndAggregatedPower }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/power`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get the revenue aggregated for the whole plant and for each
       * turbine in the given granularity.
       * - `200` - Individual and aggregated revenue
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestRevenue: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: IndividualAndAggregatedRevenue; metrics?: Currency | undefined }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/revenue`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get the strategy adjustment value for the plant
       * - `200` - The current value
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestStrategyAdjustment: (args: {
        portfolio_id: string;
        plant_id: string;
        signal: AbortSignal;
      }): ResponsePromise<{
        /**
         * @min -40
         * @max 40
         */
        data: StrategyAdjustmentValue;
      }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/strategy-adjustment`,
          signal: args.signal
        });
      },

      /**
       * Get the topology of a plant
       *
       * Get a plant topology including basic data, simple hydrobodies
       * and control_units with static data
       * - `200` - Successfully fetched plants topology
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      requestTopology: (args: {
        portfolio_id: string;
        plant_id: string;
        signal: AbortSignal;
      }): ResponsePromise<CompleteTopologyWithSpillway> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/topology`,
          signal: args.signal
        });
      },

      /**
       * The reservoir content plan and actual
       *
       * **Temporal aggregation:** *is done by simply taking the last value in an
       * interval.*
       *
       * **Reservoir aggregation:** *sum*
       *
       * Note that intervals are treated as closed on the start value and open on the
       * end, so the aggregated
       * value for a day will be the value at 23:00 for hourly content measurements.
       * - `200` - The reservoir content in hm^3 plan and actual
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestWaterContent: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: IndividualAndAggregatedWaterContent }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/water-content`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Set the strategy adjustment value for the plant
       * - `204` - Success
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      submitStrategyAdjustment: (args: {
        portfolio_id: string;
        plant_id: string;
        body: StrategyAdjustmentValue;
      }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/strategy-adjustment`,
          method: 'PUT',
          body: args.body
        });
      },

      /**
       * Update properties of an existing plant
       *
       * Update properties of an existing plant.
       * - `200` - Successfully updated the plant
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      update: (args: { plant_id: string; body: PlantUpdate }): ResponsePromise<{ id: string }> => {
        return this.request({
          path: `dashboard-v3/plant/${args.plant_id}`,
          method: 'PUT',
          body: args.body
        });
      },

      /**
       * Submit or update analytics parameters for a plant
       * - `200` - Successfully updated plant analytics parameters
       * - `201` - Successfully submitted new plant analytics parameters
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      upsertAnalyticsParameter: (args: { plant_id: string; body: AnalyticsParameters }): ResponsePromise<AnalyticsParameters> => {
        return this.request({
          path: `dashboard-v3/plant/${args.plant_id}/analytics-parameter`,
          method: 'PUT',
          body: args.body
        });
      },

      /**
       * Update nomination configuration
       *
       * Update day ahead and intraday nomination configuration
       * - `204` - Successfully updated nomination configuration
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      upsertNominationConfiguration: (args: { plant_id: string; body: NominationConfiguration }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/plant/${args.plant_id}/nomination-configuration`,
          method: 'PATCH',
          body: args.body
        });
      }
    },

    portfolio: {
      /**
       * A list of components by plant with the latest telemetry timestamp received
       * - `200` - List of components by plant with the latest telemetry timestamp received
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getLatestTelemetryTimes: (args: {
        portfolio_id: string;
        signal: AbortSignal;
      }): ResponsePromise<{ data: LatestTelemetryTimesByPlant }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/latest-telemetry-time`,
          signal: args.signal
        });
      },

      /**
       * The list of generation of plants in the portfolio
       * - `200` - List of generation for all plants
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getPortfolioPlantGeneration: (args: {
        portfolio_id: string;
        signal: AbortSignal;
      }): ResponsePromise<{ data: PlantGenerationList }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant_generation`,
          signal: args.signal
        });
      },

      /**
       * The list of plants in the portfolio
       * - `200` - List of plants
       * - `403` - Permission denied
       */
      listPortfolioPlantViews: (args: { portfolio_id: string; signal: AbortSignal }): ResponsePromise<{ data: PlantList }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant-view`,
          signal: args.signal
        });
      },

      /**
       * The list of plants in the portfolio
       * - `200` - List of plants
       * - `403` - Permission denied
       */
      listPortfolioPlants: (args: { portfolio_id: string; signal: AbortSignal }): ResponsePromise<{ data: PlantList }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant`,
          signal: args.signal
        });
      },

      /**
       * Get the physical plants in a portfolio
       *
       * Get all physical plants including name and internal ids as well as a list of the linked plant views
       * - `200` - Successfully fetched physical plant list
       * - `403` - Permission denied
       */
      requestPhysicalPlants: (args: { portfolio_id: string; signal: AbortSignal }): ResponsePromise<{ data: PhysicalPlantList }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/physical_plants`,
          signal: args.signal
        });
      },

      /**
       * Get the topology of all plants in a portfolio
       *
       * Get all plants topology including basic data, simple hydro_bodies
       * and control_units with static data
       * - `200` - Successfully fetched portfolio topology
       * - `403` - Permission denied
       */
      requestTopology: (args: { portfolio_id: string; signal: AbortSignal }): ResponsePromise<ListPlantsTopology> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/topology`,
          signal: args.signal
        });
      }
    },

    power: {
      /**
       * Delete overrides for a given timeframe
       * - `200` - Successfully removed all overrides in the given timeframe
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      deleteTurbinePowerActualOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        turbine_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
      }): ResponsePromise<{ data: OverrideDeleteTyp }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine/${args.turbine_id}/power/gross/actual/override`,
          method: 'DELETE',
          queryParams: args.queryParams
        });
      },

      /**
       * Delete overrides for a given timeframe
       * - `200` - Successfully removed all overrides in the given timeframe
       * - `403` - Permission denied
       */
      deleteTurbinePowerPlanOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        turbine_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
      }): ResponsePromise<{ data: OverrideDeleteTyp }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine/${args.turbine_id}/power/net/plan/override`,
          method: 'DELETE',
          queryParams: args.queryParams
        });
      },

      /**
       * Get aggregated turbine power plan and actual plus electricity tariff for a
       * specific timeframe
       * - `200` - The plant plan and actual power timeseries
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      requestPlantPowerAndPrice: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: PlantPowerActualAndPlanAndNomination; metrics: ElectricityTariff }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/power-and-price`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get power actual and plan
       *
       * Get power actual and plan aggregating all plants in the current portfolio
       * - `200` - Power actual and plan of a portfolio
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestPortfolioPower: (args: {
        portfolio_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: PortfolioPowerOverview }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/power`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * The power active-gross actual
       * - `200` - The turbine generation active-gross actual, including information
       * on overrides
       * - `403` - Permission denied
       */
      requestPowerActivegrossActualWithOverrideInfo: (args: {
        portfolio_id: string;
        plant_id: string;
        turbine_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: TurbinePowerOverrideInfo }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine/${args.turbine_id}/power/gross/actual-with-override-info`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * The power plan
       * - `200` - The turbine power active-net plan, including information on overrides
       * - `403` - Permission denied
       */
      requestPowerActivenetPlanWithOverrideInfo: (args: {
        portfolio_id: string;
        plant_id: string;
        turbine_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: TurbinePowerOverrideInfo }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine/${args.turbine_id}/power/net/plan-with-override-info`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get turbine power plan net and actual gross for a specific timeframe
       * - `200` - The turbine plan net and actual gross timeseries
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       */
      requestTurbinePower: (args: {
        portfolio_id: string;
        plant_id: string;
        turbine_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          additional_type?: 'price';
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: TurbinePowerActualAndPlan }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine/${args.turbine_id}/power`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Set overrides for power actuals
       * - `204` - Success
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      submitTurbinePowerActualOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        turbine_id: string;
        body: TurbinePowerOverride;
        queryParams?:
          | {
              granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
            }
          | undefined;
      }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine/${args.turbine_id}/power/gross/actual/override`,
          method: 'POST',
          queryParams: args.queryParams,
          body: args.body
        });
      },

      /**
       * Set overrides for power plans
       * - `204` - Success
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      submitTurbinePowerPlanOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        turbine_id: string;
        body: TurbinePowerOverride;
        queryParams?:
          | {
              granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
            }
          | undefined;
      }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine/${args.turbine_id}/power/net/plan/override`,
          method: 'POST',
          queryParams: args.queryParams,
          body: args.body
        });
      }
    },

    priceForecast: {
      /**
       * Retrieve latest price forecast for a given price forecast source uuid
       * - `200` - Get price forecast on interval with most recent market observation prior
       * to request time
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getPriceForecast: (args: {
        price_forecast_source_uuid: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          request_time: number;
          timezone:
            | 'Africa/Abidjan'
            | 'Africa/Accra'
            | 'Africa/Addis_Ababa'
            | 'Africa/Algiers'
            | 'Africa/Asmara'
            | 'Africa/Bamako'
            | 'Africa/Bangui'
            | 'Africa/Banjul'
            | 'Africa/Bissau'
            | 'Africa/Blantyre'
            | 'Africa/Brazzaville'
            | 'Africa/Bujumbura'
            | 'Africa/Cairo'
            | 'Africa/Casablanca'
            | 'Africa/Ceuta'
            | 'Africa/Conakry'
            | 'Africa/Dakar'
            | 'Africa/Dar_es_Salaam'
            | 'Africa/Djibouti'
            | 'Africa/Douala'
            | 'Africa/El_Aaiun'
            | 'Africa/Freetown'
            | 'Africa/Gaborone'
            | 'Africa/Harare'
            | 'Africa/Johannesburg'
            | 'Africa/Juba'
            | 'Africa/Kampala'
            | 'Africa/Khartoum'
            | 'Africa/Kigali'
            | 'Africa/Kinshasa'
            | 'Africa/Lagos'
            | 'Africa/Libreville'
            | 'Africa/Lome'
            | 'Africa/Luanda'
            | 'Africa/Lubumbashi'
            | 'Africa/Lusaka'
            | 'Africa/Malabo'
            | 'Africa/Maputo'
            | 'Africa/Maseru'
            | 'Africa/Mbabane'
            | 'Africa/Mogadishu'
            | 'Africa/Monrovia'
            | 'Africa/Nairobi'
            | 'Africa/Ndjamena'
            | 'Africa/Niamey'
            | 'Africa/Nouakchott'
            | 'Africa/Ouagadougou'
            | 'Africa/Porto-Novo'
            | 'Africa/Sao_Tome'
            | 'Africa/Tripoli'
            | 'Africa/Tunis'
            | 'Africa/Windhoek'
            | 'America/Adak'
            | 'America/Anchorage'
            | 'America/Anguilla'
            | 'America/Antigua'
            | 'America/Araguaina'
            | 'America/Argentina/Buenos_Aires'
            | 'America/Argentina/Catamarca'
            | 'America/Argentina/Cordoba'
            | 'America/Argentina/Jujuy'
            | 'America/Argentina/La_Rioja'
            | 'America/Argentina/Mendoza'
            | 'America/Argentina/Rio_Gallegos'
            | 'America/Argentina/Salta'
            | 'America/Argentina/San_Juan'
            | 'America/Argentina/San_Luis'
            | 'America/Argentina/Tucuman'
            | 'America/Argentina/Ushuaia'
            | 'America/Aruba'
            | 'America/Asuncion'
            | 'America/Atikokan'
            | 'America/Bahia'
            | 'America/Bahia_Banderas'
            | 'America/Barbados'
            | 'America/Belem'
            | 'America/Belize'
            | 'America/Blanc-Sablon'
            | 'America/Boa_Vista'
            | 'America/Bogota'
            | 'America/Boise'
            | 'America/Cambridge_Bay'
            | 'America/Campo_Grande'
            | 'America/Cancun'
            | 'America/Caracas'
            | 'America/Cayenne'
            | 'America/Cayman'
            | 'America/Chicago'
            | 'America/Chihuahua'
            | 'America/Costa_Rica'
            | 'America/Creston'
            | 'America/Cuiaba'
            | 'America/Curacao'
            | 'America/Danmarkshavn'
            | 'America/Dawson'
            | 'America/Dawson_Creek'
            | 'America/Denver'
            | 'America/Detroit'
            | 'America/Dominica'
            | 'America/Edmonton'
            | 'America/Eirunepe'
            | 'America/El_Salvador'
            | 'America/Fort_Nelson'
            | 'America/Fortaleza'
            | 'America/Glace_Bay'
            | 'America/Godthab'
            | 'America/Goose_Bay'
            | 'America/Grand_Turk'
            | 'America/Grenada'
            | 'America/Guadeloupe'
            | 'America/Guatemala'
            | 'America/Guayaquil'
            | 'America/Guyana'
            | 'America/Halifax'
            | 'America/Havana'
            | 'America/Hermosillo'
            | 'America/Indiana/Indianapolis'
            | 'America/Indiana/Knox'
            | 'America/Indiana/Marengo'
            | 'America/Indiana/Petersburg'
            | 'America/Indiana/Tell_City'
            | 'America/Indiana/Vevay'
            | 'America/Indiana/Vincennes'
            | 'America/Indiana/Winamac'
            | 'America/Inuvik'
            | 'America/Iqaluit'
            | 'America/Jamaica'
            | 'America/Juneau'
            | 'America/Kentucky/Louisville'
            | 'America/Kentucky/Monticello'
            | 'America/Kralendijk'
            | 'America/La_Paz'
            | 'America/Lima'
            | 'America/Los_Angeles'
            | 'America/Lower_Princes'
            | 'America/Maceio'
            | 'America/Managua'
            | 'America/Manaus'
            | 'America/Marigot'
            | 'America/Martinique'
            | 'America/Matamoros'
            | 'America/Mazatlan'
            | 'America/Menominee'
            | 'America/Merida'
            | 'America/Metlakatla'
            | 'America/Mexico_City'
            | 'America/Miquelon'
            | 'America/Moncton'
            | 'America/Monterrey'
            | 'America/Montevideo'
            | 'America/Montserrat'
            | 'America/Nassau'
            | 'America/New_York'
            | 'America/Nipigon'
            | 'America/Nome'
            | 'America/Noronha'
            | 'America/North_Dakota/Beulah'
            | 'America/North_Dakota/Center'
            | 'America/North_Dakota/New_Salem'
            | 'America/Ojinaga'
            | 'America/Panama'
            | 'America/Pangnirtung'
            | 'America/Paramaribo'
            | 'America/Phoenix'
            | 'America/Port-au-Prince'
            | 'America/Port_of_Spain'
            | 'America/Porto_Velho'
            | 'America/Puerto_Rico'
            | 'America/Punta_Arenas'
            | 'America/Rainy_River'
            | 'America/Rankin_Inlet'
            | 'America/Recife'
            | 'America/Regina'
            | 'America/Resolute'
            | 'America/Rio_Branco'
            | 'America/Santarem'
            | 'America/Santiago'
            | 'America/Santo_Domingo'
            | 'America/Sao_Paulo'
            | 'America/Scoresbysund'
            | 'America/Sitka'
            | 'America/St_Barthelemy'
            | 'America/St_Johns'
            | 'America/St_Kitts'
            | 'America/St_Lucia'
            | 'America/St_Thomas'
            | 'America/St_Vincent'
            | 'America/Swift_Current'
            | 'America/Tegucigalpa'
            | 'America/Thule'
            | 'America/Thunder_Bay'
            | 'America/Tijuana'
            | 'America/Toronto'
            | 'America/Tortola'
            | 'America/Vancouver'
            | 'America/Whitehorse'
            | 'America/Winnipeg'
            | 'America/Yakutat'
            | 'America/Yellowknife'
            | 'Asia/Aden'
            | 'Asia/Almaty'
            | 'Asia/Amman'
            | 'Asia/Anadyr'
            | 'Asia/Aqtau'
            | 'Asia/Aqtobe'
            | 'Asia/Ashgabat'
            | 'Asia/Atyrau'
            | 'Asia/Baghdad'
            | 'Asia/Bahrain'
            | 'Asia/Baku'
            | 'Asia/Bangkok'
            | 'Asia/Barnaul'
            | 'Asia/Beirut'
            | 'Asia/Bishkek'
            | 'Asia/Brunei'
            | 'Asia/Chita'
            | 'Asia/Choibalsan'
            | 'Asia/Colombo'
            | 'Asia/Damascus'
            | 'Asia/Dhaka'
            | 'Asia/Dili'
            | 'Asia/Dubai'
            | 'Asia/Dushanbe'
            | 'Asia/Famagusta'
            | 'Asia/Gaza'
            | 'Asia/Hebron'
            | 'Asia/Ho_Chi_Minh'
            | 'Asia/Hong_Kong'
            | 'Asia/Hovd'
            | 'Asia/Irkutsk'
            | 'Asia/Jakarta'
            | 'Asia/Jayapura'
            | 'Asia/Jerusalem'
            | 'Asia/Kabul'
            | 'Asia/Kamchatka'
            | 'Asia/Karachi'
            | 'Asia/Kathmandu'
            | 'Asia/Khandyga'
            | 'Asia/Kolkata'
            | 'Asia/Krasnoyarsk'
            | 'Asia/Kuala_Lumpur'
            | 'Asia/Kuching'
            | 'Asia/Kuwait'
            | 'Asia/Macau'
            | 'Asia/Magadan'
            | 'Asia/Makassar'
            | 'Asia/Manila'
            | 'Asia/Muscat'
            | 'Asia/Nicosia'
            | 'Asia/Novokuznetsk'
            | 'Asia/Novosibirsk'
            | 'Asia/Omsk'
            | 'Asia/Oral'
            | 'Asia/Phnom_Penh'
            | 'Asia/Pontianak'
            | 'Asia/Pyongyang'
            | 'Asia/Qatar'
            | 'Asia/Qostanay'
            | 'Asia/Qyzylorda'
            | 'Asia/Riyadh'
            | 'Asia/Sakhalin'
            | 'Asia/Samarkand'
            | 'Asia/Seoul'
            | 'Asia/Shanghai'
            | 'Asia/Singapore'
            | 'Asia/Srednekolymsk'
            | 'Asia/Taipei'
            | 'Asia/Tashkent'
            | 'Asia/Tbilisi'
            | 'Asia/Tehran'
            | 'Asia/Thimphu'
            | 'Asia/Tokyo'
            | 'Asia/Tomsk'
            | 'Asia/Ulaanbaatar'
            | 'Asia/Urumqi'
            | 'Asia/Ust-Nera'
            | 'Asia/Vientiane'
            | 'Asia/Vladivostok'
            | 'Asia/Yakutsk'
            | 'Asia/Yangon'
            | 'Asia/Yekaterinburg'
            | 'Asia/Yerevan'
            | 'Europe/Amsterdam'
            | 'Europe/Andorra'
            | 'Europe/Astrakhan'
            | 'Europe/Athens'
            | 'Europe/Belgrade'
            | 'Europe/Berlin'
            | 'Europe/Bratislava'
            | 'Europe/Brussels'
            | 'Europe/Bucharest'
            | 'Europe/Budapest'
            | 'Europe/Busingen'
            | 'Europe/Chisinau'
            | 'Europe/Copenhagen'
            | 'Europe/Dublin'
            | 'Europe/Gibraltar'
            | 'Europe/Guernsey'
            | 'Europe/Helsinki'
            | 'Europe/Isle_of_Man'
            | 'Europe/Istanbul'
            | 'Europe/Jersey'
            | 'Europe/Kaliningrad'
            | 'Europe/Kiev'
            | 'Europe/Kirov'
            | 'Europe/Lisbon'
            | 'Europe/Ljubljana'
            | 'Europe/London'
            | 'Europe/Luxembourg'
            | 'Europe/Madrid'
            | 'Europe/Malta'
            | 'Europe/Mariehamn'
            | 'Europe/Minsk'
            | 'Europe/Monaco'
            | 'Europe/Moscow'
            | 'Europe/Oslo'
            | 'Europe/Paris'
            | 'Europe/Podgorica'
            | 'Europe/Prague'
            | 'Europe/Riga'
            | 'Europe/Rome'
            | 'Europe/Samara'
            | 'Europe/San_Marino'
            | 'Europe/Sarajevo'
            | 'Europe/Saratov'
            | 'Europe/Simferopol'
            | 'Europe/Skopje'
            | 'Europe/Sofia'
            | 'Europe/Stockholm'
            | 'Europe/Tallinn'
            | 'Europe/Tirane'
            | 'Europe/Ulyanovsk'
            | 'Europe/Uzhgorod'
            | 'Europe/Vaduz'
            | 'Europe/Vatican'
            | 'Europe/Vienna'
            | 'Europe/Vilnius'
            | 'Europe/Volgograd'
            | 'Europe/Warsaw'
            | 'Europe/Zagreb'
            | 'Europe/Zaporozhye'
            | 'Europe/Zurich';
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: PriceForecastTimeseries }> => {
        return this.request({
          path: `dashboard-v3/price_forecast/${args.price_forecast_source_uuid}`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      }
    },

    reservoir: {
      /**
       * Delete overrides for a given timeframe
       * - `200` - Successfully removed all overrides in the given timeframe
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      deleteReservoirLevelActualOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        reservoir_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
      }): ResponsePromise<{ data: OverrideDeleteTyp }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/reservoir/${args.reservoir_id}/level/actual/override`,
          method: 'DELETE',
          queryParams: args.queryParams
        });
      },

      /**
       * Delete overrides for a given timeframe
       * - `200` - Successfully removed all overrides in the given timeframe
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      deleteReservoirLevelPlanOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        reservoir_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
      }): ResponsePromise<{ data: OverrideDeleteTyp }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/reservoir/${args.reservoir_id}/level/plan/override`,
          method: 'DELETE',
          queryParams: args.queryParams
        });
      },

      /**
       * The inflow plan and actual
       * - `200` - Inflow actual and plan of a hydro body
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getInflow: (args: {
        portfolio_id: string;
        plant_id: string;
        hydro_body_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: HydroBodyInflowSeries }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/hydro_body/${args.hydro_body_id}/inflow`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get all level to content value pairs for this hydro-body
       *
       * Get all level to content value pairs for this hydro-body.
       * - `200` - Successfully fetched level to content value pairs
       * - `403` - Permission denied
       */
      getLevelToContent: (args: { hydro_body_id: string; signal: AbortSignal }): ResponsePromise<LevelToContentList> => {
        return this.request({
          path: `dashboard-v3/hydro-body/${args.hydro_body_id}/level_to_content`,
          signal: args.signal
        });
      },

      /**
       * The reservoir level plan and actual
       * - `200` - The reservoir level plan and actual
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestLevel: (args: {
        portfolio_id: string;
        plant_id: string;
        reservoir_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: ReservoirLevelSeries }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/reservoir/${args.reservoir_id}/level`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * The reservoir level plan
       * - `200` - The reservoir level plan
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestLevelPlan: (args: {
        portfolio_id: string;
        plant_id: string;
        reservoir_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: ReservoirLevelPlanAndBoundariesElement[] }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/reservoir/${args.reservoir_id}/level/plan-and-override`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * The reservoir level actual, including information on overrides
       * - `200` - The reservoir level actual, including information on overrides
       * - `403` - Permission denied
       */
      requestReservoirLevelActualWithOverrideInfo: (args: {
        portfolio_id: string;
        plant_id: string;
        reservoir_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: ReservoirLevelOverrideInfo }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/reservoir/${args.reservoir_id}/level/actual-with-override-info`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * The reservoir level plan
       * - `200` - The reservoir level plan, including information on overrides
       * - `403` - Permission denied
       */
      requestReservoirLevelPlanWithOverrideInfo: (args: {
        portfolio_id: string;
        plant_id: string;
        reservoir_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: ReservoirLevelOverrideInfo }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/reservoir/${args.reservoir_id}/level/plan-with-override-info`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Set overrides for reservoir level actuals
       * - `204` - Success
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      submitReservoirLevelActualOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        reservoir_id: string;
        body: ReservoirLevelOverride;
        queryParams?:
          | {
              granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
            }
          | undefined;
      }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/reservoir/${args.reservoir_id}/level/actual/override`,
          method: 'POST',
          queryParams: args.queryParams,
          body: args.body
        });
      },

      /**
       * Set overrides for reservoir level plans
       * - `204` - Success
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      submitReservoirLevelPlanOverrides: (args: {
        portfolio_id: string;
        plant_id: string;
        reservoir_id: string;
        body: ReservoirLevelOverride;
        queryParams?:
          | {
              granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
            }
          | undefined;
      }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/reservoir/${args.reservoir_id}/level/plan/override`,
          method: 'POST',
          queryParams: args.queryParams,
          body: args.body
        });
      },

      /**
       * Update level to content matrix for this hydro-body
       *
       * Update level to content matrix for this hydro-body.
       * - `204` - Successfully added a new level to content pair
       * - `403` - Permission denied
       */
      updateLevelToContent: (args: { hydro_body_id: string; body: LevelToContentList }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/hydro-body/${args.hydro_body_id}/level_to_content`,
          method: 'PUT',
          body: args.body
        });
      }
    },

    turbine: {
      /**
       * Get loss factors for all turbines of a plant as list of changes in time
       * - `200` - The loss factors for each turbine
       * - `400` - Invalid parameter value in the request
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      requestLosses: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: PlantTurbineLosses }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine-losses`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      }
    },

    user: {
      /**
       * Delete a first level setting
       * - `204` - Successfully removed setting
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      deleteSetting: (args: { setting_name: string }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/settings/${args.setting_name}`,
          method: 'DELETE'
        });
      },

      /**
       * Get a first level user setting
       * - `200` - Successfully fetched setting
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getSetting: (args: { setting_name: string; signal: AbortSignal }): ResponsePromise<AnySettingType> => {
        return this.request({
          path: `dashboard-v3/settings/${args.setting_name}`,
          signal: args.signal
        });
      },

      /**
       * Get all user settings
       *
       * Get all user settings
       * - `200` - All user settings
       */
      getSettings: (args: { signal: AbortSignal }): ResponsePromise<SettingsObject> => {
        return this.request({
          path: `dashboard-v3/settings`,
          signal: args.signal
        });
      },

      /**
       * Get user's email and name
       *
       * Output the email adress and the full name of the user linked to this token.
       * - `200` - Get user's email and name
       */
      tokenInfo: (args: { signal: AbortSignal }): ResponsePromise<{ data: TokenInfo }> => {
        return this.request({
          path: `dashboard-v3/me`,
          signal: args.signal
        });
      },

      /**
       * Add a first level setting
       * - `201` - Successfully created setting
       * - `204` - Successfully updated setting
       * - `403` - Permission denied
       */
      upsertSetting: (args: { setting_name: string; body: AnySettingType }): ResponsePromise<unknown | void> => {
        return this.request({
          path: `dashboard-v3/settings/${args.setting_name}`,
          method: 'PUT',
          body: args.body
        });
      },

      /**
       * Updates user settings
       * - `204` - Successfully patched settings
       * - `403` - Permission denied
       */
      upsertSettings: (args: { body: SettingsObject }): ResponsePromise<void> => {
        return this.request({
          path: `dashboard-v3/settings`,
          method: 'PATCH',
          body: args.body
        });
      }
    },

    waterValue: {
      /**
       * Get water-value for all turbines in plant in currency of market region
       *
       * Get the water-value (aka. Shadow-price, marginal-price) for all turbines in
       * plant in the currency of the plants market region
       * - `200` - Water value for all turbines in plant in currency of market region
       * - `400` - Invalid parameter value in the request
       * - `401` - Access token is missing or invalid
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getPlantWaterValue: (args: {
        portfolio_id: string;
        plant_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: TurbineWaterValues; metrics: Currency }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/water-value`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      },

      /**
       * Get water-value for the requested turbine in currency of market region
       *
       * Get the water-value (aka. Shadow-price, marginal-price) for a turbine in
       * the currency of the plants market region
       * - `200` - Water value for requested turbine in currency of market region
       * - `400` - Invalid parameter value in the request
       * - `401` - Access token is missing or invalid
       * - `403` - Permission denied
       * - `404` - Unsupported parameter in the request.
       */
      getTurbineWaterValue: (args: {
        portfolio_id: string;
        plant_id: string;
        turbine_id: string;
        queryParams: {
          'start-ts': number;
          'end-ts': number;
          granularity?: ExactlyOneOf<[SubHourGranularities, HourAndCoarserGranularities]>;
        };
        signal: AbortSignal;
      }): ResponsePromise<{ data: WaterValueTimeseriesReturnType; metrics: Currency }> => {
        return this.request({
          path: `dashboard-v3/portfolio/${args.portfolio_id}/plant/${args.plant_id}/turbine/${args.turbine_id}/water-value`,
          queryParams: args.queryParams,
          signal: args.signal
        });
      }
    }
  };

  gate = {
    /**
     * Get gate discharge timeseries
     * - `200` - Gate discharge timeseries
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    requestDischargeActual: (args: {
      plant_id: string;
      queryParams: {
        'start-ts': number;
        'end-ts': number;
      };
      signal: AbortSignal;
    }): ResponsePromise<{ data: DischargeScheduleActual }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/gate/discharge-actual`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    },

    /**
     * Get the planned discharge for all gates of a plant
     * - `200` - A plan
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    requestDischargePlan: (args: {
      plant_id: string;
      queryParams: {
        'start-ts': number;
        'end-ts': number;
        'step-size'?: number;
      };
      signal: AbortSignal;
    }): ResponsePromise<{ data: DischargeSchedulePlan }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/gate/discharge-plan`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    },

    /**
     * Get gate opening timeseries
     * - `200` - Gate opening timeseries
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    requestOpeningActual: (args: {
      plant_id: string;
      queryParams: {
        unit: 'percent' | 'cm';
        'start-ts': number;
        'end-ts': number;
      };
      signal: AbortSignal;
    }): ResponsePromise<{ data: OpeningScheduleActual }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/gate/opening-actual`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    },

    /**
     * Get the planned opening for all gates of a plant
     * - `200` - A plan
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    requestOpeningPlan: (args: {
      plant_id: string;
      queryParams: {
        unit: 'percent' | 'cm';
        'start-ts': number;
        'end-ts': number;
        'step-size'?: number;
      };
      signal: AbortSignal;
    }): ResponsePromise<{ data: OpeningSchedulePlan }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/gate/opening-plan`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    },

    /**
     * Submit gate discharge in m³/s (!PREFERRED!)
     * - `204` - Gate discharge successfully submitted
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    submitDischargeActual: (args: { plant_id: string; body: DischargeScheduleActualNullable }): ResponsePromise<void> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/gate/discharge-actual`,
        method: 'POST',
        body: args.body
      });
    },

    /**
     * Submit gate opening in cm or %
     * - `204` - Gate opening successfully submitted
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    submitOpeningActual: (args: {
      plant_id: string;
      queryParams: {
        unit: 'percent' | 'cm';
      };
      body: OpeningScheduleActualNullable;
    }): ResponsePromise<void> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/gate/opening-actual`,
        method: 'POST',
        queryParams: args.queryParams,
        body: args.body
      });
    }
  };

  health = {
    /**
     * Check if API is available and has all necessary DB connections
     *
     * Only used internally to track api status and service level agreement
     * - `200` - Service fully available
     * - `503` - Service unavailable
     */
    getHealthStatus: (args: { signal: AbortSignal; security?: null | undefined }): ResponsePromise<AppContext> => {
      return this.request({
        path: `v1/health`,
        security: args.security,
        signal: args.signal
      });
    },

    /**
     * Check if API is available and has all necessary DB connections
     *
     * Only used internally to track api status and service level agreement
     * - `200` - Service fully available
     * - `503` - Service unavailable
     */
    getHealthStatusDashboard: (args: { signal: AbortSignal; security?: null | undefined }): ResponsePromise<AppContext> => {
      return this.request({
        path: `dashboard-v3/health`,
        security: args.security,
        signal: args.signal
      });
    },

    /**
     * Check if API is available and has all necessary DB connections
     *
     * Only used internally to track api status and service level agreement
     * - `200` - Service fully available
     * - `503` - Service unavailable
     */
    getHealthStatusInternalDashboard: (args: { signal: AbortSignal; security?: null | undefined }): ResponsePromise<AppContext> => {
      return this.request({
        path: `internal-dashboard/health`,
        security: args.security,
        signal: args.signal
      });
    }
  };

  internal = {
    dashboard: {
      account: {
        /**
         * Add a new account
         *
         * Add a new account to the database
         * - `204` - Account successfully added
         * - `409` - Account email already exists
         */
        add: (args: { body: NewAccount }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/account`,
            method: 'POST',
            body: args.body
          });
        },

        /**
         * Assign portfolio to account
         *
         * Assign portfolio to account.
         * - `204` - Successfully assigned portfolio from account
         * - `403` - Permission denied
         * - `409` - Assigning portfolio to API account not possible
         */
        assignPortfolio: (args: { portfolio_id: string; account_id: string }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/account/${args.account_id}`,
            method: 'POST'
          });
        },

        /**
         * List all accounts
         *
         * Get a list of all accounts
         * - `200` - List of accounts
         */
        dashboardAccounts: (args: { signal: AbortSignal }): ResponsePromise<{ data: AccountList }> => {
          return this.request({
            path: `internal-dashboard/account`,
            signal: args.signal
          });
        },

        /**
         * Delete an account
         * - `204` - Successfully deleted account
         * - `400` - Bad request
         * - `404` - Unsupported parameter in the request.
         */
        delete: (args: { account_id: string }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/account/${args.account_id}`,
            method: 'DELETE'
          });
        },

        /**
         * Get a list of all api service accounts
         * - `200` - List of all api service accounts
         */
        listApiAccounts: (args: { signal: AbortSignal }): ResponsePromise<{ data: ApiServiceAccountList }> => {
          return this.request({
            path: `internal-dashboard/account/api-service-account`,
            signal: args.signal
          });
        },

        /**
         * Get a list of all portfolios assigned to account
         * - `200` - List of all portfolios assigned to account
         * - `403` - Permission denied
         */
        requestPortfolios: (args: { account_id: string; signal: AbortSignal }): ResponsePromise<PortfolioList> => {
          return this.request({
            path: `internal-dashboard/account/${args.account_id}/portfolio`,
            signal: args.signal
          });
        },

        /**
         * Set portfolio as default for account
         *
         * Set portfolio as default for account.
         * - `204` - Successfully set portfolio as default for account
         * - `403` - Permission denied
         */
        setDefaultPortfolio: (args: { portfolio_id: string; account_id: string }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/account/${args.account_id}/default`,
            method: 'PUT'
          });
        },

        /**
         * Unassign portfolio from account
         *
         * Unassign portfolio from account.
         * - `204` - Successfully unassigned portfolio from account
         * - `409` - Unassigning portfolio from API account not possible
         */
        unassignPortfolio: (args: { portfolio_id: string; account_id: string }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/account/${args.account_id}`,
            method: 'DELETE'
          });
        }
      },

      component: {
        /**
         * Update the name of a component
         *
         * Update the name of a component.
         * - `204` - Successfully updated component
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         * - `409` - Component id not part of component group
         */
        updateComponent: (args: {
          portfolio_id: string;
          plant_id: string;
          component_type: string;
          component_id: string;
          body: { new_component_id: string; new_component_name: string };
        }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/plant/${args.plant_id}/component_type/${args.component_type}/component/${args.component_id}`,
            method: 'PUT',
            body: args.body
          });
        }
      },

      nomination: {
        /**
         * Reset nominated day ahead plan for the given date
         * - `204` - Nominated day ahead plan successfully reset
         */
        resetDayAhead: (args: { plant_id: string; body: { date: Date } }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/plant/${args.plant_id}/nomination/day_ahead/reset`,
            method: 'PUT',
            body: args.body
          });
        }
      },

      permissions: {
        /**
         * All permissions
         *
         * Get a list of all available public api permissions grouped by unit type
         * - `200` - List of all available public api permissions grouped by unit type
         */
        byUnitType: (args: { signal: AbortSignal }): ResponsePromise<{ data: PermissionsListElem[] }> => {
          return this.request({
            path: `internal-dashboard/permission/by_unit_type`,
            signal: args.signal
          });
        },

        /**
         * Get permissions of account for price forecast source
         * - `200` - Permissions for portfolio account per price forecast source
         * - `403` - Permission denied
         */
        getPriceForecastPermissions: (args: {
          portfolio_id: string;
          account_id: string;
          signal: AbortSignal;
        }): ResponsePromise<{ data: PricePlanPermissionsListElem[] }> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/account/${args.account_id}/price_forecast_source/permission`,
            signal: args.signal
          });
        },

        /**
         * Get permissions for portfolio account
         * - `200` - Permissions for portfolio account per plant
         * - `403` - Permission denied
         */
        listForAccountInPortfolio: (args: {
          portfolio_id: string;
          account_id: string;
          signal: AbortSignal;
        }): ResponsePromise<{ data: AccountPermissionsListElem[] }> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/account/${args.account_id}/permission`,
            signal: args.signal
          });
        },

        /**
         * Set permissions of account for plant
         * - `204` - Success
         * - `403` - Permission denied
         */
        setPermissions: (args: { portfolio_id: string; account_id: string; plant_id: string; body: string[] }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/account/${args.account_id}/plant/${args.plant_id}/permission`,
            method: 'PUT',
            body: args.body
          });
        },

        /**
         * Set permissions of account for price forecast source
         * - `204` - Success
         * - `403` - Permission denied
         */
        setPriceForecastPermissions: (args: {
          portfolio_id: string;
          account_id: string;
          price_forecast_source_id: string;
          body: string[];
        }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/account/${args.account_id}/price_forecast_source/${args.price_forecast_source_id}/permission`,
            method: 'PUT',
            body: args.body
          });
        }
      },

      plant: {
        /**
         * Add a new plant
         *
         * Add a new plant including basic system properties, hydro bodies and
         * control units.
         * - `200` - Successfully added a new plant
         * - `400` - Invalid parameter value in the request
         * - `403` - Permission denied
         */
        add: (args: { body: BasicPlantArrangement }): ResponsePromise<{ data: string }> => {
          return this.request({
            path: `internal-dashboard/setup/plant`,
            method: 'POST',
            body: args.body
          });
        },

        /**
         * Add a component to the plant view (component group)
         *
         * Add a component to the plant.
         * - `200` - Successfully added components
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         * - `409` - Adding component not allowed for optmization portfolio
         */
        addComponent: (args: { portfolio_id: string; plant_id: string }): ResponsePromise<AddComponent> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/plant/${args.plant_id}/component`,
            method: 'POST'
          });
        },

        /**
         * Get a basic plant arrangement
         *
         * Get a basic plant arrangement including basic data, simple hydrobodies
         * and simple control_units
         * - `200` - Successfully fetched plant
         * - `400` - Invalid parameter value in the request
         * - `403` - Permission denied
         */
        getBasicArrangement: (args: { plant_id: string; signal: AbortSignal }): ResponsePromise<BasicPlantArrangement> => {
          return this.request({
            path: `internal-dashboard/setup/plant/${args.plant_id}`,
            signal: args.signal
          });
        },

        /**
         * Get a list of all components of a plant within a portfolio
         *
         * Get all components of a plant.
         * - `200` - Successfully fetched components
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        getComponents: (args: {
          portfolio_id: string;
          plant_id: string;
          signal: AbortSignal;
        }): ResponsePromise<InExTernalComponentList> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/plant/${args.plant_id}/component`,
            signal: args.signal
          });
        },

        /**
         * Remove component from plant
         *
         * Remove a component from plant.
         * - `204` - Successfully removed component
         * - `404` - Unsupported parameter in the request.
         * - `409` - Removing component not allowed for optimization portfolio
         */
        removeComponent: (args: {
          portfolio_id: string;
          plant_id: string;
          component_type: string;
          component_id: string;
        }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/plant/${args.plant_id}/component_type/${args.component_type}/component/${args.component_id}`,
            method: 'DELETE'
          });
        },

        /**
         * Update properties of an existing plant
         *
         * Update properties of an existing plant.
         * - `200` - Successfully updated the plant
         * - `400` - Invalid parameter value in the request
         * - `403` - Permission denied
         */
        update: (args: { plant_id: string; body: PlantUpdate }): ResponsePromise<{ id: string }> => {
          return this.request({
            path: `internal-dashboard/setup/plant/${args.plant_id}`,
            method: 'PUT',
            body: args.body
          });
        }
      },

      portfolio: {
        /**
         * Add a new portfolio
         *
         * Add a new portfolio to the database
         * - `204` - Portfolio successfully added
         * - `403` - Permission denied
         * - `409` - Portfolio id already exists
         */
        add: (args: { body: Portfolio }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio`,
            method: 'POST',
            body: args.body
          });
        },

        /**
         * Add a public api service account to the portfolio
         * - `204` - Success
         * - `403` - Permission denied
         * - `409` - The account already has another portfolio assigned, consider forcing
         */
        addApiAccount: (args: { portfolio_id: string; body: HasEmail }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/account/api-service-account`,
            method: 'PUT',
            body: args.body
          });
        },

        /**
         * Assign plant to portfolio
         *
         * Assign an existing plant to a portfolio
         * - `204` - Successfully assigned plant to portfolio
         * - `403` - Permission denied
         * - `409` - Re-assigning would create a duplicate name
         */
        assignPlant: (args: { portfolio_id: string; plant_id: string }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/plant/${args.plant_id}/assign`,
            method: 'POST'
          });
        },

        /**
         * Delete the portfolio
         * - `204` - Successfully deleted portfolio
         * - `403` - Permission denied
         * - `409` - Operation is not possible because of the current state of the target
         */
        delete: (args: { portfolio_id: string }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}`,
            method: 'DELETE'
          });
        },

        /**
         * The list of plants in the portfolio
         * - `200` - List of plants
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        listPortfolioPlants: (args: { portfolio_id: string; signal: AbortSignal }): ResponsePromise<{ data: PlantList }> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/plant`,
            signal: args.signal
          });
        },

        /**
         * The list of simple plants in the portfolio (including internal IDs)
         * - `200` - List of plants
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        listSimplePortfolioComponentGroups: (args: {
          portfolio_id: string;
          signal: AbortSignal;
        }): ResponsePromise<SimplePlantWithInternalIDList> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/plant/simple`,
            signal: args.signal
          });
        },

        /**
         * List all portfolios
         *
         * Get a list of all portfolios
         * - `200` - List of portfolios
         */
        requestAll: (args: { signal: AbortSignal }): ResponsePromise<{ data: PortfolioList }> => {
          return this.request({
            path: `internal-dashboard/portfolio`,
            signal: args.signal
          });
        },

        /**
         * Set permission for account portfolio
         *
         * Updates dashboard permission for an account
         * - `204` - Success
         * - `404` - Unsupported parameter in the request.
         */
        setWritePermissionByAccountAndPortfolio: (args: {
          account_id: string;
          portfolio_id: string;
          body: { can_write?: boolean | undefined };
        }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/account/${args.account_id}/portfolio/${args.portfolio_id}/write-permission`,
            method: 'PUT',
            body: args.body
          });
        },

        /**
         * Unssign plant from portfolio
         *
         * Unassign an existing plant from a portfolio
         * - `204` - Successfully unassigned plant from portfolio
         * - `403` - Permission denied
         * - `409` - Plants cannot be unassigned from optmization portfolio
         */
        unassignPlant: (args: { portfolio_id: string; plant_id: string }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/plant/${args.plant_id}/unassign`,
            method: 'POST'
          });
        },

        /**
         * Update plant id or plant name
         *
         * Update the plant id or name within that portfolio
         * - `204` - Successfully updated plant
         * - `403` - Permission denied
         * - `404` - Plant id not part of portfolio
         * - `409` - Plant id already taken within that portfolio
         * - `422` - Neither id nor name to update was provided
         */
        updatePlantIdOrName: (args: {
          portfolio_id: string;
          plant_id: string;
          body: { id?: string | undefined; name?: string | undefined };
        }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/portfolio/${args.portfolio_id}/plant/${args.plant_id}`,
            method: 'PUT',
            body: args.body
          });
        }
      },

      priceForecastSource: {
        /**
         * Add a new price forecast source
         *
         * Add a new valid custom price forecast source
         * - `204` - Price forecast source successfully added
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        addPriceForecastSource: (args: { body: PriceForecastSource }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/price_forecast_source`,
            method: 'POST',
            body: args.body
          });
        },

        /**
         * Delete a price forecast source
         *
         * Delete a price forecast source.
         * - `204` - Successfully deleted price forecast source
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        deletePriceForecastSource: (args: { price_forecast_source_uuid: string }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/price_forecast_source/${args.price_forecast_source_uuid}`,
            method: 'DELETE'
          });
        },

        /**
         * Get a list of all price forecast sources
         *
         * Get all currently existing price forecast sources
         * - `200` - Successfully fetched all price forecast sources
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        getAllPriceForecastSources: (args: { signal: AbortSignal }): ResponsePromise<IdentifiedPriceForecastSourceList> => {
          return this.request({
            path: `internal-dashboard/price_forecast_source`,
            signal: args.signal
          });
        },

        /**
         * Update a price forecast source
         *
         * Update an existing price forecast source
         * - `204` - Price forecast source successfully updated
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        updatePriceForecastSource: (args: {
          price_forecast_source_uuid: string;
          body?: PriceForecastSource | undefined;
        }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/price_forecast_source/${args.price_forecast_source_uuid}`,
            method: 'PUT',
            body: args.body
          });
        }
      },

      setup: {
        /**
         * Add a new control_unit
         *
         * Add a new control unit to the database
         * - `200` - Control_unit successfully added
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        addControlUnit: (args: { plant_id: string; body: SimpleControlUnit }): ResponsePromise<unknown> => {
          return this.request({
            path: `internal-dashboard/setup/plant/${args.plant_id}/control_unit`,
            method: 'POST',
            body: args.body
          });
        },

        /**
         * Add a new customer
         *
         * Add a new customer to the database
         * - `204` - Customer successfully added
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        addCustomer: (args: { body: SimpleCustomer }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/setup/customer`,
            method: 'POST',
            body: args.body
          });
        },

        /**
         * Add a new hydro_body
         *
         * Add a new hydro_body with its very basic properties to an existing
         * plant.
         * - `200` - Successfully added a new hydro_body
         * - `400` - Invalid parameter value in the request
         * - `403` - Permission denied
         */
        addHydroBody: (args: { plant_id: string; body: SimpleHydroBody }): ResponsePromise<{ data: string }> => {
          return this.request({
            path: `internal-dashboard/setup/plant/${args.plant_id}/hydrobody`,
            method: 'POST',
            body: args.body
          });
        },

        /**
         * Delete a control unit
         *
         * Delete a control unit.
         * - `204` - Successfully deleted control unit
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         * - `409` - Operation is not possible because of the current state of the target
         */
        deleteControlUnit: (args: { control_unit_id: string }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/setup/control_unit/${args.control_unit_id}`,
            method: 'DELETE'
          });
        },

        /**
         * Delete a hydro_body
         *
         * Delete a hydro_body.
         * - `204` - Successfully deleted hydro_body
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         * - `409` - Operation is not possible because of the current state of the target
         */
        deleteHydroBody: (args: { hydro_body_id: string }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/setup/hydrobody/${args.hydro_body_id}`,
            method: 'DELETE'
          });
        },

        /**
         * Get a list of all Customers
         *
         * Get all currently existing customers from the DB
         * - `200` - Successfully fetched customers
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        getAllCustomers: (args: { signal: AbortSignal }): ResponsePromise<SimpleCustomerList> => {
          return this.request({
            path: `internal-dashboard/setup/customer`,
            signal: args.signal
          });
        },

        /**
         * Get a list of all hydro_bodies
         *
         * Get all currently existing hydro_bodies from the DB
         * - `200` - Successfully fetched hydro_bodies
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        getAllHydroBodies: (args: { plant_id: string; signal: AbortSignal }): ResponsePromise<SimpleHydroBodyList> => {
          return this.request({
            path: `internal-dashboard/setup/plant/${args.plant_id}/hydrobody`,
            signal: args.signal
          });
        },

        /**
         * Get a list of all local time zones
         *
         * Get all currently supported local time zones
         * - `200` - Successfully fetched local time zones
         * - `404` - Unsupported parameter in the request.
         */
        getAllLocalTimeZones: (args: { signal: AbortSignal }): ResponsePromise<TimeZoneList> => {
          return this.request({
            path: `internal-dashboard/setup/local_time_zone`,
            signal: args.signal
          });
        },

        /**
         * Get a list of all Plants
         *
         * Get all currently existing plants from the DB
         * - `200` - Successfully fetched plants
         * - `404` - Unsupported parameter in the request.
         */
        getAllPlants: (args: { signal: AbortSignal }): ResponsePromise<SimplePlantList> => {
          return this.request({
            path: `internal-dashboard/setup/plant`,
            signal: args.signal
          });
        },

        /**
         * Get a list of all price regions
         *
         * Get all currently existing price regions from the DB
         * - `200` - Successfully fetched price regions
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        getAllPriceRegions: (args: { signal: AbortSignal }): ResponsePromise<PriceRegionList> => {
          return this.request({
            path: `internal-dashboard/setup/price_region`,
            signal: args.signal
          });
        },

        /**
         * Get all properties of a control unit
         * - `200` - Successfully fetched control unit
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        getControlUnit: (args: { control_unit_id: string; signal: AbortSignal }): ResponsePromise<CompleteControlUnit> => {
          return this.request({
            path: `internal-dashboard/setup/control_unit/${args.control_unit_id}`,
            signal: args.signal
          });
        },

        /**
         * Get all flow to power pairs (triples) for this control unit
         *
         * Get all flow to power pairs (triples) for this control unit.
         * - `200` - Successfully fetched flow to power pairs (triples)
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        getFlowPowerLevelPoints: (args: { control_unit_id: string; signal: AbortSignal }): ResponsePromise<FlowToPowerList> => {
          return this.request({
            path: `internal-dashboard/setup/control_unit/${args.control_unit_id}/flow_to_power`,
            signal: args.signal
          });
        },

        /**
         * Get all properties of a hydro_body
         * - `200` - Successfully fetched hydro_body
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        getHydroBody: (args: { hydro_body_id: string; signal: AbortSignal }): ResponsePromise<{ data: CompleteHydroBody }> => {
          return this.request({
            path: `internal-dashboard/setup/hydrobody/${args.hydro_body_id}`,
            signal: args.signal
          });
        },

        /**
         * Get all level to content value pairs for this hydro_body
         *
         * Get all level to content value pairs for this hydro_body.
         * - `200` - Successfully fetched level to content value pairs
         * - `403` - Permission denied
         */
        getLevelToContent: (args: { hydrobody_id: string; signal: AbortSignal }): ResponsePromise<LevelToContentList> => {
          return this.request({
            path: `internal-dashboard/setup/hydrobody/${args.hydrobody_id}/level_to_content`,
            signal: args.signal
          });
        },

        /**
         * Get all throughput ratio triples for this control unit
         *
         * Get all throughput ratio triples for this control unit.
         * - `200` - Successfully fetched throughput ratio triples
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        requestThroughputRatio: (args: { control_unit_id: string; signal: AbortSignal }): ResponsePromise<ThroughputRatioList> => {
          return this.request({
            path: `internal-dashboard/setup/control_unit/${args.control_unit_id}/throughput_ratio`,
            signal: args.signal
          });
        },

        /**
         * Shadow a plant
         *
         * Make an exact copy of the whole plant setup and mark it as shadow
         * plant.
         * - `200` - Successfully shadowed a plant
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        shadowPlant: (args: { plant_id: string }): ResponsePromise<{ id: string }> => {
          return this.request({
            path: `internal-dashboard/setup/plant/${args.plant_id}/shadow`,
            method: 'PUT'
          });
        },

        /**
         * Update a control unit
         *
         * Update the properties of a control units
         * - `200` - Successfully updated control unit
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        updateControlUnit: (args: { control_unit_id: string; body: ControlUnitUpdate }): ResponsePromise<{ data: string }> => {
          return this.request({
            path: `internal-dashboard/setup/control_unit/${args.control_unit_id}`,
            method: 'PUT',
            body: args.body
          });
        },

        /**
         * Update level dependent flow to power matrix
         *
         * Update level dependent flow to power matrix.
         * - `200` - Successfully updated level dependent flow to power matrix
         * - `400` - (level dependent) flow to power pair already exists.
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        updateFlowPowerLevelPoints: (args: { control_unit_id: string; body: FlowToPowerList }): ResponsePromise<{ data: string }> => {
          return this.request({
            path: `internal-dashboard/setup/control_unit/${args.control_unit_id}/flow_to_power`,
            method: 'PUT',
            body: args.body
          });
        },

        /**
         * Update a hydro_body
         *
         * Update the properties of a hydro_body
         * - `200` - Successfully updated hydro_body
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         * - `409` - Operation is not possible because of the current state of the target
         */
        updateHydroBody: (args: { hydro_body_id: string; body: HydroBodyUpdate }): ResponsePromise<{ data: string }> => {
          return this.request({
            path: `internal-dashboard/setup/hydrobody/${args.hydro_body_id}`,
            method: 'PUT',
            body: args.body
          });
        },

        /**
         * Update level to content matrix
         * - `204` - Successfully added a new level to content pair
         * - `403` - Permission denied
         */
        updateLevelToContent: (args: { hydrobody_id: string; body: LevelToContentList }): ResponsePromise<void> => {
          return this.request({
            path: `internal-dashboard/setup/hydrobody/${args.hydrobody_id}/level_to_content`,
            method: 'PUT',
            body: args.body
          });
        },

        /**
         * Update level dependent throughput ratio matrix.
         * - `200` - Successfully updated the throughput ratio matrix
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        updateThroughputRatio: (args: { control_unit_id: string; body: UpdateThroughputRatioList }): ResponsePromise<{ data: string }> => {
          return this.request({
            path: `internal-dashboard/setup/control_unit/${args.control_unit_id}/throughput_ratio`,
            method: 'PUT',
            body: args.body
          });
        },

        /**
         * Upload a CSV file containing gate discharge data
         *
         * Upload a CSV file containing gate discharge data. Give instructions
         * on how to handle missing or wrong data of an uploaded file if need be.
         * - `200` - Successfully uploaded gate discharge data and ignored single
         * datapoints
         * - `204` - Successfully uploaded gate discharge data
         * - `300` - Upload needs further handling
         * - `400` - Bad request
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         * - `409` - Gate throughput extrema not set
         */
        uploadGateDischarges: (args: {
          plant_id: string;
          control_unit_id: string;
          queryParams: {
            is_utc: boolean;
            file_id?: number;
            handlings?: HandlingOption[];
          };
          body?: CSVFile | undefined;
        }): ResponsePromise<string | void> => {
          return this.request({
            path: `internal-dashboard/setup/plant/${args.plant_id}/gate/${args.control_unit_id}/upload_gate_discharges`,
            method: 'POST',
            queryParams: args.queryParams,
            requestType: 'blob',
            body: args.body
          });
        },

        /**
         * Upload a CSV file containing gate opening data
         *
         * Upload a CSV file containing gate opening data. Give instructions
         * on how to handle missing or wrong data of an uploaded file if need be.
         * - `200` - Successfully uploaded gate opening data and ignored single
         * datapoints
         * - `204` - Successfully uploaded gate opening data
         * - `300` - Upload needs further handling
         * - `400` - Bad request
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         */
        uploadGateOpenings: (args: {
          plant_id: string;
          control_unit_id: string;
          queryParams: {
            is_utc: boolean;
            file_id?: number;
            handlings?: HandlingOption[];
          };
          body?: CSVFile | undefined;
        }): ResponsePromise<string | void> => {
          return this.request({
            path: `internal-dashboard/setup/plant/${args.plant_id}/gate/${args.control_unit_id}/upload_gate_openings`,
            method: 'POST',
            queryParams: args.queryParams,
            requestType: 'blob',
            body: args.body
          });
        },

        /**
         * Upload a CSV file containing power actual data
         *
         * Upload a CSV file containing power actual data. Give instructions
         * on how to handle missing or wrong data of an uploaded file if need be.
         * - `200` - Successfully uploaded power actual data and ignored single
         * datapoints
         * - `204` - Successfully uploaded power actual data
         * - `300` - Upload needs further handling
         * - `400` - Bad request
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         * - `409` - Turbine generation extrema not set
         */
        uploadPowerActuals: (args: {
          plant_id: string;
          control_unit_id: string;
          queryParams: {
            is_utc: boolean;
            file_id?: number;
            handlings?: HandlingOption[];
          };
          body?: CSVFile | undefined;
        }): ResponsePromise<string | void> => {
          return this.request({
            path: `internal-dashboard/setup/plant/${args.plant_id}/turbine/${args.control_unit_id}/upload_power_actuals`,
            method: 'POST',
            queryParams: args.queryParams,
            requestType: 'blob',
            body: args.body
          });
        },

        /**
         * Upload a CSV file containing reservoir level data
         *
         * Upload a CSV file containing reservoir level data. Give instructions
         * on how to handle missing or wrong data of an uploaded file if need be.
         * - `200` - Successfully uploaded reservoir level data and ignored single
         * datapoints
         * - `204` - Successfully uploaded reservoir level data
         * - `300` - Upload needs further handling
         * - `400` - Bad request
         * - `403` - Permission denied
         * - `404` - Unsupported parameter in the request.
         * - `409` - Reservoir level extrema not set
         */
        uploadReservoirLevels: (args: {
          plant_id: string;
          hydrobody_id: string;
          queryParams: {
            is_utc: boolean;
            file_id?: number;
            handlings?: HandlingOption[];
          };
          body?: CSVFile | undefined;
        }): ResponsePromise<string | void> => {
          return this.request({
            path: `internal-dashboard/setup/plant/${args.plant_id}/reservoir/${args.hydrobody_id}/upload_reservoir_levels`,
            method: 'POST',
            queryParams: args.queryParams,
            requestType: 'blob',
            body: args.body
          });
        }
      }
    }
  };

  outage = {
    /**
     * Retrieve a specific turbine maintenance using its uuid
     * - `200` - A single maintenance for a turbine
     * - `400` - Invalid parameter value in the request
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    requestOutage: (args: { maintenance_id: string; signal: AbortSignal }): ResponsePromise<{ data: TurbineMaintenance }> => {
      return this.request({
        path: `v1/maintenance/${args.maintenance_id}`,
        signal: args.signal
      });
    },

    /**
     * Retrieve all the maintenances in a plant with various filtering options.
     *
     * Retrieve all portfolio maintenances according to various filter options..
     * - `200` - List of maintenances
     */
    requestPlantOutages: (args: {
      plant_id: string;
      signal: AbortSignal;
      queryParams?:
        | {
            'validity-start-ts'?: number;
            'validity-end-ts'?: number;
            'creation-start-ts'?: number;
            'creation-end-ts'?: number;
            'updated-start-ts'?: number;
            'updated-end-ts'?: number;
            label?: string;
            status?: 'Draft' | 'Active' | 'Inactive';
          }
        | undefined;
    }): ResponsePromise<{ data: TurbineMaintenances }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/maintenance`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    },

    /**
     * Retrieve maintenances for all permitted plants within a portfolio.
     *
     * Retrieve a list of maintenances per plant within a portfolio for every plant that
     * has the required read permissions.
     * - `200` - List of maintenances
     */
    requestPortfolioOutages: (args: {
      signal: AbortSignal;
      queryParams?:
        | {
            'validity-start-ts'?: number;
            'validity-end-ts'?: number;
            'creation-start-ts'?: number;
            'creation-end-ts'?: number;
            'updated-start-ts'?: number;
            'updated-end-ts'?: number;
            label?: string;
            status?: 'Draft' | 'Active' | 'Inactive';
          }
        | undefined;
    }): ResponsePromise<{ data: PortfolioMaintenanceResponse }> => {
      return this.request({
        path: `v1/maintenance`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    },

    /**
     * Add a new maintenance for a turbine
     *
     * Add a new maintenance for a turbine.
     * - `201` - Successfully added a new maintenance
     * - `400` - Invalid parameter value in the request
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    submitTurbineOutage: (args: {
      plant_id: string;
      turbine_id: string;
      body: TurbineMaintenanceSubmission;
    }): ResponsePromise<TurbineMaintenance> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/turbine/${args.turbine_id}/maintenance`,
        method: 'POST',
        body: args.body
      });
    },

    /**
     * Update a specific turbine maintenance
     * - `200` - Successfully updated a maintenance
     * - `400` - Invalid parameter value in the request
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    updateOutage: (args: {
      maintenance_id: string;
      body?: MaintenanceSubmission | undefined;
    }): ResponsePromise<{ data: TurbineMaintenance }> => {
      return this.request({
        path: `v1/maintenance/${args.maintenance_id}`,
        method: 'PUT',
        body: args.body
      });
    }
  };

  power = {
    /**
     * Delete overrides (overwrites) for a given timeframe
     * - `200` - Successfully removed all overrides (overwrites) in the given timeframe
     * - `403` - Permission denied
     */
    deleteTurbinePowerPlanOverrides: (args: {
      plant_id: string;
      turbine_id: string;
      queryParams: {
        'start-ts': number;
        'end-ts': number;
      };
    }): ResponsePromise<{ data: OverrideDeleteTyp }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/turbine/${args.turbine_id}/power/net/plan/override`,
        method: 'DELETE',
        queryParams: args.queryParams
      });
    },

    /**
     * Get the actual power of a plant
     * - `200` - Actual power of a plant
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    requestActiveGrossActual: (args: {
      plant_id: string;
      queryParams: {
        'start-ts': number;
        'end-ts': number;
      };
      signal: AbortSignal;
    }): ResponsePromise<{ data: PowerSchedule }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/power/active-gross-actual`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    },

    /**
     * Get the planned power for a plant
     * - `200` - A plan
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    requestActiveGrossPlan: (args: {
      plant_id: string;
      queryParams: {
        'start-ts': number;
        'end-ts': number;
        'step-size'?: number;
      };
      signal: AbortSignal;
    }): ResponsePromise<{ data: PowerSchedule }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/power/active-gross-plan`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    },

    /**
     * Submit power timeseries for a whole plant
     * - `204` - Power successfully submitted
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    submitActiveGrossActual: (args: { plant_id: string; body: PowerScheduleNullable }): ResponsePromise<void> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/power/active-gross-actual`,
        method: 'POST',
        body: args.body
      });
    },

    /**
     * Set overrides (overwrites) for power plans
     * - `204` - Power overrides (overwrites) successfully submitted
     * - `400` - Invalid parameter value in the request
     * - `403` - Permission denied
     */
    submitTurbinePowerPlanOverrides: (args: {
      plant_id: string;
      turbine_id: string;
      body: {
        /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
        timestamp: CreationTimestamp;
        /**
         * Generation in MW
         *
         * @min 0
         */
        value: MwGenerationValue;
      }[];
    }): ResponsePromise<void> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/turbine/${args.turbine_id}/power/net/plan/override`,
        method: 'POST',
        body: args.body
      });
    }
  };

  priceForecast = {
    /**
     * Add a price forecast
     *
     * Add a new price forecast
     * - `204` - Custom price forecast successfully added
     * - `400` - Invalid parameter value in the request
     * - `403` - Permission denied
     */
    submitPriceForecast: (args: { price_forecast_source_id: string; body: PriceForecastSubmission }): ResponsePromise<void> => {
      return this.request({
        path: `v1/price_forecast_source/${args.price_forecast_source_id}/price_forecast`,
        method: 'POST',
        body: args.body
      });
    }
  };

  reservoir = {
    /**
     * Get inflow plan for all reservoirs in plant
     * - `200` - Get inflow plan for all reservoirs in plant
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     */
    getInflowPlan: (args: {
      plant_id: string;
      queryParams: {
        'start-ts': number;
        'end-ts': number;
      };
      signal: AbortSignal;
    }): ResponsePromise<{ data: ReservoirInflowPlans }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/reservoir/inflow-plan`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    },

    /**
     * Get reservoir level timeseries
     * - `200` - Reservoir level timeseries
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    requestLevel: (args: {
      plant_id: string;
      queryParams: {
        'start-ts': number;
        'end-ts': number;
      };
      signal: AbortSignal;
    }): ResponsePromise<{ data: ReservoirLevelActual }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/reservoir/level`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    },

    /**
     * Set overrides (overwrites) for inflow plans
     * - `204` - Inflow plan overrides (overwrites) successfully submitted
     * - `400` - Invalid parameter value in the request
     * - `403` - Permission denied
     */
    submitInflowPlanOverrides: (args: {
      plant_id: string;
      reservoir_id: string;
      body: {
        /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
        timestamp: CreationTimestamp;
        /** Inflow in Cubic metre per second (cumec) */
        value: InflowValue;
      }[];
    }): ResponsePromise<void> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/reservoir/${args.reservoir_id}/inflow/plan/override`,
        method: 'POST',
        body: args.body
      });
    },

    /**
     * Submit reservoir level timeseries for a reservoir
     * - `204` - Reservoir level successfully submitted
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    submitLevel: (args: { plant_id: string; body: ReservoirLevelActual }): ResponsePromise<void> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/reservoir/level`,
        method: 'POST',
        body: args.body
      });
    }
  };

  waterValue = {
    /**
     * Get water-value per turbine for all turbines in plant in currency of market region
     *
     * Get the water-value (aka. Shadow-price, marginal-price) per turbine in
     * the currency of the plants market region
     * - `200` - Water value per turbine for all turbines in plant in currency of market region
     * - `400` - Invalid parameter value in the request
     * - `401` - Access token is missing or invalid
     * - `403` - Permission denied
     * - `404` - Unsupported parameter in the request.
     */
    requestWaterValue: (args: {
      plant_id: string;
      queryParams: {
        'start-ts': number;
        'end-ts': number;
      };
      signal: AbortSignal;
    }): ResponsePromise<{ data: TurbineWaterValues; metrics: Currency }> => {
      return this.request({
        path: `v1/plant/${args.plant_id}/water-value`,
        queryParams: args.queryParams,
        signal: args.signal
      });
    }
  };

  taskMastermind = {
    scheduler: {
      endpoints: {
        /**
         * List of configured periodic tasks
         *
         * Returns a list of scheduled job configurations.
         * It contains a schedule (crontab) and optionally a timezone
         * when the system should run the task
         * - `200` - List of periodic tasks
         */
        listPeriodicTasks: (args: { signal: AbortSignal }): ResponsePromise<{ data: PeriodicTaskList }> => {
          return this.request({
            server: 'scheduler',
            path: `v1/periodic-task/`,
            signal: args.signal
          });
        },

        /**
         * Suspend/Resume a periodic task
         *
         * Updates the state of a periodic task. The state can be suspended (true)
         * or resumed/activated (false)
         *
         * CAUTION: State change takes a minute to take effect!
         * - `204` - The job was successfully suspended/resumed.
         * - `404` - Unknown job name parameter. Job not found.
         */
        setPeriodicTaskState: (args: {
          periodic_task_name: string;
          queryParams: {
            paused: boolean;
          };
        }): ResponsePromise<void> => {
          return this.request({
            server: 'scheduler',
            path: `v1/periodic-task/${args.periodic_task_name}/state`,
            method: 'PUT',
            queryParams: args.queryParams
          });
        },

        /**
         * Trigger the periodic task with custom arguments.
         *
         * Triggers the periodic task with custom arguments. When arguments are empty or none then
         * the task with default values will be triggered.
         * - `204` - Job successfully triggered.
         * - `400` - Invalid parameter value in the request.
         * - `404` - Unknown job name parameter. Job not found.
         */
        triggerPeriodicTask: (args: { periodic_task_name: string; body: TaskArguments }): ResponsePromise<void> => {
          return this.request({
            server: 'scheduler',
            path: `v1/periodic-task/${args.periodic_task_name}/trigger`,
            method: 'POST',
            body: args.body
          });
        }
      }
    }
  };
}
