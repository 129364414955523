import { useStableCallback } from '@hydrogrid/utilities/memoization';
import { useCallback, useState } from 'react';

/**
 * React hook for using component state that can be _controlled_ via props,
 * but falls back to an _"uncontrolled"_ state within the component.
 */
export function useControllableProp<T>(prop: T | undefined, state: T) {
  const isControlled = prop !== undefined;
  const value = isControlled && typeof prop !== 'undefined' ? prop : state;
  return [value, isControlled] as const;
}

interface UseControllableStateProps<T> {
  /** The initial value to be used, in uncontrolled mode. */
  defaultValue: T | (() => T);

  /** The value to used in controlled mode. */
  value?: T | undefined;

  /** The callback fired when the value changes. */
  onChange?: ((value: T) => void) | undefined;

  /** The function that determines if the state should be updated. */
  shouldUpdate?: ((prev: T, next: T) => boolean) | undefined;
}

/**
 * React hook for using component state that can be _controlled_ via props,
 * but falls back to an _"uncontrolled"_ state within the component.
 */
export function useControllableState<T>(props: UseControllableStateProps<T>) {
  const { value: controlledValue, defaultValue, onChange, shouldUpdate = (a, b) => !Object.is(a, b) } = props;

  const stableOnChange = useStableCallback(onChange);
  const stableShouldUpdate = useStableCallback(shouldUpdate);
  const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue as T);

  const isControlled = controlledValue !== undefined;
  const value = isControlled ? (controlledValue as T) : uncontrolledValue;
  const setValueIfUncontrolled = isControlled ? undefined : setUncontrolledValue;

  const updateValue = useCallback(
    (next: React.SetStateAction<T>) => {
      const nextValue = typeof next === 'function' ? (next as (value: T) => T)(value) : next;

      if (!stableShouldUpdate(value, nextValue)) {
        return;
      }

      stableOnChange?.(nextValue);
      setValueIfUncontrolled?.(nextValue);
    },
    [setValueIfUncontrolled, stableOnChange, stableShouldUpdate, value]
  );

  return [value, updateValue, isControlled] as [value: T, setValue: React.Dispatch<React.SetStateAction<T>>, isControlled: boolean];
}
