import type { ChakraTheme } from '@chakra-ui/react';

/**
 * This function is taking care of deleting tokens from the default chakra theme that we don't need
 */
export function cleanupChakraTheme(theme: unknown) {
  delete (theme as ChakraTheme).colors.whatsapp;
  delete (theme as ChakraTheme).colors.telegram;
  delete (theme as ChakraTheme).colors.twitter;
  delete (theme as ChakraTheme).colors.messenger;
  delete (theme as ChakraTheme).colors.facebook;
  delete (theme as ChakraTheme).colors.linkedin;
  delete (theme as ChakraTheme).colors.pink;
  delete (theme as ChakraTheme).colors.purple;
  delete (theme as ChakraTheme).colors.cyan;
  delete (theme as ChakraTheme).colors.yellow;

  delete (theme as ChakraTheme).shadows['2xl'];
  delete (theme as ChakraTheme).shadows['dark-lg'];
  delete (theme as ChakraTheme).shadows['base'];
  delete (theme as ChakraTheme).shadows['xs'];

  delete (theme as ChakraTheme).fontSizes['9xl'];
  delete (theme as ChakraTheme).fontSizes['8xl'];
  delete (theme as ChakraTheme).fontSizes['7xl'];
  delete (theme as ChakraTheme).fontSizes['6xl'];
  delete (theme as ChakraTheme).fontSizes['5xl'];
  delete (theme as ChakraTheme).fontSizes['2xs'];
  delete (theme as ChakraTheme).fontSizes['3xs'];

  delete (theme as ChakraTheme).fontWeights['black'];
  delete (theme as ChakraTheme).fontWeights['extrabold'];
  delete (theme as ChakraTheme).fontWeights['semibold'];
  delete (theme as ChakraTheme).fontWeights['medium'];
  delete (theme as ChakraTheme).fontWeights['light'];
  delete (theme as ChakraTheme).fontWeights['thin'];
  delete (theme as ChakraTheme).fontWeights['hairline'];

  delete (theme as ChakraTheme).lineHeights['10'];
  delete (theme as ChakraTheme).lineHeights['9'];
  delete (theme as ChakraTheme).lineHeights['8'];
  delete (theme as ChakraTheme).lineHeights['7'];
  delete (theme as ChakraTheme).lineHeights['6'];
  delete (theme as ChakraTheme).lineHeights['5'];
  delete (theme as ChakraTheme).lineHeights['4'];
  delete (theme as ChakraTheme).lineHeights['3'];
}
