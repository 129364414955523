import { defineIconComponent } from './defineIconComponent';

import ActionRedoSvg from 'simple-line-icons/src/svgs/action-redo.svg?react';
import ActionUndoSvg from 'simple-line-icons/src/svgs/action-undo.svg?react';
import AnchorSvg from 'simple-line-icons/src/svgs/anchor.svg?react';
import ArrowDownCircleSvg from 'simple-line-icons/src/svgs/arrow-down-circle.svg?react';
import ArrowDownSvg from 'simple-line-icons/src/svgs/arrow-down.svg?react';
import ArrowLeftCircleSvg from 'simple-line-icons/src/svgs/arrow-left-circle.svg?react';
import ArrowLeftSvg from 'simple-line-icons/src/svgs/arrow-left.svg?react';
import ArrowRightCircleSvg from 'simple-line-icons/src/svgs/arrow-right-circle.svg?react';
import ArrowRightSvg from 'simple-line-icons/src/svgs/arrow-right.svg?react';
import ArrowUpCircleSvg from 'simple-line-icons/src/svgs/arrow-up-circle.svg?react';
import ArrowUpSvg from 'simple-line-icons/src/svgs/arrow-up.svg?react';
import BadgeSvg from 'simple-line-icons/src/svgs/badge.svg?react';
import BagSvg from 'simple-line-icons/src/svgs/bag.svg?react';
import BanSvg from 'simple-line-icons/src/svgs/ban.svg?react';
import BasketLoadedSvg from 'simple-line-icons/src/svgs/basket-loaded.svg?react';
import BasketSvg from 'simple-line-icons/src/svgs/basket.svg?react';
import BellSvg from 'simple-line-icons/src/svgs/bell.svg?react';
import BookOpenSvg from 'simple-line-icons/src/svgs/book-open.svg?react';
import BriefcaseSvg from 'simple-line-icons/src/svgs/briefcase.svg?react';
import BubbleSvg from 'simple-line-icons/src/svgs/bubble.svg?react';
import BubblesSvg from 'simple-line-icons/src/svgs/bubbles.svg?react';
import BulbSvg from 'simple-line-icons/src/svgs/bulb.svg?react';
import CalculatorSvg from 'simple-line-icons/src/svgs/calculator.svg?react';
import CalenderSvg from 'simple-line-icons/src/svgs/calender.svg?react';
import CallEndSvg from 'simple-line-icons/src/svgs/call-end.svg?react';
import CallInSvg from 'simple-line-icons/src/svgs/call-in.svg?react';
import CallOutSvg from 'simple-line-icons/src/svgs/call-out.svg?react';
import CameraSvg from 'simple-line-icons/src/svgs/camera.svg?react';
import CamrecorderSvg from 'simple-line-icons/src/svgs/camrecorder.svg?react';
import ChartSvg from 'simple-line-icons/src/svgs/chart.svg?react';
import CheckSvg from 'simple-line-icons/src/svgs/check.svg?react';
import ChemistrySvg from 'simple-line-icons/src/svgs/chemistry.svg?react';
import ClockSvg from 'simple-line-icons/src/svgs/clock.svg?react';
import CloseSvg from 'simple-line-icons/src/svgs/close.svg?react';
import CloudDownloadSvg from 'simple-line-icons/src/svgs/cloud-download.svg?react';
import CloudUploadSvg from 'simple-line-icons/src/svgs/cloud-upload.svg?react';
import CompassSvg from 'simple-line-icons/src/svgs/compass.svg?react';
import ControlEndSvg from 'simple-line-icons/src/svgs/control-end.svg?react';
import ControlForwardSvg from 'simple-line-icons/src/svgs/control-forward.svg?react';
import ControlPauseSvg from 'simple-line-icons/src/svgs/control-pause.svg?react';
import ControlPlaySvg from 'simple-line-icons/src/svgs/control-play.svg?react';
import ControlRewindSvg from 'simple-line-icons/src/svgs/control-rewind.svg?react';
import ControlStartSvg from 'simple-line-icons/src/svgs/control-start.svg?react';
import CreditCardSvg from 'simple-line-icons/src/svgs/credit-card.svg?react';
import CropSvg from 'simple-line-icons/src/svgs/crop.svg?react';
import CupSvg from 'simple-line-icons/src/svgs/cup.svg?react';
import CursorMoveSvg from 'simple-line-icons/src/svgs/cursor-move.svg?react';
import CursorSvg from 'simple-line-icons/src/svgs/cursor.svg?react';
import DiamondSvg from 'simple-line-icons/src/svgs/diamond.svg?react';
import DirectionSvg from 'simple-line-icons/src/svgs/direction.svg?react';
import DirectionsSvg from 'simple-line-icons/src/svgs/directions.svg?react';
import DiscSvg from 'simple-line-icons/src/svgs/disc.svg?react';
import DislikeSvg from 'simple-line-icons/src/svgs/dislike.svg?react';
import DocSvg from 'simple-line-icons/src/svgs/doc.svg?react';
import DocsSvg from 'simple-line-icons/src/svgs/docs.svg?react';
import DrawerSvg from 'simple-line-icons/src/svgs/drawer.svg?react';
import DropSvg from 'simple-line-icons/src/svgs/drop.svg?react';
import EarphonesAltSvg from 'simple-line-icons/src/svgs/earphones-alt.svg?react';
import EarphonesSvg from 'simple-line-icons/src/svgs/earphones.svg?react';
import EmotsmileSvg from 'simple-line-icons/src/svgs/emotsmile.svg?react';
import EnergySvg from 'simple-line-icons/src/svgs/energy.svg?react';
import EnvelopeOpenSvg from 'simple-line-icons/src/svgs/envelope-open.svg?react';
import EnvolopeLetterSvg from 'simple-line-icons/src/svgs/envolope-letter.svg?react';
import EnvolopeSvg from 'simple-line-icons/src/svgs/envolope.svg?react';
import EqualizerSvg from 'simple-line-icons/src/svgs/equalizer.svg?react';
import EventSvg from 'simple-line-icons/src/svgs/event.svg?react';
import ExclamationSvg from 'simple-line-icons/src/svgs/exclamation.svg?react';
import EyeSvg from 'simple-line-icons/src/svgs/eye.svg?react';
import EyeglassSvg from 'simple-line-icons/src/svgs/eyeglass.svg?react';
import FeedSvg from 'simple-line-icons/src/svgs/feed.svg?react';
import FilmSvg from 'simple-line-icons/src/svgs/film.svg?react';
import FireSvg from 'simple-line-icons/src/svgs/fire.svg?react';
import FlagSvg from 'simple-line-icons/src/svgs/flag.svg?react';
import FolderAltSvg from 'simple-line-icons/src/svgs/folder-alt.svg?react';
import FolderSvg from 'simple-line-icons/src/svgs/folder.svg?react';
import FrameSvg from 'simple-line-icons/src/svgs/frame.svg?react';
import GameControllerSvg from 'simple-line-icons/src/svgs/game-controller.svg?react';
import GhostSvg from 'simple-line-icons/src/svgs/ghost.svg?react';
import GlobeAltSvg from 'simple-line-icons/src/svgs/globe-alt.svg?react';
import GlobeSvg from 'simple-line-icons/src/svgs/globe.svg?react';
import GraduationSvg from 'simple-line-icons/src/svgs/graduation.svg?react';
import GraphSvg from 'simple-line-icons/src/svgs/graph.svg?react';
import GridSvg from 'simple-line-icons/src/svgs/grid.svg?react';
import HandbagSvg from 'simple-line-icons/src/svgs/handbag.svg?react';
import HeartSvg from 'simple-line-icons/src/svgs/heart.svg?react';
import HomeSvg from 'simple-line-icons/src/svgs/home.svg?react';
import HourglassSvg from 'simple-line-icons/src/svgs/hourglass.svg?react';
import InfoSvg from 'simple-line-icons/src/svgs/info.svg?react';
import KeySvg from 'simple-line-icons/src/svgs/key.svg?react';
import LayersSvg from 'simple-line-icons/src/svgs/layers.svg?react';
import LikeSvg from 'simple-line-icons/src/svgs/like.svg?react';
import LinkSvg from 'simple-line-icons/src/svgs/link.svg?react';
import ListSvg from 'simple-line-icons/src/svgs/list.svg?react';
import LocationPinSvg from 'simple-line-icons/src/svgs/location-pin.svg?react';
import LockOpenSvg from 'simple-line-icons/src/svgs/lock-open.svg?react';
import LockSvg from 'simple-line-icons/src/svgs/lock.svg?react';
import LoginSvg from 'simple-line-icons/src/svgs/login.svg?react';
import LogoutSvg from 'simple-line-icons/src/svgs/logout.svg?react';
import LoopSvg from 'simple-line-icons/src/svgs/loop.svg?react';
import MagicWandSvg from 'simple-line-icons/src/svgs/magic-wand.svg?react';
import MagnetSvg from 'simple-line-icons/src/svgs/magnet.svg?react';
import MagnifierAddSvg from 'simple-line-icons/src/svgs/magnifier-add.svg?react';
import MagnifierRemoveSvg from 'simple-line-icons/src/svgs/magnifier-remove.svg?react';
import MagnifierSvg from 'simple-line-icons/src/svgs/magnifier.svg?react';
import MapSvg from 'simple-line-icons/src/svgs/map.svg?react';
import MenuSvg from 'simple-line-icons/src/svgs/menu.svg?react';
import MicrophoneSvg from 'simple-line-icons/src/svgs/microphone.svg?react';
import MinusSvg from 'simple-line-icons/src/svgs/minus.svg?react';
import MouseSvg from 'simple-line-icons/src/svgs/mouse.svg?react';
import MusicToneAltSvg from 'simple-line-icons/src/svgs/music-tone-alt.svg?react';
import MusicToneSvg from 'simple-line-icons/src/svgs/music-tone.svg?react';
import MustacheSvg from 'simple-line-icons/src/svgs/mustache.svg?react';
import NoteSvg from 'simple-line-icons/src/svgs/note.svg?react';
import NotebookSvg from 'simple-line-icons/src/svgs/notebook.svg?react';
import OptionsVerticalSvg from 'simple-line-icons/src/svgs/options-vertical.svg?react';
import OptionsSvg from 'simple-line-icons/src/svgs/options.svg?react';
import OrganizationSvg from 'simple-line-icons/src/svgs/organization.svg?react';
import PaperClipSvg from 'simple-line-icons/src/svgs/paper-clip.svg?react';
import PaperPlaneSvg from 'simple-line-icons/src/svgs/paper-plane.svg?react';
import PaypalSvg from 'simple-line-icons/src/svgs/paypal.svg?react';
import PencilSvg from 'simple-line-icons/src/svgs/pencil.svg?react';
import PeopleSvg from 'simple-line-icons/src/svgs/people.svg?react';
import PhoneSvg from 'simple-line-icons/src/svgs/phone.svg?react';
import PictureSvg from 'simple-line-icons/src/svgs/picture.svg?react';
import PieChartSvg from 'simple-line-icons/src/svgs/pie-chart.svg?react';
import PinSvg from 'simple-line-icons/src/svgs/pin.svg?react';
import PlaneSvg from 'simple-line-icons/src/svgs/plane.svg?react';
import PlaylistSvg from 'simple-line-icons/src/svgs/playlist.svg?react';
import PlusSvg from 'simple-line-icons/src/svgs/plus.svg?react';
import PowerSvg from 'simple-line-icons/src/svgs/power.svg?react';
import PresentSvg from 'simple-line-icons/src/svgs/present.svg?react';
import PrinterSvg from 'simple-line-icons/src/svgs/printer.svg?react';
import PuzzleSvg from 'simple-line-icons/src/svgs/puzzle.svg?react';
import QuestionSvg from 'simple-line-icons/src/svgs/question.svg?react';
import RefreshSvg from 'simple-line-icons/src/svgs/refresh.svg?react';
import ReloadSvg from 'simple-line-icons/src/svgs/reload.svg?react';
import RocketSvg from 'simple-line-icons/src/svgs/rocket.svg?react';
import ScreenDesktopSvg from 'simple-line-icons/src/svgs/screen-desktop.svg?react';
import ScreenSmartphoneSvg from 'simple-line-icons/src/svgs/screen-smartphone.svg?react';
import ScreenTabletSvg from 'simple-line-icons/src/svgs/screen-tablet.svg?react';
import SettingsSvg from 'simple-line-icons/src/svgs/settings.svg?react';
import ShareAltSvg from 'simple-line-icons/src/svgs/share-alt.svg?react';
import ShareSvg from 'simple-line-icons/src/svgs/share.svg?react';
import ShieldSvg from 'simple-line-icons/src/svgs/shield.svg?react';
import ShuffleSvg from 'simple-line-icons/src/svgs/shuffle.svg?react';
import SizeActualSvg from 'simple-line-icons/src/svgs/size-actual.svg?react';
import SizeFullscreenSvg from 'simple-line-icons/src/svgs/size-fullscreen.svg?react';
import SocialBehanceSvg from 'simple-line-icons/src/svgs/social-behance.svg?react';
import SocialDribbbleSvg from 'simple-line-icons/src/svgs/social-dribbble.svg?react';
import SocialDropboxSvg from 'simple-line-icons/src/svgs/social-dropbox.svg?react';
import SocialFacebookSvg from 'simple-line-icons/src/svgs/social-facebook.svg?react';
import SocialFoursqareSvg from 'simple-line-icons/src/svgs/social-foursqare.svg?react';
import SocialGithubSvg from 'simple-line-icons/src/svgs/social-github.svg?react';
import SocialGoogleSvg from 'simple-line-icons/src/svgs/social-google.svg?react';
import SocialInstagramSvg from 'simple-line-icons/src/svgs/social-instagram.svg?react';
import SocialLinkedinSvg from 'simple-line-icons/src/svgs/social-linkedin.svg?react';
import SocialPintarestSvg from 'simple-line-icons/src/svgs/social-pintarest.svg?react';
import SocialRedditSvg from 'simple-line-icons/src/svgs/social-reddit.svg?react';
import SocialSkypeSvg from 'simple-line-icons/src/svgs/social-skype.svg?react';
import SocialSoundcloudSvg from 'simple-line-icons/src/svgs/social-soundcloud.svg?react';
import SocialSpotifySvg from 'simple-line-icons/src/svgs/social-spotify.svg?react';
import SocialSteamSvg from 'simple-line-icons/src/svgs/social-steam.svg?react';
import SocialStumbleuponSvg from 'simple-line-icons/src/svgs/social-stumbleupon.svg?react';
import SocialTumblrSvg from 'simple-line-icons/src/svgs/social-tumblr.svg?react';
import SocialTwitterSvg from 'simple-line-icons/src/svgs/social-twitter.svg?react';
import SocialVkontakteSvg from 'simple-line-icons/src/svgs/social-vkontakte.svg?react';
import SocialYoutubeSvg from 'simple-line-icons/src/svgs/social-youtube.svg?react';
import SpeechSvg from 'simple-line-icons/src/svgs/speech.svg?react';
import SpeedometerSvg from 'simple-line-icons/src/svgs/speedometer.svg?react';
import StarSvg from 'simple-line-icons/src/svgs/star.svg?react';
import SupportSvg from 'simple-line-icons/src/svgs/support.svg?react';
import SymbleFemaleSvg from 'simple-line-icons/src/svgs/symble-female.svg?react';
import SymbolMaleSvg from 'simple-line-icons/src/svgs/symbol-male.svg?react';
import TagSvg from 'simple-line-icons/src/svgs/tag.svg?react';
import TargetSvg from 'simple-line-icons/src/svgs/target.svg?react';
import TrashSvg from 'simple-line-icons/src/svgs/trash.svg?react';
import TrophySvg from 'simple-line-icons/src/svgs/trophy.svg?react';
import UmbrellaSvg from 'simple-line-icons/src/svgs/umbrella.svg?react';
import UserFemaleSvg from 'simple-line-icons/src/svgs/user-female.svg?react';
import UserFollowSvg from 'simple-line-icons/src/svgs/user-follow.svg?react';
import UserFollowingSvg from 'simple-line-icons/src/svgs/user-following.svg?react';
import UserUnfollowSvg from 'simple-line-icons/src/svgs/user-unfollow.svg?react';
import UserSvg from 'simple-line-icons/src/svgs/user.svg?react';
import VectorSvg from 'simple-line-icons/src/svgs/vector.svg?react';
import Volume1Svg from 'simple-line-icons/src/svgs/volume-1.svg?react';
import Volume2Svg from 'simple-line-icons/src/svgs/volume-2.svg?react';
import VolumeOffSvg from 'simple-line-icons/src/svgs/volume-off.svg?react';
import WalletSvg from 'simple-line-icons/src/svgs/wallet.svg?react';
import WrenchSvg from 'simple-line-icons/src/svgs/wrench.svg?react';

export const ActionRedoIcon = defineIconComponent('ActionRedoIcon', ActionRedoSvg);
export const ActionUndoIcon = defineIconComponent('ActionUndoIcon', ActionUndoSvg);
export const AnchorIcon = defineIconComponent('AnchorIcon', AnchorSvg);
export const ArrowDownCircleIcon = defineIconComponent('ArrowDownCircleIcon', ArrowDownCircleSvg);
export const ArrowDownIcon = defineIconComponent('ArrowDownIcon', ArrowDownSvg);
export const ArrowLeftCircleIcon = defineIconComponent('ArrowLeftCircleIcon', ArrowLeftCircleSvg);
export const ArrowLeftIcon = defineIconComponent('ArrowLeftIcon', ArrowLeftSvg);
export const ArrowRightCircleIcon = defineIconComponent('ArrowRightCircleIcon', ArrowRightCircleSvg);
export const ArrowRightIcon = defineIconComponent('ArrowRightIcon', ArrowRightSvg);
export const ArrowUpCircleIcon = defineIconComponent('ArrowUpCircleIcon', ArrowUpCircleSvg);
export const ArrowUpIcon = defineIconComponent('ArrowUpIcon', ArrowUpSvg);
export const BadgeIcon = defineIconComponent('BadgeIcon', BadgeSvg);
export const BagIcon = defineIconComponent('BagIcon', BagSvg);
export const BanIcon = defineIconComponent('BanIcon', BanSvg);
export const BasketLoadedIcon = defineIconComponent('BasketLoadedIcon', BasketLoadedSvg);
export const BasketIcon = defineIconComponent('BasketIcon', BasketSvg);
export const BellIcon = defineIconComponent('BellIcon', BellSvg);
export const BookOpenIcon = defineIconComponent('BookOpenIcon', BookOpenSvg);
export const BriefcaseIcon = defineIconComponent('BriefcaseIcon', BriefcaseSvg);
export const BubbleIcon = defineIconComponent('BubbleIcon', BubbleSvg);
export const BubblesIcon = defineIconComponent('BubblesIcon', BubblesSvg);
export const BulbIcon = defineIconComponent('BulbIcon', BulbSvg);
export const CalculatorIcon = defineIconComponent('CalculatorIcon', CalculatorSvg);
export const CalendarIcon = defineIconComponent('CalendarIcon', CalenderSvg);
export const CallEndIcon = defineIconComponent('CallEndIcon', CallEndSvg);
export const CallInIcon = defineIconComponent('CallInIcon', CallInSvg);
export const CallOutIcon = defineIconComponent('CallOutIcon', CallOutSvg);
export const CameraIcon = defineIconComponent('CameraIcon', CameraSvg);
export const CamrecorderIcon = defineIconComponent('CamrecorderIcon', CamrecorderSvg);
export const ChartIcon = defineIconComponent('ChartIcon', ChartSvg);
export const CheckIcon = defineIconComponent('CheckIcon', CheckSvg);
export const ChemistryIcon = defineIconComponent('ChemistryIcon', ChemistrySvg);
export const ClockIcon = defineIconComponent('ClockIcon', ClockSvg);
export const CloseIcon = defineIconComponent('CloseIcon', CloseSvg);
export const CloudDownloadIcon = defineIconComponent('CloudDownloadIcon', CloudDownloadSvg);
export const CloudUploadIcon = defineIconComponent('CloudUploadIcon', CloudUploadSvg);
export const CompassIcon = defineIconComponent('CompassIcon', CompassSvg);
export const ControlEndIcon = defineIconComponent('ControlEndIcon', ControlEndSvg);
export const ControlForwardIcon = defineIconComponent('ControlForwardIcon', ControlForwardSvg);
export const ControlPauseIcon = defineIconComponent('ControlPauseIcon', ControlPauseSvg);
export const ControlPlayIcon = defineIconComponent('ControlPlayIcon', ControlPlaySvg);
export const ControlRewindIcon = defineIconComponent('ControlRewindIcon', ControlRewindSvg);
export const ControlStartIcon = defineIconComponent('ControlStartIcon', ControlStartSvg);
export const CreditCardIcon = defineIconComponent('CreditCardIcon', CreditCardSvg);
export const CropIcon = defineIconComponent('CropIcon', CropSvg);
export const CupIcon = defineIconComponent('CupIcon', CupSvg);
export const CursorMoveIcon = defineIconComponent('CursorMoveIcon', CursorMoveSvg);
export const CursorIcon = defineIconComponent('CursorIcon', CursorSvg);
export const DiamondIcon = defineIconComponent('DiamondIcon', DiamondSvg);
export const DirectionIcon = defineIconComponent('DirectionIcon', DirectionSvg);
export const DirectionsIcon = defineIconComponent('DirectionsIcon', DirectionsSvg);
export const DiscIcon = defineIconComponent('DiscIcon', DiscSvg);
export const DislikeIcon = defineIconComponent('DislikeIcon', DislikeSvg);
export const DocIcon = defineIconComponent('DocIcon', DocSvg);
export const DocsIcon = defineIconComponent('DocsIcon', DocsSvg);
export const DrawerIcon = defineIconComponent('DrawerIcon', DrawerSvg);
export const DropIcon = defineIconComponent('DropIcon', DropSvg);
export const EarphonesAltIcon = defineIconComponent('EarphonesAltIcon', EarphonesAltSvg);
export const EarphonesIcon = defineIconComponent('EarphonesIcon', EarphonesSvg);
export const EmotsmileIcon = defineIconComponent('EmotsmileIcon', EmotsmileSvg);
export const EnergyIcon = defineIconComponent('EnergyIcon', EnergySvg);
export const EnvelopeOpenIcon = defineIconComponent('EnvelopeOpenIcon', EnvelopeOpenSvg);
export const EnvolopeLetterIcon = defineIconComponent('EnvolopeLetterIcon', EnvolopeLetterSvg);
export const EnvolopeIcon = defineIconComponent('EnvolopeIcon', EnvolopeSvg);
export const EqualizerIcon = defineIconComponent('EqualizerIcon', EqualizerSvg);
export const EventIcon = defineIconComponent('EventIcon', EventSvg);
export const ExclamationIcon = defineIconComponent('ExclamationIcon', ExclamationSvg);
export const EyeIcon = defineIconComponent('EyeIcon', EyeSvg);
export const EyeglassIcon = defineIconComponent('EyeglassIcon', EyeglassSvg);
export const FeedIcon = defineIconComponent('FeedIcon', FeedSvg);
export const FilmIcon = defineIconComponent('FilmIcon', FilmSvg);
export const FireIcon = defineIconComponent('FireIcon', FireSvg);
export const FlagIcon = defineIconComponent('FlagIcon', FlagSvg);
export const FolderAltIcon = defineIconComponent('FolderAltIcon', FolderAltSvg);
export const FolderIcon = defineIconComponent('FolderIcon', FolderSvg);
export const FrameIcon = defineIconComponent('FrameIcon', FrameSvg);
export const GameControllerIcon = defineIconComponent('GameControllerIcon', GameControllerSvg);
export const GhostIcon = defineIconComponent('GhostIcon', GhostSvg);
export const GlobeAltIcon = defineIconComponent('GlobeAltIcon', GlobeAltSvg);
export const GlobeIcon = defineIconComponent('GlobeIcon', GlobeSvg);
export const GraduationIcon = defineIconComponent('GraduationIcon', GraduationSvg);
export const GraphIcon = defineIconComponent('GraphIcon', GraphSvg);
export const GridIcon = defineIconComponent('GridIcon', GridSvg);
export const HandbagIcon = defineIconComponent('HandbagIcon', HandbagSvg);
export const HeartIcon = defineIconComponent('HeartIcon', HeartSvg);
export const HomeIcon = defineIconComponent('HomeIcon', HomeSvg);
export const HourglassIcon = defineIconComponent('HourglassIcon', HourglassSvg);
export const InfoIcon = defineIconComponent('InfoIcon', InfoSvg);
export const KeyIcon = defineIconComponent('KeyIcon', KeySvg);
export const LayersIcon = defineIconComponent('LayersIcon', LayersSvg);
export const LikeIcon = defineIconComponent('LikeIcon', LikeSvg);
export const LinkIcon = defineIconComponent('LinkIcon', LinkSvg);
export const ListIcon = defineIconComponent('ListIcon', ListSvg);
export const LocationPinIcon = defineIconComponent('LocationPinIcon', LocationPinSvg);
export const LockOpenIcon = defineIconComponent('LockOpenIcon', LockOpenSvg);
export const LockIcon = defineIconComponent('LockIcon', LockSvg);
export const LoginIcon = defineIconComponent('LoginIcon', LoginSvg);
export const LogoutIcon = defineIconComponent('LogoutIcon', LogoutSvg);
export const LoopIcon = defineIconComponent('LoopIcon', LoopSvg);
export const MagicWandIcon = defineIconComponent('MagicWandIcon', MagicWandSvg);
export const MagnetIcon = defineIconComponent('MagnetIcon', MagnetSvg);
export const MagnifierAddIcon = defineIconComponent('MagnifierAddIcon', MagnifierAddSvg);
export const MagnifierRemoveIcon = defineIconComponent('MagnifierRemoveIcon', MagnifierRemoveSvg);
export const MagnifierIcon = defineIconComponent('MagnifierIcon', MagnifierSvg);
export const MapIcon = defineIconComponent('MapIcon', MapSvg);
export const MenuIcon = defineIconComponent('MenuIcon', MenuSvg);
export const MicrophoneIcon = defineIconComponent('MicrophoneIcon', MicrophoneSvg);
export const MinusIcon = defineIconComponent('MinusIcon', MinusSvg);
export const MouseIcon = defineIconComponent('MouseIcon', MouseSvg);
export const MusicToneAltIcon = defineIconComponent('MusicToneAltIcon', MusicToneAltSvg);
export const MusicToneIcon = defineIconComponent('MusicToneIcon', MusicToneSvg);
export const MustacheIcon = defineIconComponent('MustacheIcon', MustacheSvg);
export const NoteIcon = defineIconComponent('NoteIcon', NoteSvg);
export const NotebookIcon = defineIconComponent('NotebookIcon', NotebookSvg);
export const OptionsVerticalIcon = defineIconComponent('OptionsVerticalIcon', OptionsVerticalSvg);
export const OptionsIcon = defineIconComponent('OptionsIcon', OptionsSvg);
export const OrganizationIcon = defineIconComponent('OrganizationIcon', OrganizationSvg);
export const PaperClipIcon = defineIconComponent('PaperClipIcon', PaperClipSvg);
export const PaperPlaneIcon = defineIconComponent('PaperPlaneIcon', PaperPlaneSvg);
export const PaypalIcon = defineIconComponent('PaypalIcon', PaypalSvg);
export const PencilIcon = defineIconComponent('PencilIcon', PencilSvg);
export const PeopleIcon = defineIconComponent('PeopleIcon', PeopleSvg);
export const PhoneIcon = defineIconComponent('PhoneIcon', PhoneSvg);
export const PictureIcon = defineIconComponent('PictureIcon', PictureSvg);
export const PieChartIcon = defineIconComponent('PieChartIcon', PieChartSvg);
export const PinIcon = defineIconComponent('PinIcon', PinSvg);
export const PlaneIcon = defineIconComponent('PlaneIcon', PlaneSvg);
export const PlaylistIcon = defineIconComponent('PlaylistIcon', PlaylistSvg);
export const PlusIcon = defineIconComponent('PlusIcon', PlusSvg);
export const PowerIcon = defineIconComponent('PowerIcon', PowerSvg);
export const PresentIcon = defineIconComponent('PresentIcon', PresentSvg);
export const PrinterIcon = defineIconComponent('PrinterIcon', PrinterSvg);
export const PuzzleIcon = defineIconComponent('PuzzleIcon', PuzzleSvg);
export const QuestionIcon = defineIconComponent('QuestionIcon', QuestionSvg);
export const RefreshIcon = defineIconComponent('RefreshIcon', RefreshSvg);
export const ReloadIcon = defineIconComponent('ReloadIcon', ReloadSvg);
export const RocketIcon = defineIconComponent('RocketIcon', RocketSvg);
export const ScreenDesktopIcon = defineIconComponent('ScreenDesktopIcon', ScreenDesktopSvg);
export const ScreenSmartphoneIcon = defineIconComponent('ScreenSmartphoneIcon', ScreenSmartphoneSvg);
export const ScreenTabletIcon = defineIconComponent('ScreenTabletIcon', ScreenTabletSvg);
export const SettingsIcon = defineIconComponent('SettingsIcon', SettingsSvg);
export const ShareAltIcon = defineIconComponent('ShareAltIcon', ShareAltSvg);
export const ShareIcon = defineIconComponent('ShareIcon', ShareSvg);
export const ShieldIcon = defineIconComponent('ShieldIcon', ShieldSvg);
export const ShuffleIcon = defineIconComponent('ShuffleIcon', ShuffleSvg);
export const SizeActualIcon = defineIconComponent('SizeActualIcon', SizeActualSvg);
export const SizeFullscreenIcon = defineIconComponent('SizeFullscreenIcon', SizeFullscreenSvg);
export const SocialBehanceIcon = defineIconComponent('SocialBehanceIcon', SocialBehanceSvg);
export const SocialDribbbleIcon = defineIconComponent('SocialDribbbleIcon', SocialDribbbleSvg);
export const SocialDropboxIcon = defineIconComponent('SocialDropboxIcon', SocialDropboxSvg);
export const SocialFacebookIcon = defineIconComponent('SocialFacebookIcon', SocialFacebookSvg);
export const SocialFoursqareIcon = defineIconComponent('SocialFoursqareIcon', SocialFoursqareSvg);
export const SocialGithubIcon = defineIconComponent('SocialGithubIcon', SocialGithubSvg);
export const SocialGoogleIcon = defineIconComponent('SocialGoogleIcon', SocialGoogleSvg);
export const SocialInstagramIcon = defineIconComponent('SocialInstagramIcon', SocialInstagramSvg);
export const SocialLinkedinIcon = defineIconComponent('SocialLinkedinIcon', SocialLinkedinSvg);
export const SocialPintarestIcon = defineIconComponent('SocialPintarestIcon', SocialPintarestSvg);
export const SocialRedditIcon = defineIconComponent('SocialRedditIcon', SocialRedditSvg);
export const SocialSkypeIcon = defineIconComponent('SocialSkypeIcon', SocialSkypeSvg);
export const SocialSoundcloudIcon = defineIconComponent('SocialSoundcloudIcon', SocialSoundcloudSvg);
export const SocialSpotifyIcon = defineIconComponent('SocialSpotifyIcon', SocialSpotifySvg);
export const SocialSteamIcon = defineIconComponent('SocialSteamIcon', SocialSteamSvg);
export const SocialStumbleuponIcon = defineIconComponent('SocialStumbleuponIcon', SocialStumbleuponSvg);
export const SocialTumblrIcon = defineIconComponent('SocialTumblrIcon', SocialTumblrSvg);
export const SocialTwitterIcon = defineIconComponent('SocialTwitterIcon', SocialTwitterSvg);
export const SocialVkontakteIcon = defineIconComponent('SocialVkontakteIcon', SocialVkontakteSvg);
export const SocialYoutubeIcon = defineIconComponent('SocialYoutubeIcon', SocialYoutubeSvg);
export const SpeechIcon = defineIconComponent('SpeechIcon', SpeechSvg);
export const SpeedometerIcon = defineIconComponent('SpeedometerIcon', SpeedometerSvg);
export const StarIcon = defineIconComponent('StarIcon', StarSvg);
export const SupportIcon = defineIconComponent('SupportIcon', SupportSvg);
export const SymbleFemaleIcon = defineIconComponent('SymbleFemaleIcon', SymbleFemaleSvg);
export const SymbolMaleIcon = defineIconComponent('SymbolMaleIcon', SymbolMaleSvg);
export const TagIcon = defineIconComponent('TagIcon', TagSvg);
export const TargetIcon = defineIconComponent('TargetIcon', TargetSvg);
export const TrashIcon = defineIconComponent('TrashIcon', TrashSvg);
export const TrophyIcon = defineIconComponent('TrophyIcon', TrophySvg);
export const UmbrellaIcon = defineIconComponent('UmbrellaIcon', UmbrellaSvg);
export const UserFemaleIcon = defineIconComponent('UserFemaleIcon', UserFemaleSvg);
export const UserFollowIcon = defineIconComponent('UserFollowIcon', UserFollowSvg);
export const UserFollowingIcon = defineIconComponent('UserFollowingIcon', UserFollowingSvg);
export const UserUnfollowIcon = defineIconComponent('UserUnfollowIcon', UserUnfollowSvg);
export const UserIcon = defineIconComponent('UserIcon', UserSvg);
export const VectorIcon = defineIconComponent('VectorIcon', VectorSvg);
export const Volume1Icon = defineIconComponent('Volume1Icon', Volume1Svg);
export const Volume2Icon = defineIconComponent('Volume2Icon', Volume2Svg);
export const VolumeOffIcon = defineIconComponent('VolumeOffIcon', VolumeOffSvg);
export const WalletIcon = defineIconComponent('WalletIcon', WalletSvg);
export const WrenchIcon = defineIconComponent('WrenchIcon', WrenchSvg);
