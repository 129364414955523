import { Button, CheckIcon, HorizontalStack, LockIcon, Text, TextInput, VerticalStack } from '@hydrogrid/design-system';
import { useCallback, useEffect, useState, type FormEvent } from 'react';
import { useChangePassword } from '../../common/api/hooks/AuthDataHooks';
import { useAuthSessions } from '../../common/auth/useAuthSessions';
import { useFormField } from '../../common/form/useFormField';
import { useRouteEnvironment } from '../../common/routing/useRouteEnvironment';
import { useAuthStore } from '../../common/stores/AuthStore';
import { NewPasswordFields } from '../../containers/NewPasswordFields/NewPasswordFields';
import { InlineError } from '../InlineError/InlineError';
import styles from './ChangePasswordForm.module.css';

interface ChangePasswordFormProps {
  onSending: (sending: boolean) => void;
  onSuccess: () => void;
}

export function ChangePasswordForm({ onSending, onSuccess }: ChangePasswordFormProps) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(false);

  const changePasswordEndpoint = useChangePassword();
  const authSessions = useAuthSessions();
  const { logoutAfterPasswordChange } = useAuthStore(state => state.actions);
  const environment = useRouteEnvironment({ optional: true });

  const currentPasswordField = useFormField({
    value: currentPassword,
    onChange: setCurrentPassword
  });

  useEffect(() => {
    onSending(changePasswordEndpoint.isPending);
  }, [changePasswordEndpoint.isPending, onSending]);

  const changePassword = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      changePasswordEndpoint.mutate(
        {
          currentPassword,
          newPassword
        },
        {
          onSuccess: () => {
            setTimeout(() => {
              if (environment) {
                authSessions.forgetSession(environment);
              } else {
                authSessions.forgetAllSessions();
              }

              // Show login overlay
              logoutAfterPasswordChange();
              onSuccess();
            }, 2000);
          }
        }
      );
    },
    [authSessions, changePasswordEndpoint, currentPassword, logoutAfterPasswordChange, environment, newPassword, onSuccess]
  );

  const wrongCurrentPassword = changePasswordEndpoint.error?.message === 'wrong current password';

  return !changePasswordEndpoint.isSuccess ? (
    <HorizontalStack spacing="1rem" padding="1rem 0.5rem">
      <div className={styles.topLeftIcon}>
        <LockIcon size="font" />
      </div>

      <form noValidate className={styles.form} onSubmit={changePassword}>
        <VerticalStack spacing="1.5rem" align="stretch" flex="1 0 auto">
          <VerticalStack spacing="1rem" align="stretch">
            <TextInput
              type="password"
              name="currentPassword"
              placeholder="Current password"
              error={wrongCurrentPassword && 'You provided the wrong password.'}
              disabled={changePasswordEndpoint.isPending}
              value={currentPasswordField.value}
              onFocus={currentPasswordField.handleFocus}
              onChange={currentPasswordField.handleChange}
            />
            <NewPasswordFields
              password={newPassword}
              confirm={confirmPassword}
              disabled={changePasswordEndpoint.isPending}
              onPasswordChange={setNewPassword}
              onConfirmChange={setConfirmPassword}
              onValidityChange={setIsValid}
            />
          </VerticalStack>
          {!wrongCurrentPassword && <InlineError query={changePasswordEndpoint} />}
          <Button colorScheme="primary" type="submit" isDisabled={changePasswordEndpoint.isPending || !isValid}>
            Change password
          </Button>
        </VerticalStack>
      </form>
    </HorizontalStack>
  ) : (
    <HorizontalStack spacing="1rem" padding="1rem">
      <div className={styles.topLeftIcon}>
        <CheckIcon color="positive" size="font" />
      </div>

      <div className={styles.form} data-testid="success-message">
        <VerticalStack align="stretch" justify="center" flex="1 0 auto">
          <Text>Your password was changed successfully!</Text>
          <Text>You have to log in again to continue working.</Text>
        </VerticalStack>
      </div>
    </HorizontalStack>
  );
}
