import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'icon']);

const variants = {
  subtle: helpers.definePartsStyle(props => {
    const { colorScheme: c } = props;

    const mainColorMap: Record<string, string> = { secondary: `${c}.500` };
    const bgColorMap: Record<string, string> = { secondary: 'white' };

    return {
      container: {
        bgColor: bgColorMap[c] ?? `${c}.50`,
        borderWidth: '1px',
        borderColor: mainColorMap[c] ?? `${c}`,
        color: mainColorMap[c] ?? undefined
      },
      icon: {
        color: mainColorMap[c] ?? `${c} !important`
      }
    };
  }),
  solid: helpers.definePartsStyle(props => {
    const { colorScheme: c } = props;
    return {
      container: {
        bgColor: `${c}.400`,
        color: 'white'
      }
    };
  })
};

export const alertTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      px: '4',
      py: '3',
      borderRadius: 'base',
      alignItems: 'flex-start'
    },
    description: {
      whiteSpace: 'wrap',
      width: '100%'
    },
    icon: {
      marginEnd: '3',
      marginTop: '0.5',
      w: '4',
      h: '4'
    }
  },
  variants,
  defaultProps: {
    variant: 'subtle',
    colorScheme: 'primary'
  }
});
