export type DescendantOptions<T> = T & {
  disabled?: boolean | undefined;
  id?: string | undefined;
};

type Descendant<E extends HTMLElement, D> = DescendantOptions<D> & {
  node: E;
  index: number;
};

/** Class to manage descendants and their position in the DOM. */
export class DescendantsManager<E extends HTMLElement, D extends object = {}> {
  private descendants = new Map<E, Descendant<E, D>>();
  private isFirstRender = true;
  private firstRenderIndices = new Map<string, number>();
  private prevIndices = new WeakMap<E, number>();
  private orderChanged = true;

  register = (nodeOrOptions: E | null | DescendantOptions<D>) => {
    if (nodeOrOptions == null) return;

    if (nodeOrOptions instanceof Element) {
      return this.registerNode(nodeOrOptions);
    }

    return (node: E | null) => {
      this.registerNode(node, nodeOrOptions);
    };
  };

  unregister = (node: E) => {
    this.descendants.delete(node);
    this.invalidate();
  };

  removeInitial = (id: string) => {
    this.firstRenderIndices.delete(id);
  };

  invalidate = () => {
    // if (!this.isFirstRender) {
    this.orderChanged = true;
    // }
  };

  ensureNodesAreSorted = () => {
    // TODO(Leon) Post v3, try to make this work in Strict Mode:
    // if (this.orderChanged && !this.isFirstRender) {

    if (this.orderChanged || import.meta.env.DEV) {
      const sorted = sortNodes(Array.from(this.descendants.keys()));
      this.assignIndex(sorted);
      this.orderChanged = false;
    }
  };

  destroy = () => {
    this.descendants.clear();
    this.firstRenderIndices.clear();
    this.isFirstRender = true;
  };

  count = () => this.descendants.size;

  enabledCount = () => this.enabledValues().length;

  values = () => {
    this.ensureNodesAreSorted();
    const values = Array.from(this.descendants.values());
    return values.sort((a, b) => a.index - b.index);
  };

  enabledValues = () => {
    return this.values().filter(descendant => !Boolean(descendant.disabled));
  };

  item = (index: number) => {
    if (this.count() === 0) return undefined;
    return this.values()[index];
  };

  enabledItem = (index: number) => {
    if (this.enabledCount() === 0) return undefined;
    return this.enabledValues()[index];
  };

  getInitialIndex = (id: string) => {
    if (!this.isFirstRender) return -1;

    let index = this.firstRenderIndices.get(id);
    if (index == null) {
      this.firstRenderIndices.set(id, (index = this.firstRenderIndices.size));
    }

    return index;
  };

  parentFinishedRendering = () => {
    this.isFirstRender = false;
  };

  first = () => this.item(0);

  firstEnabled = () => this.enabledItem(0);

  last = () => this.item(this.descendants.size - 1);

  lastEnabled = () => {
    const lastIndex = this.enabledValues().length - 1;
    return this.enabledItem(lastIndex);
  };

  indexOf = (node: E | null) => {
    if (!node) return -1;
    this.ensureNodesAreSorted();
    return this.descendants.get(node)?.index ?? -1;
  };

  enabledIndexOf = (node: E | null) => {
    if (node == null) return -1;
    return this.enabledValues().findIndex(i => i.node.isSameNode(node));
  };

  next = (index: number, loop = true) => {
    const next = getNextIndex(index, this.count(), loop);
    return this.item(next);
  };

  nextEnabled = (index: number, loop = true) => {
    const item = this.item(index);
    if (!item) return;
    const enabledIndex = this.enabledIndexOf(item.node);
    const nextEnabledIndex = getNextIndex(enabledIndex, this.enabledCount(), loop);
    return this.enabledItem(nextEnabledIndex);
  };

  prev = (index: number, loop = true) => {
    const prev = getPrevIndex(index, this.count() - 1, loop);
    return this.item(prev);
  };

  prevEnabled = (index: number, loop = true) => {
    const item = this.item(index);
    if (!item) return;
    const enabledIndex = this.enabledIndexOf(item.node);
    const prevEnabledIndex = getPrevIndex(enabledIndex, this.enabledCount() - 1, loop);
    return this.enabledItem(prevEnabledIndex);
  };

  private registerNode = (node: E | null, options?: DescendantOptions<D>) => {
    if (!node) return;

    const index = this.descendants.get(node)?.index ?? this.prevIndices.get(node) ?? -1;
    const descendant = { node, index, ...options };

    if (options?.disabled !== undefined) {
      descendant.disabled = Boolean(descendant.disabled);
    }

    this.descendants.set(node, descendant as Descendant<E, D>);

    this.invalidate();
  };

  private assignIndex = (descendants: Node[]) => {
    this.descendants.forEach(descendant => {
      const index = descendants.indexOf(descendant.node);
      descendant.index = index;
      this.prevIndices.set(descendant.node, index);
      descendant.node.dataset['index'] = String(index);
    });
  };
}

function sortNodes(nodes: Node[]) {
  return nodes.sort((a, b) => {
    const compare = a.compareDocumentPosition(b);

    if (compare & Node.DOCUMENT_POSITION_FOLLOWING || compare & Node.DOCUMENT_POSITION_CONTAINED_BY) {
      return -1;
    } else if (compare & Node.DOCUMENT_POSITION_PRECEDING || compare & Node.DOCUMENT_POSITION_CONTAINS) {
      return 1;
    } else if (compare & Node.DOCUMENT_POSITION_DISCONNECTED || compare & Node.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC) {
      throw Error('Invalid nodes which can not be sorted.');
    } else {
      return 0;
    }
  });
}

function getNextIndex(current: number, max: number, loop: boolean) {
  let next = current + 1;
  if (loop && next >= max) next = 0;
  return next;
}

function getPrevIndex(current: number, max: number, loop: boolean) {
  let next = current - 1;
  if (loop && next < 0) next = max;
  return next;
}
