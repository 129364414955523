interface TaskPoolConfig {
  concurrency?: number;
  started?: boolean;
}

interface TaskFn<ReturnType> {
  (): ReturnType;
  resolve?: (result: ReturnType) => void;
  reject?: (error: unknown) => void;
}
type SettledCallback = () => void;
type Callback = (result: unknown, task: TaskFn<unknown>) => void;

/**
 * Copied from https://github.com/tannerlinsley/swimmer
 *
 * Extended with Types and Tests + a bit of refactoring to be more readable
 */
export function createTaskPool(config?: TaskPoolConfig) {
  const { concurrency = 5, started = true } = config ?? {};

  let onSettles: Array<SettledCallback> = [];
  let onErrors: Array<Callback> = [];
  let onSuccesses: Array<Callback> = [];
  let active: Array<TaskFn<unknown>> = [];
  let pending: Array<TaskFn<unknown>> = [];

  let running = started;
  let currentConcurrency = concurrency;

  const tick = () => {
    if (!running) {
      return;
    }

    if (!pending.length && !active.length) {
      onSettles.forEach(d => d());
      return;
    }

    while (active.length < currentConcurrency && pending.length) {
      const nextTask = pending.shift()!;
      active.push(nextTask);

      const taskExecutor = async () => {
        let success = false;
        let result: unknown;
        let error: unknown;

        try {
          result = await nextTask();
          success = true;
        } catch (e) {
          error = e;
        }
        active = active.filter(d => d !== nextTask);

        if (success) {
          nextTask.resolve?.(result);
          onSuccesses.forEach(d => d(result, nextTask));
        } else {
          nextTask.reject?.(error);
          onErrors.forEach(d => d(error, nextTask));
        }

        tick();
      };
      void taskExecutor();
    }
  };

  return {
    add: <ReturnType>(task: TaskFn<ReturnType>, priority = false) =>
      new Promise<ReturnType>((resolve, reject) => {
        if (priority) {
          pending.unshift(task as TaskFn<unknown>);
        } else {
          pending.push(task as TaskFn<unknown>);
        }
        task.resolve = resolve;
        task.reject = reject;
        tick();
      }),
    throttle: (time: number) => {
      currentConcurrency = time;
    },

    onSettled: (cb: SettledCallback) => {
      onSettles.push(cb);
      return () => {
        onSettles = onSettles.filter(d => d !== cb);
      };
    },
    onError: (cb: Callback) => {
      onErrors.push(cb);
      return () => {
        onErrors = onErrors.filter(d => d !== cb);
      };
    },
    onSuccess: (cb: Callback) => {
      onSuccesses.push(cb);
      return () => {
        onSuccesses = onSuccesses.filter(d => d !== cb);
      };
    },

    stop: () => {
      running = false;
    },
    start: () => {
      running = true;
      tick();
    },
    clear: () => {
      pending = [];
    },

    getActive: () => active,
    getPending: () => pending,
    getAll: () => [...active, ...pending],
    isRunning: () => running,
    isSettled: () => !running && !active.length && !pending.length
  };
}
