import { Text, TextInput } from '@hydrogrid/design-system';
import { useEffect } from 'react';
import { useFormField } from '../../common/form/useFormField';
import {
  PasswordRequirement,
  PasswordRequirements,
  usePasswordRequirements
} from '../../components/PasswordRequirements/PasswordRequirements';

interface NewPasswordFieldsProps {
  disabled?: boolean;
  password: string;
  confirm: string;
  onPasswordChange: (password: string) => void;
  onConfirmChange: (password: string) => void;
  onValidityChange: (valid: boolean) => void;
}

/**
 * @example
 * <input passwordrules="this text">
 */
const htmlPasswordRules = 'minlength: 12; required: lower; required: upper; required: digit; required: special;';

/**
 * Password field for "Change password" / "Forgot password" forms.
 * User has to enter their new password twice for it to be valid.
 */
export function NewPasswordFields({
  password,
  confirm,
  disabled,
  onPasswordChange,
  onConfirmChange,
  onValidityChange
}: NewPasswordFieldsProps) {
  const passwordField = useFormField({
    value: password,
    onChange: onPasswordChange
  });

  const confirmField = useFormField({
    value: confirm,
    onChange: onConfirmChange
  });

  // TODO: Based on discussion with backend, deal with special characters that can't be encripted/used.
  function isInvalidLatin1Character(char: string) {
    try {
      window.btoa(char);
      return false;
    } catch {
      return true;
    }
  }

  const uniqueNonLatin1Characters = [...new Set(password.split('').filter(isInvalidLatin1Character))];

  const newPasswordValidity = usePasswordRequirements(passwordField.value);
  const confirmValid = passwordField.value === confirmField.value;

  const isValid = newPasswordValidity.isValid && confirmValid && uniqueNonLatin1Characters.length === 0;

  useEffect(() => {
    onValidityChange(isValid);
  }, [isValid, onValidityChange]);

  return (
    <>
      <TextInput
        type="password"
        name="password"
        autoComplete="new-password"
        passwordrules={htmlPasswordRules}
        placeholder="New password"
        disabled={disabled ?? false}
        value={passwordField.value}
        onFocus={passwordField.handleFocus}
        onChange={passwordField.handleChange}
      />
      <Text as="small" color="primary">
        <PasswordRequirements touched={passwordField.touched} {...newPasswordValidity} />
      </Text>
      {uniqueNonLatin1Characters.length > 0 && (
        <Text as="small" color="destructive">
          Password must not contain: {uniqueNonLatin1Characters}
        </Text>
      )}
      <TextInput
        type="password"
        name="confirmPassword"
        passwordrules={htmlPasswordRules}
        placeholder="Confirm password"
        disabled={disabled ?? false}
        value={confirmField.value}
        onFocus={confirmField.handleFocus}
        onChange={confirmField.handleChange}
      />
      <Text as="small" color="primary">
        <PasswordRequirement valid={!confirmField.touched || confirmValid}>Please re-enter your new password.</PasswordRequirement>
      </Text>
    </>
  );
}
