import { stringToBase64 } from '@hydrogrid/utilities/string';
import { noopAbortSignal } from '@hydrogrid/utilities/timing/abortSignal';
import { type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { environments, type Environment } from '../../config/environments';
import { useAuthSessions, type AuthSessions } from '../auth/useAuthSessions';
import { tokenRefreshGraceTimeBeforeExpiry } from '../business-logic/authSettings';
import { useAuthStore } from '../stores/AuthStore';
import { ApiError } from './ApiError';
import { SessionExpiredError } from './SessionExpiredError';
import { TokenVerifyError } from './TokenVerifyError';
import { Api } from './generated-client/Api';
import type { ErrorHook, RequestHook, ResponseHook } from './generated-client/HttpClient';
import type { RequestSecurity } from './generated-client/RequestTypes';

// on one of these status codes, we assume our token is invalid (when contacting the /me endpoint)
const UNAUTHORIZED_STATUS_CODES = [400, 401];

// To prevent an infinite auth loop, we do not revalidate
// the token refesh logic for a specific set of endpoints.
const endpointsThatNeverRefreshToken = [
  'dashboard-v3/auth/multi_factor',
  'dashboard-v3/auth/multi_factor/resend_token',
  'dashboard-v3/auth/password/forgot',
  'dashboard-v3/auth/password/set',
  'dashboard-v3/auth/token',
  'dashboard-v3/health',
  'dashboard-v3/me',
  'v1/auth',
  'v1/auth/logout',
  'v1/auth/refresh',
  'v1/health'
];

/**
 * Specific API class for the Insight frontend with logic & hooks
 * to refresh the access token via a refresh token before/after it expires.
 */
export class InsightApi extends Api {
  private environment: Environment;
  private sessionManager: AuthSessions | null = null;

  constructor(environment: Environment) {
    super();
    this.environment = environment;
    this.baseUrl = environment.api;
  }

  shouldSkipRefreshToken(pathname: string) {
    const baseURLPath = new URL(this.baseUrl.api).pathname;
    const requestPath = pathname.replace(baseURLPath, '');

    return endpointsThatNeverRefreshToken.includes(requestPath);
  }

  setSessionManager(manager: AuthSessions) {
    this.sessionManager = manager;
  }

  private checkingIfStillLoggedIn: Promise<void> | null = null;
  private refreshingToken: Promise<string> | null = null;

  public async checkIfStillLoggedIn() {
    if (this.checkingIfStillLoggedIn) {
      return this.checkingIfStillLoggedIn;
    }

    const token = this.sessionManager?.accessToken;

    const { verifySessionStart, sessionStillValid, errorVerifyingToken, sessionNoLongerValid } = useAuthStore.getState().actions ?? {};
    verifySessionStart();

    return (this.checkingIfStillLoggedIn = api.dashboard.user.tokenInfo({ signal: noopAbortSignal() }).then(
      ({ data: tokenInfo }) => {
        if (!tokenInfo.email_address) {
          throw new Error('Invalid application state: User account has no email address.');
        }

        sessionStillValid({
          email: tokenInfo.email_address,
          fullName: tokenInfo.name ?? tokenInfo.email_address
        });
      },
      error => {
        const errorObj = error as { status?: number };
        const isUnauthorized = typeof errorObj.status === 'number' && UNAUTHORIZED_STATUS_CODES.includes(errorObj.status);
        if (!isUnauthorized) {
          // in this case the backend e.g. is not reachable or is returning a 500 error
          errorVerifyingToken();
          throw new TokenVerifyError();
        }

        console.info(`Previous session ${token?.tokenId ?? ''} is no longer valid.`);
        sessionNoLongerValid();
        this.sessionManager?.forgetSession(this.environment);

        throw new SessionExpiredError();
      }
    )).finally(() => {
      this.checkingIfStillLoggedIn = null;
    });
  }

  protected async refreshAccessToken() {
    if (this.refreshingToken) {
      return this.refreshingToken;
    }

    return (this.refreshingToken = this.requestNewAccessToken().finally(() => {
      this.refreshingToken = null;
    }));
  }

  protected async requestNewAccessToken() {
    const { refreshTokenStart, refreshTokenSuccess, refreshTokenExpired, refreshTokenFailed } = useAuthStore.getState().actions ?? {};
    refreshTokenStart();

    try {
      const tokens = this.sessionManager?.tokenSet;
      if (!tokens) {
        console.error('Invalid application state: Need to refresh access token, but access token is not stored.');
        throw new SessionExpiredError();
      }

      const tokenPair = await this.auth.getRefreshToken({ body: { access_token: tokens.accessJWT } });

      this.sessionManager?.tokenRefreshed({
        environment: this.environment,
        accessToken: tokenPair.access_token,
        refreshToken: tokenPair.refresh_token
      });

      refreshTokenSuccess();

      return tokenPair.access_token;
    } catch (error) {
      if (error instanceof ApiError && error.status === 401) {
        refreshTokenExpired();
        throw new SessionExpiredError();
      }

      const errorObj = error instanceof Error ? error : new Error(String(error), { cause: error });
      refreshTokenFailed();
      throw errorObj;
    }
  }

  protected override securityProvider = async (request: Request, security: RequestSecurity) => {
    switch (security?.security) {
      case 'basicAuth':
        request.headers.set('Authorization', `Basic ${stringToBase64(security.username + ':' + security.password, 'latin1')}`);
        break;

      case 'basicAuthMfaSecret':
        request.headers.set('Authorization', `Basic ${stringToBase64(security.username + ':' + security.mfaSecret, 'latin1')}`);
        break;

      case 'bearerAuth':
        request.headers.set('Authorization', `Bearer ${security.token}`);
        break;

      default: {
        const isTokenRefreshRequest = request.url.endsWith('/auth/refresh') && request.method === 'POST';
        const tokens = this.sessionManager?.tokenSet;

        const path = new URL(request.url).pathname;
        const canRefreshToken = !this.shouldSkipRefreshToken(path);

        if (!tokens) {
          return request;
        } else if (isTokenRefreshRequest) {
          request.headers.set('Authorization', `Bearer ${tokens.refreshJWT}`);
        } else if (canRefreshToken && this.refreshingToken) {
          const token = await this.refreshingToken;
          request.headers.set('Authorization', `Bearer ${token}`);
        } else {
          const considerExpiredAt = tokens.access.validUntil.getTime() - tokenRefreshGraceTimeBeforeExpiry;
          const isAlreadyOrAlmostExpired = Date.now() > considerExpiredAt;

          if (canRefreshToken && isAlreadyOrAlmostExpired) {
            const token = await this.refreshAccessToken();
            request.headers.set('Authorization', `Bearer ${token}`);
            return request;
          }

          request.headers.set('Authorization', `Bearer ${tokens.accessJWT}`);
        }
      }
    }
    return request;
  };

  protected override requestHook: RequestHook | null = async request => {
    const path = new URL(request.url).pathname;

    if (this.shouldSkipRefreshToken(path)) {
      return request;
    }

    if (request.method === 'GET' && this.checkingIfStillLoggedIn) {
      await this.checkingIfStillLoggedIn;
    }

    const { status: loginStatus } = useAuthStore.getState() ?? {};
    if (loginStatus === 'logged-out') {
      throw new SessionExpiredError();
    }

    return request;
  };

  protected override responseHook: ResponseHook | null = async (response, request) => {
    if (response.status === 401) {
      const path = new URL(request.url).pathname;

      if (this.shouldSkipRefreshToken(path)) {
        return response;
      }

      await this.checkIfStillLoggedIn();
    }

    return response;
  };

  protected override errorHook: ErrorHook | null = (error, _request) => {
    throw error;
  };
}

/**
 * App-wide API instance, changes when the user selects a different backend environment.
 * Defaults to the first environment (production api when in production frontend, etc.)
 */
export let api = new InsightApi(environments[0]);

const apisByEnvironment = new Map<Environment, InsightApi>();
apisByEnvironment.set(environments[0], api);

for (const environment of environments.slice(1)) {
  apisByEnvironment.set(environment, new InsightApi(environment));
}

/**
 * Provides the API instance to the application, and automatically
 * changes the API baseUrl based on the backend environment in the route.
 */
export function ProvideApi({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();
  const sessions = useAuthSessions();

  const slug = pathname.match(/^\/([^/]+)\//)?.[1];
  const environmentFromPath = environments.find(env => env.slug === slug);
  const apiForEnvironment = environmentFromPath && apisByEnvironment.get(environmentFromPath);

  if (apiForEnvironment) {
    api = apiForEnvironment;
  }

  api.setSessionManager(sessions);

  return <>{children}</>;
}

/* c8 ignore start */
let forcedTestApi: InsightApi | undefined;

export function setApiForTests(testApi: InsightApi) {
  if (import.meta.env.MODE !== 'test') {
    throw new Error(`For testing only and should not be used in ${import.meta.env.MODE}.`);
  }

  api = testApi;
  for (const environment of environments) {
    apisByEnvironment.set(environment, testApi);
  }
}
/* c8 ignore stop */

export function useApiForEnvironment(environment: Environment): Api;
export function useApiForEnvironment(environment: Environment | null | undefined): Api | null;
export function useApiForEnvironment(environment: Environment | null | undefined): Api | null {
  if (!environment) {
    return null;
  }

  let api = apisByEnvironment.get(environment);
  if (!api) {
    if (import.meta.env.MODE === 'test' && forcedTestApi) {
      return forcedTestApi;
    }

    api = new InsightApi(environment);
    apisByEnvironment.set(environment, api);
  }

  return api;
}
