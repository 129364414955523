import { api } from '../InsightApi';
import { defineQuery } from '../query-utils/defineQuery';

export const availableConstraintTypes = defineQuery((portfolioId: string) => ({
  queryKey: ['portfolio', portfolioId, 'constraint-types'],
  queryFn: ({ signal }) => api.dashboard.constraint.getAllConstraintTypes({ portfolio_id: portfolioId, signal })
}));

export const constraintsForPlant = defineQuery(({ portfolioId, plantId }: { portfolioId: string; plantId: string }) => ({
  queryKey: ['portfolio', portfolioId, 'plant', plantId, 'constraints'],
  queryFn: ({ signal }) => api.dashboard.plant.requestConstraints({ portfolio_id: portfolioId, plant_id: plantId, signal })
}));
