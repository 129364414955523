import { Children as ReactChildren, useEffect } from 'react';

interface TitleProps {
  children: string | string[];
}

/**
 * A component to allow setting the document title (displayed on the tab, in history, on bookmarks).
 *
 * @usage
 * ```tsx
 * function IcecreamPage() {
 *   return <>
 *     <Title>Icecream</Title>
 *     <p>500 fun facts about icecream!</p>
 *   </>;
 * }
 */
export function Title({ children }: TitleProps) {
  const title = ReactChildren.map(children, child => (Array.isArray(child) ? child.join('') : child)).join('');

  // TODO: Pass title to a provide that assigns it to document.title (to allow prefix/suffix)
  void title;
  useEffect(() => {
    document.title = `${title} - HYDROGRID Insight`;
  }, [title]);

  return null;
}
