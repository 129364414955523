import { useShallowMemo } from '@hydrogrid/utilities/memoization';
import type { PartialOrUndefined } from '@hydrogrid/utilities/typescript';
import { useMemo } from 'react';
import { useHash } from '../hashing/useHash';
import type { Theme, ThemeColor, ThemeColorWithOpacity, ThemeDuration, ThemeFont, ThemeTextStyle } from '../theme/Theme';
import { useStyleRule } from './useStyles';

type StylableResponsiveValue<T> = T | { [B in keyof Theme['breakpoints']]: T };

export interface ThemeableProps {
  animationDuration?: StylableResponsiveValue<ThemeDuration>;
  backgroundColor?: StylableResponsiveValue<ThemeColor | ThemeColorWithOpacity>;
  borderColor?: StylableResponsiveValue<ThemeColor | ThemeColorWithOpacity>;
  color?: StylableResponsiveValue<ThemeColor | ThemeColorWithOpacity>;
  font?: ThemeFont;
  margin?: StylableResponsiveValue<string | number>;
  padding?: StylableResponsiveValue<string | number>;
  textStyle?: StylableResponsiveValue<ThemeTextStyle>;
  transitionDuration?: StylableResponsiveValue<ThemeDuration>;
}

type ThemeablePropName = keyof ThemeableProps;

const themeablePropNames: ThemeablePropName[] = [
  'animationDuration',
  'backgroundColor',
  'borderColor',
  'color',
  'font',
  'margin',
  'padding',
  'textStyle',
  'transitionDuration'
];
const themeablePropNameSet: ReadonlySet<ThemeablePropName> = new Set(themeablePropNames);
const isThemeablePropName = (name: string): name is ThemeablePropName => themeablePropNameSet.has(name as ThemeablePropName);

/**
 * Allow to use style namespaced classnames.
 *
 * @example
 * ```tsx
 * const { color, textStyle, ...otherProps } = props;
 * const className = useScopedStyles('MyComponent', { color, textStyle });
 * return <div className={className}>Hello, Mister {otherProps.lastName}!</div>;
 * ```
 **/
export function useThemeableStyles(namespace: string, props: PartialOrUndefined<ThemeableProps>): string {
  const [themeableProps] = pickThemeableProps(props);
  const themeablePropsMemoized = useShallowMemo(() => themeableProps, [themeableProps]);

  const hash = useHash(themeablePropsMemoized);
  const scopedClassName = `${namespace}-${hash}`;

  const cssRule = useMemo(
    () => formatThemablePropsAsRule(`.${scopedClassName}`, themeablePropsMemoized),
    [scopedClassName, themeablePropsMemoized]
  );

  useStyleRule(cssRule, namespace);

  return scopedClassName;
}

/**
 * Picks themeable props from a props hash.
 *
 * @example
 * ```ts
 * const props = { color: 'primary', font: 'body', age: 50, lastName: 'Musk' };
 * const [themeableProps, otherProps] = pickThemeableProps(props);
 * console.log(themeableProps); // -> { color: 'primary', font: 'body' };
 * console.log(otherProps); // -> { age: 50, lastName: 'Musk' };
 * ```
 **/
export function pickThemeableProps<T extends PartialOrUndefined<ThemeableProps>>(
  props: T
): [styleProps: Partial<ThemeableProps>, otherProps: Omit<T, keyof ThemeableProps>] {
  const styleProps: Record<string, unknown> = {};
  const otherProps: Record<string, unknown> = {};

  for (const [key, value] of Object.entries(props)) {
    if (isThemeablePropName(key)) {
      styleProps[key] = value;
    } else {
      otherProps[key] = value;
    }
  }

  return [styleProps as Partial<ThemeableProps>, otherProps as Omit<T, keyof ThemeableProps>];
}

function formatThemablePropsAsRule(selector: string, props: Partial<ThemeableProps>) {
  const styles: string[] = [];

  const { backgroundColor, borderColor, color, font, margin, padding, textStyle } = props;

  // TODO: Support for responsive styles, e.g.
  // props = { margin: { small: 4, medium: 8, large: 12 } }

  if (isString(textStyle)) {
    if (color == null) {
      styles.push(`color: var(--textStyle-${textStyle}-color, currentColor)`);
    }

    styles.push(
      `font-family: var(--textStyle-${textStyle}-font)`,
      `font-style: var(--textStyle-${textStyle}-fontStyle)`,
      `font-weight: var(--textStyle-${textStyle}-fontWeight)`,
      `text-decoration: var(--textStyle-${textStyle}-textDecoration)`
    );
  }

  if (isString(font)) {
    styles.push(`font-family: var(--font-${font})`);
  }

  if (isString(color)) {
    styles.push(`color: var(--color-${color})`);
  }

  if (isString(borderColor)) {
    styles.push(`border-color: var(--color-${borderColor})`);
  }

  if (isString(backgroundColor)) {
    styles.push(`background-color: var(--color-${backgroundColor})`);
  }

  if (isStringOrNumber(margin)) {
    styles.push(`margin: ${margin}`);
  }

  if (isStringOrNumber(padding)) {
    styles.push(`padding: ${padding}`);
  }

  return [`${selector} {`, ...styles.map(line => `  ${line};`), `}`].join('\n');
}

function isString(value: unknown): value is string {
  return typeof value === 'string';
}

function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

function isStringOrNumber(value: unknown): value is string | number {
  return isString(value) || isNumber(value);
}
