import { ResponsePromise } from '../ResponsePromise';
import type { RequestSecurity, TypedRequestFunction, TypedResponse } from './RequestTypes';

export type SecurityProvider = (request: Request, security: RequestSecurity | null) => Request | Promise<Request>;
export type RequestHook = (request: Request) => Request | Promise<Request>;
export type ResponseHook = (response: Response, request: Request) => Response | Promise<Response>;
export type ErrorHook = (error: Error, request: Request) => Response | Promise<Response> | void | Promise<void> | never;

export class HttpClient {
  baseUrl = { api: 'https://api.hydrogrid.ai', scheduler: 'https://mastermind-api.hydrogrid.eu/v1' };
  readonly request: TypedRequestFunction;

  /** Provide a different securityProvider to set headers etc. on the request. */
  protected securityProvider: SecurityProvider = (request: Request) => request;

  /** Change a request before it is sent. */
  protected requestHook: RequestHook | null = null;

  /** Change a response after it is received. */
  protected responseHook: ResponseHook | null = null;

  /**
   * Handle a critical (non-HTTP) error.
   *
   * Return a `Response` if you can handle the error,
   * rethrow the error if it can not be handled.
   */
  protected errorHook: ErrorHook | null = null;

  protected baseApiParams: RequestInit = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  };

  constructor() {
    this.request = this.sendRequest.bind(this) as TypedRequestFunction;
  }

  protected sendRequest(args: RequestArgs): ResponsePromise<unknown> {
    const { responseType = 'json', method = 'get', path, queryParams } = args;
    return new ResponsePromise<unknown>(() => this.sendRawRequest(args), responseType, { method, path, queryParams });
  }

  private async sendRawRequest(args: RequestArgs): Promise<TypedResponse<unknown>> {
    const { path, method, body, queryParams, requestType = 'json', responseType = 'json', security, signal = null } = args;

    // Fallback url is the base api
    let urlPath = args.server !== undefined ? this.baseUrl[args.server] : this.baseUrl.api;

    if (urlPath.startsWith('/')) {
      urlPath = location.origin + urlPath;
    }

    let url: URL;
    try {
      url = new URL(path, urlPath);
    } catch (e) {
      throw Error('Error constructing url');
    }

    for (const [name, values] of Object.entries(queryParams ?? {})) {
      for (const value of Array.isArray(values) ? values : [values]) {
        url.searchParams.append(name, String(value));
      }
    }

    let requestBody: BodyInit | null = null;
    const headers = new Headers(this.baseApiParams.headers);

    switch (requestType) {
      case 'json':
        headers.set('content-type', 'application/json');
        requestBody = body === undefined ? requestBody : JSON.stringify(body);
        break;

      case 'text':
        if (body instanceof Blob) {
          const contents = await readFileAsBase64(body);
          headers.set('content-type', body.type);
          headers.set('content-type-transfer-encoding', 'base64');
          requestBody = contents;
        } else {
          requestBody = String(body);
        }
        break;

      case 'formData': {
        const formData = new FormData();
        for (const [key, data] of Object.entries((typeof body === 'object' && body) || {})) {
          if (data instanceof File) {
            formData.append(key, data, data.name);
          } else if (data instanceof Blob) {
            formData.append(key, data);
          } else {
            formData.append(key, typeof data === 'object' && data != null ? JSON.stringify(data) : String(data));
          }
        }
        requestBody = formData;
        break;
      }

      case 'blob': {
        if (typeof body === 'string') {
          headers.set('content-type', 'text/csv');
          requestBody = body;
        }
      }
    }

    if (responseType === 'json') {
      headers.set('accept', 'application/json');
    }

    let request = new Request(url.toString(), {
      ...this.baseApiParams,
      method: method ?? 'GET',
      headers,
      body: requestBody,
      signal
    });

    request = await this.securityProvider(request, security ?? null);

    if (this.requestHook) {
      request = await this.requestHook(request);
    }

    try {
      let responsePromise = fetch(request);

      if (this.responseHook) {
        responsePromise = responsePromise.then(response => {
          if (this.responseHook) {
            return this.responseHook(response, request);
          } else {
            return response;
          }
        });
      }

      return responsePromise as Promise<TypedResponse<unknown>>;
    } catch (cause) {
      const error = cause instanceof Error ? cause : new Error(String(cause), { cause });

      if (this.errorHook) {
        const returnValue = await this.errorHook(error, request);

        if (returnValue instanceof Response) {
          return returnValue as TypedResponse<unknown>;
        }
      }

      // TODO: cleanup
      throw error;
    }
  }
}

function readFileAsBase64(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(String(reader.result));
    reader.onerror = () => reject(new Error('Failed to format file as base64'));
    reader.readAsBinaryString(file);
  });
}

interface RequestArgs {
  path: string;
  method?: string;
  queryParams?: { [key: string]: string | number };
  requestType?: 'json' | 'text' | 'formData' | 'blob';
  body?: unknown;
  responseType?: 'json' | 'blob' | 'text';
  security?: RequestSecurity;
  signal?: AbortSignal;
  server?: 'api' | 'scheduler' | undefined;
}
