import type { DataRange } from '@hydrogrid/design-system';
import { api } from '../InsightApi';
import { dataRangeToBackendParameters } from '../query-utils/dataRangeToBackendParameters';
import { defineQuery } from '../query-utils/defineQuery';

export const plantMaintenances = defineQuery(
  ({ portfolioId, plantId, range, timeZone }: { portfolioId: string; plantId: string; range: DataRange; timeZone: string }) => ({
    queryKey: ['maintenances', portfolioId, 'plant', plantId, range, timeZone],
    queryFn: async ({ signal }) => {
      const { granularity: _remove, ...queryParams } = dataRangeToBackendParameters(range, timeZone);

      const response = await api.dashboard.outage.requestPlantOutages({
        portfolio_id: portfolioId,
        plant_id: plantId,
        queryParams,
        signal
      });
      return response.data;
    }
  })
);

export const maintenance = defineQuery(({ portfolioId, plantId, uuid }: { portfolioId: string; plantId: string; uuid: string }) => ({
  queryKey: ['maintenances', portfolioId, 'plant', plantId, 'uuid', uuid],
  queryFn: ({ signal }) =>
    api.dashboard.outage
      .requestOutage({ portfolio_id: portfolioId, plant_id: plantId, maintenance_id: uuid, signal })
      .then(response => response.data)
}));

export const maintenanceHistory = defineQuery(({ uuid }: { uuid: string }) => ({
  queryKey: ['maintenances', 'history', uuid],
  queryFn: ({ signal }) => api.dashboard.outage.requestOutageHistory({ maintenance_id: uuid, signal }).then(response => response.data)
}));
