import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const $size = cssVar('checkbox-size');

const baseStyleControl = defineStyle(props => {
  const { colorScheme: c } = props;

  return {
    w: $size.reference,
    h: $size.reference,
    transitionProperty: 'box-shadow',
    transitionDuration: 'normal',
    border: '2px solid',
    borderRadius: 'sm',
    borderColor: 'inherit',
    color: 'white',

    _checked: {
      bg: `${c}.500`,
      borderColor: `${c}.500`,
      color: 'white',

      _hover: {
        bg: `${c}.600`,
        borderColor: `${c}.600`
      },

      _disabled: {
        borderColor: 'secondary.200',
        bg: 'secondary.200',
        color: 'white'
      },

      _invalid: {
        bg: 'red.500',
        borderColor: 'red.500',
        color: 'white'
      }
    },

    _indeterminate: {
      bg: `${c}.500`,
      borderColor: `${c}.500`,
      color: 'white'
    },

    _disabled: {
      borderColor: 'secondary.200'
    },

    _invalid: {
      borderColor: 'red.500'
    }
  };
});

const baseStyleContainer = defineStyle({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  _disabled: { cursor: 'not-allowed' }
});

const baseStyleLabel = defineStyle({
  userSelect: 'none',
  height: 'fit-content',
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  _disabled: { color: 'secondary.400' },
  _hover: {
    cursor: 'pointer',

    _disabled: {
      cursor: 'not-allowed'
    }
  }
});

const baseStyleIcon = defineStyle({
  transitionProperty: 'transform',
  transitionDuration: 'normal'
});

const baseStyle = definePartsStyle(props => ({
  icon: baseStyleIcon,
  container: baseStyleContainer,
  control: baseStyleControl(props),
  label: baseStyleLabel
}));

const sizes = {
  sm: definePartsStyle({
    control: { [$size.variable]: 'sizes.3' },
    container: { gap: 1 },
    label: { fontSize: 'sm' },
    icon: { fontSize: '8px' }
  }),
  md: definePartsStyle({
    control: { [$size.variable]: 'sizes.4' },
    container: { gap: 1.5 },
    label: { fontSize: 'md' },
    icon: { fontSize: '8px' }
  }),
  lg: definePartsStyle({
    control: { [$size.variable]: 'sizes.5' },
    container: { gap: 2 },
    label: { fontSize: 'lg' },
    icon: { fontSize: '12px' }
  })
};

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'primary'
  }
});
