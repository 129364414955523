/**
 * @file Defines types & utilities for "data ranges", representing the `start, end, granularity` of charts.
 *
 * Dev note: All months in data range start at 1 (January), not 0 - unlike the JavaScript `Date`.
 */

import type { KeyOfUnion } from '@hydrogrid/utilities/typescript';
import { getDaysInMonth, getISOWeeksInYear } from 'date-fns';

export type DataRange =
  | YearlyDataRange
  | QuarterlyDataRange
  | MonthlyDataRange
  | WeeklyDataRange
  | DailyDataRange
  | HourlyDataRange
  | SubHourlyDataRange;

export type DataRangeGranularity = DataRange['granularity'];

/** State of a data range while selecting */
export type PartialDataRange = InferAsPartialDataRange<DataRange>;

type InferAsPartialDataRange<U> = U extends { granularity: string; start: unknown; end: unknown }
  ? { granularity: U['granularity']; start?: U['start'] | undefined; end?: U['end'] | undefined }
  : never;

interface YearlyDataRange {
  granularity: 'year';
  start: {
    year: number;
  };
  end: {
    year: number;
  };
}

interface QuarterlyDataRange {
  granularity: 'quarter';
  start: {
    quarter: number;
    year: number;
  };
  end: {
    quarter: number;
    year: number;
  };
}

interface MonthlyDataRange {
  granularity: 'month';
  start: {
    month: number;
    year: number;
  };
  end: {
    month: number;
    year: number;
  };
}

interface WeeklyDataRange {
  granularity: 'week';
  start: {
    week: number;
    year: number;
  };
  end: {
    week: number;
    year: number;
  };
}

export interface DailyDataRange {
  granularity: 'day';
  start: {
    day: number;
    month: number;
    year: number;
  };
  end: {
    day: number;
    month: number;
    year: number;
  };
}

/** For now, we only allow fetching hourly and finer data granularities with a day as start/end time. */
interface HourlyDataRange {
  granularity: 'hour';
  start: {
    day: number;
    month: number;
    year: number;
  };
  end: {
    day: number;
    month: number;
    year: number;
  };
}

interface SubHourlyDataRange {
  granularity: 'half-hour' | 'raw';
  start: {
    day: number;
    month: number;
    year: number;
  };
  end: {
    day: number;
    month: number;
    year: number;
  };
}

const validGranularities: DataRangeGranularity[] = ['year', 'quarter', 'month', 'week', 'day', 'hour', 'half-hour', 'raw'];

type RangeStartEndPropertyKey = KeyOfUnion<DataRange['start']>;

const validStartEndPropertiesPerGranularity: Record<DataRangeGranularity, RangeStartEndPropertyKey[]> = {
  year: ['year'],
  quarter: ['year', 'quarter'],
  month: ['year', 'month'],
  week: ['year', 'week'],
  day: ['year', 'month', 'day'],
  hour: ['year', 'month', 'day'],
  'half-hour': ['year', 'month', 'day'],
  raw: ['year', 'month', 'day']
};

/**
 * Type guard to check if a value is a data range.
 *
 * Note: This does not check if the range is valid, @see {@link isValidDataRange}.
 */
export function isDataRange(input: unknown): input is DataRange {
  if (typeof input !== 'object' || !input || Array.isArray(input)) {
    return false;
  }

  const { granularity, start, end, ...extraProps } = input as DataRange;
  if (
    !validGranularities.includes(granularity) ||
    typeof start !== 'object' ||
    typeof end !== 'object' ||
    start == null ||
    end == null ||
    Object.keys(extraProps).length > 0 ||
    Object.prototype.toString.call(start) !== '[object Object]' ||
    Object.prototype.toString.call(end) !== '[object Object]'
  ) {
    return false;
  }

  const validStartEndProperties = validStartEndPropertiesPerGranularity[granularity];
  const extraEndKeys = new Set(Object.keys(end));

  for (const [key, value] of Object.entries(start)) {
    if (!validStartEndProperties.includes(key as RangeStartEndPropertyKey)) {
      return false;
    }

    if (typeof value !== 'number' || !(key in end) || typeof end[key as keyof typeof end] !== 'number') {
      return false;
    }

    extraEndKeys.delete(key);
  }

  if (extraEndKeys.size > 0) {
    return false;
  }

  return true;
}

/**
 * Validates if a range is valid:
 * - start <= end
 * - years are in the range [1900, 2200]
 * - quarters are in the range [1, 4]
 * - months are in the range [1, 12]
 * - days are in the range [1, max-days-in-month]
 */
export function isValidDataRange(range: DataRange) {
  if (
    range.start.year > range.end.year ||
    range.start.year < 1900 ||
    range.start.year > 2200 ||
    range.end.year < 1900 ||
    range.end.year > 2200
  ) {
    return false;
  }

  if (Object.values(range.start).some(Number.isNaN) || Object.values(range.end).some(Number.isNaN)) {
    return false;
  }

  switch (range.granularity) {
    case 'year':
      break;

    case 'quarter':
      if (range.start.quarter < 1 || range.start.quarter > 4 || range.end.quarter < 1 || range.end.quarter > 4) {
        return false;
      }
      if (range.start.year === range.end.year && range.start.quarter > range.end.quarter) {
        return false;
      }
      return true;

    case 'week': {
      const weeksInStartYear = getISOWeeksInYear(new Date(range.start.year, 2, 1));
      const weeksInEndYear = getISOWeeksInYear(new Date(range.end.year, 2, 1));

      if (range.start.week < 1 || range.start.week > weeksInStartYear || range.end.week < 1 || range.end.week > weeksInEndYear) {
        return false;
      }

      if (range.start.year === range.end.year && range.start.week > range.end.week) {
        return false;
      }

      return true;
    }

    case 'month':
    case 'day':
    case 'hour':
    case 'half-hour':
    case 'raw': {
      if (
        range.start.month < 1 ||
        range.start.month > 12 ||
        range.end.month < 1 ||
        range.end.month > 12 ||
        (range.start.year === range.end.year && range.start.month > range.end.month)
      ) {
        return false;
      } else if (range.granularity === 'month') {
        return true;
      }

      if (range.start.month === range.end.month && range.start.day > range.end.day) {
        return false;
      }

      if (
        range.start.day < 1 ||
        range.end.day < 1 ||
        range.start.day > getDaysInMonth(new Date(range.start.year, range.start.month - 1)) ||
        range.end.day > getDaysInMonth(new Date(range.end.year, range.end.month - 1))
      ) {
        return false;
      }
      break;
    }

    default: {
      const unhandledGranularity: never = range;
      void unhandledGranularity;
      return false;
    }
  }

  return true;
}

/**
 * Format a {@link DataRange} as a string. Whenever possible, an ISO format is used.
 *
 * Year quarters are formatted as "{year}-Q{quarter}", as the ISO format is confusing:
 * - First quarter of 2023: `2023-33` (ISO) equals `2023-Q1` (ours)
 * - Fourth quarter of 2024: `2023-36` (ISO) equals `2024-Q4` (ours)
 *
 * For granularities which would be ambiguous, a "--{granularity}" suffix is added:
 * - `2023--2024` (no ambiguity)
 * - `2023-06-16--2023-07-25` (daily granularity)
 * - `2023-06-16--2023-07-25--1h` (hourly granularity, ambiguous otherwise)
 *
 * @example
 * ```
 * stringifyDataRange({ granularity: "week", start: { year: 2020, week: 40 }, end: { year: 2023, week: 2 })
 * // -> "2020W40--2023W02"
 * ```
 */
export function stringifyDataRange(range: DataRange): string {
  const pad2 = (num: number) => String(num | 0).padStart(2, '0');

  switch (range.granularity) {
    case 'year':
      return `${range.start.year}--${range.end.year}`;

    case 'quarter':
      return `${range.start.year}Q${range.start.quarter}--${range.end.year}Q${range.end.quarter}`;

    case 'month':
      return `${range.start.year}-${pad2(range.start.month)}--${range.end.year}-${pad2(range.end.month)}`;

    case 'week':
      return `${range.start.year}W${pad2(range.start.week)}--${range.end.year}W${pad2(range.end.week)}`;

    case 'day':
    case 'hour':
    case 'half-hour':
    case 'raw': {
      const isoDateRange = `${range.start.year}-${pad2(range.start.month)}-${pad2(range.start.day)}--${range.end.year}-${pad2(
        range.end.month
      )}-${pad2(range.end.day)}`;

      const suffix = {
        day: '',
        hour: '--1h',
        'half-hour': '--30m',
        raw: '--raw'
      }[range.granularity];

      return `${isoDateRange}${suffix}`;
    }

    default: {
      const unhandledCase: never = 'granularity' in range ? (range as { granularity: never }).granularity : range;
      throw new Error(`Invalid granularity "${String(unhandledCase)}" in data range.`);
    }
  }
}

/**
 * Parse a {@link DataRange} as a string formatted by {@link stringifyDataRange}.
 *
 * @example
 * ```
 * parseDataRange("2020W40--2023W2")
 * // -> { granularity: "week", start: { year: 2020, week: 40 }, end: { year: 2023, week: 2 }
 * ```
 */
export function parseDataRange(input: string): DataRange {
  const throwIfInvalid = (range: DataRange) => {
    if (!isValidDataRange(range)) {
      throw new TypeError(`Input "${input}" produces an invalid data range.`);
    }
    return range;
  };

  // "2018--2022"
  const yearly = input.match(/^(\d{4})--(\d{4})$/);
  if (yearly) {
    return throwIfInvalid({
      granularity: 'year',
      start: { year: Number(yearly[1]) },
      end: { year: Number(yearly[2]) }
    });
  }

  // "2018Q2--2022Q3", "2018-Q2--2022-Q3"
  const quarterly = input.match(/^(\d{4})-?Q([1-4])--(\d{4})-?Q([1-4])$/);
  if (quarterly) {
    const [, startYear, startQuarter, endYear, endQuarter] = quarterly.map(Number);

    return throwIfInvalid({
      granularity: 'quarter',
      start: { year: startYear, quarter: startQuarter },
      end: { year: endYear, quarter: endQuarter }
    });
  }

  // "2023-33--2024-36" (Q1 2023 - Q4 2024)
  // https://www.loc.gov/standards/datetime/, section "Sub year groupings"
  const quarterlyISO = input.match(/^(\d{4})-3([3-6])--(\d{4})-3([3-6])$/);
  if (quarterlyISO) {
    const [, startYear, startQuarterCode, endYear, endQuarterCode] = quarterlyISO.map(Number);

    return throwIfInvalid({
      granularity: 'quarter',
      start: { year: startYear, quarter: startQuarterCode - 2 },
      end: { year: endYear, quarter: endQuarterCode - 2 }
    });
  }

  // "2018-04--2022-08"
  const monthly = input.match(/^(\d{4})-(0[1-9]|1[012])--(\d{4})-(0[1-9]|1[012])$/);
  if (monthly) {
    const [, startYear, startMonth, endYear, endMonth] = monthly.map(Number);

    return throwIfInvalid({
      granularity: 'month',
      start: { year: startYear, month: startMonth },
      end: { year: endYear, month: endMonth }
    });
  }

  // "2021-W52--2023-W05", "2021W52--2023W05"
  const weekly = input.match(/^(\d{4})-?W(0[1-9]|[1-4]\d|5[0-3])--(\d{4})-?W(0[1-9]|[1-4]\d|5[0-3])$/);
  if (weekly) {
    const [, startYear, startWeek, endYear, endWeek] = weekly.map(Number);

    return throwIfInvalid({
      granularity: 'week',
      start: { year: startYear, week: startWeek },
      end: { year: endYear, week: endWeek }
    });
  }

  // "2021-12-16--2023-04-15",
  // "2021-12-16--2023-04-15--raw",
  // "2021-12-16--2023-04-15--30m"
  const dailyOrLower = input.match(
    /^(\d{4})-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])--(\d{4})-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])(|--1h|--30m|--raw)$/
  );
  if (dailyOrLower) {
    const [, startYear, startMonth, startDay, endYear, endMonth, endDay] = dailyOrLower.map(Number);
    const suffix = dailyOrLower[7];

    const granularity = {
      '': 'day',
      '--1h': 'hour',
      '--30m': 'half-hour',
      '--raw': 'raw'
    }[suffix] as 'day' | 'hour' | 'half-hour' | 'raw';

    return throwIfInvalid({
      granularity,
      start: { year: startYear, month: startMonth, day: startDay },
      end: { year: endYear, month: endMonth, day: endDay }
    });
  }

  throw new TypeError(`String "${input}" is not a valid data range.`);
}
