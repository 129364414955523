import type { OmitStrict } from '@hydrogrid/utilities/typescript';
import {
  forwardRef,
  type ComponentPropsWithRef,
  type ComponentType,
  type PropsWithoutRef,
  type ReactElement,
  type ReactNode,
  type RefAttributes
} from 'react';
import { useThemeableStyles } from '../../css/useThemeableStyles';
import type { ThemeColor } from '../../theme/Theme';
import { classNames } from '../../utils/classNames';
import styles from './Menu.module.css';
import { MenuItemIcon } from './MenuItemIcon';
import { MenuKeyboardShortcut } from './MenuKeyboardShortcut';
import { useMenuContext, useMenuItem, type UseMenuItemProps } from './useMenu';

interface MenuItemProps<E extends HTMLElement> extends OmitStrict<UseMenuItemProps<E>, 'hasIcon'> {
  children?: ReactNode;

  className?: string | undefined;

  color?: ThemeColor;

  icon?: ReactElement | undefined;

  busy?: boolean | undefined;
}

/** Explicit type to use a different component/tag with `as`. */
interface ExplicitlyTypedMenuItem {
  (props: { as?: undefined; color?: ThemeColor } & MenuItemProps<HTMLButtonElement>): ReactElement | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <As extends ComponentType<any>>(props: { as: As } & ComponentPropsWithRef<As> & MenuItemProps<RefType<As>>): ReactElement | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RefType<T extends ComponentType<any>> =
  ComponentPropsWithRef<T> extends RefAttributes<infer R> ? (R extends Element ? NonNullable<R> : HTMLElement) : HTMLElement;

const MenuItemComponent = forwardRef<HTMLButtonElement, MenuItemProps<HTMLButtonElement> & { as?: ComponentType }>((props, ref) => {
  const { children, className, color, shortcut, icon, busy = false, as: AsComponent, ...otherProps } = props;

  const scopedClassName = useThemeableStyles('MenuItem', { color });

  const { variant, hasIcons: menuHasIcons } = useMenuContext();
  const menuitemProps = useMenuItem({ ...otherProps, hasIcon: icon != null }, ref);

  const Component = AsComponent ? (AsComponent as (props: {}) => ReactElement | null) : 'button';

  // Render <button type="button"> if no component was passed with `as`
  const buttonProps = (AsComponent ? {} : { type: 'button' }) as PropsWithoutRef<ComponentType>;

  const otherItemsHaveIcon = menuHasIcons && !icon;

  return (
    <Component
      {...buttonProps}
      {...menuitemProps}
      className={classNames(
        styles.menuItem,
        styles[`menuItem-${variant}`],
        otherItemsHaveIcon && styles[`menuItem-${variant}-othersHaveIcon`],
        menuitemProps.disabled && styles.menuItemDisabled,
        busy && styles.menuItemBusy,
        scopedClassName,
        className
      )}
      aria-busy={busy || undefined}
    >
      {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
      {icon || shortcut ? <span className={styles.menuItemContents}>{children}</span> : children}
      {shortcut != null && <MenuKeyboardShortcut hotkey={shortcut} />}
    </Component>
  );
});

export const MenuItem = MenuItemComponent as unknown as ExplicitlyTypedMenuItem;
