/**
 * Group array items by a property.
 *
 * @example
 * const input = [
 *   { species: 'fruit', name: 'Apple', color: 'red' },
 *   { species: 'fruit', name: 'Banana', color: 'yellow' },
 *   { species: 'fruit', name: 'Date', color: 'brown' },
 *   { species: 'fruit', name: 'Plum', color: 'purple' },
 *   { species: 'dog', name: 'Husky', color: 'white' },
 *   { species: 'dog', name: 'Golden retriever', color: 'yellow' },
 *   { species: 'horse', name: 'Secretariat', color: 'brown' }
 * ];
 *
 * const bySpecies = groupBy(input, 'species');
 * // bySpecies = {
 * //   fruit: [
 * //     { species: 'fruit', name: 'Apple', color: 'red' },
 * //     { species: 'fruit', name: 'Banana', color: 'yellow' },
 * //     { species: 'fruit', name: 'Date', color: 'brown' },
 * //     { species: 'fruit', name: 'Plum', color: 'purple' }
 * //   ],
 * //   dog: [
 * //     { species: 'dog', name: 'Husky', color: 'white' },
 * //     { species: 'dog', name: 'Golden retriever', color: 'yellow' }
 * //   ],
 * //   horse: [
 * //     { species: 'horse', name: 'Secretariat', color: 'brown' }
 * //   ]
 * // }
 *
 * const byColor = groupBy(input, 'color');
 * // byColor = {
 * //   red: [{ species: 'fruit', name: 'Apple', color: 'red' }],
 * //   yellow: [
 * //     { species: 'fruit', name: 'Banana', color: 'yellow' },
 * //     { species: 'dog', name: 'Golden retriever', color: 'yellow' }
 * //   ],
 * //   brown: [
 * //     { species: 'fruit', name: 'Date', color: 'brown' },
 * //     { species: 'horse', name: 'Secretariat', color: 'brown' }
 * //   ],
 * //   purple: [{ species: 'fruit', name: 'Plum', color: 'purple' }],
 * //   white: [{ species: 'dog', name: 'Husky', color: 'white' }],
 * // }
 */
export function groupBy<
  InputType,
  Key extends keyof InputType,
  KeyType extends string | number = ObjectKeyForType<InputType[Key]>,
  OutputKey extends keyof InputType = never,
  OutputType = [OutputKey] extends [never] ? InputType : InputType[OutputKey]
>(
  items: Iterable<InputType>,
  key: Key | ((item: InputType) => KeyType),
  groupItem?: OutputKey | ((item: InputType) => OutputType) | undefined
) {
  const map = groupToMap(
    items,
    // Stringify keys so `1` and `"1"` refer to the same group
    item => String(typeof key === 'function' ? key(item) : item[key]),
    groupItem
  );

  return Object.fromEntries(map) as { [group in KeyType & PropertyKey]?: OutputType[] };
}

type ObjectKeyForType<T> = T extends string
  ? T
  : T extends number
    ? T
    : T extends true
      ? 'true'
      : T extends false
        ? 'false'
        : T extends null
          ? 'null'
          : T extends undefined
            ? 'undefined'
            : T extends (infer A)[]
              ? ObjectKeyForType<A>
              : T extends RegExp
                ? string
                : T extends bigint
                  ? string | number
                  : T extends object
                    ? '[object Object]'
                    : never;

/**
 * Create a `Map` instance of array items, grouped by a property.
 *
 * @example
 * const input = [
 *   { species: 'fruit', name: 'Apple', color: 'red' },
 *   { species: 'fruit', name: 'Banana', color: 'yellow' },
 *   { species: 'fruit', name: 'Date', color: 'brown' },
 *   { species: 'fruit', name: 'Plum', color: 'purple' },
 *   { species: 'dog', name: 'Husky', color: 'white' },
 *   { species: 'dog', name: 'Golden retriever', color: 'yellow' },
 *   { species: 'horse', name: 'Secretariat', color: 'brown' }
 * ];
 *
 * const map = groupToMap(input, 'species');
 * map.get('fruit') // => [...]
 * map.get('dog') // => [...]
 * map.get('horse') // => [...]
 * map.has('dolphin') // => false
 */
// export function groupToMap<T, K extends keyof T, G extends keyof T = never, O = G extends never ? T : T[G]>(items: Iterable<T>, key: K | ((item: T) => K), groupItem?: (G | ((item: T) => O) | undefined)) {
export function groupToMap<
  InputType,
  Key extends keyof InputType,
  KeyType = InputType[Key],
  OutputKey extends keyof InputType = never,
  OutputType = [OutputKey] extends [never] ? InputType : InputType[OutputKey]
>(
  items: Iterable<InputType>,
  key: Key | ((item: InputType) => KeyType),
  outputKeyOrMapFn?: OutputKey | ((item: InputType) => OutputType) | undefined
) {
  const groups = new Map<KeyType, OutputType[]>();

  for (const item of items) {
    const groupKey = typeof key === 'function' ? key(item) : (item[key] as KeyType);
    const group = groups.get(groupKey);
    const mappedItem =
      outputKeyOrMapFn === undefined
        ? (item as unknown as OutputType)
        : typeof outputKeyOrMapFn === 'function'
          ? outputKeyOrMapFn(item)
          : (item[outputKeyOrMapFn] as OutputType);

    if (group !== undefined) {
      group.push(mappedItem);
    } else {
      groups.set(groupKey, [mappedItem]);
    }
  }

  return groups;
}
