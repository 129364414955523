import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgWarningTriangle = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 512, height: 512, viewBox: "0 0 24 24", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M14.88 2.67a3.3 3.3 0 0 0-5.76 0L.42 18.2a3.18 3.18 0 0 0 .02 3.19A3.26 3.26 0 0 0 3.3 23h17.42a3.26 3.26 0 0 0 2.85-1.62 3.18 3.18 0 0 0 .03-3.19zm6.95 17.7a1.28 1.28 0 0 1-1.12.63H3.29a1.28 1.28 0 0 1-1.12-.63 1.18 1.18 0 0 1-.01-1.2l8.7-15.52a1.35 1.35 0 0 1 2.27 0l8.71 15.52a1.18 1.18 0 0 1 0 1.2z" }),
  /* @__PURE__ */ jsx("path", { d: "M12 7a1 1 0 0 0-1 1v6a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1z" }),
  /* @__PURE__ */ jsx("circle", { cx: 12, cy: 18, r: 1 })
] });
const ForwardRef = forwardRef(SvgWarningTriangle);
export default ForwardRef;
