import { searchStringToRegex } from '@hydrogrid/utilities/string';

export function getNextItemFromSearch<T>(
  items: T[],
  searchTerm: string,
  itemToString: (item: T) => string,
  currentItem: T | undefined,
  matchStrategy: 'fuzzy' | 'start' = 'start'
): T | undefined {
  if (searchTerm == null) {
    return currentItem;
  }

  let match: (input: string) => boolean;

  if (matchStrategy === 'fuzzy') {
    const regex = searchStringToRegex(searchTerm);
    match = input => regex.test(input);
  } else {
    const searchLowercase = searchTerm.toLocaleLowerCase();
    match = input => input.toLocaleLowerCase().startsWith(searchLowercase);
  }

  if (currentItem === undefined) {
    return items.find(item => match(itemToString(item)));
  }

  const matchingItems = items.filter(item => match(itemToString(item)));

  if (matchingItems.length > 0) {
    if (matchingItems.includes(currentItem)) {
      const currentIndex = matchingItems.indexOf(currentItem);
      const nextIndex = (currentIndex + 1) % matchingItems.length;
      return matchingItems[nextIndex];
    }

    return matchingItems[0];
  }

  return currentItem;
}
