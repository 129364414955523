import type { ReactNode } from 'react';
import { Button, type ButtonProps } from '../Button/Button';
import { Dialog, type DialogProps } from './Dialog';

export interface ConfirmDialogProps extends Omit<DialogProps, 'isOpen' | 'footer' | 'children'> {
  description: ReactNode;
  cancelButtonProps?: Omit<ButtonProps, 'onClick'>;
  confirmButtonProps?: Omit<ButtonProps, 'onClick'>;
  hideConfirmButton?: boolean;
  onConfirm: () => void;
}

export function ConfirmDialog({
  cancelButtonProps,
  confirmButtonProps,
  description,
  hideConfirmButton = false,
  ...props
}: ConfirmDialogProps) {
  return (
    <Dialog
      isOpen
      {...props}
      footer={
        <>
          <Button
            data-testid="cancelConfirmDialog"
            autoFocus
            variant="outline"
            colorScheme="secondary"
            onClick={props.onClose}
            {...cancelButtonProps}
          >
            {cancelButtonProps?.children ?? 'Cancel'}
          </Button>
          {!hideConfirmButton && (
            <Button data-testid="confirmDialogBtn" colorScheme="error" onClick={props.onConfirm} {...confirmButtonProps}>
              {confirmButtonProps?.children ?? 'Confirm'}
            </Button>
          )}
        </>
      }
    >
      {description}
    </Dialog>
  );
}
