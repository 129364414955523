import { createLocalStore } from '@hydrogrid/utilities/stores';
import type { UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { combine } from 'zustand/middleware';
import type {
  MaintenanceReason,
  MaintenanceType,
  SettingStatusType,
  TurbineMaintenance
} from '../../../common/api/generated-client/Schemas';
import { useCombineQueries } from '../../../common/api/query-utils/combineQueries';

type State = {
  plants: string[] | 'all';
  search: string;
  status: SettingStatusType[] | 'all';
  turbines: string[] | 'all';
  reason: MaintenanceReason[] | 'all';
  type: MaintenanceType[] | 'all';
  remainingAvailability: { min: number; max: number };
};

const defaultState: State = {
  plants: 'all',
  search: '',
  status: 'all',
  turbines: 'all',
  reason: 'all',
  type: 'all',
  remainingAvailability: { min: 0, max: 100 }
};

export const [useMaintenanceFiltersStore, MaintenanceFilterProvider] = createLocalStore('MaintenanceFiltersStore', () =>
  combine(
    defaultState,

    set => ({
      actions: {
        clearFilters: () => {
          set({
            ...defaultState
          });
        },
        setPlants: (plants: string[] | 'all') => {
          set({ plants });
        },
        setSearch: (search: string) => {
          set({ search });
        },
        setStatus: (status: SettingStatusType[] | 'all') => {
          set({ status });
        },
        setTurbines: (turbines: string[] | 'all') => {
          set({ turbines });
        },
        setReason: (reason: MaintenanceReason[] | 'all') => {
          set({ reason });
        },
        setType: (type: MaintenanceType[] | 'all') => {
          set({ type });
        },
        setRemainingAvailability: (remainingAvailability: { min: number; max: number }) => {
          set({ remainingAvailability });
        }
      }
    })
  )
);

export function useIsFilterMaintenanceActive() {
  const filters = useMaintenanceFiltersStore(state => state);
  const { actions: _actions, ...restFilters } = filters;
  return !_.isEqual(restFilters, defaultState);
}

export type MaintenancesFiltered = UseQueryResult<{
  maintenances: TurbineMaintenance[];
}>;

export function useFilterMaintenances(maintenances: UseQueryResult<TurbineMaintenance[]>): MaintenancesFiltered {
  const filters = useMaintenanceFiltersStore(state => state);
  const { actions: _actions, ...restFilters } = filters;

  const filteredMaintenances = useCombineQueries(maintenances, maintenances => {
    if (_.isEqual(restFilters, defaultState)) {
      return { maintenances, plants: filters.plants };
    }

    const filteredMaintenances = maintenances.filter(maintenance => {
      if (filters.plants != 'all') {
        if (!filters.plants.includes(maintenance.plant_id)) return false;
      }

      if (filters.search != '') {
        const searchRegex = new RegExp(filters.search, 'i');
        const isIncludedInSearch =
          searchRegex.test(maintenance.name ?? '') ||
          searchRegex.test(maintenance.turbine_name ?? '') ||
          searchRegex.test(maintenance.description ?? '') ||
          searchRegex.test(maintenance.created_by_email ?? '') ||
          searchRegex.test(maintenance.created_by_name ?? '');
        if (!isIncludedInSearch) return false;
      }

      if (filters.status != 'all') {
        if (!filters.status.includes(maintenance.status)) return false;
      }

      if (filters.reason != 'all') {
        if (!filters.reason.includes(maintenance.unavailability_reason)) return false;
      }

      if (filters.type != 'all') {
        if (!filters.type.includes(maintenance.unavailability_type)) return false;
      }

      const turbineRemainingPercent = (maintenance.remaining_power / maintenance.turbine_p_max) * 100;
      if (turbineRemainingPercent < filters.remainingAvailability.min || turbineRemainingPercent > filters.remainingAvailability.max) {
        return false;
      }

      if (filters.turbines != 'all') {
        if (!filters.turbines.includes(maintenance.turbine_id)) return false;
      }

      return true;
    });

    return {
      maintenances: filteredMaintenances
    };
  });

  return filteredMaintenances;
}
