import { useParams } from 'react-router-dom';
import { environments, type Environment } from '../../config/environments';

/**
 * Get the backend environment in the :environment parameter of the current route.
 *
 * If the page you are using this on can be called without a route, pass `{ optional: true }`.
 */
export function useRouteEnvironment(options?: { optional?: false }): Environment;
export function useRouteEnvironment(options: { optional: true }): Environment | undefined;
export function useRouteEnvironment({ optional = false }: { optional?: boolean } = {}): Environment | undefined {
  const environmentSlugInPath = useParams<{ environment: string }>()?.environment;

  /* c8 ignore next 5 */
  if (import.meta.env.MODE === 'test') {
    if (routeEnvironmentForTests != null) {
      return routeEnvironmentForTests;
    }
  }

  if (environmentSlugInPath == null && !optional) {
    throw new Error('useRouteEnvironment called in a route with no environment. Pass optional=true if this is on purpose.');
  }

  const environment = environments.find(env => env.slug === environmentSlugInPath);

  // If the user uses a wrong route (e.g. /nowhere/login), fall back to default environment
  if (!environment && !optional) {
    if (import.meta.env.DEV) {
      const invalidSlug = environmentSlugInPath === undefined ? 'undefined' : JSON.stringify(environmentSlugInPath);
      const validSlugs = `"${environments.map(env => env.slug).join('", "')}"`;
      throw new Error(`Invalid backend environment ${invalidSlug} in route, must be one of ${validSlugs}.`);
    }

    return environments[0];
  }

  return environment;
}

let routeEnvironmentForTests: Environment | undefined;

export function setRouteEnvironmentForTests(environment: Environment) {
  if (import.meta.env.MODE !== 'test') {
    throw new Error('Only call in tests.');
  }
  routeEnvironmentForTests = environment;
}
