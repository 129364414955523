import { createContext, useCallback, useContext, useEffect, useMemo, useState, type ReactNode } from 'react';
import { useRouteEnvironment } from '../../common/routing/useRouteEnvironment';
import { environments } from '../../config/environments';
import { LazyLoadedGoogleMapsLoadScript } from './LazyLoadedGoogleMapsLoadScript';

interface GoogleMapScriptState {
  isSuccess: boolean;
  isError: boolean;
  componentNeedsGoogleMap: () => void;
  retryLoading: () => void;
}

const GoogleMapsApiContext = createContext<GoogleMapScriptState | null>(null);

/** Context to only load google maps one time, the hard way. */
export function ProvideGoogleMapsApi({ children }: { children?: ReactNode }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loadScriptCounter, setLoadScriptCounter] = useState(0);

  const environment = useRouteEnvironment({ optional: true });
  const apiKey = (environment ?? environments[0]).mapsApiKey;

  const loadedSuccessfully = useCallback(() => {
    setIsSuccess(true);
  }, []);

  const loadingFailed = useCallback(() => setIsError(true), []);

  const componentNeedsGoogleMap = useCallback(() => setShouldLoad(true), []);

  const retryLoading = useCallback(() => {
    if (!isSuccess) {
      setLoadScriptCounter(counter => counter + 1);
    }
  }, [isSuccess]);

  const contextValue: GoogleMapScriptState = useMemo(
    () => ({
      isSuccess,
      isError,
      componentNeedsGoogleMap,
      retryLoading
    }),
    [componentNeedsGoogleMap, isSuccess, isError, retryLoading]
  );

  return (
    <>
      <GoogleMapsApiContext.Provider value={contextValue}>{children}</GoogleMapsApiContext.Provider>
      {shouldLoad && (
        <div hidden>
          <LazyLoadedGoogleMapsLoadScript
            key={loadScriptCounter}
            googleMapsApiKey={apiKey}
            onLoad={loadedSuccessfully}
            onError={loadingFailed}
          />
        </div>
      )}
    </>
  );
}

export function useGoogleMapsApi() {
  const context = useContext(GoogleMapsApiContext);
  if (!context) {
    throw new Error('Call useGoogleMapsApi inside ProvideGoogleMapsApi.');
  }

  const { componentNeedsGoogleMap } = context;

  useEffect(() => {
    componentNeedsGoogleMap();
  }, [componentNeedsGoogleMap]);

  return context as Omit<GoogleMapScriptState, 'componentNeedsGoogleMap'>;
}
