import { createContext, useContext, useMemo, useRef, type ReactNode, type Ref, type RefObject } from 'react';

interface Overlay {
  id: string;
  ref: RefObject<Element>;
}

interface OverlayManager {
  getOverlays: () => Overlay[];
  getOverlayById: (id: string) => Overlay | undefined;
}

const topLevelOverlay: Overlay = {
  id: 'root',
  ref: { current: document.body }
};

const OverlayContext = createContext<OverlayManager>({
  getOverlays() {
    return [topLevelOverlay];
  },

  getOverlayById() {
    return undefined;
  }
});

interface OverlayHostProps {
  children?: ReactNode;
  id: string;
  hostRef?: Ref<HTMLElement>;
}

/** Creates a container that children can render into via `ReactDOM.createPortal`. */
export function OverlayHost({ children, id, hostRef: externalHostRef }: OverlayHostProps) {
  const internalHostRef = useRef<HTMLDivElement>(null);
  const hostRef = externalHostRef ?? internalHostRef;

  const parentOverlays = useContext(OverlayContext);
  const overlay = useMemo(
    (): Overlay => ({
      id,
      ref: hostRef as RefObject<Element>
    }),
    [id, hostRef]
  );

  const overlayManager = useMemo((): OverlayManager => {
    return {
      getOverlays() {
        return parentOverlays.getOverlays().concat(overlay);
      },
      getOverlayById(id: string) {
        return id === overlay.id ? overlay : parentOverlays.getOverlayById(id);
      }
    };
  }, [overlay, parentOverlays]);

  return (
    <OverlayContext.Provider value={overlayManager}>
      {children}
      {!externalHostRef && <div ref={internalHostRef}></div>}
    </OverlayContext.Provider>
  );
}

export function useOverlayHosts() {
  return useContext(OverlayContext).getOverlays();
}

export function useOverlayHost(id: 'root'): Overlay;
export function useOverlayHost(id?: string | null | undefined): Overlay | undefined;
export function useOverlayHost(id?: string | null | undefined) {
  const context = useContext(OverlayContext);

  return useMemo(() => {
    if (!id || id === 'root') {
      return context.getOverlayById('root') ?? context.getOverlays()[0];
    }

    if (id === 'closest') {
      const overlays = context.getOverlays();
      return overlays[overlays.length - 1];
    }

    if (id !== null) {
      return context.getOverlayById(id);
    }
  }, [context, id]);
}
