import { type DataRange } from '@hydrogrid/design-system';
import { api } from '../InsightApi';
import { dataRangeToBackendParameters } from '../query-utils/dataRangeToBackendParameters';
import { defineQuery } from '../query-utils/defineQuery';

export const portfolioPower = defineQuery(
  ({ portfolioId, range, timeZone }: { portfolioId: string; range: DataRange; timeZone: string }) => ({
    queryKey: ['portfolio', portfolioId, 'power', range, timeZone],
    queryFn: ({ signal }) => {
      const { granularity: _remove, ...rawRange } = dataRangeToBackendParameters(range, timeZone);
      return api.dashboard.power.requestPortfolioPower({ portfolio_id: portfolioId, queryParams: rawRange, signal });
    }
  })
);
