import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgDownload = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", viewBox: "0 0 24 24", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { fill: "currentColor", clipPath: "url(#a)", children: [
    /* @__PURE__ */ jsx("path", { d: "M22.8 21H1.2a1.2 1.2 0 1 0 0 2.4h21.6a1.2 1.2 0 1 0 0-2.4Z" }),
    /* @__PURE__ */ jsx("path", { d: "M12 .6a1.2 1.2 0 0 0-1.2 1.2v12.7l-3.95-3.95a1.2 1.2 0 1 0-1.7 1.7l6 6a1.2 1.2 0 0 0 1.7 0l6-6a1.2 1.2 0 1 0-1.7-1.7L13.2 14.5V1.8A1.2 1.2 0 0 0 12 .6Z" })
  ] }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "a", children: /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "M0 0h24v24H0z" }) }) })
] });
const ForwardRef = forwardRef(SvgDownload);
export default ForwardRef;
