import type { CSSProperties } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { classNames } from '../../utils/classNames';
import styles from './ProgressBar.module.css';

type ProgressBarProps = IndeterminateProgressBarProps | DeterminateProgressBarProps | PromiseProgressBarProps;

interface ProgressBarBaseProps {
  className?: string | undefined;
  id?: string;

  /** @default "primary" */
  color?: 'primary' | 'contrast' | 'destructive';

  /** @default "block" */
  layout?: 'block' | 'absolute-top' | 'absolute-bottom';

  /** @default "0.25rem" // 8px */
  size?: string;
}

interface IndeterminateProgressBarProps extends ProgressBarBaseProps {
  isIndeterminate?: true;

  /** @default true */
  isActive?: boolean;

  progress?: never;
  promise?: never;
  resetDelay?: never;
}

interface DeterminateProgressBarProps extends ProgressBarBaseProps {
  isIndeterminate?: false;

  /** A progress between `0` and `100`, or `null` to hide the progressbar. */
  progress: number | null;

  isActive?: never;
  promise?: never;
  resetDelay?: never;
}

interface PromiseProgressBarProps extends ProgressBarBaseProps {
  /** A promise that starts/ends the progress bar animation */
  promise: Promise<unknown> | null;

  /** @default 1000 */
  resetDelay?: number;

  isIndeterminate?: true;
  isActive?: never;
  progress?: never;
}

export const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>((props, ref) => {
  const {
    className,
    color = 'primary',
    layout = 'block',
    progress,
    promise,
    resetDelay = 1000,
    isActive: isActiveProp,
    isIndeterminate = (progress !== null && typeof progress !== 'number') || promise !== undefined,
    size = '0.25rem',
    ...restProps
  } = props;

  const lastProgressPercent = useRef(0);
  const progressPercent = isIndeterminate ? undefined : typeof progress === 'number' ? progress : lastProgressPercent.current;

  if (typeof progress === 'number') {
    lastProgressPercent.current = progress;
  }

  const [promiseStatus, setPromiseStatus] = useState<'idle' | 'pending' | 'resolved' | 'rejected'>(promise ? 'pending' : 'idle');

  useEffect(() => {
    if (!promise) {
      return setPromiseStatus('idle');
    }

    let wasDestroyed = false;
    let hasResolved = false;
    let timeout = Number.NaN;

    promise.then(
      () => {
        if (wasDestroyed) return;
        hasResolved = true;
        setPromiseStatus('resolved');
        timeout = window.setTimeout(() => setPromiseStatus('idle'), resetDelay);
      },
      () => {
        if (wasDestroyed) return;
        hasResolved = true;
        setPromiseStatus('rejected');
        timeout = window.setTimeout(() => setPromiseStatus('idle'), resetDelay);
      }
    );

    void Promise.resolve().then(() => {
      if (!wasDestroyed && !hasResolved) {
        setPromiseStatus('pending');
      }
    });

    return () => {
      clearTimeout(timeout);
      wasDestroyed = true;
    };
  }, [promise, resetDelay]);

  const isActive =
    isActiveProp ??
    (!isIndeterminate
      ? progress != null
      : promise !== undefined
        ? promise !== null && promiseStatus === 'pending'
        : progress === undefined);

  const wasResolved = promiseStatus === 'resolved' && promise != null;
  const wasRejected = promiseStatus === 'rejected' && promise != null;

  const wasActive = useRef(isActive);
  const animationRestartId = useRef(0);
  const animationRestartKey = (animationRestartId.current += isActive && !wasActive.current ? 1 : 0);
  wasActive.current = isActive;

  // TODO: Clean up color names after applying new branding colors
  const cssVars = {
    '--ProgressBar-size': size,
    '--ProgressBar-color': `var(--color-${color === 'contrast' ? 'secondary' : color})`,
    '--ProgressBar-percent': progressPercent
  };

  return (
    <div
      ref={ref}
      {...restProps}
      role="progressbar"
      aria-hidden={isActive ? undefined : true}
      aria-valuenow={progressPercent}
      className={classNames(
        styles.progressBar,
        styles[layout],
        styles[`colorSchema-${color}`],
        isIndeterminate ? styles.indeterminate : styles.determinate,
        isActive ? styles.active : wasRejected ? styles.rejected : wasResolved ? styles.resolved : styles.inactive,
        className
      )}
      style={cssVars as CSSProperties}
    >
      <div className={styles.track}>
        <div key={animationRestartKey} className={styles.value} />
      </div>
    </div>
  );
});
