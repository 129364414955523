import type { ReactNode } from 'react';
import { ActionsTasksFilterProvider } from './ActionsTasksFilterStore';

interface ActionsProvidersProps {
  children?: ReactNode;
}

export function ActionsProvodiers({ children }: ActionsProvidersProps) {
  return <ActionsTasksFilterProvider>{children}</ActionsTasksFilterProvider>;
}
