import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgTimeZone = (props, ref) => /* @__PURE__ */ jsxs("svg", { id: "fi_3503230", height: 512, viewBox: "0 0 256 256", width: 512, xmlns: "http://www.w3.org/2000/svg", "data-name": "Layer 1", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "m115.96 243.959a115.952 115.952 0 1 1 114.873-131.892 5 5 0 1 1 -9.906 1.362c-7.163-52.1-52.289-91.388-104.967-91.388a105.959 105.959 0 1 0 32.2 206.94 5 5 0 1 1 3.035 9.528 115.9 115.9 0 0 1 -35.235 5.45z" }),
  /* @__PURE__ */ jsx("path", { d: "m115.96 73.2c-30.936 0-58.934-10.014-74.895-26.787a5 5 0 1 1 7.245-6.893c13.9 14.606 39.821 23.68 67.65 23.68s53.747-9.073 67.647-23.678a5 5 0 1 1 7.245 6.893c-15.962 16.77-43.959 26.785-74.892 26.785z" }),
  /* @__PURE__ */ jsx("path", { d: "m44.687 218.036a5 5 0 0 1 -3.622-8.447c15.961-16.773 43.959-26.787 74.9-26.787a138.883 138.883 0 0 1 14.933.8 5 5 0 0 1 -1.08 9.942 128.633 128.633 0 0 0 -13.853-.744c-27.829 0-53.751 9.074-67.65 23.68a4.987 4.987 0 0 1 -3.628 1.556z" }),
  /* @__PURE__ */ jsx("path", { d: "m115.96 243.959c-19.443 0-37.523-12.41-50.911-34.945-12.903-21.714-20.009-50.49-20.009-81.014s7.106-59.295 20.009-81.014c13.388-22.535 31.468-34.945 50.911-34.945 17.645 0 34.5 10.48 47.454 29.51 12.356 18.148 20.44 42.994 22.762 69.962a5 5 0 0 1 -9.963.859c-4.435-51.5-30.338-90.331-60.253-90.331-15.773 0-30.8 10.672-42.314 30.053-11.999 20.196-18.606 47.153-18.606 75.906s6.607 55.71 18.606 75.906c11.513 19.381 26.541 30.053 42.314 30.053a5 5 0 0 1 0 10z" }),
  /* @__PURE__ */ jsx("path", { d: "m148.287 133h-143.287a5 5 0 0 1 0-10h143.287a5 5 0 1 1 0 10z" }),
  /* @__PURE__ */ jsx("path", { d: "m115.96 243.861a5 5 0 0 1 -5-5v-221.82a5 5 0 0 1 10 0v221.82a5 5 0 0 1 -5 5z" }),
  /* @__PURE__ */ jsx("path", { d: "m197.043 237.476a58.957 58.957 0 1 1 58.957-58.957 59.023 59.023 0 0 1 -58.957 58.957zm0-107.913a48.957 48.957 0 1 0 48.957 48.956 49.011 49.011 0 0 0 -48.957-48.956z" }),
  /* @__PURE__ */ jsx("path", { d: "m210.348 190.048a4.972 4.972 0 0 1 -2.5-.671l-12.718-7.342a5 5 0 0 1 -2.5-4.33v-30.351a5 5 0 0 1 10 0v27.464l10.218 5.9a5 5 0 0 1 -2.5 9.331z" })
] });
const ForwardRef = forwardRef(SvgTimeZone);
export default ForwardRef;
