import 'temporal-polyfill/global';

import { StrictMode } from 'react';
import { createRoot as createReactRoot } from 'react-dom/client';
import { AppRoot } from './containers/AppRoot/AppRoot';

import { monkeyPatchTemporalPolyfillIssue21 } from '@hydrogrid/design-system';
import './global-styles.css';

monkeyPatchTemporalPolyfillIssue21();

// we reload the page when vite can't find assets (after a new frontend deployment) https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

const root = createReactRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <StrictMode>
    <AppRoot />
  </StrictMode>
);

// Dummy
