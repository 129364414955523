import { Flex } from '@chakra-ui/react';
import {
  Button,
  ClipboardIcon,
  DownloadIcon,
  isValidDataRange,
  stringifyDataRange,
  toast,
  useClipboard,
  type DataRange
} from '@hydrogrid/design-system';
import { useUpdateEffect } from '@hydrogrid/utilities/react';
import { stringToBase64 } from '@hydrogrid/utilities/string';
import { useQueryClient, type MutationStatus, type QueryKey, type QueryStatus } from '@tanstack/react-query';
import { Component, lazy, useCallback, useEffect, useMemo, useState, type ComponentType, type ErrorInfo, type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useApiStatus } from '../../common/api/hooks/CommonDataHooks';
import { tryParseAccessToken, type AccessToken } from '../../common/api/parseAccessToken';
import { useAuthSessions } from '../../common/auth/useAuthSessions';
import { downloadFile } from '../../common/file/downloadFile';
import { useRouteEnvironment } from '../../common/routing/useRouteEnvironment';
import { useAuthStore } from '../../common/stores/AuthStore';
import { frontendVersion } from '../../components/AppVersions/viteEnvironment';
import { ErrorFaceAnimation } from '../../components/ErrorFaceAnimation/ErrorFaceAnimation';
import { environments } from '../../config/environments';
import { shouldUseProductionErrorBoundaryInDevelopment } from '../../development/consoleHelpers';
import styles from './AppErrorBoundary.module.css';

export interface AppManifest {
  [sourceFilename: string]: {
    file: string;
    src?: string;
    css?: string[];
    isDynamicEntry?: true;
    imports?: string[];
    dynamicImports?: string[];
  };
}

interface ErrorState {
  error: Error | null;
  info: ErrorInfo | null;
}

interface ErrorComponentProps extends ErrorState {
  retry: () => void;
}

const spamSafeEmail = `operations(AT)hydrogrid.ai`.replace('(AT)', '@');

function createErrorBoundary(displayName: string, ErrorComponent: ComponentType<ErrorComponentProps>) {
  //
  // During development, show the call stack when an error is thrown.
  // To enable/disable, run in the browser console:
  //   insight.errorBoundary = true/false
  //
  if (import.meta.env.DEV && import.meta.env.MODE !== 'test') {
    if (!shouldUseProductionErrorBoundaryInDevelopment()) {
      const DevelopmentErrorBoundary = lazy(() =>
        import('@hydrogrid/design-system').then(mod => ({ default: mod.DevelopmentErrorBoundary }))
      );
      return DevelopmentErrorBoundary;
    }
  }

  class ErrorBoundary extends Component<{ children?: ReactNode }, ErrorState> {
    constructor(props: { children: ReactNode }) {
      super(props);

      this.state = {
        error: null,
        info: null
      };
    }

    static getDerivedStateFromError(error: Error) {
      return { error };
    }

    override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
      const { error: prevError, info: prevInfo } = this.state;

      const isSameError = prevError?.message === error.message && prevError.stack === error.stack;
      const isSameInfo = prevInfo?.componentStack === errorInfo?.componentStack;

      if (!isSameError || !isSameInfo) {
        this.setState({
          error,
          info: errorInfo
        });
      }
    }

    retry = () => {
      this.setState({
        error: null,
        info: null
      });
    };

    override render() {
      const { error, info } = this.state;
      if (!error) {
        return <>{this.props.children}</>;
      }

      return <ErrorComponent error={error} info={info} retry={this.retry} />;
    }
  }

  return ErrorBoundary;
}

/**
 * App-wide error boundary for very critical unrecoverable errors.
 *
 * This should only be shown in very exceptional circumstances,
 * if the {@link DiagnosticErrorBoundary} throws an error itself,
 * or a high-level Provider throws before the app can render.
 */
const CriticalErrorBoundary = createErrorBoundary('CriticalErrorBoundary', function CriticalErrorBoundary({ error, info }) {
  const [hoveringLink, setHoveringLink] = useState(false);

  const authSessions = useAuthSessions();
  const token = authSessions.accessToken;
  const isHydrogridUser = token != null && /@hydrogrid\.(ai|eu)$/.test(token.userEmail);
  const isProduction = import.meta.env.PROD && environments.length === 1 && environments[0].name.toLowerCase() === 'production';
  const allowCopyingError = isHydrogridUser || !isProduction;

  const reloadPageWithoutCache = () => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const reload = window.location.reload.bind(window.location) as (bypassCache?: boolean) => void;
    reload(true);
  };

  const clipboard = useClipboard({
    onSuccess: () => {
      toast({ status: 'success', description: 'Error copied to clipboard.' });
    }
  });

  const copyErrorJson = () => {
    const { origin, pathname } = window.location;
    const smallerErrorObject = {
      error,
      info: info && {
        ...info,
        componentStack: info.componentStack?.replaceAll(origin, '').replace(/^\s+/, '').split(/\n +/)
      },
      origin,
      pathname
    };

    let textToCopy = JSON.stringify(smallerErrorObject);
    try {
      textToCopy = btoa(textToCopy);
    } catch {}

    clipboard.copy(textToCopy);
  };

  return (
    <div className={styles.critical}>
      <ErrorFaceAnimation mood={hoveringLink ? 'happy' : 'sad'} className={styles.logo} />
      <h1>Unrecoverable Error</h1>
      <p>Hydrogrid Insight encountered a critical error it can not recover from.</p>
      <p>
        {'If this keeps happening, please let us know at '}
        <a href={`mailto:${spamSafeEmail}`} className={styles.email}>
          {spamSafeEmail}
        </a>
        .
      </p>

      <Flex align="center" justify="center" gap={4}>
        <Button
          colorScheme="primary"
          onClick={reloadPageWithoutCache}
          onMouseEnter={() => setHoveringLink(true)}
          onMouseLeave={() => setHoveringLink(false)}
        >
          Reload page
        </Button>
        {allowCopyingError && (
          <Button variant="outline" colorScheme="secondary" onClick={copyErrorJson}>
            Copy error description
          </Button>
        )}
      </Flex>
    </div>
  );
});

export interface AppErrorDiagnostics {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  location: string;
  routeState: unknown;
  authState: Record<string, unknown>;
  tokens: { access: AccessToken | null; refresh: AccessToken | null };
  frontendVersion: string;
  backendVersion: string | undefined;
  isOnline: boolean;
  vpnStatus: null | string | { failed: Error | true };
  documentFiles: {
    stylesheets: string[];
    scripts: string[];
    failed?: Error | true;
  };
  manifest: null | { files: string[] } | { failed: Error | true };
  queries: (readonly [string, QueryStatus])[];
  mutations: (readonly [string, MutationStatus])[];
}

const DiagnosticErrorBoundary = createErrorBoundary('DiagnosticErrorBoundary', function DiagnosticErrorBoundary({ error, info, retry }) {
  const environment = useRouteEnvironment({ optional: true });
  const health = useApiStatus(environment);
  const queryClient = useQueryClient();
  const location = useLocation();
  const authState = useAuthStore(state => state);
  const authSessions = useAuthSessions();

  useUpdateEffect(() => {
    retry();
  }, [location, retry]);

  const allQueries = queryClient
    .getQueryCache()
    .getAll()
    .map(query => {
      const queryKey = keyToString(query.queryKey);
      const status = query.state.status;
      return [queryKey, status] as const;
    });

  const allMutations = queryClient
    .getMutationCache()
    .getAll()
    .map(mutation => {
      const mutationKey = mutation.options.mutationKey ? keyToString(mutation.options.mutationKey) : `mutation-${mutation.mutationId}`;
      const status = mutation.state.status;
      return [mutationKey, status] as const;
    });

  const backendVersion = health.data?.version;

  const tryGetDocumentFiles = (): AppErrorDiagnostics['documentFiles'] => {
    try {
      const notNull = (str: string | null): str is string => str != null;
      return {
        stylesheets: [...document.querySelectorAll('link[rel="stylesheet"]')].map(link => link.getAttribute('href')).filter(notNull),
        scripts: [...document.querySelectorAll('link[as="script"],link[rel="modulepreload"]')]
          .map(link => link.getAttribute('href'))
          .filter(notNull)
      };
    } catch (error) {
      return { stylesheets: [], scripts: [], failed: error instanceof Error ? error : true };
    }
  };

  const [manifest, setManifest] = useState<AppErrorDiagnostics['manifest']>(null);
  const [vpnStatus, setVpnStatus] = useState<AppErrorDiagnostics['vpnStatus']>(null);

  const diagnostics = useMemo(
    (): AppErrorDiagnostics => ({
      error,
      errorInfo: info,
      location: window.location.href,
      routeState: location.state,
      authState,
      tokens: {
        access: authSessions.accessToken,
        refresh: authSessions.refreshJWT == null ? null : tryParseAccessToken(authSessions.refreshJWT)
      },
      frontendVersion,
      backendVersion,
      isOnline: window.navigator.onLine,
      vpnStatus,
      documentFiles: tryGetDocumentFiles(),
      manifest,
      queries: allQueries,
      mutations: allMutations
    }),
    [
      allMutations,
      allQueries,
      authSessions.accessToken,
      authSessions.refreshJWT,
      authState,
      backendVersion,
      error,
      info,
      location.state,
      manifest,
      vpnStatus
    ]
  );

  useEffect(() => {
    const fetchManifest = async () => {
      const response = await fetch('/manifest.json', { cache: 'no-cache' });

      if (response.ok) {
        const manifest = (await response.json()) as AppManifest;
        const files = Object.values(manifest)
          .map(entry => entry.file?.replace(/^assets\//, ''))
          .filter(file => file && !/\.(svg)$/.test(file));
        return { files };
      } else {
        const reason = (await response.json().catch(() => true)) as Error | true;
        return { failed: reason };
      }
    };

    fetchManifest()
      .then(manifest => setManifest(manifest))
      .catch((reason: Error) => setManifest({ failed: reason }));

    getVpnStatus()
      .then(vpnStatus => setVpnStatus(vpnStatus))
      .catch((reason: Error) => setVpnStatus({ failed: reason }));
  }, [backendVersion]);

  const diagnosticBase64 = useMemo(() => {
    return stringToBase64(JSON.stringify(diagnostics, serializeErrorsToJson));
  }, [diagnostics]);

  const downloadBugReport = useCallback(() => {
    const fileName = `insight-${new Date().toISOString().replace(/:/g, '-')}.bugreport`;
    const file = new File([diagnosticBase64], fileName, { type: 'text/plain' });
    downloadFile(file);
  }, [diagnosticBase64]);

  return (
    <div className={styles.diagnostic}>
      <h1 className={styles.longHeader}>An unexpected error has occured</h1>
      <h2 className={styles.shortHeader} aria-hidden>
        Unexpected Error
      </h2>
      <p>
        <Button color="primary" variant="link" onClick={() => retry()} className={styles.refreshButton}>
          Click here
        </Button>
        {' to try again.'}
      </p>
      <p>
        {'If this keeps happening, please let us know at '}
        <br />
        <Button
          colorScheme="primary"
          variant="link"
          onClick={() => (window.location.href = `mailto:${spamSafeEmail}?subject=Hydrogrid Bug Report&body=`)}
          className={styles.emailButton}
        >
          <span>{spamSafeEmail}</span>
        </Button>
        {' with this diagnostic data:'}
      </p>
      <div className={styles.buttons}>
        <Button
          colorScheme="secondary"
          variant="outline"
          size="sm"
          leftIcon={<DownloadIcon />}
          className={styles.downloadButton}
          onClick={downloadBugReport}
        >
          Download
        </Button>
        {import.meta.env.DEV && <CopyToClipboard text={diagnosticBase64} />}
      </div>
    </div>
  );
});

// Disable error boundaries in tests and in development, if set via localStorage

/** @internal */
const internal_CriticalErrorBoundary = CriticalErrorBoundary;

/** @internal */
const internal_DiagnosticErrorBoundary = DiagnosticErrorBoundary;

let exportedCriticalErrorBoundary = CriticalErrorBoundary as ComponentType<{ children?: ReactNode }>;
let exportedDiagnosticErrorBoundary = DiagnosticErrorBoundary as ComponentType<{ children?: ReactNode }>;

if (import.meta.env.MODE === 'test' || (import.meta.env.DEV && localStorage.getItem('devSkipErrorBoundary') === 'true')) {
  exportedCriticalErrorBoundary = ({ children }) => children;
  exportedDiagnosticErrorBoundary = ({ children }) => children;
}

export {
  exportedCriticalErrorBoundary as CriticalErrorBoundary,
  exportedDiagnosticErrorBoundary as DiagnosticErrorBoundary,
  internal_CriticalErrorBoundary,
  internal_DiagnosticErrorBoundary
};

function CopyToClipboard({ text }: { text: string }) {
  const clipboard = useClipboard({
    onSuccess: () => {
      toast({ status: 'success', description: 'Error copied to clibpoard.' });
    }
  });

  return (
    <Button
      colorScheme="secondary"
      variant="outline"
      size="sm"
      leftIcon={<ClipboardIcon state={clipboard.state} />}
      onClick={() => clipboard.copy(text)}
    >
      Copy
    </Button>
  );
}

async function getVpnStatus() {
  if (!import.meta.env.DEV && import.meta.env.VITE_DEPLOY_TARGET !== 'insight') {
    return 'not-needed';
  }
  const { headers } = await fetch('/', { cache: 'no-cache' });
  if (headers.get('content-type')?.includes('text/html') === true && headers.get('link')?.includes('/wp-json/') === true) {
    return 'not-connected';
  }
  return 'connected';
}

function keyToString(key: QueryKey): string {
  return key
    .map(part => {
      // e.g. Format date ranges as "2023-01--2023-12" instead of the long object
      if (isPartDataRange(part) && isValidDataRange(part)) {
        return stringifyDataRange(part);
      }
      return String(part);
    })
    .join('.');
}

function isPartDataRange(obj: unknown): obj is DataRange {
  return typeof obj === 'object' && obj !== null && 'granularity' in obj && 'start' in obj && 'end' in obj;
}

function serializeErrorsToJson(_key: string, value: unknown) {
  if (value instanceof Error) {
    const { name, message, stack } = value;
    return { name, message, stack };
  }

  return value;
}
