/**
 * https://github.com/fullcalendar/temporal/issues/21
 *
 * How the bug surfaces:
 * ```ts
 * const october = Temporal.PlainYearMonth.from('2023-11').subtract({ months: 1 });
 *
 * october.equals('2023-10')  // => false, should be true
 * Temporal.PlainYearMonth.compare(october, '2023-10')  // => 1, should be 0
 * ```
 *
 * What causes the bug:
 * ```ts
 * october.getISOFields().isoDay  // => 30, should be 1
 * ```
 */
export function monkeyPatchTemporalPolyfillIssue21() {
  const ym = new Temporal.PlainYearMonth(2000, 1);
  if (ym.equals(ym.subtract({ days: 1 }))) return;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const originalGetISOFields = Temporal.PlainYearMonth.prototype.getISOFields;

  Temporal.PlainYearMonth.prototype.getISOFields = function patchedGetISOFields(this) {
    return { ...originalGetISOFields.call(this), isoDay: 1 };
  };
}
