import { defineIconComponent } from '../defineIconComponent';
import CalculateSvg from './calculate.svg?react';
import CloseCrossSvg from './close-cross.svg?react';
import DownloadSvg from './download.svg?react';
import ExclamationMarkSvg from './exclamation-mark.svg?react';
import CsvFileSvg from './file-csv.svg?react';
import ExcelFileSvg from './file-excel.svg?react';
import JsonFileSvg from './file-json.svg?react';
import PdfFileSvg from './file-pdf.svg?react';
import HotkeyAltSvg from './hotkey-alternative.svg?react';
import HotkeySvg from './hotkey.svg?react';
import InnovationSvg from './innovation.svg?react';
import QuestionMarkSvg from './question-mark.svg?react';
import ReservesSvg from './reserves.svg?react';
import SaveErrorSvg from './save-error.svg?react';
import SaveSvg from './save.svg?react';
import TimeZoneSvg from './time-zone.svg?react';
import WarningCircleSvg from './warning-circle.svg?react';
import WarningHexagonSvg from './warning-hexagon.svg?react';
import WarningTriangleSvg from './warning-triangle.svg?react';

export const CloseCrossIcon = defineIconComponent('CloseCross', CloseCrossSvg);
export const DownloadIcon = defineIconComponent('Download', DownloadSvg);
export const ExcelFileIcon = defineIconComponent('ExcelFile', ExcelFileSvg);
export const ExclamationMarkIcon = defineIconComponent('ExclamationMark', ExclamationMarkSvg);
export const HotkeyIcon = defineIconComponent('Hotkey', HotkeySvg);
export const HotkeyAltIcon = defineIconComponent('HotkeyAlt', HotkeyAltSvg);
export const InnovationIcon = defineIconComponent('Innovation', InnovationSvg);
export const JsonFileIcon = defineIconComponent('JsonFile', JsonFileSvg);
export const PdfFileIcon = defineIconComponent('PdfFile', PdfFileSvg);
export const ReservesIcon = defineIconComponent('Reserves', ReservesSvg);
export const CalculateIcon = defineIconComponent('Calculate', CalculateSvg);
export const CsvFileIcon = defineIconComponent('CsvFile', CsvFileSvg);
export const QuestionMarkIcon = defineIconComponent('QuestionMark', QuestionMarkSvg);
export const SaveErrorIcon = defineIconComponent('SaveError', SaveErrorSvg);
export const SaveIcon = defineIconComponent('Save', SaveSvg);
export const TimeZoneIcon = defineIconComponent('TimeZone', TimeZoneSvg);
export const WarningCircleIcon = defineIconComponent('WarningCircle', WarningCircleSvg);
export const WarningHexagonIcon = defineIconComponent('WarningHexagon', WarningHexagonSvg);
export const WarningTriangleIcon = defineIconComponent('WarningTriangle', WarningTriangleSvg);
