import { useUpdateEffect } from '@hydrogrid/utilities/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
  type AriaAttributes,
  type ReactNode,
  type Ref
} from 'react';
import { keyCombination } from '../../accessibility/keyCombination';
import { useClickOutside } from '../../accessibility/useClickOutside';
import { createDescendantsContext } from '../../dom/DescendantsContext';
import { isTabbable } from '../../dom/Tabbable';
import { isHTMLElement } from '../../dom/isElement';
import { useAnimatablePresence } from '../../dom/useAnimatablePresence';
import { useCombinedEventHandler } from '../../dom/useCombinedEventHandler';
import { usePopper, type UsePopperProps } from '../../external-libraries/popperjs/usePopper';
import { useCombinedRefs } from '../../utils/useCombinedRefs';
import { getNextItemFromSearch } from './getNextItemFromSearch';
import { useTypeahead } from './useTypeahead';

/** @file Hooks for `Menu` / `MenuButton` / `MenuList` / `MenuItem`. */

interface MenuItemInfo {
  hasIcon: boolean;
  shortcut: string | undefined;
}

const {
  Provider: MenuItemDescendantsProvider,
  useDescendant: useMenuDescendant,
  useDescendantsContext: useMenuDescendantsContext,
  useDescendants: useMenuItemsDescendants
} = createDescendantsContext<HTMLElement, MenuItemInfo>('MenuItemDescendants');

export { MenuItemDescendantsProvider };

type MenuVariant = 'default';

export interface UseMenuProps extends Omit<UsePopperProps, 'enabled'> {
  /** Provide to control the open/closed state from outside. */
  isOpen?: boolean;

  /** Provide to control the focused menu item from outside. */
  focusedIndex?: number;

  id?: string;

  /** @default true */
  closeOnSelect?: boolean;

  /** @default true */
  closeOnClickOutside?: boolean;

  /** @default true */
  autoFocusFirstItem?: boolean;

  /** @default 'default' */
  variant?: MenuVariant;

  onOpen?: () => void;
  onClose?: () => void;
  onToggle?: (isOpen: boolean) => void;
  onClickOutside?: (event: MouseEvent) => void;
  onFocusChange?: (focusedIndex: number) => void;
}

/** Hook that sets up all necessary context values for a `Menu` implementation. */
export function useMenu(props: UseMenuProps) {
  const {
    isOpen: isOpenControlled,
    focusedIndex: focusedIndexControlled,
    id: idFromProps,
    closeOnSelect = true,
    closeOnClickOutside = true,
    autoFocusFirstItem = true,
    placement = 'bottom-start',
    variant = 'default',
    onOpen: onOpenFromProps,
    onClose: onCloseFromProps,
    onToggle: onToggleFromProps,
    onClickOutside,
    onFocusChange,
    ...popperProps
  } = props;

  const generatedId = useId();
  const id = idFromProps ?? `Menu-${generatedId}`;
  const buttonId = `${id}-button`;
  const menuId = `${id}-menu`;

  const menuRef = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLElement>(null);

  const descendants = useMenuItemsDescendants();

  const [focusedIndexUncontrolled, setFocusedIndexUncontrolled] = useState(focusedIndexControlled ?? -1);
  const [isOpenUncontrolled, setIsOpenUncontrolled] = useState(isOpenControlled ?? false);

  const isControlled = isOpenControlled !== undefined;
  const focusedIndex = focusedIndexControlled ?? focusedIndexUncontrolled;
  const isOpen = isOpenControlled ?? isOpenUncontrolled;

  const setFocusedIndex = useCallback(
    (index: number) => {
      onFocusChange?.(index);
      setFocusedIndexUncontrolled(index);
    },
    [onFocusChange]
  );

  const popper = usePopper({
    gutter: 0,
    ...popperProps,
    enabled: isOpen,
    placement
  });

  const [hasIcons, setHasIcons] = useState(() => descendants.values().some(item => item.hasIcon));

  useLayoutEffect(() => {
    if (isOpen) {
      setHasIcons(descendants.values().some(item => item.hasIcon));
    }
  }, [descendants, isOpen]);

  const cleanups = useRef(new Set<() => void>());

  useEffect(() => {
    const handles = cleanups.current;
    return () => {
      handles.forEach(cleanup => cleanup());
      handles.clear();
    };
  }, []);

  const focusMenu = useCallback(() => {
    const handle = requestAnimationFrame(() => {
      menuRef.current?.focus({ preventScroll: false });
    });
    cleanups.current.add(() => cancelAnimationFrame(handle));
  }, []);

  const focusFirstItem = useCallback(() => {
    const handle = setTimeout(() => {
      const first = descendants.firstEnabled();
      if (first) {
        setFocusedIndex(first.index);
      }
    });

    cleanups.current.add(() => clearTimeout(handle));
  }, [descendants, setFocusedIndex]);

  const focusLastItem = useCallback(() => {
    const handle = setTimeout(() => {
      const last = descendants.lastEnabled();
      if (last) {
        setFocusedIndex(last.index);
      }
    });

    cleanups.current.add(() => clearTimeout(handle));
  }, [descendants, setFocusedIndex]);

  const open = useCallback(() => {
    if (!isControlled) {
      setIsOpenUncontrolled(true);
    }
    onToggleFromProps?.(true);
    onOpenFromProps?.();

    if (autoFocusFirstItem) {
      focusFirstItem();
    } else {
      focusMenu();
    }
  }, [autoFocusFirstItem, focusFirstItem, focusMenu, isControlled, onOpenFromProps, onToggleFromProps]);

  const close = useCallback(() => {
    if (!isControlled) {
      setIsOpenUncontrolled(false);
    }
    onToggleFromProps?.(false);
    onCloseFromProps?.();
  }, [isControlled, onCloseFromProps, onToggleFromProps]);

  const toggle = useCallback(() => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  }, [isOpen, open, close]);

  const openAndFocusFirstItem = useCallback(() => {
    open();
    focusFirstItem();
  }, [open, focusFirstItem]);

  const openAndFocusLastItem = useCallback(() => {
    open();
    focusLastItem();
  }, [open, focusLastItem]);

  const wasJustOpened = useRef(isOpen);
  useEffect(() => {
    if (!isOpen) return;

    wasJustOpened.current = true;
    const handle = requestAnimationFrame(() => {
      wasJustOpened.current = false;
    });

    return () => cancelAnimationFrame(handle);
  }, [isOpen]);

  useClickOutside({
    enabled: isOpen && (closeOnClickOutside || onClickOutside != null),
    ref: menuRef,
    handler: event => {
      onClickOutside?.(event);
      if (event.defaultPrevented || !closeOnClickOutside) return;

      const node = event.target as Node;
      if (!wasJustOpened.current && buttonRef.current?.contains(node) !== true) {
        close();
      }
    }
  });

  // Focus the menu button after closing the menu
  useUpdateEffect(() => {
    if (isOpen) return;

    const menuElement = menuRef.current;
    const activeElement = document.activeElement;
    const shouldPreventStealingFocus =
      menuElement != null && activeElement != null && !menuElement.contains(activeElement) && !isTabbable(activeElement as HTMLElement);

    if (shouldPreventStealingFocus) {
      return;
    }

    const element = buttonRef.current ?? menuElement;
    if (!element) return;

    const handle = requestAnimationFrame(() => {
      element.focus({ preventScroll: true });
    });

    return () => cancelAnimationFrame(handle);
  }, [isOpen]);

  const menuAnimation = useAnimatablePresence({ isOpen });

  const cancelPendingFocus = useRef<() => void>();

  useUpdateEffect(() => {
    if (!isOpen) {
      cancelPendingFocus.current?.();
      const frame = requestAnimationFrame(() => {
        setFocusedIndex(-1);
        buttonRef.current?.focus({ preventScroll: true });
      });
      cancelPendingFocus.current = () => cancelAnimationFrame(frame);
    }
  }, [isOpen, setFocusedIndex]);

  const refocus = useCallback(() => {
    const hasFocusWithin = menuRef.current?.contains(document.activeElement) ?? false;
    const shouldRefocus = isOpen && !hasFocusWithin;

    if (!shouldRefocus) return;

    const node = descendants.item(focusedIndex)?.node;
    if (node) {
      node.focus();
    }
  }, [isOpen, focusedIndex, descendants]);

  return {
    descendants,
    id,
    buttonId,
    menuId,
    hasIcons,
    focusedIndex,
    isOpen,
    isControlled,
    closeOnClickOutside,
    closeOnSelect,
    autoFocusFirstItem,
    variant,
    menuRef,
    buttonRef,
    menuAnimation,
    popper,
    open,
    close,
    toggle,
    setFocusedIndex,
    openAndFocusFirstItem,
    openAndFocusLastItem,
    forceUpdate: popper.forceUpdate,
    onTransitionEnd: refocus,
    cancelPendingFocus
  };
}

type MenuContextValue = Omit<ReturnType<typeof useMenu>, 'descendants'>;
const MenuContext = createContext<MenuContextValue | null>(null);

export const MenuProvider = MenuContext.Provider;

export function useMenuContext() {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('You have to call useMenu inside a Menu render tree.');
  }
  return context;
}

interface UseMenuButtonProps<E extends HTMLElement> {
  onClick?: ((event: React.MouseEvent<E>) => void) | undefined;
  onKeyDown?: ((event: React.KeyboardEvent<E>) => void) | undefined;
}

/** Hook that sets up all necessary context values & props for a `MenuButton` implementation. */
export function useMenuButton<E extends HTMLElement, P extends UseMenuButtonProps<E>>(props: P, externalRef: Ref<E> = null) {
  const menu = useMenuContext();

  const { toggle, openAndFocusFirstItem, openAndFocusLastItem, popper } = menu;

  const handleMenuButtonKeyboardInput = useCallback(
    (event: React.KeyboardEvent<E>) => {
      const key = keyCombination(event);
      const keyMap: Record<string, React.KeyboardEventHandler<E>> = {
        Enter: openAndFocusFirstItem,
        ArrowDown: openAndFocusFirstItem,
        ArrowUp: openAndFocusLastItem
      };

      const action = keyMap[key];

      if (action != null) {
        event.preventDefault();
        event.stopPropagation();
        action(event);
      }
    },
    [openAndFocusFirstItem, openAndFocusLastItem]
  );

  return {
    ...props,
    ref: useCombinedRefs(menu.buttonRef, externalRef, popper.referenceRef),
    id: menu.buttonId,
    'aria-expanded': menu.isOpen,
    'aria-haspopup': 'menu' as const,
    'aria-controls': menu.menuId,
    onClick: useCombinedEventHandler(props.onClick, toggle),
    onKeyDown: useCombinedEventHandler(props.onKeyDown, handleMenuButtonKeyboardInput)
  };
}

interface UseMenuListProps<E> {
  children?: ReactNode;
  style?: React.CSSProperties;
  onKeyDown?: (event: React.KeyboardEvent<E>) => void;
  onAnimationEnd?: (event: React.AnimationEvent<E>) => void;
}

export function useMenuList<E extends HTMLElement, P extends UseMenuListProps<E>>(props: P, ref: Ref<E | null> = null) {
  const { focusedIndex, setFocusedIndex, menuRef, close, menuId, menuAnimation } = useMenuContext();

  const descendants = useMenuDescendantsContext();

  // Based on current character pressed, find the next item to be selected
  const typeahead = useTypeahead({
    handler: typedSoFar => {
      const matchingItem = getNextItemFromSearch(
        descendants.values(),
        typedSoFar,
        item => item?.node?.textContent ?? '',
        descendants.item(focusedIndex)
      );

      if (matchingItem) {
        const index = descendants.indexOf(matchingItem.node);
        setFocusedIndex(index);
      }
    },
    preventDefault: event => event.key !== ' ' && isMenuItem(event.target)
  });

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      // Ignore events that bubble up from React.Portal children
      if (!event.currentTarget.contains(event.target as Element)) return;

      const eventKey = keyCombination(event);

      const keyMap: Record<string, React.KeyboardEventHandler> = {
        Tab: event => event.preventDefault(),
        'Shift+Tab': event => event.preventDefault(),
        Escape: close,
        ArrowDown: () => {
          const next = descendants.nextEnabled(focusedIndex);
          if (next) {
            setFocusedIndex(next.index);
          }
        },
        ArrowUp: () => {
          const prev = descendants.prevEnabled(focusedIndex);
          if (prev) {
            setFocusedIndex(prev.index);
          }
        },
        Home: () => {
          const first = descendants.firstEnabled();
          if (first) {
            setFocusedIndex(first.index);
          }
        },
        End: () => {
          const last = descendants.lastEnabled();
          if (last) {
            setFocusedIndex(last.index);
          }
        }
      };

      const action = keyMap[eventKey];

      if (action !== undefined) {
        event.preventDefault();
        action(event);
        return;
      }

      if (isMenuItem(event.target)) {
        typeahead(event);
      }
    },
    [close, descendants, focusedIndex, setFocusedIndex, typeahead]
  );

  return {
    ...props,
    ref: useCombinedRefs(menuRef, menuAnimation.ref, ref),
    children: menuAnimation.shouldRender ? props.children : null,
    tabIndex: -1,
    role: 'menu',
    id: menuId,
    style: {
      ...props.style,
      transformOrigin: 'var(--popper-transform-origin)'
    },
    'aria-orientation': 'vertical' as AriaAttributes['aria-orientation'],
    onKeyDown: useCombinedEventHandler(props.onKeyDown, onKeyDown)
  };
}

function isMenuItem(element: Element | EventTarget | null) {
  // Match "menuitem", "menuitemradio", "menuitemcheckbox"
  return isHTMLElement(element) && (element.getAttribute('role')?.startsWith('menuitem') ?? false);
}

/** Hook that returns the necessary props for the positioning div of a `MenuList` implementation. */
export function useMenuListPositioner<P extends { style?: React.CSSProperties } = { style?: React.CSSProperties }>(props: P = {} as P): P {
  const { popper, menuAnimation } = useMenuContext();

  return popper.getPopperProps({
    ...props,
    style: {
      visibility: menuAnimation.shouldRender ? 'visible' : 'hidden',
      ...props.style
    }
  });
}

export interface UseMenuItemProps<E extends HTMLElement> {
  /** @default false */
  disabled?: boolean;

  /** @default false */
  focusableWhenDisabled?: boolean;

  /** Override the Menu's `closeOnSelect` prop. */
  closeOnSelect?: boolean;

  hasIcon: boolean;

  /**
   * Keyboard shortcut to be displayed on the menu item.
   *
   * _Note:_ No actual handling of keyboard shortcuts is done by the menu item,
   * to allow the parent component to decide if the shortcut should work
   * at all times or only while the menu is open.
   *
   * @example "Ctrl+E" */
  shortcut?: string | undefined;

  id?: string | undefined;

  onClick?: (event: React.MouseEvent<E>) => void;
  onFocus?: (event: React.FocusEvent<E>) => void;
  onMouseEnter?: (event: React.MouseEvent<E>) => void;
  onMouseMove?: (event: React.MouseEvent<E>) => void;
  onMouseLeave?: (event: React.MouseEvent<E>) => void;
}

/** Hook that returns the necessary props for the positioning div of a `MenuList` implementation. */
export function useMenuItem<E extends HTMLElement = HTMLButtonElement, P extends UseMenuItemProps<E> = UseMenuItemProps<E>>(
  props: P = {} as P,
  externalRef: Ref<E> | null = null
) {
  const {
    closeOnSelect,
    disabled = false,
    focusableWhenDisabled = false,
    hasIcon,
    shortcut,
    id: idFropProps,
    onClick: onClickProp,
    onFocus: onFocusProp,
    onMouseEnter: onMouseEnterProp,
    onMouseLeave: onMouseLeaveProp,
    onMouseMove: onMouseMoveProp,
    ...otherProps
  } = props;

  const {
    setFocusedIndex,
    focusedIndex,
    closeOnSelect: closeOnSelectFromMenu,
    menuRef,
    isOpen,
    menuId,
    close,
    cancelPendingFocus
  } = useMenuContext();

  const ref = useRef<E>(null);
  const generatedId = useId();
  const id = idFropProps ?? `${menuId}-menuitem-${generatedId}`;

  const { index, register } = useMenuDescendant({
    disabled: disabled && !focusableWhenDisabled,
    hasIcon,
    shortcut: shortcut,
    id
  });

  const onMouseEnter = useCallback(
    (event: React.MouseEvent<E>) => {
      onMouseEnterProp?.(event);
      if (disabled) return;
      setFocusedIndex(index);
    },
    [setFocusedIndex, index, disabled, onMouseEnterProp]
  );

  const onMouseMove = useCallback(
    (event: React.MouseEvent<E>) => {
      onMouseMoveProp?.(event);
      if (ref.current && document.activeElement !== ref.current) {
        onMouseEnter(event);
      }
    },
    [onMouseEnter, onMouseMoveProp]
  );

  const onMouseLeave = useCallback(
    (event: React.MouseEvent<E>) => {
      onMouseLeaveProp?.(event);
      if (disabled) return;
      setFocusedIndex(-1);
    },
    [setFocusedIndex, disabled, onMouseLeaveProp]
  );

  const onClick = useCallback(
    (event: React.MouseEvent<E>) => {
      if (disabled) {
        event.stopPropagation();
        event.preventDefault();
        return;
      }

      onClickProp?.(event);

      if (!isMenuItem(event.currentTarget)) return;

      // Allow the menu item to override the menu's `closeOnSelect` prop.
      if (closeOnSelect ?? closeOnSelectFromMenu) {
        close();
      }
    },
    [disabled, onClickProp, closeOnSelect, closeOnSelectFromMenu, close]
  );

  const onFocus = useCallback(
    (event: React.FocusEvent<E>) => {
      onFocusProp?.(event);
      setFocusedIndex(index);
    },
    [setFocusedIndex, onFocusProp, index]
  );

  const isFocused = index === focusedIndex;

  const trulyDisabled = disabled && !focusableWhenDisabled;

  useUpdateEffect(() => {
    if (!isOpen) return;

    if (isFocused && !trulyDisabled && ref.current) {
      cancelPendingFocus.current?.();

      const animationFrame = requestAnimationFrame(() => {
        ref.current?.focus();
      });

      cancelPendingFocus.current = () => cancelAnimationFrame(animationFrame);
    } else if (menuRef.current && menuRef.current !== document.activeElement) {
      menuRef.current.focus({ preventScroll: true });
    }
  }, [isFocused, trulyDisabled, menuRef, isOpen, cancelPendingFocus]);

  return {
    ...otherProps,
    'aria-disabled': trulyDisabled ? undefined : disabled,
    disabled,
    id,
    role: 'menuitem',
    tabIndex: isFocused ? 0 : -1,
    ref: useCombinedRefs(register as ((node: E | null) => void) | null, ref, externalRef),
    onClick,
    onMouseEnter,
    onMouseMove,
    onMouseLeave,
    onFocus
  };
}
