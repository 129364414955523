import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle({
  textStyle: 'body',
  fontWeight: 'bold',
  mb: '1',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  opacity: 1,
  _disabled: {
    opacity: 0.4
  }
});

export const formLabelTheme = defineStyleConfig({
  baseStyle
});
