import { createContext, useContext, useMemo, type ReactNode } from 'react';
import type { ProductTiers } from '../business-logic/productPlans';
import { useAuthStore } from '../stores/AuthStore';

interface UserPermissions {
  editActualsInFuture: boolean;
  editPlansInPast: boolean;
  viewLiveData: boolean;
  downloadPriceForecast: boolean;
  editPlantSettings: boolean;
  editCustomers: boolean;
  manageUsers: boolean;
  searchTopology: boolean;
  managePortfolios: boolean;
  viewActions: boolean;
  plan: ProductTiers;
}

const UserPermissionsContext = createContext<UserPermissions | null>(null);

export function ProvideUserPermissions({ children }: { children: ReactNode }) {
  const isAdmin = useAuthStore(state => state.isAdmin);

  //TODO: Should remove when we have backend intergation of account + plan sold
  const userEmail = useAuthStore(state => state.email);
  const isSSE = userEmail?.endsWith('@sse.com') ?? false;
  const plan = isSSE || isAdmin ? 'plus' : 'basic';

  const permissions = useMemo((): UserPermissions => {
    return {
      editActualsInFuture: isAdmin,
      editPlansInPast: isAdmin,
      viewLiveData: isAdmin,
      downloadPriceForecast: isAdmin,
      editPlantSettings: isAdmin,
      editCustomers: isAdmin,
      manageUsers: isAdmin,
      searchTopology: isAdmin,
      managePortfolios: isAdmin,
      viewActions: isAdmin,
      plan
    };
  }, [isAdmin, plan]);

  return <UserPermissionsContext.Provider value={permissions}>{children}</UserPermissionsContext.Provider>;
}

export function useUserPermissions() {
  const context = useContext(UserPermissionsContext);
  if (context == null) {
    throw new Error(`The component calling useUserPermissions needs to be inside a UserPermissionsContext.`);
  }
  return context;
}
