import { useMemo, type MutableRefObject, type Ref } from 'react';

type ReactRef<T> = Ref<T> | MutableRefObject<T>;

function assignRef<T = unknown>(ref: ReactRef<T> | undefined, value: T) {
  if (ref == null) return;

  if (typeof ref === 'function') {
    ref(value);
    return;
  }

  try {
    (ref as { current: T }).current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${String(value)}' to ref '${String(ref)}'`);
  }
}

export function combineRefs<T>(...refs: (ReactRef<T> | undefined)[]) {
  if (refs.every(ref => ref == null)) {
    return null;
  }

  return (node: T | null) => {
    refs.forEach(ref => {
      if (ref) assignRef(ref, node);
    });
  };
}

// Source: https://github.com/chakra-ui/chakra-ui/blob/main/packages/hooks/src/use-merge-refs.ts
/**
 * React hook that combines react refs into a single memoized function
 *
 * @example
 * import React from "react";
 * import { useCombinedRefs } from `@chakra-ui/hooks`;
 *
 * const Component = React.forwardRef((props, ref) => {
 *   const internalRef = React.useRef();
 *   return <div {...props} ref={useCombinedRefs(internalRef, ref)} />;
 * });
 */
export function useCombinedRefs<T>(...refs: (ReactRef<T> | undefined)[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => combineRefs(...refs), refs);
}
