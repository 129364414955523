import { createStrictContext } from '@hydrogrid/utilities/react';
import { useState, type PropsWithChildren } from 'react';
import { createStore, useStore, type StateCreator, type StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';

/**
 * This should be used for creating Stores that are only available on a specific page / feature.
 *
 * The store has to be provided at a specific point in the React Tree using the Provider returned by this function.
 *
 * We also have a "createGlobalStore" utility function for creating globally accessible stores like for our authentication state, but most of the time you'd want to use this one.
 */
export function createLocalStore<T, InitProps>(name: string, creator: (props?: InitProps) => StateCreator<T>) {
  const [useStoreContext, StoreContextProvider] = createStrictContext<StoreApi<T>>(name);

  function Provider({ children, props }: PropsWithChildren<{ props?: InitProps | undefined }>) {
    const [store] = useState(() =>
      createStore<T>()(
        devtools(typeof creator === 'function' ? creator(props) : creator, {
          name,
          enabled: !import.meta.env.PROD && import.meta.env.MODE !== 'test'
        })
      )
    );

    return <StoreContextProvider value={store}>{children}</StoreContextProvider>;
  }

  type Params<U> = Parameters<typeof useStore<StoreApi<T>, U>>;
  function useLocalStore<U>(selector: Params<U>[1]) {
    const store = useStoreContext();
    return useStore(store, selector);
  }
  Object.defineProperty(useLocalStore, 'name', {
    configurable: true,
    value: `use${name}`
  });

  return [useLocalStore, Provider, useStoreContext] as const;
}
