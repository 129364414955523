import type { ReactNode } from 'react';
import { routes } from '../../../common/routing/routes';
import { useAppRoute } from '../../../common/routing/useAppRoute';
import { ConstraintFilterProvider } from './ConstraintFiltersStore';

interface ConstraintProvidersProps {
  children?: ReactNode;
}

export function ConstraintProviders({ children }: ConstraintProvidersProps) {
  const route = useAppRoute(routes.constraints);

  return <ConstraintFilterProvider key={route.params.portfolioId}>{children}</ConstraintFilterProvider>;
}
