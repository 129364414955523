import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { Button, Dialog, KeyboardKeys } from '@hydrogrid/design-system';
import { groupToMap } from '@hydrogrid/utilities/data';
import { Fragment, useMemo } from 'react';
import { useAvailableAppActions } from '../../common/accessibility/AppActionsContext';
import type { AppAction } from '../../common/accessibility/useAppActions';
import styles from './HotkeyModal.module.css';
import { useHotkeyModal } from './HotkeyModalStateContext';

export function HotkeyModal() {
  const { isOpen, close } = useHotkeyModal();
  const actions = useAvailableAppActions();

  const groupedActions = useMemo(() => {
    return groupToMap(actions, action => action.group ?? 'General');
  }, [actions]);

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      isOpen
      onClose={close}
      isCentered
      title="Keyboard Shortcuts"
      footer={
        <Button colorScheme="secondary" variant="outline" onClick={close}>
          Close
        </Button>
      }
    >
      <Grid as="dl" gridTemplateColumns="auto 1fr" m={0} gap={2}>
        {[...groupedActions].map(([group, actions]) => (
          <ActionGroup key={group} name={group} actions={actions} />
        ))}
      </Grid>
    </Dialog>
  );
}

interface ActionGroupProps {
  name: string;
  actions: AppAction[] | undefined;
}

function ActionGroup({ name, actions }: ActionGroupProps) {
  return (
    <>
      <Text as="h3" m={0} textStyle="headline2" gridColumn="1 / -1" _notFirst={{ mt: 4 }}>
        {name}
      </Text>
      {actions?.map(({ name, hotkey, enabled }, index) => {
        if (enabled !== null && enabled === false) return null;
        return (
          <Fragment key={index}>
            <Flex as="dt" textAlign="end" align="center" justify="flex-end" maxW="min(20rem, calc(40vw - 0.25rem))" pl={4}>
              {name}:
            </Flex>
            <Box as="dd" sx={{ all: 'unset' }}>
              {hotkey != null && <KeyboardKeys keys={hotkey} className={styles.keys} keyClassName={styles.key} />}
            </Box>
          </Fragment>
        );
      })}
    </>
  );
}
