import {
  forwardRef,
  type ComponentPropsWithRef,
  type ElementType,
  type ReactElement,
  type ReactNode,
  type Ref,
  type RefAttributes
} from 'react';
import { Button, type ButtonProps } from '../Button/Button';
import { useMenuButton, useMenuContext } from './useMenu';

interface MenuButtonProps extends Omit<ButtonProps, 'isActive'> {}

interface ExplicitlyTypedMenuButton {
  // Using the default button implementation
  (props: { as?: undefined } & MenuButtonProps & RefAttributes<HTMLButtonElement>): ReactElement | null;

  // Using a different component or tag name as button
  <
    PropsType extends {
      children: ReactNode;
      onClick?: (event?: React.MouseEvent) => void;
      onKeyDown?: (event?: React.KeyboardEvent) => void;
    },
    AsComponent extends ElementType<PropsType>
  >(
    props: { as: AsComponent } & ComponentPropsWithRef<AsComponent>
  ): ReactElement | null;
}

const MenuButtonComponent = forwardRef<HTMLButtonElement, { as?: ElementType } & MenuButtonProps>((props, externalRef) => {
  const { as: AsComponent, ...otherProps } = props;
  const buttonProps = useMenuButton(otherProps, externalRef);
  const { isOpen } = useMenuContext();

  if (AsComponent === undefined) {
    return (
      <Button
        colorScheme="secondary"
        isActive={isOpen ? true : (undefined as never)}
        {...buttonProps}
        ref={buttonProps.ref as Ref<HTMLButtonElement>}
      />
    );
  }

  const Component = AsComponent as (props: {}) => ReactElement | null;
  return <Component {...buttonProps} />;
});

/**
 * The button that triggers a menu on click.
 *
 * @usage
 * ```tsx
 * <Menu>
 *   <MenuButton>Actions</MenuButton>
 *   <MenuList>...</MenuList>
 * </Menu>
 * ```
 *
 * Pass a component type with `as` to render a different component:
 *
 * (custom components must use `forwardRef` to be used as MenuButton)
 * ```tsx
 * <Menu>
 *   <MenuButton as={FoobarButton} foobarColor="blue">Select</MenuButton>
 *   <MenuList>...</MenuList>
 * </Menu>
 * ```
 */
export const MenuButton = MenuButtonComponent as unknown as ExplicitlyTypedMenuButton;
