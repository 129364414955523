import { selectAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system';
import { inputTheme } from './Input';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar('select-bg');

const baseStyleField = defineStyle({
  ...inputTheme.baseStyle?.field,
  appearance: 'none',
  paddingBottom: '1px',
  lineHeight: 'normal',
  bg: $bg.reference,
  [$bg.variable]: 'colors.white',
  '> option, > optgroup': {
    bg: $bg.reference
  },
  display: 'flex',
  userSelect: 'none',
  alignItems: 'center',
  gap: 2,
  p: 2,
  flexWrap: 'nowrap'
});

const baseStyleOption = defineStyle({});

const baseStyle = definePartsStyle({
  field: baseStyleField,
  option: baseStyleOption
});

const sizes = {
  md: {
    ...inputTheme.sizes?.md,
    field: {
      ...inputTheme.sizes?.md.field
    }
  },
  xs: {
    ...inputTheme.sizes?.xs,
    field: {
      ...inputTheme.sizes?.xs.field
    }
  }
};

export const multiSelectTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: inputTheme.variants,
  defaultProps: inputTheme.defaultProps
});
