import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgFileCsv = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", viewBox: "0 0 24 24", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { fill: "currentColor", clipPath: "url(#a)", children: [
    /* @__PURE__ */ jsx("path", { d: "m20.85 5.15-5-5A.5.5 0 0 0 15.5 0H5a2 2 0 0 0-2 2v20c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V5.5a.5.5 0 0 0-.15-.35ZM16 1.7 19.3 5H17a1 1 0 0 1-1-1V1.7ZM20 22a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h10v3c0 1.1.9 2 2 2h3v16Z" }),
    /* @__PURE__ */ jsx("path", { d: "m13.8 13.3-.46-.22.45.22Zm-1.75 3.84-.46-.21.46.2Zm-1.74-.45-.46-.2.46.2Zm1.07-2.4.45.2.23-.51-.53-.17-.15.48Zm-.88-1.9c0-.87.64-1.5 1.5-1.5v-1a2.46 2.46 0 0 0-2.5 2.5h1Zm1.5-1.5c.86 0 1.5.63 1.5 1.5h1c0-1.42-1.09-2.5-2.5-2.5v1Zm1.5 1.5c0 .25-.06.48-.16.7l.9.42c.16-.33.26-.7.26-1.13h-1Zm-.16.7-1.75 3.84.92.41 1.74-3.84-.91-.41Zm-1.75 3.84a.8.8 0 0 1-.29.38.89.89 0 0 1-.48.11v1c.36 0 .7-.07 1.01-.26.3-.2.53-.48.68-.82l-.92-.4Zm-.77.49a.3.3 0 0 1-.14-.02l-.01-.01c-.01-.04-.04-.19.1-.5l-.92-.4c-.19.42-.27.88-.1 1.28.19.46.61.65 1.07.65v-1Zm-.06-.53 1.07-2.4-.9-.4-1.08 2.4.91.4Zm.77-3.08a1.46 1.46 0 0 1-1.03-1.43h-1c0 1.13.7 2.06 1.73 2.39l.3-.96Z" })
  ] }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "a", children: /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "M0 0h24v24H0z" }) }) })
] });
const ForwardRef = forwardRef(SvgFileCsv);
export default ForwardRef;
