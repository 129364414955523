import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Flex, Icon, Text } from '@chakra-ui/react';
import { type PropsWithChildren, type ReactNode } from 'react';
import { ArrowRightIcon } from '../../icons';

interface NavigationAccordionProps {
  label: string;
  icon?: ReactNode;
  isMenuCollapsed?: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export function NavigationAccordion({
  label,
  icon,
  isOpen = false,
  onOpen,
  onClose,
  isMenuCollapsed = false,
  children
}: PropsWithChildren<NavigationAccordionProps>) {
  return (
    <Accordion
      p={0}
      allowMultiple
      index={isOpen ? [0] : []}
      onChange={expandedIndex => {
        if (typeof expandedIndex === 'number') {
          return;
        }

        if (expandedIndex.length > 0) {
          onOpen();
        } else {
          onClose();
        }
      }}
    >
      <AccordionItem
        display="flex"
        color="white"
        borderBottom={0}
        p={0}
        borderTop={[0, '1px']}
        borderLeft={['1px', 0]}
        flexDirection={['row', 'column']}
      >
        <>
          <AccordionButton
            display="flex"
            flexDirection={['row', 'column', isMenuCollapsed ? 'column' : 'row']}
            alignItems="center"
            justifyContent="space-between"
            gap={[1, null, isMenuCollapsed ? 1 : 2]}
            py={[1.5, null, isMenuCollapsed ? 2 : 3]}
            px={[2, 4]}
            minH={12.5}
            w={[12, null, isMenuCollapsed ? 12 : 50]}
            fontSize={[0, null, isMenuCollapsed ? 0 : 'md']}
            transition="width 0.3s"
            _hover={{ bg: 'secondary.700' }}
            bg={isOpen ? 'contrast.800' : (undefined as never)}
          >
            <Flex gap={[0, null, isMenuCollapsed ? 0 : 2.5]} align="center">
              {icon}
              <Text fontSize={[0, null, isMenuCollapsed ? 0 : 'md']} transition="font-size 0.3s" color="white">
                {label}
              </Text>
            </Flex>
            <Icon
              as={ArrowRightIcon}
              transform={[
                `rotate(${isOpen ? 0 : 180}deg)`,
                `rotate(${isOpen ? 90 : 270}deg)`,
                `rotate(${isMenuCollapsed ? (isOpen ? 90 : 270) : isOpen ? 90 : 0}deg)`
              ]}
              transition="transform 0.2s"
              size="small"
            />
          </AccordionButton>
          <AccordionPanel p={0}>{children}</AccordionPanel>
        </>
      </AccordionItem>
    </Accordion>
  );
}
