export function arraysShallowEqual<T>(a: T[], b: T[]): boolean {
  return a === b || (a.length === b.length && a.every((value, index) => Object.is(value, b[index])));
}

export function shallowEqual<T>(a: T, b: T): boolean {
  if (Array.isArray(a) && Array.isArray(b)) {
    return arraysShallowEqual(a, b);
  } else if (Array.isArray(a) !== Array.isArray(b)) {
    return false;
  } else if (typeof a === 'object' && a && typeof b === 'object' && b) {
    return objectsShallowEqual(a, b);
  } else {
    return Object.is(a, b) || a === b;
  }
}

export function objectsShallowEqual<T extends object>(a: T, b: T): boolean {
  if (a === b) return true;

  const keysA = Object.keys(a).sort() as (keyof T)[];
  const keysB = Object.keys(b).sort() as (keyof T)[];

  return arraysShallowEqual(keysA, keysB) && keysA.every(key => Object.is(a[key], b[key]));
}

export function objectsShallowEqualWithShallowEqualArrays<T extends object>(a: T, b: T): boolean {
  if (a === b) return true;

  const keysA = Object.keys(a).sort() as (keyof T)[];
  const keysB = Object.keys(b).sort() as (keyof T)[];

  return (
    arraysShallowEqual(keysA, keysB) &&
    keysA.every(key => {
      const valueA = a[key];
      const valueB = b[key];
      return Object.is(valueA, valueB) || (Array.isArray(valueA) && Array.isArray(valueB) && arraysShallowEqual(valueA, valueB));
    })
  );
}
