import { CockpitEditModeQueryParam, DataRangeQueryParam, TimeZoneQueryParam, optional } from './QueryParamTypes';
import { defineRoute } from './TypedRoutes';

import type { ApiUserRouteState } from '../../pages/Admin/User/ApiUserRouteState';
import type { ConstraintsRouteState } from '../../pages/Constraints/ConstraintsRouteState';
import type { ForgotPasswordRouteState } from '../../pages/ForgotPassword/ForgotPasswordPageRouteState';
import type { LoginPageRouteState } from '../../pages/Login/LoginPageRouteState';
import type { MaintenancesRouteState } from '../../pages/Maintenances/MaintenancesRouteState';

/**
 * All app routes with their parameter types.
 * For the react components for each route, @see AppRouter.
 */
export const routes = {
  /** Start page. Checks if the user is logged in and redirects to login or cockpit. */
  index: defineRoute('/'),

  /** Login page. Includes the 2FA login step. */
  login: defineRoute('/:environment/login', {
    state: (state?: LoginPageRouteState) => state
  }),

  /** Forgot page. Allows the user to enter the email to reset the password. */
  forgotPassword: defineRoute('/:environment/forgot-password', {
    state: (state?: ForgotPasswordRouteState) => state
  }),

  /**
   * Reset password page.
   * This is where the user lands when they click the link in their email from `forgotPassword`.
   * Allows the user to reset their password, it should include a reset token and an email.
   */
  resetPassword: defineRoute('/:environment/reset-password', {
    query: {
      reset_token: String,
      email: String
    }
  }),

  /** Cockpit start page. Shows a map with available plants. */
  cockpitMap: defineRoute('/:environment/map/:portfolioId/:plantId?'),

  /**
   * Cockpit view for a specific power plant.
   * Contains charts, a topology and allows editing data in a table.
   *
   * Selecting a specific reservoir/turbine can be done via query parameters.
   * e.g.: eu/cockpit/portf-a/plant-b
   * - `?reservoir=some-reservoir-id`
   * - `?turbine=some-turbine-id`
   * - `?gate=some-gate-id`
   * - `?reservoir=some-reservoir-id&turbine=some-turbine-id`
   * - `?reservoir=some-reservoir-id&gate=some-gate-id`
   *
   * Providing both `turbine` and `gate` is invalid, but we can't currently
   * express that via TypeScript. Will switch to `zod` for validation soon-ish.
   */
  cockpitCharts: defineRoute('/:environment/cockpit/:portfolioId/:plantId', {
    query: {
      range: optional(DataRangeQueryParam),
      turbine: optional(String),
      gate: optional(String),
      reservoir: optional(String),
      edit: optional(CockpitEditModeQueryParam)
    }
  }),

  portfolioOverview: defineRoute('/:environment/portfolio/:portfolioId', {
    query: {
      range: optional(DataRangeQueryParam),
      timezone: optional(TimeZoneQueryParam),
      tab: optional(String)
    }
  }),

  longTermView: defineRoute('/:environment/long-term/:portfolioId', {
    query: {
      range: optional(DataRangeQueryParam)
    }
  }),

  waterValue: defineRoute('/:environment/water-value/:portfolioId/:plantId?/:turbineId?', {
    query: {
      range: optional(DataRangeQueryParam)
    }
  }),

  plantReporting: defineRoute('/:environment/reporting/:portfolioId/:plantId', {
    query: {
      range: optional(DataRangeQueryParam)
    }
  }),

  portfolioReporting: defineRoute('/:environment/reporting/:portfolioId', {
    query: {
      range: optional(DataRangeQueryParam)
    }
  }),

  constraints: defineRoute('/:environment/constraints/:portfolioId/:plantId?', {
    query: {
      view: optional(String),
      tab: optional(String)
    },
    state: (state?: ConstraintsRouteState) => state
  }),

  maintenances: defineRoute('/:environment/maintenance/:portfolioId', {
    query: {
      edit: optional(String),
      plantId: optional(String),
      selected: optional(String),
      view: optional(String),
      range: optional(DataRangeQueryParam),
      granularity: optional(String)
    },
    state: (state?: MaintenancesRouteState) => state
  }),

  plants: defineRoute('/:environment/admin/plants/:portfolioId', {
    query: {
      sortBy: optional(String),
      sortOrder: optional(String)
    }
  }),

  plantSettings: defineRoute('/:environment/admin/plants/:portfolioId/:plantId/:tab?/:subtab?', {
    query: {
      component: optional(String)
    }
  }),

  reserves: defineRoute('/:environment/reserves/:portfolioId', {
    query: {
      tab: optional(String)
    }
  }),

  customers: defineRoute('/:environment/admin/customers'),

  portfolios: defineRoute('/:environment/admin/portfolios'),

  users: defineRoute('/:environment/admin/users', {
    query: { tab: optional(String) }
  }),

  insightUser: defineRoute('/:environment/admin/users/insight/:user'),

  apiUser: defineRoute('/:environment/admin/users/api/:user', {
    state: (state?: ApiUserRouteState) => state
  }),

  liveData: defineRoute('/:environment/operations/liveData/:portfolioId', {
    query: {
      // Maybe?
      // section: optional(String)
      // tab: optional(String)
      outdated: optional(String)
    }
  }),

  actions: defineRoute('/:environment/operations/actions/')
};
