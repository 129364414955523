/* eslint-disable */

type AllKeysOf<T> = T extends any ? keyof T : never;
type ProhibitKeys<K extends keyof any> = { [P in K]?: never };
type ExactlyOneOf<T extends any[]> = T[number] extends object
  ? {
      [K in keyof T]: T[K] & ProhibitKeys<Exclude<AllKeysOf<T[number]>, keyof T[K]>>;
    }[number]
  : T[number];

export type Account = HasEmail & {
  name: string | null;
  default_portfolio_id: string | null;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  last_interaction_timestamp: CreationTimestamp;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  created_at_timestamp: CreationTimestamp;
  number_portfolios: number;
};

export type AccountList = Account[];

export interface AccountPermissionsListElem {
  plantId: string;
  plantName: string;
  permissions: string[];
}

export interface AddComponent {
  internal_id: string;
  type: ComponentType;
}

export interface AnalyticsParameters {
  simplified_inflow_calculation: boolean;
  consider_balancing_energy_cost: boolean;
  intraday_redispatch: boolean;
  shadow_trading_start_timestamp_millis?: CreationTimestamp | null | undefined;
  use_only_market_order_bid: boolean;
  gate_redispatch_frequency_hours: GateRedispatchFrequencyHours;
  strategy_adjustment_factor: StrategyAdjustmentFactor;
  bid_price_stepsize: BidPriceStepsize;
  discrete_bid_execution: boolean;
  bid_volume_stepsize: BidVolumeStepsize;
  bid_padding: boolean;
  exchange_price_minimum: ExchangePriceMinimum;
  exchange_price_maximum: ExchangePriceMaximum;
  bid_price_lower_bound: BidPriceLowerBound;
  insert_separation_columns_into_bid: boolean;
  use_simplified_bid?: boolean | undefined;
}

export type AnySettingType = object | string | number | number | boolean | unknown[] | null;

export type ApiServiceAccount = ApiServiceAccountSubmission & {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  last_interaction_timestamp?: CreationTimestamp | undefined;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  created_at_timestamp: CreationTimestamp;
  portfolio_id: string;
};

export type ApiServiceAccountList = ApiServiceAccount[];

export interface ApiServiceAccountSubmission {
  email: Email;
  name: string;
}

export interface AppContext {
  version: string;
}

export type BasicPlantArrangement = Plant & Topology;

export type BidPriceLowerBound = number;

export type BidPriceStepsize = number;

export type BidVolumeStepsize = number;

export type CatchmentAreaKm2 = number;

export type CatchmentElevationMasl = number;

/**
 * @example
 * ```
 * {
 *   "unavailability_reason": [
 *     null,
 *     "Shutdown"
 *   ],
 *   "unavailability_type": [
 *     null,
 *     "Planned"
 *   ],
 *   "status": [
 *     null,
 *     "Active"
 *   ],
 *   "name": [
 *     null,
 *     "NewName"
 *   ],
 *   "description": [
 *     null,
 *     "Some description"
 *   ],
 *   "validity_start_timestamp_millis": [
 *     null,
 *     1688389627000
 *   ],
 *   "validity_end_timestamp_millis": [
 *     null,
 *     1688689627000
 *   ],
 *   "remaining_power": [
 *     null,
 *     3.2
 *   ],
 *   "deactivated_timestamp_millis": [
 *     null,
 *     1688709627000
 *   ]
 * }
 * ```
 */
export interface Changeset {
  unavailability_reason?: MaintenanceReasonChange | undefined;
  unavailability_type?: MaintenanceTypeChange | undefined;
  status?: MaintenanceStatusChange | undefined;
  name?: MaintenanceNameChange | undefined;
  description?: MaintenanceDescriptionChange | undefined;
  validity_start_timestamp_millis?: TimestampChange | undefined;
  validity_end_timestamp_millis?: TimestampChange | undefined;
  remaining_power?: MaintenanceValueChange | undefined;
  deactivated_timestamp_millis?: TimestampChange | undefined;
}

export type CompleteConstraint = Constraint & {
  id: string;
  constrainable_name: string;
  type_description: string;
  type_unit: string;
  deactivated_timestamp_millis: CreationTimestamp | null;
  email: Email;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  creation_timestamp_millis: CreationTimestamp;
};

export type CompleteConstraintList = CompleteConstraint[];

export type CompleteControlUnit = SimpleControlUnit & {
  id: string;
  p_min: PMin | null;
  p_max: PMax | null;
  q_min: QMin | null;
  q_max: QMax | null;
  control_status: ControlStatus;
  gate_opening_cm_extrema: GateOpeningCmExtrema | null;
  unmetered?: boolean | null | undefined;
  always_on: boolean;
};

export type CompleteControlUnitList = CompleteControlUnit[];

export type CompleteHydroBody = SimpleHydroBody & HydroBodyAttributes;

export interface CompleteTopology {
  hydro_bodies: TopologyHydroBodyList;
  control_units: CompleteControlUnitList;
}

export interface CompleteTopologyWithSpillway {
  hydro_bodies: TopologyHydroBodyListWithSpillway;
  control_units: CompleteControlUnitList;
}

export interface Component {
  id: string;
  name: string;
  type: ComponentType;
}

export type ComponentList = Component[];

export type ComponentType = 'Reservoir' | 'Turbine' | 'Gate';

/** Corresponding string values for the type @see ComponentType */
export const allComponentTypes = ['Reservoir', 'Turbine', 'Gate'] as const;

export type ConstrainableType = 'Plant' | 'Reservoir' | 'Turbine' | 'Gate';

/** Corresponding string values for the type @see ConstrainableType */
export const allConstrainableTypes = ['Plant', 'Reservoir', 'Turbine', 'Gate'] as const;

export interface Constraint {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  validity_start_timestamp_millis?: CreationTimestamp | undefined;
  validity_end_timestamp_millis?: CreationTimestamp | null | undefined;
  recurring_pattern?: RecurringPattern | undefined;
  constrainable_id: string;
  constrainable_type: ConstrainableType;
  value: ExactlyOneOf<[ConstraintFloat, string]>;
  type_name: string;
  type_display_name?: string | undefined;
  name?: ConstraintName | undefined;
  description?: ConstraintDescription | undefined;
}

export type ConstraintDescription = string | null;

export type ConstraintFloat = number;

export type ConstraintName = string | null;

export interface ConstraintType {
  name: string;
  description: string;
  unit: string;
  constrainable_type: ConstrainableType;
  section: ConstraintTypeSection;
  display_name: string;
}

export type ConstraintTypeList = ConstraintType[];

export type ConstraintTypeSection = 'not_implemented' | 'plant_constraints' | 'plant_constraints_internal' | 'admin_plant_setting';

/** Corresponding string values for the type @see ConstraintTypeSection */
export const allConstraintTypeSections = [
  'not_implemented',
  'plant_constraints',
  'plant_constraints_internal',
  'admin_plant_setting'
] as const;

export type ConstraintValueNullable = unknown /* TODO(1): <no type> */ | null;

export type ControlStatus = 'PASSIVE' | 'ACTIVE' | 'LEVEL_REGULATE' | 'ACTIVE_LEVEL_REGULATE';

/** Corresponding string values for the type @see ControlStatus */
export const allControlStatus = ['PASSIVE', 'ACTIVE', 'LEVEL_REGULATE', 'ACTIVE_LEVEL_REGULATE'] as const;

export interface ControlUnitUpdate {
  p_min: PMin | null;
  p_max: PMax | null;
  q_min: QMin | null;
  q_max: QMax | null;
  control_status: ControlStatus;
  feeds_from: string | null;
  spills_to: string | null;
  name: string | null;
  gate_opening_cm_extrema: GateOpeningCmExtrema | null;
  travel_time_minutes: TravelTimeMinutes | null;
  unmetered?: boolean | null | undefined;
}

/**
 * Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00)
 *
 * @example
 * ```
 * 1688389171000
 * ```
 */
export type CreationTimestamp = number;

export type CSVFile = string;

export interface Currency {
  currency: {
    shortName: string;
    symbol: string;
    description: string;
  };
}

/**
 * @min 0
 * @max 59
 */
export type CutOffMinuteOfHour = number;

export type Date = string;

export interface DayAheadNominationConfiguration {
  /**
   * @min 0
   * @max 23
   */
  cutoff_hour_of_day_local: HourOfDayLocal;
  /**
   * @min 0
   * @max 59
   */
  cutoff_minute_of_hour: CutOffMinuteOfHour;
}

export interface DayAheadPrice {
  overall: EURperMWhPriceValue | null;
  timeseries: DayAheadPriceElementList;
}

export interface DayAheadPriceElement {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  value: EURperMWhPriceValue | null;
}

export type DayAheadPriceElementList = DayAheadPriceElement[];

/**
 * @min 1
 * @max 28
 */
export type DayOfMonthLocal = number;

/**
 * @min 1
 * @max 7
 */
export type DayOfWeekLocal = number;

/**
 * @min 1
 * @max 31
 */
export type DayOfYearLocal = number;

export interface DefaultTaskArguments {
  name?: string | undefined;
  value?: string | undefined;
  format?: string | undefined;
}

export type DischargeScheduleActual = { gateId: string; timeseries: GateDischargeWithModeTimeseriesElem[] }[];

export type DischargeScheduleActualNullable = { gateId: string; timeseries: NullableGateDischargeWithModeTimeseriesElem[] }[];

export type DischargeSchedulePlan = { gateId: string; timeseries: GateDischargeWithModeTimeseriesElem[] }[];

/**
 * Discharge in Cubic metre per second (cumec)
 *
 * @min 0
 */
export type DischargeValue = number;

export interface ElectricityTariff {
  electricityTariff: ElectricityTariffDef;
}

export interface ElectricityTariffDef {
  shortName?: string | undefined;
  symbol?: string | undefined;
  description?: string | undefined;
}

/**
 * @example
 * ```
 * "some@email.com"
 * ```
 */
export type Email = string;

export interface EnergyElement {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  actual: MWhEnergyValue | null;
  plan: MWhEnergyValue | null;
}

export type EnergyElementList = EnergyElement[];

export interface EnergyTurbineTimeseries {
  turbineId: string;
  timeseries: EnergyElementList;
}

export type EnergyTurbineTimeseriesList = EnergyTurbineTimeseries[];

export type ErrorCode = number;

/** Total amount of money in EUR, e.g. Revenue */
export type EuroAmount = number;

/** Price in EUR/MWh */
export type EURperMWhPriceValue = number;

export type ExchangePriceMaximum = number;

export type ExchangePriceMinimum = number;

export type ExtendedBasicPlantArrangement = BasicPlantArrangement & { price_forecast_source_display_name: string };

export type FlowRateM3Sec = number;

export interface FlowToPower {
  flow_rate: FlowRateM3Sec;
  generation: GenerationMw;
  reservoir_level?: ReservoirLevelMasl | null | undefined;
}

export type FlowToPowerList = FlowToPower[];

export interface GateDischargeBoundaries {
  q_min?: QMin | undefined;
  q_max?: QMax | undefined;
}

export type GateDischargeItem = GateDischargeBoundaries & {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  actual?: DischargeValue | null | undefined;
  plan?: DischargeValue | null | undefined;
  price?: Price | null | undefined;
};

/** @minItems 1 */
export type GateDischargeOverride = TimestampDischargeTuple[];

export type GateDischargeOverrideInfo = TimestampDischargeOverrideInfoTuple[];

export type GateDischargeResponse = GateDischargeItem[];

/**
 * Gate discharge measured in m³/s
 *
 * @min 0
 */
export type GateDischargeValue = number;

export interface GateDischargeWithModeTimeseriesElem {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Gate discharge measured in m³/s
   *
   * @min 0
   */
  value: GateDischargeValue;
  /**
   * The mode the control unit is currently in.
   * If omitted 'optimal' will be taken as the default.
   */
  mode?: OperationMode | undefined;
}

/** @min 0 */
export type GateMaxOpeningCm = number;

/**
 * @min 0
 * @max 0
 */
export type GateMinOpeningCm = number;

export type GateOpening = number;

export interface GateOpeningCmExtrema {
  /**
   * @min 0
   * @max 0
   */
  min: GateMinOpeningCm;
  /** @min 0 */
  max: GateMaxOpeningCm;
}

export interface GateOpeningWithModeTimeseriesElem {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Gate opening in cm
   *
   * @min 0
   */
  value: OpeningValue;
  /**
   * The mode the control unit is currently in.
   * If omitted 'optimal' will be taken as the default.
   */
  mode?: OperationMode | undefined;
}

export interface GateOpeningWithModeTimeseriesElemNullable {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Gate opening in cm
   *
   * @min 0
   */
  value: NullableOpeningValue;
  /**
   * The mode the control unit is currently in.
   * If omitted 'optimal' will be taken as the default.
   */
  mode?: OperationMode | undefined;
}

export type GateRedispatchFrequencyHours = number;

export type GenerationMw = number;

/**
 * @min 0
 * @max 1
 */
export type GenerationRelative = number;

/** @min 0 */
export type GenerationTotalMW = number;

export type GranularityMinutes = number;

export interface GrossAndNetElement {
  gross?: PowerPlanAndActualAndBoundariesElem | undefined;
  net?: PowerPlanAndActualAndBoundariesElem | undefined;
}

export type HandlingOption = 'discard_exceeding_limits' | 'ignore_missing_data' | 'forward_fill_missing_data' | 'overwrite_existing_data';

/** Corresponding string values for the type @see HandlingOption */
export const allHandlingOptions = [
  'discard_exceeding_limits',
  'ignore_missing_data',
  'forward_fill_missing_data',
  'overwrite_existing_data'
] as const;

export interface HasEmail {
  email: Email;
}

export type HourAndCoarserGranularities = 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';

/** Corresponding string values for the type @see HourAndCoarserGranularities */
export const allHourAndCoarserGranularities = ['hour', 'day', 'week', 'month', 'quarter', 'year'] as const;

/**
 * @min 0
 * @max 23
 */
export type HourOfDayLocal = number;

export interface HydroBody {
  id: string;
  name: string;
  /** Lat/Lon coordinates */
  location: LatLonLocation;
}

export interface HydroBodyAttributes {
  maximum_spill_throughput: MaximumSpillThroughput | null;
  weather_url: string | null;
  type_specific_attributes: HydroBodyReservoir | null;
}

export type HydroBodyInflowOverrideInfo = TimestampInflowOverrideInfoTuple[];

export interface HydroBodyInflowPlanElem {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  plan?: InflowValue | null | undefined;
  longTermAvg?: InflowValue | null | undefined;
}

/** @minItems 1 */
export type HydroBodyInflowPlanOverride = TimestampInflowTuple[];

export type HydroBodyInflowSeries = InflowActualAndPlanElement[];

export interface HydroBodyReservoir {
  reservoir_level_min_masl: ReservoirLevelMinMasl | null;
  reservoir_level_max_masl: ReservoirLevelMaxMasl | null;
  gps_latitude_decimal: Latitude | null;
  gps_longitude_decimal: Longitude | null;
  spillway_level_masl: SpillwayLevelMasl | null;
  spillway_width_m: SpillwayWidthM | null;
  spillway_shape_parameter: SpillwayShapeParameter | null;
  catchment_area_km2: CatchmentAreaKm2 | null;
  catchment_elevation_masl: CatchmentElevationMasl | null;
}

export type HydroBodyUpdate = HydroBodyAttributes & { name: string };

export interface IdentifiedPortfolioMaintenanceError {
  plant_id: string;
  error: string;
  status_code: ErrorCode;
}

export interface IdentifiedPriceForecastSource {
  market_id: string;
  portfolio_id: string;
  external_id: string;
  display_name: string;
  uuid: string;
}

export type IdentifiedPriceForecastSourceList = IdentifiedPriceForecastSource[];

export interface IndividualAndAggregatedEnergy {
  overall_actual: MWhEnergyValue | null;
  aggregated_timeseries: EnergyElementList;
  turbines: EnergyTurbineTimeseriesList;
}

export interface IndividualAndAggregatedInflow {
  overall: InflowValue | null;
  /** @minItems 0 */
  aggregated_timeseries: InflowElementList;
  reservoirs: InflowHydroBodyTimeseriesList;
}

export interface IndividualAndAggregatedInflowWeightedDayAheadPrice {
  overall_actual: EURperMWhPriceValue | null;
  aggregated_timeseries: InflowWeightedDayAheadPriceElementList;
  turbines: InflowWeightedDayAheadPriceTurbineTimeseriesList;
}

export interface IndividualAndAggregatedPower {
  overall: MwGenerationValue | null;
  aggregated_timeseries: PowerElementList;
  turbines: PowerTurbineTimeseriesList;
}

export interface IndividualAndAggregatedRealizedDayAheadPrice {
  overall: EURperMWhPriceValue | null;
  aggregated_timeseries: RealizedDayAheadPriceElementList;
  turbines: RealizedDayAheadPriceTurbineTimeseriesList;
}

export interface IndividualAndAggregatedRevenue {
  overall_actual: EuroAmount | null;
  aggregated_timeseries: RevenueElementList;
  turbines: RevenueTurbineTimeseriesList;
}

export interface IndividualAndAggregatedWaterContent {
  overall_actual: ReservoirContentValue | null;
  aggregated_timeseries: ReservoirContentActualAndPlanElement[];
  reservoirs: ReservoirContentSeries[];
}

export interface InExTernalComponent {
  external_id: string;
  internal_id: string;
  name: string;
  type: ComponentType;
}

export type InExTernalComponentList = InExTernalComponent[];

export type InflowActualAndPlanElement = HydroBodyInflowPlanElem & InflowActualElement;

export interface InflowActualElement {
  actual?: InflowValue | null | undefined;
}

/** @minItems 0 */
export type InflowElementList = TimestampInflowTupleNullable[];

export interface InflowHydroBodyTimeseries {
  reservoirId: string;
  /** @minItems 0 */
  timeseries: InflowElementList;
}

export type InflowHydroBodyTimeseriesList = InflowHydroBodyTimeseries[];

export interface InflowPlanTimeseriesElem {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /** Inflow in Cubic metre per second (cumec) */
  value: InflowValue;
}

/** Inflow in Cubic metre per second (cumec) */
export type InflowValue = number;

export interface InflowWeightedDayAheadPriceElement {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  actual: EURperMWhPriceValue | null;
  plan: EURperMWhPriceValue | null;
}

export type InflowWeightedDayAheadPriceElementList = InflowWeightedDayAheadPriceElement[];

export interface InflowWeightedDayAheadPriceTurbineTimeseries {
  turbineId: string;
  timeseries: InflowWeightedDayAheadPriceElementList;
}

export type InflowWeightedDayAheadPriceTurbineTimeseriesList = InflowWeightedDayAheadPriceTurbineTimeseries[];

export interface IntraDayNominationConfiguration {
  minimum_lead_time_minutes: MinimumLeadTimeMinutes;
}

export interface JwtTokens {
  access_token: string;
  refresh_token: string;
}

export interface LatestTelemetryTimes {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  latest: TimestampNullable;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  latest_override: TimestampNullable;
  name: string;
  type: ComponentType;
}

export type LatestTelemetryTimesByComponent = Record<string, LatestTelemetryTimes>;

/**
 * @example
 * ```
 * {
 *   "plant_id_1": {
 *     "component_id_1": {
 *       "latest": 123456789,
 *       "latest_override": 123456789,
 *       "name": "comp1",
 *       "type": "TURBINE"
 *     },
 *     "component_id_2": {
 *       "latest": 123456789,
 *       "latest_override": 123456789,
 *       "name": "comp2",
 *       "type": "GATE"
 *     }
 *   },
 *   "plant_id_2": {
 *     "component_id_1": {
 *       "latest": 123456789,
 *       "latest_override": 123456789,
 *       "name": "comp3",
 *       "type": "RESERVOIR"
 *     },
 *     "component_id_2": {
 *       "latest": 123456789,
 *       "latest_override": 123456789,
 *       "name": "comp4",
 *       "type": "TURBINE"
 *     }
 *   },
 *   "plant_id_3": {
 *     "component_id_1": {
 *       "latest": 123456789,
 *       "latest_override": 123456789,
 *       "name": "comp5",
 *       "type": "GATE"
 *     },
 *     "component_id_2": {
 *       "latest": null,
 *       "latest_override": 123456789,
 *       "name": "comp6",
 *       "type": "RESERVOIR"
 *     }
 *   }
 * }
 * ```
 */
export type LatestTelemetryTimesByPlant = Record<string, LatestTelemetryTimesByComponent>;

export type Latitude = number;

/** Lat/Lon coordinates */
export interface LatLonLocation {
  latitude?: number | undefined;
  longitude?: number | undefined;
}

export interface LevelToContent {
  level: ReservoirLevelMasl;
  content: ReservoirContentHm3;
}

export type LevelToContentList = LevelToContent[];

export type ListPlantsTopology = PlantTopology[];

export type Longitude = number;

export type MaintenanceDescriptionChange = MaintenanceDescriptionNullable[];

export type MaintenanceDescriptionNullable = string | null;

export type MaintenanceName = string | null;

export type MaintenanceNameChange = MaintenanceNameNullable[];

export type MaintenanceNameNullable = string | null;

export type MaintenanceReason = 'Failure' | 'Foreseen' | 'Maintenance' | 'Shutdown' | 'Grid / Network';

/** Corresponding string values for the type @see MaintenanceReason */
export const allMaintenanceReasons = ['Failure', 'Foreseen', 'Maintenance', 'Shutdown', 'Grid / Network'] as const;

export type MaintenanceReasonChange = MaintenanceReasonNullable[];

export type MaintenanceReasonNullable = 'Failure' | 'Foreseen' | 'Maintenance' | 'Shutdown' | 'Grid / Network' | null;

/** Corresponding string values for the type @see MaintenanceReasonNullable */
export const allMaintenanceReasonNullables = ['Failure', 'Foreseen', 'Maintenance', 'Shutdown', 'Grid / Network'] as const;

export type MaintenanceStatusChange = SettingStatusTypeNullable[];

export interface MaintenanceSubmission {
  name: MaintenanceName;
  description: string;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  validity_start_timestamp_millis: CreationTimestamp;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  validity_end_timestamp_millis: CreationTimestamp;
  unavailability_reason: MaintenanceReason;
  unavailability_type: MaintenanceType;
  status: SettingStatusType;
  label?: string | undefined;
}

export type MaintenanceType = 'Planned' | 'Unplanned';

/** Corresponding string values for the type @see MaintenanceType */
export const allMaintenanceTypes = ['Planned', 'Unplanned'] as const;

export type MaintenanceTypeChange = MaintenanceTypeNullable[];

export type MaintenanceTypeNullable = 'Planned' | 'Unplanned' | null;

/** Corresponding string values for the type @see MaintenanceTypeNullable */
export const allMaintenanceTypeNullables = ['Planned', 'Unplanned'] as const;

export type MaintenanceValueChange = ConstraintValueNullable[];

/**
 * Reservoir level in metres above sea
 *
 * @min 0
 * @max 8000
 */
export type MasLevelValue = number;

export type MaximumSpillThroughput = number;

export interface MfaSecret {
  mfa_secret: string;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  valid_until: CreationTimestamp;
}

export interface MfaToken {
  mfa_token: string;
}

export type MinimumLeadTimeMinutes = number;

/**
 * @min 1
 * @max 12
 */
export type MonthOfYearLocal = number;

/**
 * Generation in MW
 *
 * @min 0
 */
export type MwGenerationValue = number;

/**
 * Energy in MWh
 *
 * @min 0
 */
export type MWhEnergyValue = number;

export interface MWTimeseriesElem {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Generation in MW
   *
   * @min 0
   */
  value: MwGenerationValue;
  /**
   * The mode the control unit is currently in.
   * If omitted 'optimal' will be taken as the default.
   */
  mode?: OperationMode | undefined;
}

export type NewAccount = HasEmail & { name: string; default_portfolio_id: string };

export interface NominationConfiguration {
  day_ahead?: DayAheadNominationConfiguration | null | undefined;
  intraday?: IntraDayNominationConfiguration | null | undefined;
}

/**
 * Gate discharge measured in m³/s
 *
 * @min 0
 */
export type NullableGateDischargeValue = number | null;

export interface NullableGateDischargeWithModeTimeseriesElem {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Gate discharge measured in m³/s
   *
   * @min 0
   */
  value: NullableGateDischargeValue;
  /**
   * The mode the control unit is currently in.
   * If omitted 'optimal' will be taken as the default.
   */
  mode?: OperationMode | undefined;
}

/**
 * Generation in MW
 *
 * @min 0
 */
export type NullableMwGenerationValue = number | null;

export interface NullableMWTimeseriesElem {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Generation in MW
   *
   * @min 0
   */
  value: NullableMwGenerationValue;
  /**
   * The mode the control unit is currently in.
   * If omitted 'optimal' will be taken as the default.
   */
  mode?: OperationMode | undefined;
}

/**
 * Gate opening in cm
 *
 * @min 0
 */
export type NullableOpeningValue = number | null;

export type OpeningScheduleActual = { gateId: string; timeseries: GateOpeningWithModeTimeseriesElem[] }[];

export type OpeningScheduleActualNullable = { gateId: string; timeseries: GateOpeningWithModeTimeseriesElemNullable[] }[];

export type OpeningSchedulePlan = { gateId: string; timeseries: OpeningTimeseriesElem[] }[];

export interface OpeningTimeseriesElem {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Gate opening in cm
   *
   * @min 0
   */
  value: OpeningValue;
}

/**
 * Gate opening in cm
 *
 * @min 0
 */
export type OpeningValue = number;

/**
 * The mode the control unit is currently in.
 * If omitted 'optimal' will be taken as the default.
 */
export type OperationMode = 'optimal' | 'manual' | 'off' | 'automatic';

/** Corresponding string values for the type @see OperationMode */
export const allOperationModes = ['optimal', 'manual', 'off', 'automatic'] as const;

/** @min 0 */
export type OverrideDeleteCountType = number;

export interface OverrideDeleteTyp {
  /** @min 0 */
  delete_count: OverrideDeleteCountType;
  warning?: Warning | undefined;
}

export interface PasswordResetPayload {
  new_password: string;
  password?: string | undefined;
  email?: Email | undefined;
  reset_token?: string | undefined;
}

export type PeriodicTaskList = {
  id: string;
  name: string;
  timezone: string;
  cronTabLine: string;
  isPaused: boolean;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  nextRunTimestamp?: Timestamp | undefined;
  argsDefaultValues?: DefaultTaskArguments[] | undefined;
}[];

export interface PermissionsListElem {
  unitType: UnitType;
  permissions: string[];
}

export interface PhysicalPlant {
  plant_views: { name?: string | undefined; external_id?: string | undefined }[];
  name: string;
  active: boolean;
  internal_id: string;
  avg_gps_latitude_decimal?: Latitude | undefined;
  avg_gps_longitude_decimal?: Longitude | undefined;
}

export interface PhysicalPlantList {
  /** @minItems 0 */
  plants?: PhysicalPlant[] | undefined;
}

export interface Plant {
  customer_id: string;
  name: string;
  short_name: string;
  country: string;
  price_region: string;
  sub_type: PlantSubType;
  customer_name?: string | undefined;
  active: boolean;
  local_time_zone: TimeZone;
  market_timezone: TimeZone;
  grid_granularity_minutes: GranularityMinutes;
  price_forecast_source_id: string;
  price_forecast_source_portfolio_id: string;
}

export interface PlantGeneration {
  id: string;
  name: string;
  /** @min 0 */
  generation_total_mw: GenerationTotalMW;
  generation_relative: GenerationRelative | null;
  /** @min 0 */
  p_max_total_mw: PMaxTotalMW;
  warnings: Warnings;
}

export type PlantGenerationList = PlantGeneration[];

export interface PlantGPSLocation {
  latitude?: Latitude | undefined;
  longitude?: Longitude | undefined;
}

export type PlantList = SimplePlantWithCurrency[];

export interface PlantNomination {
  day_ahead_nomination: MwGenerationValue | null;
}

export interface PlantPowerActual {
  actual?: MwGenerationValue | null | undefined;
}

export type PlantPowerActualAndPlanAndNomination = PlantPowerActualAndPlanAndPriceAndNominationElement[];

export type PlantPowerActualAndPlanAndPriceAndNominationElement = PlantPowerActual &
  PlantPowerPrice &
  PlantPowerDayAheadPlan &
  PlantNomination &
  GrossAndNetElement & {
    /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
    timestamp: CreationTimestamp;
  };

export interface PlantPowerDayAheadPlan {
  day_ahead_plan?: MwGenerationValue | null | undefined;
}

export interface PlantPowerPrice {
  day_ahead_price?: Price | null | undefined;
}

export type PlantSubType = 'Storage';

/** Corresponding string values for the type @see PlantSubType */
export const allPlantSubTypes = ['Storage'] as const;

export type PlantsWithMaintenances = PlantWithMaintenance[];

export interface PlantTopology {
  plant_id: string;
  topology: CompleteTopology;
}

export type PlantTurbineLosses = TurbineLossSetting[];

export interface PlantUpdate {
  name: string;
  country: string;
  price_region: string;
  active: boolean;
  local_time_zone: TimeZone;
  market_timezone: TimeZone;
  grid_granularity_minutes: GranularityMinutes;
  price_forecast_source_id: string;
  price_forecast_source_portfolio_id: string;
}

export interface PlantWithMaintenance {
  plant_id: string;
  maintenances: TurbineMaintenances;
}

/** @min 0 */
export type PMax = number;

/** @min 0 */
export type PMaxTotalMW = number;

export type PMin = number;

export interface Portfolio {
  id: string;
  name: string;
  is_default?: boolean | undefined;
  can_write?: boolean | undefined;
}

export type PortfolioList = Portfolio[];

export type PortfolioMaintenanceErrors = IdentifiedPortfolioMaintenanceError[];

export interface PortfolioMaintenanceResponse {
  plants: PlantsWithMaintenances | null;
  errors: PortfolioMaintenanceErrors | null;
}

export type PortfolioPowerOverview = PowerActualAndPlanElement[];

export interface PowerActualAndPlanElement {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  actual?: MwGenerationValue | null | undefined;
  plan?: MwGenerationValue | null | undefined;
}

export interface PowerElement {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  value?: MwGenerationValue | null | undefined;
}

export type PowerElementList = PowerElement[];

export interface PowerPlanAndActualAndBoundariesElem {
  actual?: MwGenerationValue | null | undefined;
  plan?: MwGenerationValue | null | undefined;
  pMin?: MwGenerationValue | null | undefined;
  pMax?: MwGenerationValue | null | undefined;
}

/**
 * @example
 * ```
 * [
 *   {
 *     "turbineId": "my-turbine-22",
 *     "timeseries": [
 *       {
 *         "timestamp": 1262304000000,
 *         "value": 44,
 *         "mode": "optimal"
 *       },
 *       {
 *         "timestamp": 1262305000000,
 *         "value": 20,
 *         "mode": "manual"
 *       },
 *       {
 *         "timestamp": 1262306000000,
 *         "value": 0
 *       }
 *     ]
 *   },
 *   {
 *     "turbineId": "my-turbine-23",
 *     "timeseries": [
 *       {
 *         "timestamp": 1262304000000,
 *         "value": 0,
 *         "mode": "off"
 *       },
 *       {
 *         "timestamp": 1262305000000,
 *         "value": 21,
 *         "mode": "automatic"
 *       },
 *       {
 *         "timestamp": 1262306000000,
 *         "value": 44
 *       }
 *     ]
 *   }
 * ]
 * ```
 */
export type PowerSchedule = {
  turbineId: string;
  /** @minItems 1 */
  timeseries: MWTimeseriesElem[];
}[];

/**
 * @example
 * ```
 * [
 *   {
 *     "turbineId": "my-turbine-22",
 *     "timeseries": [
 *       {
 *         "timestamp": 1262304000000,
 *         "value": 44,
 *         "mode": "optimal"
 *       },
 *       {
 *         "timestamp": 1262305000000,
 *         "value": 20,
 *         "mode": "manual"
 *       },
 *       {
 *         "timestamp": 1262306000000,
 *         "value": "None"
 *       }
 *     ]
 *   },
 *   {
 *     "turbineId": "my-turbine-23",
 *     "timeseries": [
 *       {
 *         "timestamp": 1262304000000,
 *         "value": 0,
 *         "mode": "off"
 *       },
 *       {
 *         "timestamp": 1262305000000,
 *         "value": 21,
 *         "mode": "automatic"
 *       },
 *       {
 *         "timestamp": 1262306000000,
 *         "value": "None"
 *       }
 *     ]
 *   }
 * ]
 * ```
 */
export type PowerScheduleNullable = {
  turbineId: string;
  /** @minItems 1 */
  timeseries: NullableMWTimeseriesElem[];
}[];

export interface PowerTurbineTimeseries {
  turbineId: string;
  timeseries: PowerElementList;
}

export type PowerTurbineTimeseriesList = PowerTurbineTimeseries[];

/** Electricity price (example EUR, GBP) */
export type Price = number;

export interface PriceForecast {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  value: PriceForecastQuoteValue;
}

export type PriceForecastQuoteValue = number;

export interface PriceForecastSource {
  market_id: string;
  portfolio_id: string;
  external_id: string;
  display_name: string;
}

export interface PriceForecastSubmission {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  market_observation_timestamp: CreationTimestamp;
  /** @minItems 2 */
  timeseries: PriceForecastSubmissionTimeseries;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  creation_timestamp?: CreationTimestamp | undefined;
}

/** @minItems 2 */
export type PriceForecastSubmissionTimeseries = PriceForecast[];

export type PriceForecastTimeseries = PriceForecast[];

export interface PricePlanPermissionsListElem {
  priceForecastSourceId: string;
  priceForecastSourceName: string;
  permissions: string[];
}

export interface PriceRegion {
  id: string;
  name: string;
}

export type PriceRegionList = PriceRegion[];

export type QMax = number;

export type QMin = number;

export interface RealizedDayAheadPriceElement {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  value: EURperMWhPriceValue | null;
  type: ReportType;
}

export type RealizedDayAheadPriceElementList = RealizedDayAheadPriceElement[];

export interface RealizedDayAheadPriceTurbineTimeseries {
  turbineId: string;
  timeseries: RealizedDayAheadPriceElementList;
}

export type RealizedDayAheadPriceTurbineTimeseriesList = RealizedDayAheadPriceTurbineTimeseries[];

export interface RecurringPattern {
  /**
   * @min 1
   * @max 12
   */
  month_of_year_local_start: MonthOfYearLocal;
  /**
   * @min 1
   * @max 12
   */
  month_of_year_local_end: MonthOfYearLocal;
  /**
   * @min 1
   * @max 31
   */
  day_of_year_local_start: DayOfYearLocal;
  /**
   * @min 1
   * @max 31
   */
  day_of_year_local_end: DayOfYearLocal;
  /**
   * @min 1
   * @max 28
   */
  day_of_month_local_start: DayOfMonthLocal;
  /**
   * @min 1
   * @max 28
   */
  day_of_month_local_end: DayOfMonthLocal;
  /**
   * @min 0
   * @max 23
   */
  hour_of_day_local_start: HourOfDayLocal;
  /**
   * @min 0
   * @max 23
   */
  hour_of_day_local_end: HourOfDayLocal;
  /**
   * @min 1
   * @max 7
   */
  day_of_week_local_start: DayOfWeekLocal;
  /**
   * @min 1
   * @max 7
   */
  day_of_week_local_end: DayOfWeekLocal;
}

export type ReportType = 'plan' | 'actual' | 'mixed';

/** Corresponding string values for the type @see ReportType */
export const allReportTypes = ['plan', 'actual', 'mixed'] as const;

export interface ReservoirContentActualAndPlanElement {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  actual?: ReservoirContentValue | null | undefined;
  plan?: ReservoirContentValue | null | undefined;
  maximum?: ReservoirContentValue | null | undefined;
  minimum?: ReservoirContentValue | null | undefined;
}

export type ReservoirContentHm3 = number;

export interface ReservoirContentSeries {
  reservoirId: string;
  timeseries: ReservoirContentActualAndPlanElement[];
}

/**
 * Reservoir content in (hm)^3
 *
 * @min -10
 * @max 2760000
 */
export type ReservoirContentValue = number;

export type ReservoirInflowPlans = { reservoirId: string; timeseries: InflowPlanTimeseriesElem[] }[];

export type ReservoirLevelActual = { reservoirId: string; timeseries: ReservoirLevelTimeseriesElem[] }[];

export type ReservoirLevelActualAndPlanElement = ReservoirLevelPlanAndBoundariesElement & ReservoirLevelActualElement;

export interface ReservoirLevelActualElement {
  actual?: ReservoirLevelValue | null | undefined;
}

export type ReservoirLevelMasl = number;

export type ReservoirLevelMaxMasl = number;

export type ReservoirLevelMinMasl = number;

/** @minItems 1 */
export type ReservoirLevelOverride = TimestampMaslTuple[];

export type ReservoirLevelOverrideInfo = TimestampMaslOverrideInfoTuple[];

export interface ReservoirLevelPlanAndBoundariesElement {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  plan?: ReservoirLevelValue | null | undefined;
  maximum?: ReservoirLevelValue | null | undefined;
  minimum?: ReservoirLevelValue | null | undefined;
}

export type ReservoirLevelSeries = ReservoirLevelActualAndPlanElement[];

export interface ReservoirLevelTimeseriesElem {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Reservoir level in metres above sea
   *
   * @min 0
   * @max 8000
   */
  value: MasLevelValue;
}

/**
 * Reservoir level in metres above sea
 *
 * @min 0
 * @max 8000
 */
export type ReservoirLevelValue = number;

export interface RevenueElement {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  actual: EuroAmount | null;
  plan: EuroAmount | null;
}

export type RevenueElementList = RevenueElement[];

export interface RevenueTurbineTimeseries {
  turbineId: string;
  timeseries: RevenueElementList;
}

export type RevenueTurbineTimeseriesList = RevenueTurbineTimeseries[];

/**
 * @example
 * ```
 * {
 *   "constraints": {
 *     "sortBy": "otherField",
 *     "sortOrder": "DESC"
 *   },
 *   "lastUsedVersion": "7.2253",
 *   "intSetting": 7,
 *   "floatSetting": 7.2253,
 *   "listSetting": [
 *     "seven data",
 *     "is seven data",
 *     "in seven settings"
 *   ]
 * }
 * ```
 */
export type SettingsObject = Record<string, AnySettingType>;

export type SettingStatusType = 'Draft' | 'Active' | 'Inactive';

/** Corresponding string values for the type @see SettingStatusType */
export const allSettingStatusTypes = ['Draft', 'Active', 'Inactive'] as const;

export type SettingStatusTypeNullable = 'Draft' | 'Active' | 'Inactive' | null;

/** Corresponding string values for the type @see SettingStatusTypeNullable */
export const allSettingStatusTypeNullables = ['Draft', 'Active', 'Inactive'] as const;

export interface SimpleControlUnit {
  feeds_from: string;
  spills_to: string;
  type: string;
  name: string | null;
  id?: string | undefined;
  redirect_id?: string | undefined;
}

export type SimpleControlUnitList = SimpleControlUnit[];

export interface SimpleCustomer {
  id: string;
  name: string;
}

export type SimpleCustomerList = SimpleCustomer[];

export interface SimpleHydroBody {
  name: string;
  type: string;
  id?: string | undefined;
  redirect_id?: string | undefined;
}

export type SimpleHydroBodyList = SimpleHydroBody[];

export interface SimplePlant {
  id: string;
  name: string;
  active: boolean;
}

export type SimplePlantList = SimplePlant[];

export interface SimplePlantWithCurrency {
  id: string;
  internal_id: string;
  name: string;
  timezone: TimeZone;
  location?: PlantGPSLocation | undefined;
  price_forecast_source_uuid?: string | undefined;
  currency?:
    | {
        shortName: string;
        symbol: string;
        description: string;
      }
    | undefined;
}

export interface SimplePlantWithInternalID {
  external_id: string;
  internal_id: string;
  name: string;
}

export type SimplePlantWithInternalIDList = SimplePlantWithInternalID[];

export type SpillwayLevelMasl = number;

export type SpillwayShapeParameter = number;

export type SpillwayWidthM = number;

export type StrategyAdjustmentFactor = number;

/**
 * @min -40
 * @max 40
 */
export type StrategyAdjustmentValue = number;

export type SubHourGranularities = 'half-hour';

/** Corresponding string values for the type @see SubHourGranularities */
export const allSubHourGranularities = ['half-hour'] as const;

export type TaskArguments = { name?: string | undefined; value?: string | undefined }[];

export type ThroughputCumec = number;

export interface ThroughputRatio {
  reservoir_level: ReservoirLevelMasl;
  throughput_cumec: ThroughputCumec;
  gate_opening: GateOpening;
}

export type ThroughputRatioList = ThroughputRatio[];

/** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
export type Timestamp = number;

export type TimestampChange = TimestampNullable[];

export interface TimestampDischargeOverrideInfoTuple {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Discharge in Cubic metre per second (cumec)
   *
   * @min 0
   */
  value?: DischargeValue | undefined;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  edit_timestamp: CreationTimestamp;
  override_by?: Email | undefined;
}

export interface TimestampDischargeTuple {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Discharge in Cubic metre per second (cumec)
   *
   * @min 0
   */
  value: DischargeValue;
}

export interface TimestampInflowOverrideInfoTuple {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /** Inflow in Cubic metre per second (cumec) */
  value?: InflowValue | undefined;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  edit_timestamp: CreationTimestamp;
  override_by?: Email | undefined;
}

export interface TimestampInflowTuple {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /** Inflow in Cubic metre per second (cumec) */
  value: InflowValue;
}

export interface TimestampInflowTupleNullable {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  value: InflowValue | null;
  type: ReportType;
}

export interface TimestampMaslOverrideInfoTuple {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Reservoir level in metres above sea
   *
   * @min 0
   * @max 8000
   */
  value?: ReservoirLevelValue | undefined;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  edit_timestamp: CreationTimestamp;
  override_by?: Email | undefined;
}

export interface TimestampMaslTuple {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Reservoir level in metres above sea
   *
   * @min 0
   * @max 8000
   */
  value: ReservoirLevelValue;
}

export interface TimestampMwOverrideInfoTuple {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Generation in MW
   *
   * @min 0
   */
  value?: MwGenerationValue | undefined;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  edit_timestamp: CreationTimestamp;
  override_by?: Email | undefined;
}

export interface TimestampMwTuple {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  /**
   * Generation in MW
   *
   * @min 0
   */
  value: MwGenerationValue;
}

/**
 * Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00)
 *
 * @example
 * ```
 * 1688389171000
 * ```
 */
export type TimestampNullable = number | null;

export type TimeZone =
  | 'Africa/Abidjan'
  | 'Africa/Accra'
  | 'Africa/Addis_Ababa'
  | 'Africa/Algiers'
  | 'Africa/Asmara'
  | 'Africa/Bamako'
  | 'Africa/Bangui'
  | 'Africa/Banjul'
  | 'Africa/Bissau'
  | 'Africa/Blantyre'
  | 'Africa/Brazzaville'
  | 'Africa/Bujumbura'
  | 'Africa/Cairo'
  | 'Africa/Casablanca'
  | 'Africa/Ceuta'
  | 'Africa/Conakry'
  | 'Africa/Dakar'
  | 'Africa/Dar_es_Salaam'
  | 'Africa/Djibouti'
  | 'Africa/Douala'
  | 'Africa/El_Aaiun'
  | 'Africa/Freetown'
  | 'Africa/Gaborone'
  | 'Africa/Harare'
  | 'Africa/Johannesburg'
  | 'Africa/Juba'
  | 'Africa/Kampala'
  | 'Africa/Khartoum'
  | 'Africa/Kigali'
  | 'Africa/Kinshasa'
  | 'Africa/Lagos'
  | 'Africa/Libreville'
  | 'Africa/Lome'
  | 'Africa/Luanda'
  | 'Africa/Lubumbashi'
  | 'Africa/Lusaka'
  | 'Africa/Malabo'
  | 'Africa/Maputo'
  | 'Africa/Maseru'
  | 'Africa/Mbabane'
  | 'Africa/Mogadishu'
  | 'Africa/Monrovia'
  | 'Africa/Nairobi'
  | 'Africa/Ndjamena'
  | 'Africa/Niamey'
  | 'Africa/Nouakchott'
  | 'Africa/Ouagadougou'
  | 'Africa/Porto-Novo'
  | 'Africa/Sao_Tome'
  | 'Africa/Tripoli'
  | 'Africa/Tunis'
  | 'Africa/Windhoek'
  | 'America/Adak'
  | 'America/Anchorage'
  | 'America/Anguilla'
  | 'America/Antigua'
  | 'America/Araguaina'
  | 'America/Argentina/Buenos_Aires'
  | 'America/Argentina/Catamarca'
  | 'America/Argentina/Cordoba'
  | 'America/Argentina/Jujuy'
  | 'America/Argentina/La_Rioja'
  | 'America/Argentina/Mendoza'
  | 'America/Argentina/Rio_Gallegos'
  | 'America/Argentina/Salta'
  | 'America/Argentina/San_Juan'
  | 'America/Argentina/San_Luis'
  | 'America/Argentina/Tucuman'
  | 'America/Argentina/Ushuaia'
  | 'America/Aruba'
  | 'America/Asuncion'
  | 'America/Atikokan'
  | 'America/Bahia'
  | 'America/Bahia_Banderas'
  | 'America/Barbados'
  | 'America/Belem'
  | 'America/Belize'
  | 'America/Blanc-Sablon'
  | 'America/Boa_Vista'
  | 'America/Bogota'
  | 'America/Boise'
  | 'America/Cambridge_Bay'
  | 'America/Campo_Grande'
  | 'America/Cancun'
  | 'America/Caracas'
  | 'America/Cayenne'
  | 'America/Cayman'
  | 'America/Chicago'
  | 'America/Chihuahua'
  | 'America/Costa_Rica'
  | 'America/Creston'
  | 'America/Cuiaba'
  | 'America/Curacao'
  | 'America/Danmarkshavn'
  | 'America/Dawson'
  | 'America/Dawson_Creek'
  | 'America/Denver'
  | 'America/Detroit'
  | 'America/Dominica'
  | 'America/Edmonton'
  | 'America/Eirunepe'
  | 'America/El_Salvador'
  | 'America/Fort_Nelson'
  | 'America/Fortaleza'
  | 'America/Glace_Bay'
  | 'America/Godthab'
  | 'America/Goose_Bay'
  | 'America/Grand_Turk'
  | 'America/Grenada'
  | 'America/Guadeloupe'
  | 'America/Guatemala'
  | 'America/Guayaquil'
  | 'America/Guyana'
  | 'America/Halifax'
  | 'America/Havana'
  | 'America/Hermosillo'
  | 'America/Indiana/Indianapolis'
  | 'America/Indiana/Knox'
  | 'America/Indiana/Marengo'
  | 'America/Indiana/Petersburg'
  | 'America/Indiana/Tell_City'
  | 'America/Indiana/Vevay'
  | 'America/Indiana/Vincennes'
  | 'America/Indiana/Winamac'
  | 'America/Inuvik'
  | 'America/Iqaluit'
  | 'America/Jamaica'
  | 'America/Juneau'
  | 'America/Kentucky/Louisville'
  | 'America/Kentucky/Monticello'
  | 'America/Kralendijk'
  | 'America/La_Paz'
  | 'America/Lima'
  | 'America/Los_Angeles'
  | 'America/Lower_Princes'
  | 'America/Maceio'
  | 'America/Managua'
  | 'America/Manaus'
  | 'America/Marigot'
  | 'America/Martinique'
  | 'America/Matamoros'
  | 'America/Mazatlan'
  | 'America/Menominee'
  | 'America/Merida'
  | 'America/Metlakatla'
  | 'America/Mexico_City'
  | 'America/Miquelon'
  | 'America/Moncton'
  | 'America/Monterrey'
  | 'America/Montevideo'
  | 'America/Montserrat'
  | 'America/Nassau'
  | 'America/New_York'
  | 'America/Nipigon'
  | 'America/Nome'
  | 'America/Noronha'
  | 'America/North_Dakota/Beulah'
  | 'America/North_Dakota/Center'
  | 'America/North_Dakota/New_Salem'
  | 'America/Ojinaga'
  | 'America/Panama'
  | 'America/Pangnirtung'
  | 'America/Paramaribo'
  | 'America/Phoenix'
  | 'America/Port-au-Prince'
  | 'America/Port_of_Spain'
  | 'America/Porto_Velho'
  | 'America/Puerto_Rico'
  | 'America/Punta_Arenas'
  | 'America/Rainy_River'
  | 'America/Rankin_Inlet'
  | 'America/Recife'
  | 'America/Regina'
  | 'America/Resolute'
  | 'America/Rio_Branco'
  | 'America/Santarem'
  | 'America/Santiago'
  | 'America/Santo_Domingo'
  | 'America/Sao_Paulo'
  | 'America/Scoresbysund'
  | 'America/Sitka'
  | 'America/St_Barthelemy'
  | 'America/St_Johns'
  | 'America/St_Kitts'
  | 'America/St_Lucia'
  | 'America/St_Thomas'
  | 'America/St_Vincent'
  | 'America/Swift_Current'
  | 'America/Tegucigalpa'
  | 'America/Thule'
  | 'America/Thunder_Bay'
  | 'America/Tijuana'
  | 'America/Toronto'
  | 'America/Tortola'
  | 'America/Vancouver'
  | 'America/Whitehorse'
  | 'America/Winnipeg'
  | 'America/Yakutat'
  | 'America/Yellowknife'
  | 'Asia/Aden'
  | 'Asia/Almaty'
  | 'Asia/Amman'
  | 'Asia/Anadyr'
  | 'Asia/Aqtau'
  | 'Asia/Aqtobe'
  | 'Asia/Ashgabat'
  | 'Asia/Atyrau'
  | 'Asia/Baghdad'
  | 'Asia/Bahrain'
  | 'Asia/Baku'
  | 'Asia/Bangkok'
  | 'Asia/Barnaul'
  | 'Asia/Beirut'
  | 'Asia/Bishkek'
  | 'Asia/Brunei'
  | 'Asia/Chita'
  | 'Asia/Choibalsan'
  | 'Asia/Colombo'
  | 'Asia/Damascus'
  | 'Asia/Dhaka'
  | 'Asia/Dili'
  | 'Asia/Dubai'
  | 'Asia/Dushanbe'
  | 'Asia/Famagusta'
  | 'Asia/Gaza'
  | 'Asia/Hebron'
  | 'Asia/Ho_Chi_Minh'
  | 'Asia/Hong_Kong'
  | 'Asia/Hovd'
  | 'Asia/Irkutsk'
  | 'Asia/Jakarta'
  | 'Asia/Jayapura'
  | 'Asia/Jerusalem'
  | 'Asia/Kabul'
  | 'Asia/Kamchatka'
  | 'Asia/Karachi'
  | 'Asia/Kathmandu'
  | 'Asia/Khandyga'
  | 'Asia/Kolkata'
  | 'Asia/Krasnoyarsk'
  | 'Asia/Kuala_Lumpur'
  | 'Asia/Kuching'
  | 'Asia/Kuwait'
  | 'Asia/Macau'
  | 'Asia/Magadan'
  | 'Asia/Makassar'
  | 'Asia/Manila'
  | 'Asia/Muscat'
  | 'Asia/Nicosia'
  | 'Asia/Novokuznetsk'
  | 'Asia/Novosibirsk'
  | 'Asia/Omsk'
  | 'Asia/Oral'
  | 'Asia/Phnom_Penh'
  | 'Asia/Pontianak'
  | 'Asia/Pyongyang'
  | 'Asia/Qatar'
  | 'Asia/Qostanay'
  | 'Asia/Qyzylorda'
  | 'Asia/Riyadh'
  | 'Asia/Sakhalin'
  | 'Asia/Samarkand'
  | 'Asia/Seoul'
  | 'Asia/Shanghai'
  | 'Asia/Singapore'
  | 'Asia/Srednekolymsk'
  | 'Asia/Taipei'
  | 'Asia/Tashkent'
  | 'Asia/Tbilisi'
  | 'Asia/Tehran'
  | 'Asia/Thimphu'
  | 'Asia/Tokyo'
  | 'Asia/Tomsk'
  | 'Asia/Ulaanbaatar'
  | 'Asia/Urumqi'
  | 'Asia/Ust-Nera'
  | 'Asia/Vientiane'
  | 'Asia/Vladivostok'
  | 'Asia/Yakutsk'
  | 'Asia/Yangon'
  | 'Asia/Yekaterinburg'
  | 'Asia/Yerevan'
  | 'Europe/Amsterdam'
  | 'Europe/Andorra'
  | 'Europe/Astrakhan'
  | 'Europe/Athens'
  | 'Europe/Belgrade'
  | 'Europe/Berlin'
  | 'Europe/Bratislava'
  | 'Europe/Brussels'
  | 'Europe/Bucharest'
  | 'Europe/Budapest'
  | 'Europe/Busingen'
  | 'Europe/Chisinau'
  | 'Europe/Copenhagen'
  | 'Europe/Dublin'
  | 'Europe/Gibraltar'
  | 'Europe/Guernsey'
  | 'Europe/Helsinki'
  | 'Europe/Isle_of_Man'
  | 'Europe/Istanbul'
  | 'Europe/Jersey'
  | 'Europe/Kaliningrad'
  | 'Europe/Kiev'
  | 'Europe/Kirov'
  | 'Europe/Lisbon'
  | 'Europe/Ljubljana'
  | 'Europe/London'
  | 'Europe/Luxembourg'
  | 'Europe/Madrid'
  | 'Europe/Malta'
  | 'Europe/Mariehamn'
  | 'Europe/Minsk'
  | 'Europe/Monaco'
  | 'Europe/Moscow'
  | 'Europe/Oslo'
  | 'Europe/Paris'
  | 'Europe/Podgorica'
  | 'Europe/Prague'
  | 'Europe/Riga'
  | 'Europe/Rome'
  | 'Europe/Samara'
  | 'Europe/San_Marino'
  | 'Europe/Sarajevo'
  | 'Europe/Saratov'
  | 'Europe/Simferopol'
  | 'Europe/Skopje'
  | 'Europe/Sofia'
  | 'Europe/Stockholm'
  | 'Europe/Tallinn'
  | 'Europe/Tirane'
  | 'Europe/Ulyanovsk'
  | 'Europe/Uzhgorod'
  | 'Europe/Vaduz'
  | 'Europe/Vatican'
  | 'Europe/Vienna'
  | 'Europe/Vilnius'
  | 'Europe/Volgograd'
  | 'Europe/Warsaw'
  | 'Europe/Zagreb'
  | 'Europe/Zaporozhye'
  | 'Europe/Zurich';

/** Corresponding string values for the type @see TimeZone */
export const allTimeZones = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Fort_Nelson',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Colombo',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kathmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Asia/Riyadh',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ulaanbaatar',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich'
] as const;

export type TimeZoneList = TimeZone[];

export interface TokenInfo {
  email_address?: Email | undefined;
  name?: string | undefined;
}

export interface Topology {
  hydro_bodies: SimpleHydroBodyList;
  control_units: SimpleControlUnitList;
}

export type TopologyHydroBody = SimpleHydroBody & {
  level_min?: ReservoirLevelMinMasl | null | undefined;
  level_max?: ReservoirLevelMaxMasl | null | undefined;
  max_content?: ReservoirContentHm3 | null | undefined;
};

export type TopologyHydroBodyList = TopologyHydroBody[];

export type TopologyHydroBodyListWithSpillway = TopologyHydroBodyWithSpillway[];

export type TopologyHydroBodyWithSpillway = SimpleHydroBody & {
  level_min?: ReservoirLevelMinMasl | null | undefined;
  level_max?: ReservoirLevelMaxMasl | null | undefined;
  max_content?: ReservoirContentHm3 | null | undefined;
  spillway_level_masl?: SpillwayLevelMasl | null | undefined;
};

/** @min 0 */
export type TravelTimeMinutes = number;

export interface TurbineLossSetting {
  turbine_id: string;
  losses: TurbineLossTimeseriesElem[];
}

export interface TurbineLossTimeseriesElem {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  value: TurbineLossValue;
}

export type TurbineLossValue = number;

export type TurbineMaintenance = TurbineMaintenanceSubmission & {
  uuid: string;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  creation_timestamp_millis: CreationTimestamp;
  turbine_name: string;
  /** @min 0 */
  turbine_p_max: PMax;
  plant_id: string;
  turbine_id: string;
  created_by_name: string;
  created_by_email: Email;
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  last_modified_timestamp_millis: CreationTimestamp;
  last_modified_by_name: string;
  last_modified_by_email: Email;
  label?: string | undefined;
};

export type TurbineMaintenanceHistory = TurbineMaintenanceHistoryEvent[];

export interface TurbineMaintenanceHistoryEvent {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  change_timestamp_millis: CreationTimestamp;
  email: Email;
  username: string;
  changeset: Changeset;
}

export type TurbineMaintenances = TurbineMaintenance[];

export type TurbineMaintenanceSubmission = MaintenanceSubmission & {
  /** @min 0 */
  remaining_power: PMax;
};

export type TurbinePowerActualAndPlan = TurbinePowerActualAndPlanAndPriceElement[];

export interface TurbinePowerActualAndPlanAndPriceElement {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  gross?: PowerPlanAndActualAndBoundariesElem | undefined;
  net?: PowerPlanAndActualAndBoundariesElem | undefined;
  price?: Price | null | undefined;
  day_ahead_plan?: MwGenerationValue | null | undefined;
}

/** @minItems 1 */
export type TurbinePowerOverride = TimestampMwTuple[];

export type TurbinePowerOverrideInfo = TimestampMwOverrideInfoTuple[];

export type TurbineWaterValues = { turbine_id: string; timeseries: WaterValueTimeseriesReturnType }[];

export type UnitType = 'TURBINE' | 'RESERVOIR' | 'GATE' | 'MARKET';

/** Corresponding string values for the type @see UnitType */
export const allUnitTypes = ['TURBINE', 'RESERVOIR', 'GATE', 'MARKET'] as const;

/** @minItems 4 */
export type UpdateThroughputRatioList = ThroughputRatio[];

export interface UserEmail {
  email?: Email | undefined;
}

export interface Warning {
  id: string;
  value?: string | undefined;
}

export type Warnings = Warning[];

export type WaterValue = number;

export type WaterValueTimeseriesReturnType = {
  /** Unix timestamp in milliseconds, should be >= 1262304000000 (2010-01-01 00:00:00) */
  timestamp: CreationTimestamp;
  value: WaterValue;
}[];
