import { api } from '../InsightApi';
import { defineQuery } from '../query-utils/defineQuery';

export const plantSettings = defineQuery(({ plantId }: { plantId: string }) => ({
  queryKey: ['plant', plantId, 'plantSettings'],
  queryFn: ({ signal }) => api.dashboard.plant.requestAnalyticsParameters({ plant_id: plantId, signal })
}));

export const nominationConfiguration = defineQuery(({ plantId }: { plantId: string }) => ({
  queryKey: ['plant', plantId, 'nominationConfiguration'],
  queryFn: ({ signal }) => api.dashboard.plant.getNominationConfiguration({ plant_id: plantId, signal })
}));

export const plantBasicSettings = defineQuery(({ plantId }: { plantId: string }) => ({
  queryKey: ['portfolio', 'plant', plantId, 'BasicSettings'],
  queryFn: ({ signal }) => api.dashboard.plant.getBasicArrangement({ plant_id: plantId, signal })
}));

// export const allPriceRegions = defineQuery(() => ({
//   queryKey: ['allPriceRegions'],
//   queryFn: () => api.internal.dashboard.setup.getAllPriceRegions()
// }));

// export const allPriceForecastSources = defineQuery(() => ({
//   queryKey: ['allPriceForecastSources'],
//   queryFn: () => api.internal.dashboard.priceForecastSource.getAllPriceForecastSources()
// }));

export const getHydrobodyInfo = defineQuery(({ hydro_body_id }: { hydro_body_id: string }) => ({
  queryKey: ['hydrobody', hydro_body_id],
  queryFn: ({ signal }) => api.dashboard.hydroBody.getHydroBody({ hydro_body_id, signal })
}));

export const getControlUnitInfo = defineQuery(({ control_unit_id }: { control_unit_id: string }) => ({
  queryKey: ['hydrobody', control_unit_id],
  queryFn: ({ signal }) => api.dashboard.controlUnit.getControlUnit({ control_unit_id, signal })
}));

export const hydrobodyDetailsInternal = defineQuery(({ hydroBodyId }: { hydroBodyId: string }) => ({
  queryKey: ['hydrobodyDetails', hydroBodyId],
  queryFn: ({ signal }) => api.internal.dashboard.setup.getHydroBody({ hydro_body_id: hydroBodyId, signal })
}));

export const getLevelToContent = defineQuery(({ hydroBodyId }: { hydroBodyId: string }) => ({
  queryKey: ['levelToContent', hydroBodyId],
  queryFn: ({ signal }) => api.dashboard.reservoir.getLevelToContent({ hydro_body_id: hydroBodyId, signal })
}));
