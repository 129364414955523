import { useShallowMemo } from '@hydrogrid/utilities/memoization';
import type { PartialOrUndefined, Simplify } from '@hydrogrid/utilities/typescript';
import type { StandardProperties, SvgProperties } from 'csstype';
import { createContext, useContext, useInsertionEffect, useMemo, useRef } from 'react';
import { useHash } from '../hashing/useHash';

class StyleSheetManager {
  /** A map of css-string -> how often is it in use **/
  usage = new Map<string, number>();

  /** A map of css-string -> styleSheet **/
  sheets = new Map<string, CSSStyleSheet>();
}

const StyleSheetManagerContext = createContext(new StyleSheetManager());

export type StyleableProps = StandardProperties & SvgProperties;

type StyleableCssVars = {
  [key: `--${string}`]: string | number | null | undefined;
};

type StyleablePropName = keyof (StandardProperties & SvgProperties);

/** List of all CSS properties */
const cssProperties: StyleablePropName[] = [
  'accentColor',
  'alignContent',
  'alignItems',
  'alignmentBaseline',
  'alignSelf',
  'alignTracks',
  'all',
  'animation',
  'animationDelay',
  'animationDirection',
  'animationDuration',
  'animationFillMode',
  'animationIterationCount',
  'animationName',
  'animationPlayState',
  'animationTimingFunction',
  'appearance',
  'aspectRatio',
  'backdropFilter',
  'backfaceVisibility',
  'background',
  'backgroundAttachment',
  'backgroundBlendMode',
  'backgroundClip',
  'backgroundColor',
  'backgroundImage',
  'backgroundOrigin',
  'backgroundPosition',
  'backgroundPositionX',
  'backgroundPositionY',
  'backgroundRepeat',
  'backgroundSize',
  'baselineShift',
  'blockOverflow',
  'blockSize',
  'border',
  'borderBlock',
  'borderBlockColor',
  'borderBlockEnd',
  'borderBlockEndColor',
  'borderBlockEndStyle',
  'borderBlockEndWidth',
  'borderBlockStart',
  'borderBlockStartColor',
  'borderBlockStartStyle',
  'borderBlockStartWidth',
  'borderBlockStyle',
  'borderBlockWidth',
  'borderBottom',
  'borderBottomColor',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
  'borderBottomStyle',
  'borderBottomWidth',
  'borderCollapse',
  'borderColor',
  'borderEndEndRadius',
  'borderEndStartRadius',
  'borderImage',
  'borderImageOutset',
  'borderImageRepeat',
  'borderImageSlice',
  'borderImageSource',
  'borderImageWidth',
  'borderInline',
  'borderInlineColor',
  'borderInlineEnd',
  'borderInlineEndColor',
  'borderInlineEndStyle',
  'borderInlineEndWidth',
  'borderInlineStart',
  'borderInlineStartColor',
  'borderInlineStartStyle',
  'borderInlineStartWidth',
  'borderInlineStyle',
  'borderInlineWidth',
  'borderLeft',
  'borderLeftColor',
  'borderLeftStyle',
  'borderLeftWidth',
  'borderRadius',
  'borderRight',
  'borderRightColor',
  'borderRightStyle',
  'borderRightWidth',
  'borderSpacing',
  'borderStartEndRadius',
  'borderStartStartRadius',
  'borderStyle',
  'borderTop',
  'borderTopColor',
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderTopStyle',
  'borderTopWidth',
  'borderWidth',
  'bottom',
  'boxDecorationBreak',
  'boxShadow',
  'boxSizing',
  'breakAfter',
  'breakBefore',
  'breakInside',
  'captionSide',
  'caretColor',
  'clear',
  'clip',
  'clipPath',
  'clipRule',
  'color',
  'colorAdjust',
  'colorInterpolation',
  'colorRendering',
  'colorScheme',
  'columnCount',
  'columnFill',
  'columnGap',
  'columnRule',
  'columnRuleColor',
  'columnRuleStyle',
  'columnRuleWidth',
  'columns',
  'columnSpan',
  'columnWidth',
  'contain',
  'content',
  'contentVisibility',
  'counterIncrement',
  'counterReset',
  'counterSet',
  'cursor',
  'direction',
  'display',
  'dominantBaseline',
  'emptyCells',
  'fill',
  'fillOpacity',
  'fillRule',
  'filter',
  'flex',
  'flexBasis',
  'flexDirection',
  'flexFlow',
  'flexGrow',
  'flexShrink',
  'flexWrap',
  'float',
  'floodColor',
  'floodOpacity',
  'font',
  'fontFamily',
  'fontFeatureSettings',
  'fontKerning',
  'fontLanguageOverride',
  'fontOpticalSizing',
  'fontSize',
  'fontSizeAdjust',
  'fontSmooth',
  'fontStretch',
  'fontStyle',
  'fontSynthesis',
  'fontVariant',
  'fontVariantCaps',
  'fontVariantEastAsian',
  'fontVariantLigatures',
  'fontVariantNumeric',
  'fontVariantPosition',
  'fontVariationSettings',
  'fontWeight',
  'forcedColorAdjust',
  'gap',
  'glyphOrientationVertical',
  'grid',
  'gridArea',
  'gridAutoColumns',
  'gridAutoFlow',
  'gridAutoRows',
  'gridColumn',
  'gridColumnEnd',
  'gridColumnStart',
  'gridRow',
  'gridRowEnd',
  'gridRowStart',
  'gridTemplate',
  'gridTemplateAreas',
  'gridTemplateColumns',
  'gridTemplateRows',
  'hangingPunctuation',
  'height',
  'hyphens',
  'imageOrientation',
  'imageRendering',
  'imageResolution',
  'initialLetter',
  'inlineSize',
  'inset',
  'insetBlock',
  'insetBlockEnd',
  'insetBlockStart',
  'insetInline',
  'insetInlineEnd',
  'insetInlineStart',
  'isolation',
  'justifyContent',
  'justifyItems',
  'justifySelf',
  'justifyTracks',
  'left',
  'letterSpacing',
  'lightingColor',
  'lineBreak',
  'lineClamp',
  'lineHeight',
  'lineHeightStep',
  'listStyle',
  'listStyleImage',
  'listStylePosition',
  'listStyleType',
  'margin',
  'marginBlock',
  'marginBlockEnd',
  'marginBlockStart',
  'marginBottom',
  'marginInline',
  'marginInlineEnd',
  'marginInlineStart',
  'marginLeft',
  'marginRight',
  'marginTop',
  'marker',
  'markerEnd',
  'markerMid',
  'markerStart',
  'mask',
  'maskBorder',
  'maskBorderMode',
  'maskBorderOutset',
  'maskBorderRepeat',
  'maskBorderSlice',
  'maskBorderSource',
  'maskBorderWidth',
  'maskClip',
  'maskComposite',
  'maskImage',
  'maskMode',
  'maskOrigin',
  'maskPosition',
  'maskRepeat',
  'maskSize',
  'maskType',
  'mathStyle',
  'maxBlockSize',
  'maxHeight',
  'maxInlineSize',
  'maxLines',
  'maxWidth',
  'minBlockSize',
  'minHeight',
  'minInlineSize',
  'minWidth',
  'mixBlendMode',
  'motion',
  'motionDistance',
  'motionPath',
  'motionRotation',
  'objectFit',
  'objectPosition',
  'offset',
  'offsetAnchor',
  'offsetDistance',
  'offsetPath',
  'offsetRotate',
  'offsetRotation',
  'opacity',
  'order',
  'orphans',
  'outline',
  'outlineColor',
  'outlineOffset',
  'outlineStyle',
  'outlineWidth',
  'overflow',
  'overflowAnchor',
  'overflowBlock',
  'overflowClipBox',
  'overflowClipMargin',
  'overflowInline',
  'overflowWrap',
  'overflowX',
  'overflowY',
  'overscrollBehavior',
  'overscrollBehaviorBlock',
  'overscrollBehaviorInline',
  'overscrollBehaviorX',
  'overscrollBehaviorY',
  'padding',
  'paddingBlock',
  'paddingBlockEnd',
  'paddingBlockStart',
  'paddingBottom',
  'paddingInline',
  'paddingInlineEnd',
  'paddingInlineStart',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'pageBreakAfter',
  'pageBreakBefore',
  'pageBreakInside',
  'paintOrder',
  'perspective',
  'perspectiveOrigin',
  'placeContent',
  'placeItems',
  'placeSelf',
  'pointerEvents',
  'position',
  'quotes',
  'resize',
  'right',
  'rotate',
  'rowGap',
  'rubyAlign',
  'rubyMerge',
  'rubyPosition',
  'scale',
  'scrollbarColor',
  'scrollbarGutter',
  'scrollbarWidth',
  'scrollBehavior',
  'scrollMargin',
  'scrollMarginBlock',
  'scrollMarginBlockEnd',
  'scrollMarginBlockStart',
  'scrollMarginBottom',
  'scrollMarginInline',
  'scrollMarginInlineEnd',
  'scrollMarginInlineStart',
  'scrollMarginLeft',
  'scrollMarginRight',
  'scrollMarginTop',
  'scrollPadding',
  'scrollPaddingBlock',
  'scrollPaddingBlockEnd',
  'scrollPaddingBlockStart',
  'scrollPaddingBottom',
  'scrollPaddingInline',
  'scrollPaddingInlineEnd',
  'scrollPaddingInlineStart',
  'scrollPaddingLeft',
  'scrollPaddingRight',
  'scrollPaddingTop',
  'scrollSnapAlign',
  'scrollSnapMargin',
  'scrollSnapMarginBottom',
  'scrollSnapMarginLeft',
  'scrollSnapMarginRight',
  'scrollSnapMarginTop',
  'scrollSnapStop',
  'scrollSnapType',
  'shapeImageThreshold',
  'shapeMargin',
  'shapeOutside',
  'shapeRendering',
  'stopColor',
  'stopOpacity',
  'stroke',
  'strokeDasharray',
  'strokeDashoffset',
  'strokeLinecap',
  'strokeLinejoin',
  'strokeMiterlimit',
  'strokeOpacity',
  'strokeWidth',
  'tableLayout',
  'tabSize',
  'textAlign',
  'textAlignLast',
  'textAnchor',
  'textCombineUpright',
  'textDecoration',
  'textDecorationColor',
  'textDecorationLine',
  'textDecorationSkip',
  'textDecorationSkipInk',
  'textDecorationStyle',
  'textDecorationThickness',
  'textEmphasis',
  'textEmphasisColor',
  'textEmphasisPosition',
  'textEmphasisStyle',
  'textIndent',
  'textJustify',
  'textOrientation',
  'textOverflow',
  'textRendering',
  'textShadow',
  'textSizeAdjust',
  'textTransform',
  'textUnderlineOffset',
  'textUnderlinePosition',
  'top',
  'touchAction',
  'transform',
  'transformBox',
  'transformOrigin',
  'transformStyle',
  'transition',
  'transitionDelay',
  'transitionDuration',
  'transitionProperty',
  'transitionTimingFunction',
  'translate',
  'unicodeBidi',
  'userSelect',
  'vectorEffect',
  'verticalAlign',
  'visibility',
  'whiteSpace',
  'widows',
  'width',
  'willChange',
  'wordBreak',
  'wordSpacing',
  'wordWrap',
  'writingMode',
  'zIndex',
  'zoom'
];

export type StyleableSizePropName = Extract<
  StyleablePropName,
  | 'blockSize'
  | 'boxSizing'
  | 'height'
  | 'inlineSize'
  | 'maxBlockSize'
  | 'maxHeight'
  | 'maxInlineSize'
  | 'maxWidth'
  | 'minBlockSize'
  | 'minHeight'
  | 'minInlineSize'
  | 'minWidth'
  | 'width'
>;

export type StyleableLayoutPropName = Extract<
  StyleablePropName,
  | 'alignContent'
  | 'alignItems'
  | 'alignSelf'
  | 'blockSize'
  | 'boxSizing'
  | 'flex'
  | 'flexBasis'
  | 'flexGrow'
  | 'flexShrink'
  | 'gap'
  | 'gridArea'
  | 'gridColumn'
  | 'gridColumnEnd'
  | 'gridColumnStart'
  | 'gridRow'
  | 'gridRowEnd'
  | 'gridRowStart'
  | 'height'
  | 'inlineSize'
  | 'inset'
  | 'insetBlock'
  | 'insetBlockEnd'
  | 'insetBlockStart'
  | 'insetInline'
  | 'insetInlineEnd'
  | 'insetInlineStart'
  | 'justifySelf'
  | 'left'
  | 'margin'
  | 'marginBlock'
  | 'marginBlockEnd'
  | 'marginBlockStart'
  | 'marginBottom'
  | 'marginInline'
  | 'marginInlineEnd'
  | 'marginInlineStart'
  | 'marginLeft'
  | 'marginRight'
  | 'marginTop'
  | 'maxBlockSize'
  | 'maxHeight'
  | 'maxInlineSize'
  | 'maxWidth'
  | 'minBlockSize'
  | 'minHeight'
  | 'minInlineSize'
  | 'minWidth'
  | 'order'
  | 'padding'
  | 'paddingBlock'
  | 'paddingBlockEnd'
  | 'paddingBlockStart'
  | 'paddingBottom'
  | 'paddingInline'
  | 'paddingInlineEnd'
  | 'paddingInlineStart'
  | 'paddingLeft'
  | 'paddingRight'
  | 'paddingTop'
  | 'placeSelf'
  | 'right'
  | 'top'
  | 'width'
>;

const cssPropNameSet: ReadonlySet<StyleablePropName> = new Set(cssProperties);
const isCssProperty = (name: string): name is StyleablePropName => cssPropNameSet.has(name as StyleablePropName) || name.startsWith('--');

// Explicitly enable when you want to debug CSS rules added via useStyleRule/useScopedStyles/useThemeableStyles.
// This will show the generated CSS rules as inspectable text inside <style> elements in the document head,
// but due to its performance cost it is disabled by default.
const debugStyles = true as boolean;

/**
 * Allow to use CSS rules which might change depending on props.
 * Ensures that every identical stylesheet is only appended once.
 *
 * @example
 * ```tsx
 * const { border, color, fontFamily, ...otherProps } = props;
 * const className = useStyleRule('.someClass { display: 'flex' }', 'MyComponent');
 * return <div className={className}>Hello, Mister {otherProps.lastName}!</div>;
 * ```
 **/
export function useStyleRule(rule: string, namespace = 'useStyleSheet') {
  const { sheets, usage } = useContext(StyleSheetManagerContext);
  const componentStackRef = useRef<string>();

  // In development, store component stack to add it as data-* attribute
  if (import.meta.env.DEV && !componentStackRef.current) {
    componentStackRef.current = new Error().stack;
  }

  useInsertionEffect(() => {
    // Add stylesheet for the css if there is none attached
    if (!sheets.has(rule)) {
      const styleElement = document.createElement('style');
      styleElement.dataset.styleNamespace = namespace;

      // During development, add stack as data attribute to the style element
      if (import.meta.env.DEV) {
        const stackStr = componentStackRef.current ?? '';
        const stackFrames = [
          ...stackStr.matchAll(
            /^ {4}at (?:(\w.+?) \(((.+?)(?::\d+(?::\d+)?)?)\)|((https?[^\n?]*\/([^./?:]+)[^/?:]*(?:\?[^:]*))(?::\d+(?::\d+)?)?))$/gm
          )
        ].map(groups => {
          const name = groups[1] ?? groups[6];
          const location = groups[2] ?? groups[4];
          const url = groups[3] ?? groups[5];
          return { name, location, url };
        });

        const componentStack = stackFrames
          .filter(({ url }) => !url.includes('/node_modules/'))
          .slice(1)
          .reverse()
          .map(frame => frame.name)
          .join(' > ');

        styleElement.dataset.stack = componentStack;

        if (debugStyles) {
          const sourceLocation = stackFrames.find(({ name }) => !name.startsWith('use'))?.url?.replace(/\?.*$|:\d+:\d+$/, '');
          styleElement.textContent = `${rule}\n/*# sourceURL=${sourceLocation ?? stackFrames[0]?.name} */`;
        }
      }

      document.head.appendChild(styleElement);
      const styleSheet = styleElement.sheet;
      if (styleSheet) {
        sheets.set(rule, styleSheet);
        if (!import.meta.env.DEV || !debugStyles) {
          styleSheet.insertRule(rule);
        }
      } else {
        console.error(`useStyles: Inserted style element has no styleSheet`);
      }
    }

    // Count the usage of the className
    usage.set(rule, usage.get(rule) ?? 0 + 1);

    return () => {
      const otherInstancesUsingSameRule = usage.get(rule) ?? 0 - 1;
      usage.set(rule, otherInstancesUsingSameRule);

      if (otherInstancesUsingSameRule > 0) {
        return;
      }

      setTimeout(() => {
        // If no components use the classname after rerendering, remove the <style>.
        const usageAfterRendering = Number(usage.get(rule));
        const sheet = sheets.get(rule);
        if (usageAfterRendering <= 0 && sheet) {
          sheet.ownerNode?.remove();
          sheets.delete(rule);
          usage.delete(rule);
        }
      });
    };
  }, [namespace, rule, sheets, usage]);
}

function useScopedClassName(namespace: string, styles: Record<string, unknown>) {
  const hash = useHash(styles);
  return `${namespace}-${hash}`;
}

/**
 * Allow to use namespaced classnames for styles that depend on props.
 *
 * @example
 * ```tsx
 * const { border, color, fontFamily, ...otherProps } = props;
 * const className = useScopedStyles('MyComponent', { border, color, fontFamily });
 * return <div className={className}>Hello, Mister {otherProps.lastName}!</div>;
 * ```
 **/
export function useScopedStyles(namespace: string, styleProps: StyleableProps & StyleableCssVars): string {
  // Extract CSS props from might-be-more-than-just-css-props.
  const [styles] = pickStyleableProps(styleProps);
  const stylesMemoized = useShallowMemo(() => styles, [styles]);

  const scopedClassName = useScopedClassName(namespace, stylesMemoized);
  const rule = useMemo(() => formatCssPropsAsRule(`.${scopedClassName}`, stylesMemoized), [scopedClassName, stylesMemoized]);

  // Add/update the CSS rule in the <head>
  useStyleRule(rule, namespace);

  return scopedClassName;
}

/**
 * Pick styleable CSS props from a props object.
 *
 * @example
 * ```ts
 * const props = { color: 'red', fontSize: '3em', age: 50, lastName: 'Musk' };
 * const [styleableProps, otherProps] = pickStyleableProps(props);
 * console.log(styleableProps); // -> { color: 'red', fontSize: '3em' };
 * console.log(otherProps); // -> { age: 50, lastName: 'Musk' };
 * ```
 **/
export function pickStyleableProps<T extends Partial<StyleableProps>>(
  props: T
): [styleProps: Partial<StyleableProps>, otherProps: Omit<T, keyof StyleableProps>] {
  const styleProps: Record<string, unknown> = {};
  const otherProps: Record<string, unknown> = {};

  for (const [key, value] of Object.entries(props)) {
    if (value !== undefined && isCssProperty(key)) {
      styleProps[key] = value;
    } else {
      otherProps[key] = value;
    }
  }

  return [styleProps, otherProps] as [
    styleProps: Simplify<Pick<Partial<StyleableProps>, keyof T & keyof StyleableProps>>,
    otherProps: Simplify<Omit<T, keyof StyleableProps>>
  ];
}

/**
 * Format a hash of camelCased css properties to a CSS rule.
 *
 * @example
 * ```ts
 * const rule = formatCssPropsAsRule('.example', {
 *   textDecorationStyle: 'underline',
 *   WebkitFlexGrow: 1,
 *   '--myVariable': 'red'
 * });
 * // rule is now
 * `.example {
 *    text-decoration-style: underline;
 *    -webkit-flex-grow: 1,
 *    --myVariable: red,
 * }`;
 * ```
 **/
export function formatCssPropsAsRule(selector: string, props: StyleableProps | PartialOrUndefined<StyleableProps & StyleableCssVars>) {
  const styles: string[] = [];

  for (const [camelCasedKey, value] of Object.entries(props)) {
    if (value === undefined) continue;

    const hyphenatedKey = camelCasedKey.startsWith('--')
      ? camelCasedKey
      : camelCasedKey.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`);

    styles.push(`  ${hyphenatedKey}:${String(value)};`);
  }

  // TODO: add support for breakpoints

  if (!styles.length) {
    return `${selector} { }`;
  } else {
    return [`${selector} {`, ...styles, `}`].join('\n');
  }
}
