import { accordionAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleContainer = defineStyle({
  borderTopWidth: '1px',
  borderColor: 'inherit',
  _last: {
    borderBottomWidth: '1px'
  }
});

const baseStyleButton = defineStyle({
  transitionProperty: 'common',
  transitionDuration: 'normal',
  fontSize: 'md',
  _focusVisible: {
    boxShadow: 'outline'
  },
  _hover: {
    bg: 'blackAlpha.50'
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed'
  },
  p: '2',
  h: '10'
});

const baseStylePanel = defineStyle({
  p: '2',
  pb: '5'
});

const baseStyleIcon = defineStyle({
  fontSize: '1.25em'
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon
});

const variantOutline = definePartsStyle({});

const variantSolid = definePartsStyle({
  container: {
    borderWidth: 0,
    _last: {
      borderWidth: 0
    }
  },
  button: {
    bg: 'secondary.50',
    rounded: 'base',
    fontWeight: 'bold',
    _hover: {
      bg: 'secondary.100'
    }
  }
});

const variants = {
  outline: variantOutline,
  solid: variantSolid,
  unstyled: {
    button: {
      h: 'auto',
      p: 0,
      rounded: 'base',
      fontWeight: 'bold',
      _hover: {
        bg: 'transparent'
      }
    }
  }
};

export const accordionTheme = defineMultiStyleConfig({ baseStyle, variants, defaultProps: { variant: 'outline' } });
