import { Box, Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { HorizontalStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VerticalStack } from '@hydrogrid/design-system';
import { guessNameFromEmail } from '@hydrogrid/utilities/string';
import { useCallback, useState } from 'react';
import { useAuthStore } from '../../common/stores/AuthStore';
import { ChangePasswordForm } from '../../components/ChangePasswordForm/ChangePasswordForm';
import { UserAvatar } from '../UserAvatar/UserAvatar';

interface SettingsModalProps {
  visible: boolean;
  onChangeVisible: (visible: boolean) => void;
}

export function SettingsModal({ visible, onChangeVisible }: SettingsModalProps) {
  const close = useCallback(() => onChangeVisible(false), [onChangeVisible]);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const { email, fullName } = useAuthStore(state => ({ email: state.email, fullName: state.fullName }));

  return (
    <Modal isOpen={visible} isCentered onClose={close} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent boxShadow="none" bg="none" border="none" w="auto" maxW="calc(100vh - 2rem)">
        <Box minW="40rem" minH="27rem" bg="page" rounded="base" py={1}>
          <Tabs isLazy lazyBehavior="unmount">
            <TabList>
              <Tab isDisabled={isChangingPassword}>Settings</Tab>
              <Tab>Change password</Tab>
            </TabList>
            <ModalCloseButton top={0} right={1} />
            <TabPanels>
              <TabPanel>
                <HorizontalStack align="flex-start" spacing="1rem" padding="1rem 0.5rem">
                  {email && <UserAvatar email={email} name={fullName} size={60} />}
                  <VerticalStack justify="center" spacing="2rem">
                    <VerticalStack justify="center" spacing="0">
                      <Text>{fullName ?? (email && guessNameFromEmail(email))}</Text>
                      <Text>{email}</Text>
                    </VerticalStack>
                    <div>
                      {'You can '}
                      <a href="https://www.gravatar.com" target="_blank" rel="nofollower noreferrer">
                        upload & choose your profile picture on gravatar.com
                      </a>
                    </div>
                  </VerticalStack>
                </HorizontalStack>
              </TabPanel>
              <TabPanel>
                <ChangePasswordForm onSending={setIsChangingPassword} onSuccess={close} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </ModalContent>
    </Modal>
  );
}
