import { useLocaleFormat } from '@hydrogrid/design-system';
import { useEffect, useMemo, useState } from 'react';

interface UseCountdownOptions {
  /**
   * Show the countdown only when it is below n seconds.
   * This only affects the returned {@link Countdown.text `text`} property.
   */
  below?: number;
  /**
   * Don't show the countdown after it reaches 0:00.
   * This only affects the returned {@link Countdown.text `text`} property.
   *
   * @default `false`
   */
  hideExpired?: boolean;
}

export interface Countdown {
  /** Remaining seconds until `when`. */
  remaining: number | null;
  /** The countdown as "mm:ss"/"m:ss" text in localized format. */
  text: string | null;
  /**
   * - `true` if the instant given as `when` is in the past,
   * - `false` when the instant is in the future or `null`.
   */
  expired: boolean;
}

/** Get the seconds until a point in time as 'minutes:seconds' string. */
export function useCountdown(
  when: Date | string | number | null | undefined,
  { below, hideExpired = false }: UseCountdownOptions = {}
): Countdown {
  const localeFormat = useLocaleFormat();

  const [remainingSeconds, setRemainingSeconds] = useState<number | null>(() => {
    if (when == null) return null;
    const then = new Date(when).getTime();
    const now = Date.now();
    return then < now ? null : Math.round(then - now);
  });

  // Update the countdown every second
  useEffect(() => {
    if (when == null) return setRemainingSeconds(null);

    const then = new Date(when).getTime();
    const now = Date.now();
    if (then < now) return setRemainingSeconds(hideExpired ? null : 0);

    let timeout = Number.NaN;

    const update = () => {
      const now = Date.now();
      if (then < now) {
        setRemainingSeconds(hideExpired ? null : 0);
      } else {
        setRemainingSeconds(Math.round((then - now) / 1000));
        const remainingMillisecondsToNextSecond = 1000 - (now % 1000);
        const earliestTimeToShow = below != null ? then - now - below * 1000 : 0;
        timeout = window.setTimeout(update, Math.max(remainingMillisecondsToNextSecond, earliestTimeToShow));
      }
    };
    update();

    return () => window.clearTimeout(timeout);
  }, [below, hideExpired, when]);

  const formatted = useMemo(() => {
    if (remainingSeconds == null || (below != null && remainingSeconds > below)) {
      return null;
    }

    return localeFormat.minutesSeconds(remainingSeconds);
  }, [below, localeFormat, remainingSeconds]);

  const countdown = useMemo(
    () => ({
      remaining: remainingSeconds,
      expired: remainingSeconds != null && remainingSeconds <= 0,
      text: formatted
    }),
    [formatted, remainingSeconds]
  );

  return countdown;
}
