import { Flex, UnorderedList } from '@chakra-ui/react';
import { forwardRef, type ReactNode } from 'react';

interface NavigationProps {
  children: ReactNode;
  direction?: 'horizontal' | 'vertical';
}

export const Navigation = forwardRef<HTMLUListElement, NavigationProps>((props, ref) => {
  return (
    <Flex maxH="100%">
      <UnorderedList
        ref={ref}
        margin={0}
        display="flex"
        flexDir={['row', 'column']}
        justifyContent={['center', 'flex-start']}
        h={[undefined as never, 'fit-content']}
      >
        {props.children}
      </UnorderedList>
    </Flex>
  );
});
