import { createStrictContext } from '@hydrogrid/utilities/react';
import type { PropsWithChildren } from 'react';
import { TableStoreProvider } from './TableStore';

interface TableContextValue {
  rowCount: number;
  columnTemplate: string;
  isHeaderSticky: boolean;
  isFirstColumnSticky: boolean;
  isFooterSticky: boolean;
}
const [useTableContext, TableContextProvider] = createStrictContext<TableContextValue>('TableStoreContext');
export { useTableContext };

export function TableProviders({ contextValue, children }: PropsWithChildren<{ contextValue: TableContextValue }>) {
  return (
    <TableContextProvider value={contextValue}>
      <TableStoreProvider>{children}</TableStoreProvider>
    </TableContextProvider>
  );
}
