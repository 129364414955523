import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgWarningCircle = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M256 0C114.5 0 0 114.5 0 256s114.5 256 256 256 256-114.5 256-256S397.5 0 256 0zm0 472c-119.4 0-216-96.61-216-216 0-119.4 96.61-216 216-216 119.4 0 216 96.61 216 216 0 119.4-96.62 216-216 216z" }),
  /* @__PURE__ */ jsx("path", { d: "M256 128.88a20 20 0 0 0-20 20v128.79a20 20 0 1 0 40 0v-128.8a20 20 0 0 0-20-20z" }),
  /* @__PURE__ */ jsx("circle", { cx: 256, cy: 349.16, r: 27 })
] });
const ForwardRef = forwardRef(SvgWarningCircle);
export default ForwardRef;
