import { createPopper, type Instance, type Modifier, type Placement, type VirtualElement } from '@popperjs/core';
import { useCallback, useEffect, useRef, type Ref, type RefCallback } from 'react';
import { combineRefs } from '../../utils/useCombinedRefs';
import { popperCssVars } from './popper-css-vars';
import * as customModifiers from './popper-modifiers';

/**
 * @file "inspired" / "borrowed" from
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/popper.
 *
 * But with better generic types & less redundancy.
 */

/** Common properties for a Popper.js instance */
export interface UsePopperProps {
  enabled?: boolean;

  /** Main and cross-axis offset to displace popper element from its reference element. */
  offset?: [number, number];

  /**
   * Distance or margin between the reference and popper.
   * It is used internally to create an `offset` modifier.
   *
   * Defining an `offset` will override the gutter.
   * @default 8
   */
  gutter?: number;

  /**
   * If `true`, will prevent the popper from being cut off and ensure
   * it's visible within the boundary area.
   * @default true
   */
  preventOverflow?: boolean;

  /**
   * If `true`, the popper will change its placement and flip when it's
   * about to overflow its boundary area.
   * @default true
   */
  flip?: boolean;

  /**
   * If `true`, the popper will match the width of the reference at all times.
   * It's useful for `autocomplete`, `date-picker` and `select` patterns.
   * @default false
   */
  matchWidth?: boolean;

  /**
   * The boundary area for the popper. Used within the `preventOverflow` modifier
   * @default "clippingParents"
   */
  boundary?: 'clippingParents' | 'scrollParent' | HTMLElement;

  /**
   * If provided, determines whether the popper will reposition itself on `scroll`
   * and `resize` of the window.
   * @default true
   */
  eventListeners?: boolean | { scroll?: boolean; resize?: boolean };

  /**
   * The padding required to prevent the arrow from
   * reaching the very edge of the popper.
   * @default 8
   */
  arrowPadding?: number;

  /**
   * The CSS positioning strategy to use.
   * @default "absolute"
   */
  strategy?: 'absolute' | 'fixed';

  /**
   * The placement of the popper relative to its reference.
   * @default "bottom"
   */
  placement?: Placement;

  /**
   * Array of popper.js modifiers. Check the docs to see
   * the list of possible modifiers you can pass.
   *
   * @see Docs https://popper.js.org/docs/v2/modifiers/
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modifiers?: Partial<Modifier<string, Record<string, any>>>[];
}

type ArrowCSSVarProps = {
  /** Size of the popover arrow (`--popper-arrow-size`). */
  size?: string | number;

  /** The box-shadow color of the popover arrow (`--popper-arrow-shadow-color`). */
  shadowColor?: string;

  /** Background color of the popper arrow (`--popper-arrow-background-color`). */
  backgroundColor?: string;
};

export function usePopper(props: UsePopperProps = {}) {
  const {
    enabled = true,
    modifiers,
    placement = 'bottom',
    strategy = 'absolute',
    arrowPadding = 8,
    eventListeners = true,
    offset,
    gutter = 8,
    flip = true,
    boundary = 'clippingParents',
    preventOverflow = true,
    matchWidth = false
  } = props;

  const reference = useRef<Element | VirtualElement | null>(null);
  const popper = useRef<HTMLElement | null>(null);
  const instance = useRef<Instance | null>(null);

  const cleanup = useRef(() => {});

  const setupPopper = useCallback(() => {
    if (!enabled || !reference.current || !popper.current) return;

    cleanup.current?.();

    instance.current = createPopper(reference.current, popper.current, {
      placement,
      modifiers: [
        customModifiers.innerArrow,
        customModifiers.positionArrow,
        customModifiers.transformOrigin,
        {
          ...customModifiers.matchWidth,
          enabled: matchWidth
        },
        {
          name: 'eventListeners',
          ...getEventListenerOptions(eventListeners)
        },
        {
          name: 'arrow',
          options: { padding: arrowPadding }
        },
        {
          name: 'offset',
          options: {
            offset: offset ?? [0, gutter]
          }
        },
        {
          name: 'flip',
          enabled: flip,
          options: { padding: 8 }
        },
        {
          name: 'preventOverflow',
          enabled: preventOverflow,
          options: { boundary }
        },
        // Allow to override Popper.js-internal modifiers
        ...(modifiers ?? [])
      ],
      strategy
    });

    // Force-update one time to fix any positioning issues
    instance.current.forceUpdate();

    cleanup.current = instance.current.destroy;
  }, [placement, enabled, modifiers, matchWidth, eventListeners, arrowPadding, offset, gutter, flip, preventOverflow, boundary, strategy]);

  useEffect(() => {
    return () => {
      // Fix for vite live reload potentially breaking components when this reference still exists
      if (!reference.current && !popper.current) {
        instance.current?.destroy();
        instance.current = null;
      }
    };
  }, []);

  const referenceRef = useCallback(
    <T extends Element | VirtualElement>(node: T | null) => {
      reference.current = node;
      setupPopper();
    },
    [setupPopper]
  );

  const getReferenceProps = useCallback(
    <P, E extends Element | VirtualElement>(props: P = {} as P, ref: Ref<E> | null = null) => ({
      ...props,
      ref: combineRefs(referenceRef, ref)
    }),
    [referenceRef]
  );

  const popperRef = useCallback(
    <E extends HTMLElement>(node: E | null) => {
      popper.current = node;
      setupPopper();
    },
    [setupPopper]
  );

  const getPopperProps = useCallback(
    <P extends { style?: React.CSSProperties }, E extends Element | VirtualElement>(props: P = {} as P, ref: Ref<E> | null = null) => ({
      ...props,
      ref: combineRefs(popperRef as RefCallback<E>, ref),
      style: {
        ...props.style,
        position: strategy,
        minWidth: matchWidth ? undefined : 'max-content',
        inset: '0 auto auto 0'
      }
    }),
    [strategy, popperRef, matchWidth]
  );

  const getArrowProps = useCallback(
    <P extends Partial<ArrowCSSVarProps & { style: React.CSSProperties }>, E extends Element = Element>(
      props: P = {} as P,
      ref: Ref<E> | null = null
    ) => {
      const { size: _1, shadowColor: _2, backgroundColor: _3, style: _4, ...rest } = props;
      return {
        ...rest,
        ref,
        'data-popper-arrow': '',
        style: getArrowStyle(props)
      };
    },
    []
  );

  const getArrowInnerProps = useCallback(
    <P, E extends Element = Element>(props: P = {} as P, ref: Ref<E> | null = null) => ({
      ...props,
      ref,
      'data-popper-arrow-inner': ''
    }),
    []
  );

  const update = useCallback(() => void instance.current?.update(), []);
  const forceUpdate = useCallback(() => instance.current?.forceUpdate(), []);

  return {
    update,
    forceUpdate,
    transformOrigin: popperCssVars.transformOrigin.varRef,

    /** Element the popper is positioned relative to, e.g. a popover trigger. */
    referenceRef,

    /** The popper element, e.g. popover contents. */
    popperRef,

    getPopperProps,
    getArrowProps,
    getArrowInnerProps,
    getReferenceProps
  };
}

function getArrowStyle<P extends Partial<ArrowCSSVarProps & { style: React.CSSProperties }>>(props: P) {
  const { size, shadowColor, backgroundColor, style } = props;
  const computedStyle = { ...style, position: 'absolute' } as React.CSSProperties & Record<string, string | number>;
  if (size !== undefined) {
    computedStyle[popperCssVars.arrowSize.var] = size;
  }
  if (shadowColor !== undefined) {
    computedStyle[popperCssVars.arrowShadowColor.var] = shadowColor;
  }
  if (backgroundColor !== undefined) {
    computedStyle[popperCssVars.arrowBackgroundColor.var] = backgroundColor;
  }
  return computedStyle;
}

const defaultEventListeners = {
  scroll: true,
  resize: true
};

function getEventListenerOptions(eventListeners: boolean | { scroll?: boolean; resize?: boolean }) {
  if (typeof eventListeners === 'object') {
    return {
      enabled: true,
      options: { ...defaultEventListeners, ...eventListeners }
    };
  }

  return {
    enabled: eventListeners,
    options: defaultEventListeners
  };
}
