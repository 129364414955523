import { createLocalStore } from '@hydrogrid/utilities/stores';
import { combine } from 'zustand/middleware';

const defaultState = {
  activeShadows: {
    left: false,
    right: false,
    top: false,
    bottom: false
  }
};
type StoreState = typeof defaultState;

export const [useTableStore, TableStoreProvider] = createLocalStore('TableStore', () =>
  combine(defaultState, set => ({
    actions: {
      setActiveShadows: (shadows: Partial<StoreState['activeShadows']>) =>
        set(state => ({ activeShadows: { ...state.activeShadows, ...shadows } }))
    }
  }))
);
