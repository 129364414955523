import { useCallback, useState } from 'react';

interface UseControlledFormFieldArgs<T> {
  initialValue?: never;
  value: T;
  onChange: (newValue: T) => void;
  onFocus?: () => void;
}

interface UseUncontrolledFormFieldArgs<T> {
  initialValue: T;
  value?: never;
  onChange?: (newValue: T) => void;
  onFocus?: () => void;
}

type UseFormFieldProps<T> = UseControlledFormFieldArgs<T> | UseUncontrolledFormFieldArgs<T>;

/**
 * Keeps state for a form field, including "touched" & "dirty" state.
 */
export function useFormField<T>({ value: controlledValue, initialValue, onChange, onFocus }: UseFormFieldProps<T>) {
  const [uncontrolledValue, setUncontrolledValue] = useState(controlledValue ?? initialValue);
  const [touched, setTouched] = useState(false);
  const [dirty, setDirty] = useState(false);

  const value = (controlledValue ?? uncontrolledValue) as T;

  const handleFocus = useCallback(() => {
    setTouched(true);
    onFocus?.();
  }, [onFocus]);

  const handleChange = useCallback(
    (newValue: T) => {
      setTouched(true);
      setDirty(true);
      setUncontrolledValue(newValue);
      onChange?.(newValue);
    },
    [onChange]
  );

  return { value, touched, dirty, handleFocus, handleChange };
}
