import { dateFromISOCalendarWeek, type DataRange } from '@hydrogrid/design-system';

// Type inferred by Api.dashboard.power.requestPlantPowerAndPrice.
// Accepted granularities might be different for other endpoints - not sure.
export type BackendRangeParameters = {
  'start-ts': number;
  'end-ts': number;
  granularity?: 'half-hour' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';
};

/**
 * Get the query parameters to send to the backend for a given data range in a specific time zone.
 *
 * Intended to be used in react-query custom hooks.
 * @param withGranularity @default true
 */
export function dataRangeToBackendParameters(range: DataRange, timeZone: string): BackendRangeParameters {
  if (!timeZone) {
    throw new Error(`dataRangeToBackendParameters: No timeZone was passed.`);
  }
  const { start, end } = dataRangeAsIntervalTimestamps(range, timeZone);

  const backendParams: BackendRangeParameters = {
    'start-ts': start,
    'end-ts': end
  };

  if (range.granularity !== 'raw') {
    backendParams.granularity = range.granularity;
  }

  return backendParams;
}

/**
 * Return a {@link DataRange} as `[start, end)` interval in unix timestamps for a given timezone.
 *
 * Note: The returned interval is an "start-including, end-excluding" interval, meaning e.g. data
 * returned by the backend for the interval should have timestamps `start <= timestamp < end`.
 */
export function dataRangeAsIntervalTimestamps(range: DataRange, timeZone: string) {
  switch (range.granularity) {
    case 'year':
      return {
        start: Temporal.ZonedDateTime.from({ year: range.start.year, month: 1, day: 1, timeZone: timeZone }).epochMilliseconds,
        end: Temporal.ZonedDateTime.from({ year: range.end.year, month: 1, day: 1, timeZone: timeZone }).add({ years: 1 }).epochMilliseconds
      };

    case 'quarter':
      return {
        start: Temporal.ZonedDateTime.from({ year: range.start.year, month: range.start.quarter * 3 - 2, day: 1, timeZone: timeZone })
          .epochMilliseconds,
        end: Temporal.ZonedDateTime.from({ year: range.end.year, month: range.end.quarter * 3 + 1, day: 1, timeZone: timeZone })
          .epochMilliseconds
      };

    case 'month':
      return {
        start: Temporal.ZonedDateTime.from({ year: range.start.year, month: range.start.month, day: 1, timeZone: timeZone })
          .epochMilliseconds,
        end: Temporal.ZonedDateTime.from({ year: range.end.year, month: range.end.month, day: 1, timeZone: timeZone }).add({ months: 1 })
          .epochMilliseconds
      };

    case 'week': {
      const startDate = Temporal.PlainDate.from(
        dateFromISOCalendarWeek({
          yearOfWeek: range.start.year,
          weekOfYear: range.start.week,
          weekday: 1
        })
      );

      const endDate = Temporal.PlainDate.from(
        dateFromISOCalendarWeek({
          yearOfWeek: range.end.year,
          weekOfYear: range.end.week,
          weekday: 7
        })
      ).add({ days: 1 });

      return {
        start: startDate.toZonedDateTime(timeZone).epochMilliseconds,
        end: endDate.toZonedDateTime(timeZone).epochMilliseconds
      };
    }

    case 'day':
    case 'hour':
    case 'half-hour':
    case 'raw': {
      return {
        start: Temporal.ZonedDateTime.from({
          year: range.start.year,
          month: range.start.month,
          day: range.start.day,
          timeZone: timeZone
        }).epochMilliseconds,

        end: Temporal.ZonedDateTime.from({
          year: range.end.year,
          month: range.end.month,
          day: range.end.day,
          timeZone: timeZone
        }).add({ days: 1 }).epochMilliseconds
      };
    }

    default: {
      const unhandledCase: never = 'granularity' in range ? (range as { granularity: never }).granularity : range;
      throw new Error(`Invalid granularity "${String(unhandledCase)}" in data range.`);
    }
  }
}
