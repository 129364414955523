import { Button as ChakraButton, type ButtonProps as ChakraButtonProps, type ThemeTypings } from '@chakra-ui/react';
import { forwardRef } from 'react';

export interface ButtonProps extends Omit<ChakraButtonProps, 'colorScheme'> {
  colorScheme?: ThemeTypings['colorSchemes'] | 'white';
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button({ colorScheme, ...props }, ref) {
  return <ChakraButton ref={ref} {...props} {...(colorScheme ? { colorScheme: colorScheme as ThemeTypings['colorSchemes'] } : {})} />;
});
