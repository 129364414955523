import { useCallback, useEffect, useRef } from 'react';

interface UseTypeaheadOptions {
  /**
   * After this delay in ms, typing starts a new sequence.
   *
   * @default 300
   */
  timeout?: number;

  handler: (textSoFar: string) => void;

  /** @default () => true */
  preventDefault?: (event: React.KeyboardEvent) => boolean;
}

/**
 * Hook to allow selecting a menu item by typing the first characters.
 *
 * @usage
 * ```tsx
 * const typeahead = useTypeahead({
 *   handler: (textSoFar) => {
 *     findItemStartingWith(textSoFar)?.focus();
 *   }
 * });
 *
 * const onKeyDown = (event: React.KeyboardEvent) => {
 *   if (shouldBeHandledByThisComponent(event)) {
 *     return handle(event);
 *   }
 *
 *   typeahead(event);
 * };
 * ```
 */
export function useTypeahead(options: UseTypeaheadOptions) {
  const { timeout = 300, handler, preventDefault } = options;

  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  const typedKeys = useRef<string[]>([]);
  const cleanup = useRef(() => {});

  const clearKeysAfterDelay = useCallback(() => {
    cleanup.current();

    const handle = setTimeout(() => {
      typedKeys.current = [];
    }, timeout);

    cleanup.current = () => clearTimeout(handle);
  }, [timeout]);

  useEffect(() => {
    return () => cleanup.current();
  }, []);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Backspace') {
        typedKeys.current.pop();
        return;
      }

      if (isPrintableCharacter(event)) {
        typedKeys.current.push(event.key);

        if (!preventDefault || preventDefault(event)) {
          event.preventDefault();
          event.stopPropagation();
        }

        handlerRef.current(typedKeys.current.join(''));

        clearKeysAfterDelay();
      }
    },
    [clearKeysAfterDelay, preventDefault]
  );

  return onKeyDown;
}

function isPrintableCharacter(event: React.KeyboardEvent) {
  const { key } = event;
  return key.length === 1 || (key.length > 1 && /[^a-zA-Z0-9]/.test(key));
}
