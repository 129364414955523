import { useRef, type DependencyList } from 'react';
import { shallowEqual } from './shallowEqual';

/**
 * Like useMemo, but checks for shallow equality.
 *
 * @example
 * ```tsx
 * const [draft, setDraft] = useState({ name: 'example' });
 *
 * const hash = useShallowMemo(() => {
 *   return calculateHash(draft);
 * }, [draft]);
 *
 * // Will not rerun calculateHash
 * setDraft({ name: 'example' });
 * ```
 *
 * ---
 *
 * @example
 * ```tsx
 * const [letters, setLetters] = useState(['a', 'b', 'd', 'c']);
 *
 * const sorted = useShallowMemo(() => {
 *   return [...letters].sort();
 * }, [letters]);
 *
 * // Will not rerun Array.sort, as the array is shallow-equal
 * setLetters(['a', 'b', 'd', 'c']);
 *
 * // Will rerun Array.sort, but return the previous array
 * setLetters(['a', 'c', 'd', 'b']);
 *
 * // Will rerun Array.sort and return a new array
 * setLetters(['e', 'f', 'd', 'c']);
 * ```
 */
export function useShallowMemo<T>(factory: () => T, deps: DependencyList) {
  const cache = useRef<{ deps: DependencyList; result: T }>();

  const cached = cache.current;

  // If all input dependencies are shallow-equal, return the previous output.
  if (cached && cached.deps.length === deps.length && cached.deps.every((oldValue, index) => shallowEqual(oldValue, deps[index]))) {
    return cached.result;
  }

  // If the output is shallow-equal to the previous output, return the previous output.
  const result = factory();
  if (cached && shallowEqual(cached.result, result)) {
    cache.current = { deps, result: cached.result };
    return cached.result;
  }

  cache.current = { deps, result };
  return result;
}
