import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgTurbine = (props, ref) => /* @__PURE__ */ jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: 40, height: 40, fill: "none", viewBox: "0 0 40 40", "aria-label": "Turbine", ref, ...props, children: /* @__PURE__ */ jsxs("g", { fill: "currentColor", children: [
  /* @__PURE__ */ jsx("path", { fill: "#fff", d: "M20 39.44A19.44 19.44 0 1 0 20 .56a19.44 19.44 0 0 0 0 38.88Z" }),
  /* @__PURE__ */ jsx("path", { d: "M20 1.11a18.89 18.89 0 1 1 0 37.78A18.89 18.89 0 0 1 20 1.1ZM20 0a20 20 0 1 0 0 40 20 20 0 0 0 0-40Z" }),
  /* @__PURE__ */ jsx("path", { d: "M26.61 18.5c-.04-.2-.1-.38-.15-.57a5.17 5.17 0 0 0 3-8.7l-.41-.4-5.44 5.43c-.16-.1-.33-.2-.5-.28a5.19 5.19 0 0 0-4.03-8.28h-.58v7.69c-.2.04-.38.1-.57.15a5.17 5.17 0 0 0-8.7-3l-.4.41 5.43 5.44c-.1.16-.2.33-.28.5a5.18 5.18 0 0 0-8.28 4.03v.58h7.69c.04.2.1.38.15.57a5.2 5.2 0 0 0-4.1 7.04c.25.62.63 1.19 1.1 1.66l.41.4 5.44-5.43.5.29a5.18 5.18 0 0 0 4.03 8.27h.58v-7.69c.2-.04.38-.1.56-.15a5.17 5.17 0 0 0 5.1 4.47h.05a4.99 4.99 0 0 0 3.56-1.47l.4-.41-5.43-5.44.29-.5a5.19 5.19 0 0 0 8.27-4.03v-.58h-7.69ZM20 25.62a5.62 5.62 0 1 1 0-11.24 5.62 5.62 0 0 1 0 11.24Zm9.02-15.12a4.03 4.03 0 0 1-3.03 6.32 6.79 6.79 0 0 0-1.44-1.85l4.47-4.47Zm-9.36-3.6a3.97 3.97 0 0 1 3.32 3.96c0 .98-.35 1.92-1 2.66-.64-.2-1.3-.3-1.98-.3h-.34V6.92ZM10.5 11a4.03 4.03 0 0 1 6.32 3.03c-.7.37-1.33.86-1.85 1.44L10.5 11Zm-3.6 9.35a3.97 3.97 0 0 1 3.96-3.32c.98 0 1.92.35 2.66 1a6.75 6.75 0 0 0-.3 2.32H6.92ZM11 29.51c-.5-.68-.76-1.5-.74-2.34a4.04 4.04 0 0 1 3.77-3.98c.37.7.86 1.32 1.44 1.84L11 29.51Zm9.36 3.59a3.97 3.97 0 0 1-3.33-3.95c0-.98.36-1.93 1-2.66.64.2 1.31.3 1.98.3.11 0 .23 0 .34-.02v6.33Zm9.16-4.07c-.68.5-1.5.76-2.34.74a4.05 4.05 0 0 1-3.98-3.78c.7-.37 1.32-.86 1.84-1.44l4.48 4.48Zm-.36-6.04c-.98 0-1.92-.35-2.66-1 .2-.64.3-1.32.3-1.99 0-.11 0-.23-.02-.34h6.32a3.97 3.97 0 0 1-3.94 3.32V23Z" }),
  /* @__PURE__ */ jsx("path", { d: "M16.84 20a3.16 3.16 0 1 0 6.32 0 3.16 3.16 0 0 0-6.32 0ZM22 20a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" })
] }) });
const ForwardRef = forwardRef(SvgTurbine);
export default ForwardRef;
