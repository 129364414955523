import { createContext, useContext, type PropsWithChildren } from 'react';
import type { DataRange } from '../../time/DataRange';
import type { ChartDataKey } from './useChartState';

export interface RechartsContextValue {
  timeZone: string;
  range: DataRange;
  dataKeys: { [key: string]: ChartDataKey | undefined };
}

const RechartsContext = createContext<RechartsContextValue | null>(null);

/** @internal */
export function ProvideRechartsContext({ children, ...contextValue }: PropsWithChildren<RechartsContextValue>) {
  return <RechartsContext.Provider value={contextValue}>{children}</RechartsContext.Provider>;
}

/** @internal */
export function useRechartsContext() {
  const contextValue = useContext(RechartsContext);
  if (!contextValue) {
    throw new Error(`You need to render this component inside a Chart.`);
  }
  return contextValue;
}
