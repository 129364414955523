import { capitalize } from './capitalize';

export function guessNameFromEmail(email: string) {
  const addressPart = email.replace(/@.+$/, '');
  if (/^\p{L}{1,2}$/u.test(addressPart)) {
    return addressPart.toUpperCase();
  }

  return capitalize(addressPart.replace(/((?!-)\P{L}+)/gu, ' '));
}
