import { forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, type ReactNode } from 'react';
import { Color } from '../../css/Color';
import { pickStyleableProps, useScopedStyles, type StyleableProps } from '../../css/useStyles';
import { useElementSize } from '../../dom/useElementSize';
import type { ThemeColor } from '../../theme/Theme';
import { useTheme } from '../../theme/ThemeProvider';
import { classNames } from '../../utils/classNames';
import { useCombinedRefs } from '../../utils/useCombinedRefs';
import { useOnWindowResize } from '../../utils/useOnWindowResize';
import { useSynchronizedScrolling } from '../SynchronizeScrolling/SynchronizeScrolling';
import styles from './ScrollPanel.module.css';
import { getOverlayScrollbarSize } from './getOverlayScrollbarSize';

interface ScrollPanelProps extends StyleableProps {
  /** Axis for which to allow scrolling & show scrollbars. */
  axis?: 'horizontal' | 'vertical' | 'both';

  children?:
    | ReactNode
    | ((params: { topOverflow: boolean; bottomOverflow: boolean; leftOverflow: boolean; rightOverflow: boolean }) => ReactNode);
  areaClassName?: string | undefined;
  className?: string | undefined;

  /**
   * `true` to prevent scrolling.
   */
  disabled?: boolean;

  /**
   * Amount of milliseconds to wait to hide the scrollbar.
   * @default 150
   */
  fadeOutDelay?: number;

  /** Synchronize scroll panel with a sibling @see SynchronizeScroll */
  synchronize?: boolean;

  /**
   * Set to `true` to resize the scroll panel with the size of the contained children.
   * You can still pass `minHeight`/`maxHeight`/`minWidth`/`maxHeight` to limit the size.
   * @default false
   */
  fitContent?: boolean;

  /** @default "secondary" */
  shadowColor?: ThemeColor | (string & {});

  /** @default 0.2 */
  shadowOpacity?: number;

  /**
   * Whether to show/hide the scroll shadows.
   * @default true
   */
  showShadows?: boolean;

  /**
   * Whether to show/hide the left shadow.
   * @default `showShadows`
   */
  showLeftShadow?: boolean;

  /**
   * Whether to show/hide the right shadow.
   * @default `showShadows`
   */
  showRightShadow?: boolean;

  /**
   * Whether to show/hide the top shadow.
   * @default `showShadows`
   */
  showTopShadow?: boolean;

  /**
   * Whether to show/hide the bottom shadow.
   * @default `showShadows`
   */
  showBottomShadow?: boolean;

  /** @default 1 */
  shadowZIndex?: number;

  /** Offset the scroll shadow to the right/left. */
  shadowOffsetX?: string | number | undefined;

  /** Offset the left-hand scroll shadow to the right. */
  shadowOffsetLeft?: string | number | undefined;

  /** Offset the right-hand scroll shadow to the left. */
  shadowOffsetRight?: string | number | undefined;

  /** Offset the scroll shadow up/down. */
  shadowOffsetY?: string | number | undefined;

  /** Offset the top scroll shadow down. */
  shadowOffsetTop?: string | number | undefined;

  /** Offset the bottom scroll shadow up. */
  shadowOffsetBottom?: string | number | undefined;

  /** Function executed when scrolling */
  onScroll?: (() => void) | undefined;

  hideScrollbar?: boolean;
}

/** A scrollable panel that shows overlay shadows when content is clipped. */
export const ScrollPanel = forwardRef<HTMLDivElement, ScrollPanelProps>(
  (
    {
      areaClassName,
      axis = 'vertical',
      children,
      className,
      disabled = false,
      fadeOutDelay = 150,
      fitContent = false,
      synchronize = false,
      shadowColor = 'secondary',
      shadowOpacity = 0.2,
      showShadows = true,
      showLeftShadow = showShadows,
      showRightShadow = showShadows,
      showTopShadow = showShadows,
      showBottomShadow = showShadows,
      shadowZIndex,
      shadowOffsetX,
      shadowOffsetY,
      shadowOffsetLeft = shadowOffsetX,
      shadowOffsetRight = shadowOffsetX,
      shadowOffsetTop = shadowOffsetY,
      shadowOffsetBottom = shadowOffsetY,
      onScroll,
      hideScrollbar = false,
      ...styleAndOtherProps
    },
    forwardedRef
  ) => {
    const theme = useTheme();
    const shadowColorCssVar = useMemo(() => {
      if (shadowColor == null) return undefined;
      if (shadowColor in theme.colors) {
        return `rgb(var(--color-${shadowColor}-rgb) / ${shadowOpacity})`;
      }
      const color = Color.from({ ...Color.parse(shadowColor), a: shadowOpacity });
      return color.toString();
    }, [shadowColor, shadowOpacity, theme.colors]);

    const [styleProps, otherProps] = pickStyleableProps(styleAndOtherProps);
    const scopedClassName = useScopedStyles('ScrollPanel', {
      ...styleProps,
      '--scrollPanel-shadow-color': shadowColorCssVar
    });

    const { height, minHeight, maxHeight } = styleProps;
    const heightClass = useScopedStyles('ScrollPanel-inner', { height, minHeight, maxHeight });

    const containerRef = useRef<HTMLDivElement>();
    const scrollSyncRef = useSynchronizedScrolling(axis, { enable: synchronize });
    const combinedRef = useCombinedRefs(forwardedRef, containerRef, scrollSyncRef);

    const [topOverflow, setTopOverflow] = useState(false);
    const [bottomOverflow, setBottomOverflow] = useState(false);
    const [leftOverflow, setLeftOverflow] = useState(false);
    const [rightOverflow, setRightOverflow] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const scrollingTimeout = useRef(Number.NaN);

    const updateScrollState = useCallback(() => {
      const element = containerRef.current;
      if (!element || disabled) return;

      const { scrollTop, scrollHeight, scrollLeft, scrollWidth, offsetHeight, offsetWidth } = element;

      setTopOverflow(Math.round(scrollTop) > 0);
      setBottomOverflow(Math.round(scrollTop + offsetHeight) < scrollHeight);
      setLeftOverflow(Math.round(scrollLeft) > 0);
      setRightOverflow(Math.round(scrollLeft + offsetWidth) < scrollWidth);
    }, [disabled]);

    useOnWindowResize(updateScrollState, true);

    const elementSize = useElementSize(containerRef.current);
    useLayoutEffect(() => {
      void elementSize;
      updateScrollState();

      const frame = requestAnimationFrame(updateScrollState);
      return () => cancelAnimationFrame(frame);
    }, [elementSize, updateScrollState]);

    useLayoutEffect(() => {
      updateScrollState();
    }, [showTopShadow, showLeftShadow, showRightShadow, showBottomShadow, updateScrollState]);

    const handleScroll = useCallback(() => {
      onScroll && onScroll();

      updateScrollState();

      // Show scrollbar, fade it out after delay
      window.clearTimeout(scrollingTimeout.current);
      const timeout = window.setTimeout(() => {
        setIsScrolling(false);
      }, fadeOutDelay);
      scrollingTimeout.current = timeout;
    }, [fadeOutDelay, onScroll, updateScrollState]);

    useEffect(() => {
      return () => clearTimeout(scrollingTimeout.current);
    }, []);

    const offset = `${getOverlayScrollbarSize()}px`;

    return (
      <div
        className={classNames(styles.container, className, scopedClassName, disabled && styles.disabled)}
        data-scroll-panel={axis}
        {...otherProps}
      >
        {showTopShadow && axis !== 'horizontal' && !disabled && (
          <div
            className={styles.topShadow}
            hidden={!topOverflow}
            aria-hidden={true}
            style={{ right: offset, top: shadowOffsetTop, zIndex: shadowZIndex }}
          />
        )}
        {showLeftShadow && axis !== 'vertical' && !disabled && (
          <div
            className={styles.leftShadow}
            hidden={!leftOverflow}
            aria-hidden={true}
            style={{ bottom: offset, left: shadowOffsetLeft, zIndex: shadowZIndex }}
          />
        )}
        <div
          ref={combinedRef}
          className={classNames(
            styles.scrollPanel,
            areaClassName,
            heightClass,
            isScrolling && styles.isScrolling,
            styles[axis],
            styles[`scrollPanel-${fitContent ? 'fitContent' : 'fill'}`],
            hideScrollbar && styles.hideScrollbar
          )}
          data-scroll-area={axis}
          onScroll={handleScroll}
        >
          {typeof children === 'function' ? children({ topOverflow, bottomOverflow, leftOverflow, rightOverflow }) : children}
        </div>
        {showRightShadow && axis !== 'vertical' && !disabled && (
          <div
            className={styles.rightShadow}
            hidden={!rightOverflow}
            aria-hidden={true}
            style={{ bottom: offset, right: shadowOffsetRight, zIndex: shadowZIndex }}
          />
        )}
        {showBottomShadow && axis !== 'horizontal' && !disabled && (
          <div
            className={styles.bottomShadow}
            hidden={!bottomOverflow}
            aria-hidden={true}
            style={{ right: offset, bottom: shadowOffsetBottom, zIndex: shadowZIndex }}
          />
        )}
      </div>
    );
  }
);
