import { createStrictContext } from '@hydrogrid/utilities/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState, type ReactNode } from 'react';
import { z } from 'zod';
import { createStore, useStore, type StoreApi } from 'zustand';
import type { SettingsObject } from '../api/generated-client/Schemas';
import { useUpdateSettingMutation } from '../api/hooks/UserSettingsStoreDataHooks';
import { api } from '../api/InsightApi';
import { useAuthStore } from './AuthStore';

const userSettings = z.object({
  telemetryOutdatedAfter: z.number()
});

type UserSettingsWithoutActions = z.infer<typeof userSettings>;
export type UserSettings = UserSettingsWithoutActions & {
  actions: {
    setSetting: <K extends keyof UserSettingsWithoutActions>(setting: K, body: UserSettingsWithoutActions[K]) => void;
    setSettings: (settings: SettingsObject) => void;
  };
};

const defaultState: Omit<UserSettings, 'actions'> = {
  telemetryOutdatedAfter: 6
};

const [useSettingsStore, SettingsStoreProvider] = createStrictContext<StoreApi<UserSettings>>('SettingsStore');

export function UserSettingsProvider({ children }: { children: ReactNode }) {
  const isLoggedIn = useAuthStore(state => state.status === 'logged-in');

  const settings = useQuery({
    queryKey: ['user-settings'],
    queryFn: ({ signal }) => api.dashboard.user.getSettings({ signal }),
    initialData: defaultState,
    initialDataUpdatedAt: 0,
    enabled: isLoggedIn
  });

  const updateSetting = useUpdateSettingMutation({ isSettingsMatching });

  const [store] = useState(() => {
    return createStore<UserSettings>(set => ({
      ...defaultState,
      actions: {
        setSetting: (setting, body) => {
          updateSetting.mutate({ setting, body });
        },
        setSettings: settings => {
          set(settings);
        }
      }
    }));
  });

  useEffect(() => {
    store.getState().actions.setSettings(settings.data);
  }, [settings.data, store]);

  return <SettingsStoreProvider value={store}>{children}</SettingsStoreProvider>;
}

function isSettingsMatching(data: unknown): data is UserSettings {
  return userSettings.safeParse(data).success;
}

type Params<U> = Parameters<typeof useStore<StoreApi<UserSettings>, U>>;

export function useUserSettings<U>(selector: Params<U>[1]) {
  const store = useSettingsStore();
  return useStore(store, selector);
}
