import { forwardRef, type ReactNode } from 'react';
import { useScopedStyles } from '../../css/useStyles';
import type { ThemeColor, ThemeColorWithOpacity } from '../../theme/Theme';
import { classNames } from '../../utils/classNames';
import styles from './Popover.module.css';

type PopoverAlignBlock = 'top' | 'bottom';
type PopoverAlignInline = 'left' | 'right';

type PopoverAlign =
  | PopoverAlignBlock
  | PopoverAlignInline
  | `${PopoverAlignBlock}-${PopoverAlignInline}`
  | `${PopoverAlignInline}-${PopoverAlignBlock}`;

interface PopoverProps {
  align: PopoverAlign;
  backgroundColor?: ThemeColor | ThemeColorWithOpacity;
  borderColor?: ThemeColor | ThemeColorWithOpacity | 'currentColor';
  borderRadius?: string;
  children?: ReactNode;
  color?: ThemeColor | ThemeColorWithOpacity | 'currentColor';
  size?: 'small' | 'medium' | 'large';
}

/**
 * A popover bubble that aligns itself to the nearest `position:relative` parent.
 */
export const Popover = forwardRef<HTMLDivElement, PopoverProps>((props, ref) => {
  const {
    children,
    color,
    backgroundColor = 'page',
    borderColor = backgroundColor,
    borderRadius,
    align = 'right',
    size = 'medium',
    ...restProps
  } = props;
  const scopedClass = useScopedStyles('Popover', {
    '--dropdown-background-color': `var(--color-${backgroundColor})`,
    '--dropdown-border-color': borderColor === 'currentColor' ? borderColor : `var(--color-${borderColor})`,
    borderRadius: borderRadius ?? 'inherit',
    color: color === 'currentColor' ? color : `var(--color-${color ?? 'bodyText'})`
  });

  return (
    <div className={classNames(styles.dropdown, scopedClass)} ref={ref}>
      <div
        role="presentation"
        className={classNames(styles.arrow, styles[`arrow-${size}`], styles[`arrow-${align}`], styles[`arrow-${size}-${align}`])}
      ></div>
      <div
        role="region"
        className={classNames(styles.bubble, styles[`bubble-${size}`], styles[`bubble-${align}`], styles[`bubble-${size}-${align}`])}
        {...restProps}
      >
        {children}
      </div>
    </div>
  );
});
