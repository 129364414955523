import { useStableCallback } from '@hydrogrid/utilities/memoization';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

export interface AnimatablePresenceEvents {
  onAppearStart?: (() => void) | undefined;
  onAppearEnd?: (() => void) | undefined;
  onVanishStart?: (() => void) | undefined;
  onVanishEnd?: (() => void) | undefined;
}

interface UseAnimatablePresenceOptions extends AnimatablePresenceEvents {
  isOpen: boolean;
}

/**
 * Hook for components that conditionally render their children, but "animate them in".
 *
 * When the component using `useAnimatablePresence` does not use normal css animations,
 * it can call `onAnimationStart` & `onAnimationEnd` to let `useAnimatablePresence` know
 * that its animation is completed.
 */
export function useAnimatablePresence({ isOpen, onAppearStart, onAppearEnd, onVanishStart, onVanishEnd }: UseAnimatablePresenceOptions) {
  const [shouldRender, setShouldRender] = useState(isOpen);
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current && isOpen) {
      setShouldRender(true);
    }
  }, [initialized, isOpen]);

  useLayoutEffect(() => {
    initialized.current = true;
  }, []);

  useEffect(() => {
    return () => {
      initialized.current = false;
    };
  }, []);

  const onAnimationStart = useStableCallback(() => {
    if (isOpen) {
      onAppearStart?.();
    } else {
      onVanishStart?.();
    }
  });

  const onAnimationEnd = useStableCallback(() => {
    if (isOpen) {
      setShouldRender(true);
      onAppearEnd?.();
    } else {
      setShouldRender(false);
      onVanishEnd?.();
    }
  });

  const prevElementRef = useRef<HTMLElement | null>(null);

  const register = useCallback(
    (element: HTMLElement | null) => {
      if (element === prevElementRef.current) return;

      const prevElement = prevElementRef.current;

      if (prevElement) {
        prevElement.removeEventListener('animationstart', onAnimationStart);
        prevElement.removeEventListener('animationend', onAnimationEnd);
        prevElementRef.current = null;
      }

      if (element) {
        prevElementRef.current = element;
        element.addEventListener('animationstart', onAnimationStart);
        element.addEventListener('animationend', onAnimationEnd);
      }
    },
    [onAnimationEnd, onAnimationStart]
  );

  return {
    ref: register,
    shouldRender: isOpen || shouldRender,
    onAnimationStart,
    onAnimationEnd
  };
}
