import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgHotkey = (props, ref) => /* @__PURE__ */ jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: 512, height: 512, fill: "none", viewBox: "0 0 24 24", ref, ...props, children: /* @__PURE__ */ jsxs("g", { fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd", children: [
  /* @__PURE__ */ jsx("path", { d: "M11.25 2c0-.41.34-.75.75-.75h10c.41 0 .75.34.75.75v20c0 .41-.34.75-.75.75H2a.75.75 0 0 1-.75-.75V10c0-.41.34-.75.75-.75h9.25zm1.5.75V10c0 .41-.34.75-.75.75H2.75v10.5h18.5V2.75z" }),
  /* @__PURE__ */ jsx("path", { d: "M11.03 13.47c.3.3.3.77 0 1.06L9.56 16l1.47 1.47a.75.75 0 1 1-1.06 1.06l-2-2a.75.75 0 0 1 0-1.06l2-2c.3-.3.77-.3 1.06 0z" }),
  /* @__PURE__ */ jsx("path", { d: "M17 10.75c.41 0 .75.34.75.75V16c0 .41-.34.75-.75.75H8.5a.75.75 0 0 1 0-1.5h7.75V11.5c0-.41.34-.75.75-.75z" })
] }) });
const ForwardRef = forwardRef(SvgHotkey);
export default ForwardRef;
