import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgSaveError = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 20, height: 20, viewBox: "0 0 20 20", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { d: "M15.87 1.54 14.6.17a.5.5 0 0 0-.37-.17H.51A.5.5 0 0 0 0 .5v15c0 .27.23.5.5.5h15a.5.5 0 0 0 .5-.5V1.87a.5.5 0 0 0-.13-.34Zm-4.38-.53v3.9H9.47v-3.9h2.02Zm-3.04 0v3.9H4.51v-3.9h3.94ZM12.4 15H3.6V8.97h8.8v6.02Zm2.59 0h-1.57V8.46a.5.5 0 0 0-.5-.5H3.08a.5.5 0 0 0-.5.5v6.53H1V1H3.5v4.41c0 .28.23.51.5.51h8a.5.5 0 0 0 .5-.5V1h1.52l.97 1.07v12.9Z" }),
  /* @__PURE__ */ jsx("path", { fill: "#fff", d: "M19.77 18.35c.3-.53.3-1.15 0-1.68l-4.32-7.31c-.3-.53-.84-.84-1.45-.84-.6 0-1.15.31-1.45.84l-4.32 7.32c-.3.53-.3 1.16 0 1.68s.84.83 1.45.83h8.63c.61 0 1.15-.31 1.46-.84Z" }),
  /* @__PURE__ */ jsxs("g", { fill: "#C10101", children: [
    /* @__PURE__ */ jsx("path", { d: "M19.77 18.35c.3-.53.3-1.15 0-1.68l-4.32-7.31c-.3-.53-.84-.84-1.45-.84-.6 0-1.15.31-1.45.84l-4.32 7.32c-.3.53-.3 1.16 0 1.68s.84.83 1.45.83h8.63c.61 0 1.15-.31 1.46-.84Zm-.66-.38a.92.92 0 0 1-.8.46H9.68a.92.92 0 0 1-.8-1.38l4.32-7.32a.9.9 0 0 1 .8-.46.9.9 0 0 1 .79.47l4.33 7.32a.9.9 0 0 1 0 .9Z" }),
    /* @__PURE__ */ jsx("path", { d: "M13.85 11.9c-.3.08-.48.34-.48.67l.04.59.13 2.2c.01.26.2.44.46.44.25 0 .45-.2.46-.45 0-.15 0-.29.02-.45l.08-1.42.06-.93a.76.76 0 0 0-.06-.3.62.62 0 0 0-.71-.36Z" }),
    /* @__PURE__ */ jsx("path", { d: "M14 16.22a.62.62 0 0 0-.62.62c0 .34.28.62.62.62.32 0 .62-.28.6-.6a.6.6 0 0 0-.6-.64Z" })
  ] })
] });
const ForwardRef = forwardRef(SvgSaveError);
export default ForwardRef;
