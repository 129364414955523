import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgFileJson = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", viewBox: "0 0 24 24", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "m20.85 5.15-5-5A.5.5 0 0 0 15.5 0H5a2 2 0 0 0-2 2v20c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V5.5a.5.5 0 0 0-.15-.35ZM16 1.7 19.3 5H17a1 1 0 0 1-1-1V1.7ZM20 22a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h10v3c0 1.1.9 2 2 2h3v16Z" }),
  /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "M5.9 14.23v.22c0 .25.14.36.38.42.52.12.84.5.84 1.09v1.84c0 1.05.85 1.88 2.01 1.88h.2c.25 0 .39-.14.39-.4v-.18c0-.26-.14-.4-.4-.4H9.2a.94.94 0 0 1-.96-.98V15.8c0-.78-.4-1.27-1.1-1.46.7-.2 1.1-.68 1.1-1.46v-1.92c0-.57.4-.98.96-.98h.12c.26 0 .4-.15.4-.4V9.4c0-.26-.14-.4-.4-.4h-.19c-1.16 0-2.01.83-2.01 1.88v1.84c0 .58-.32.97-.84 1.1-.24.05-.38.16-.38.4Z" }),
  /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "M11.25 17c0 .42.32.74.75.74s.75-.32.75-.75a.74.74 0 0 0-.75-.75.74.74 0 0 0-.75.75Zm0-5.01c0 .43.32.75.75.75s.75-.32.75-.75a.74.74 0 0 0-.75-.75.74.74 0 0 0-.75.75Z" }),
  /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "M18.1 14.45v-.22c0-.25-.13-.36-.37-.42-.53-.12-.84-.5-.84-1.09v-1.84c0-1.05-.85-1.88-2.02-1.88h-.19c-.26 0-.4.14-.4.4v.18c0 .26.14.4.4.4h.12c.56 0 .96.41.96.98v1.92c0 .78.4 1.27 1.1 1.46-.7.2-1.1.68-1.1 1.46v1.92c0 .57-.4.98-.96.98h-.12c-.26 0-.4.15-.4.4v.18c0 .26.15.4.4.4h.2c1.16 0 2.01-.83 2.01-1.88v-1.84c0-.58.31-.97.84-1.1.24-.05.37-.16.37-.4Z" })
] });
const ForwardRef = forwardRef(SvgFileJson);
export default ForwardRef;
