import { api } from '../InsightApi';
import { defineQuery } from '../query-utils/defineQuery';

export const insightUsersList = defineQuery(() => ({
  queryKey: ['insight-users'],
  queryFn: ({ signal }) => api.internal.dashboard.account.dashboardAccounts({ signal })
}));

export const apiUsersList = defineQuery(() => ({
  queryKey: ['api-users'],
  queryFn: ({ signal }) => api.internal.dashboard.account.listApiAccounts({ signal })
}));

export const allPortfoliosList = defineQuery(() => ({
  queryKey: ['all-portfolios'],
  queryFn: ({ signal }) => api.internal.dashboard.portfolio.requestAll({ signal })
}));

export const userPortfolios = defineQuery((account_id: string) => ({
  queryKey: ['user-portfolios', account_id],
  queryFn: ({ signal }) => api.internal.dashboard.account.requestPortfolios({ account_id, signal })
}));

export const apiPermissionsPortfolio = defineQuery((portfolio_id: string, account_id: string) => ({
  queryKey: ['api-permissions-portfolio', account_id],
  queryFn: ({ signal }) => api.internal.dashboard.permissions.listForAccountInPortfolio({ portfolio_id, account_id, signal })
}));

export const permissionsList = defineQuery((account_id: string) => ({
  queryKey: ['api-permissions-list', account_id],
  queryFn: ({ signal }) => api.internal.dashboard.permissions.byUnitType({ signal })
}));
