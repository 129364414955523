import { useLayoutEffect, useState, type RefObject } from 'react';

/**
 * Get the width and height of an element, and rerender when they change.
 *
 * @example
 * ```
 * const [div, setDiv] = useState<HTMLDivElement | null>(null>);
 * const size = useElementSize(div);
 * return <div ref={setDiv}>{size?.width} x {size?.height}</div>;
 * ```
 */
export function useElementSize(elementOrRef: RefObject<HTMLElement> | HTMLElement | null | undefined) {
  const [dimensions, setDimensions] = useState(() => {
    const element = elementOrRef == null ? elementOrRef : 'current' in elementOrRef ? elementOrRef.current : elementOrRef;
    const width = element?.offsetWidth;
    const height = element?.offsetHeight;
    return width != null && height != null ? { width, height } : null;
  });

  useLayoutEffect(() => {
    const element = elementOrRef == null ? elementOrRef : 'current' in elementOrRef ? elementOrRef.current : elementOrRef;
    if (!element) return;

    const width = element.offsetWidth;
    const height = element.offsetHeight;

    setDimensions(current => (current && current.width === width && current.height === height ? current : { width, height }));

    const resizeObserver = new ResizeObserver(entries => {
      const entry = entries[0];
      if (entry == null) return;

      if (entry.contentBoxSize !== undefined) {
        const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
        setDimensions({ width, height });
      } else {
        const { width, height } = entry.contentRect;
        setDimensions({ width, height });
      }
    });

    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, [elementOrRef]);

  return dimensions;
}
