import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle(() => {
  return {
    container: {
      backgroundColor: 'page',
      borderRadius: 'base',
      color: 'chakra-body-text',
      borderWidth: '1px'
    }
  };
});

const sizes = {
  sm: defineStyle({
    container: {
      padding: '2'
    }
  }),
  md: defineStyle({
    container: {
      padding: '3'
    }
  }),
  lg: defineStyle({
    container: {
      padding: '6'
    }
  })
};

const variants = {
  outline: defineStyle(props => {
    const { colorScheme: c } = props;

    const borderColorMap: Record<string, string> = {
      secondary: 'secondary.20',
      primary: 'primary.300'
    };

    return {
      container: {
        borderColor: borderColorMap[c] ?? `${c}.20`
      }
    };
  }),
  filled: defineStyle(props => {
    const { colorScheme: c } = props;

    const backgroundColorMap: Record<string, string> = {
      secondary: 'secondary.50',
      primary: 'primary.300'
    };

    return {
      container: {
        backgroundColor: backgroundColorMap[c] ?? `${c}.50`,
        borderColor: backgroundColorMap[c] ?? `${c}.50`
      }
    };
  }),
  unstyled: {
    body: {
      padding: '0'
    },
    header: {
      padding: '0'
    },
    footer: {
      padding: '0'
    }
  }
};

export const cardTheme = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'outline',
    colorScheme: 'secondary',
    size: 'md'
  }
});
