import { Flex, Icon, Text } from '@chakra-ui/react';
import { Button, Dialog, WarningTriangleIcon } from '@hydrogrid/design-system';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { api } from '../../common/api/InsightApi';
import { sleep } from '../../common/utils/sleep';

export function TokenVerifyErrorDialog() {
  const queryClient = useQueryClient();
  const [isRetryingTokenVerification, setRetryingTokenVerification] = useState(false);
  const [tokenVerifyBackoff, setTokenVerifyBackoff] = useState(1000);

  const retryVerifyingToken = async () => {
    setRetryingTokenVerification(true);

    await sleep(tokenVerifyBackoff);
    try {
      await api.checkIfStillLoggedIn();

      const reloadAllData = queryClient.invalidateQueries();
      reloadAllData.catch(_ignore => {});
    } catch {
      const newBackoff = tokenVerifyBackoff * 2;
      setTokenVerifyBackoff(Math.min(10_000, newBackoff));
    } finally {
      setRetryingTokenVerification(false);
    }
  };

  return (
    <Dialog
      title={
        <Flex align="center" gap={2.5} color="error">
          <Icon as={WarningTriangleIcon} boxSize={5} color="error" /> An error occured
        </Flex>
      }
      isOpen
      isCentered
      hideCloseButton
      onClose={() => {}}
      footer={
        <>
          <Button colorScheme="secondary" isLoading={isRetryingTokenVerification} onClick={retryVerifyingToken}>
            Retry
          </Button>
        </>
      }
    >
      <Text>We couldn't verify your session, please check your internet connection.</Text>
    </Dialog>
  );
}
