import { selectAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system';
import { inputTheme } from './Input';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar('select-bg');

const baseStyleField = defineStyle({
  ...inputTheme.baseStyle?.field,
  appearance: 'none',
  lineHeight: 'normal',
  bg: $bg.reference,
  [$bg.variable]: 'colors.white',
  '> option, > optgroup': {
    bg: $bg.reference
  }
});

const baseStyleIcon = defineStyle({
  width: '6',
  height: '6',
  insetEnd: '2',
  position: 'relative',
  color: 'currentColor',
  fontSize: 'xl',
  _disabled: {
    opacity: 0.5
  }
});

const baseStyle = definePartsStyle({
  field: baseStyleField,
  icon: baseStyleIcon
});

const iconSpacing = defineStyle({
  paddingInlineEnd: '10'
});

const sizes = {
  md: {
    ...inputTheme.sizes?.md,
    field: {
      ...inputTheme.sizes?.md.field,
      ...iconSpacing
    }
  },
  xs: {
    ...inputTheme.sizes?.xs,
    field: {
      ...inputTheme.sizes?.xs.field,
      ...iconSpacing
    }
  }
};

export const selectTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: inputTheme.variants,
  defaultProps: inputTheme.defaultProps
});
