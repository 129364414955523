import type { CSSProperties } from 'react';

/** Default theme for Hydrogrid Insight. */
export const defaultTheme = {
  colors: {
    primary: '#0bb7a0',
    secondary: '#586369',
    accent: '#ad1265',
    page: '#ffffff',
    background: '#e4e5e6',
    bodyText: '#586369',
    caption: '#0bb7a0',
    layer: '#e4e5e6',
    positive: '#0bb731',
    destructive: '#c10101',
    caution: '#ffab1a',
    info: '#0b6fb7',

    // New design colors & token names:
    // https://jira.hydrogrid.eu/browse/DS-3629
    contrast: '#1b2327'
  },
  breakpoints: {
    // TODO: Maybe rename? 992px is not really large.
    // - "xsmall"/"phone" (576)
    // - "small"/"tablet" (768)
    // - "medium"/"laptop" (992)
    // - "large"/"desktop" (1440)
    // - "xlarge"/"4k" (2560)
    small: 576,
    medium: 768,
    large: 992,
    xlarge: 1440,
    xxlarge: 2560
  },
  fonts: {
    body: '"Core Sans CR", "Segoe UI", "sans-serif"',
    charts: '"Core Sans CR", "Segoe UI", "sans-serif"',
    monospace: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace'
  },
  fontSizes: {
    '4xl': '2.25rem',
    '3xl': '1.875rem',
    '2xl': '1.5rem',
    xl: '1.25rem',
    lg: '1.125rem',
    base: '1rem',
    sm: '0.875rem',
    xs: '0.75rem'
  },
  lineHeights: {
    taller: 2,
    tall: 1.625,
    base: 1.5,
    short: 1.375,
    shorter: 1.25,
    none: 1,
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem'
  },
  iconSizes: {
    xsmall: '0.5rem',
    small: '0.75rem',
    medium: '1rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '2rem'
  },
  textStyles: {
    title: { font: 'body', size: '4xl', color: 'bodyText', lineHeight: '10' },
    headline: { font: 'body', size: '2xl', color: 'primary', lineHeight: 'taller' },
    caption: { font: 'body', size: 'lg', color: 'bodyText', lineHeight: 'tall' },
    body: { font: 'body', size: 'sm', color: 'bodyText', lineHeight: 'shorter' },
    code: { font: 'monospace', size: 'sm', color: 'bodyText', lineHeight: 'shorter' }
  },
  shadows: {
    weak: '0 3px 6px rgb(74 88 96 / 5%)',
    medium: '0 3px 6px rgb(74 88 96 / 10%)',
    strong: '0 3px 6px rgb(74 88 96 / 20%)',
    focus: '0 0 2px 2px rgb(11 183 160 / 10%)'
  },
  durations: {
    veryfast: 80,
    fast: 150,
    medium: 300,
    slow: 600,
    veryslow: 1200,
    veryveryslow: 2400
  }
} satisfies Theme;

/**
 * Type for the application theme.
 * For the default theme used in Insight, see {@link defaultTheme}
 */
export interface Theme {
  colors: {
    primary: string;
    secondary: string;
    accent: string;
    page: string;
    background: string;
    bodyText: string;
    caption: string;
    layer: string;
    positive: string;
    destructive: string;
    caution: string;
    info: string;

    contrast: string;
  };
  breakpoints: {
    small: number;
    medium: number;
    large: number;
    xlarge: number;
    xxlarge: number;
  };
  fonts: {
    body: string;
    charts: string;
    monospace: string;
  };
  fontSizes: {
    '4xl': string;
    '3xl': string;
    '2xl': string;
    xl: string;
    lg: string;
    base: string;
    sm: string;
    xs: string;
  };
  lineHeights: {
    taller: number;
    tall: number;
    base: number;
    short: number;
    shorter: number;
    none: number;
    '3': string;
    '4': string;
    '5': string;
    '6': string;
    '7': string;
    '8': string;
    '9': string;
    '10': string;
  };
  iconSizes: {
    xsmall: string;
    small: string;
    medium: string;
    large: string;
    xlarge: string;
    xxlarge: string;
  };
  textStyles: {
    title: TextStyle;
    headline: TextStyle;
    caption: TextStyle;
    body: TextStyle;
    code: TextStyle;
  };
  shadows: {
    weak: string;
    medium: string;
    strong: string;
    focus: string;
  };
  durations: {
    veryfast: number;
    fast: number;
    medium: number;
    slow: number;
    veryslow: number;
    veryveryslow: number;
  };
}

export const colorOpacities = [90, 80, 70, 60, 50, 40, 30, 20, 10] as const;

type ColorOpacity = (typeof colorOpacities)[number];

export type ThemeColor = keyof Theme['colors'];
export type ThemeColorWithOpacity = `${ThemeColor}-${ColorOpacity}`;
export type ThemeBreakpoint = keyof Theme['breakpoints'];
export type ThemeFont = keyof Theme['fonts'];
type ThemeFontSize = keyof Theme['fontSizes'];
type ThemeLineHeight = keyof Theme['lineHeights'];
export type ThemeIconSize = keyof Theme['iconSizes'];
export type ThemeTextStyle = keyof Theme['textStyles'];
export type ThemeDuration = keyof Theme['durations'];

interface TextStyle {
  font: ThemeFont;
  color: ThemeColor;
  size: ThemeFontSize;
  lineHeight: ThemeLineHeight;
  weight?: CSSProperties['fontWeight'];
  style?: CSSProperties['fontStyle'];
  decoration?: CSSProperties['textDecoration'];
}
