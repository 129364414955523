import { Button, HorizontalStack, Select, Text, TextInput, VerticalStack } from '@hydrogrid/design-system';
import { useCallback, useMemo, useState, type FormEvent, type MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { ApiError } from '../../common/api/ApiError';
import type { MfaSecret } from '../../common/api/generated-client/Schemas';
import { useLogin } from '../../common/api/hooks/AuthDataHooks';
import { isHydrogridUser } from '../../common/business-logic/specialUsers';
import { routes } from '../../common/routing/routes';
import { useCountdown } from '../../common/time/useCountdown';
import type { Environment } from '../../config/environments';
import { UserAvatar } from '../../containers/UserAvatar/UserAvatar';
import styles from './LoginForm.module.css';

interface LoginFormProps {
  canSelectEnvironment?: boolean;
  environment: Environment;
  environments: Environment[];
  usernamePrefilled?: string | undefined;
  onChangeEnvironment?: (environment: Environment) => void;
  onChangeEnvironmentClick?: () => void;
  onSuccessNeedsMfa: (mfa: { username: string; secret: string; validUntil: number }) => void;
  onSuccessWithoutMfa: ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => void;
  onForgotPassword: ({ email }: { email: string }) => void;
}

/**
 * A login form with validation.
 * Requests and validation need to be done in the parent component.
 */
export function LoginForm({
  canSelectEnvironment = true,
  environment,
  environments,
  usernamePrefilled,
  onChangeEnvironment,
  onChangeEnvironmentClick,
  onForgotPassword,
  onSuccessNeedsMfa,
  onSuccessWithoutMfa
}: LoginFormProps) {
  const [username, setUsername] = useState(usernamePrefilled ?? '');
  const [password, setPassword] = useState('');

  const [loginFailed, setLoginFailed] = useState(false);

  const [loginTimeoutUntil, setLoginTimeoutUntil] = useState<Date | null>(null);
  const loginTimeout = useCountdown(loginTimeoutUntil, { hideExpired: true }).text;

  const changeUsername = useCallback((username: string) => {
    setUsername(username);
    setLoginFailed(false);
    setLoginTimeoutUntil(null);
  }, []);

  const changePassword = useCallback((password: string) => {
    setPassword(password);
    setLoginFailed(false);
  }, []);

  const forgotPasswordLinkClicked = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      onForgotPassword({ email: username });
    },
    [onForgotPassword, username]
  );

  const login = useLogin();

  const otherError: string | null = useMemo(() => {
    const error = login.error;
    if (!(error instanceof ApiError) || error.status === 401 || error.status === 429) {
      return null;
    }

    // Non-production only:
    // Show a message when login fails due to non-connected VPN.
    // This gets compiled away for production.
    if (import.meta.env.DEV || import.meta.env.VITE_DEPLOY_TARGET !== 'insight') {
      if (
        error.relatedError instanceof Error &&
        error.relatedError.message === 'Invalid JSON in response body.' &&
        (error.response?.headers.get('content-type')?.startsWith('text/html') ?? false) &&
        isHydrogridUser(username)
      ) {
        return `Unable to reach the backend.\nAre you connected to the VPN?`;
      }
    }

    return error.message;
  }, [login.error, username]);

  const submitForm = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      UserAvatar.preload(username).catch(_ignore => {});
      UserAvatar.preload(username, 120).catch(_ignore => {});

      login.mutate(
        { username, password },
        {
          onSuccess: (data, { username }) => {
            if ('mfa_secret' in data) {
              onSuccessNeedsMfa({ username, secret: data.mfa_secret, validUntil: (data as MfaSecret).valid_until });
            } else {
              // No MFA enabled for account (is this still possible?)
              return onSuccessWithoutMfa({
                accessToken: data.access_token,
                refreshToken: data.refresh_token
              });
            }
          },
          onError: error => {
            if (!(error instanceof ApiError)) return;

            const retryAfter = error.response.headers?.get('retry-after');
            const retryAfterOffset = typeof retryAfter === 'string' ? Number.parseInt(retryAfter, 10) * 1000 : 0;
            const serverTime = error.response.headers?.get('date');

            if (!isNaN(retryAfterOffset) && retryAfterOffset > 0 && serverTime) {
              // If we log in too often, the error responds with:
              //   HTTP 429 Too Many Requests (or HTTP 401 Unauthorized)
              //   Date: <current date in GMT format>
              //   Retry-After: <retry time in seconds>

              const earliestDateToRetryAt = new Date(serverTime).getTime() + retryAfterOffset;

              if (Number.isNaN(earliestDateToRetryAt)) {
                return console.error('Server responded with an invalid value for the "Retry-After" or "Date" headers.');
              }

              setLoginTimeoutUntil(new Date(earliestDateToRetryAt));

              // TODO: Show notification that we have to retry login
            } else if (error.status === 401) {
              // On HTTP 401, highlight username & password field
              setLoginFailed(true);

              // TODO: Show notification that username and/or password are incorrect
            } else {
              setLoginFailed(true);
              // TODO: Show notification for an unknown error
            }
          }
        }
      );
    },
    [login, password, username, onSuccessNeedsMfa, onSuccessWithoutMfa]
  );

  return (
    <form noValidate className={styles.form} onSubmit={submitForm}>
      <VerticalStack spacing="2rem" align="stretch">
        <VerticalStack spacing="1rem" align="stretch">
          <h1 className={styles.heading}>Login</h1>
          <TextInput
            autoFocus
            name="username"
            id="username"
            label="Username"
            placeholder="user@example.com"
            autoComplete="username"
            value={username}
            disabled={login.isPending}
            error={loginFailed}
            onChange={changeUsername}
          />
          <TextInput
            name="password"
            id="password"
            label="Password"
            placeholder="••••••••"
            autoComplete="current-password"
            value={password}
            disabled={login.isPending}
            error={otherError ?? (loginFailed && 'Invalid username or password')}
            onChange={changePassword}
            type="password"
          />
          {environments.length >= 2 &&
            (canSelectEnvironment ? (
              <Select
                name="environment"
                value={environment.slug}
                isReadOnly={!canSelectEnvironment}
                onChange={envSlug => {
                  const env = environments.find(env => env.slug === envSlug);
                  if (!env) {
                    return;
                  }

                  onChangeEnvironment?.(env);
                }}
              >
                {environments.map(environment => (
                  <option key={environment.slug} value={environment.slug}>
                    {environment.name}
                  </option>
                ))}
              </Select>
            ) : (
              <div className={styles.readonlyEnvironment}>
                {environment.name}
                {onChangeEnvironmentClick && (
                  <Button colorScheme="primary" variant="link" onClick={onChangeEnvironmentClick}>
                    Change
                  </Button>
                )}
              </div>
            ))}
        </VerticalStack>
        <HorizontalStack className={styles.buttons} spacing="1rem" wrap>
          <Button
            colorScheme={loginTimeout ? 'error' : 'primary'}
            type="submit"
            isDisabled={login.isPending || !username || !password || loginTimeout != null}
            role={loginTimeout ? 'timer' : undefined}
          >
            {loginTimeout || 'Continue'}
          </Button>
          <HorizontalStack align="center" justify="center">
            <Text color="primary">
              <Link to={routes.forgotPassword.url({ params: { environment: environment.slug } })} onClick={forgotPasswordLinkClicked}>
                Forgot Password?
              </Link>
            </Text>
          </HorizontalStack>
        </HorizontalStack>
      </VerticalStack>
    </form>
  );
}
