import { createLocalStore } from '@hydrogrid/utilities/stores';
import type { UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { combine } from 'zustand/middleware';
import type { PeriodicTaskList } from '../../../../common/api/generated-client/Schemas';
import { useCombineQueries } from '../../../../common/api/query-utils/combineQueries';

type State = {
  state: ('Active' | 'Inactive')[] | 'all';
  search: string;
};

const defaultState: State = {
  state: 'all',
  search: ''
};

export const [useActionsTasksFilterStore, ActionsTasksFilterProvider] = createLocalStore('ActionsTasksFilterStore', () =>
  combine(
    defaultState,

    set => ({
      actions: {
        clearFilters: () => {
          set({
            ...defaultState
          });
        },
        toggleState: (state: ('Active' | 'Inactive')[] | 'all') => {
          set({ state });
        },
        setSearch: (search: string) => {
          set({ search });
        }
      }
    })
  )
);

export function useFilterActiontasksisActive() {
  const filters = useActionsTasksFilterStore(state => state);
  const { actions: _actions, ...restFilters } = filters;
  return !_.isEqual(restFilters, defaultState);
}

export function useFilterActionTasks(tasks: UseQueryResult<{ data: PeriodicTaskList }>) {
  const filters = useActionsTasksFilterStore(state => state);
  const { actions: _actions, ...restFilters } = filters;

  const filteredTasks = useCombineQueries(tasks, tasks => {
    if (_.isEqual(restFilters, defaultState)) {
      return tasks.data;
    }

    const filteredTasks = tasks.data.filter(task => {
      const searchRegex = new RegExp(filters.search, 'i');
      const isIncludedInSearch = searchRegex.test(task.name);
      if (!isIncludedInSearch) return false;

      if (filters.state !== 'all') {
        if (filters.state.includes('Active') && task.isPaused) return false;
        if (filters.state.includes('Inactive') && !task.isPaused) return false;
        if (filters.state.length === 0) return false;
      }

      return true;
    });

    return filteredTasks;
  });

  return filteredTasks;
}
