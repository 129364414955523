import { createContext, useCallback, useContext, useMemo, useState, type ReactNode } from 'react';

interface HotkeyModalState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

const HotkeyModalStateContext = createContext<HotkeyModalState | null>(null);

export function ProvideHotkeyModalState({ children }: { children?: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const state = useMemo((): HotkeyModalState => {
    return { isOpen, open, close };
  }, [isOpen, open, close]);

  return <HotkeyModalStateContext.Provider value={state}>{children}</HotkeyModalStateContext.Provider>;
}

export function useHotkeyModal() {
  const context = useContext(HotkeyModalStateContext);
  if (context == null) {
    throw new Error(`The component calling useHotkeyModalState needs to be inside a ProvideHotkeyModalState.`);
  }
  return context;
}
