import { createContext, useContext, useEffect, useMemo, useState, type ReactNode } from 'react';

const LocaleContext = createContext<UserLocales | null>(null);

interface ProvideLocaleProps {
  children: ReactNode;
  forceLocale?: string | undefined;
}

export interface UserLocales {
  /** List of preferred languages by the user. */
  languages: string[];

  /** Primary language of the user. Equal to `languages[0]`. */
  language: string;

  /** Locale used for number formatting. */
  numbers: string;

  /** Locale used for date and time information. */
  dateTime: string;

  /** Locale used for sorting/comparing text. */
  collator: string;
}

function getUserLocales(): UserLocales {
  const languages = [...window.navigator.languages];
  if (!languages.length) {
    languages.push('en-GB');
  }

  return {
    languages,
    language: languages[0],
    numbers: new Intl.NumberFormat().resolvedOptions().locale,
    dateTime: new Intl.DateTimeFormat().resolvedOptions().locale,
    collator: new Intl.Collator().resolvedOptions().locale
  };
}

/**
 * Provides the user locale to the application. (@see useLocale)
 * Allows to set a fallback or override the locale entirely.
 */
export function ProvideLocales({ children, forceLocale }: ProvideLocaleProps) {
  const [locales, setLocales] = useState(getUserLocales());

  useEffect(() => {
    const handleLanguageChange = () => setLocales(getUserLocales());

    window.addEventListener('languagechange', handleLanguageChange);
    return () => window.removeEventListener('languagechange', handleLanguageChange);
  }, []);

  const contextValue: UserLocales = useMemo(() => {
    if (!forceLocale) {
      return locales;
    }

    return {
      languages: [forceLocale],
      language: forceLocale,
      numbers: forceLocale,
      dateTime: forceLocale,
      collator: forceLocale
    };
  }, [forceLocale, locales]);

  return <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>;
}

/**
 * Returns the user's preferred locale to dislay localized content.
 * @example
 * ```
 * const locales = useLocales();
 * return <div>{locales.join(', ')}</div>;
 * // -> <div>de-AT, de-DE, de, en-GB, en-US, en</div> (user dependant)
 * ```
 */
export function useLocales() {
  const locales = useContext(LocaleContext);
  if (locales == null) {
    throw new Error('For useLocale to work, render ProvideLocale in the root component.');
  }
  return locales;
}
