export function isElement(element: unknown): element is Element {
  return element != null && typeof element == 'object' && 'nodeType' in element && element.nodeType === Node.ELEMENT_NODE;
}

export function isHTMLElement(element: unknown): element is HTMLElement {
  if (!isElement(element)) {
    return false;
  }

  const win = element.ownerDocument.defaultView ?? window;
  return element instanceof win.HTMLElement;
}
