import { HorizontalStack, Text, VerticalStack } from '@hydrogrid/design-system';
import { UserAvatar } from '../../containers/UserAvatar/UserAvatar';
import styles from './LoginSucessful.module.css';

interface LoginSucessfulProps {
  email: string;
  fullName: string | null;
  isReturning: boolean;
}

export function LoginSucessful({ email, fullName, isReturning }: LoginSucessfulProps) {
  return (
    <div className={styles.container}>
      <VerticalStack spacing="1rem" align="stretch">
        <h1 className={styles.heading}>Login successful</h1>
        <VerticalStack spacing="2rem" padding={isReturning ? '2rem 0 0' : 0} align="center" justify="center">
          <VerticalStack spacing="0" align="flex-start" justify="center">
            <HorizontalStack className={styles.userDetails} align="center" spacing="1rem" padding="1rem 0.5rem">
              {email && <UserAvatar email={email} name={fullName} size={60} />}
              <VerticalStack justify="center" spacing="0">
                <Text>{fullName ?? ' '}</Text>
                <Text>{email}</Text>
              </VerticalStack>
            </HorizontalStack>
          </VerticalStack>
          {isReturning && <p className={styles.welcomeBack}>Welcome back!</p>}
        </VerticalStack>
        <div role="progressbar" className={styles.timeoutProgressBar} />
      </VerticalStack>
    </div>
  );
}
