import { Box, Link, ListItem } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { NavLink, type To } from 'react-router-dom';
import { childrenToString } from '../../accessibility/childrenToString';

interface NavigationItemProps {
  children: ReactNode;
  icon?: ReactNode | undefined;
  to?: To | undefined;
  state?: unknown;
  isMenuCollapsed?: boolean;
  isActive?: boolean;
}

export function NavigationItem(props: NavigationItemProps) {
  const { children, icon, to, state, isMenuCollapsed = false, isActive = false, ...otherProps } = props;

  const label = childrenToString(children);

  return (
    <ListItem
      role="none"
      w={['100%', 12, isMenuCollapsed ? 12 : 50]}
      h={13}
      position="relative"
      color="secondary.50"
      fontSize={[0, null, isMenuCollapsed ? 0 : 'md']}
      transition="width 0.3s, font-size 0.3s"
      display="flex"
      flexDir="column"
      overflow="hidden"
      {...otherProps}
    >
      {to != null ? (
        <Link
          role="menuitem"
          as={NavLink}
          to={to}
          state={state}
          aria-label={label}
          display="flex"
          boxSizing="border-box"
          flexDirection="row"
          alignItems="center"
          py={3}
          px={4}
          minW={13}
          minH={13}
          paddingLeft={[4, null, icon != undefined ? 4 : isMenuCollapsed ? 4 : 10]}
          _hover={{ bgColor: 'primary', textShadow: '0px 0px 4px var(--color-secondary)' }}
          _focusWithin={{ bgColor: 'primary', textShadow: '0px 0px 4px var(--color-secondary)' }}
          _focusVisible={{
            outline: 'none'
          }}
          _activeLink={{
            bgColor: 'primary',
            textShadow: '0px 0px 4px var(--color-secondary)'
          }}
          bgColor={isActive ? 'primary' : (undefined as never)}
          textShadow={isActive ? '0px 0px 4px var(--color-secondary)' : (undefined as never)}
        >
          {icon != null && (
            <Box w={4} h={4} marginRight={[0, null, isMenuCollapsed ? 0 : 3]}>
              {icon}
            </Box>
          )}
          {children}
        </Link>
      ) : (
        <Box
          role="menuitem"
          aria-label={label}
          display="flex"
          boxSizing="border-box"
          flexDirection="row"
          alignItems="center"
          py={3}
          px={4}
          minW={13}
          minH={13}
          paddingLeft={[4, null, icon != undefined ? 4 : isMenuCollapsed ? 4 : 10]}
          _hover={{ bgColor: 'primary', textShadow: '0px 0px 4px var(--color-secondary)' }}
          _focusWithin={{ bgColor: 'primary', textShadow: '0px 0px 4px var(--color-secondary)' }}
          _focusVisible={{
            outline: 'none'
          }}
          _activeLink={{
            bgColor: 'primary',
            textShadow: '0px 0px 4px var(--color-secondary)'
          }}
          bgColor={isActive ? 'primary' : (undefined as never)}
          textShadow={isActive ? '0px 0px 4px var(--color-secondary)' : (undefined as never)}
        >
          {icon != null && (
            <Box w={4} h={4} marginRight={[0, null, isMenuCollapsed ? 0 : 3]}>
              {icon}
            </Box>
          )}
          {children}
        </Box>
      )}
    </ListItem>
  );
}
