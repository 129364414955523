import type { ComponentWithAs } from '../../utils/AsProps';
import { useRegisterPageNavigationHost } from './PageNavigationContext';

/** Defines a host where `PageNavigation` contents are rendered into. */
export const PageNavigationHost: ComponentWithAs<'nav'> = function (props) {
  const { as: Container = 'nav', children: _, ...otherProps } = props;

  const register = useRegisterPageNavigationHost();

  return <Container ref={register} {...otherProps}></Container>;
};
