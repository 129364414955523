import { OverlayHost } from '@hydrogrid/design-system';
import { Suspense, useCallback, type ReactNode } from 'react';
import { useAppAction } from '../../common/accessibility/useAppActions';
import { HotkeyModal } from '../../components/HotkeyModal/HotkeyModal';
import { useHotkeyModal } from '../../components/HotkeyModal/HotkeyModalStateContext';
import { SkipToMainContent } from '../../components/SkipToMainContent/SkipToMainContent';
import { DiagnosticErrorBoundary } from '../../containers/AppErrorBoundary/AppErrorBoundary';
import { AppHeader } from '../../containers/AppHeader/AppHeader';
import { AppNavigationBar } from '../../containers/AppNavigationBar/AppNavigationBar';
import styles from './LayoutWithSidebar.module.css';

export function LayoutWithSidebar({ children }: { children?: ReactNode }) {
  const { open, close, isOpen } = useHotkeyModal();

  const toggleHotkeyModal = useCallback(() => {
    isOpen ? close() : open();
  }, [close, isOpen, open]);

  useAppAction({
    id: 'open-hotkey-modal',
    name: 'Show Shortcuts',
    hotkey: '?',
    callback: toggleHotkeyModal,
    group: 'General'
  });

  return (
    <div className={styles.layout}>
      <SkipToMainContent />
      <AppHeader className={styles.header} />
      <AppNavigationBar />
      <main className={styles.content}>
        <DiagnosticErrorBoundary>
          <OverlayHost id="page">
            <Suspense fallback={<div />}>{children}</Suspense>
          </OverlayHost>
        </DiagnosticErrorBoundary>
      </main>
      <HotkeyModal />
    </div>
  );
}
