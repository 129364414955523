import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['container', 'text', 'icon']);

const baseStyle = helpers.definePartsStyle(() => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 'base',
      borderWidth: '1px',
      borderColor: 'transparent',
      w: 'fit-content',
      rounded: 'full'
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    text: {
      lineHeight: '1',
      flexGrow: 0
    }
  };
});

const sizes = {
  md: helpers.definePartsStyle({
    container: {
      px: 1,
      gap: 1,
      h: 4
    },
    text: {
      textStyle: 'body'
    }
  }),
  lg: helpers.definePartsStyle({
    container: {
      px: 1.5,
      gap: 2,
      h: 5.5
    },
    text: { textStyle: 'bodyLg' }
  })
};

const variants = {
  solid: helpers.definePartsStyle(({ colorScheme: c }) => {
    return {
      container: {
        backgroundColor: `${c}.100`,
        borderColor: `${c}.100`
      }
    };
  }),
  outline: helpers.definePartsStyle(({ colorScheme: c }) => {
    return {
      container: {
        borderColor: `${c}.400`
      }
    };
  }),
  unstyled: {}
};

export const tagTheme = helpers.defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'solid',
    colorScheme: 'secondary',
    size: 'md'
  }
});
