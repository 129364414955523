import { useHotkeys, type KeyCombinationMap } from '@hydrogrid/design-system';
import { useDeepReusedWithCallbacks, useShallowMemo } from '@hydrogrid/utilities/memoization';
import { useTrackAppActions } from './AppActionsContext';

export interface AppAction {
  id: string;
  name: string;
  hotkey?: string;
  group?: string;
  enabled?: boolean;
  callback: () => void;
}

export function useAppActions(actions: AppAction[]) {
  const stableActions = useDeepReusedWithCallbacks(actions);

  const hotkeys = useShallowMemo(() => {
    const hotkeyMap: KeyCombinationMap = {};

    for (const { enabled = true, hotkey, callback } of stableActions) {
      if (hotkey && enabled) {
        hotkeyMap[hotkey] = callback;
      }
    }

    return hotkeyMap;
  }, [stableActions]);

  useTrackAppActions(stableActions);
  useHotkeys(hotkeys);
}

export function useAppAction(action: AppAction) {
  return useAppActions([action]);
}
