import type { MakeUndefineablePropsOptional } from '@hydrogrid/utilities/typescript';
import type { Path } from 'react-router-dom';
import type { PathParamTypes } from './TypedPath';
import type { AbsoluteRouteArguments, AbsoluteRouteArgumentsWithoutState } from './TypedRouteArguments';
import type { AppNavigateOptions } from './useAppNavigate';

// Unfortunately some of the type mappings don't work without explicit "any" typings, so we need to shush eslint.
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return */

// Fake symbols to allow type inference
declare const typedRoutePathParams: unique symbol;
declare const typedRouteQueryParamsIn: unique symbol;
declare const typedRouteQueryParamsOut: unique symbol;
declare const typedRouteState: unique symbol;

/**
 * A type-safe route definition created via {@link defineRoute}.
 *
 * Can define path parameters, query parameters, and/or route state.
 */
export interface TypedRoute<
  Path extends string = string,
  PathParams extends Record<string, unknown> = Record<string, never>,
  QueryParamsIn extends Record<string, unknown> = Record<string, never>,
  QueryParamsOut extends Record<string, unknown> = Record<string, never>,
  RouteState = Record<string, never>
> {
  readonly path: Path;
  readonly pathParams: { readonly name: keyof PathParams; readonly optional: boolean }[];
  readonly pathParamNames: (keyof PathParams)[];
  readonly queryParamTypes: QueryParamsSerializerHash<QueryParamsOut, QueryParamsIn>;
  readonly routeStateTransformer: RouteStateTransformer<RouteState>;

  // Fake symbol properties for type inference
  readonly [typedRoutePathParams]: PathParams;
  readonly [typedRouteQueryParamsIn]: QueryParamsIn;
  readonly [typedRouteQueryParamsOut]: QueryParamsOut;
  readonly [typedRouteState]: RouteState;

  /**
   * Format the route as a URL.
   *
   * _Note:_ Instead of using a `<Link>` component with `url()`, consider using `<AppLink>`.
   */
  url: TypedRouteUrlMethod<this>;

  /**
   * Format the route as props for a react-router `Link`.
   *
   * Instead of a `<Link>` component, consider using `<AppLink>`.
   * *Meant to be used via `<AppLink>`, not to be called directly.*
   */
  link: TypedRouteLinkMethod<this>;

  /** Format the path name of the route with provided path parameters. */
  formatPath: (params: TypedRoutePathParams<this>) => string;

  /** Format the path name of the route with provided path parameters. */
  formatQueryString: (params: TypedRouteQueryParamsIn<this>) => string;
}

/**
 * Can be used for `extends` clauses.
 *
 * @example
 * ```
 * type Example<T extends AnyTypedRoute> = T extends TypedRoute<Path, PathParams, QueryParamsIn, QueryParamsIn, RouteState>
 *  ? // ...
 *  : // ...
 * ```
 */
export type AnyTypedRoute = TypedRoute<string, any, any, any, any>;

export type UnknownTypedRoute = TypedRoute<string, Record<string, unknown>, Record<string, unknown>, Record<string, unknown>, unknown>;

/** Infers the route path from a {@link TypedRoute}. */
export type TypedRoutePathParams<R extends AnyTypedRoute> = R extends TypedRoute<any, infer PathParams, any, any, any> ? PathParams : never;

/** Infers the query parameters settable for a {@link TypedRoute}. */
export type TypedRouteQueryParamsIn<R extends AnyTypedRoute> =
  R extends TypedRoute<any, any, infer QueryParamsIn, any, any> ? QueryParamsIn : never;

/** Infers the query parameters retrievable from a {@link TypedRoute}. */
export type TypedRouteQueryParamsOut<R extends AnyTypedRoute> =
  R extends TypedRoute<any, any, any, infer QueryParamsOut, any> ? QueryParamsOut : never;

/** Infers the query parameters retrievable from a {@link TypedRoute}. */
export type TypedRouteState<R extends AnyTypedRoute> = R extends TypedRoute<any, any, any, any, infer RouteState> ? RouteState : never;

type TypedRouteUrlMethod<R extends AnyTypedRoute> = (...args: AbsoluteRouteArgumentsWithoutState<R>) => string;

type TypedRouteLinkMethod<R extends AnyTypedRoute> = (
  ...args: [...args: AbsoluteRouteArguments<R>, options?: AppNavigateOptions]
) => PartialReactRouterLinkProps;

/** Returned by {@link TypedRoute}.link */
export interface PartialReactRouterLinkProps {
  to: Partial<Path>;
  state: any;
  replace: boolean;
}

/**
 * A custom type for query parameters.
 *
 * @usage
 * ```ts
 * // Example to serialize a RegExp as a URL parameter
 * export const RegexParam: QueryParamSerializer<RegExp> = {
 *   serialize: (input: RegExp) => `/${input}/${input.flags}`,
 *   deserialize: (input: string) => {
 *     const [, regex, flags] = input.match(/^\/(.+)\/(\w*)$/) ?? [];
 *     return regex && flags ? new RegExp(regex, flags) : undefined;
 *   }
 * };
 * ```
 */
export type QueryParamSerializer<Out, In = Out> = {
  name?: string;
  relatedType?: QueryParamSerializer<any, any> | ParamStaticType<any>;
  serialize: (input: In, name: string) => string | undefined;
  deserialize: (input: string, name: string, params: URLSearchParams) => Out | undefined;
};

/** Refers to global constructors - `String`, `Number`, `Boolean`, etc. */
export type ParamStaticType<T> = (input: any) => T;

type AnyQueryParamsDeclarationHash = Record<string, ParamStaticType<any> | QueryParamSerializer<any, any>>;

/** Hash type stored on a {@link TypedRoute}. */
type QueryParamsSerializerHash<TOut, TIn = TOut> = {
  [K in keyof TOut]: QueryParamSerializer<TOut[K], TIn[K & keyof TIn] & TOut[K]>;
};

/** Transforms a {@link QueryParamsDeclarationHash} to a {@link TypedRoute}#QueryParamsIn type. */
type QueryParamsInFromDeclaration<T extends AnyQueryParamsDeclarationHash> = MakeUndefineablePropsOptional<{
  [K in keyof T]: T[K] extends QueryParamSerializer<unknown, infer P> ? P : T[K] extends ParamStaticType<infer P> ? P : never;
}>;

/** Transforms a {@link QueryParamsDeclarationHash} to a {@link TypedRoute}#QueryParamsOut type. */
type QueryParamsOutFromDeclaration<T extends AnyQueryParamsDeclarationHash> = {
  [K in keyof T]?: T[K] extends QueryParamSerializer<infer P> ? P : T[K] extends ParamStaticType<infer P> ? P : never;
};

/** Transforms a {@link RouteStateDeclaration} to a {@link RouteStateTransformer}. */
type RouteStateFromDeclaration<T> =
  T extends RouteStateValidator<infer S>
    ? S
    : T extends RouteStateTransformer<infer S>
      ? S
      : T extends RouteStateDeclarationHash<infer R>
        ? R
        : never;

/**
 * Type hack to allow {@link defineRoute} to infer the route state type.
 *
 * Would be used to map a type to itself, but can also be used to validate "connected" state parts.
 *
 * @example
 * ```
 * // A compile-time-checked (non-enforced) type
 * defineRoute('/path', (state: { x: number, y: number }) => state);
 * ```
 */
type RouteStateTransformer<T> = (state: T) => T;

/**
 * Type hack to allow {@link defineRoute} to infer the route state type.
 *
 * Could be used to validate "connected" state parts.
 *
 * @example
 * ```
 * // A runtime-checked (enforced) type
 * defineRoute('/path', {
 *   state: (state: unknown): state is { x: number, y: number } => (
 *     typeof state === 'object' && !!state
 *       && 'x' in state && typeof state.x === 'number'
 *       && 'y' in state && typeof state.y === 'number'
 *   )
 * );
 * ```
 */
type RouteStateValidator<T> = (state: unknown) => state is T;

/**
 * Explicitly typed route state with individual properties.
 *
 * @example
 * ```
 * defineRoute('/path', {
 *   state: {
 *     x: Number,
 *     y: Number
 *   }
 * });
 * ```
 */
type RouteStateDeclarationHash<T> = {
  [K in keyof T]: ParamStaticType<T[K]>;
};

type RouteStateDeclaration = RouteStateDeclarationHash<unknown> | RouteStateTransformer<unknown>;

/**
 * Tests for non-optional parameters after optional parameters.
 * @example
 *   "/a/:b/:c?/d"
 *   "/a/:b/:c?/:d"
 *   // This is ok and not matched:
 *   "/a/:b/:c?/:d?"
 */
const requiredAfterOptionalParameters = /(?:^|\/):([A-Za-z0-9_]+)\?(?!$|(?:\/:([A-Za-z0-9_]+)\?)+)/;

/**
 * Define a route with a path and path parameters.
 *
 * @usage
 * ```ts
 * const foo = defineRoute('/foo/:id/:fruit(apple|banana)/:optionalParam?')
 * ```
 */
export function defineRoute<Path extends `/${string}`>(path: Path): TypedRoute<Path, PathParamTypes<Path>>;

/**
 * Define a route with a path, path parameters and query parameters.
 *
 * @usage
 * ```ts
 * const foo = defineRoute('/foo/:id', {
 *   query: {
 *     stringQueryParam: String,
 *     booleanQueryParam: Boolean,
 *   }
 * })
 * ```
 */
export function defineRoute<Path extends `/${string}`, QueryParams extends AnyQueryParamsDeclarationHash = {}>(
  path: Path,
  { query }: { query: QueryParams; state?: never }
): TypedRoute<Path, PathParamTypes<Path>, QueryParamsInFromDeclaration<QueryParams>, QueryParamsOutFromDeclaration<QueryParams>>;

/**
 * Define a route with a path, path parameters and route state.
 *
 * @usage
 * ```ts
 * const foo = defineRoute('/foo/:id', {
 *   state: (state: { x: number, y: number }) => state
 * })
 * ```
 */
export function defineRoute<Path extends `/${string}`, State extends RouteStateDeclaration>(
  path: Path,
  { state }: { query?: never; state: State }
): TypedRoute<Path, PathParamTypes<Path>, Record<string, never>, Record<string, never>, RouteStateFromDeclaration<State>>;

/**
 * Define a route with a path, path parameters and query parameters.
 *
 * @usage
 * ```ts
 * const foo = defineRoute('/foo/:id/:optionalParam?', {
 *   query: {
 *     stringQueryParam: String,
 *     booleanQueryParam: Boolean,
 *   },
 *   state: (state: { x: number, y: number }) => state
 * })
 * ```
 */
export function defineRoute<
  Path extends `/${string}`,
  QueryParams extends AnyQueryParamsDeclarationHash,
  State extends RouteStateDeclaration
>(
  path: Path,
  args: { query: QueryParams; state: State }
): TypedRoute<
  Path,
  PathParamTypes<Path>,
  QueryParamsInFromDeclaration<QueryParams>,
  QueryParamsOutFromDeclaration<QueryParams>,
  RouteStateFromDeclaration<State>
>;

export function defineRoute(
  path: string,
  {
    query: queryParamTypesInput = {},
    state: routeStateTypes = {}
  }: { query?: AnyQueryParamsDeclarationHash; state?: RouteStateDeclaration } = {}
): AnyTypedRoute {
  // Check if a required parameter follows an optional parameter, e.g. `/country?/cities/:city`
  const invalidOptionalParameter = path.match(requiredAfterOptionalParameters);
  if (invalidOptionalParameter) {
    throw new Error(
      `Pattern "${path}" is invalid: Optional parameter "${invalidOptionalParameter[1]}" is followed by non-optional parameters.`
    );
  }

  path = path.startsWith('/') ? path : `/${path}`;

  void routeStateTypes;

  // Extract "city" from "/cities/:city/location"
  const pathParams = Array.from(path.matchAll(/(?:^|\/):([A-Za-z0-9_]+)(\?)?(?=$|\/)/g), match => {
    return {
      name: match[1],
      optional: match[2] != null
    };
  });

  const routeStateTransformer: RouteStateTransformer<unknown> =
    typeof routeStateTypes === 'function'
      ? (state: unknown) => {
          // We either receive a validator that returns true/false, or a mapping function that returns T.
          const fn = routeStateTypes as RouteStateTransformer<unknown> | RouteStateValidator<unknown>;
          const returnValue = fn(state);

          if (returnValue === false) {
            console.error(`Route state for route ${path} seems to be invalid: `, { state, validator: fn });

            if (!import.meta.env.PROD) {
              throw new Error(`Route state for route ${path} seems to be invalid: ${String(state)}`);
            }
          }

          return returnValue;
        }
      : (state: unknown) => {
          // TODO(leon):
          // Validate state type (caller passed a hash as parameter, e.g. { x: Number })
          void state;
          return true;
        };

  const allowedSimpleParameterTypes: ParamStaticType<unknown>[] = [String, Number, Boolean];

  // The caller can define query parameters like `Number` or `String`, or as `{ serialize(), deserialize() }` pair.
  // We always normalize to the second form.
  const queryParamTypes = Object.fromEntries(
    Object.entries(queryParamTypesInput).map<[string, QueryParamSerializer<unknown, unknown>]>(([name, serializer]) => {
      if (typeof serializer === 'object' && serializer != null && 'serialize' in serializer && 'deserialize' in serializer) {
        return [name, serializer];
      }

      if (typeof serializer === 'function') {
        // Only allow a subset of "simple" param types
        if (!allowedSimpleParameterTypes.includes(serializer)) {
          throw new Error(`Query param ${name} is defined as ${serializer.name}, but only String, Number or Bool`);
        }

        return [
          name,
          {
            serialize: (input: unknown, _name: string) => {
              try {
                return String(input);
              } catch {
                return undefined;
              }
            },
            deserialize: (input: string, _name: string, _params: URLSearchParams) => {
              try {
                const value = serializer(input);
                if (typeof value === 'number' && Number.isNaN(value)) {
                  return undefined;
                }
                return value;
              } catch {
                return undefined;
              }
            }
          }
        ];
      }

      throw new Error(`Route ${path} defines a query parameter ${name} with an invalid type.`);
    })
  );

  const formatPath = (params: Record<string, unknown>) => insertPathParameters(path, params);

  const formatQueryString = (params: Record<string, unknown> = {}) => {
    return formatQueryParameters(params, queryParamTypes).toString();
  };

  const url: TypedRouteUrlMethod<AnyTypedRoute> = ({
    params = {},
    query = {}
  }: { params?: Record<string, string>; query?: Record<string, unknown> } = {}) => {
    const formattedPath = insertPathParameters(path, params);
    const search = formatQueryParameters(query, queryParamTypes).toString();
    return search ? `${formattedPath}?${search}` : formattedPath;
  };

  const link: TypedRouteLinkMethod<AnyTypedRoute> = (
    {
      params = {},
      query = {},
      state = {}
    }: { params?: Record<string, string>; query?: Record<string, unknown>; state?: Record<string, unknown> } = {},
    { replace = false } = {}
  ): PartialReactRouterLinkProps => {
    // The function passed to defineRoute can either return true/false to act as a validator,
    // or return the state (directly or with modifications) to act as a transformer.
    const routeStateTransformerResult = routeStateTransformer(state);
    const routeState =
      routeStateTransformerResult === true || routeStateTransformerResult === undefined
        ? state
        : routeStateTransformerResult === false
          ? undefined
          : routeStateTransformerResult;

    return {
      to: {
        pathname: insertPathParameters(path, params),
        search: formatQueryParameters(query, queryParamTypes).toString()
      },
      replace,
      state: routeState
    };
  };

  const route = {
    path,
    pathParams,
    pathParamNames: pathParams.map(param => param.name),
    queryParamTypes,
    routeStateTransformer,
    formatPath,
    formatQueryString,
    url,
    link
  } as AnyTypedRoute;

  Object.defineProperties(route, {
    displayName: {
      configurable: true,
      value: `TypedRoute "${path}"`
    },
    [Symbol.toStringTag]: {
      configurable: true,
      value: `TypedRoute "${path}"`
    }
  });

  return route;
}

function insertPathParameters(path: string, params: Record<string, unknown>) {
  return path.replace(/(^|\/):([A-Za-z0-9_]+)(\?)?(?=$|\/)/g, (_, prefix: string, paramName: string, isOptional: string) => {
    if (!isOptional && params[paramName] === undefined) {
      // Params are strongly typed and TypeScript should error before we reach this state,
      // but as a safety hatch we check if the parameter is missing in the passed path params.
      if (import.meta.env.PROD) {
        console.error(`Unmatched path parameter ${paramName} in route.url() for route `, { path, params });
      } else {
        throw new Error(`Unmatched path parameter ${paramName} in route.url() for route ${path}`);
      }
    }

    if (isOptional && params[paramName] === undefined) {
      return '';
    }

    return `${prefix}${encodeURIComponent(String(params[paramName]))}`;
  });
}

/**
 * Format a URL query part with correct escaping and keeping the query parameters in a consistent order.
 *
 * @example
 * formatQueryParameters({ page: 1, brand: 'H&M', category: 'shirt' }, {
 *   category: String
 *   brand: String,
 *   page: Number,
 * })
 * // -> 'category=shirt&brand=H%26M&page=1'
 */
function formatQueryParameters(queryParams: Record<string, unknown>, queryParamTypes: AnyQueryParamsDeclarationHash) {
  const searchParams = new URLSearchParams();
  for (const [key, serializer] of Object.entries(queryParamTypes)) {
    const value = queryParams[key];
    const formattedValue = typeof serializer === 'function' ? String(value) : serializer.serialize(value, key);
    if (formattedValue !== undefined) {
      searchParams.set(key, String(formattedValue));
    }
  }

  return searchParams;
}
