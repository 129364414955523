import { classNames } from '@hydrogrid/design-system';
import SadIcon from '../../assets/animations/sad.svg?react';
import styles from './ErrorFaceAnimation.module.css';

interface ErrorFaceAnimationProps {
  mood: 'happy' | 'sad';
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
}

export function ErrorFaceAnimation({
  mood,
  width = '10em',
  height = '10em',
  fill = 'var(--color-secondary)',
  className
}: ErrorFaceAnimationProps) {
  return <SadIcon width={width} height={height} className={classNames(className, styles[mood])} fill={fill} data-testid="icon" />;
}
