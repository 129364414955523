import { type ReactNode } from 'react';
import { forwardRefWithAs } from '../../utils/AsProps';
import { classNames } from '../../utils/classNames';
import { useCombinedRefs } from '../../utils/useCombinedRefs';
import styles from './TextEllipsis.module.css';
import { useTitleOnTextEllipsis } from './useTitleOnTextEllipsis';

interface TextEllipsisProps {
  axis?: 'horizontal' | 'vertical' | 'both';
  className?: string | undefined;
  children?: ReactNode;
}

/**
 * Component that automatically sets/removes a title to a element
 * when its text overflows and shows a text ellipsis.
 */
export const TextEllipsis = forwardRefWithAs<'div', TextEllipsisProps>(function TextEllipsis(props, externalRef) {
  const { as: Component = 'div', axis = 'horizontal', className, ...otherProps } = props;
  const ellipsisRef = useTitleOnTextEllipsis();
  const ref = useCombinedRefs(ellipsisRef, externalRef);

  return <Component ref={ref} className={classNames(styles[`textEllipsis-${axis}`], className)} {...otherProps}></Component>;
});
