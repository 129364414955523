import { Fragment, useMemo } from 'react';

interface KeyboardKeysProps {
  keys: string;
  className?: string | undefined;
  keyClassName?: string | undefined;
  title?: string | undefined;
}

/** Pretty-prints a key combination with symbols for special keys. */
export function KeyboardKeys({ keys, className, keyClassName, title }: KeyboardKeysProps) {
  const prettyHotkey = useMemo(() => {
    return (
      <>
        {keys.split('+').map((key, index) => (
          <Fragment key={index}>
            {index > 0 && ' + '}
            <kbd className={keyClassName}>{symbolsForKeys[key] ?? key}</kbd>
          </Fragment>
        ))}
      </>
    );
  }, [keys, keyClassName]);

  return (
    <kbd className={className} title={title ?? `Hotkey: ${keys}`}>
      {prettyHotkey}
    </kbd>
  );
}

//
// TODO(leon):
//   1. this is a bit inconsistent now 😿
//      e.g. "Ctrl + E" vs "⇧ + E"
//   2. in the far future: Translate (e.g. "Ctrl+Delete" -> "Strg+Entf")
//   3. maybe? (Mac)
//      Alt: '⌥'
//      Ctrl: '⎈' (old)
//
// http://xahlee.info/comp/unicode_computing_symbols.html
//
const symbolsForKeys: Record<string, string> = {
  Meta: '⌘',
  Shift: '⇧',
  Enter: '↵',
  ArrowUp: '↑',
  ArrowDown: '↓',
  ArrowLeft: '←',
  ArrowRight: '→',
  Backspace: '⌫',
  Tab: '⭾'
};
