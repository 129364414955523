import { jsx, jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Ref, forwardRef } from "react";
const SvgFileExcel = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", viewBox: "0 0 24 24", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fill: "currentColor", d: "m20.85 5.15-5-5A.5.5 0 0 0 15.5 0H5a2 2 0 0 0-2 2v20c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V5.5a.5.5 0 0 0-.15-.35ZM16 1.7 19.3 5H17a1 1 0 0 1-1-1V1.7ZM20 22a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h10v3c0 1.1.9 2 2 2h3v16Z" }),
  /* @__PURE__ */ jsx("rect", { width: 4, height: 1, x: 7.5, y: 10, fill: "currentColor", rx: 0.2 }),
  /* @__PURE__ */ jsx("rect", { width: 4, height: 1, x: 12.5, y: 10, fill: "currentColor", rx: 0.2 }),
  /* @__PURE__ */ jsx("rect", { width: 4, height: 1, x: 7.5, y: 12, fill: "currentColor", rx: 0.2 }),
  /* @__PURE__ */ jsx("rect", { width: 4, height: 1, x: 12.5, y: 12, fill: "currentColor", rx: 0.2 }),
  /* @__PURE__ */ jsx("rect", { width: 4, height: 1, x: 7.5, y: 14, fill: "currentColor", rx: 0.2 }),
  /* @__PURE__ */ jsx("rect", { width: 4, height: 1, x: 12.5, y: 14, fill: "currentColor", rx: 0.2 }),
  /* @__PURE__ */ jsx("rect", { width: 4, height: 1, x: 7.5, y: 16, fill: "currentColor", rx: 0.2 }),
  /* @__PURE__ */ jsx("rect", { width: 4, height: 1, x: 12.5, y: 16, fill: "currentColor", rx: 0.2 })
] });
const ForwardRef = forwardRef(SvgFileExcel);
export default ForwardRef;
