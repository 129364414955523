import { createContext, useCallback, useContext, useEffect, useState, type ReactNode } from 'react';
import { useLocales, type UserLocales } from './Locales';

/** Methods of {@link LocaleFormat} that can be used to format dates, i.e. accept a `Date` object. */
export type LocaleDateFormatName = {
  [K in keyof LocaleFormat]: LocaleFormat[K] extends (value: Date) => string ? K : never;
}[keyof LocaleFormat] &
  keyof LocaleFormat;

export interface LocaleFormat {
  /**
   * Short date format, e.g.:
   * - "19.02.2021" (de-DE)
   * - "2/19/2021" (en-US)
   **/
  shortDate: (date: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string) => string;

  /**
   * Long date format, e.g.:
   * - "5. Januar 2021" (de-DE)
   * - "January 5, 2021" (en-US)
   **/
  longDate: (date: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string) => string;

  /**
   * Short date range format, e.g.:
   * - "07.–20.05.23" (de-DE)
   * - "5/7/23 – 5/20/23" (en-US)
   **/
  shortDateRange: (
    start: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string,
    end: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string
  ) => string;

  /**
   * Long date range format, e.g.:
   * - "7.–20. Mai 2023" (de-DE)
   * - "May 7 – 20, 2023" (en-US)
   **/
  longDateRange: (
    start: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string,
    end: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string
  ) => string;

  /**
   * Aligned date range format, optimized for tables / grids, e.g.:
   * - "07. Mai 2023 – 20. Mai 2023" (de-DE)
   * - "May 07, 2023 – May 20, 2023" (en-US)
   **/
  alignedDateRange: (
    start: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string,
    end: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string
  ) => string;

  /**
   * TODOTODOTODOTODOTODOTODOTODO
   * Aligned date range format, optimized for tables / grids, e.g.:
   * - "07. Mai 2023 – 20. Mai 2023" (de-DE)
   * - "May 07, 2023 – May 20, 2023" (en-US)
   **/
  alignedDateTimeRange: (
    start: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string,
    end: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string
  ) => string;

  /**
   * Short date format, e.g.:
   * - "19.02." (de-DE)
   * - "2/19" (en-US)
   **/
  dayMonth: (date: number | Date | Temporal.PlainDate | Temporal.ZonedDateTime | string | { day: number; month: number }) => string;

  /**
   * Day of Month only
   * - "01"
   */
  dayOfMonth: (date: number | Date | Temporal.PlainDate | Temporal.ZonedDateTime | string | { day: number }) => string;

  /**
   * Month and year only in number, e.g.:
   * - "02/23" (de-DE)
   * - "02/23" (es-AR)
   **/
  numberYearMonth: (date: number | Date | Temporal.PlainDate | Temporal.ZonedDateTime | string | { month: number; year: number }) => string;

  /**
   * Month and year only, e.g.:
   * - "Feb 2023" (de-DE)
   * - "Feb 2023" (es-AR)
   **/
  shortYearMonth: (date: number | Date | Temporal.PlainDate | Temporal.ZonedDateTime | string | { month: number; year: number }) => string;

  /**
   * Month and year only, e.g.:
   * - "Februar 2023" (de-DE)
   * - "Febrero de 2023" (es-AR)
   **/
  longYearMonth: (date: number | Date | Temporal.PlainDate | Temporal.ZonedDateTime | string | { month: number; year: number }) => string;

  /**
   * Short month range format, e.g.:
   * - "02/2024 – 05/2024" (de-DE)
   * - "2/2024 – 5/2024" (en-US)
   **/
  shortYearMonthRange: (
    start: Date | Temporal.PlainYearMonth | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime,
    end: Date | Temporal.PlainYearMonth | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime
  ) => string;

  /**
   * Long month range format, e.g.:
   * - "Februar–März 2024" (de-DE)
   * - "Februrary – March 2024" (en-US)
   **/
  longYearMonthRange: (
    start: Date | Temporal.PlainYearMonth | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime,
    end: Date | Temporal.PlainYearMonth | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime
  ) => string;

  /**
   * Year only, e.g.:
   * - "2023" (de-DE)
   * - "٢٠٢٣" (ar-EG)
   **/
  year: (date: number | Date | Temporal.PlainDate | Temporal.PlainYearMonth | Temporal.ZonedDateTime | string | { year: number }) => string;

  /**
   * Time with minutes, e.g.:
   * - "16:05" (de-DE)
   * - "4:05 PM" (en-US)
   **/
  shortTime: (date: number | Date | Temporal.PlainTime | Temporal.PlainDateTime | Temporal.ZonedDateTime | string) => string;

  /**
   * Date & Time with minutes, e.g.:
   * - "19.02.2022, 16:05" (de-DE)
   * - "2/19/22, 4:05 PM" (en-US)
   **/
  shortDateTime: (date: number | Date | Temporal.PlainDateTime | Temporal.ZonedDateTime | string) => string;

  /**
   * Date & Time with minutes, e.g.:
   * - "19. Mai, 16:05" (de-DE)
   * - "19. May, 4:05 PM" (en-US)
   **/
  shortDateTimeMonth: (date: number | Date | Temporal.PlainDateTime | Temporal.ZonedDateTime | string) => string;

  /**
   * Date optimized for vertical alignment in tables e.g.:
   * - "19.02.2022" (de-DE)
   * - "02/19/2022" (en-US)
   **/
  alignedDate: (date: number | Date | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | string) => string;

  /**
   * Date & Time with minutes, optimized for vertical alignment in tables e.g.:
   * - "19.02.2022, 16:05" (de-DE)
   * - "02/19/2022, 04:05 PM" (en-US)
   **/
  alignedDateTime: (date: number | Date | Temporal.PlainDateTime | Temporal.ZonedDateTime | string) => string;

  /**
   * Long Date & Time with minutes, e.g.:
   * - "19. Februar 2022 um 16:05" (de-DE)
   * - "February 19, 2022 at 4:05 PM" (en-US)
   */
  longDateTime: (date: number | Date | Temporal.PlainDateTime | Temporal.ZonedDateTime | string) => string;

  /**
   * Format an amount of seconds as minutes & seconds, e.g.: `format.minutesSeconds(471)`
   * - "7:51" (de-DE)
   * - "٠٧:٥١" (ar-EG)
   */
  minutesSeconds: (seconds: number) => string;

  /**
   * Date relative to today in days, e.g.:
   * - "vor 5 Tagen" (de-DE)
   * - "5 days ago" (en-US)
   * - "heute" (de-DE)
   * - "today" (en-US)
   * - "übermorgen" (de-DE)
   * - "in 2 days" (en-US)
   */
  relativeDate: (date: number | Date | Temporal.ZonedDateTime | string) => string;

  /**
   * Time relative to now in minutes or larger units, e.g.:
   * - "vor 15 Minuten" (de-DE)
   * - "15 minutes ago" (en-US)
   * - "heute" (de-DE)
   * - "today" (en-US)
   * - "übermorgen" (de-DE)
   * - "in 2 days" (en-US)
   */
  relativeTime: (date: number | Date | Temporal.ZonedDateTime | string) => string;

  /**
   * Number with decimal/thousands separator, e.g.:
   * - "15.525,66" (de-DE)
   * - "15,525.66" (en-US)
   *
   * The value is rounded to the nearest decimals passed as `decimals` parameter.
   * If the value is `null` or `Number.NaN`, the returned string will be `"-"`.
   *
   * @example
   * ```
   * format.number(12345.678, 2);
   * // => "12.345,68" (de-DE)
   * // => "12,345.68" (en-US)
   *
   * format.number(12345.678, 0);
   * // => "12.346" (de-DE)
   * // => "12,346" (en-US)
   *
   * format.number(null, 0);
   * // => "-"
   * ```
   */
  number: (value: number | null, decimals: number | 'auto', emptyDisplayValue?: string) => string;

  /**
   * Monetary values with currency formatted in the user format.
   * @example
   * ```
   * format.monetary(2000, "EUR");
   * // => "2.000 €" (de-DE)
   * // => "€2,000" (en-US)
   * ```
   */
  monetary: (value: number, currency: string) => string;

  /**
   * The character sequence used to separate start and end date in a range.
   * @example
   * ```
   * format.dateRangeSeparator
   * // => " – " (de-DE)
   * // => " – " (ar-EG)
   * ```
   */
  dateRangeSeparator: string;

  /**
   * The character sequence used to separate groups of numbers, usually thousands.
   * @example
   * ```
   * format.numberGroupSeparator
   * // => "." (de-DE) -> 10.000.000.000,00
   * // => "," (en-US) -> 10,000,000,000.00
   * // => "٬" (ar-EG) -> 10٬000٬000٬000٫00
   * // (arabic only has slightly different characters)
   * ```
   */
  numberGroupSeparator: string;

  /**
   * The character used to separate the integer part of a number from the fraction part.
   * @example
   * ```
   * format.numberDecimalSeparator
   * // => "." (en-US) -> 50.11
   * // => "," (de-DE) -> 50,11
   * // => "٬" (ar-EG) -> 50٫11
   * // (slightly different character)
   * ```
   */
  numberDecimalSeparator: string;

  /** `true` if the locale uses `"4:00 PM"`, `false` if it uses `"16:00"`. */
  usesAmPm: boolean;
}

function createFormatter(locales: UserLocales, timeZone?: string): LocaleFormat {
  const { dateTime: dateLocale, numbers: numbersLocale } = locales;
  const userTimeZone = Temporal.Now.timeZone().id;
  timeZone ??= userTimeZone;

  const shortDateFormat = new Intl.DateTimeFormat(dateLocale, { day: 'numeric', month: 'numeric', year: 'numeric', timeZone });
  const longDateFormat = new Intl.DateTimeFormat(dateLocale, { dateStyle: 'long', timeZone });
  const dayMonthFormat = new Intl.DateTimeFormat(dateLocale, { day: 'numeric', month: 'numeric', timeZone });
  const dayOfMonthFormat = new Intl.DateTimeFormat(dateLocale, { day: '2-digit' });
  const numberYearMonthFormat = new Intl.DateTimeFormat(dateLocale, { month: '2-digit', year: '2-digit', timeZone });
  const shortYearMonthFormat = new Intl.DateTimeFormat(dateLocale, { month: 'short', year: 'numeric', timeZone });
  const longYearMonthFormat = new Intl.DateTimeFormat(dateLocale, { month: 'long', year: 'numeric', timeZone });
  const yearFormat = new Intl.DateTimeFormat(dateLocale, { year: 'numeric', timeZone });
  const shortTimeFormat = new Intl.DateTimeFormat(dateLocale, { timeStyle: 'short', timeZone });
  const shortDateTimeFormat = new Intl.DateTimeFormat(dateLocale, { dateStyle: 'short', timeStyle: 'short', timeZone });
  const shortDateTimeMonthFormat = new Intl.DateTimeFormat(dateLocale, {
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    timeZone
  });
  const alignedDateFormat = new Intl.DateTimeFormat(dateLocale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone
  });
  const alignedDateTimeFormat = new Intl.DateTimeFormat(dateLocale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone
  });
  const longDateTimeFormat = new Intl.DateTimeFormat(dateLocale, { dateStyle: 'long', timeStyle: 'short', timeZone });
  const minutesSecondsFormat = new Intl.DateTimeFormat(dateLocale, { minute: 'numeric', second: '2-digit' });
  const relativeDateFormat = new Intl.RelativeTimeFormat(dateLocale, { numeric: 'auto' });

  const twoDecimalsNumberFormat = new Intl.NumberFormat(numbersLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const noDecimalsNumberFormat = new Intl.NumberFormat(numbersLocale, { maximumFractionDigits: 0 });
  const flexibleNumberFormat = new Intl.NumberFormat(numbersLocale, { minimumFractionDigits: 0, maximumFractionDigits: 12 });

  const spaceEnDashSpace = `\u2009\u2013\u2009`;

  // TODO: Check if definitions for part.value and part.type have been added in later versions of Temporal (Last checked 0.2.5)
  const dateTimeFormatRangePart = longDateFormat.formatRangeToParts(new Date(2020, 1, 2), new Date(2021, 3, 4)).find(part => {
    if ('value' in part && 'type' in part && typeof part.value === 'string') {
      return part.type === 'literal' && part.source === 'shared' && !/w/.test(part.value);
    }
  });
  const separatorBetweenDatesInDateRange =
    dateTimeFormatRangePart !== undefined && 'value' in dateTimeFormatRangePart && typeof dateTimeFormatRangePart.value === 'string'
      ? dateTimeFormatRangePart.value
      : spaceEnDashSpace;

  const numberParts = flexibleNumberFormat.formatToParts(123456789.0123456);
  const numberGroupSeparatorCharacter = numberParts.find(part => part.type === 'group')?.value ?? ',';
  const numberDecimalSeparatorCharacter = numberParts.find(part => part.type === 'decimal')?.value ?? '.';

  const format: LocaleFormat = {
    shortDate: value => shortDateFormat.format(toDateObj(value, timeZone)),
    longDate: value => longDateFormat.format(toDateObj(value, timeZone)),
    shortDateRange: (start, end) => shortDateFormat.formatRange(toDateObj(start, timeZone), toDateObj(end, timeZone)),
    longDateRange: (start, end) => longDateFormat.formatRange(toDateObj(start, timeZone), toDateObj(end, timeZone)),
    alignedDateRange: (start, end) => alignedDateFormat.formatRange(toDateObj(start, timeZone), toDateObj(end, timeZone)),
    alignedDateTimeRange: (start, end) => alignedDateTimeFormat.formatRange(toDateObj(start, timeZone), toDateObj(end, timeZone)),
    dayMonth: value => dayMonthFormat.format(toDateObj(value, timeZone)),
    dayOfMonth: value => dayOfMonthFormat.format(toDateObj(value, timeZone)),
    numberYearMonth: value => numberYearMonthFormat.format(toDateObj(value, timeZone)),
    shortYearMonth: value => shortYearMonthFormat.format(toDateObj(value, timeZone)),
    longYearMonth: value => longYearMonthFormat.format(toDateObj(value, timeZone)),
    shortYearMonthRange: (start, end) => shortYearMonthFormat.formatRange(toDateObj(start, timeZone), toDateObj(end, timeZone)),
    longYearMonthRange: (start, end) => longYearMonthFormat.formatRange(toDateObj(start, timeZone), toDateObj(end, timeZone)),
    year: value => yearFormat.format(toDateObj(value, timeZone)),
    shortTime: value => shortTimeFormat.format(toDateObj(value, timeZone)),
    shortDateTime: value => shortDateTimeFormat.format(toDateObj(value, timeZone)),
    shortDateTimeMonth: value => shortDateTimeMonthFormat.format(toDateObj(value, timeZone)),
    alignedDate: value => alignedDateFormat.format(toDateObj(value, timeZone)),
    alignedDateTime: value => alignedDateTimeFormat.format(toDateObj(value, timeZone)),
    longDateTime: value => longDateTimeFormat.format(toDateObj(value, timeZone)),
    minutesSeconds: seconds => {
      const sign = seconds < 0 ? '-' : '';
      const mmss = minutesSecondsFormat.format(new Date(2000, 0, 1, 0, 0, Math.abs(seconds)));
      return `${sign}${mmss}`;
    },
    relativeDate: value => {
      let targetTimestamp: number;
      let targetMonthSum: number;

      if (value instanceof Temporal.ZonedDateTime) {
        targetTimestamp = value.epochMilliseconds;
        targetMonthSum = value.year * 12 + value.month;
      } else {
        const date = value instanceof Date ? value : new Date(value);
        targetTimestamp = date.getTime();
        targetMonthSum = date.getFullYear() * 12 + date.getMonth() + 1;
      }
      const now = new Date();

      const dayDiff = (targetTimestamp - now.getTime()) / 86400000;
      if (Math.abs(dayDiff) < 31) {
        return relativeDateFormat.format(Math.trunc(dayDiff), 'days');
      }

      const monthsDiff = targetMonthSum - now.getFullYear() * 12 - now.getMonth() - 1;
      if (Math.abs(monthsDiff) < 12) {
        return relativeDateFormat.format(Math.trunc(monthsDiff), 'months');
      }

      return relativeDateFormat.format(Math.trunc(monthsDiff / 12), 'years');
    },
    relativeTime: date => {
      const timestamp = date instanceof Temporal.ZonedDateTime ? date.epochMilliseconds : new Date(date).getTime();
      const minuteDiff = (timestamp - Date.now()) / 60_000;
      if (Math.abs(minuteDiff) < 60) {
        return relativeDateFormat.format(Math.trunc(minuteDiff), 'minutes');
      }

      const hourDiff = minuteDiff / 60;
      if (Math.abs(hourDiff) < 24) {
        return relativeDateFormat.format(Math.trunc(hourDiff), 'hours');
      }

      return format.relativeDate(date);
    },
    number: (value, decimals, emptyDisplayValue = '-') => {
      if (value === null || Number.isNaN(value)) {
        return emptyDisplayValue;
      }

      let numberFormat;
      if (decimals === 0) {
        numberFormat = noDecimalsNumberFormat;
      } else if (decimals === 2) {
        numberFormat = twoDecimalsNumberFormat;
      } else if (decimals === 'auto') {
        numberFormat = flexibleNumberFormat;
      } else {
        numberFormat = new Intl.NumberFormat(numbersLocale, {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals
        });
      }

      return numberFormat.format(value);
    },
    monetary: (value, currency) =>
      Number(value).toLocaleString(numbersLocale, {
        style: 'currency',
        currency
      }),

    get dateRangeSeparator() {
      return separatorBetweenDatesInDateRange;
    },

    get numberGroupSeparator() {
      return numberGroupSeparatorCharacter;
    },

    get numberDecimalSeparator() {
      return numberDecimalSeparatorCharacter;
    },

    usesAmPm: shortDateTimeFormat.resolvedOptions().hour12 ?? false
  };

  return format;
}

function toDateObj(
  date: Date | string | number | { year?: number; month?: number; day?: number; hour?: number; minute?: number; second?: number },
  timeZone?: string | undefined
) {
  if (date instanceof Date) {
    return date;
  }

  if (typeof date !== 'object') {
    return new Date(date);
  }

  // If the `Date` prototype was overridden or the `Date` comes from a different iframe, it needs special treatment
  if (date != null && Object.prototype.toString.call(date) === '[object Date]' && 'getTime' in date && typeof date.getTime === 'function') {
    const returnValue: unknown = date.getTime();
    if (typeof returnValue === 'number') {
      return new Date(returnValue);
    }
  }

  if (date == null || !('day' in date || 'month' in date || 'year' in date || 'hour' in date || 'minute' in date || 'second' in date)) {
    throw new Error(`Can not convert value to date: ${typeof date} ${String(date)}`);
  }

  const { year, month, day, hour, minute, second } = date;

  return Temporal.ZonedDateTime.from({
    year: year ?? Temporal.Now.plainDateISO(timeZone).year,
    month: month ?? 1,
    day: day ?? 1,
    hour: hour ?? 0,
    minute: minute ?? 0,
    second: second ?? 0,
    timeZone: timeZone ?? 'Europe/Vienna'
  }).epochMilliseconds;
}

const LocaleFormatContext = createContext<((timeZone: string | undefined) => LocaleFormat) | null>(null);

/**
 * Provides an application-wide formatters for presenting dates, times, etc.
 * Output format depends on the browser language/formatting settings by default (@see ProvideLocale)
 **/
export function ProvideLocaleFormat({ children }: { children: ReactNode }) {
  const locales = useLocales();
  const [formatsForTimezone, setFormatsForTimezone] = useState(() => new Map<string | undefined, LocaleFormat>());

  useEffect(() => {
    setFormatsForTimezone(new Map());
  }, [locales]);

  const getFormatter = useCallback(
    (timeZone: string | undefined) => {
      let formatter = formatsForTimezone.get(timeZone);
      if (!formatter) {
        formatter = createFormatter(locales, timeZone);
        formatsForTimezone.set(timeZone, formatter);
      }
      return formatter;
    },
    [formatsForTimezone, locales]
  );

  return <LocaleFormatContext.Provider value={getFormatter}>{children}</LocaleFormatContext.Provider>;
}

/**
 * Returns a formatter for presenting dates, times, etc in the user preferred format.
 * Depends on the user locale (@see useLocale, @see ProvideLocale).
 *
 * @example
 * ```
 * function Example({ from, to }: { from: number, to: number }) {
 *   const format = useLocaleFormat();
 *   return <span>{format.shortDate(from)} - {format.shortDate(to)}</span>;
 * }
 * ```
 */
export function useLocaleFormat(timeZone?: string | undefined) {
  const getFormat = useContext(LocaleFormatContext);
  if (!getFormat) {
    throw new Error('For useLocaleFormat to work, render ProvideLocaleFormat in the root component.');
  }

  return getFormat(timeZone);
}
