import { childrenToString } from '../../accessibility/childrenToString';
import { usePageTitle } from './usePageTitle';

interface PageTitleProps {
  children: string | number | string[] | null;
}

/**
 * Component to set the page title.
 *
 * @example
 * ```
 * const AwesomePage = () => (
 *   <div>
 *     <PageTitle>Awesome Page</PageTitle>
 *     <p>This is an awesome page!</p>
 *   </div>
 * );
 * const RootComponent = () => (
 *   <AppTitle prefix="Example" suffix="Appname" separator=" - ">
 *     <AwesomePage />
 *   </PageTitlePrefix>
 * );
 *
 * // Browser tab title: "Example - Awesome Page - Appname"
 * ```
 */
export function PageTitle({ children }: PageTitleProps) {
  const title = childrenToString(children);
  usePageTitle(title);
  return null;
}
