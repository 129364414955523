import { createContext, useContext as reactUseContext, type Provider } from 'react';

const NO_CONTEXT_PROVIDED = Symbol('NO_CONTEXT_PROVIDED');

export function createStrictContext<T>(name: string, { message }: { message?: string } = {}) {
  const Context = createContext<T | typeof NO_CONTEXT_PROVIDED>(NO_CONTEXT_PROVIDED);
  Context.displayName = `${name}Context`;

  function useContext() {
    const contextValue = reactUseContext(Context);

    if (contextValue === NO_CONTEXT_PROVIDED) {
      throw new Error(message ?? `use${name} called outside a ${name}Provider`);
    }

    return contextValue;
  }

  Object.defineProperty(useContext, 'name', {
    configurable: true,
    value: `use${name}`
  });

  return [useContext, Context.Provider] as [() => T, Provider<T>];
}
